import { VIDEO_RECORDING_ENABLED } from 'consts'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ApplicationSocketContext } from './ApplicationSocketState'
import { logoutObservable } from 'data/observables'
import { addStreamingLog } from 'data/apis/streaming-logs'
import { StreamingLog } from 'data/domain/streaming-log'
import { AuthContext } from './AuthState'

const FrameCaptureState = ({ children }) => {
  const [video, setVideo] = useState(undefined)
  const { socket } = useContext(ApplicationSocketContext)
  const { user } = useContext(AuthContext)

  const captureFrame = useCallback(() => {
    if (!video) {
      return
    }
    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas.getContext('2d').drawImage(video, 0, 0)
    // const data = canvas.toDataURL('image/png')
    // sendCameraFrame(data)
  }, [video])

  const createVideoHolder = (stream: MediaStream) => {
    const videoElement = document.createElement('video')
    videoElement.autoplay = true
    videoElement.srcObject = stream
    videoElement.width = 320
    videoElement.height = 180
    setVideo(videoElement)
  }

  const getCameraStreamInput = useCallback(() => {
    if (!socket) {
      return
    }
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          width: 320,
          height: 180
        }
      })
      .then((stream) => {
        createVideoHolder(stream)
        logoutObservable.subscribe(() => {
          stream.getTracks().forEach((track) => track.stop())
        })
      })
      .catch((e) => {
        addStreamingLog({
          action: 'GET_CAMERA_STREAM_INPUT',
          message: e?.message,
          candidateId: user?.id
        } as StreamingLog)
      })
  }, [socket, user])

  useEffect(() => {
    if (!VIDEO_RECORDING_ENABLED) {
      return
    }
    getCameraStreamInput()
  }, [getCameraStreamInput])

  useEffect(() => {
    if (!VIDEO_RECORDING_ENABLED || !video) {
      return
    }
    const interval = setInterval(captureFrame, 1000 / 1)

    return () => {
      clearInterval(interval)
    }
  }, [video, captureFrame])

  return children()
}

export default FrameCaptureState
