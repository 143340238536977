import Button from 'components/Button'
import React, { useContext } from 'react'
import { AppealResponseContext } from 'states/AppealResponseState'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Html from 'components/Html'
import { Box } from '@material-ui/core'

type AppealResponseDetailProps = {
  providerCodename?: string
  className?: string
}

const AppealResponseDetail = ({ className }: AppealResponseDetailProps) => {
  const { appeal } = useContext(AppealResponseContext)
  const history = useHistory()
  return (
    <>
      <div className={className}>
        <Box className="box-header">
          <div className="title-defer">
            <span>Análise do recurso</span>
          </div>
          <div className="defer-header">
            <div>
              <span>Status do Deferimento</span>
              <p>{appeal?.result?.description}</p>
            </div>
            <div>
              <span>Respondido por</span>
              <p>{appeal?.repliedBy?.name}</p>
            </div>
            <div>
              <span>Data da Resposta</span>
              <p>{appeal?.repliedAt}</p>
            </div>
          </div>
          <div className="response-to-candidate">
            <span>Resposta para Candidato</span>
            <Html>{appeal?.response}</Html>
          </div>
        </Box>
        <div className="buttons">
          <Button
            action="error"
            onClick={() => {
              history.goBack()
            }}
          >
            Voltar
          </Button>
        </div>
      </div>
    </>
  )
}

export default styled(AppealResponseDetail)`
  span {
    color: ${(props) => props.theme.clientColors.primary};
    font-weight: bold;
  }
  p {
    color: #606060;
  }
  .title-defer {
    span {
      color: ${(props) => props.theme.clientColors.primary};
      font-size: 1.4rem;
      position: relative;
      font-weight: 600;
      bottom: 1rem;
    }
  }
  .defer-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .buttons {
    display: flex;
    justify-content: flex-start;
    gap: 0.5em;
    margin-top: 0.2rem;
    & > * {
      padding: 10px 30px;
      margin: 2px 0px 0px 0px;
      font-size: 15px;
    }
  }
  .response-to-candidate {
    ${Html} {
      margin-top: 0.8rem;
    }
  }
  .box-header {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.103);
    padding: 1rem;
  }
`
