import React, { useEffect, useRef, useState } from 'react'
import DraggableModal from './DraggableModal'

interface ScientificCalculatorProps {
  isScientificCalculatorOpen: boolean
  setIsScientificCalculatorOpen: (value: boolean) => void
}

const ScientificCalculator: React.FC<ScientificCalculatorProps> = ({
  isScientificCalculatorOpen,
  setIsScientificCalculatorOpen
}) => {
  const [calculatorState, setCalculatorState] = useState({})
  const calculator = useRef(null)

  useEffect(() => {
    const calculatorContainer = document.getElementById('calculator-container')
    if (calculatorContainer && !calculator.current) {
      calculator.current = Desmos.ScientificCalculator(calculatorContainer)
      if (Object.keys(calculatorState).length > 0) {
        calculator.current.setState(calculatorState)
      }
      calculator.current.observeEvent('change', () =>
        setCalculatorState(calculator.current.getState())
      )
    }
  }, [isScientificCalculatorOpen, calculatorState])

  useEffect(() => {
    return () => (calculator.current = null)
  }, [isScientificCalculatorOpen])

  return (
    <DraggableModal
      isOpen={isScientificCalculatorOpen}
      onAction={() => setIsScientificCalculatorOpen(false)}
    >
      <div
        id="calculator-container"
        style={{ width: '600px', height: '400px' }}
      ></div>
    </DraggableModal>
  )
}

export default ScientificCalculator
