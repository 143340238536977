import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { ReactNode } from 'react'
import Button, { ButtonProps } from '../Button'
import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

interface ToggleMenuButtonProps extends ButtonProps {
  children: ReactNode
  className?: string
  icon?: IconDefinition
  disabled?: boolean
  action?: 'default' | 'primary' | 'secondary' | 'icon' | 'unstyled' | 'link'
}

const ToggleMenuButton = ({
  children,
  icon,
  className,
  disabled = false,
  action = 'icon',
  ...rest
}: ToggleMenuButtonProps) => {
  return (
    <div className={className}>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button
              className="btn-awesome-icon"
              type="button"
              disabled={disabled}
              action={action}
              {...rest}
              {...bindTrigger(popupState)}
            >
              {icon ? (
                <FontAwesomeIcon className={className} icon={icon} />
              ) : (
                <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
              )}
            </Button>
            {!disabled && (
              <Menu {...bindMenu(popupState)}>
                {children &&
                  React.Children.map(children, (actionItem) => {
                    if (!actionItem) {
                      return null
                    }
                    return (
                      <MenuItem onClick={popupState.close}>
                        {actionItem}
                      </MenuItem>
                    )
                  })}
              </Menu>
            )}
          </React.Fragment>
        )}
      </PopupState>
    </div>
  )
}

export default styled(ToggleMenuButton)`
  .btn-awesome-icon {
    border: none;
    background-color: none;
  }

  .btn-awesome-icon:hover {
    background-color: none;
    background: none;
    border: none;
  }
`
