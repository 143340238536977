import React, { useEffect } from 'react'
import Highcharts from 'highcharts'
import styled from 'styled-components'

const HighchartSkill = ({ data }) => {
  useEffect(() => {
    Highcharts.chart(`container-${data.habilidadeId}`, {
      chart: {
        type: 'pie',
        backgroundColor: 'none',
        height: '400',
        width: '300'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: `${data.qtd}/${data.qtdTotal}<br>Acertos`,
        align: 'center',
        verticalAlign: 'center',
        style: {
          fontSize: '35px',
          textAlign: 'center'
        },
        x: 0,
        y: 177
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          dataLabels: {
            enabled: false
          },
          states: {
            hover: {
              enabled: false
            }
          },
          size: 100,
          innerSize: '100%',
          borderColor: null,
          borderWidth: 8
        }
      },
      tooltip: {
        valueSuffix: '%',
        valueDecimals: 2
      },
      series: [
        {
          size: '100%',
          innerSize: '100%',
          data: [
            {
              name: 'erros',
              y: 100 * (1 - data.qtd / data.qtdTotal),
              color: '#cccc'
            },
            {
              name: 'acertos',
              y: (100 * data.qtd) / data.qtdTotal,
              color: '#48ab86'
            }
          ]
        }
      ]
    })
  })

  return (
    <div
      id={`container-${data.habilidadeId}`}
      style={{ overflow: 'scroll' }}
    ></div>
  )
}

export default styled(HighchartSkill)`
  .highcharts-credits {
    display: none !important;
  }
`
