import React, { useMemo, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import CircularBorderLoader from './DataDisplay/CircularBorderLoader'
import RemainingTime from './RemainingTime'
import CustomRemainingTime from './CustomRemainingTime'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface AnswerRemainingTimeProps {
  className?: string
  remainingTimeInitialDate: number | undefined
  answerInitialTime: number | undefined
  secondsToTimeout: number | undefined
  secondsToAnswerTimeout: number | undefined
  allowHideRemainingTime?: boolean
  showAnswerCountdown?: boolean
}

const AnswerRemainingTime: React.FC<AnswerRemainingTimeProps> = ({
  className,
  remainingTimeInitialDate,
  secondsToTimeout,
  allowHideRemainingTime,
  answerInitialTime,
  secondsToAnswerTimeout,
  showAnswerCountdown = false
}) => {
  const [percentage, setPercentage] = useState(0)
  const secondsToMilliseconds = useRef(1000)
  const hundredPercent = useRef(100)

  const start = useMemo(() => new Date(remainingTimeInitialDate).getTime(), [
    remainingTimeInitialDate
  ])
  const end = useMemo(
    () =>
      new Date(
        remainingTimeInitialDate +
          secondsToTimeout * secondsToMilliseconds.current
      ).getTime(),
    [secondsToTimeout, remainingTimeInitialDate]
  )
  const range = useMemo(() => end - start, [end, start])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const elapsedFromNow = new Date().getTime() - start
      const percentageElapsed =
        (elapsedFromNow / range) * hundredPercent.current
      if (percentageElapsed >= 100) return
      setPercentage(percentageElapsed)
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [range, start])

  return (
    <div className={className}>
      <div className="circular-counter">
        <CircularBorderLoader
          className="circular-loader"
          percentage={percentage}
          size={50}
        ></CircularBorderLoader>
        <div className="centered-icon">
          <FontAwesomeIcon className="icon" icon={faInfoCircle} size="2x" />
        </div>
      </div>
      <RemainingTime
        title="Tempo atividade"
        initial={remainingTimeInitialDate}
        secondsToTimeout={secondsToTimeout}
        allowHideRemainingTime={allowHideRemainingTime}
      />
      {showAnswerCountdown && (
        <>
          <hr />
          <CustomRemainingTime
            title="Tempo questão"
            initial={answerInitialTime}
            secondsToTimeout={secondsToAnswerTimeout}
            allowHideRemainingTime={allowHideRemainingTime}
          />
        </>
      )}
    </div>
  )
}

export default styled(AnswerRemainingTime)`
  position: fixed;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

  .circular-counter {
    position: relative;
    display: flex;

    .centered-icon {
      position: absolute;
      top: 59%;
      left: 42%;
      transform: translateY(-50%) translateX(-50%);

      svg {
        width: 24px;
      }
    }
  }
  @media (min-width: 781px) {
    top: 170px;
    right: 30px;
  }
  @media (min-width: 601px) and (max-width: 780px) {
    left: 78px;
    bottom: 5px;
    height: 38px;
    display: flex;
    padding: 0.5rem;
    justify-content: center;

    .circular-counter {
      display: none;
    }

    ${RemainingTime} {
      display: flex;
      width: auto;

      span {
        margin-top: 12px;
        margin-left: 1rem;
      }
      button {
        margin-left: 0.3rem;
        margin-bottom: 8px;
      }
    }
  }

  @media (max-width: 600px) {
    left: 10px;
    bottom: 5px;
    height: 38px;
    display: flex;
    justify-content: center;

    .circular-counter {
      display: none;
    }

    ${RemainingTime} {
      display: flex;
      width: auto;

      span {
        margin-left: 1rem;
      }
    }
  }

  hr {
    width: 100%;
  }
`
