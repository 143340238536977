import Button from 'components/Button'
import AppealResultInput from 'components/Inputs/AppealResultInput'
import { AppealReplyError } from 'data/domain/appeals'
import { Formik, FormikValues } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppealResponseContext } from 'states/AppealResponseState'
import styled from 'styled-components'
import TextArea from '../Formik/Inputs/textarea'
import { Box } from '@material-ui/core'
import TextEditor from './TextEditor'
import InputNumber from 'components/Inputs/InputNumber'
import AutosaveFeedback, { IFeedBackStatus } from 'components/AutoSaveFeedBack'
import { API_HOST } from 'consts'
import axios from 'axios'
import { get } from 'lodash'

type AppealResponseFormProps = {
  appeal?: any
  providerCodename?: string
  className?: string
  onChange?: any
}

const AppealResponseForm = ({ className, appeal }: AppealResponseFormProps) => {
  const [savingStatus, setSavingStatus] = useState<IFeedBackStatus>('pending')
  const [convertedText, setConvertedText] = useState('')
  const history = useHistory()
  const {
    handleResponseAppeal,
    responseAppealLoading,
    responseAppealError
  } = useContext(AppealResponseContext)

  const handleSubmit = (values: FormikValues) => {
    handleResponseAppeal({
      response: convertedText,
      result: values.result?.id
    })
  }

  const saveNewAnswerValue = useCallback(
    (value: number, evt?: any) => {
      if (value) {
        const totalItem = appeal?.answerValue?.totalItem
        const applicationId = appeal?.application?.id
        const answerId = appeal?.answerValue?.answerId
        setSavingStatus('saving')
        if (value > totalItem) {
          toast.error(
            `Não é permitido o envio de uma nota maior que o total deste objeto de aprendizagem. Valor máximo ${totalItem} permitido`
          )
          setSavingStatus('error')
        } else {
          axios
            .patch(
              `${API_HOST}/v1/applications/${applicationId}/answers/${answerId}/update_answer_value`,
              {
                updated_value: value
              }
            )
            .then(() => {
              setSavingStatus('ok')
            })
            .catch((e) => {
              setSavingStatus('error')

              if (e?.response?.status === 400) {
                const errorMessage =
                  get(e.response.data, 'value.0') ||
                  get(e.response.data, 'nonFieldErrors.0') ||
                  'Ocorreu um erro inesparado.'

                toast.error(errorMessage)
              }
            })
        }
      }
    },
    [appeal]
  )

  useEffect(() => {
    if (responseAppealError) {
      toast.error(responseAppealError.nonFieldErrors)
    }
  }, [responseAppealError])

  const itemCategory = appeal?.item?.category

  return (
    <div className={className}>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({
          setFieldError,
          handleSubmit,
          errors
        }: {
          errors: AppealReplyError
          handleSubmit: () => void
          setFieldError: (field: string, errorMsg: string) => void
        }) => (
          <>
            <Box className="box-header">
              <div className="main-response">
                <h3>Resposta para Recurso</h3>
                <div className="input-filed">
                  <div>
                    <p>Sua decisão</p>
                    <AppealResultInput />
                  </div>
                  {itemCategory === 'FREE_RESPONSE' &&
                  appeal?.answerValue !== null ? (
                    <>
                      <div style={{ marginLeft: '1rem' }}>
                        <p>Nova nota</p>
                        <InputNumber
                          name="grade"
                          placeholder={`Nota atual: ${appeal?.answerValue?.currentValue}`}
                          onChange={(value: any, evt: any) =>
                            saveNewAnswerValue(value, evt)
                          }
                        />
                      </div>
                      <AutosaveFeedback status={savingStatus} />
                    </>
                  ) : (
                    ''
                  )}
                  {responseAppealError?.response &&
                    errors?.result &&
                    setFieldError('result', responseAppealError.result)}
                </div>
                <div className="response-for-candidate">
                  <div className="defer-response">
                    <p>Sua resposta</p>
                  </div>
                  <TextEditor
                    value={convertedText}
                    onChange={setConvertedText}
                  />
                </div>
                {responseAppealError?.response &&
                  errors?.response &&
                  setFieldError('response', responseAppealError.response)}
                <div className="buttons">
                  <Button
                    type="submit"
                    action="primary"
                    onClick={() => handleSubmit()}
                    disabled={responseAppealLoading}
                  >
                    {responseAppealLoading ? 'Enviando..' : 'Responder'}
                  </Button>
                  <Button
                    action="error"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            </Box>
          </>
        )}
      </Formik>
    </div>
  )
}

export default styled(AppealResponseForm)`
  display: grid;
  gap: 0.6rem;
  span {
    color: black;
    font-weight: bold;
  }
  margin: 5px;
  ${TextArea} {
    color: black;
    resize: none;
  }
  h4 {
    font-size: 16px;
  }
  .input-filed {
    display: flex;
    align-items: center;
    p {
      color: ${(props) => props.theme.clientColors.primary};
      font-size: 1.3rem;
      position: relative;
      font-weight: 600;
    }
    ${AppealResultInput} {
      width: 250px;
    }
    ${InputNumber} {
      margin-bottom: 2.5rem;
      width: 150px;
      height: 12px;
    }
  }
  .main-response {
    display: grid;
  }
  .defer-response {
    color: ${(props) => props.theme.clientColors.primary};
    font-size: 1.3rem;
    position: relative;
    font-weight: 600;
  }
  .response-for-candidate {
    display: grid;
    margin-top: -9px;
  }
  .box-header {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.103);
    padding: 1rem;
  }
  .buttons {
    display: flex;
    justify-content: flex-start;
    gap: 0.5em;
    margin-top: 4.5rem;
    & > * {
      padding: 10px 30px;
      margin: 2px 0px 0px 0px;
      font-size: 15px;
    }
  }
`
