import React from 'react'
import styled from 'styled-components'
import Letter from './Letter'
import Html from 'components/Html'

type Props = {
  letter: string
  content: string
  disableItemTextHighlight?: boolean
  className?: string
}
const AlternativeContent = ({
  className,
  letter,
  disableItemTextHighlight,
  content
}: Props) => (
  <div className={className}>
  <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
    <div style={{
      width: '35px',
      height: '35px',
      border: '1px solid #CFD2D9',
      borderRadius:'20px',
      backgroundColor:'fff',
      marginRight: '1rem',
      display:'flex',
      alignItems:'center',
      justifyItems:'center',
    }}>
      <Letter letter={letter} />
    </div>
    <Html noHighlight={disableItemTextHighlight}>{content}</Html>
  </div>
  </div>
)

export default styled(AlternativeContent)`
  display: flex;
  line-height: 1.5rem;
  vertical-align: text-bottom;
  color: black;
`
