import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback } from 'react'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'

type QuestionProps = {
  params?: any
  name?: string
  examId?: any
  label?: string
}

const QuestionInput = ({ params, examId, ...props }: QuestionProps) => {
  // eslint-disable-next-line
  const fetchQuestions = useCallback(
    debounce(async (search) => {
      if (!examId) return []

      const response = await axios.get(
        `${API_HOST}/v1/exams/${examId}/exam_item_by_position`,
        {
          params: {
            search,
            ...params
          }
        }
      )
      return response.data
    }, 500),
    [params, examId]
  )

  return (
    <InputAsync
      key={JSON.stringify({ ...params, examId })}
      defaultOptions
      loadOptions={fetchQuestions}
      noOptionsMessage={() =>
        examId
          ? 'Nenhum resultado encontrado'
          : 'Selecione uma atividade para filtrar a questão'
      }
      name="status"
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.verboseName || d.position}
      {...props}
    />
  )
}

export default QuestionInput
