import * as faceapi from 'face-api.js'
import { FACE_API_MODELS_HOST } from '../consts'

class FaceDetectionManager {
  private faceAPI: any
  private callback: Function
  private intervalId: number

  constructor() {
    faceapi.nets.tinyFaceDetector.loadFromUri(FACE_API_MODELS_HOST)
    this.faceAPI = faceapi
    this.callback = () => {}
  }

  setCallBack(customCallback: Function) {
    this.callback = customCallback
  }

  getActivity(numFaces: number) {
    if (numFaces === 1) {
      return ''
    }
    if (numFaces > 1) {
      return 'MULTIPLE'
    }
    if (numFaces === 0) {
      return 'MISSING'
    }
  }

  disable() {
    clearInterval(this.intervalId)
  }

  startDetection(mediaStream: MediaStream) {
    const video = document.createElement('video')
    video.srcObject = mediaStream
    video.autoplay = true
    video.addEventListener('play', () => {
      video.muted = true
      this.intervalId = setInterval(async () => {
        this.faceAPI
          .detectAllFaces(video, new this.faceAPI.TinyFaceDetectorOptions())
          .then((detections: Array<any>) => {
            this.callback(this.getActivity(detections.length))
          })
          .catch((e: any) => console.error(e))
      }, 1000)
    })
  }
}

const FaceDetection = new FaceDetectionManager()
export default FaceDetection
