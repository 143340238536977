import firebase from 'infra/firebase'
import { useState, useRef, useEffect } from 'react'
import { RollbarErrorTracking } from 'infra/rollbar'

type Query<T> = firebase.firestore.Query<T>
type DocumentChange<T> = firebase.firestore.DocumentChange<T>
type DocumentData = firebase.firestore.DocumentData

/**
 * Listen to modifications in a firebase query.
 *
 * @param queryFn - Function that returns a Firebase query.
 * @param onError - Function that handles errors. If not provided,
 * errors will be reported to Rollbar.
 *
 * @return collection - Query result, automatically updated on document change.
 */
function useFirebaseCollection<T extends { id: string }>(
  queryFn: () => Query<DocumentData>,
  // If an error handler is not provided, report errors to Rollbar
  onError: (error: Error) => void = RollbarErrorTracking.logError
) {
  const [collection, _setCollection] = useState<T[]>([])
  const collectionRef = useRef(collection)
  const setCollection = (data: any) => {
    collectionRef.current = data
    _setCollection(data)
  }

  const handleDocChange = (
    _collection: T[],
    doc: DocumentChange<DocumentData>
  ) => {
    const docTypeHandler = {
      added: () => {
        if (!_collection.find((d) => d.id === doc.doc.id)) {
          const data = doc.doc.data() as T
          return [..._collection, { id: doc.doc.id, ...data }]
        }
        return _collection
      },
      removed: () => {
        return _collection.filter((d) => d.id !== doc.doc.id)
      },
      modified: () => {
        const data = doc.doc.data() as T
        return _collection.map((d) =>
          d.id === doc.doc.id ? { ...d, ...data } : d
        )
      }
    }[doc.type]
    return _collection && docTypeHandler ? docTypeHandler() : _collection
  }

  useEffect(() => {
    const query = queryFn()
    if (!query) {
      return
    }
    const unsubscribe = queryFn().onSnapshot(function (snapshot) {
      let _collection = [...collectionRef.current]
      snapshot.docChanges().forEach(function (doc) {
        _collection = handleDocChange(_collection, doc)
      })
      setCollection(_collection || [])
    }, onError)

    return unsubscribe
  }, [queryFn, onError])

  return { collection }
}

export default useFirebaseCollection
