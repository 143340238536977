import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'states/AuthState'
import { API_HOST } from '../consts'
import { PreferenceName } from '../types'

function usePreference<T>(preferenceName: PreferenceName): T | undefined {
  const { hasGroup, user } = useContext(AuthContext)
  const [preference, setPreference] = useState<T | undefined>(undefined)

  useEffect(() => {
    if (hasGroup('RESPONSIBLE') && user.groups.length == 1) {
      return
    }
    axios
      .get<T>(`${API_HOST}/preferences/global/${preferenceName}/`)
      .then((response) => {
        setPreference(response.data)
      })
      .catch(() => {
        setPreference(undefined)
      })
  }, [preferenceName, hasGroup])

  return preference
}

export default usePreference
