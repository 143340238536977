export const walkOnHTMLElements = (
  element: Element | HTMLElement,
  handleCallback: (element: HTMLElement) => undefined | void
) => {
  if (element) {
    for (const child of element.children) {
      walkOnHTMLElements(child, handleCallback)
      handleCallback(child as HTMLElement)
    }
  }
}
