import styled from 'styled-components'
import Modal from '../Modal/ModalRootModal'
import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import ModalFooter from 'components/Modal/ModalFooter'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Axios from 'axios'
import { API_HOST } from 'consts'

interface ReportStudentPermissionsModalProps {
  className?: string
  collection?: any
  collectionId?: any
  hideModal?: any
  fetchCollection?: any
}

export const initialValues = {
  answerSheetDisclosed: false,
  postAnswerVisualization: false,
  postItemNavigation: false,
  postAlternativeExplanationVisualization: false,
  showAnswerSheetOverview: false,
  showCandidateGrade: false,
  canDiscloseReport: false,
  showAnswerSheetToCandidate: false
}

const ReportStudentPermissionsModal = ({
  className,
  collection,
  collectionId,
  hideModal,
  fetchCollection
}: ReportStudentPermissionsModalProps) => {
  const { t } = useTranslation()

  const sendPermission = (value: any) => {
    Axios.patch(
      `${API_HOST}/v1/collections/${collectionId}/update_collection_permissions`,
      value
    )
      .then((res) => {
        console.log(res)
        toast.success(res.data)
        hideModal()
      })
      .catch((err) => {
        return (
          toast.error('Não foi possível alterar as permissões.'),
          console.error(err)
        )
      })
  }

  useEffect(() => {
    fetchCollection()
  }, [])

  return (
    <>
      <Modal title={t('Student Permissions')}>
        <div className={className}>
          <Formik
            initialValues={collection || initialValues}
            onSubmit={(values) => sendPermission(values)}
          >
            {(formik) => (
              <Form className="form">
                <div className="checks-container">
                  <Field
                    type="checkbox"
                    className="checks"
                    name="answerSheetDisclosed"
                  />
                  Divulgar Gabarito
                </div>
                <div className="checks-container">
                  <Field
                    type="checkbox"
                    className="checks"
                    name="postAnswerVisualization"
                  />
                  Visualização de respostas após a prova
                </div>
                <div className="checks-container">
                  <Field
                    type="checkbox"
                    className="checks"
                    name="postItemNavigation"
                  />
                  Navegar entre itens após a prova
                </div>
                <div className="checks-container">
                  <Field
                    type="checkbox"
                    className="checks"
                    name="postAlternativeExplanationVisualization"
                  />
                  Visualização da justificativa da alternativa
                </div>
                <div className="checks-container">
                  <Field
                    type="checkbox"
                    className="checks"
                    name="showAnswerSheetOverview"
                  />
                  Mostrar total de acertos
                </div>
                <div className="checks-container">
                  <Field
                    type="checkbox"
                    className="checks"
                    name="showCandidateGrade"
                  />
                  Mostrar ao candidato, a nota obtida no item
                </div>
                <div className="checks-container">
                  <Field
                    type="checkbox"
                    className="checks"
                    name="canDiscloseReport"
                  />
                  Exibir boletim ao Estudante
                </div>
                <div className="checks-container">
                  <Field
                    type="checkbox"
                    className="checks"
                    name="showAnswerSheetToCandidate"
                  />
                  Habilitar acesso do boletim ao candidato
                </div>

                <ModalFooter>
                  <Button
                    action="primary"
                    className="send-button"
                    type="submit"
                    onClick={() => fetchCollection()}
                    disabled={formik.isSubmitting}
                  >
                    {t('Save')}
                  </Button>
                  <Button
                    action="secondary"
                    className="cancel-button"
                    type="button"
                    onClick={() => hideModal()}
                  >
                    {t('Cancel')}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default styled(ReportStudentPermissionsModal)`
  .form {
    display: grid;
  }

  .checks {
    width: 20px;
    height: 95%;
    margin-right: 10px;
  }

  .checks-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #eeecec;
    padding: 15px 0px 15px 8px;
  }

  .title-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .send-button {
    border-radius: 70px;
  }

  .cancel-button {
    background-color: #e4e4e4;
    border-radius: 70px;
    border-color: #e4e4e4;
    color: #878787;

    &:hover {
      background-color: #ffffff;
      color: #878787;
    }
  }
`
