import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Separator from '../ExamDashboardComponents/Separator'

type ModalHeaderProps = {
  onClose?: () => void
  title: string
  className?: string
  subtitle?: string
}

const ModalHeader = ({
  onClose,
  title,
  subtitle,
  className
}: ModalHeaderProps) => {
  return (
    <>
      <div className={className}>
        <h2>{title}</h2>
        <span>{subtitle}</span>
        {onClose && (
          <Button action="unstyled" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </div>
      <Separator />
    </>
  )
}

export default styled(ModalHeader)`
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h2 {
    margin: 0;
  }

  span {
    font-size: 18px;
  }
`
