import React, { useEffect, useContext } from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import useQuery from '../hooks/useQuery'
import { AuthContext } from '../states/AuthState'
import db from '../db'
import AppealState from 'states/AppealState'
import PrivateRoute from 'components/PrivateRoute'
import AppealCreateForm from './AppealCreateForm'
import AppealEditForm from './AppealViewForm'

const Appeal = () => {
  const match = useRouteMatch()
  const query = useQuery()
  const { user } = useContext(AuthContext)
  useEffect(() => {
    if (query.get('clear_database')) {
      db.delete().then(() => {
        window.location.href = '/'
      })
    }
  }, [query, user])

  return (
    <AppealState>
      {() => (
        <Switch>
          <PrivateRoute
            path={match.path + '/new'}
            component={AppealCreateForm}
          />
          <PrivateRoute
            path={match.path + '/:appealId/'}
            component={AppealEditForm}
          />
        </Switch>
      )}
    </AppealState>
  )
}

export default Appeal
