import { useState } from 'react'

const usePopover = (): {
  anchorEl: Element
  open: boolean
  handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  handleClose: () => void
} => {
  const [anchorEl, setAnchorEl] = useState<Element>(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = !!anchorEl

  return { anchorEl, open, handleClick, handleClose }
}

export default usePopover
