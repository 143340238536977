import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback } from 'react'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'

const AppealStatusInput = ({
  params,
  ...props
}: {
  params?: any
  basic?: boolean
  name?: string
  label?: any
}) => {
  // eslint-disable-next-line
  const fetchAppealStatus = useCallback(
    debounce(async (search) => {
      const response = await axios.get(`${API_HOST}/v1/appeals-status`, {
        params: {
          search,
          ...params
        }
      })
      return response.data
    }, 500),
    [params]
  )

  return (
    <InputAsync
      key={JSON.stringify(params)}
      defaultOptions
      loadOptions={fetchAppealStatus}
      name="status"
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.description}
      {...props}
    />
  )
}

export default AppealStatusInput
