import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ApplicationContext } from '../states/ApplicationState'
import ConfigContext from 'contexts/ConfigContext'
import Button from './Button'
import { Box } from '@material-ui/core'

type RemainingTimeProps = {
  className?: string
  secondsToTimeout: number | undefined
  initial: number | undefined
  allowHideRemainingTime?: boolean
  title?: string
}

const RemainingTime = ({
  className,
  initial,
  title,
  secondsToTimeout,
  allowHideRemainingTime = true
}: RemainingTimeProps) => {
  const [hide, setHide] = useState(false)
  const { showSecondsInRemainingTime } = useContext(ConfigContext)
  const { handleTimeout } = useContext(ApplicationContext)
  const { t } = useTranslation()

  if (secondsToTimeout === undefined || !initial) {
    return (
      <span className={className}>
        <span>{t('Unavailable')}</span>
      </span>
    )
  }

  return (
    <Countdown
      date={initial + secondsToTimeout * 1000}
      onComplete={handleTimeout}
      renderer={({ ...time }) => {
        let label = ''

        if (time.days) {
          label += `${time.days}d `
        }

        label += time.hours.toString().padStart(2, '0')
        label += ':'
        label += time.minutes.toString().padStart(2, '0')

        if (showSecondsInRemainingTime) {
          label += ':'
          label += time.seconds.toString().padStart(2, '0')
        }

        const alertClass =
          time.minutes <= 10 && time.hours === 0 && time.days === 0
        const warningClass =
          time.minutes <= 2 && time.hours === 0 && time.days === 0

        return (
          <span className={className}>
            <span className="title">{title}</span>
            <span
              className={`${alertClass && 'alert'}${
                warningClass ? '-blink' : ''
              }`}
            >
              {hide ? '-' : label}
            </span>
            <Box marginTop="10px">
              {allowHideRemainingTime && (
                <Button action="icon" onClick={() => setHide(!hide)}>
                  <FontAwesomeIcon icon={hide ? 'eye' : 'eye-slash'} />
                </Button>
              )}
            </Box>
          </span>
        )
      }}
    />
  )
}

export default styled(RemainingTime)`
  width: 90px;
  color: ${(props) => props.theme.colors.white};

  .title {
    display: block;
  }

  .alert {
    color: red;

    &-blink {
      color: red;
      animation: blink 1s infinite;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media (max-width: 600px) {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 7px;
    }
  }
`
