import styled from 'styled-components'

export const DropDownStyle = styled.div`
  transition: all 0.3s;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  :hover {
    background: ${(props) => props.theme.clientColors.secondary};
  }
`

export const DropDownFixed = styled.div`
  background: ${(props) => props.theme.clientColors.secondary};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`
