import styled from 'styled-components'

const Tag = styled.span`
  background-color: ${(props) => props.color};
  padding: 5px 0.8rem;
  font-size: 14px;
  border-radius: 10px;
  color: white;
`

export default Tag
