import AgoraRTC from 'agora-rtc-sdk'
import { AGORA_API_KEY, CLOUD_RECORDING_LAMBDA_URL } from './consts'
import { IUser } from 'types'
import { RollbarErrorTracking } from 'infra/rollbar'
import Axios from 'axios'
import { getEncodingSettings, setAgoraClientListenerLog } from 'infra/streaming'
import { StreamingLog } from 'data/domain/streaming-log'
import { addStreamingLog } from 'data/apis/streaming-logs'

class VideoStreamingManager {
  private stream: any
  private client: any
  private user: IUser
  private isRecording = false
  private onError: (error: Error) => void
  private onSuccess: () => void

  public setOnError = (onErrorCallback: (error: Error) => void) => {
    this.onError = onErrorCallback
  }

  public setOnSuccess = (onSuccessCallback: () => void) => {
    this.onSuccess = onSuccessCallback
  }

  public startStreamingForUser(user: IUser, stream: any) {
    if (!user || this.isRecording) {
      return
    }
    this.isRecording = true
    this.user = user

    const videoTracks = stream.getVideoTracks()
    const audioTracks = stream.getAudioTracks()

    this.stream = AgoraRTC.createStream({
      streamID: user.id,
      audio: true,
      video: true,
      screen: false,
      videoSource: videoTracks.length && videoTracks[0],
      audioSource: audioTracks.length && audioTracks[0]
    })

    this.client = AgoraRTC.createClient({ mode: 'live', codec: 'h264' })
    try {
      this.stream.init(
        () => {
          this.stream.setVideoEncoderConfiguration(getEncodingSettings())
          this.client.init(
            AGORA_API_KEY,
            () => {
              this.stream.on('accessDenied', (evt: any) => {
                addStreamingLog({
                  action: 'AGORA_STREAM_ACCESS_DENIED',
                  message: JSON.stringify(evt),
                  candidateId: user?.id
                } as StreamingLog)
              })
            },
            (agoraClientInitError) => {
              addStreamingLog({
                action: 'AGORA_CLIENT_INIT_ERROR',
                message: JSON.stringify(agoraClientInitError),
                candidateId: user?.id
              } as StreamingLog)
              RollbarErrorTracking.logError(agoraClientInitError)
              this.onError(agoraClientInitError)
            }
          )
        },
        (agoraStreamInitError) => {
          addStreamingLog({
            action: 'AGORA_STREAM_INIT_ERROR',
            message: JSON.stringify(agoraStreamInitError),
            candidateId: user?.id
          } as StreamingLog)
          RollbarErrorTracking.logError(agoraStreamInitError)
          this.onError(agoraStreamInitError)
        }
      )
    } catch (e) {
      addStreamingLog({
        action: 'AGORA_INIT_ERROR',
        message: e?.message,
        candidateId: user?.id
      } as StreamingLog)
    }
    this.onSuccess()
  }

  public destroy() {
    this.isRecording = false
    try {
      this.client && this.client.unpublish(this.stream)
      this.stream && this.stream.stop()
      this.stream && this.stream.close()
    } catch (e) {
      console.log(e)
    }
  }

  async startCloudRecording(roomId: number) {
    console.log('STTART')
    console.log(CLOUD_RECORDING_LAMBDA_URL)
    try {
      const r = await Axios.post(CLOUD_RECORDING_LAMBDA_URL, {
        userId: this.user.id,
        roomId: roomId,
        provider: this.user.provider.codename
      })
      console.log(r)
    } catch (e) {
      console.error(e.data)
      RollbarErrorTracking.logError(e)
    }
  }

  async unsubscribe() {
    try {
      this.client.leave()
    } catch {}
  }

  async handleStream(channelName: string) {
    if (!AGORA_API_KEY) {
      return
    }

    const agoraStream = this.stream
    const agoraClient = this.client
    const user = this.user
    try {
      // If agora didn't initialize for some reason,
      // give up on the connection
      if (!agoraStream || !agoraClient) {
        return console.error('Tried to stream with no stream')
      }
      // await this.startCloudRecording(roomId)
      agoraClient.join(
        null,
        channelName,
        user.id,
        () => {
          agoraClient.publish(agoraStream)
          setAgoraClientListenerLog(agoraClient, user?.id, 'STUDENT')
          this.onSuccess()
        },
        (agoraClientJoinError) => {
          addStreamingLog({
            action: 'AGORA_CLIENT_JOIN_ERROR',
            message: JSON.stringify(agoraClientJoinError),
            candidateId: user?.id
          } as StreamingLog)
          RollbarErrorTracking.logError(agoraClientJoinError)
          this.onError(agoraClientJoinError)
        }
      )
    } catch (e) {}
  }
}

const streamingManager = new VideoStreamingManager()
export default streamingManager
