import axios from 'axios';
import debounce from 'debounce-promise';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_HOST } from '../../consts';
import InputAsync from './InputAsync';

const UserInput = ({
  basic,
  params,
  label = 'User',
  ...props
}: {
  params?: any;
  basic?: boolean;
  label?: string;
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(5);

  const fetchUsers = useCallback(
    debounce(async (search) => {
      const response = await axios.get(
        `${API_HOST}/v1/users${basic ? '/basic' : ''}`,
        {
          params: {
            search,
            page_size: pageSize,
            ...params,
          },
        }
      );
      return response.data.results;
    }, 500),
    [params, pageSize]
  );

  const handleInputChange = (inputValue: string) => {
 
    if (inputValue) {
      setPageSize(10000);
    } else {

      setPageSize(5);
    }
  };

  return (
    <InputAsync
      key={JSON.stringify(params)}
      defaultOptions
      loadOptions={fetchUsers}
      label={t(label)}
      name="user"
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.name}
      onInputChange={handleInputChange}
      {...props}
    />
  );
};

export default UserInput;