import { useField, useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'

type InputProps = {
  onChange?: Function
  label?: string
  className?: string
  tooltip?: string
  required?: boolean
  type?: string
  name: string
}

const Input = ({
  label,
  className,
  onChange,
  required,
  name,
  ...rest
}: InputProps) => {
  const [field, meta] = useField(name)
  const { setFieldValue, submitCount } = useFormikContext()
  const error = submitCount > 0 ? meta.error : undefined

  return (
    <div className={className}>
      <div className={error ? 'input-error red' : ''}>
        <label
          htmlFor={field.name}
          className={`
            input-label-style
            ${error ? 'red' : 'dim'}
            ${required ? 'input-required' : ''}
          `}
        >
          {label}
        </label>
        <input
          {...field}
          {...rest}
          onChange={(event) => {
            setFieldValue(field.name, event.target.value)
            if (onChange) {
              onChange(event.target.value)
            }
          }}
        />
      </div>
      {error && <span className="input-helper red">{error}</span>}
    </div>
  )
}

export default styled(Input)`
  label {
    font-weight: bold;
  }

  .input-required {
    &:after {
      content: '*';
    }
  }

  input {
    width: 100%;
    padding: 8px 8px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.grayMedium};
    margin-top: 5px;
    outline: none;
    font-size: 14px;

    &:focus {
      box-shadow: none;
    }
    &:focus-within {
      border: 2px solid ${(props) => props.theme.clientColors.secondary};

      &:after {
        display: none;
      }
    }
  }
`
