import { Formik } from 'formik'
import moment from 'moment'
import React, { ReactNode, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import swal from 'sweetalert'
import { ModalContext } from '../../states/ModalState'
import devices from '../../utils/devices'
import Button from '../Button'
import MaskedInput from '../Inputs/MaskedInput'
import RadioInput from '../Inputs/RadioInput'
import RadioInputGroup from '../Inputs/RadioInputGroup'
import ModalFooter from '../Modal/ModalFooter'
import Modal from '../Modal/ModalRootModal'
import { get, parseInt } from 'lodash'
import QuestionMark from 'components/QuestionMark'
import Checkbox from 'components/Inputs/Checkbox'

export type FormValues = {
  startDate: string
  startHour: string
  endDate: string
  endHour: string
  minutes: string
  strategy: string
  applyToCollection: boolean
}

export type SubmitValues = {
  startTime: string
  endTime: string
  maxDuration: string
  deleteExisting: boolean
  applyToCollection: boolean
}

export interface SetTimeWindowModalProps {
  className?: string
  children?: ReactNode
  onSubmit: (values: SubmitValues) => Promise<any>
  applyToCollectionOption?: boolean
}

const SetTimeWindowModal = ({
  children,
  className,
  onSubmit,
  applyToCollectionOption
}: SetTimeWindowModalProps) => {
  const { t } = useTranslation()
  const { hideModal } = useContext(ModalContext)
  const [duration, setDuration] = useState<number>()

  const tryParseDate = (date: string) => {
    return moment(date, 'DD/MM/YYYY').isValid()
  }

  const tryParseHour = (hour: string) => {
    return moment(hour, 'hh:mm').isValid()
  }

  const validateDate = (value: string) => {
    return tryParseDate(value)
  }

  const validateHour = (value: string) => {
    return tryParseHour(value)
  }

  const validate = (values: FormValues) => {
    const invalidMsg = t('Invalid format.')
    const requiredMsg = t('This field is required.')
    const errors: { [key: string]: string } = {}
    if (!validateDate(values.startDate)) {
      errors.startDate = invalidMsg
    }
    if (!validateHour(values.startHour)) {
      errors.startHour = invalidMsg
    }
    if (!validateDate(values.endDate)) {
      errors.endDate = invalidMsg
    }
    if (!validateHour(values.endHour)) {
      errors.endHour = invalidMsg
    }
    if (!values.strategy) {
      errors.strategy = requiredMsg
    }
    return errors
  }

  const saveExamDates = async (values: FormValues) => {
    const minutesAux = duration.toString()

    const payload: SubmitValues = {
      startTime: `${values.startDate} ${values.startHour}`,
      endTime: `${values.endDate} ${values.endHour}`,
      maxDuration: minutesAux,
      deleteExisting: values.strategy === 'delete_existing',
      applyToCollection: values.applyToCollection
    }

    try {
      await onSubmit(payload)
      swal({
        icon: 'success',
        title: t('Data definida com sucesso')
      })
      setTimeout(hideModal, 1000)
    } catch (err) {
      let title = t('Could not set date. Check the data and try again')
      const errorMessage = get(err, 'response.data', false)
      if (errorMessage) {
        title = t(errorMessage[0])
      }
      swal({
        icon: 'error',
        title
      })
    }
  }

  const getTimeInputs = () => {
    const startTimeInput = document.getElementById(
      'startHour'
    ) as HTMLInputElement
    const endTimeInput = document.getElementById('endHour') as HTMLInputElement
    return [startTimeInput, endTimeInput]
  }

  const formatTime = (time: string) => {
    const [hour, minutes] = time.split(':')
    return parseInt(hour) * 60 + parseInt(minutes)
  }

  const getDateInputs = () => {
    const startDateInput = document.getElementById(
      'startDate'
    ) as HTMLInputElement
    const endDateInput = document.getElementById('endDate') as HTMLInputElement
    return [startDateInput, endDateInput]
  }

  const formatDate = (date: string) => {
    const splitedDate = date.split('/')
    const formatedDate = new Date(
      +splitedDate[2],
      +splitedDate[1] - 1,
      +splitedDate[0]
    )
    return formatedDate
  }

  const getBeginAndEndTime = () => {
    const [startTimeInput, endTimeInput] = getTimeInputs()
    const startTime = startTimeInput.value
    const endTime = endTimeInput.value
    return [formatTime(startTime), formatTime(endTime)]
  }

  const getStartAndEndDates = () => {
    const [startDateInput, endDateInput] = getDateInputs()
    return [formatDate(startDateInput.value), formatDate(endDateInput.value)]
  }

  const getDaysDifferenceInMinutes = () => {
    const [startDate, endDate] = getStartAndEndDates()
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const daysDifference =
      (endDate.getTime() - startDate.getTime()) / oneDayInMilliseconds
    return daysDifference * 1440
  }

  const calculateDuration = () => {
    const daysDifferenceInMinutes = getDaysDifferenceInMinutes()
    const [beginTime, endTime] = getBeginAndEndTime()
    return endTime - beginTime + daysDifferenceInMinutes
  }

  const updateDuration = () => {
    const newDuration = calculateDuration()
    setDuration(newDuration)
  }

  const handleFormChange = (event) => {
    if (event.target.name === 'duration') {
      return
    }
    updateDuration()
  }

  const today = new Date()

  const addZeroToSingleData = (num: number) => {
    return num.toString().padStart(2, '0')
  }

  const formatFullYear = (date: Date) => {
    return `${addZeroToSingleData(date.getDate())}/${addZeroToSingleData(
      date.getMonth() + 1
    )}/${date.getFullYear()}`
  }

  const formatFullTime = (time: Date) => {
    return `${addZeroToSingleData(time.getHours())}:${addZeroToSingleData(
      time.getMinutes()
    )}`
  }

  const initialValues: FormValues = {
    startDate: `${formatFullYear(today)}`,
    startHour: `${formatFullTime(today)}`,
    endDate: '',
    endHour: '',
    minutes: '',
    strategy: 'delete_existing',
    applyToCollection: false
  }

  return (
    <Modal
      className={className}
      title={t('Definition of deadline for activity')}
      onClose={hideModal}
    >
      <Formik
        onSubmit={saveExamDates}
        initialValues={initialValues}
        validate={validate}
      >
        {(formik) => (
          <form
            onChange={(e) => handleFormChange(e)}
            onSubmit={formik.handleSubmit}
          >
            <>
              <div className="modal-body-wrapper">
                <label>{t('Start time')}</label>
                <MaskedInput
                  name="startDate"
                  mask="99/99/9999"
                  placeholder={t('Date')}
                />
                <MaskedInput name="startHour" mask="99:99" placeholder="Hour" />
                <label>{t('End time')}</label>
                <MaskedInput
                  name="endDate"
                  mask="99/99/9999"
                  placeholder={t('Date')}
                />
                <MaskedInput name="endHour" mask="99:99" placeholder="Hora" />
                <label>{t('Duration')}</label>
                <input
                  value={!duration ? '' : duration}
                  name="duration"
                  onChange={(e) => setDuration(parseInt(e.target.value))}
                />
              </div>
              <div className="row-2">
                <RadioInputGroup
                  name="strategy"
                  label={
                    <>
                      {t('Existing dates')}{' '}
                      <QuestionMark
                        title={t(
                          'Choose if previous dates should be kept or removed'
                        )}
                      />
                    </>
                  }
                >
                  <RadioInput value="keep_existing" label={t('Keep')} />
                  <RadioInput value="delete_existing" label={t('Remove')} />
                </RadioInputGroup>
              </div>
            </>
            <ModalFooter>
              <Button
                className="button-add"
                type="submit"
                action="primary"
                disabled={formik.isSubmitting}
              >
                {t('Confirm')}
              </Button>
              <Button
                className="button-cancel"
                action="primary"
                disabled={formik.isSubmitting}
                onClick={() => hideModal()}
              >
                {t('Cancel')}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default styled(SetTimeWindowModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  width: 100%;

  .modal-body-wrapper {
    display: grid;
    grid-template-columns: 50px 100px 100px;
    align-items: center;
    column-gap: 30px;
    row-gap: 10px;
    margin-top: 24px;
    @media (max-width: ${devices.mobileL}) {
      grid-template-columns: 100%;
    }
  }

  .row-2 {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 250px 1fr;
  }
  .button-add {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  }

  .button-cancel {
    background: #e4e4e4;
    border: none;
    color: #878787;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    margin-left: 30px;
  }
  .button-cancel:hover {
    background: #c5bfbf;
    border: none;
  }

  input {
    width: 100%;
    font-size: 16px;
    padding: 5px;
    line-height: 1.5;
    background-color: white;
    border: 2px solid ${(props) => props.theme.colors.grayLight};
    border-radius: 5px;
  }

  .has-error > input {
    border: 1px solid red;
  }

  ${ModalFooter} {
    display: flex;
    justify-content: space-between;
  }

  ${RadioInputGroup} {
    /* margin-top: 10px; */
  }
`
