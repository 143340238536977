import { useField, useFormikContext } from 'formik'
import React from 'react'
import InputMask from 'react-input-mask'
import styled from 'styled-components'
import InputError from './InputError'

type MaskedInputProps = {
  label?: string
  className?: string
  placeholder?: string
  name: string
  mask: string
  maskChar?: string
}

const MaskedInput = ({
  name,
  label,
  className,
  placeholder,
  mask,
  maskChar
}: MaskedInputProps) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField({
    name
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(field.name, event.target.value)
    return field.name
  }

  return (
    <div className={className}>
      {label && <span className="input-label">{label}</span>}
      <InputMask
        id={name}
        onChange={handleChange}
        value={field.value}
        placeholder={placeholder}
        mask={mask}
        maskChar={maskChar}
        {...field}
      />
      {meta.error && meta.touched && <InputError>{meta.error}</InputError>}
    </div>
  )
}

export default styled(MaskedInput)`
  .input-label {
    font-weight: bolder;
  }

  input {
    font-size: 16px;
    padding: 5px;
    line-height: 1.5;
    background-color: white;
    border: 2px solid ${(props) => props.theme.colors.grayLight};
    border-radius: 5px;
  }
`
