import React from 'react'
import PropTypes from 'prop-types'
import { Container, Nav, Navbar, Collapse, Button, Dropdown } from 'reactstrap'
import { get } from 'lodash'
import { UserContext } from '../consts'
import DropdownControlledDrawer from './DropDownControlledDrawer'
import axios from 'axios'
import { API_HOST } from 'consts'

// import Dropdown from 'reactstrap/lib/Dropdown';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import { RepeatOneSharp } from '@material-ui/icons'

class MenuDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  state = {
    isMenuOpen: false,
    isHover: false,
    isHoverBillboard: false,
    menu: []
  }
  componentDidMount() {
    this.fetchMenu()
  }
  get shouldRenderAllMenus() {
    const { permissions } = this.props
    return get(permissions, '__all__')
  }

  toggleMenu() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
      isHover: !this.state.isHover
    })
  }
  fetchMenu() {
    axios.get(`${API_HOST}/menu/menu`).then((response) => {
      this.setState({ menu: response.data })
    })
  }
  renderMenu() {
    const { menu } = this.state
    const { drawer, leave } = this.props
    return (
      <>
        {menu &&
          menu.map((m) => (
            <DropdownControlledDrawer
              key={m.id}
              menu={m}
              drawer={drawer}
              leave={leave}
            />
          ))}
      </>
    )
  }

  handleMouseEnter = () => {
    this.setState({ isHover: true })
  }

  handleMouseLeave = () => {
    this.setState({ isHover: false })
  }

  handleMouseBillboardEnter = () => {
    this.setState({ isHoverBillboard: true })
  }

  handleMouseBillboardLeave = () => {
    this.setState({ isHoverBillboard: false })
  }

  render() {
    const { isMenuOpen, isHover, isHoverBillboard } = this.state
    const { drawer } = this.props
    return (
      <Navbar expand="md">
        <i className="fas fa-bars hamburguer" onClick={this.toggleMenu} />
        <Collapse isOpen={isMenuOpen} navbar>
          <Container>
            <ul
              style={{
                display: 'flex',
                textAlign: 'left',
                flexDirection: 'column',
                width: '110%',
                padding: '0.2rem'
              }}
            >
              {this.renderMenu()}
            </ul>
          </Container>
        </Collapse>
      </Navbar>
    )
  }
}

MenuDrawer.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.bool),
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired
            }).isRequired
          ).isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  ).isRequired
}

MenuDrawer.defaultProps = {
  permissions: {},
  menu: []
}

export default (props) => (
  <UserContext.Consumer>
    {(context) => <MenuDrawer {...context} {...props} />}
  </UserContext.Consumer>
)
