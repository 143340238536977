import { ICorrectAnswer } from 'data/domain/exams'
import React from 'react'
import { IAlternative } from 'types'
import AnswerSheetAlternative from './AnswerSheetAlternative'

type Props = {
  alternatives: IAlternative[]
  givenAnswer: string
  bulkAnswer: any[]
  correctAnswer: string
  correctBulkAnswer: ICorrectAnswer[]
  isCancelled: boolean
  postAlternativeExplanationVisualization: boolean
}

const AnswerSheetAlternatives = ({
  alternatives,
  correctBulkAnswer,
  isCancelled,
  bulkAnswer,
  postAlternativeExplanationVisualization
}: Props) =>
  alternatives?.map((alternative) => (
    <>
      <AnswerSheetAlternative
        key={alternative.id}
        letter={alternative.letter}
        content={alternative.content}
        explanation={alternative.explanation}
        isPicked={bulkAnswer?.find((x) => x?.id === alternative?.id)}
        isCorrect={
          !!correctBulkAnswer?.find((x) => x?.letter === alternative.letter)
        }
        isCancelled={isCancelled}
        postAlternativeExplanationVisualization={
          postAlternativeExplanationVisualization
        }
      />
    </>
  )) || null

export default AnswerSheetAlternatives
