import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Card from './Card/Card'
import CardListToolbar from './CardListToolbar'

type CardListProps = {
  children: ReactNode
  className?: string
}

const CardList = ({ children, className }: CardListProps) => {
  return <div className={className}>{children}</div>
}

export default styled(CardList)`
  ${Card} {
    margin-bottom: 18px;
    border-radius: 16px;
    box-shadow: 0px 1px 4px rgba(58, 58, 58, 0.25);
  }

  .react-loading-skeleton {
    margin-bottom: 15px;
  }

  ${CardListToolbar} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
`
