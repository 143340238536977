import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ChromeTutorial = () => (
  <p>
    Na barra de endereços, a esquerda, procure por{' '}
    <FontAwesomeIcon icon="info-circle" />.
  </p>
)

export default ChromeTutorial
