import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionTypeBase } from 'react-select'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'

interface CollectionInputProps extends OptionTypeBase {
  params?: any
  ordering?: any
}

const CollectionInput = ({ params, ...props }: CollectionInputProps) => {
  const { t } = useTranslation()

  // eslint-disable-next-line
  const fetchCollections = useCallback(
    debounce(async (search, ordering) => {
      const response = await axios.get(`${API_HOST}/v1/collections`, {
        params: {
          search,
          ...params,
          ordering: '-start_time'
        }
      })
      return response.data.results
    }, 500),
    [params]
  )

  return (
    <InputAsync
      key={JSON.stringify(params)}
      defaultOptions
      loadOptions={fetchCollections}
      label={t('Collection')}
      name="collection"
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.name}
      {...props}
    />
  )
}

export default CollectionInput
