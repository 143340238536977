import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

type VideoIconProps = {
  className?: string
  pulse?: boolean
  type: string
  icon: IconProp
  onClick?: () => void
}

const VideoIcon = ({
  className,
  pulse = false,
  icon,
  onClick
}: VideoIconProps) => {
  return (
    <div className={className} onClick={onClick} role="button">
      <div className={`icon-container ${pulse ? 'pulse' : ''} `}>
        <FontAwesomeIcon className="icon" icon={icon} />
      </div>
    </div>
  )
}

export default styled(VideoIcon)`
  .icon-container {
    margin-left: 5px;
    background: ${(props) => props.theme.videoColors[props.type]};
    border-radius: 50%;
    padding: 5px;

    &.pulse {
      animation: pulse-shadow 2s infinite;
    }
  }

  .icon {
    border-radius: 50%;
    color: white;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.85);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.85);
    }
  }

  @keyframes pulse-shadow {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.theme.videoColors[props.type]};
    }

    70% {
      box-shadow: 0 0 0 12px #ffffff00;
    }

    100% {
      box-shadow: 0 0 0 0 #ffffff00;
    }
  }
`
