import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { EmailContext } from '../../states/EmailState'
import Modal from '../Modal/Modal'
import InputSelect from '../Inputs/InputSelect'
import InputText from '../Inputs/InputText'
import EnhancedTextInput from '../Inputs/EnhancedTextInput'
import Button from '../Button'
import Tag from '../Tag'
import { IEmailTemplate, IUser } from 'types'
import { EMAIL_API_HOST, EMAIL_PARTNER_TOKEN } from '../../consts'
import { toast } from 'react-toastify'

type EmailRecipientFormProps = {
  className?: string
  users?: IUser[]
}

type FormikInitialValues = {
  template: IEmailTemplate
  subject: string
  body: string
  bcc: string
  cc: string
  users: IUser[]
}

const EmailRecipientForm = ({ className, users }: EmailRecipientFormProps) => {
  const { templates, templateLoading } = useContext(EmailContext)
  const initialValues: FormikInitialValues = {
    template: null,
    subject: '',
    body: '',
    bcc: '',
    cc: '',
    users
  }
  const history = useHistory()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  const handleTemplateSelection = (
    formik: FormikProps<FormikInitialValues>,
    value: IEmailTemplate
  ) => {
    formik.setValues({
      template: value,
      subject: value.subject,
      body: value.body,
      bcc: '',
      cc: '',
      users
    })
  }

  const emailFactory = (emailData: FormikInitialValues) => {
    return {
      body: emailData.body,
      subject: emailData.subject,
      cc: emailData.cc,
      bcc: emailData.bcc,
      typeId: get(emailData, 'template.id'),
      recipients: emailData.users.map((user) => {
        return {
          email: user.email,
          context: user
        }
      })
    }
  }

  const getEmailHeaders = () => {
    return {
      Authorization: EMAIL_PARTNER_TOKEN
    }
  }

  const handleSubmitEmail = async (
    emailData: FormikInitialValues
  ): Promise<void> => {
    try {
      const email = emailFactory(emailData)
      await axios.post(`${EMAIL_API_HOST}/v2/emails/bulk`, email, {
        headers: getEmailHeaders()
      })
      toast.success('Emails enviados com sucesso.')
      history.push('/emails/recipients')
    } catch (e) {
      toast.error(
        'Ocorreu um erro ao enviar os emails. Tente novamente mais tarde'
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => console.log(values)}
      render={(formik) => (
        <div className={className}>
          <InputSelect
            label="Template"
            loading={templateLoading}
            options={templates}
            placeholder="Selecione um template"
            name="template"
            getOptionLabel={(option) => option.description}
            getOptionValue={(option) => option.id}
            onChange={(value: IEmailTemplate) =>
              handleTemplateSelection(formik, value)
            }
          />
          <p>{t('Select a template to continue filling out the form.')}</p>
          <InputText
            name="subject"
            label="Assunto"
            disabled={!formik.values.template}
          />
          <div className="recipients-row">
            <label>Destinatário/s:</label>
            <Tag onClick={() => setShowModal(true)} color="#245f7a">
              {users.length} Estudantes selecionados
            </Tag>
          </div>
          <EnhancedTextInput
            name="body"
            label="Corpo"
            disabled={!formik.values.template}
          />
          <InputText
            name="cc"
            label="Cópia"
            disabled={!formik.values.template}
          />
          <InputText
            name="bcc"
            label="Cópia oculta"
            disabled={!formik.values.template}
          />
          <div className="submit-div">
            <Button
              action="default"
              onClick={() => history.push('/emails/recipients')}
            >
              Cancelar
            </Button>
            <Button
              action="primary"
              onClick={() => handleSubmitEmail(formik.values)}
            >
              Enviar
            </Button>
          </div>
          <Modal
            title={t('Emails')}
            cancelText="Fechar"
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
          >
            {users.map((user) => (
              <span key={user.id}>{user.email}</span>
            ))}
          </Modal>
        </div>
      )}
    />
  )
}

export default styled(EmailRecipientForm)`
  .recipients-row {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 200px;
  }
  .recipients-row span {
    margin-top: 0.4rem;
  }
  .submit-div {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }
  .submit-div button {
    margin-left: 10px;
  }
  p {
    font-size: 12px;
    font-weight: 600;
    color: #8e8e8e;
  }
`
