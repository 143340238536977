import React, { useEffect, useState } from "react";
import PreClass from "./ContainerStories";
import {
  Dialog,
} from '@material-ui/core';
import { API_HOST } from "consts";
import Axios from "axios";

const StoryComponent = ({ title, id , view }) => {
  const [data, setData] = useState(null);
  const [viewStory , setView] = useState(false)
  const [storyData, setStoryData] = useState()

  const fetchStoryData = () => {
    try {
      Axios.get(`${API_HOST}/get_story_content/?id=${id}`).then((response) => {
        const storiesContent = response.data;
        const urls = storiesContent.map(item => item.url);
        setStoryData(urls);
      })
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    fetchStoryData()
  }, [])


  const StyleContainer = {
    display: 'flex',
    flexDirection: 'column',
    width: '70px',
    height: '87px',
    justifyContent: 'space-between'
  }

  const StyleTitle = {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    textAlign: 'center'
  }

  const StyleStory = {
    width: '60px',
    height: '60px',
    border: view || viewStory ? '2px solid #ACACAC' : '2px solid #723DF4',
    borderRadius: '50%',
    cursor: 'pointer',
    overflow: "hidden",
    textAlign: 'center',
  }

  const handleClose = () => {
    setData(null);
  };

  return (
    <>
      <div style={StyleContainer}>
        <div style={StyleStory} onClick={() => {
          setData({
            opened: true,
            width: 'sm',
            component: <PreClass images={storyData && storyData} onClose={handleClose} storyId={id} storyView={view} setView = {setView} />
          });
        }}>
          <img style={{ height: '100px' }} src={storyData && storyData[0]} alt="" />
        </div>
        <div style={StyleTitle}>{title}</div>
      </div>
      <Dialog
        open={!!data}
        onClose={handleClose}
        fullScreen
        style={{background: 'none'}}
      >
        {data?.component}
      </Dialog>
    </>
  );
}

export default StoryComponent;


