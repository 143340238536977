import axios from 'axios'
import debounce from 'debounce-promise'
import { get } from 'lodash'
import { useCallback, useEffect, useReducer } from 'react'
import { IListState, ListStateAction } from '../types'

export const initialState: IListState = {
  results: [],
  count: undefined,
  numPages: 0,
  next: undefined,
  previous: undefined,
  page: 1,
  pageSize: 50,
  isLoading: true,
  fetched: false,
  hasError: false,
  filterParams: {},
  ordering: '',
  search: '',
  handleFilter: () => null,
  handlePageChange: () => null,
  handlePageSizeChange: () => null
}

const reducer = (state: IListState, action: ListStateAction): IListState => {
  switch (action.type) {
    case 'FETCH_LIST':
      return { ...state, isLoading: true, hasError: false, fetched: false }
    case 'FETCH_LIST_SUCCESS':
      return { ...state, isLoading: false, fetched: true, hasError: false, ...action.payload }
    case 'FETCH_LIST_ERROR':
      return { ...state, isLoading: false, fetched: true, hasError: true }
    case 'HANDLE_FILTER':
      return { ...state, filterParams: action.payload }
    case 'CHANGE_PAGE':
      return { ...state, page: action.payload }
    case 'CHANGE_PAGE_SIZE':
      return { ...state, pageSize: action.payload }
    case 'CHANGE_SEARCH':
      return { ...state, search: action.payload }
    default:
      return state
  }
}

export type UseListProps = {
  api: string
  authorization?: string
  defaultPageSize?: number
  defaultFilterParams?: any
  defaultOrdering?: string
  fetchInterval?: number
}

export const useList = ({
  api,
  authorization,
  defaultPageSize,
  defaultOrdering,
  defaultFilterParams,
  fetchInterval
}: UseListProps) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    pageSize: defaultPageSize || initialState.pageSize,
    ordering: defaultOrdering || initialState.ordering,
    filterParams: defaultFilterParams || initialState.filterParams
  })

  const handleFilter = (filterParams?: any) => {
    dispatch({ type: 'HANDLE_FILTER', payload: filterParams })
  }

  // eslint-disable-next-line
  const handleSearchChange = useCallback(
    debounce((search: string | undefined) => {
      dispatch({ type: 'CHANGE_SEARCH', payload: search })
    }, 500),
    []
  )

  const handlePageChange = (page: number) => {
    dispatch({ type: 'CHANGE_PAGE', payload: page })
  }

  const handlePageSizeChange = (event: any) => {
    const value = get(event, 'target.value', event)
    dispatch({ type: 'CHANGE_PAGE_SIZE', payload: value })
  }

  const { page, filterParams, pageSize, ordering, search } = state

  const fetchList = useCallback(
    async (shouldDispatchFetching = true) => {
      if (shouldDispatchFetching) {
        dispatch({ type: 'FETCH_LIST' })
      }
      try {
        const params = {
          ...filterParams,
          pageSize,
          page,
          page_size: pageSize,
          // ordering,
          search
        }
        const data = { params, headers: {} }
        if (authorization) {
          data.headers = { Authorization: authorization }
        }
        const response = await axios.get(api, data)
        if (response.data.results) {
          dispatch({ type: 'FETCH_LIST_SUCCESS', payload: response.data })
        } else if (get(response, 'data.body.results')) {
          dispatch({
            type: 'FETCH_LIST_SUCCESS',
            payload: response.data.body
          })
        } else if (response.data.body) {
          dispatch({
            type: 'FETCH_LIST_SUCCESS',
            payload: { results: response.data.body }
          })
        } else {
          dispatch({
            type: 'FETCH_LIST_SUCCESS',
            payload: { results: response.data }
          })
        }
      } catch (_) {
        dispatch({ type: 'FETCH_LIST_ERROR' })
      }
    },
    [api, authorization, page, filterParams, pageSize, ordering, search]
  )

  useEffect(() => {
    fetchList()
    if (fetchInterval) {
      const fetchIntervalFunction = setInterval(
        () => fetchList(false),
        fetchInterval
      )
      return () => clearInterval(fetchIntervalFunction)
    }
  }, [fetchList, fetchInterval])

  const value = {
    ...state,
    fetchList,
    handleFilter,
    handlePageChange,
    handlePageSizeChange,
    handleSearchChange
  }

  return value
}

export default useList
