import Button from 'components/Button'
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContext } from 'states/ModalState'
import { IExam, IItemWithFreeResponse } from '../../types'
import ItemInfoCard from './ItemInfoCard'
import ItemModal from './ItemModal'

type ItemCardProps = {
  itemId?: any
  items: IItemWithFreeResponse
  exam?: IExam | undefined
}

const ItemCard = ({ itemId, items }: ItemCardProps) => {
  const [expanded, setExpanded] = useState(false)
  const { showModal } = useContext(ModalContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (expanded) {
      setExpanded(!expanded)
    }
  }, [setExpanded, expanded])

  return (
    <>
      <ItemInfoCard items={items}>
        <Button
          action="unstyled"
          type="button"
          onClick={() =>
            showModal(() => <ItemModal items={items} itemId={itemId} />)
          }
        >
          {expanded ? t('Close') : t('See Item')}
        </Button>
      </ItemInfoCard>
    </>
  )
}

export default ItemCard
