import React from 'react'
import DraggableModal from './DraggableModal'

interface CalculatorProps {
  isCalculatorOpen: boolean
  setIsCalculatorOpen: (value: boolean) => void
}

const Calculator: React.FC<CalculatorProps> = ({
  isCalculatorOpen,
  setIsCalculatorOpen
}) => {
  return (
    <DraggableModal
      isOpen={isCalculatorOpen}
      onAction={() => setIsCalculatorOpen(false)}
      defaultPosition={{ x: 0, y: 180 }}
    >
      <iframe
        className="handle"
        src="https://d1urw5zkuq1lal.cloudfront.net"
        height="630"
        width="320"
        frameBorder="0"
        title="Calculator"
      ></iframe>
    </DraggableModal>
  )
}

export default Calculator
