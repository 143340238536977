import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Formik, FormikValues } from 'formik'
import HierarchyFilterBox from 'components/HierarchyFilterBox'
import UserInput from 'components/Inputs/UserInput'
import CardList from 'components/CardList'
import PaginationFooter from 'components/PaginationFooter'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'states/AuthState'
import styled from 'styled-components'
import devices from 'utils/devices'
import Page from 'components/Page'
import useCollection from 'hooks/useCollection'
import Skeleton from 'react-loading-skeleton'
import ExamInputSelect from 'components/Inputs/ExamInputSelect'
import ResultsFound from 'components/ResultsFound'

import AnswerSheetOrderState from 'states/AnswerSheetOrderState'
import Button from 'components/Button'
import CardListToolbar from 'components/CardListToolbar'
import ActionMenuButton from 'components/Buttons/ActionMenuButton'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { API_HOST } from 'consts'
import ItemTitle from 'pages/AnswerSheet/components/ItemTitle'
import SheetOrderStatus from 'components/BulkOrder/SheetOrderStatus'
import useQuery from 'hooks/useQuery'
import AnswerSheetBulkOrderCard from './AnswerSheetBulkOrderCard'
import { IAnswerSheetOrderState } from 'types'

type AnswerSheetBulkOrderListProps = {
  className?: string
}

const AnswerSheetBulkOrderList = ({
  className
}: AnswerSheetBulkOrderListProps) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const [initialValues, setInitialValues] = useState<FormikValues>({})
  const [bulkOrder, setBulkOrder] = useState()
  const [loadingInitialValues, setLoadingInitialValues] = useState<boolean>(
    true
  )
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')
  const collection = useCollection(collectionId)

  useEffect(() => {
    setInitialValues(
      JSON.parse(localStorage.getItem('order-list-filter') || '{}')
    )
    setLoadingInitialValues(false)
  }, [])

  const fetchBulkOrder = useCallback(() => {
    Axios.get(
      `${API_HOST}/v1/generate-bulk-order?exam__collection=${collectionId}`
    )
      .then((res) =>
        setBulkOrder(res.data.map((bulkOrder) => bulkOrder.status.name))
      )
      .catch((err) => {
        console.error(err)
      })
  }, [collectionId])

  useEffect(() => {
    if (!bulkOrder) {
      fetchBulkOrder()
    }
  }, [bulkOrder, fetchBulkOrder])

  const downloadZipReportCard = async () => {
    try {
      const response = await Axios.get(
        `${API_HOST}/v1/generate-bulk-order/${collectionId}/generate_presigned_url_zip`
      )
      const link = document.createElement('a')
      link.href = response.data.url
      link.setAttribute('download', `${collection.name}.zip`)
      document.body.appendChild(link)
      link.click()
    } catch {
      toast.error('Nenhum zip encontrado.')
    }
  }

  return (
    <Page customWidth={1366}>
      {!loadingInitialValues && (
        <AnswerSheetOrderState initialValues={initialValues}>
          {({
            isLoading,
            results,
            totalResults,
            handlePageChange,
            onPageSizeChange,
            pageSize,
            numPages,
            onFilter
          }: IAnswerSheetOrderState) => (
            <div className={className}>
              {!loadingInitialValues && (
                <div className="parent-wrapper">
                  <div className="filter-wrapper">
                    <Formik initialValues={initialValues} onSubmit={onFilter}>
                      <HierarchyFilterBox
                        distinct={false}
                        providerCodename={user?.provider?.codename}
                      >
                        <UserInput basic />
                        <ExamInputSelect
                          collectionId={collection?.id}
                          label={t('Exam_name')}
                          name="exam"
                        />
                        <SheetOrderStatus name="status" label="Status" />
                      </HierarchyFilterBox>
                    </Formik>
                  </div>
                  <div className="card-list-wrapper">
                    <div className="bulk-order">
                      <ItemTitle>
                        <span> Status da ordem de serviço:</span> {bulkOrder}
                      </ItemTitle>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '0.5rem 0'
                      }}
                    >
                      {totalResults !== undefined && (
                        <ResultsFound resultsFound={totalResults} />
                      )}
                      <CardListToolbar>
                        <ActionMenuButton label={t('Actions')}>
                          <Button
                            action="unstyled"
                            onClick={downloadZipReportCard}
                          >
                            Baixar pasta compactada com todos os boletins
                          </Button>
                        </ActionMenuButton>
                      </CardListToolbar>
                    </div>
                    <CardList>
                      {isLoading && <Skeleton height={150} count={pageSize} />}
                      {!isLoading &&
                        results.map((result) => (
                          <>
                            <AnswerSheetBulkOrderCard
                              answerSheetOrder={result}
                            />
                          </>
                        ))}
                    </CardList>
                    <PaginationFooter
                      pageSize={pageSize}
                      pageCount={numPages}
                      onPageChange={handlePageChange}
                      onPageSizeChange={onPageSizeChange}
                      disablePageSizeChange
                    >
                      <p>
                        {t(
                          'Showing {{resultsLength}} of {{totalResults}} found',
                          {
                            appealsLenght: results.length,
                            totalAppeals: totalResults
                          }
                        )}
                      </p>
                    </PaginationFooter>
                  </div>
                </div>
              )}
            </div>
          )}
        </AnswerSheetOrderState>
      )}
    </Page>
  )
}

export default styled(AnswerSheetBulkOrderList)`
  .appealList {
    padding: 1rem;
    .appeal-exam {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      margin-top: -10px;

      p {
        font-size: 1.3rem;
      }
    }
    .appeal-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.1rem;
      font-weight: 700;
      margin-top: 6px;

      p {
        font-size: 0.85rem;
        color: ${(props) => props.theme.clientColors.secondaryDark};
      }
    }
    .card-list-Results {
      padding: 1rem;
    }
    .appeal-header-infos {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 0.9rem;
      font-weight: 700;
      padding-right: 1.5rem;
      margin-top: -1.4rem;
    }
    .appeal-id-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.9rem;
      font-weight: 600;
      margin-top: -5px;

      p {
        margin-left: 10px;
      }
    }
    .appeal-content {
      display: grid;

      h4 {
        font-size: 1rem;
        font-weight: 600;
        margin-top: -2px;
      }
      span {
        text-align: justify;
        font-size: 1rem;
        margin-top: -16px;
      }
    }
    .appeal-bibliography {
      display: grid;

      h4 {
        font-size: 1rem;
        font-weight: 600;
      }
      span {
        text-align: justify;
        font-size: 1rem;
        margin-top: -16px;
      }
    }
    .appeal-created-at {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .parent-wrapper {
    display: flex;
    column-gap: 20px;
  }

  @media (max-width: ${devices.mobileL}) {
    .parent-wrapper {
      flex-direction: column;
      row-gap: 20px;
      column-gap: 0;
    }
  }

  .filter-wrapper {
    max-width: 400px;

    @media (min-width: ${devices.tablet}) {
      min-width: 400px;
      column-gap: 20px;
    }
  }

  .card-list-wrapper {
    padding-left: 1rem;
    flex-grow: 6;
  }

  .bulk-order {
    span {
      color: #000;
    }
  }
  .generate-report-card {
    margin: 0.9rem 0;
    display: flex;
    justify-content: flex-end;
  }
`
