import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type LoadingIndicatorProps = {
  center?: boolean
  className?: string
}

const LoadingIndicator = ({ center, className }: LoadingIndicatorProps) => {
  const renderLoadingIndicator = () => (
    <FontAwesomeIcon
      spin
      className={`loading-indicator ${className || ''}`}
      icon="circle-notch"
    />
  )
  if (center) {
    return (
      <div className={`d-flex justify-center ${className || ''}`}>
        {renderLoadingIndicator()}
      </div>
    )
  }
  return renderLoadingIndicator()
}

LoadingIndicator.propTypes = {
  center: PropTypes.bool
}

LoadingIndicator.defaultProps = {
  center: false
}

export default LoadingIndicator
