import {
  DirectorSymptom,
  Rubric,
  SymptomTableRow
} from 'data/domain/repertorization'
import Dexie from 'dexie'
import { IAnswer, IApplication, IItem } from './types'

class ExamsDatabase extends Dexie {
  public answers: Dexie.Table<IAnswer, number>
  public items: Dexie.Table<IItem, number>
  public applications: Dexie.Table<IApplication, number>
  public rubrics: Dexie.Table<Rubric, number[]>
  public symptomTableRows: Dexie.Table<SymptomTableRow, number[]>
  public directorSymptoms: Dexie.Table<DirectorSymptom, number>

  public constructor() {
    super('exams')
    this.version(1).stores({
      answers: 'id,position,application.id,_changed,[application.id+_changed]',
      applications: 'id,user.id',
      items: 'id',
      rubrics: '[application+item+position],syncStatus',
      symptomTableRows: '[application+position],syncStatus',
      directorSymptoms: 'application,syncStatus'
    })
    this.answers = this.table('answers')
    this.applications = this.table('applications')
    this.items = this.table('items')
    this.rubrics = this.table<Rubric, number[]>('rubrics')
    this.symptomTableRows = this.table<SymptomTableRow, number[]>(
      'symptomTableRows'
    )
    this.directorSymptoms = this.table('directorSymptoms')
  }
}

const db = new ExamsDatabase()

export default db
