import axios from 'axios'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { API_HOST } from '../consts'
import { IClasses, IInstitution, IInstitutionFilterData, IPeriodo, IEtapa, IBooleanPreference } from '../types'
import InputSelect from './Inputs/InputSelect'
import { AuthContext } from 'states/AuthState'
import usePreference from 'hooks/usePreference'

type HierarchyFilterBoxProps = {
  providerCodename?: string
  className?: string
  distinct?: boolean
  isSchoolValid?: boolean
  required?: string[]
}

const HierarchyFilter = ({
  providerCodename,

}: HierarchyFilterBoxProps) => {
  const [institutionsValues, setInstitutionsValues] = useState<IInstitution[]>(
    []
  )
  const [classesValues, setClassesValues] = useState<IClasses[]>([])
  const [periodoValues, setPeriodoValues] = useState<IPeriodo[]>([])
  const [etapaValues, setEtapaValuses] = useState<IEtapa[]>([])
  const formik = useFormikContext()
  const sharedFlow = usePreference<IBooleanPreference>(
    'Shared_Data__ActivateSharedFlow'
  )


  const fetchSchool = useCallback(async () => {
    try {
      const response = await axios.get<IInstitutionFilterData[]>(
        `${API_HOST}/institucion-users/user_schools`
      )
      setInstitutionsValues(
        response.data.map((schools) => ({
          id: schools['id'],
          name: schools['name'],
          codIntegrador : schools['codIntegrador'],
        }))
      )
      return response.data
    } catch (_) { }
  }, [providerCodename])

  const fetchClasses = (key,e, e1) => {
    try {
      axios
        .get(
          `${API_HOST}/classess/get_all_classes?institucion__id=${e1.id}&${key}=${e.id}`
        )
        .then((response) => {
          setClassesValues(
            response.data.map((classes) => ({
              id: classes.id,
              name: classes.turmaDs
            }))
          )
        })
    } catch (e) {
      console.error(e)
    }
  }
  const fetchClassesShared = (e,school) => {
    try {
      axios
        .get(
          `${API_HOST}/classes-users/user_classes?school=${school.codIntegrador}&grade=${e.id}`
        )
        .then((response) => {
          setClassesValues(
            response.data.map((classes) => ({
              id: classes.id,
              name: classes.name
            }))
          )
        })
    } catch (e) {
      console.error(e)
    }
  }
  
  const fetchPeriodo = () => {
    try {
      axios.get(`${API_HOST}/v1/year_period`).then((response) => {
        setPeriodoValues(
          response.data.map((periodo) => ({
            id: periodo.id,
            name: `${periodo.anoId.dsAno} - ${periodo.periodoId.dsPeriodo}`
          }))
        )
      })
    } catch (e) {
      console.error(e)
    }
  }

  const fetchEtapa = (e) => {
    try {
      axios.get(`${API_HOST}/school_stage-users/user_grades?school=${e.codIntegrador}`).then((response) => {
        setEtapaValuses(
          response.data.map((etapa) => ({
            id: etapa.id,
            name: `${etapa.name}`
          }))
        )
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchSchool()

  }, [fetchSchool])

  return (
    <>
      <>
        <InputSelect
          defaultOptions
          loadOptions={fetchSchool}
          options={institutionsValues}
          value={institutionsValues.find(
            (option) => option.id === formik.values['Escolas']
          )}
          label="Escolas"
          name="Escolas"
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.name}
          onChange={(e) => {
            if(sharedFlow && get(sharedFlow, 'value')){
              fetchEtapa(e)
            }
            else{
            fetchPeriodo()
            }
            formik.values['Escolas'] = e
          }}
        />
        {sharedFlow && get(sharedFlow, 'value')?
        <InputSelect
          defaultOptions
          loadOptions={fetchEtapa}
          options={etapaValues}
          label="Série/Ano"
          name="Etapa"
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.name}
          onChange={(e) => fetchClassesShared(e, formik.values['Escolas'])}
        />:
        <InputSelect
        defaultOptions
        loadOptions={fetchPeriodo}
        options={periodoValues}
        label="Período"
        name="Periodo"
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) => d.name}
        onChange={(e) => fetchClasses('periodo_ano_id',e, formik.values['Escolas'])}
      />}
        <InputSelect
          defaultOptions
          loadOptions={fetchClasses}
          options={classesValues}
          label="Turmas"
          name="Turmas"
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.name}
        />
      </>
    </>
  )
}

export default styled(HierarchyFilter)``
