import React, { useContext } from 'react'
import { DashboardPreFilter } from 'types'
import Card from './Card/Card'
import CardTitle from './ExamDashboardComponents/CardTitle'
import moment from 'moment'
import ApplicationStatus from './ApplicationStatus'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard } from '@fortawesome/pro-regular-svg-icons'
import CardSubtitle from './ExamDashboardComponents/CardSubtitle'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { AuthContext } from 'states/AuthState'
import ExamInfoLabelHome from './ExamInfoLabelHome'
import ProgressBarHome from './ProgressBarHome'
import { Typography, Button } from '@material-ui/core'
import devices from 'utils/devices'
import { API_HOST } from 'consts'
import axios from 'axios'
import { toast } from 'react-toastify'
type DashboardPreFilterCardProps = {
  className?: string
  dashboardPreFilter: DashboardPreFilter
}

const DashBoardPreFilterCard = ({
  className,
  dashboardPreFilter
}: DashboardPreFilterCardProps) => {
  const { hasGroup } = useContext(AuthContext)
  const { t } = useTranslation()
  const history = useHistory()
  
  const getStatus = () => {
    const examStartMoment = moment(dashboardPreFilter.startTime)
    const examFinishMoment = moment(dashboardPreFilter.endTime)
    const momentNow = moment(new Date())

    if (momentNow.isAfter(examFinishMoment)) {
      return 'FINISHED'
    } else if (
      examStartMoment.isBefore(momentNow) &&
      examFinishMoment.isAfter(momentNow)
    ) {
      return 'AVAILABLE'
    } else if (examStartMoment.isAfter(momentNow)) {
      return 'UNAVAILABLE'
    } else {
      return 'UNKNOWN'
    }
  }

  const setAvaliada = (event) => {
    event.stopPropagation();
        history.push(`/exams/dashboard/view?collection=${dashboardPreFilter.id}`);

  };

  // const getReadableDate = (date: any) => {
  //   return moment(date).format('DD/MM/YYYY HH:mm')
  // }

  const handleClick = (status) => {
    if (hasGroup('CORRECTOR') && status !== 'AVAILABLE') {
      history.push(`/exams/dashboard/items?collection=${dashboardPreFilter.id}`)
    } else {
      if (!dashboardPreFilter.totalApplications && status !== 'AVAILABLE') {
        return
      }

      history.push(`/exams/dashboard/list?collection=${dashboardPreFilter.id}`)
    }
  }

  return (
    <Card
      onClick={() => handleClick(getStatus())}
      className={classNames(className, {
        disabled: dashboardPreFilter.totalApplications === 0
      })}
    >
      <div className="card-container">
        {/* <div className="icon">
          <FontAwesomeIcon
            icon={dashboardPreFilter?.iconName}
            style={{ fontSize: '45px' }}
          />
        </div> */}
        <div className="card-body" style={{width:'100%'}}>
          <CardTitle>
            <div
              className="card-title"
              style={{
                color: 'var(--Neutral-color---Darker, #262626)',
                fontSize: '1rem',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '1.25rem'
              }}
            >
              {dashboardPreFilter.name}
              <ApplicationStatus status={getStatus()}></ApplicationStatus>
            </div>
            {/* <div style={{display:'flex'}}>
            {(dashboardPreFilter?.isMochila && dashboardPreFilter?.avaliada)?
            
            <div 
            style={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            marginLeft:'1rem',
            marginRight:'1rem'
          }} 
            className='trilha-avaliada-flag'>
              <>Trilha Adaptativa : Avaliada</>
            </div>
            
            :
            <div 
            style={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            marginLeft:'1rem',
            marginRight:'1rem'
          }} 
            className='trilha-pendente-flag'>
              <>Trilha Adaptativa : Avaliação Pendente</>
            </div>} */}

            {/* </div> */}
          </CardTitle>
          {/* <CardSubtitle>
        <FontAwesomeIcon icon={faUserGraduate}></FontAwesomeIcon>{' '}
        {dashboardPreFilter.totalApplications || 0} {t('participants')}
      </CardSubtitle> */}
          {/* <span>
          <p>{t('From')}:</p> <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>{getReadableDate(dashboardPreFilter.startTime)}
          </span>
          <span>
          <p>{t('to')}</p> <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>{getReadableDate(dashboardPreFilter.endTime)}
        </span> */}

          <div className="card-infos">
            <div className="card-footer" style={{width:'100%'}}>
              {/* <span>
              <p>{t('Total exams')}:</p>{' '}
              <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>
              {dashboardPreFilter.totalExams}
            </span> */}

              <ExamInfoLabelHome label="Componente curricular">
                <span
                  style={{
                    color: '#262626',
                    fontSize: '0.875rem',
                    fontStyle: 'normal',
                    fontWeight: 450,
                    lineHeight: '1.25rem'
                  }}
                >
                  {dashboardPreFilter?.discipline}
                </span>
              </ExamInfoLabelHome>

              <ExamInfoLabelHome label="Série/Ano">
                <span
                  style={{
                    color: '#262626',
                    fontSize: '0.875rem',
                    fontStyle: 'normal',
                    fontWeight: 450,
                    lineHeight: '1.25rem'
                  }}
                >
                  {dashboardPreFilter?.seriesYear}
                </span>
              </ExamInfoLabelHome>
            </div>

            <ExamInfoLabelHome label="Nº de atividade">
              <span
                style={{
                  color: '#262626',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 450,
                  lineHeight: '1.25rem'
                }}
              >
                {dashboardPreFilter?.totalExams}
              </span>

            </ExamInfoLabelHome>
            <div
              style={{
                alignItems: 'stretch',
                strokeWidth: '1px',
                border: '1px solid var(--Neutral-color-Gray-200, #DEE7EF)',
                width: '100%',
                marginTop: '1rem',
                marginBottom: '1rem'
              }}
            />

            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <ExamInfoLabelHome label="Engajamento dos estudantes" />

              <ProgressBarHome
                current={dashboardPreFilter?.countExamsAvailable}
                total={dashboardPreFilter?.totalApplications}
              />
              {dashboardPreFilter.typeId === 3 && dashboardPreFilter.categoria === 1 && (
                dashboardPreFilter.avaliada === true ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#ECE4FD',
                      borderRadius: '8px',
                      gap: '10px',
                      marginLeft: 'auto',
                      whiteSpace: 'nowrap',
                      padding: '8px',

                    }}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '115px'
                    }}>
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontWeight: 550,
                        lineHeight: '20px',
                        letterSpacing: '0px',
                        textAlign: 'center',
                        color: '#7C47F6',
                      }}
                    >
                      Trilha avaliada
                    </Typography>
                     </div>
                   
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginLeft: 'auto',
                      whiteSpace: 'nowrap',
                      padding: '5px',
                    }}
                  >

                    <Button
                      style={{
                        width: '85px',
                        height: '32px',
                        backgroundColor: '#7C47F6',
                        borderRadius: '26px',
                      }}
                      onClick={(event) => {
                        setAvaliada(event);
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '12px',
                          fontWeight: 550,
                          lineHeight: '20px',
                          letterSpacing: '0px',
                          textAlign: 'center',
                          color: '#fff',
                        }}
                      >
                        Avaliar
                      </Typography>
                    </Button>
                  </div>
                )
              )}
            </div>

          </div>
        </div>
      </div>
    </Card>
  )
}
export default styled(DashBoardPreFilterCard)`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Neutral-color---Lighter, #dfdfdf);
  background: #fff;

  ${CardTitle} {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    color: ${(props) => props.theme.clientColors.primary};
  }

  .card-body {
    margin-top: 1rem;
  }

  .card-container {
    /* display: grid; */
    align-items: center;
    /* grid-template-columns: 1fr 8fr; */
    width: 100%;
  }

  .card-title {
    /* text-decoration: none; */
    display: flex;
    width: 54.25rem;
    gap: 1.5rem;
    align-items: center;
    margin-top: -20px;
  }

  .trilha-avaliada-flag {
    text-align: 'center';
    color: white;
    background-color: ${(props) => props.theme.clientColors.colorSecondIcon};
    border-radius: 4px;
    height: 30px;
    font-size: 10px;
    font-weight: 600px;
    width: 217px;
  }
  .trilha-pendente-flag {
    text-align: 'center';
    background-color: ${(props) => props.theme.clientColors.colorPrimaryIcon};
    color: white;
    border-radius: 4px;
    height: 30px;
    font-size: 10px;
    font-weight: 600px;
    width: 217px;
  }

  .icon {
    color: ${(props) => props.theme.clientColors.colorPrimaryIcon};
    background-color: ${(props) => props.theme.clientColors.colorSecondIcon};
    border-radius: 100%;
    width: 120px;
    height: 120px;
    display: flex;
    padding: 3rem;
    align-items: center;
    justify-content: center;
  }

  ${CardSubtitle} {
    font-weight: bold;
    font-size: 12px;
  }

  cursor: pointer;
  &.disabled {
    opacity: 80%;
    cursor: not-allowed;
  }

  .card-footer {
    display: flex;
    width: 54.25rem;
    align-items: flex-start;
    gap: 1.5rem;
    margin-bottom: 2px;
  }

  .card-footer > span {
    display: flex;
    align-items: center;
    column-gap: 3px;
  }

  .card-infos {
    display: flex;
    flex-direction: column;
  }
  ${ExamInfoLabelHome} > strong {
    font-size: 14px;
    color: ${(props) => props.theme.clientColors.primary} !important;
  }

  .card-footer > span > p {
    color: ${(props) => props.theme.clientColors.primary} !important;
  }

  @media (max-width: ${devices.mobileL}) {
    .card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .card-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
    }

    ${ExamInfoLabelHome} > strong {
      margin-top: 0.6rem;
    }
  }
`
