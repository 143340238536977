import { fetchAnswerSheetItem } from 'data/apis/items'
import AnswerSheetItemContent from 'pages/AnswerSheet/components/AnswerSheetItemContent'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { IItem } from 'types'
import CancelledItemIndicator from './CancelledItemIndicator'
import LimiteTimeExceededIndicator from './LimiteTimeExceededIndicator'
import ItemTitle from './ItemTitle'
import { ICorrectAnswer, AnswerAttachments } from 'data/domain/exams'

type Props = {
  postAlternativeExplanationVisualization: boolean
  showItemId?: boolean
  getAnswer: (
    itemId: number
  ) => {
    position: number
    givenAnswer: string
    givenBulkAnswer: any[]
    correctAnswer: string
    answerGradeLinear?: any
    verboseName: string
    correctBulkAnswer: ICorrectAnswer[]
    isCancelled: boolean
    isTimeout: boolean
    attachment: AnswerAttachments
  }
}

const AnswerSheetItem = ({
  getAnswer,
  postAlternativeExplanationVisualization,
  showItemId
}: Props) => {
  const { itemId } = useParams()
  const {
    data: item,
    isLoading: fetchingItem,
    error: fetchItemError
  } = useQuery<IItem, string>(['item', itemId], (_, _itemId: number) =>
    fetchAnswerSheetItem(_itemId)
  )

  const {
    position,
    givenAnswer,
    correctAnswer,
    correctBulkAnswer,
    givenBulkAnswer,
    isCancelled,
    isTimeout,
    verboseName,
    attachment
  } = getAnswer(+itemId)

  return (
    <>
      <ItemTitle>Objeto de aprendizagem {verboseName || position}</ItemTitle>
      {showItemId && <small>Código da questão {itemId}</small>}
      {isTimeout && <LimiteTimeExceededIndicator />}
      {isCancelled && <CancelledItemIndicator />}
      <AnswerSheetItemContent
        postAlternativeExplanationVisualization={
          postAlternativeExplanationVisualization
        }
        item={item}
        fetchItemError={fetchItemError}
        fetchingItem={fetchingItem}
        correctAnswer={correctAnswer}
        correctBulkAnswer={correctBulkAnswer}
        bulkAnswer={givenBulkAnswer}
        givenAnswer={givenAnswer}
        attachment={attachment}
        isCancelled={isCancelled}
      />
    </>
  )
}

export default AnswerSheetItem
