import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import { API_HOST, FIREBASE_ENABLED } from 'consts'
import { storage } from 'infra/firebase'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  className?: string
  user: number
  answer: number
  application: number
  getFiles: (file: any) => void
  files: any
  toggleIsLoading: (value: boolean) => void
}

const Attachment = ({
  className,
  user,
  answer,
  application,
  getFiles,
  files,
  toggleIsLoading
}: Props) => {
  const { t } = useTranslation()
  const [urls, setUrls] = useState([])
  console.log(urls)
  const [isLoading, setIsLoading] = useState(false)

  const saveImages = useCallback(
    async (path, filename) => {
      try {
        await Axios.post(
          `${API_HOST}/v1/applications/${application}/answer_attachment`,
          {
            path,
            filename,
            answer
          }
        )
      } catch {
        toast.error(t('Error when importing file'))
      }
      setIsLoading(false)
    },
    [answer, application, t]
  )

  const uploadFile = useCallback(
    async (file) => {
      setIsLoading(true)

      const imageRef = storage
        .ref()
        .child(`answer/${user}/${answer}/${file?.name}`)

      const time = file?.lastModified

      const uploadImage = await imageRef.put(file)

      const url = uploadImage.ref.getDownloadURL()
      url.then((res) => {
        setUrls((u) => {
          const newUrls = [
            ...u,
            {
              time,
              path: res,
              answer: answer,
              fileName: file?.name
            }
          ]
          saveImages(res, file?.name)
          return newUrls
        })
      })
    },
    [answer, user, saveImages]
  )

  useEffect(() => {
    if (isLoading) {
      toggleIsLoading(true)
    } else {
      toggleIsLoading(false)
    }
  }, [isLoading, toggleIsLoading])

  const onFileChange = (event) => {
    const file = event.target.files[0]
    getFiles(file)
    if (FIREBASE_ENABLED) {
      uploadFile(file)
    }
  }

  return (
    <div className={className}>
      <label htmlFor="select-file">
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <>
            {t('Attach')}
            {files?.length > 0 && <span>{files?.length}</span>}
          </>
        )}
      </label>
      <input
        type="file"
        accept="image/*"
        capture="camera"
        onChange={onFileChange}
        id="select-file"
      />
    </div>
  )
}

export default styled(Attachment)`
  label {
    margin-right: 15px;
    cursor: pointer;
    padding: 7px 20px;
    background: #fff;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12pt;
    border: ${(props) => props.theme.clientColors.primary} solid 1pt;
    color: ${(props) => props.theme.clientColors.primary};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    transition: filter 300ms;

    &:hover {
      filter: brightness(90%);
    }
  }

  svg {
    margin-left: 4px;
  }

  span {
    color: ${(props) => props.theme.clientColors.primary};
    margin-left: 6px;
    font-size: 11pt;
    font-weight: bold;
  }

  input[type='file'] {
    display: none;
  }
`
