import { InternetConnectionDetectionStrategy, IUser } from 'types'
import FileDownloadStrategy from './internet-detection-strategy/file-download-strategy'
import { updateCurrentSituation } from 'data/apis/message-exchange'
import { addInternetMeasurementRecord } from 'data/apis/internet-measurement'
import { FIREBASE_ENABLED } from 'consts'

class InternetConnectionStatusManager {
  private strategy: InternetConnectionDetectionStrategy
  private callbackId = -1
  private user: IUser

  constructor() {
    this.decideStrategy()
  }

  public start(user: IUser) {
    if (!user) {
      return
    }
    this.user = user
    this.strategy.start()
  }

  private decideStrategy() {
    this.strategy = new FileDownloadStrategy()
    if (FIREBASE_ENABLED) {
      this.callbackId = this.strategy.addListener(this.onDetection.bind(this))
    }
  }

  private async onDetection(
    lastMeasurement: number,
    averageMeasurement: number,
    score: number
  ) {
    try {
      await updateCurrentSituation(`${this.user.id}`, {
        lastMeasuredDelay: lastMeasurement,
        averageInternetDelay: averageMeasurement,
        currentInternetScore: score
      })

      await addInternetMeasurementRecord(
        `${this.user.id}`,
        lastMeasurement,
        averageMeasurement,
        score
      )
    } catch (err) {}
  }
}

const internetManager = new InternetConnectionStatusManager()
export default internetManager
