import React from 'react'
import {Tab, Tabs, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  customTextColor: {
    color: '#7C47F6'
  },
  paper: {
    border: '0px 0px 1px 0px',
    backgroundColor: '#fafafa'
  },
  tab: {
    border: '1px solid #DFE2E6'
  }
}))

interface TabMenuProps {
  activeTab: number
  onTabChange: (tab: number) => void
}

const TabMenu: React.FC<TabMenuProps> = ({ activeTab, onTabChange }) => {
  const classes = useStyles()

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    onTabChange(newValue)
  }

  const selectedColor = '#DFE2E6'
  const selectedColorTop = '#0047B6'

  const getStyle = (tabIndex) => ({
    width: '100%',
    height: '51px',
    padding: '10px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: '1px 1px 0px 1px',
    borderBottom: activeTab === tabIndex ? 'none' : '1px solid #DFE2E6',
    borderTop:
      tabIndex === activeTab ? `3px solid ${selectedColorTop}` : 'none',
    borderLeft: tabIndex === activeTab ? `1px solid ${selectedColor}` : 'none',
    borderRight: tabIndex === activeTab ? `1px solid${selectedColor}` : 'none'
  })

  return (
    <div style={{display:'flex', justifyContent:"center", width:'100%', overflow:'auto', alignItems:'center'}}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="inherit"
        centered
        scrollButtons="auto"
        variant="scrollable"
        style={{marginTop:'20px', width:'850px'}}
      >
        <Tab
          label="Atividades processuais"
          value="3"
          className={activeTab === 3 ? classes.customTextColor : ''}
          style={getStyle(3)}
        />
        <Tab
          label="Trilhas adaptativas"
          value="1"
          className={activeTab === 1 ? classes.customTextColor : ''}
          style={getStyle(1)}
        />
        <Tab
          label="Avaliações"
          value="2"
          className={activeTab === 2 ? classes.customTextColor : ''}
          style={getStyle(2)}
        />
      </Tabs>
    </div>
  )
}

export default TabMenu
