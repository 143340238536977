import axios from 'axios'
import { API_HOST } from 'consts'
import { AppealReplyData } from 'data/domain/appeals'
import i18n from 'i18n'
import { get } from 'lodash'
import { IAppealFormData } from '../../types'

export const createAppeal = async (appealData: IAppealFormData) => {
  return axios.post(`${API_HOST}/v1/appeals`, appealData)
}

export const fetchAppeals = async (applicationId: number) => {
  const response = await axios.get(
    `${API_HOST}/v1/appeals?application=${applicationId}`
  )
  return response.data
}

export const fetchAppealById = async (appealId: number) => {
  const response = await axios.get(`${API_HOST}/v1/appeals/${appealId}`)
  return response.data
}

export const updateAppealById = async (
  appealId: number,
  appealData: IAppealFormData
) => {
  const response = await axios.put(
    `${API_HOST}/v1/appeals/${appealId}`,
    appealData
  )
  return response.data
}

export const uploadAttachment = async (formData) => {
  const response = await axios.post(
    `${API_HOST}/v1/appeal_attachments`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return response.data
}

export const fetchAppealDetail = async (appealId: number) => {
  const response = await axios.get(`${API_HOST}/v1/appeals/${appealId}/details`)
  return response.data
}

export const replyAppeal = async (appealId: number, data: AppealReplyData) => {
  // TODO: Resolver o warning do ES Lint
  try {
    const response = await axios.put(
      `${API_HOST}/v1/appeals/${appealId}/reply`,
      data
    )
    return response.data
  } catch (error) {
    const response = error.response
    if (response.status === 400) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(response.data)
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      nonFieldErrors: i18n.t('An error occurred. Please try again.')
    })
  }
}

export const startPaymentProcess = async (appealProccessId: number) => {
  const response = await axios.post(
    `${API_HOST}/v1/appeal-process/${appealProccessId}/start_payment_process`
  )
  return response.data
}

export const fetchAppealProcess = async (applicationId: number) => {
  const response = await axios.get(`${API_HOST}/v1/appeal-process`, {
    params: {
      application: applicationId
    }
  })
  return get(response.data, '0', {})
}

export const deleteAppeal = async (appealId: number) => {
  const response = await axios.delete(`${API_HOST}/v1/appeals/${appealId}`)
  return response.data
}
