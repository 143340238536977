import React from 'react'
import PropTypes from 'prop-types'
// import { ThemeContext } from 'styled-components';
import styled from 'styled-components'

const HeaderAvalia = ({
  className,
  schoolName,
  schoolYear,
  dreAndMunicipio,
  student
}) => {
  // const theme = useContext(ThemeContext);
  return (
    <div className={className}>
      <div
        className="container__background"
        // style={{ background: `${theme.primary}` }}
      >
        <div className="basic__info">
          <div className="input__background">
            <span className="first__info">
              Escola: <span>{schoolName}</span>
            </span>
          </div>
          <div className="input__background">
            <span className="first__info">
              Etapa Escolar: <span>{schoolYear}</span>
            </span>
          </div>
        </div>
        <div className="basic__info">
          <div className="input__background">
            <span className="first__info">
              {/* Exemplo: DRE Juina | Juina |  */}
              DR/Município: <span>{dreAndMunicipio}</span>
            </span>
          </div>
          <div className="input__background">
            <span className="first__info">
              Estudante: <span>{student}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

HeaderAvalia.propTypes = {
  schoolName: PropTypes.string,
  schoolYear: PropTypes.string,
  dreAndMunicipio: PropTypes.string,
  student: PropTypes.string
}

export default styled(HeaderAvalia)`
  width: 95%;
  .container__background {
    background: #6a35dd;
    border-radius: 10px;
    border: none;
    padding: 4px;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    box-shadow: 2px 4px 5px rgb(58, 58, 58, 0.25);
  }

  .basic__info {
    display: flex;
    flex-direction: column;
  }

  .first__info {
    font-weight: bold;
  }

  .first__info > span {
    font-weight: 500;
  }

  .input__background {
    padding: 0.7rem;
    margin: 4px;
    width: auto;
    border-radius: 10px;
    background: #fff;
  }

  span {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 769px) {
    .container__background {
      display: flex;
      flex-direction: column;
    }
  }
`
