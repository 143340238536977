import React, { useState } from 'react'
import { IItem } from 'types'
import styled from 'styled-components'
import ReactCardFlip from 'react-card-flip'
import Html from 'components/Html'
import flash_card from '../../../assets/img/flash_card.svg'
import flash_card_verso from '../../../assets/img/flash_card_verso.svg'

type PropsFlashCard = {
  item: IItem
  className?: string
}

const FlashCard = ({ className, item }: PropsFlashCard) => {
  const [isFliped, setIsFliped] = useState(false)
  return (
    <ReactCardFlip isFlipped={isFliped} flipDirection="horizontal">
      <div
        className={className}
        style={{ backgroundImage: `url(${flash_card})` }}
      >
        <div className="text-conteiner" style={{ padding: '2rem 2rem 0 2rem' }}>
          <Html>{item?.introduction}</Html>
        </div>
        <button
          className="btn-v"
          onClick={() => {
            setIsFliped(!isFliped)
          }}
        >
          Virar
        </button>
      </div>
      <div
        className={className}
        style={{ backgroundImage: `url(${flash_card_verso})` }}
      >
        <div
          className="text-conteiner-1"
          style={{ padding: '2rem 2rem 0 2rem' }}
        >
          <Html>{item?.question}</Html>
        </div>
        <button
          className="btn-v"
          onClick={() => {
            setIsFliped(!isFliped)
          }}
        >
          Virar
        </button>
      </div>
    </ReactCardFlip>
  )
}
export default styled(FlashCard)`
  background: #fff;
  margin: 1rem;
  min-height: 15rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 800px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(58, 58, 58, 0.5);
  .text-conteiner {
    min-height: 13rem;
    widht: 100%;
    display: flex;
    align-items: center;
  }
  .text-conteiner-1 {
    min-height: 13rem;
    widht: 100%;
    display: flex;
    align-items: center;
  }
  .btn-v {
    border-width: 1px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    min-width: 50%;
    min-height: 2rem;
    background: #fff;
    border-color: #000;
    border-radius: 8px;
    &:hover {
      background: #7229e6;
      color: #fff;
    }
  }
`
