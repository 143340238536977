import styled from 'styled-components'

export default styled.button<{
  action: 'primary' | 'secondary'
}>`
  box-shadow: none;
  border: 0.6px solid #95989a;
  font: Bold 16px/30px Open Sans, sans-serif;
  padding: 7px 20px;
  border-radius: 8px;
  letter-spacing: 1px;
  cursor: pointer;

  ${(props) =>
    props.action === 'primary' &&
    `
    background-color: #1a3a60;
    color: white;
  `}

  ${(props) =>
    props.action === 'secondary' &&
    `
    background-color: white;
  `}
`
