import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Tag from '../Tag'

type AppealsStatusProps = {
  status: any
  className?: string
}

const AppealsStatus = ({ status, className }: AppealsStatusProps) => {
  const { t } = useTranslation()
  const color = () => {
    return (
      {
        analysis: '#BF6827',
        disclosed: '#63B288',
        replied: '#1A3A60'
      }[status] || 'Unknown'
    )
  }

  const getStatusLabel = (status: string): string => {
    return (
      {
        analysis: 'Em análise',
        disclosed: 'Divulgado',
        replied: 'Respondido'
      }[status] || 'Unknown'
    )
  }
  return (
    <Tag color={color()} className={className}>
      {t(getStatusLabel(status))}
    </Tag>
  )
}

export default styled(AppealsStatus)``
