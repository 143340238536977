import React, { ReactNode } from 'react'
import AlternativeOutline from './AlternativeOutline'

type Props = {
  children: ReactNode
}

const CorrectAlternativeOutline = ({ children }: Props) => {
  return (
    <AlternativeOutline color="#61B082" text="Resposta correta">
      {children}
    </AlternativeOutline>
  )
}

export default CorrectAlternativeOutline
