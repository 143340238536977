import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { IApplication, IExamDashboardApplication } from 'types'
import { getInternetMeasurementDuringApplication } from '../../../data/apis/message-exchange'
import { Connection } from 'data/domain/message-exchange'
import { Skeleton } from '@material-ui/lab'
import InternetMeasurementChart from './InternetMeasurementChart'
import { useTranslation } from 'react-i18next'

interface InternetMeasurementProps {
  className?: string
  application: IApplication | IExamDashboardApplication
}

const InternetMeasurement: React.FC<InternetMeasurementProps> = ({
  className,
  application
}) => {
  const [isFetching, setIsFetching] = useState(true)
  const [connectionRecords, setConnectionRecords] = useState<Connection[]>([])
  const [chartLoaded, setChartLoaded] = useState(0)
  const { t } = useTranslation()

  const noRecords = useMemo(() => !isFetching && !connectionRecords.length, [
    isFetching,
    connectionRecords
  ])

  const fetchCandidateInternetMeasurementRecords = useCallback(async () => {
    const connectionDays = []

    if (application.startedAt) {
      connectionDays.push({
        startedAt: application.startedAt,
        finishedAt: application.finishedAt
      })
    } else if (application.timeWindows.length) {
      application.timeWindows.forEach((tw) => {
        connectionDays.push({
          startedAt: tw.startTime,
          finishedAt: tw.endTime
        })
      })
    } else {
      application.exam.timeWindows.forEach((tw) => {
        connectionDays.push({
          startedAt: tw.startTime,
          finishedAt: tw.endTime
        })
      })
    }

    let records = []
    const getRecords = connectionDays.map(async (cd) => {
      const dayRecords = await getInternetMeasurementDuringApplication(
        String(application?.user.id),
        cd.startedAt,
        new Date(cd.finishedAt).toISOString()
      )
      records = [...records, ...dayRecords]
    })

    await Promise.all(getRecords)

    setIsFetching(false)
    return records
  }, [application])

  useEffect(() => {
    fetchCandidateInternetMeasurementRecords()
      .then((records) => {
        if (chartLoaded === 0) {
          if (!records) return
          setConnectionRecords(records)
          setChartLoaded(1)
        }
      })
      .catch((err) => console.log(err))
  }, [chartLoaded, fetchCandidateInternetMeasurementRecords])

  return (
    <div className={className}>
      {isFetching && (
        <>
          <Skeleton
            data-testid="skeleton"
            height={30}
            className="margin-bottom"
          />
          <Skeleton data-testid="skeleton" height={26} />
          <Skeleton data-testid="skeleton" height={400} />
        </>
      )}
      {noRecords && <p>{t('No records found')}</p>}
      {!isFetching && !noRecords && (
        <>
          {/* <InternetMeasurementDetails connectionRecords={connectionRecords} /> */}
          <InternetMeasurementChart
            userName={application?.user?.name}
            connectionRecords={connectionRecords}
          />
        </>
      )}
    </div>
  )
}

export default styled(InternetMeasurement)`
  padding: 0 40px;

  .margin-bottom {
    margin-bottom: 10px;
  }
  p {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
`
