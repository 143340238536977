import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ToggleMenuButton from '../components/Buttons/ToggleMenuButton'
import { IExam, IAppeal } from '../types'
import Card from './Card/Card'
import CardSubtitle from './ExamDashboardComponents/CardSubtitle'
import CardTitle from './ExamDashboardComponents/CardTitle'
import Flex from './Flex'
import ProfilePicture from './ProfilePicture'
import styled from 'styled-components'
import AppealSubtitlesInfos from './Appeal/AppealSubtitlesInfos'
import AppealsStatus from './Appeal/AppealsStatus'
import axios from 'axios'
import { API_HOST } from 'consts'
import Html from './Html'

type AppealInfoCardProps = {
  className?: string
  appeal: IAppeal
  toggleMenuDisabled?: boolean
  hideCandidateName?: boolean
  exam?: IExam | undefined
}

const AppealInfoCard: React.FC<AppealInfoCardProps> = ({
  className,
  appeal,
  toggleMenuDisabled = false,
  hideCandidateName,
  children
}) => {
  const { t } = useTranslation()
  const [appealAnswer, setAppealAnswer] = useState(null)

  const getAppealAnswer = useCallback(async (): Promise<void> => {
    axios
      .get(`${API_HOST}/v1/appeals/${appeal?.id}/details`)
      .then((res) => setAppealAnswer(res.data))
      .catch((err) => console.log(err))
  }, [appeal])

  useEffect(() => {
    if (!appealAnswer) {
      getAppealAnswer()
    }
  }, [appealAnswer, getAppealAnswer])

  return (
    <Card className={className}>
      <ProfilePicture user={appeal.application.user} />
      <div>
        <CardTitle>
          {hideCandidateName
            ? 'Candidato #' + appeal.application.user.publicIdentifier
            : appeal.application.user.name}
          <span className="subtitle-small">{appeal.application.exam.name}</span>
          <Flex>
            <AppealsStatus
              className="appeal-status"
              status={appeal?.status?.tag}
            />
            <ToggleMenuButton disabled={toggleMenuDisabled}>
              {children}
            </ToggleMenuButton>
          </Flex>
        </CardTitle>
        <CardSubtitle>
          <AppealSubtitlesInfos
            appealId={appeal.id}
            item={appeal.position}
            createdAt={appeal?.createdAt}
          />
        </CardSubtitle>
        <div className="appeal-content">
          <h4>{t('Content')}</h4>
          <span>{appeal.content}</span>
        </div>
        <div className="appeal-bibliography">
          <h4>{t('Bibliography')}</h4>
          <span>{appeal.bibliography}</span>
        </div>
        {appealAnswer?.status?.tag === 'disclosed' ? (
          <div className="appeal-bibliography">
            <h4>{t('Answer to candidate')}:</h4>
            <Html>{appealAnswer?.response}</Html>
          </div>
        ) : (
          ''
        )}
      </div>
    </Card>
  )
}

export default styled(AppealInfoCard)`
  display: grid;
  grid-template-columns: 55px auto;
  grid-column-gap: 1rem;

  ${CardTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    ${AppealsStatus} {
      margin-right: 5px;
      width: 4.9rem;
      text-align: center;
    }
    div {
      margin-left: 1rem;
    }
  }
  ${CardSubtitle} {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
    padding-right: 1.5rem;
  }

  .subtitle-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  .appeal-content {
    display: grid;

    h4 {
      font-size: 1.1rem;
      margin-top: 6px;
    }
    span {
      text-align: justify;
      font-size: 1rem;
      margin-top: -16px;
    }
  }
  .appeal-bibliography {
    display: grid;

    h4 {
      font-size: 1.1rem;
      margin-top: 6px;
    }
    span {
      text-align: justify;
      font-size: 1rem;
      margin-top: -16px;
    }
    ${Html} {
      margin-top: -30px;
    }
  }
`
