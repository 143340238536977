import React, { useContext, useEffect, useState } from 'react'
import useQuery from '../hooks/useQuery'
import { IItemWithFreeResponse } from 'types'
import { Formik, FormikValues } from 'formik'
import HierarchyFilterBox from 'components/HierarchyFilterBox'
import CardList from 'components/CardList'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'states/AuthState'
import styled from 'styled-components'
import devices from 'utils/devices'
import Page from 'components/Page'
import useCollection from 'hooks/useCollection'
import ResultsFound from 'components/ResultsFound'
import ItemState from 'states/ItemState'
import ItemCard from 'components/ItemList/ItemCard'
import PaginationFooter from 'components/PaginationFooter'
import Input from 'components/Formik/Inputs/Input'
import QuestionInput from 'components/Inputs/questionInput'
import CorrectionStatusInput from 'components/Inputs/CorrectionStatusInput'
import Skeleton from 'react-loading-skeleton'
import ExamByCollectionInput from 'components/Inputs/ExamByCollectionInput'

type ItemsProps = {
  className?: string
}

const ItemsList = ({ className }: ItemsProps) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const [initialValues, setInitialValues] = useState<FormikValues>({})
  const [loadingInitialValues, setLoadingInitialValues] = useState<boolean>(
    true
  )
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')
  const collection = useCollection(collectionId)

  useEffect(() => {
    setInitialValues(
      JSON.parse(localStorage.getItem('items-list-filter') || '{}')
    )
    setLoadingInitialValues(false)
  }, [])

  return (
    <Page customWidth={1366}>
      {!loadingInitialValues && (
        <ItemState initialValues={initialValues}>
          {({
            freeResponse,
            totalItems,
            fetchingItems,
            handlePageChange,
            pageSize,
            onFilter,
            numPages
          }: IItemWithFreeResponse) => (
            <div className={className}>
              {!loadingInitialValues && (
                <div className="parent-wrapper">
                  <div className="filter-wrapper">
                    <Formik initialValues={initialValues} onSubmit={onFilter}>
                      {(formik) => (
                        <HierarchyFilterBox
                          distinct={false}
                          providerCodename={user?.provider?.codename}
                        >
                          <ExamByCollectionInput
                            collectionId={collection?.id}
                            label={t('Exam_name')}
                            name="exam"
                          />
                          <QuestionInput
                            name="position"
                            examId={formik?.values?.exam?.id}
                            label={t('Object_of_Learning')}
                          />
                          <CorrectionStatusInput />
                          <Input
                            name="publicIdentifier"
                            label={t('Public Identifier')}
                          />
                          <Input
                            name="correction_value"
                            label={t('correction_value')}
                          />
                          <Input
                            name="free_response"
                            label={t('free_response')}
                          />
                        </HierarchyFilterBox>
                      )}
                    </Formik>
                  </div>
                  <div className="card-list-wrapper">
                    <ResultsFound resultsFound={totalItems} />
                    <CardList>
                      {fetchingItems && (
                        <Skeleton height={150} count={pageSize} />
                      )}
                      {!fetchingItems &&
                        freeResponse.map((results) => (
                          <ItemCard
                            key={results?.id}
                            items={results}
                            itemId={results?.item?.id}
                          />
                        ))}
                    </CardList>
                    <PaginationFooter
                      pageSize={pageSize}
                      pageCount={numPages}
                      onPageChange={handlePageChange}
                      disablePageSizeChange
                    >
                      <p>
                        {t('Showing {{itemsLenght}} of {{totalItems}} found', {
                          itemsLenght: freeResponse.length,
                          totalItems: totalItems
                        })}
                      </p>
                    </PaginationFooter>
                  </div>
                </div>
              )}
            </div>
          )}
        </ItemState>
      )}
    </Page>
  )
}

export default styled(ItemsList)`
  .appealList {
    padding: 1rem;
    .appeal-exam {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      margin-top: -10px;

      p {
        font-size: 1.3rem;
      }
    }
    .appeal-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.1rem;
      font-weight: 700;
      margin-top: 6px;

      p {
        font-size: 0.85rem;
        color: ${(props) => props.theme.clientColors.secondaryDark};
      }
    }
    .card-list-Results {
      padding: 1rem;
    }
    .appeal-header-infos {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 0.9rem;
      font-weight: 700;
      padding-right: 1.5rem;
      margin-top: -1.4rem;
    }
    .appeal-id-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.9rem;
      font-weight: 600;
      margin-top: -5px;

      p {
        margin-left: 10px;
      }
    }
    .appeal-content {
      display: grid;

      h4 {
        font-size: 1rem;
        font-weight: 600;
        margin-top: -2px;
      }
      span {
        text-align: justify;
        font-size: 1rem;
        margin-top: -16px;
      }
    }
    .appeal-bibliography {
      display: grid;

      h4 {
        font-size: 1rem;
        font-weight: 600;
        /* margin-top: -0.3px; */
      }
      span {
        text-align: justify;
        font-size: 1rem;
        margin-top: -16px;
      }
    }
    .appeal-created-at {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .parent-wrapper {
    display: flex;
    column-gap: 20px;
  }

  @media (max-width: ${devices.mobileL}) {
    .parent-wrapper {
      flex-direction: column;
      row-gap: 20px;
      column-gap: 0;
    }
  }

  .filter-wrapper {
    max-width: 400px;

    @media (min-width: ${devices.tablet}) {
      min-width: 400px;
      column-gap: 20px;
    }
  }

  .card-list-wrapper {
    padding-left: 1rem;
    flex-grow: 6;
  }
`
