import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export type IFeedBackStatus = 'ok' | 'pending' | 'saving' | 'error'
type IFeedBackIcon = 'check' | 'asterisk' | 'spinner' | 'times'

const getIcon = (status: IFeedBackStatus) => {
  return {
    ok: 'check',
    pending: 'asterisk',
    saving: 'spinner',
    error: 'times'
  }[status] as IFeedBackIcon
}

const getColorClass = (status: IFeedBackStatus) =>
  ({
    ok: '#28A744',
    pending: '#5FA1FF',
    saving: '#5FA1FF',
    error: '#DD3444'
  }[status])

const AutosaveFeedback = ({ status }: { status: IFeedBackStatus }) => {
  const icon: IFeedBackIcon = getIcon(status)
  if (icon) {
    return (
      <span className="no-icon">
        <FontAwesomeIcon
          color={getColorClass(status)}
          icon={icon}
          pulse={status === 'saving'}
        />
      </span>
    )
  }
  return <div className="no-icon" />
}

export default styled(AutosaveFeedback)``
