import Rollbar from 'rollbar'
import { ROLLBAR_ENV, ENABLE_ROLLBAR, ROLLBAR_ACCESS_TOKEN } from 'consts'

export const RollbarErrorTracking = (() => {
  /**
   * Function to convert any type to boolean
   * @param {(string|number|boolean)} value - Input type for conversion
   * @return {boolean} Return boolean value
   */
  const toBoolean = (value: string | number | boolean | undefined): boolean => {
    if (value === 'true') {
      return true
    }
    return typeof value === 'string' ? !!+value : !!value
  }

  const rollbarObj = new Rollbar({
    accessToken: ROLLBAR_ACCESS_TOKEN,
    environment: ROLLBAR_ENV,
    enabled: toBoolean(ENABLE_ROLLBAR),
    captureUncaught: true,
    captureUnhandledRejections: true
  })
  /**
   * This function will log information message to rollbar application
   * @param {string} info - Info input text
   */
  const logInfo = (info: Rollbar.LogArgument) => {
    rollbarObj.info(info)
  }
  /**
   * This function will log error message to rollbar application
   * @param {string} error - Error input text text
   */
  const logError = (error: Rollbar.LogArgument) => {
    rollbarObj.error(error)
  }
  /**
   * This function will log information message to rollbar application
   * @param {string} warning - Warning input text
   */
  const logWarning = (warning: Rollbar.LogArgument) => {
    rollbarObj.warning(warning)
  }
  /**
   * This function will log critical error message to rollbar application
   * @param {string} critical - Critical input text
   */
  const logCritical = (critical: Rollbar.LogArgument) => {
    rollbarObj.critical(critical)
  }
  return { logInfo, logError, logWarning, logCritical }
})()
