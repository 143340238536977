import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { IApplication, IBooleanPreference, IStringPreference } from 'types'
import usePreference from 'hooks/usePreference'

type SeeReportCardLinkProps = {
  application?: IApplication
  className?: string
}

const SeeReportCardLink = ({
  application,
  className
}: SeeReportCardLinkProps) => {
  const { t } = useTranslation()
  const exams_link =
    window.location.protocol + '//' + window.location.host + '/aluno-relatorio/'
  const {
    answerSheetDisclosed,
    postItemNavigation,
    postAnswerVisualization,
    showAnswerSheetToCandidate
  } = application?.exam?.collection

  const redirectToStudentReport = usePreference<IBooleanPreference>(
    'Application__RedirectToStudentReport'
  )
  const externalStudentReportLink = usePreference<IStringPreference>(
    'Application__ExternalStudentReportLink'
  )
  const [shouldShowLink, setShouldShowLink] = useState(false)

  useEffect(() => {
    if (!window.location.href.includes('avaliamt')) {
      setShouldShowLink(true)
    }
  }, [])

  if (!application) {
    return <span></span>
  }
  const redirectTo = () => {
    if (redirectToStudentReport?.value) {
      return exams_link
    } else {
      return `${externalStudentReportLink?.value}/aluno/`
    }
  }

  return (
    <>
      {answerSheetDisclosed &&
        postItemNavigation &&
        postAnswerVisualization &&
        showAnswerSheetToCandidate && (
          <Link
            className={className}
            to={{
              pathname: `/answer-list/${application?.id}`,
              state: [
                {
                  ShowHeader: true,
                  ShowAnswers: true
                }
              ]
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {t('See report-card')}
          </Link>
        )}

      {shouldShowLink && application?.idn && (
        <Link
          className={className}
          to={redirectTo() + `${application.idn}`}
          onClick={() =>
            window.open(`${redirectTo()}${application.idn}`, '_self')
          }
        >
          {t('Application report')}
        </Link>
      )}
    </>
  )
}

export default styled(SeeReportCardLink)`
  font-weight: bold;
  color: ${(props) => props.theme.applicationStatus.AVAILABLE};
`
