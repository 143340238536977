import React from 'react'
import { useTranslation } from 'react-i18next'
import InputAsync from './InputAsync'

const FinishedApplicationsFilter = ({ params, ...props }: { params?: any }) => {
  const { t } = useTranslation()

  const fetchOptions = async () => {
    return [
      { value: 'FINISHED', name: 'Finalizadas' },
      { value: 'AVAILABLE', name: 'Pendentes' },
      { value: 'STARTED', name: 'Iniciadas' },
      { value: 'UNAVAILABLE', name: 'Indisponíveis' }
    ]
  }

  return (
    <InputAsync
      key={JSON.stringify(params)}
      defaultOptions
      loadOptions={fetchOptions}
      label={t('Finished applications')}
      name="finished_applications"
      getOptionValue={(d) => d.value}
      getOptionLabel={(d) => d.name}
      {...props}
    />
  )
}

export default FinishedApplicationsFilter
