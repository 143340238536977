import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type TabSelectorProps = {
  className?: string
  onTabSelected: (tabCode: string) => void
  tabs: string[]
  defaultTab?: string
  selectedTab?: string
}

const TabSelector: React.FC<TabSelectorProps> = ({
  className,
  onTabSelected,
  tabs,
  defaultTab,
  selectedTab
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    onTabSelected(defaultTab)
  }, [defaultTab, onTabSelected])

  const handleSelect = (menu: string) => {
    onTabSelected(menu)
  }

  return (
    <div className={className}>
      {tabs.map((tab) => {
        return (
          <p
            key={tab}
            onClick={() => handleSelect(tab)}
            className={`menu-item ${selectedTab === tab ? 'selected' : ''}`}
          >
            {t(tab)}
          </p>
        )
      })}
    </div>
  )
}

export default styled(TabSelector)`
  padding: 1rem;
  display: flex;
  column-gap: 20px;

  .menu-item {
    font: normal normal bold 17px Open Sans, sans-serif;
    color: #5a5a5a;
    cursor: pointer;
    margin: 0;
  }

  .selected {
    color: #23607a;
    text-decoration: underline;
  }
`
