import { useCallback } from 'react'
import useFirebaseCollection from './useFirebaseCollection'
import { getNotSeenRoomMessages } from 'data/apis/message-exchange'
import { Message } from 'data/domain/message-exchange'

function useNotSeenRoomMessages(roomId: string | undefined) {
  const _getNotSeenRoomMessages = useCallback(() => {
    if (!roomId) {
      return null
    }
    return getNotSeenRoomMessages(roomId)
  }, [roomId])

  const { collection: messages } = useFirebaseCollection<Message>(
    _getNotSeenRoomMessages
  )
  return { messages }
}

export default useNotSeenRoomMessages
