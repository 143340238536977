import Button from 'components/Button'
import AppealInfoCard from 'components/AppealsInfoCard'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IExam, IAppeal } from '../../types'

type AppealCardProps = {
  appeal: IAppeal
  exam?: IExam | undefined
}

const AppealCard = ({ appeal }: AppealCardProps) => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    if (expanded) {
      setExpanded(!expanded)
    }
  }, [setExpanded, expanded])

  const getStatus = () => {
    if (appeal?.status?.tag === 'analysis') {
      return 'Responder'
    }
    return 'Visualizar'
  }

  const hideCandidateName =
    appeal?.application?.exam?.collection?.appealIssuingWindow
      ?.hideCandidateName

  return (
    <>
      <AppealInfoCard appeal={appeal} hideCandidateName={hideCandidateName}>
        <Button
          action="unstyled"
          type="button"
          onClick={() =>
            history.push({
              pathname: `/appeals-response/${appeal?.id}/`,
              search: `?&id=${appeal?.id}&item=${appeal?.position}&hide=${hideCandidateName}`
            })
          }
        >
          {expanded ? t('Close') : getStatus()}
        </Button>
      </AppealInfoCard>
    </>
  )
}

export default AppealCard
