/* eslint-disable react/prop-types */
/* eslint no-unused-vars: 0 */
import devices from 'utils/devices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import usePreference from 'hooks/usePreference'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import { VideoStreamingContext } from '../states/VideoStreamingState'
import { IApplication, IBooleanPreference } from '../types'
import { formatDateTimeString } from '../utils/date'
import ApplicationStatus from './ApplicationStatus'
import Card from './Card/Card'
import CardDetails from './CardDetails'
import CardSubtitle from './ExamDashboardComponents/CardSubtitle'
import CardTitle from './ExamDashboardComponents/CardTitle'
import ExamInfoLabelHome from './ExamInfoLabelHome'
import ExamInfoLabel from './ExamInfoLabel'
import FinishReasonIndicator from './FinishReasonIndicator'
import RemainingTime from './RemainingTime'
import SeeResultsLink from './SeeResultsLink'
import { postAnswerVisualization as _postAnswerVisualization } from 'data/utils/applications'
import SeeInstructionLink from './SeeInstructionLink'
import SeeReportCardLink from './SeeReportCardLink'
import { API_HOST, EXAMS_ORAL_URL } from 'consts'
import axios from 'axios'
import { getToken } from '../utils/auth'
import KioskToken from 'utils/kioskToken'
import { toast } from 'react-toastify'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import swal from 'sweetalert2'
import Button from './Button'

type ApplicationCardProps = {
  className?: string
  application: IApplication
  postAnswerVisualization?: typeof _postAnswerVisualization
}

const ApplicationCard = ({
  application,
  className,
  postAnswerVisualization = _postAnswerVisualization
}: ApplicationCardProps) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const history = useHistory()

  const configuration = useApplicationConfiguration(
    application?.exam?.collection?.applicationConfiguration
  )

  const { hasPermissionError, granted, triedToAskForPermission } = useContext(
    VideoStreamingContext
  )

  const kioskToken = new KioskToken()
  let maxDuration: string | undefined
  const showMaxDuration = usePreference<IBooleanPreference>(
    'Application__ShowMaxDuration'
  )

  const { startedAt, finishedAt } = application
  /* eslint no-unused-vars: 0 */
  let timeWindows
  if (application.timeWindows && application.timeWindows.length > 0) {
    timeWindows = application.timeWindows
  } else {
    timeWindows = application.exam.timeWindows
  }

  const shouldRenderLink = ['AVAILABLE', 'STARTED'].includes(application.status)

  // According to the image in the task card, the See Results
  // label is the same color as the Available status

  /* eslint no-unused-vars: 0 */
  const renderDuration = () => {
    if (application.hideMaxDuration) {
      return null
    }
    switch (application.status) {
      case 'STARTED':
        return (
          <ExamInfoLabel label="Remaining time" icon={['far', 'clock']}>
            <RemainingTime
              initial={Date.now()}
              allowHideRemainingTime={false}
              secondsToTimeout={application.secondsToTimeoutWhenLoaded}
            />
          </ExamInfoLabel>
        )
      case 'FINISHED':
        return null
      default:
        if (showMaxDuration?.value) {
          return <ExamInfoLabel label="Time">{maxDuration}</ExamInfoLabel>
        }
        return null
    }
  }

  const getInWaitingQueue = () => {
    axios.post(
      `${API_HOST}/v1/applications/${application.id}/create_waiting_queue`,
      {
        application: application.id
      }
    )
  }

  const redirectToOral = async () => {
    if (kioskToken.get() || configuration?.canOpenInAnyBrowser) {
      const response = await axios.get(
        `${API_HOST}/v1/user_session?user=${application?.user.id}`
      )

      const res = response.data

      try {
        const userToken = getToken()
        if (
          res?.browserFamily === 'Firefox' &&
          res?.browserVersion === '52.0'
        ) {
          history.push('/erro-firefox')
        } else {
          await getInWaitingQueue()
          window.location.replace(`${EXAMS_ORAL_URL}redirect/${userToken}`)
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      toast.error(t('You should access through the Safe Browser.'))
    }
  }

  const ApplicationFinished = application?.status !== 'FINISHED'
  const ApplicationUnknown = application?.status !== 'UNAVAILABLE'

  const handleCardClick = () => {
    if (triedToAskForPermission && (!granted || hasPermissionError)) {
      history.push('/camera-permission-tutorial')
    } else if (
      application?.exam?.examsOral &&
      ApplicationFinished &&
      ApplicationUnknown
    ) {
      redirectToOral()
    } else if (application.mandatoryCollectionUnfinished) {
      swal.fire({
        icon: 'error',
        title: `Essa atividade só ficará disponível após a conclusão da avaliação ${application?.mandatoryCollectionName}`
      })
    } else {
      history.push(application.instructionsUrl)
    }
  }

  return (
    <Card
      className={`${className} ${shouldRenderLink ? 'available' : ''}`}
      onClick={() => handleCardClick()}
    >
      <div className="card-container">
        <div className="icon">
          <FontAwesomeIcon
            icon={application?.exam?.collection?.iconName}
            style={{ color: theme.clientColors.primary, fontSize: '40px' }}
          />
        </div>
        <div className="card-body">
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <CardTitle style={{ alignItems: 'center' }}>
              <strong
                style={{
                  color: 'var(--Neutral-color---Darker, #262626)',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  marginBottom: '0.5rem',
                  alignItems: 'center',
                  lineHeight: '1.25rem'
                }}
              >
                {application.title}
              </strong>
              <ApplicationStatus
                status={application.status}
                reseted={application.reseted}
                timeWindows={application.exam.timeWindows}
              />
            </CardTitle>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'flex-start', gap: '1.5rem' }}
          >
            <ExamInfoLabelHome label="Área de Conhecimento">
              <span
                style={{
                  color: '#262626',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 450,
                  lineHeight: '1.25rem'
                }}
              >
                {application?.exam?.collection?.knowledgeAreaDescription}
              </span>
            </ExamInfoLabelHome>
            <ExamInfoLabelHome label="Componente curricular">
              <span
                style={{
                  color: '#262626',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 450,
                  lineHeight: '1.25rem'
                }}
              >
                {application?.exam?.componenteCurricularDescription}
              </span>
            </ExamInfoLabelHome>
          </div>

          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '2rem' }}>
            <ExamInfoLabelHome label="Nº de atividade">
              <span
                style={{
                  color: '#262626',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 450,
                  lineHeight: '1.25rem'
                }}
              >
                {application?.exam?.totalExams}
              </span>
            </ExamInfoLabelHome>

            <ExamInfoLabelHome label="Nº de questões">
              <span
                style={{
                  color: '#262626',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 450,
                  lineHeight: '1.25rem'
                }}
              >
                {application?.exam?.totalAnswer}
              </span>
            </ExamInfoLabelHome>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1.5rem',
              alignSelf: 'stretch'
            }}
          >
            <ExamInfoLabelHome label="Início">
              <span
                style={{
                  color: '#262626',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 450,
                  lineHeight: '1.25rem'
                }}
              >
                {startedAt && formatDateTimeString(startedAt)}
              </span>
            </ExamInfoLabelHome>

            <ExamInfoLabelHome label="Término">
              <span
                style={{
                  color: '#262626',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 450,
                  lineHeight: '1.25rem'
                }}
              >
                {finishedAt && formatDateTimeString(finishedAt)}
              </span>
            </ExamInfoLabelHome>
            <ExamInfoLabelHome label="Duração">
              <span
                style={{
                  color: '#262626',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 450,
                  lineHeight: '1.25rem'
                }}
              >
                {application.duration}
              </span>
            </ExamInfoLabelHome>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1.5',
              alignSelf: 'stretch'
            }}
          >
            <ExamInfoLabelHome label="Docente">
              <span
                style={{
                  color: '#262626',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 450,
                  lineHeight: '1.25rem'
                }}
              >
                {application?.exam?.collection?.createdBy}
              </span>
            </ExamInfoLabelHome>
          </div>
          {application?.exam?.areaDoConhecimento === 8 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1.5',
                alignSelf: 'stretch'
              }}
            >
              <ExamInfoLabelHome label="Vista da Redação">
                <Button
                  action="link"
                  onClick={() =>
                    window.open(
                      `https://sesi-alagoas-correcao.educat.net.br/vista-prova-sesi/${application?.idn}`,
                      '_blank'
                    )
                  }
                  marginBottom="0rem"
                  marginTop="10px"
                >
                  clique aqui
                </Button>
              </ExamInfoLabelHome>
            </div>
          )}
          <FinishReasonIndicator finishReason={application.finishReason} />
          <div className="results-link">
            {application.reportCardId && (
              <SeeResultsLink reportCardId={application.reportCardId} />
            )}
            {application?.showInstructionLink && (
              <SeeInstructionLink application={application} />
            )}
            {<SeeReportCardLink application={application} />}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default styled(ApplicationCard)`
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 0px 4px rgba(58, 58, 58, 0.25);
  width: 100%;

  .icon {
    background: ${(props) => props.theme.clientColors.secondary};
    border-radius: 100%;
    width: 120px;
    height: 120px;
    display: flex;
    padding: 3rem;
    align-items: center;
    justify-content: center;
  }

  .results-link {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    margin-left: 0.2rem;
  }

  .card-body {
    margin-left: 1rem;
  }

  .time-exam {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
  ${CardTitle} {
    color: ${(props) => props.theme.clientColors.text};
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      margin-right: 15px;
    }

    strong {
      margin-right: 15px;
    }

    a:visited {
      color: unset;
    }

    a:not(:visited) {
      color: unset;
    }
  }

  .card-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }

  ${ExamInfoLabel} {
    font-size: 14px;
    margin-top: 0.5rem;
    max-width: 300px;
    color: #4a4a4a;
  }

  .card-footer {
    width: auto;
    padding: 0 0.6rem;
    margin-top: 1rem;
    border-radius: 0px 0px 16px 16px;
    display: flex;
    justify-content: space-between;
  }

  .mt-10px {
    margin-top: 10px;
  }

  ${CardDetails} {
    @media (max-width: 600px) {
      .mt-10px {
        svg {
          display: none;
        }
      }
    }
  }

  ${CardSubtitle} {
    display: grid;

    column-gap: 1rem;
    margin-top: 1rem;

    @media (max-width: 600px) {
      grid-template-columns: 1fr 1fr;
      row-gap: 1rem;

      span {
        font-size: 10px;
        svg {
          display: none;
        }
      }
    }
  }

  @media (max-width: ${devices.mobileL}) {
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    border: none;
    border-radius: 16px;
    box-shadow: 0px 0px 4px rgba(58, 58, 58, 0.25);
    .card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .results-link {
      margin-top: 0.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-left: 0.2rem;
    }

    .time-exam {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .card-body {
      margin-top: 1rem;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
    }
    .icon {
      background: #eae4fb;
      border-radius: 100%;
      width: 150px;
      height: 150px;
      display: flex;
      padding: 3rem;
      align-items: center;
      justify-content: center;
    }
    ${CardTitle} {
      color: ${(props) => props.theme.clientColors.text};
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        margin-right: 15px;
      }

      strong {
        margin-right: 15px;
      }

      a:visited {
        color: unset;
      }

      a:not(:visited) {
        color: unset;
      }
    }

    ${ExamInfoLabel} {
      font-size: 14px;
      margin-top: 0.5rem;
      color: #4a4a4a;
    }

    .card-footer {
      width: auto;
      margin-top: 1rem;
      border-radius: 0px 0px 16px 16px;
      padding: 0.6rem;
      display: flex;
      flex-direction: column;
    }

    .mt-10px {
      margin-top: 10px;
    }

    ${CardDetails} {
      @media (max-width: 600px) {
        .mt-10px {
          svg {
            display: none;
          }
        }
      }
    }

    ${CardSubtitle} {
      display: flex;
      flex-direction: column;
    }
  }
`
