import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Candidate } from '../data/domain/message-exchange'
import { useTranslation } from 'react-i18next'

type Props = {
  className?: string
  candidate?: Candidate
}

const ApplicationStatusIcon = ({ className, candidate }: Props) => {
  const { t } = useTranslation()
  const [totalApplications, setTotalApplications] = useState<number>()
  const [applicationCounter, setApplicationCounter] = useState<number>(0)

  useEffect(() => {
    if (candidate?.totalApplications === undefined) return

    if (candidate?.applicationCounter) {
      setApplicationCounter(candidate?.applicationCounter)
    }
    setTotalApplications(candidate?.totalApplications)

    if (candidate?.applicationCounter >= candidate?.totalApplications) {
      setApplicationCounter(candidate?.totalApplications)
    }
  }, [candidate])

  const tooltipText = () => {
    if (totalApplications) {
      return (
        <ul>
          <li>
            <div className="color-on" />
            <p>{t('Exam started')}</p>
          </li>
          <li>
            <div className="color-off" />
            <p>{t('Exam finished')}</p>
          </li>
        </ul>
      )
    } else {
      return t('wait, soon you will see the state of exam this user')
    }
  }

  return (
    <div className={className}>
      {totalApplications ? (
        <>
          <div className="top">{+applicationCounter}</div>
          <div className="middle">/</div>
          <div className="bottom">{totalApplications}</div>
        </>
      ) : (
        <span className="ball" />
      )}
      <span className="tooltip-container">{tooltipText()}</span>
    </div>
  )
}

export default styled(ApplicationStatusIcon)`
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 20px;
  position: relative;

  &:hover {
    .tooltip-container {
      visibility: visible;
    }
  }

  .ball {
    background: ${(props) => props.theme.colors.grayDark};
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    bottom: 3px;
    right: -2px;
  }

  .tooltip-container {
    visibility: hidden;
    color: #fff;
    z-index: 1000;
    position: absolute;
    bottom: -100px;
    background: ${(props) => props.theme.colors.grayDark};
    border-radius: 4px;
    padding: 4px;
    min-width: 100px;
  }

  .color-on {
    height: 8px;
    width: 8px;
    background: #ff9000;
  }

  .color-off {
    height: 8px;
    width: 8px;
    background: #528652;
  }

  ul {
    margin: 0;
    padding: 0;
    width: 130px;
    li {
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      margin-left: 4px;

      p {
        margin-left: 5px;
      }
    }
  }

  .top {
    color: ${(props) => {
      switch (props.candidate?.isInApplication) {
        case true:
          return '#ff9000'
        case false:
          return '#528652'
      }
    }};

    position: absolute;
    top: -3px;
    left: 0px;
    font-size: 10px;
  }

  .middle {
    position: absolute;
    top: 2px;
    right: 8px;
    transform: rotate(20deg);
  }

  .bottom {
    color: #528652;
    position: absolute;
    bottom: -3px;
    right: 0;
    font-size: 10px;
  }
`
