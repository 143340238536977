import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Input from '../Formik/Inputs/Input'
import HierarchyFilter from '../HierarchyFilter'
import HierarchyInputAsync from '../Inputs/HierarchyInputAsync'
import Separator from '../ExamDashboardComponents/Separator'
import { Form, useFormikContext } from 'formik'
import { get } from 'lodash'
// import { useField, useFormikContext } from 'formik'

type NewStudentFormProps = {
  providerCodename?: string
  className?: string
}

const NewStudentForm = ({
  providerCodename,
  className
}: NewStudentFormProps) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext()
  const [useSameEmail, setUseSameEmail] = useState(true)

  useEffect(() => {
    setFieldValue('password', 'Educat@2022')
    if (!get(values, 'username') && get(values, 'email')) {
      setFieldValue('username', get(values, 'email'))
    }
  }, [])

  const handleUserName = () => {
    if (useSameEmail) {
      setFieldValue('username', get(values, 'email'))
    } else {
      setFieldValue('username', '')
    }
  }

  const handleUserNameClone = () => {
    setUseSameEmail(!useSameEmail)
    return handleUserName()
  }

  return (
    <Form className={className}>
      <Input required label={t('Name')} name="name" />
      <div className="grid">
        <Input label={t('Email')} name="email" />
        <Input
          required
          label={t('Public Identifier')}
          name="public_identifier"
        />
        <HierarchyFilter distinct={false} providerCodename={providerCodename} />
      </div>
      <Separator />

      <div className="grid">
        <Input label={t('Username')} name="username" />
        <Input label={t('Password')} name="password" />
        <div className="checkbox-container">
          <input type="checkbox" onClick={() => handleUserNameClone()} /> Usar o
          email digitado acima?
        </div>
      </div>
    </Form>
  )
}

export default styled(NewStudentForm)`
  ${Input}, ${HierarchyInputAsync} {
    margin-bottom: 1rem;
  }
  .checkbox-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
  .grid {
    display: grid;
    grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
    column-gap: 1rem;
  }
`
