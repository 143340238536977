import axios from 'axios'
import { API_HOST } from 'consts'

const getRecordingPresignUrl = async (key) => {
  try {
    const response = await axios.get(
      `${API_HOST}/recording/upload_url?key=${key}`
    )
    return response.data.uploadUrl
  } catch {
    return false
  }
}

const transformRequest = (data, headers) => {
  // Remove authorization from headears
  delete headers.common.Authorization
  return data
}

const uploadToS3Url = async (
  url,
  data,
  onUploadProgress = null,
  onSuccess = null,
  onError = null
) => {
  const headers = { 'Content-Type': data.type }
  // data.targetService = 'aws_s3'
  axios({
    method: 'put',
    data: data,
    headers: headers,
    transformRequest: [transformRequest],
    url: url,
    onUploadProgress: onUploadProgress
  })
    .then(onSuccess)
    .catch(onError)
}

export const uploadRecordToS3 = async (data: any, key: any) => {
  const uploadUrl = await getRecordingPresignUrl(key)
  const response = uploadToS3Url(uploadUrl, data)
  return response
}
