import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CardSubtitle from './ExamDashboardComponents/CardSubtitle'
import ApplicationToolbar from './ApplicationToolbarComponents/ApplicationToolbar'
import { ApplicationContext } from '../states/ApplicationState'
import AnswerReviewList from './AnswerReviewList'
import AnswerReviewListItem from './AnswerReviewListItem'
import Button from './Button'
import Error from './Error'
import RemainingTime from './RemainingTime'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import { MinStayTimeIndicator } from 'pages/MinStayTimeIndicator'
import { fetchMinStayTime } from 'data/apis/applications'
import { findNextAvaiableAnswer } from 'data/utils/answers'
import { Container } from '@material-ui/core'

type ReviewAnswersProps = {
  className?: string
}

const ReviewAnswers = ({ className }: ReviewAnswersProps) => {
  const {
    answers,
    application,
    resumeApplication,
    finishApplication,
    finishingApplication,
    finishApplicationError,
    remainingTimeInitialDate,
    returnToApplicationList,
    checkAnswersPage,
    secondsToTimeout
  } = useContext(ApplicationContext)
  const { t } = useTranslation()
  const configuration = useApplicationConfiguration(
    application?.exam.collection.applicationConfiguration
  )
  const collection = application?.exam?.collection

  const getTextToRender = () => {
    if (!application || !application.exam) {
      return
    }
    const examIsBrowsable =
      configuration?.canBrowseAcrossItems && configuration?.canUpdateAnswer
    return examIsBrowsable
      ? t('Review your answers before submitting.')
      : t(
          'Here you can see what you answered, but you will not be able to change any answer.'
        )
  }

  const [hasAvaiableAnswer, setHasAvaibleAnswer] = useState(false)
  useEffect(() => {
    if (application && configuration) {
      ;(async () => {
        const nextAvaiableAnswer = await findNextAvaiableAnswer(
          application?.id,
          configuration
        )
        setHasAvaibleAnswer(Boolean(nextAvaiableAnswer))
      })()
    }
  }, [application, configuration])

  const renderApplicationToolBar = () => {
    if (collection?.allowRevisionCommunication) {
      return <ApplicationToolbar collection={collection} />
    }
  }

  const setUnavailableToApplications =
    configuration?.setUnavailableToApplications

  return (
    <Container maxWidth="md" className={className}>
      {renderApplicationToolBar()}
      {!checkAnswersPage && (
        <RemainingTime
          initial={remainingTimeInitialDate}
          secondsToTimeout={secondsToTimeout}
          allowHideRemainingTime={false}
        />
      )}
      <CardSubtitle>{getTextToRender()}</CardSubtitle>
      {application && (
        <CardSubtitle>
          <MinStayTimeIndicator
            applicationId={application.id}
            fetchMinStayTime={fetchMinStayTime}
          />
        </CardSubtitle>
      )}
      <AnswerReviewList>
        {answers.map((answer) => (
          <AnswerReviewListItem
            key={answer.id}
            showItemId={application.exam.shuffleItems}
            answer={answer}
            application={application}
          />
        ))}
      </AnswerReviewList>
      <div className="actions">
        {!finishingApplication && hasAvaiableAnswer && (
          <Button
            action="secondary"
            onClick={() => {
              if (checkAnswersPage) {
                returnToApplicationList()
              } else {
                resumeApplication('')
              }
            }}
          >
            {t('go back')}
          </Button>
        )}
        {!checkAnswersPage && (
          <Button
            isLoading={finishingApplication}
            action="primary"
            onClick={() => {
              finishApplication()
            }}
          >
            {setUnavailableToApplications
              ? 'Finalizar Etapa atual'
              : t('shut down')}
          </Button>
        )}
        {finishApplicationError && <Error>{finishApplicationError}</Error>}
      </div>
    </Container>
  )
}

export default styled(ReviewAnswers)`
  padding: 20px;
  text-align: center;

  ${AnswerReviewList} {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  ${CardSubtitle} {
    margin-top: 1rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // grid-auto-rows: 100px;
    // grid-template-rows: 1fr 1fr;
    // gap: 20px;
  }
`
