import React, { createContext } from 'react'
import { IItemWithFreeResponse } from '../types'
import useList from '../hooks/useList'
import useQuery from 'hooks/useQuery'
import { DASHBOARD_API_HOST } from 'consts'
import { FormikValues } from 'formik'

type ItemProps = {
  children: Function
  initialValues?: any
}

const initialItemState: IItemWithFreeResponse = {
  freeResponse: [],
  fetchingItems: false,
  item: undefined,
  id: undefined,
  position: undefined,
  introduction: undefined,
  question: undefined,
  category: undefined,
  handleFilter: () => undefined,
  handlePageChange: () => undefined,
  handlePageSizeChange: () => undefined,
  onFilter: () => undefined,
  totalItems: undefined,
  pageSize: undefined,
  numPages: undefined,
  attachment: undefined
}

export const ItemWithFreeResponseContext = createContext<IItemWithFreeResponse>(
  initialItemState
)

const ItemState = ({ children, initialValues }: ItemProps) => {
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  const formatListFilter = (params: FormikValues) => {
    return {
      ...params,
      correction_value: params?.correction_value,
      correction_status: params?.correction_status?.id,
      public_identifier: params?.publicIdentifier,
      free_response: params?.free_response,
      exam: params?.exam?.id,
      position: params?.position?.position
    }
  }

  const {
    isLoading: fetchingItems,
    results: freeResponse,
    count: totalItems,
    handleFilter,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    numPages
  } = useList({
    api: `${DASHBOARD_API_HOST}/v1/item_with_answer?in_avaiable_application=&collection=${collectionId}`,
    defaultPageSize: 10,
    defaultFilterParams: formatListFilter(initialValues)
  })

  const saveFilterOnLocalStorage = (params) => {
    const parsedFilter = {}
    Object.entries(params).forEach(([key, value]) => {
      parsedFilter[key] = value === null ? undefined : value
    })
    localStorage.setItem('items-list-filter', JSON.stringify(parsedFilter))
  }

  const onFilter = (params: FormikValues) => {
    saveFilterOnLocalStorage(params)
    handleFilter(formatListFilter(params))
  }

  const contextValue = {
    freeResponse,
    totalItems,
    fetchingItems,
    handleFilter,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    numPages,
    onFilter
  }

  return (
    <ItemWithFreeResponseContext.Provider value={contextValue}>
      {children(contextValue)}
    </ItemWithFreeResponseContext.Provider>
  )
}

export default ItemState
