import styled from 'styled-components'

export default styled.div`
  /* @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  animation-duration: 1s;
  animation-fill-mode: both;

  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; */
`
