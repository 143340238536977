import { setCameraAccepted } from 'data/apis/message-exchange'
import { setRoom } from 'data/apis/online-status'
import { groupBy, max, sortBy } from 'lodash'
import React, {
  ChangeEvent,
  createContext,
  useCallback,
  useContext,
  useReducer,
  useEffect,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { API_HOST, FIREBASE_ENABLED, VIDEO_RECORDING_ENABLED } from '../consts'
import applicationConfigurationStore from '../data/apis/application-configuration'
import useList from '../hooks/useList'
import useQuery from '../hooks/useQuery'
import {
  ApplicationStateAction,
  IApplication,
  IApplicationListState
} from '../types'
import { AuthContext } from './AuthState'
import { VideoStreamingContext } from './VideoStreamingState'
import { isCloserDateFromToday } from '../utils/date'
import { initialState } from 'contexts/ApplicationContext'

type ApplicationListStateProps = {
  children: Function
  initialValues?: any
  sharedFlowValidation?: any
  tipoId?: any
}

const getApplicationsByCollection = (applications: IApplication[]) => {
  const sortedApplications = sortBy(
    applications,
    (d: IApplication) => d.exam.collection.id
  )
  const applicationsByCollection: {
    [key: number]: IApplication[]
  } = groupBy(sortedApplications, (d: IApplication) => d.exam.collection.id)
  return applicationsByCollection
}

export const sortApplicationsByStatus = (applications: IApplication[]) => {
  return applications.sort((a, b) => {
    // Applications should be displayed in the following order
    const priority = [
      'STARTED',
      'AVAILABLE',
      'FINISHED',
      'STOPPED',
      'UNAVAILABLE',
      'UNKNOWN'
    ]

    const aPriority = priority.findIndex((d) => d === a.status)
    const bPriority = priority.findIndex((d) => d === b.status)

    // Sort by exam name when application has same priority
    if (aPriority === bPriority) {
      const aExamName = (a?.exam?.name || '').toLowerCase()
      const bExamName = (b?.exam?.name || '').toLowerCase()
      if (aExamName < bExamName) {
        return -1
      }
      if (aExamName > bExamName) {
        return 1
      }
      return 0
    }
    return aPriority - bPriority
  })
}

const mapApplicationsByCollection = (applications: IApplication[]) => {
  const application = sortApplicationsByStatus(applications)[0]

  // Gets max finish date
  let maxFinishDate: string | undefined
  const finishedAll = applications.every((d) => d.finishedAt)
  if (finishedAll) {
    const finishDates = applications.map((d) => d.finishedAt)
    maxFinishDate = max(finishDates)
  }

  // Gets title and instructions
  let title: string
  let instructionsUrl: string
  let isMultipleApplication: boolean

  // ### REQUESTED BY PRISCILLA IN 04/2020 ###
  // If the collection has only one application,
  // the system should redirect straight to
  // the instructions page.
  if (applications.length > 1) {
    const { collection } = application.exam
    title = collection.name
    instructionsUrl = `/applications?collection=${collection.id}`
    isMultipleApplication = true
  } else {
    // If collection has only one application, goes straight to
    // application instructions
    title = application.exam.name
    instructionsUrl = `/applications/${application.id}`
    isMultipleApplication = false
  }

  return {
    ...application,
    finishedAt: maxFinishDate,
    title,
    instructionsUrl,
    secondsToTimeoutWhenLoaded: application.secondsToTimeout,
    hideMaxDuration: applications.length > 1,
    isMultipleApplication
  }
}

const filterByCollection = (
  applications: IApplication[],
  collectionId: number
) => {
  return applications.filter((d) => d.exam.collection.id === collectionId)
}

const requiresVideo = async (applications: any) => {
  for (const application of applications) {
    const applicationConfigurationId =
      application.exam.collection.applicationConfiguration
    if (!applicationConfigurationId) {
      continue
    }

    const configuration = await applicationConfigurationStore.get(
      applicationConfigurationId
    )
    if (!configuration) {
      continue
    }

    const isVideoRequired = application.exam.collection.shouldStreamToAgora
    //  ||
    // configuration.shouldStreamToFirebase ||
    // configuration.shouldUploadToS3 ||
    // configuration.shouldFaceDetect
    if (isVideoRequired) {
      return configuration
    }
  }
  return null
}

const reducer = (
  state: IApplicationListState,
  action: ApplicationStateAction
): IApplicationListState => {
  switch (action.type) {
    case 'ON_SEARCH_CHANGE':
      return { ...state, search: action.payload }
    case 'ON_FILTER_CHANGE':
      return { ...state, ...action.payload }
    default:
      return state
  }
}
export const ApplicationListContext = createContext({})

export const ApplicationListState = ({
  children,
  tipoId,
  sharedFlowValidation,
  initialValues
}: ApplicationListStateProps) => {
  let apiUrl = `${API_HOST}/v1/applications`

  if (sharedFlowValidation === true) {
    apiUrl += `?category=${tipoId}`
  }

  const {
    results,
    count,
    pageSize,
    numPages,
    handleSearchChange,
    handlePageChange,
    handlePageSizeChange,
    handleFilter
  } = useList({
    api: apiUrl,
    defaultPageSize: 10,
    defaultOrdering: '-end_time,id'
  })
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')
  const { user } = useContext(AuthContext)
  const [roomSetted, setRoomSetted] = useState(false)

  const { startStreaming, permissionsAreOk } = useContext(VideoStreamingContext)
  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'ON_SEARCH_CHANGE', payload: event.target.value })
    handleSearchChange(event.target.value)
  }
  const onPageSizeChange = (event: any) => {
    handlePageSizeChange(event.target.value)
  }

  const formatListFilter = (params: any) => {
    return {
      status: params?.status,
      knowledge_area: params?.areaConheciemento
    }
  }

  const onFilter = (params: any) => {
    handleFilter(formatListFilter(params))
  }

  let applications
  if (collectionId) {
    applications = filterByCollection(results, collectionId).map((d) => ({
      ...d,
      title: d.exam.name,
      instructionsUrl: `/applications/${d.id}`,
      secondsToTimeoutWhenLoaded: d.secondsToTimeout
    }))
  } else {
    const applicationsByCollection = getApplicationsByCollection(results)
    applications = Object.values(applicationsByCollection).map(
      mapApplicationsByCollection
    )
  }
  useEffect(() => {
    if (applications && applications.length && !roomSetted) {
      let first = applications[0]
      let first_timeWindow =
        applications[0]?.timeWindows[0]?.startTime ||
        applications[0]?.exam?.timeWindows[0]?.startTime
      const setTimeWindows = (updateTimeWindow, updateApplication) => {
        first = updateApplication
        first_timeWindow = updateTimeWindow.startTime
      }
      applications.map((updateApplication) =>
        updateApplication.timeWindows.map((updateTimeWindow) =>
          isCloserDateFromToday(updateTimeWindow.startTime, first_timeWindow)
            ? setTimeWindows(updateTimeWindow, updateApplication)
            : undefined
        )
      )
      if (FIREBASE_ENABLED) {
        setRoom(user?.id.toString(), first.roomId.toString())
      }
      setRoomSetted(true)
    }
  }, [roomSetted, applications, user])

  const checkVideo = useCallback(async () => {
    // Foi necessário essa gambiarra para resolver de forma rápido o problema de
    // não iniciar a streaming autometicamente caso possua outra configuração de video já ativa
    const shouldStreamToAgora = applications.some(
      (ap: IApplication) => ap.exam.collection.shouldStreamToAgora
    )
    let configuration = null

    if (shouldStreamToAgora) {
      configuration = await requiresVideo(applications)
    }

    if (configuration) {
      startStreaming(
        configuration.shouldStreamToFirebase,
        configuration.shouldUploadToS3,
        shouldStreamToAgora,
        configuration.shouldFaceDetect,
        configuration.shouldTakeSnapshots,
        configuration.shouldUploadScreenshotToS3
      )
    }

    if (VIDEO_RECORDING_ENABLED && applications && applications.length) {
      const first = applications[0]
      const permissionsOk = await permissionsAreOk()
      if (permissionsOk) {
        setCameraAccepted(first.roomId, user?.id, true)
      } else {
        setCameraAccepted(first.roomId, user?.id, false)
      }
    }
  }, [applications, permissionsAreOk, startStreaming, user])

  useEffect(() => {
    checkVideo()
    if (collectionId && applications.length === 1) {
      history.push('/applications')
    }
  }, [applications, collectionId, history, checkVideo])

  applications = sortApplicationsByStatus(applications)

  const value = {
    ...state,
    results: applications,
    totalResults: count,
    collectionId,
    pageSize,
    numPages,
    onPageSizeChange,
    onSearchChange,
    handlePageChange,
    onFilter
  }

  return (
    <ApplicationListContext.Provider value={value}>
      {children(value)}
    </ApplicationListContext.Provider>
  )
}

export default ApplicationListState
