import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import educatLogo from '../assets/img/educat.png'
import Button from '../components/Button'
import Page from '../components/Page'
import { userHasDocument } from '../components/UserDocument'
import { DEFAULT_LOGO } from '../consts'
import useQuery from '../hooks/useQuery'
import { AuthContext } from '../states/AuthState'
import NewSession from './NewSession'
import streamingManager from 'videoStreaming'
type LoginProps = {
  className?: string
}
const Login = ({ className }: LoginProps) => {
  const {
    login,
    loginError,
    networkError,
    isSubmitting,
    detailedErrors,
    showNewSessionPage,
    hasGroup
  } = useContext(AuthContext)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const { t } = useTranslation()
  const history = useHistory()
  const query = useQuery()
  const theme = useContext(ThemeContext)
  streamingManager.destroy()
  const handleLogin = async (setNewClient = false) => {
    const success = await login({ username, password, setNewClient })

    if (success) {
      if (hasGroup('ALUNO')) {
        if (!(await userHasDocument())) {
          history.push('upload-user-document')
          return
        }
      }
      const next = query.get('next') || '/'
      history.push(next)
    }
  }
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleLogin()
  }
  if (showNewSessionPage) {
    return <NewSession onConfirm={() => handleLogin(true)} />
  }
  return (
    <Page className={className}>
      <img src={theme.logoImg || DEFAULT_LOGO || educatLogo} alt={theme.name} />
      <form onSubmit={onSubmit}>
        <input
          required
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          placeholder={t('Username')}
        />
        <input
          required
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder={t('Password')}
        />
        <Button action="primary" type="submit" isLoading={isSubmitting}>
          {t('login').toUpperCase()}
        </Button>
        <Button
          onClick={() => history.push('/forgot-password')}
          action="unstyled"
          type="button"
        >
          {t('Forgot my password')}
        </Button>
        {networkError && (
          <p>{t('Could not connect to the server, try again later.')}</p>
        )}
        {loginError && <p>{detailedErrors?.nonFieldErrors}</p>}
      </form>
    </Page>
  )
}
export default styled(Login)`
  margin-top: 5%;
  text-align: center;
  img {
    margin-top: 100px;
    margin-bottom: 50px;
    max-height: 200px;
    max-width: 300px;
  }
  form {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 300px;
    justify-content: center;
    input {
      font-size: 16px;
      padding: 5px;
      line-height: 1.5;
      background-color: white;
      border: 2px solid ${(props) => props.theme.colors.grayLight};
      border-radius: 5px;
    }
  }
`
