import Button from 'components/Button'
import useFeedback from 'hooks/useFeedback'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { errors } from 'resources'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import TextArea from 'components/Formik/Inputs/textarea'
import Checkbox from 'components/Inputs/Checkbox'
import CandidateActionDrawer from './CandidateActionDrawer'
import * as Yup from 'yup'

const MESSAGE_MAX_LENGTH = 400

const ValidationSchema = Yup.object().shape({
  message: Yup.string().required('Required').max(MESSAGE_MAX_LENGTH)
})

const initialValues = {
  message: '',
  showMessage: false
}

type ExplanationDrawerProps = {
  className?: string
  onClose: () => void
  candidateName: string
  isOpen: boolean
  onSubmit: (message: string, showMessage: boolean) => Promise<void>
  title: string
  showMessageToCandidate?: boolean
}

const ExplanationDrawer = ({
  candidateName,
  onClose,
  isOpen,
  onSubmit,
  title,
  showMessageToCandidate = true
}: ExplanationDrawerProps) => {
  const { t } = useTranslation()
  const { handleErrorFeedback } = useFeedback()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      try {
        await onSubmit(values.message, values.showMessage)
        handleClose()
      } catch (error) {
        handleErrorFeedback(error, errors.DEFAULT)
      }
    },
    [onSubmit, handleClose, handleErrorFeedback]
  )

  return (
    <CandidateActionDrawer
      isOpen={isOpen}
      onClose={handleClose}
      title={t(title)}
      candidateName={candidateName}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema}
      >
        {(formik) => (
          <Form>
            <TextArea
              name="message"
              placeholder={t('Add an explanation')}
              maxLength={MESSAGE_MAX_LENGTH}
            />
            {showMessageToCandidate && (
              <Checkbox
                name="showMessage"
                label={t('Show explanation to the candidate')}
              />
            )}
            <Button
              action="secondary"
              disabled={formik.isSubmitting}
              type="submit"
            >
              {t('Send')}
            </Button>
          </Form>
        )}
      </Formik>
    </CandidateActionDrawer>
  )
}

export default styled(ExplanationDrawer)`
  padding: 0px 10px 10px 10px;
  max-width: 400px;

  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top-container > p {
    margin: 0;
  }

  .help-text {
    font-size: 12px;
    font-weight: 600;
    margin: 10px 0;
    color: grey;
  }

  .close-button {
    color: #5a5a5a;
    font-weight: bold;
    cursor: pointer;
  }

  .fixed-message-container {
    display: flex;
    display: grid;
    margin: 3px 0 3px 0;
  }

  .fixed-message {
    border: 1px solid #23607a;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 5px;
  }

  .fixed-message:hover {
    background-color: #eee;
    cursor: pointer;
  }

  .textarea-container {
    display: flex;
    justify-content: center;
  }

  textarea {
    align-self: flex-end;
    width: 100%;
    resize: none;
    border: 1px solid #c9c9c9;
    font-size: 14px;
    border-radius: 4px;
    font-family: Open Sans, sans-serif;
    padding: 5px;
  }

  .button-container {
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .checkbox-wrapper {
    display: grid;
  }
`
