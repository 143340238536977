import qs from 'qs'
import React, { useContext } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Footer from '../components/Footer'
import OfflineMessage from '../components/OfflineMessage'
import { AuthContext } from '../states/AuthState'
import { isAuthenticated } from '../utils/auth'
import LateralMenu from './LateralMenu'

interface PrivateRouteProps extends RouteProps {
  className?: string
  withFooter?: boolean
  withHeader?: boolean
  withOfflineWarn?: boolean
  allowedGroups?: string[]
}

const PrivateRoute = ({
  className,
  withFooter,
  withHeader,
  withOfflineWarn,
  allowedGroups = [],
  ...props
}: PrivateRouteProps) => {
  const location = useLocation()
  const { hasGroup } = useContext(AuthContext)

  const hasPermission = () => {
    if (allowedGroups.length === 0) {
      return true
    }

    return allowedGroups.some((g) => hasGroup(g))
  }

  if (isAuthenticated()) {
    if (hasPermission()) {
      return (
        <div className={className}>
          {withHeader && <LateralMenu />}
          {/* {withHeader && (
            <LateralMenu
              children={
                <> */}
          {withOfflineWarn && <OfflineMessage />}
          <Route {...props} />
          {/* </>
              }
            />
          )} */}

          {withFooter && <Footer />}
        </div>
      )
    }
    return <Redirect to="/not-found" />
  }

  const getLoginRedirectURL = () => {
    const currentURLParams = qs.parse(location.search.slice(1))

    const next = location.pathname

    return `/login?${qs.stringify({ ...currentURLParams, next })}`
  }

  return <Redirect to={getLoginRedirectURL()} />
}

PrivateRoute.defaultProps = {
  withFooter: true,
  withHeader: true,
  withOfflineWarn: true
} as Partial<PrivateRouteProps>

export default styled(PrivateRoute)`
  position: relative;
  min-height: 100vh;
  padding-bottom: 3rem;

  @media (max-width: 600px) {
    min-height: 150vh;
  }
`
