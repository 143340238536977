import { createContext } from 'react'

const getEnv = (envName, defaultValue) => {
  const env = process.env[`REACT_APP_${envName}`]
  // if (!env && defaultValue === undefined) {
  //   throw Error(
  //     `${envName} is not set and has no default value.
  //     Please pass defaultValue to getEnv or set the correct env.`
  //   )
  // }
  return env || defaultValue
}

// App behaviour config
export const DEFAULT_LOGO = getEnv('DEFAULT_LOGO', null)
export const VIDEO_RECORDING_ENABLED = !!getEnv(
  'VIDEO_RECORDING_ENABLED',
  false
)
export const COORDINATOR_ALLOWED_IN_DASHBOARD = !!getEnv(
  'COORDINATOR_ALLOWED_IN_DASHBOARD',
  false
)
export const AVERAGE_LATENCY_IN_MILISSECONDS = getEnv(
  'AVERAGE_LATENCY_IN_MILISSECONDS',
  700
)

// Service endpoints
export const CLOUD_RECORDING_LAMBDA_URL = getEnv(
  'CLOUD_RECORDING_LAMBDA_URL',
  null
)
export const API_HOST = getEnv('API_HOST', 'http://localhost:8000')
export const DASHBOARD_API_HOST = getEnv('DASHBOARD_API_HOST', API_HOST)
export const SOCKET_HOST = getEnv(
  'SOCKET_HOST',
  'https://exams-websocket.educat.net.br'
)

export const SENTRY_KEY = getEnv(
  'SENTRY_KEY',
  'https://bf12d157f2434b038eff5e6fb062a174@o1432069.ingest.sentry.io/4503897560121344'
)

export const FACE_API_MODELS_HOST = getEnv(
  'FACE_API_MODELS_HOST',
  'https://d2qwb5oql6jx19.cloudfront.net/EXAMS/MODELS/'
)
export const EMAIL_API_HOST = getEnv('EMAIL_API_HOST', 'http://localhost:3001')

// Rollbar
export const ROLLBAR_ACCESS_TOKEN = getEnv('ROLLBAR_ACCESS_TOKEN', null)
export const ROLLBAR_ENV = getEnv('ROLLBAR_ENV', null)
export const ENABLE_ROLLBAR = !!getEnv('ENABLE_ROLLBAR', false)

// Service keys
// Please note that everything defined here is available to everyone that
// access the app! Never provide private keys or keys with admin privillege!
export const AGORA_API_KEY = getEnv('AGORA_API_KEY', null)
export const AGORA_CHANNEL_PREFIX = getEnv('AGORA_CHANNEL_PREFIX', '')
export const FIREBASE_CONFIG = getEnv('FIREBASE_CONFIG')
export const FIREBASE_ENABLED = getEnv('FIREBASE_ENABLED', false)
export const EMAIL_PARTNER_TOKEN = getEnv(
  'EMAIL_PARTNER_TOKEN',
  'd898abef-8db9-4fbd-a185-06a5493c210f'
)
export const GA_TRACKING_CODE = getEnv('GA_TRACKING_CODE', null)
export const CHAT_CONFIG = getEnv('CHAT_CONFIG', null)
export const EXAMS_ORAL_URL = getEnv(
  'EXAMS_ORAL_URL',
  'https://exams-oral-dev.educat.net.br/'
)
export const CORRECAO_QUESTAO_ABERTA_UI_URL = getEnv(
  'CORRECAO_QUESTAO_ABERTA_UI_URL',
  'http://localhost:3001'
)

export const ANALYSIS_API_HOST = getEnv(
  'ANALYSIS_API_HOST',
  'https://api.analysis.educat.net.br'
)
export const EDUCAT_UI_URL = getEnv('EDUCAT_UI_URL', 'http://localhost:3000')
export const UserContext = createContext({
  user: {},
  features: [],
  permissions: {},
  groups: {},
  hasPermission: () => false,
  groupsProvider: () => {}
})
