import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

type SeeAnswersLinkProps = {
  applicationId?: number
  className?: string
  isMultipleApplication?: boolean
  instructionsUrl?: string
}

const SeeAnswersLink = ({
  applicationId,
  className,
  isMultipleApplication,
  instructionsUrl
}: SeeAnswersLinkProps) => {
  const { t } = useTranslation()

  if (!applicationId) {
    return <span></span>
  }

  const answersLink = isMultipleApplication
    ? instructionsUrl
    : `/answer-sheets/${applicationId}`

  return (
    <Link
      className={className}
      to={answersLink}
      onClick={(e) => e.stopPropagation()}
    >
      {t('Check answers')}
    </Link>
  )
}

export default styled(SeeAnswersLink)`
  font-weight: bold;
  color: ${(props) => props.theme.applicationStatus.AVAILABLE};
`
