import axios from 'axios'
import debounce from 'debounce-promise'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'

const SpecialNeedInput = ({
  params,
  ...props
}: {
  params?: any
  basic?: boolean
}) => {
  const { t } = useTranslation()

  const fetchSpecialNeed = debounce(async (search) => {
    const response = await axios.get(`${API_HOST}/v1/special_need`, {
      params: {
        search,
        ...params
      }
    })
    return response.data
  }, 500)

  return (
    <InputAsync
      key={JSON.stringify(params)}
      defaultOptions
      loadOptions={fetchSpecialNeed}
      label={t('Special Need')}
      name="special_need"
      getOptionValue={(d) => d.specialNeed}
      getOptionLabel={(d) => (d.specialNeed ? d.specialNeed : 'nenhuma')}
      {...props}
    />
  )
}

export default SpecialNeedInput
