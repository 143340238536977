import { createContext } from 'react'
import { Config } from 'data/domain/config'

export const initialConfig: Config = {
  syncAnswerInterval: 7000,
  fetchNotificationInterval: 15000,
  syncApplicationsExamDashboardInterval: 90000,
  allowHideRemainingTimeAnswerPage: true,
  showSecondsInRemainingTime: true,
  disableItemTextHighlight: true,
  lastSeenUpdateInterval: 10000,
  lastSeenUpdate: true,
  defaultAnswerLog: true,
  firebaseAnswerLog: true,
  itemAccessLog: true
}

const ConfigContext = createContext<Config>(initialConfig)

export default ConfigContext
