import { useCallback, useEffect } from 'react'
import { Candidate } from 'data/domain/message-exchange'
import { getCandidates, pauseApplication } from 'data/apis/message-exchange'
import useFirebaseCollection from './useFirebaseCollection'

const hasExpiredBreak = (candidate: Candidate) => {
  const seconds = new Date().getTime() / 1000
  return seconds > candidate.pause?.maxEndDate?.seconds
}

function useCandidates(roomId: string | undefined) {
  const _getCandidates = useCallback(() => {
    if (!roomId) {
      return null
    }
    return getCandidates(roomId)
  }, [roomId])

  const { collection: candidates } = useFirebaseCollection<Candidate>(
    _getCandidates
  )

  const handleExpiredBreaks = useCallback(() => {
    candidates.filter(hasExpiredBreak).forEach((candidate) => {
      if (candidate?.pause && candidate?.pause?.motive === 'BREAK_START') {
        pauseApplication(roomId, candidate.id, {
          dismissible: false,
          message:
            'Sua atividade foi pausada, pois você demorou tempo demais no banheiro. Por favor, aguarde ação do aplicador.',
          motive: 'LATE_BREAK_RETURN',
          showMessage: true
        })
      }
    })
  }, [candidates, roomId])

  useEffect(() => {
    const timer = setTimeout(() => {
      handleExpiredBreaks()
    }, 3000)
    return () => clearTimeout(timer)
  }, [handleExpiredBreaks])

  return { candidates }
}

export default useCandidates
