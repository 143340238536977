import React from 'react'
import AppealResponsePage from './AppealResponsePage'
import AppealResponseState from 'states/AppealResponseState'

const AppealResponse = () => {
  return (
    <AppealResponseState>{() => <AppealResponsePage />}</AppealResponseState>
  )
}

export default AppealResponse
