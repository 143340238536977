import React from 'react'
import { useTranslation } from 'react-i18next'
import { IApplication } from 'types'
import Modal from './Modal/Modal'
import SymptomTableReview from './Repertorization/SymptomTableReview'
import RepertorizationTableReview from './Repertorization/RepertorizationTableReview'

interface RepertorizationModalProps {
  // className?: string
  application: IApplication
  isOpen: boolean
  onClose: () => void
}

const RepertorizationModal = ({
  application,
  isOpen,
  onClose
}: RepertorizationModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('Repertorization Table')}
      maxWidth={1800}
      width={1800}
    >
      <>
        <SymptomTableReview application={application} />
        <RepertorizationTableReview application={application} />
      </>
    </Modal>
  )
}

export default RepertorizationModal
