import { useCallback } from 'react'
import useFirebaseCollection from './useFirebaseCollection'
import {
  getCandidatePendingBreakRequest,
  getCandidateNotAnsweredBreakRequests
} from 'data/apis/message-exchange'
import { BreakRequest } from 'data/domain/message-exchange'

function useCandidateBreakRequest(
  roomId: string | undefined,
  candidateId: string | undefined
) {
  const _getCandidatePendingBreakRequest = useCallback(() => {
    if (!roomId || !candidateId) {
      return null
    }
    return getCandidatePendingBreakRequest(roomId, candidateId)
  }, [roomId, candidateId])

  const { collection: breakRequests } = useFirebaseCollection<BreakRequest>(
    _getCandidatePendingBreakRequest
  )
  return { breakRequest: breakRequests.length > 0 ? breakRequests[0] : null }
}

export function useCandidateHasPendingBreakRequest(
  roomId: string | undefined,
  candidateId: string | undefined
) {
  const _getCandidatePendingBreakRequest = useCallback(() => {
    if (!roomId || !candidateId) {
      return null
    }
    return getCandidateNotAnsweredBreakRequests(roomId, candidateId)
  }, [roomId, candidateId])

  const { collection: breakRequests } = useFirebaseCollection<BreakRequest>(
    _getCandidatePendingBreakRequest
  )
  return breakRequests.length > 0
}

export default useCandidateBreakRequest
