import React, { useContext, useState, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import VideoIcon from './VideoIcon'
import CandidateBreakComponent from './CandidateBreakComponent'
import CandidateMessagePopover from './CandidateMessagePopover'
import RoomMessageExchangeContext from 'contexts/RoomMessageExchangeContext'
import { IExamDashboardApplication, BreakStatus } from '../../types'
import {
  faQuestion,
  faRestroom,
  faBaby,
  faExclamation
} from '@fortawesome/free-solid-svg-icons'
import CandidateContext from 'contexts/CandidateContext'
import TimeoutVideoIcon from './TimeoutVideoIcon'

export type VideoIconQueueProps = {
  className?: string
  application: IExamDashboardApplication
  question: string
  breakStatus: BreakStatus
}

const VideoIconQueue = ({
  className,
  question,
  breakStatus,
  application
}: VideoIconQueueProps) => {
  const {
    setMessageAsRead,
    sendBreakResponse,
    sendNotificationToCandidate,
    sendNursingBreakResponse
  } = useContext(RoomMessageExchangeContext)
  const { t } = useTranslation()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [breakPopoverOpen, setBreakPopoverOpen] = useState(false)
  const iconPopoverRef = useRef<any>(null)
  const breakIconPopoverRef = useRef<any>(null)
  const { messages, breakRequest, candidate } = useContext(CandidateContext)
  const alert = messages?.find(() => true)

  const getMargin = () => {
    let itemCount = -1
    if (question) {
      itemCount++
    }

    if (breakStatus) {
      itemCount++
    }

    return { marginLeft: itemCount * 35 + 'px' }
  }

  const pendingBreak =
    breakStatus &&
    breakStatus.status === 'PENDING' &&
    breakIconPopoverRef !== null

  const bathroomBreak = pendingBreak && breakStatus.motive === 'BATHROOM'
  const nursingBreak = pendingBreak && breakStatus.motive === 'NURSING_BREAK'

  return (
    <div className={'icon-row ' + className}>
      <div className="icons">
        {alert &&
          alert.motive === 'IMPROPER_BEHAVIOR' &&
          iconPopoverRef !== null && (
            <>
              <div
                ref={iconPopoverRef}
                onClick={() => setPopoverOpen(true)}
                className="question-indicator"
              >
                <VideoIcon type="suspectBehavior" pulse icon={faExclamation} />
              </div>
              <CandidateMessagePopover
                onClose={() => {
                  setPopoverOpen(false)
                  setMessageAsRead(alert.id)
                }}
                anchorElement={iconPopoverRef.current}
                open={popoverOpen}
                question={{
                  message: 'Comportamento suspeito por parte do candidato',
                  when: new Date().toISOString()
                }}
              />
            </>
          )}
        {alert &&
          alert.motive !== 'IMPROPER_BEHAVIOR' &&
          iconPopoverRef !== null && (
            <>
              <div
                ref={iconPopoverRef}
                onClick={() => setPopoverOpen(true)}
                className="question-indicator"
              >
                <VideoIcon type="question" pulse icon={faQuestion} />
              </div>
              <CandidateMessagePopover
                onClose={() => {
                  setPopoverOpen(false)
                  setMessageAsRead(alert.id)
                }}
                anchorElement={iconPopoverRef.current}
                open={popoverOpen}
                question={{
                  message: alert.message,
                  when: new Date().toISOString()
                }}
              />
            </>
          )}
        {(bathroomBreak || nursingBreak) && (
          <>
            <div
              ref={breakIconPopoverRef}
              style={getMargin()}
              onClick={() => setBreakPopoverOpen(true)}
            >
              <VideoIcon
                type="restroom"
                pulse
                icon={nursingBreak ? faBaby : faRestroom}
              />
            </div>
            <CandidateBreakComponent
              onClose={(result) => {
                setBreakPopoverOpen(false)
                if (nursingBreak) {
                  sendNursingBreakResponse(
                    breakRequest.id,
                    result,
                    `${application.user.id}`
                  )
                } else {
                  sendBreakResponse(
                    breakRequest.id,
                    result,
                    `${application.user.id}`
                  )
                }
                if (!result) {
                  sendNotificationToCandidate(
                    application.user.id,
                    nursingBreak ? 'REFUSE_NURSING_BREAK' : 'REFUSE_BREAK'
                  )
                }
                if (result) {
                  sendNotificationToCandidate(
                    application.user.id,
                    nursingBreak ? 'ACCEPT_NURSING_BREAK' : 'ACCEPT_BREAK',
                    'BADGE',
                    'Por favor finalize a questão atual e clique no botão próxima.'
                  )
                }
              }}
              anchorElement={breakIconPopoverRef.current}
              open={breakPopoverOpen}
              message={
                nursingBreak
                  ? t('Do you want to approve the breastfeed request?')
                  : t('Do you want to approve the bathroom request?')
              }
            />
          </>
        )}
        {candidate?.pause?.motive === 'BREAK_START' && (
          <TimeoutVideoIcon
            type="restroomAccepted"
            key={application.user.id}
            icon={faRestroom}
            onClick={() => window.alert('O candidato está no banheiro.')}
            maxDuration={{ seconds: candidate?.pause?.maxEndDate.seconds }}
          />
        )}
        {candidate?.pause?.motive === 'NURSING_BREAK_START' && (
          <TimeoutVideoIcon
            type="restroomAccepted"
            key={application.user.id}
            icon={faBaby}
            onClick={() => window.alert('A aluna está amamentando.')}
            maxDuration={{ seconds: 0 }}
          />
        )}
        {candidate?.pause?.motive === 'LATE_BREAK_RETURN' && (
          <VideoIcon
            pulse
            type="restroom"
            icon="exclamation"
            onClick={() =>
              window.alert(
                'O candidato ultrapassou o tempo limite de ida ao banheiro. A atividade dele foi pausada e deve ser manualmente retomada.'
              )
            }
          />
        )}
      </div>
    </div>
  )
}

export default styled(VideoIconQueue)`
  position: absolute;
  right: 2.5rem;
  margin-right: 5px;
  z-index: 999;
  top: 5px;

  .icons {
    display: flex;
    flex-direction: row-reverse;
  }

  .question-indicator {
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation: fade-in 0.5s ease;
  }
`
