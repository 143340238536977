import { ReactComponentLike } from 'prop-types'
import React, { createContext, ReactNode, useReducer } from 'react'
import ModalRoot from '../components/Modal/ModalRoot'

export interface IModalState {
  component: ReactComponentLike | null
  props: any
  showModal: (component: ReactComponentLike) => void
  hideModal: () => void
}

const initialState: IModalState = {
  component: null,
  props: {},
  showModal: function () {},
  hideModal: function () {}
}

export const ModalContext = createContext(initialState)

const reducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return { ...state, ...action.payload }
    case 'HIDE_MODAL':
      return { ...state, component: null, props: {} }
    default:
      return state
  }
}

const ModalState = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const showModal = (component, props = {}) => {
    dispatch({ type: 'SHOW_MODAL', payload: { component, props } })
  }

  const hideModal = (component, props = {}) => {
    dispatch({ type: 'HIDE_MODAL', payload: { component, props } })
  }

  const contextValue = {
    ...state,
    showModal,
    hideModal
  }

  return (
    <ModalContext.Provider value={contextValue}>
      <ModalRoot />
      {children}
    </ModalContext.Provider>
  )
}

export default ModalState
