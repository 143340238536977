import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'states/AuthState'
import styled from 'styled-components'
import ToggleMenuButton from '../components/Buttons/ToggleMenuButton'
import { IExam, IExamDashboardApplication } from '../types'
import ApplicationStatus from './ApplicationStatus'
import Card from './Card/Card'
import ColorfulProgressBar from './ColorfulProgressBar'
import CardFooter from './ExamDashboardComponents/CardFooter'
import CardSubtitle from './ExamDashboardComponents/CardSubtitle'
import CardTitle from './ExamDashboardComponents/CardTitle'
import NextTimeWindow from './ExamDashboardComponents/NextTimeWindow'
import SubtitleDisplayIdentifier from './ExamDashboardComponents/SubtitleDisplayIdentifier'
import SubtitleDisplayRoom from './ExamDashboardComponents/SubtitleDisplayRoom'
import SubtitleDisplayUsername from './ExamDashboardComponents/SubtitleDisplayUsername'
import Flex from './Flex'
import ProfilePicture from './ProfilePicture'
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight'

type ExamInformationCardProps = {
  className?: string
  application: IExamDashboardApplication
  toggleMenuDisabled?: boolean
  exam?: IExam | undefined
}

const ExamInformationCard: React.FC<ExamInformationCardProps> = ({
  className,
  application,
  toggleMenuDisabled = false,
  children
}) => {
  const { t } = useTranslation()
  const { perms } = useContext(AuthContext)
  const grades = useRef(application.grades)

  useEffect(() => {
    if (!grades) {
      grades.current = {
        correct: 0,
        wrong: 0,
        total: 0
      }
    }
  }, [grades])
  return (
    <Card className={className}>
      <ProfilePicture user={application.user} />
      <div>
        <CardTitle>
          <div
            className="div-name-user"
            style={{ width: '30%', fontSize: '16px', marginLeft: '0' }}
          >
            {application.user.name}
          </div>
          <span className="subtitle-small">{application.exam.name}</span>
          <Flex>
            <ToggleMenuButton disabled={toggleMenuDisabled}>
              {children}
            </ToggleMenuButton>
          </Flex>
        </CardTitle>
        <div style={{ display: 'flex', height: '40px' }}>
          <CardSubtitle>
            <NextTimeWindow application={application}></NextTimeWindow>
          </CardSubtitle>
          <div
            style={{
              display: 'flex',
              marginLeft: '0.4rem',
              alignItems: 'flex-start'
            }}
          >
            {application?.nursingMother && (
              <div className="person">{t('Nursing Mother').toUpperCase()}</div>
            )}

            {application?.specialNeed && (
              <div className="person" style={{ marginLeft: '1rem' }}>
                {application?.specialNeed.toUpperCase()}
              </div>
            )}
          </div>
        </div>
        <CardFooter
          application={application}
          showTimeInfo={Boolean(perms?.Dashboard?.List?.ShowTimeInfo)}
        >
          <div className="grades" style={{ width: '100%', height: '40px' }}>
            <div
              className="grades-items"
              style={{
                width: '60%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <ColorfulProgressBar
                showProgressValues
                value={application?.grades?.total}
                max={application.numItems}
              />
              <span>
                {/* "fa-solid fa-check" */}
                <FontAwesomeIcon
                  icon={['fas', 'check']}
                  style={{ color: '#61b082', marginRight: '10px' }}
                />{' '}
                {t('Correct')}: {grades.current?.correct}
              </span>
              <span>
                <FontAwesomeIcon
                  icon="times"
                  style={{ color: '#E96240', marginRight: '10px' }}
                />{' '}
                {t('Wrong')}: {grades.current?.wrong}
              </span>
            </div>
            <div className="grade-button">
              <ApplicationStatus
                status={application.status}
                reseted={application.reseted}
              />
            </div>
          </div>
        </CardFooter>
      </div>
    </Card>
  )
}

export default styled(ExamInformationCard)`
  display: grid;
  grid-template-columns: 55px auto;
  grid-column-gap: 1rem;

  ${CardTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    ${ApplicationStatus} {
      margin-right: 5px;
    }
    div {
      margin-left: 1rem;
    }
  }

  .grade-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .person {
    font-weight: 500;
    background: #ffebbb;
    padding: 5px;
    font-size: 10px;
    border: none;
    display: flex;
    max-width: 270px;
    border-radius: 4px;
    color: #5b5b5b;
  }

  ${CardSubtitle} {
    display: grid;
    justify-content: space-between;
    margin-top: 5px;
    align-items: baseline;
    font-size: 12px;
  }

  ${CardFooter} {
    .grades {
      span {
        margin-right: 1rem;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
    }
  }

  .subtitle-small {
    font-size: 14px !important;
  }
`
