import { addStreamingLog } from 'data/apis/streaming-logs'
import { StreamingLog } from 'data/domain/streaming-log'
import { logoutObservable } from 'data/observables'
import { shouldEnableFaceDetection } from 'data/utils/user-configuration'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import streamingManager from 'videoStreaming'
import Modal from '../components/Modal/Modal'
import { VIDEO_RECORDING_ENABLED } from '../consts'
import firebaseVideoManager from '../fileChunkUploader'
import s3VideoManager from '../components/Recording/fileChunkS3Uploader'
import faceDetectionManager from '../managers/faceDetection'
import snapshotManager from '../snapshotUploader'
import screenshotManager from '../components/screenshot'
import {
  childrenIsFunction,
  IVideoStreamingState,
  VideoStreamingStateAction
} from '../types'
import { isAuthenticated } from '../utils/auth'
import { AuthContext } from './AuthState'

type VideoStreamingStateProps = {
  children: ReactElement
}

const reducer = (
  state: IVideoStreamingState,
  action: VideoStreamingStateAction
): IVideoStreamingState => {
  switch (action.type) {
    case 'ASKING_FOR_PERMISSION':
      return {
        ...state,
        isPermissionModalOpen: action.payload.isModalOpen,
        shouldStreamToFirebase: action.payload.shouldStreamToFirebase,
        shouldUploadToS3: action.payload.shouldUploadToS3,
        shouldStreamToAgora: action.payload.shouldStreamToAgora,
        shouldFaceDetect: action.payload.shouldFaceDetect,
        shouldUploadScreenshotToS3: action.payload.shouldUploadScreenshotToS3,
        triedToAskForPermission: true
      }
    case 'CAMERA_PERMISSION_RESULT':
      return {
        ...state,
        granted: action.payload.granted,
        isPermissionModalOpen: false,
        triedToAskForPermission: true
      }
    case 'CAMERA_START_RESULT':
      return {
        ...state,
        isPermissionModalOpen: false,
        hasPermissionError: action.payload.hasCameraError,
        triedToAskForPermission: true
      }
  }
}

const initialState: IVideoStreamingState = {
  triedToAskForPermission: false,
  hasPermissionError: undefined,
  granted: undefined,
  startStreaming: () => undefined,
  permissionsAreOk: async () => undefined,
  setPermissionGranted: () => undefined,
  setCameraId: () => undefined,
  setMicrophoneId: () => undefined,
  isPermissionModalOpen: false,
  shouldStreamToAgora: false,
  shouldStreamToFirebase: false,
  shouldUploadToS3: false,
  shouldFaceDetect: false,
  shouldUploadScreenshotToS3: false
}

export const VideoStreamingContext = createContext<IVideoStreamingState>(
  initialState
)

const VideoStreamingState = ({ children }: VideoStreamingStateProps) => {
  const { user, hasGroup, userConfiguration } = useContext(AuthContext)
  const [agoraStreaming, setAgoraStreaming] = useState(false)
  const [deviceId, setDeviceId] = useState(undefined)
  const [audioDeviceId, setAudioDeviceId] = useState(undefined)
  const [firebaseUploading, setFirebaseUploading] = useState(false)
  const [s3Uploading, setS3Uploading] = useState(false)
  const [detectingFaces, setDetectingFaces] = useState(false)
  const [takingSnapshots, setTakingSnapshots] = useState(false)
  const [screenshotUploadingToS3, setScreenshotUploadingToS3] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [agoraStreamingStatus, setAgoraStreamingStatus] = useState('LOADING')
  const [firebaseUploadingStatus, setFirebaseUploadingStatus] = useState(
    'LOADING'
  )
  const [s3UploadingStatus, setS3UploadingStatus] = useState('LOADING')
  const [faceDetctionStatus, setFaceDetctionStatus] = useState('LOADING')
  const [takingSnapshotsStatus, setTakingSnapshotsStatus] = useState('LOADING')
  const [
    screenshotUploadingToS3Status,
    setScreenshotUploadingToS3Status
  ] = useState('LOADING')
  const { t } = useTranslation()
  const history = useHistory()
  const {
    isPermissionModalOpen,
    granted,
    hasPermissionError,
    triedToAskForPermission,
    shouldStreamToFirebase,
    shouldUploadToS3,
    shouldStreamToAgora,
    shouldFaceDetect,
    shouldUploadScreenshotToS3
  } = state

  useEffect(() => {
    const streamingStatuses = [
      agoraStreamingStatus,
      firebaseUploadingStatus,
      s3UploadingStatus,
      faceDetctionStatus,
      takingSnapshotsStatus,
      screenshotUploadingToS3Status
    ]
    console.log(streamingStatuses)
    if (streamingStatuses.some((s) => s === 'LOADING')) {
      return
    }
    if (streamingStatuses.every((s) => s === 'SUCCESS')) {
      dispatch({
        type: 'CAMERA_START_RESULT',
        payload: { hasCameraError: false }
      })
    }
    if (streamingStatuses.some((s) => s === 'ERROR')) {
      dispatch({
        type: 'CAMERA_START_RESULT',
        payload: { hasCameraError: true }
      })
    }
  }, [
    agoraStreamingStatus,
    firebaseUploadingStatus,
    s3UploadingStatus,
    faceDetctionStatus,
    takingSnapshotsStatus,
    screenshotUploadingToS3Status
  ])

  const handleSuccess = useCallback(
    (stream) => {
      if (agoraStreaming) {
        streamingManager.setOnError((e) => {
          console.log(e)
          setAgoraStreamingStatus('ERROR')
        })
        streamingManager.setOnSuccess(() => setAgoraStreamingStatus('SUCCESS'))
        agoraStreaming && streamingManager.startStreamingForUser(user, stream)
      } else {
        setAgoraStreamingStatus('SUCCESS')
      }
      firebaseUploading &&
        firebaseVideoManager.startRecordingForUser(stream, user)
      setFirebaseUploadingStatus('SUCCESS')
      s3Uploading && s3VideoManager.startRecordingForUser(stream, user)
      setS3UploadingStatus('SUCCESS')
      shouldEnableFaceDetection(detectingFaces, userConfiguration) &&
        faceDetectionManager.startDetection(stream)
      setFaceDetctionStatus('SUCCESS')
      takingSnapshots && snapshotManager.startUploadingSnapshots(stream, user)
      setTakingSnapshotsStatus('SUCCESS')
      screenshotUploadingToS3 &&
        screenshotManager.startUploadingScreenshots(user)
      setScreenshotUploadingToS3Status('SUCCESS')
    },
    [
      user,
      agoraStreaming,
      firebaseUploading,
      s3Uploading,
      detectingFaces,
      takingSnapshots,
      userConfiguration,
      screenshotUploadingToS3
    ]
  )

  const handleError = useCallback(
    (e) => {
      console.log(e)
      addStreamingLog({
        action: 'INITIALIZE_STREAMING_CHANNELS_ERROR',
        message: JSON.stringify(e),
        candidateId: user?.id
      } as StreamingLog)
      console.log('123')
      dispatch({
        type: 'CAMERA_START_RESULT',
        payload: { hasCameraError: true }
      })
    },
    [user]
  )

  const initializeStreamingChannels = useCallback(() => {
    if (!granted) {
      return
    }

    if (hasPermissionError === false && triedToAskForPermission) {
      return
    }

    if (deviceId === undefined) {
      return
    }

    if (audioDeviceId === undefined) {
      return
    }

    console.log(deviceId)
    console.log(audioDeviceId)

    navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId: audioDeviceId
        },
        video: {
          width: 320,
          height: 180,
          deviceId
        }
      })
      .then((stream) => {
        handleSuccess(stream)
        logoutObservable.subscribe(() => {
          stream.getTracks().forEach((track) => track.stop())
        })
      })
      .catch(handleError)
  }, [
    handleSuccess,
    handleError,
    granted,
    hasPermissionError,
    triedToAskForPermission,
    deviceId,
    audioDeviceId
  ])

  const startStreaming = useCallback(
    (
      shouldStreamToFirebase,
      shouldUploadToS3,
      shouldStreamToAgora,
      shouldFaceDetect,
      shouldTakeSnapshots,
      shouldUploadScreenshotToS3
    ) => {
      if (
        !VIDEO_RECORDING_ENABLED ||
        !isAuthenticated() ||
        !hasGroup('ALUNO') ||
        isPermissionModalOpen ||
        granted !== undefined
      ) {
        return
      }

      setAgoraStreaming(shouldStreamToAgora)
      setFirebaseUploading(shouldStreamToFirebase)
      setS3Uploading(shouldUploadToS3)
      setDetectingFaces(shouldFaceDetect)
      setTakingSnapshots(shouldTakeSnapshots)
      setScreenshotUploadingToS3(shouldUploadScreenshotToS3)

      dispatch({
        type: 'ASKING_FOR_PERMISSION',
        payload: {
          isModalOpen: true,
          shouldStreamToFirebase,
          shouldUploadToS3,
          shouldStreamToAgora,
          shouldFaceDetect,
          shouldUploadScreenshotToS3
        }
      })
    },
    [hasGroup, isPermissionModalOpen, granted]
  )

  const setPermissionGranted = (granted: boolean) => {
    dispatch({ type: 'CAMERA_PERMISSION_RESULT', payload: { granted } })
  }

  const setCameraId = (cameraId: string) => {
    setDeviceId(cameraId)
  }

  const setMicrophoneId = (microphoneId: string) => {
    setAudioDeviceId(microphoneId)
  }

  const permissionsAreOk = async () => {
    return !hasPermissionError && granted
    // try {
    //  const result = await navigator.permissions.query({ name: 'camera' })
    //  return result.state === 'granted' && !hasPermissionError && granted
    // } catch (e) {
    //   return !hasPermissionError && granted
    // }
  }

  useEffect(() => {
    initializeStreamingChannels()
  }, [initializeStreamingChannels])

  if (isPermissionModalOpen) {
    return (
      <Modal
        isOpen={isPermissionModalOpen}
        // cancelText="Cancelar"
        actionText="OK"
        onAction={() => {
          dispatch({
            type: 'ASKING_FOR_PERMISSION',
            payload: {
              isModalOpen: false,
              shouldStreamToFirebase: false,
              shouldUploadToS3: false,
              shouldStreamToAgora: false,
              shouldFaceDetect: false,
              shouldUploadScreenshotToS3: false
            }
          })
          history.push('/camera-permission')
        }}
        // onCancel={() => setPermissionGranted(false)}
        disableBackdropClick
        // onClose={() => setPermissionGranted(false)}
        title={t('Permission to use camera and microphone')}
      >
        {t(
          'You have been assigned one or more exams which require camera and microphone access as a mandatory permission. If you refuse to grant, you will not be able to proceed.'
        )}
      </Modal>
    )
  }

  const contextValue = {
    isPermissionModalOpen,
    granted,
    hasPermissionError,
    triedToAskForPermission,
    startStreaming,
    permissionsAreOk,
    setPermissionGranted,
    shouldStreamToAgora,
    shouldStreamToFirebase,
    shouldUploadToS3,
    shouldFaceDetect,
    shouldUploadScreenshotToS3,
    setCameraId,
    setMicrophoneId
  }

  return (
    <VideoStreamingContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </VideoStreamingContext.Provider>
  )
}

export default VideoStreamingState
