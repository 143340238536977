import React from 'react'
import BulkCreateApplicationState from 'states/BulkCreateApplicationState'
import SelectCollection from './SelectCollection'
import SelectApplicationTimeWindow from './SelectApplicationTimeWindow'
import SelectApplicationUsers from './SelectApplicationUsers'

const BulkCreateApplicationPage: React.FC = () => {
  return (
    <BulkCreateApplicationState>
      <>
        <SelectCollection />
        <SelectApplicationUsers />
        <SelectApplicationTimeWindow />
      </>
    </BulkCreateApplicationState>
  )
}

export default BulkCreateApplicationPage
