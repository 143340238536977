import axios from 'axios'
import {
  clearCurrentSituation,
  getCandidate,
  incrementApplicationCounter,
  setCameraAccepted,
  setStartApplication,
  setApplicationState,
  setCountApplications
} from 'data/apis/message-exchange'
import { findNextAvaiableAnswer } from 'data/utils/answers'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import internetManager from 'utils/internet-connection-status-manager'
import useFeedback from 'hooks/useFeedback'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Modal from '../components/Modal/Modal'
import { API_HOST, FIREBASE_ENABLED, VIDEO_RECORDING_ENABLED } from '../consts'
import db from '../db'
import {
  IAnswer,
  IApplication,
  IApplicationState,
  IApplicationStateAction
} from '../types'
import { ApplicationSocketContext } from './ApplicationSocketState'
import { AuthContext, kioskToken } from './AuthState'
import { VideoStreamingContext } from './VideoStreamingState'
import streamingManager from 'videoStreaming'
import CandidateMessageExchangeContext from 'contexts/CandidateMessageExchangeContext'
import { RollbarErrorTracking } from 'infra/rollbar'

type ApplicationProps = {
  children: Function
}

const reducer = (
  state: IApplicationState,
  action: IApplicationStateAction
): IApplicationState => {
  switch (action.type) {
    case 'FETCH_APPLICATION':
      return {
        ...state,
        fetchingApplication: true,
        fetchApplicationError: false
      }
    case 'FETCH_APPLICATION_SUCCESS':
      return {
        ...state,
        fetchingApplication: false,
        fetchApplicationError: false,
        remainingTimeInitialDate: Date.now(),
        ...action.payload
      }
    case 'FETCH_APPLICATION_ERROR':
      return {
        ...state,
        fetchingApplication: false,
        fetchApplicationError: true
      }
    case 'FETCH_ANSWERS':
      return { ...state, fetchingAnswers: true, fetchAnswersError: false }
    case 'FETCH_ANSWERS_SUCCESS':
      return {
        ...state,
        fetchingAnswers: false,
        fetchAnswersError: false,
        answers: action.payload
      }
    case 'FETCH_ANSWERS_ERROR':
      return { ...state, fetchingAnswers: false, fetchAnswersError: true }
    case 'UPDATE_ANSWER_IN_ANSWERS':
      return {
        ...state,
        answers: state.answers.map((d) =>
          d.id === action.payload.id ? action.payload : d
        )
      }
    case 'START_APPLICATION':
      return { ...state, startingApplication: true, startApplicationError: '' }
    case 'START_APPLICATION_SUCCESS':
      if (state.application) {
        state.application.status = 'STARTED'
      }
      return {
        ...state,
        startingApplication: false,
        startApplicationError: '',
        secondsToTimeout: action.payload,
        remainingTimeInitialDate: Date.now()
      }
    case 'START_APPLICATION_ERROR':
      return {
        ...state,
        startingApplication: false,
        startApplicationError: action.payload
      }
    case 'FINISH_APPLICATION':
      return {
        ...state,
        finishingApplication: true,
        finishApplicationError: ''
      }
    case 'FINISH_APPLICATION_SUCCESS':
      return {
        ...state,
        finishingApplication: false,
        finishApplicationError: ''
      }
    case 'FINISH_APPLICATION_ERROR':
      return {
        ...state,
        finishingApplication: false,
        finishApplicationError: action.payload
      }
    case 'ADD_MORE_TIME':
      return {
        ...state,
        secondsToTimeout: action.payload,
        remainingTimeInitialDate: Date.now()
      }
    default:
      return state
  }
}

const initialState: IApplicationState = {
  answers: [],
  application: undefined,
  fetchAnswersError: false,
  fetchApplicationError: false,
  fetchingApplication: false,
  fetchingAnswers: false,
  dispatch: () => {},
  startApplication: () => {},
  resumeApplication: () => {},
  fetchApplication: () => {},
  checkApplicationAnswers: () => {},
  returnToApplicationList: () => {},
  finishApplication: () => {},
  checkAnswersPage: false,
  startingApplication: false,
  startApplicationError: '',
  fetchAnswers: () => {},
  finishingApplication: false,
  finishApplicationError: '',
  secondsToTimeout: undefined,
  remainingTimeInitialDate: undefined,
  handleTimeout: () => {},
  addMoreTime: () => undefined
}

export const ApplicationContext = createContext<IApplicationState>(initialState)

const ApplicationState = ({ children }: ApplicationProps) => {
  const { applicationId } = useParams()
  const history = useHistory()
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [reviewModal, setReviewModal] = useState(false)
  const [unansweredItems, setUnansweredItems] = useState(0)
  const ReactSwal = withReactContent(swal)
  const { updateExamStatus, joinRoomAsCandidate } = useContext(
    ApplicationSocketContext
  )
  const { handleErrorFeedback } = useFeedback()
  const { user } = useContext(AuthContext)
  const { permissionsAreOk } = useContext(VideoStreamingContext)
  const { breakRequest, handlePendingBreak } = useContext(
    CandidateMessageExchangeContext
  )
  const location = useLocation()

  const { application, answers } = state
  const configuration = useApplicationConfiguration(
    application?.exam.collection.applicationConfiguration
  )
  const checkAnswersPage =
    application?.exam?.collection?.showApplicationAfterTimeout &&
    application?.status === 'FINISHED'

  const addMoreTime = useCallback((minutes: number) => {
    dispatch({ type: 'ADD_MORE_TIME', payload: minutes })
  }, [])

  // Fetches application from api
  const fetchApplication = useCallback(async () => {
    if (!applicationId) {
      return
    }

    dispatch({ type: 'FETCH_APPLICATION' })

    let newApplication: IApplication | undefined
    let secondsToTimeout: number | undefined

    try {
      // Tries to fetch application from server
      const response = await axios.get(
        `${API_HOST}/v1/applications/${applicationId}`
      )
      newApplication = response.data
      secondsToTimeout = response.data.secondsToTimeout

      if (newApplication) {
        // Saves/updates application in local db
        await db.applications.put(newApplication)
      }
    } catch (error) {
      const status = error?.response?.status

      switch (status) {
        case 401:
        case 403:
        case 404:
          history.push('/applications')
          return
      }

      // If an error occurs, look up in local db
      newApplication = await db.applications
        .where({ id: +applicationId })
        .first()
    }

    if (newApplication) {
      dispatch({
        type: 'FETCH_APPLICATION_SUCCESS',
        payload: {
          secondsToTimeout,
          application: newApplication
        }
      })
    } else {
      dispatch({ type: 'FETCH_APPLICATION_ERROR' })
    }

    return newApplication
  }, [applicationId, history])

  // Fetches answers from api or local db
  const fetchAnswers = useCallback(
    async (forceAnswerUpdates = false) => {
      if (!application) {
        return
      }

      // If needed, update answers in local db
      if (forceAnswerUpdates) {
        db.answers.where({ 'application.id': +application.id }).delete()
      }

      let newAnswers: IAnswer[]

      // This allows the server to request the client to fetch updated answers
      let { shouldUpdateAnswers } = application

      // If not requested by the server, check if there are answers in local db
      if (!shouldUpdateAnswers) {
        const hasAnswersInDb = !!(await db.answers
          .where({ 'application.id': application.id })
          .count())
        shouldUpdateAnswers = !hasAnswersInDb
      }

      // If needed, fetches answers and updates local db
      // This will overwrite answers not syncronized with the server!
      if (shouldUpdateAnswers) {
        const params = { ordering: 'position' }
        const response = await axios.get(
          `${API_HOST}/v1/applications/${application.id}/answers`,
          { params }
        )
        newAnswers = response.data.map((newAnswer: IAnswer) => ({
          ...newAnswer,
          _changed: 0
        }))

        await db.transaction('rw', db.answers, async () => {
          await db.answers.where({ 'application.id': application.id }).delete()
          await db.answers.bulkAdd(newAnswers)
        })

        // Tells the server that answers have been updated
        // axios.patch(`${API_HOST}/v1/applications/${application.id}/`, { shouldUpdateAnswers: false })
      } else {
        newAnswers = await db.answers
          .filter((d) => d.application.id === application.id)
          .sortBy('position')
      }

      dispatch({ type: 'FETCH_ANSWERS_SUCCESS', payload: newAnswers })

      return newAnswers
    },
    [application]
  )

  const approvedBreakRequest = breakRequest?.approved
  const InReviewOrInstrucions =
    location.pathname.endsWith('/review') ||
    location.pathname.endsWith('/instructions')

  useEffect(() => {
    if (InReviewOrInstrucions && approvedBreakRequest) {
      try {
        handlePendingBreak()
      } catch (error) {
        // If there was an error while starting the break,
        // just ignore it and try again on the next item change.
        // However, error will be reported since it should not happen.
        RollbarErrorTracking.logError(error)
      }
    }
  }, [approvedBreakRequest, InReviewOrInstrucions, handlePendingBreak])

  const channelName = application?.channelName
  useEffect(() => {
    if (channelName) {
      try {
        streamingManager.handleStream(channelName)
      } catch (e) {
        console.error(e)
      }
    }
    return () => streamingManager.unsubscribe()
  }, [channelName])

  const getSyncAnswersPayload = useCallback(async () => {
    if (!application) {
      return []
    }
    const changedAnswers = await db.answers
      .where({ 'application.id': application.id, _changed: 1 })
      .toArray()

    if (changedAnswers.length === 0) {
      return []
    }

    return changedAnswers.map((answer) => ({
      id: answer.id,
      alternativeId: answer.alternative?.id,
      alternatives: answer.alternatives,
      gradeLinear: answer.gradeLinear,
      freeResponse: answer.freeResponse,
      seconds: answer.seconds
    }))
  }, [application])

  const goAnswer = (newAnswer: IAnswer) => {
    history.push(`/applications/${applicationId}/answers/${newAnswer.id}`)
  }

  const goAnswerAdaptativo = (answerId: any) => {
    if (answerId) {
      history.push(`/applications/${applicationId}/answers/${answerId}`)
    } else {
      toast.error('Ocorreu um erro ao coletar seu próxima item')
    }
  }

  // Goes to application review
  const checkApplicationAnswers = () => {
    history.push(`/applications/${application.id}/review`)
  }

  const isAdaptative = application?.exam?.adaptative

  // Goes to last answered answer, or to first answer if none was answered
  const resumeApplication = async (adaptativeNextAnswer: any) => {
    if (adaptativeNextAnswer !== '' && isAdaptative) {
      goAnswerAdaptativo(adaptativeNextAnswer)
    } else {
      if (adaptativeNextAnswer === '' && isAdaptative) {
        const response = await axios.post(
          `${API_HOST}/v1/applications/${applicationId}/adaptative_resume`
        )
        goAnswerAdaptativo(response.data)
      } else {
        const answer = await findNextAvaiableAnswer(
          +applicationId,
          configuration
        )

        if (answer) {
          goAnswer(answer)
        } else {
          const error = new Error(
            'No answer found when calling `resumeApplication`.'
          )
          handleErrorFeedback(error, t('No question available.'))
          history.push('/applications')
        }
      }
    }
  }

  // Returns to application list
  const returnToApplicationList = () => {
    history.push('/applications')
  }

  const fetchApplicationCounter = useCallback(async () => {
    axios
      .get(
        `${API_HOST}/v1/user/${user?.id}/room/${application?.roomId}/counter_applications`
      )
      .then((res) => {
        setCountApplications(
          application?.roomId?.toString(),
          application?.user?.id?.toString(),
          res?.data?.counter
        )
      })
  }, [user?.id, application])

  const initCounter = (room: string, user: string) => {
    const candidate = getCandidate(room, user)

    candidate.get().then((data) => {
      if (!data.data().totalApplications) {
        setStartApplication(room, user, true)
      } else {
        incrementApplicationCounter(room, user, true)
      }
    })
  }

  useEffect(() => {
    if (application && user?.id) {
      fetchApplicationCounter()
    }
  }, [fetchApplicationCounter, user?.id, application])

  const startApplication = async () => {
    if (!application || !configuration) {
      return
    }
    dispatch({ type: 'START_APPLICATION' })
    try {
      const response = await axios.post(
        `${API_HOST}/v1/applications/${applicationId}/start`
      )
      if (FIREBASE_ENABLED) {
        initCounter(application?.roomId?.toString(), user?.id?.toString())
      }

      dispatch({
        type: 'START_APPLICATION_SUCCESS',
        payload: response.data.secondsToTimeout
      })
      resumeApplication(response?.data?.answer)
    } catch (e) {
      const msg =
        e?.response?.data?.[0] || t('An error occurred. Please try again.')
      dispatch({
        type: 'START_APPLICATION_ERROR',
        payload: msg
      })
    }
  }

  const removeItems = () => {
    const itemIds = answers.map((ans) => ans.item.id)
    return db.items.where('id').anyOf(itemIds).delete()
  }

  const removeAnswers = () => {
    const answerIds = answers.map((ans) => ans.id)
    return db.answers.where('id').anyOf(answerIds).delete()
  }

  const handleFinishApplication = async () => {
    if (!application) {
      return
    }

    dispatch({ type: 'FINISH_APPLICATION' })
    updateExamStatus({ finished: true })
    const payload = await getSyncAnswersPayload()
    try {
      await axios.post(
        `${API_HOST}/v1/applications/${applicationId}/finish`,
        payload
      )

      try {
        await removeItems()
      } catch (_) {
        // If unable to remove items, just ignore it
      }

      try {
        await removeAnswers()
      } catch (_) {
        // If unable to remove answers, just ignore it
      }

      try {
        await clearCurrentSituation(user?.id.toString())
      } catch (_) {
        // If unable to tell Firebase, just ignore it. The correct
        // values will be set when the user enters the first item from their
        // next application.
      }

      dispatch({ type: 'FINISH_APPLICATION_SUCCESS' })

      setApplicationState(
        application?.roomId?.toString(),
        user?.id?.toString(),
        false
      )

      history.push(`/applications/${application.id}/finished`)
      setReviewModal(false)

      return true
    } catch (e) {
      dispatch({
        type: 'FINISH_APPLICATION_ERROR',
        payload: t('An error occurred. Please try again.')
      })
      return false
    }
  }

  // If application reaches timeout open warn user and finish application
  const handleTimeout = async () => {
    ReactSwal.fire({
      title: t('The time for this step has been reached'),
      text: t("Don't worry, your answers entered so far are saved."),
      icon: 'warning'
    }).then(async () => {
      const finished = await handleFinishApplication()
      if (!finished) {
        ReactSwal.fire({
          title: t('An error occurred'),
          text: t('Please check your internet connection and try again.'),
          icon: 'error'
        }).then(() => {
          handleTimeout()
        })
      }
    })
  }

  const getUnansweredItems = () => {
    if (!application) {
      return
    }
    return db.answers
      .filter((d) => d.application.id === application.id)
      .filter((d) => !d.freeResponse)
      .filter((d) => !d.alternative)
      .filter((d) => !d.gradeLinear)
      .filter((d) => d.itemCategory !== 'REPERTORIZATION_TABLE')
      .filter((d) => d.alternatives?.length === 0)
      .count()
  }

  const finishApplication = async () => {
    if (configuration?.canBrowseAcrossItems) {
      setReviewModal(true)
      const items = await getUnansweredItems()
      setUnansweredItems(items)
    } else {
      handleFinishApplication()
    }
  }

  const checkForCameraPermissions = useCallback(async () => {
    if (!VIDEO_RECORDING_ENABLED) {
      return
    }
    if (
      !application ||
      !application.exam ||
      !application.exam.collection ||
      !user
    ) {
      return
    }
    if (!configuration) {
      return
    }

    const hasVideoRequirement = application.exam.collection.shouldStreamToAgora
    //  ||
    // (configuration.shouldUploadToS3 ||
    //   configuration.shouldStreamToFirebase ||
    //   configuration.shouldFaceDetect)

    if (!hasVideoRequirement) {
      return
    }

    const permissionsOk = await permissionsAreOk()
    if (permissionsOk) {
      setCameraAccepted(application.roomId, user.id, true)
      return
    }

    setCameraAccepted(application.roomId, user.id, false)
    history.push('/camera-permission-tutorial')
  }, [application, history, permissionsAreOk, configuration, user])

  const joinRoom = useCallback(() => {
    if (!application) {
      return
    }
    joinRoomAsCandidate(application.roomId)
  }, [joinRoomAsCandidate, application])

  // Fetches application on component mount
  useEffect(() => {
    fetchApplication()
  }, [fetchApplication])

  // Fetches answers when application is defined
  useEffect(() => {
    fetchAnswers()
  }, [fetchAnswers])

  useEffect(() => {
    const in_browser = application?.user?.isInBrowser
    if (!configuration || configuration.canOpenInAnyBrowser || in_browser)
      return

    if (!kioskToken.get()) {
      toast.error(t('You should access through the Safe Browser.'))

      return history.push('/applications')
    }
  }, [configuration, history, application, t])

  useEffect(() => {
    checkForCameraPermissions()
    joinRoom()
  }, [application, checkForCameraPermissions, joinRoom])

  useEffect(() => {
    internetManager.start(user)
  }, [user])

  if (reviewModal) {
    return (
      <Modal
        isOpen={reviewModal}
        cancelText="Voltar"
        actionText="Enviar"
        onAction={() => handleFinishApplication()}
        onClose={() => setReviewModal(false)}
        onCancel={() => setReviewModal(false)}
        title={
          application?.type?.id == 3
            ? t('Are you sure you want to end the activity?')
            : t('Are you sure you want to send answers?')
        }
      >
        {unansweredItems > 0 &&
          t('You have {{unansweredItems}} questions without an answer.', {
            unansweredItems
          })}
      </Modal>
    )
  }

  const contextValue = {
    ...state,
    dispatch,
    fetchApplication,
    fetchAnswers,
    startApplication,
    resumeApplication,
    checkApplicationAnswers,
    returnToApplicationList,
    finishApplication,
    handleTimeout,
    addMoreTime,
    checkAnswersPage
  }

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children(contextValue)}
    </ApplicationContext.Provider>
  )
}

export default ApplicationState
