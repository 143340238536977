import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Container } from '@material-ui/core'
import Button from 'components/Button'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

type Props = {
  className?: string
  fetchNextApplicationId: (applicationId: number) => Promise<number | null>
  onContinueToNextApplication: (nextUrl: string) => void
  onBackToApplicationsList: () => void
  applicationType: any
}

const ApplicationFinished: React.FC<Props> = ({
  className,
  fetchNextApplicationId,
  onContinueToNextApplication,
  onBackToApplicationsList,
  applicationType
}: Props) => {
  const { t } = useTranslation()
  const { applicationId } = useParams()
  const [nextApplicationId, setNextApplicationId] = useState<number>(null)

  useEffect(() => {
    if (!applicationId) {
      return
    }

    fetchNextApplicationId(+applicationId).then(setNextApplicationId)
  }, [applicationId, fetchNextApplicationId])

  const getNextUrl = () => {
    return `/applications/${nextApplicationId}`
  }

  const handleContinue = () => {
    onContinueToNextApplication(getNextUrl())
  }

  const handleGoToApplicationList = () => {
    onBackToApplicationsList()
  }
  return (
    <Container maxWidth="sm" className={className}>
      <Box
        flexDirection="column"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <FontAwesomeIcon icon="check-circle" size="3x" color="#008262" />
        <h1>{t('Congratulations!')}</h1>
        <h3>
          {applicationType?.id == 3
            ? t(
                'Congratulations! You have successfully completed one more step.'
              )
            : t('Your answers were successfully sent!')}
        </h3>
        {nextApplicationId ? (
          <Button onClick={handleContinue} action="primary">
            {t('Continue to the next Exam')}
          </Button>
        ) : null}
        <Button
          onClick={handleGoToApplicationList}
          action={nextApplicationId ? 'secondary' : 'primary'}
        >
          {t('Back to Exams List')}
        </Button>
      </Box>
    </Container>
  )
}

export default styled(ApplicationFinished)`
  margin-top: 2rem;

  button {
    margin-top: 2rem;
  }

  h1 {
    margin-bottom: 0;
  }

  p {
    font-size: 18px;
  }
`
