import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import CardList from 'components/CardList'
import RecipientCard from 'components/Email/RecipientCard'
import HierarchyFilterBox from 'components/HierarchyFilterBox'
import ListPage from 'components/ListPage'
import Page from 'components/Page'
import PaginationFooter from 'components/PaginationFooter'
import { FieldArray, Formik } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { AuthContext } from 'states/AuthState'
import { BulkCreateApplicationContext } from 'states/BulkCreateApplicationState'
import EmailRecipientListState from 'states/EmailRecipientListState'
import { FormValues, IHierarchy } from 'types'
import BulkCreateApplicationPageInfo from './BulkCreateApplicationPageInfo'

const SelectApplicationUsers: React.FC = () => {
  const { t } = useTranslation()
  const { currentStep, setSelectedUsers } = useContext(
    BulkCreateApplicationContext
  )
  const { user } = useContext(AuthContext)

  const defaultFilterParams = {
    group: 'ALUNO',
    is_active: true
  }

  const formInitialValues: FormValues = {
    collection: undefined,
    selectedAll: undefined
  }

  const mapHierarchy = (hierarchy: {
    [key: string]: IHierarchy | undefined
  }) => {
    // Maps hierarchy filter to how the api expects
    if (!hierarchy) {
      return undefined
    }

    const value = Object.entries(hierarchy)
      .filter((entry) => entry[1])
      .reduce(
        (acc, entry) => ({
          ...acc,
          [entry[0]]: { name: get(entry[1], 'name') }
        }),
        {}
      )

    // If no value selected, returns undefined
    // so it is not added in query string by axios
    return isEmpty(value) ? undefined : value
  }

  const mapFilters = (values: FormValues) => {
    // Maps filters from how they are saved in form to how the api expects
    const { collection, ...hierarchy } = values
    return {
      collection: get(collection, 'id'),
      hierarchy: mapHierarchy(hierarchy)
    }
  }

  if (currentStep !== 3) {
    return <></>
  }

  return (
    <EmailRecipientListState defaultFilterParams={defaultFilterParams}>
      {({
        results,
        numPages,
        handleFilter,
        handlePageChange,
        handlePageSizeChange,
        count,
        isLoading,
        pageSize
      }) => (
        <div>
          <Page customWidth={1366}>
            <BulkCreateApplicationPageInfo
              title={t('Batch activity allocation')}
              instructions={t('Select the candidates')}
            />
          </Page>
          <ListPage customWidth={1366}>
            <Formik
              initialValues={formInitialValues}
              onSubmit={(values) =>
                handleFilter({ ...defaultFilterParams, ...mapFilters(values) })
              }
            >
              {(formik) => (
                <>
                  <HierarchyFilterBox
                    providerCodename={user?.provider?.codename}
                  />
                  <div>
                    <CardList>
                      {isLoading ? (
                        <Skeleton height={110} count={pageSize} />
                      ) : (
                        <FieldArray
                          name="users"
                          render={() => (
                            <div>
                              {results.map((user) => (
                                <RecipientCard key={user.id} user={user} />
                              ))}
                            </div>
                          )}
                        />
                      )}
                    </CardList>
                    <PaginationFooter
                      pageSize={pageSize}
                      pageCount={numPages}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                    >
                      <p>
                        Mostrando {results.length} de {count} registros.
                      </p>
                    </PaginationFooter>
                    <div className="button-div">
                      <Button
                        onClick={() =>
                          setSelectedUsers(
                            get(formik, 'values.users', []).filter(
                              (value) => value
                            ),
                            mapFilters(get(formik, 'values'))
                          )
                        }
                        action="primary"
                      >
                        Avançar
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </ListPage>
        </div>
      )}
    </EmailRecipientListState>
  )
}

export default SelectApplicationUsers
