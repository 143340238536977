import CreateNoticeForm from 'components/CreateNoticeForm'
import Page from 'components/Page'
import React from 'react'
import styled from 'styled-components'

type CreateNoticePageProps = {
  className?: string
}

const CreateNoticePage = ({ className }: CreateNoticePageProps) => {
  return (
    <div className={className}>
      <Page>
        <CreateNoticeForm edit={false} />
      </Page>
    </div>
  )
}
export default styled(CreateNoticePage)``
