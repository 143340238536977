import React from 'react'
import styled from 'styled-components'
import Draggable from 'react-draggable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'

interface DraggableModalProps {
  className?: string
  onAction: () => unknown
  isOpen: boolean
  defaultPosition?: {
    x: number
    y: number
  }
}

const DraggableModal: React.FC<DraggableModalProps> = ({
  children,
  className,
  onAction,
  isOpen,
  defaultPosition = { x: 0, y: 0 }
}) => {
  if (!isOpen) return <></>

  return (
    <Draggable
      axis="both"
      handle=".handle"
      defaultPosition={defaultPosition}
      position={null}
      grid={[1, 1]}
      scale={1}
      defaultClassName={className}
    >
      <div className="handle">
        <div className="draggable-body">
          <Box display="flex" justifyContent="flex-end" paddingBottom="10px">
            <FontAwesomeIcon
              onClick={onAction}
              className="close-icon"
              icon={['fas', 'times']}
              size="2x"
            />
          </Box>
          {children}
        </div>
      </div>
    </Draggable>
  )
}

export default styled(DraggableModal)`
  position: fixed;
  top: 5%;
  z-index: 102;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.196);

  .draggable-body {
    cursor: move;
    background: white;
    padding: 1rem;
  }

  .close-icon {
    cursor: pointer;
  }
`
