import { useCallback } from 'react'
import useFirebaseCollection from './useFirebaseCollection'
import { getNotSeenCandidateMessages } from 'data/apis/message-exchange'
import { Message } from 'data/domain/message-exchange'

function useNotSeenCandidateMessages(
  roomId: string | undefined,
  candidateId: string | undefined
) {
  const _getNotSeenCandidateMessages = useCallback(() => {
    if (!roomId || !candidateId) {
      return null
    }
    return getNotSeenCandidateMessages(roomId, candidateId)
  }, [roomId, candidateId])

  const { collection: messages } = useFirebaseCollection<Message>(
    _getNotSeenCandidateMessages
  )
  return { messages }
}

export default useNotSeenCandidateMessages
