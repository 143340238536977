import { useEffect } from 'react'
import { fetchAppealProcess } from 'data/apis/appeals'
import { useQuery } from 'react-query'

const useAppealProcess = (applicationId: number) => {
  const {
    data: appealProcess,
    isFetching: fetchingAppealProcess,
    refetch
  } = useQuery(
    ['appeal-process', applicationId],
    () => fetchAppealProcess(applicationId),
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  useEffect(() => {
    refetch()
  }, [applicationId, refetch])

  return { appealProcess, fetchingAppealProcess }
}

export default useAppealProcess
