import classnames from 'classnames'
import { useField, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { OptionTypeBase } from 'react-select'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import { IHierarchyCategory } from 'types'

interface SelectMultipleCategoriesProps extends OptionTypeBase {
  loadOptions: (search?: any) => Promise<any>
  hierarchies: IHierarchyCategory
  onValueChange?: any
  className?: string
  label?: string
  name: string
  help?: string
  defaultOptions?: boolean
  placeholder?: string
  getOptionLabel?: (option: any) => string
  getOptionValue?: (option: any) => string
  required?: boolean
}

const SelectMultipleCategories = ({
  help,
  label,
  className,
  defaultOptions,
  placeholder,
  loadOptions,
  name,
  hierarchies,
  required,
  onValueChange,
  ...rest
}: SelectMultipleCategoriesProps) => {
  const { setFieldValue, submitCount } = useFormikContext()
  const [, meta] = useField({
    name
  })
  const error = submitCount > 0 ? meta.error : undefined

  // const setSelectValue = async (name, value) => {
  //   if (value !== null) {
  //     if (categories.indexOf(value) === -1) {
  //       setFieldValue(name, hierarchies)
  //     }
  //   }
  // }

  // const filterCategories = useCallback(() => {
  //   return categories.filter((category) =>
  //     hierarchies.find((x) => x === category.codename)
  //   )
  // }, [categories, hierarchies])

  // const setFilteredHierarchies = () => {
  //   const i = filterCategories()
  //   // setCategories(i)
  // }

  useEffect(() => {
    setFieldValue(name, hierarchies)
  }, [hierarchies, name, setFieldValue])

  return (
    <div className={className}>
      {label && (
        <span
          className={classnames('input-label', { 'input-required': required })}
        >
          {label}
        </span>
      )}
      <AsyncSelect
        className="inner-field"
        isClearable
        placeholder={placeholder || 'Buscar...'}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        cacheOptions={false}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onChange={onValueChange}
        {...rest}
      />
      {error && <span className="input-helper red">{error}</span>}
      {error && help && <br />}
      {help && <span className="input-helper">{help}</span>}
    </div>
  )
}

export default styled(SelectMultipleCategories)`
  .input-label {
    font-weight: bolder;
  }

  .inner-field {
    margin-top: 5px;
  }

  .input-required {
    &:after {
      content: '*';
    }
  }
`
