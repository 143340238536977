import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded'
import { API_HOST } from 'consts'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import Html from './Html'
import swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type CardPluralProps = {
  className?: string
  content?: any
  refetch?: any
  title?: string
  activeSinceDay: any
  activeSinceTime: any
  noticeId: number
  status: boolean
  index: number
}

const CardPlural = ({
  className,
  content,
  refetch,
  title,
  activeSinceDay,
  activeSinceTime,
  noticeId,
  status,
  index
}: CardPluralProps) => {
  const history = useHistory()
  const [admin, setAdmin] = useState(false)
  const [getUserGroup, setGetUserGroup] = useState(null)
  const deleteNotice = () => {
    swal
      .fire({
        icon: 'warning',
        text: 'Deseja remover este aviso?',
        title: 'Atenção',
        showCancelButton: true,
        cancelButtonText: 'Cancelar'
      })
      .then((result) => {
        if (result.value === true) {
          Axios.delete(`${API_HOST}/billboard/${noticeId}/delete_notice`).then(
            () => {
              refetch()
              document.getElementById(`${index}`).style.display = 'none'
            }
          )
        }
      })
  }
  const editNotice = () => {
    history.push(`plural-editar-aviso/${noticeId}`)
  }
  const verifyUser = useCallback(() => {
    setGetUserGroup(true)
    const user: JSON = JSON.parse(localStorage.getItem('user'))
    const userGroup = get(user, 'groups')
    for (let i = 0; i < userGroup.length; i++) {
      if (userGroup[i] === 'ADMINISTRADOR') {
        setAdmin(true)
      }
    }
  }, [])
  useEffect(() => {
    if (!getUserGroup) verifyUser()
  }, [admin, getUserGroup, verifyUser])
  return (
    <div className={className}>
      <Card className="card-container" id={`${index}`}>
        <div className="title-and-icons">
          {admin ? (
            <>
              {status === true ? (
                <div className="icons_status">
                  <div
                    style={{
                      backgroundColor: '#61B082',
                      borderRadius: '50px',
                      width: '15px',
                      height: '15px',
                      boxShadow: '0px 1px 5px rgba(58, 58, 58, 0.25)'
                    }}
                  ></div>
                </div>
              ) : (
                <div className="icons_status">
                  <div
                    style={{
                      backgroundColor: '#E96240',
                      borderRadius: '50px',
                      width: '15px',
                      height: '15px',
                      boxShadow: '0px 1px 5px rgba(58, 58, 58, 0.25)'
                    }}
                  ></div>
                </div>
              )}
            </>
          ) : (
            ''
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '200px',
            overflow: 'scroll'
          }}
        >
          <span
            style={{
              margin: '0',
              fontSize: '18px',
              fontWeight: 600
            }}
          >
            {title}
          </span>
          <div className="card-content">
            <Html>{content}</Html>
          </div>
        </div>
        <div className="icons-timers">
          <p className="p-title">Data de publicação:</p>
          <div className="grid-item">
            <CalendarTodayIcon style={{ fontSize: 'medium' }} />
            <span className="time">
              {activeSinceDay && activeSinceDay ? activeSinceDay : '--/--/----'}
            </span>
          </div>
          <div className="grid-item">
            <AccessAlarmsRoundedIcon style={{ fontSize: 'medium' }} />
            <span className="time">
              {activeSinceTime && activeSinceTime ? activeSinceTime : '--:--'}
            </span>
          </div>
          {admin ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                marginLeft: '0.7rem',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <FontAwesomeIcon
                  icon="trash"
                  className="icon"
                  style={{ color: '#E96240' }}
                  onClick={() => deleteNotice()}
                />
                <FontAwesomeIcon
                  icon="edit"
                  className="icon"
                  style={{ color: '#F1AE3D' }}
                  onClick={() => editNotice()}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Card>
    </div>
  )
}

export default styled(CardPlural)`
  width: 100%;
  .card-container {
    height: 100%;
    width: 100%;
    border: 3px solid #f4f4f4;
    box-shadow: 0px 1px 5px 0px rgb(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 1.3rem;
    display: flex;
    margin-left: 1rem;
    justify-content: space-between;
    flex-direction: column;
    min-height: 280px;
    word-wrap: break-word;
    text-align: justify;
  }
  .title-and-icons {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .card-title {
    font-size: 14px;
    color: #abb0ba;
    font-weight: 600;
  }

  .card-content {
    width: 100%;
    overflow: scroll;
    color: #4a4f58;
    font-size: 14px;
  }
  .icons-timers {
    display: flex;
    margin-top: 2rem;
  }
  .grid-item {
    margin-left: 3rem;
    display: flex;
    align-items: center;
  }
  .time {
    margin-left: 0.7rem;
  }
  .p-title {
    display: flex;
    justify-content: flex-start;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-left: 0.1rem;
    color: #863bff;
  }
  .icon {
    font-size: 20px;
    margin-left: 0.7rem;
  }
  @media screen and (max-width: 1320px) {
    .icons-timers {
      display: grid;
      grid-template-columns: 33.3% 33.3% 33.3%;
      grid-template-rows: 50px 50px;
      font-size: 10px;
      .MuiSvgIcon-root {
        display: none;
      }
    }
  }
  @media screen and (max-width: 650px) {
    .icons-timers {
      display: none;
    }
  }
`
