export const getMotive = (motiveCode: string) => {
  return {
    BREAK: 'Pedido para ir ao banheiro',
    BREAK_START: 'Ida ao banheiro',
    BREAK_END: 'Volta do banheiro',
    ACCEPT_BREAK: 'Pedido para ir ao banheiro aceito',
    REFUSE_BREAK: 'Pedido para ir ao banheiro negado',
    LATE_BREAK_RETURN: 'Candidato não retornou no prazo',
    PAUSE: 'Ação do aplicador',
    MESSAGE: 'Mensagem enviada',
    IMPROPER_BEHAVIOR: 'Comportamento suspeito',
    MORE_TIME: 'Candidato ganhou mais tempo para fazer a atividade'
  }[motiveCode]
}

export const getEvent = (eventCode: string) => {
  return {
    PAUSED: 'Atividade pausada',
    RESUMED: 'Atividade retomada'
  }[eventCode]
}
