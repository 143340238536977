import styled from 'styled-components'
import React from 'react'
import Button, { ButtonProps } from './Button'

interface TooltipButtonProps extends ButtonProps {
  tooltipText: string
  className?: string
  onClick: () => unknown
}

const TooltipButton: React.FC<TooltipButtonProps> = ({
  className,
  tooltipText,
  children,
  onClick,
  ...props
}) => {
  return (
    <div className={className} onClick={onClick}>
      <div className="popover-text">
        <p>{tooltipText}</p>
      </div>
      <Button {...props}>{children}</Button>
    </div>
  )
}

export default styled(TooltipButton)`
  position: relative;

  &:hover {
    .popover-text {
      display: block;
    }
  }

  .popover-text {
    display: none;
    position: absolute;
    top: -15px;
    left: 0;
    transform: translateY(-100%);
    background: #ffffff;
    text-align: center;
    min-width: 100px;
    transition: all 300ms;
    padding: 10px 0;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.019);

    &::after {
      content: '';
      bottom: -10px;
      left: 8px;
      width: 0;
      position: absolute;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #ffffff;
      z-index: 1;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.019);
    }

    p {
      margin: 0;
      font-size: 11px;
    }
  }
`
