import React, { useContext } from 'react'
import useQuery from '../../hooks/useQuery'
import ExamDashboardState from 'states/ExamDashboardState'
import { IExamDashboardState } from 'types'
import { Formik, FormikValues } from 'formik'
import HierarchyFilterBox from 'components/HierarchyFilterBox'
import UserInput from 'components/Inputs/UserInput'
import CardList from 'components/CardList'
import Skeleton from 'react-loading-skeleton'
import PaginationFooter from 'components/PaginationFooter'
import { AuthContext } from 'states/AuthState'
import styled from 'styled-components'
import devices from 'utils/devices'
import Page from 'components/Page'
import PostExamApplicationCard from 'components/PostExam/PostExamApplicationCard'
// import useCollection from 'hooks/useCollection'
import axios from 'axios'
import Button from 'components/Button'
import { API_HOST } from 'consts'
import { toast } from 'react-toastify'
import ActionMenuButton from 'components/Buttons/ActionMenuButton'
import { useTranslation } from 'react-i18next'
import CardListToolbar from 'components/CardListToolbar'

type PostExamProps = {
  className?: string
}

const PostExam = ({ className }: PostExamProps) => {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')
  // const collection = useCollection(collectionId)

  const formInitialValues: FormikValues = {
    collection: undefined
  }

  const createBulkOrder = async () => {
    try {
      toast.success('Boletins gerados com sucesso!')
      await axios.post(
        `${API_HOST}/v1/generate-bulk-order/${collectionId}/generate`
      )
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Page customWidth={1366}>
      <ExamDashboardState>
        {({
          applications,
          fetchingApplications,
          onFilter,
          totalApplications,
          handlePageChange,
          onPageSizeChange,
          numPages,
          pageSize
        }: IExamDashboardState) => (
          <div className={className}>
            <div className="parent-wrapper">
              <div className="filter-wrapper">
                <Formik initialValues={formInitialValues} onSubmit={onFilter}>
                  {() => (
                    <HierarchyFilterBox
                      distinct={false}
                      providerCodename={user?.provider?.codename}
                    >
                      <UserInput basic />
                    </HierarchyFilterBox>
                  )}
                </Formik>
              </div>
              <div className="card-list-wrapper">
                {/* <SearchBarWrapper onSearchChange={onSearchChange} /> */}
                <CardList>
                  <CardListToolbar
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <ActionMenuButton label={t('Actions')}>
                      <Button action="unstyled" onClick={createBulkOrder}>
                        Gerar boletins em massa
                      </Button>
                    </ActionMenuButton>
                  </CardListToolbar>
                  {fetchingApplications && (
                    <Skeleton height={150} count={pageSize} />
                  )}
                  {!fetchingApplications &&
                    applications.map((application) => (
                      <PostExamApplicationCard
                        application={application}
                        key={application.id}
                        exam={application.exam}
                      />
                    ))}
                </CardList>
                <PaginationFooter
                  pageSize={pageSize}
                  pageCount={numPages}
                  onPageChange={handlePageChange}
                  onPageSizeChange={onPageSizeChange}
                  disablePageSizeChange
                >
                  <p>
                    Mostrando {applications.length} de {totalApplications}{' '}
                    registros.
                  </p>
                </PaginationFooter>
              </div>
            </div>
          </div>
        )}
      </ExamDashboardState>
    </Page>
  )
}

export default styled(PostExam)`
  .parent-wrapper {
    display: flex;
    column-gap: 20px;
  }

  @media (max-width: ${devices.mobileL}) {
    .parent-wrapper {
      flex-direction: column;
      row-gap: 20px;
      column-gap: 0;
    }
  }

  .filter-wrapper {
    max-width: 400px;

    @media (min-width: ${devices.tablet}) {
      min-width: 400px;
      column-gap: 20px;
    }
  }

  .card-list-wrapper {
    padding-left: 1rem;
    flex-grow: 6;
  }

  .generate-report-card {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`
