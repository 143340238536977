import React, { useCallback, useEffect, useState } from 'react'
import { LinePlot } from 'd3plus-plot'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import { Connection } from 'data/domain/message-exchange'
import moment from 'moment'
import { LineChartItem } from 'types'
import { uniqBy } from 'lodash'
import { AVERAGE_LATENCY_IN_MILISSECONDS } from 'consts'
import { Paper, Tab, Tabs } from '@material-ui/core'

const averageLatency = parseInt(AVERAGE_LATENCY_IN_MILISSECONDS)

interface InternetMeasurementChartProps {
  userName: string
  connectionRecords: Connection[]
}

interface ChartItem {
  id: string
  x: string
  y: number
}

const formatConnectionRecordDate = (when: Date) =>
  moment(when).format('DD/MM/YYYY HH:mm')

const isValidXLabel = (xLabel: string) =>
  !!['00', '20', '40'].find((d) => xLabel.endsWith(d))

const getAverageLimits = (): number[] => [
  averageLatency / 2,
  averageLatency,
  averageLatency + averageLatency / 2
]

const getXLabels = (chartItems: ChartItem[]) => {
  if (!chartItems.length) {
    return []
  }
  return [
    chartItems[0].x,
    ...chartItems
      .slice(1, -1)
      .map((d) => d.x)
      .filter(isValidXLabel),
    chartItems[chartItems.length - 1].x
  ]
}

const getRoundedNumber = (numberToRound: number) => {
  if (numberToRound < 2000) {
    return Math.round(numberToRound / 100) * 100
  }

  return Math.round(numberToRound / 1000) * 1000
}

const calculateLabels = (maxValue: number) => {
  const divi = maxValue / 5
  let currentDomain = 0
  const domains = []
  for (let i = 0; i < 5; i++) {
    domains.push(getRoundedNumber(currentDomain))
    currentDomain = Math.floor(currentDomain + divi)
  }
  return [...domains, maxValue]
}

/**
 *
 * @param chartItems
 * @returns 2 numbers which will set the Y axis boundaries.
 */

const getYDomains = (
  chartItems: ChartItem[],
  kind: 'latency' | 'score'
): number[] => {
  if (kind === 'latency') {
    let yDomains = []
    yDomains = chartItems.map(({ y }) => y)
    const safeMetrics = getAverageLimits()
    yDomains = [...yDomains, ...safeMetrics]
    return [0, Math.max(...yDomains) + 200]
  }
  return [0, 100]
}

/**
 * @param chartItems
 *
 * gets the getYDomain boundaries and uses them as reference. Only the max number will
 * be used in this case.
 *
 * @returns an array with the sequence of numbers between the 1 and the top domain
 */

const getYLabels = (chartItems: ChartItem[], kind: 'latency' | 'score') => {
  if (!chartItems.length) return []

  const labels = getYDomains(chartItems, kind)
  const highestLabel = Math.max(...labels)

  let actualLabels = []
  if (highestLabel >= 200) {
    actualLabels = calculateLabels(highestLabel)
  }

  return actualLabels
}

const sortByAxis = (axis: string, dataToSort: ChartItem[]): ChartItem[] =>
  dataToSort.sort((a, b) => a[axis] - b[axis])

const InternetMeasurementChart: React.FC<InternetMeasurementChartProps> = ({
  userName,
  connectionRecords
}) => {
  const [kind, setKind] = useState<'score' | 'latency'>('latency')
  const { t } = useTranslation()
  const formatMeasurementRecordsToFitChart = useCallback(
    (records: Connection[]) => {
      const chartItems: LineChartItem[] = []
      for (const record of records) {
        chartItems.push({
          id: userName,
          x: formatConnectionRecordDate(record.when),
          y: kind === 'latency' ? record.currentAverage : record.currentScore
        })
      }
      return chartItems
    },
    [userName, kind]
  )

  const renderChart = useCallback(
    (chartItems: LineChartItem[]) => {
      d3.select('#internet-chart').selectAll('svg').remove()
      new LinePlot()
        .select(`#internet-chart`)
        .config({
          data: chartItems,
          height: 500,
          shapeConfig: {
            label: true,
            width: 30,
            Line: {
              stroke: '#000'
            }
          },
          tooltipConfig: {
            title: (data) => {
              if (kind === 'latency') {
                return `Latencia: ${Math.floor(data.y)}ms no dia ${data.x}`
              }

              return `Score: ${Math.floor(data.y)} no dia ${data.x}`
            }
          },
          legendConfig: {
            data: [
              { id: 1, label: 'Péssimo' },

              { id: 3, label: 'Bom' },

              { id: 5, label: 'Ótimo' }
            ],
            label: (data) => data.label,
            shape: 'Line',
            shapeConfig: {
              fill: (data) => {
                if (data.id === 1) return 'red'
                if (data.id === 3) return 'green'
                if (data.id === 5) return 'blue'
              }
            }
          },
          xConfig: {
            title: t('Schedule'),
            labels: getXLabels(chartItems)
          },
          yConfig: {
            title: kind === 'latency' ? t('Latencia (ms)*') : 'score',
            labels: getYLabels(chartItems, kind),
            domain: getYDomains(chartItems, kind)
          },
          legendPosition: 'bottom',
          annotations: [
            {
              data: [
                {
                  id: 'greatLimit',
                  x: sortByAxis('x', chartItems)[0].x,
                  y: getAverageLimits()[0]
                },
                {
                  id: 'greatLimit',
                  x: chartItems[chartItems.length - 1].x,
                  y: getAverageLimits()[0]
                },

                {
                  id: 'regularLimit',
                  x: chartItems[0].x,
                  y: getAverageLimits()[1]
                },
                {
                  id: 'regularLimit',
                  x: chartItems[chartItems.length - 1].x,
                  y: getAverageLimits()[1]
                },

                {
                  id: 'terribleLimit',
                  x: chartItems[0].x,
                  y: getAverageLimits()[2]
                },
                {
                  id: 'terribleLimit',
                  x: chartItems[chartItems.length - 1].x,
                  y: getAverageLimits()[2]
                }
              ],
              shape: 'Line',
              stroke: (data) => {
                if (data.id === 'greatLimit') return 'blue'
                if (data.id === 'regularLimit') return 'green'
                if (data.id === 'terribleLimit') return 'red'
              },
              strokeDasharray: '5',
              strokeWidth: 2
            }
          ]
        })
        .render()
    },
    [t, kind]
  )

  useEffect(() => {
    if (!connectionRecords.length) return
    const formattedRecords = formatMeasurementRecordsToFitChart(
      uniqBy(connectionRecords, (d) => formatConnectionRecordDate(d.when))
    )
    renderChart(formattedRecords)
  }, [formatMeasurementRecordsToFitChart, connectionRecords, renderChart])

  return (
    <>
      <Paper>
        <Tabs
          value={{ latency: 0, score: 1 }[kind]}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newValue) => {
            setKind({ 0: 'latency', 1: 'score' }[newValue])
          }}
        >
          <Tab label="Latência" />
          <Tab label="Score" />
        </Tabs>
      </Paper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: '20px'
        }}
      >
        <div id={`internet-chart`} className="chart" data-testid="chart"></div>
        <p style={{ fontSize: '12px' }}>
          * Tempo médio de resposta de conexão entre usuário e servidor
        </p>
        <p style={{ fontSize: '12px', fontStyle: 'italic' }}>
          Tempo de resposta aceitável(milissegundos): {averageLatency}
        </p>
      </div>
    </>
  )
}

export default InternetMeasurementChart
