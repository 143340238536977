import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Button from 'components/Button'
import Modal from 'components/Modal/Modal'
import { startPaymentProcess } from 'data/apis/appeals'
import { get, sortBy } from 'lodash'
import React, { useState, useCallback } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

type CartModalProps = {
  className?: any
  candidateId?: any
  onClose?: boolean
  appeals?: any
  appealProcessId?: number
  onDeleteAppeal: (appealId: number) => Promise<any>
  perAppealValue?: number
}

const CartModal = ({
  className,
  appeals,
  appealProcessId,
  onDeleteAppeal,
  perAppealValue
}: CartModalProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const [removedAppealIds, setRemovedAppealIds] = useState([])
  const [deletingAppeal, setDeletingAppeal] = useState<number | undefined>(
    undefined
  )
  const handleClose = () => {
    setIsOpen(false)
  }
  const sortedAppeals = sortBy(appeals, (ap) => ap?.item).filter(
    (ap) => !removedAppealIds.includes(ap?.id)
  )

  const [handleStartPaymentProcess] = useMutation(
    () => {
      return startPaymentProcess(appealProcessId)
    },
    {
      onSuccess: ({ processUrl }: { processUrl: string }) => {
        window.location.href = processUrl
      },
      onError: () => {
        toast.error(
          'Ocorreu um erro ao iniciar o processo de pagamento do recurso.'
        )
      }
    }
  )

  const handleDeleteAppeal = useCallback(
    (appealId: number) => {
      onDeleteAppeal(appealId).then(() => {
        setRemovedAppealIds((previous) => [...previous, appealId])
        setDeletingAppeal(undefined)
      })
    },
    [onDeleteAppeal]
  )

  const showDeletingAlertMessage = useCallback(
    (appealId: number, index: number, item: number) => {
      const DeleteAppealAlertMessage = ({
        item,
        appealId
      }: {
        item: number
        appealId: number
      }) => {
        return (
          <div className="removing-appeal">
            <Alert severity="error">
              <>
                <div>
                  Tem certeza que deseja remover o recurso para o objeto de
                  aprendizagem {item}?
                </div>
                <div>Essa ação não pode ser desfeita.</div>
                <div className="deleting-appeal-buttons">
                  <Button onClick={() => setDeletingAppeal(undefined)}>
                    Cancelar
                  </Button>
                  <Button
                    action="error"
                    onClick={() => handleDeleteAppeal(appealId)}
                  >
                    Deletar
                  </Button>
                </div>
              </>
            </Alert>
          </div>
        )
      }
      const lastAppeal = index === sortedAppeals?.length - 1
      const isOddAppeal = index % 2 > 0
      const currentDeletingAppeal = deletingAppeal === appealId

      if (lastAppeal && currentDeletingAppeal) {
        return <DeleteAppealAlertMessage item={item} appealId={appealId} />
      }

      const previousAppeal = get(sortedAppeals, (index - 1).toString())
      const previousDeletingAppeal = deletingAppeal === previousAppeal?.id
      if (isOddAppeal) {
        if (currentDeletingAppeal) {
          return <DeleteAppealAlertMessage item={item} appealId={appealId} />
        }

        if (previousDeletingAppeal) {
          return (
            <DeleteAppealAlertMessage
              item={+previousAppeal?.item}
              appealId={+previousAppeal?.id}
            />
          )
        }
      }
    },
    [deletingAppeal, handleDeleteAppeal, sortedAppeals]
  )

  const appealProcessAmount = (
    +perAppealValue * +sortedAppeals?.length
  ).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL'
  })
  return (
    <div className="modalRecurso">
      <Modal
        className={className}
        title="Recursos"
        isOpen={isOpen}
        onClose={handleClose}
      >
        <div className="modal">
          <span className="spanTotal">
            Total de recursos abertos: {sortedAppeals?.length}
          </span>
          <span className="spanTotal">{appealProcessAmount}</span>
          {sortedAppeals.length === 0 && (
            <span>
              Para concluir o processo de pagamento é necessário abrir pelo
              menos um recurso.
            </span>
          )}
          <div className="opened-appeals">
            {sortedAppeals &&
              sortedAppeals.map((ap, index) => (
                <>
                  <div>
                    <span className="opened-appeal">
                      Item <strong>{ap?.item}</strong>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setDeletingAppeal(+ap?.id)
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <span>Remover recurso</span>
                      </IconButton>
                    </span>
                  </div>
                  {showDeletingAlertMessage(+ap?.id, index, ap?.item)}
                </>
              ))}
          </div>
          <Alert severity="error">
            <span>
              Após clicar em concluir você será redirecionado para o portal de
              pagamentos e não será possível criar novos recursos.
            </span>
          </Alert>
        </div>
        <Button
          action="primary"
          disabled={sortedAppeals.length === 0}
          onClick={() => handleStartPaymentProcess()}
        >
          Concluir
        </Button>
      </Modal>
    </div>
  )
}

export default styled(CartModal)`
  .modal {
    display: grid;
    padding: 10px 0;
    line-height: 200%;
  }
  .strong {
    font-weight: 500;
  }
  .spanTotal {
    font-size: 16px;
    font-weight: 700;
  }

  .opened-appeals {
    display: grid;
    grid-template-columns: 1fr 1fr;

    span {
      margin-left: 5px;
    }

    .removing-appeal {
      margin: 5px;
      grid-column: span 2;

      .MuiAlert-message {
        width: 100%;
      }
    }
  }

  .deleting-appeal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    ${Button} {
      margin: 5px;
    }
  }
`
