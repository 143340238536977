import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback } from 'react'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'
import { useTranslation } from 'react-i18next'

type ExamItemInputSelectProps = {
  examId: number
  onChange: any
  label?: string
}

const ExamItemInputSelect = ({
  examId,
  label,
  ...rest
}: ExamItemInputSelectProps) => {
  const { t } = useTranslation()

  // eslint-disable-next-line
  const fetchItems = useCallback(
    debounce(async (search) => {
      const response = await axios.get(
        `${API_HOST}/v1/exams/${examId}/exam_item_by_position`,
        {
          // Need to change endpoint
          params: {
            search
          }
        }
      )
      return response.data.map((item) => {
        return {
          id: item.itemId,
          name: `${t('Question')} ${item.position} - Código ${item.id}`
        }
      })
    }, 500),
    []
  )

  return (
    <InputAsync
      defaultOptions
      loadOptions={fetchItems}
      name="items"
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.name}
      {...rest}
      label={label ?? null}
    />
  )
}

export default ExamItemInputSelect
