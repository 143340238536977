import Page from 'components/Page'
import useFeedback from 'hooks/useFeedback'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { errors, labels, messages } from 'resources'
import { DjangoGroup, Group } from 'data/domain/groups'
import { getGroup, getGroupList, updatePermissions } from 'data/apis/groups'
import { Skeleton } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import _ from 'lodash'
import Button from 'components/Button'

const fixedTranslation = {
  AnswerBreakRequest: 'Responder pedido de banheiro',
  AnswerMessages: 'Responder mensagens',
  PauseResume: 'Pausar / retomar atividade',
  SendAlert: 'Enviar alerta',
  SendReloadRequest: 'Enviar pedido de recarregamento',
  ViewHistory: 'Ver histórico',
  ViewInfo: 'Ver informações',
  ShowTimeInfo: 'Ver informações do tempo de atividade',
  SeeItem: 'Ver objeto de aprendizagem',
  SeeAppealButton: 'Ver lista de recursos',
  SendMessageGlobal: 'Enviar mensagem para todos os candidatos online',
  SeeRooms: 'Ver salas',
  Enable: 'Ativado',
  SeeGenerateReportCard: 'Ver botão de geração de boletins',
  Dashboard: 'Painel de Controle',
  Appeal: 'Recurso',
  Statistics: 'Estatísticas',
  ReportCard: 'Boletim',
  List: 'Lista',
  Videos: 'Vídeos',
  DigitalBook: 'Cadernos Digitais',
  Events: 'Agenda',
  DigitalClassroom: 'Aulas Digitais',
  Sga: 'SGA',
  Exam: 'Atividades',
  Activity: 'Ativar nome "Atividades"',
  Billboard: 'Mural de Avisos',
  Conductor: 'Maestro'
}

type PermissionManagementProps = {
  className?: string
}

const PermissionManagement = ({ className }: PermissionManagementProps) => {
  const { t } = useTranslation()
  const { handleErrorFeedback, handleSuccessFeedback } = useFeedback()
  const [groups, setGroups] = useState<DjangoGroup[]>([])
  const [fetchingGroups, setFetchingGroups] = useState(false)
  const [fetchingPermissions, setFetchingPermissions] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState('')
  const [groupPermissions, setGroupPermissions] = useState<Group>(null)
  const [isSaving, setIsSaving] = useState(false)

  const fetchPermissions = useCallback(
    async (groupName: string) => {
      try {
        setFetchingPermissions(true)
        const result = await getGroup(groupName)
        setGroupPermissions(result)
      } catch (e) {
        handleErrorFeedback(e, t(errors.ERROR_LOADING_PERMISSIONS))
      } finally {
        setFetchingPermissions(false)
      }
    },
    [handleErrorFeedback, t]
  )

  const fetchGroups = useCallback(async () => {
    try {
      setFetchingGroups(true)
      const result = await getGroupList()
      setGroups(result)

      if (result.length) {
        const firstGroup = result[0].name
        setSelectedGroup(firstGroup)
        fetchPermissions(firstGroup)
      }
    } catch (e) {
      handleErrorFeedback(e, t(errors.ERROR_LOADING_GROUPS))
    } finally {
      setFetchingGroups(false)
    }
  }, [handleErrorFeedback, t, fetchPermissions])

  useEffect(() => {
    fetchGroups()
  }, [fetchGroups])

  const handleGroupClick = (groupName: string) => {
    setSelectedGroup(groupName)
    fetchPermissions(groupName)
  }

  const renderGroups = () => {
    if (!groups) {
      return
    }

    if (!groups.length) {
      return 'Nenhum grupo encontrado'
    }

    return groups.map((group, i) => {
      return (
        <div
          key={i}
          onClick={() => handleGroupClick(group.name)}
          className={`group-card ${
            selectedGroup === group.name ? 'selected' : ''
          }`}
        >
          {group.name}
          <FontAwesomeIcon
            style={{ display: 'none' }}
            icon={faAngleRight}
          ></FontAwesomeIcon>
        </div>
      )
    })
  }

  const handlePermissionsChange = useCallback(
    (fullPath: string, newValue: boolean) => {
      const groupCopy = {
        ...groupPermissions
      }
      _.set(groupCopy, fullPath, newValue)
      setGroupPermissions(groupCopy)
    },
    [groupPermissions]
  )

  const renderThrough = useCallback(
    (level: number, recursionObject: any, pathBuilded: Array<string>) => {
      const returnElements = []
      for (const key in recursionObject) {
        const shouldRenderCheckbox = typeof recursionObject[key] === 'boolean'

        if (shouldRenderCheckbox) {
          const fullPath = `Permissions.${pathBuilded.join('.')}.${key}`
          returnElements.push(
            <FormControlLabel
              key={`${key}_label`}
              control={
                <Checkbox
                  key={`${key}_check`}
                  style={{ marginLeft: level * 25 + 'px' }}
                  checked={_.get(groupPermissions, fullPath)}
                  onChange={(event) => {
                    handlePermissionsChange(fullPath, event.target.checked)
                  }}
                ></Checkbox>
              }
              label={fixedTranslation[key] || key}
            ></FormControlLabel>
          )
          continue
        }

        returnElements.push(
          <ul
            key={key}
            className="permissions-parent"
            style={{ marginLeft: level * 25 + 'px' }}
          >
            {fixedTranslation[key] || key}
          </ul>
        )
        returnElements.push(
          <li key={key + '_li'} className="permissions-children">
            {renderThrough(level + 1, recursionObject[key], [
              ...pathBuilded,
              key
            ])}
          </li>
        )
      }
      return returnElements
    },
    [groupPermissions, handlePermissionsChange]
  )

  const renderPermissions = useCallback(() => {
    if (!groupPermissions) {
      return
    }
    const permissions = groupPermissions.Permissions
    const tree = renderThrough(1, permissions, [])
    return tree
  }, [groupPermissions, renderThrough])

  const renderSkeletons = (amount) => {
    const skeletons = []
    for (let i = 0; i < amount; i++) {
      skeletons.push(<Skeleton key={i} height={100} animation="wave" />)
    }
    return <>{skeletons}</>
  }

  const handleSave = async () => {
    try {
      setIsSaving(true)
      await updatePermissions(selectedGroup, groupPermissions)
      handleSuccessFeedback(t(messages.SAVED_SUCCESSFULLY))
    } catch (e) {
      handleErrorFeedback(e, t(errors.ERROR_SAVING_TRY_AGAIN))
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Page
      className={className}
      customWidth={1366}
      title={t(labels.PERMISSIONS)}
    >
      <div className="block-container">
        <div>
          <h3>{t(labels.GROUPS)}</h3>
          <hr></hr>
          {fetchingGroups && renderSkeletons(4)}
          {!fetchingGroups && renderGroups()}
          <div className="buttons-container">
            <Button
              action="primary"
              onClick={() => handleSave()}
              disabled={isSaving}
            >
              {t(labels.SAVE)}
            </Button>
            <Button
              action="secondary"
              onClick={() => window.location.reload()}
              disabled={isSaving}
            >
              {t(labels.CANCEL)}
            </Button>
          </div>
        </div>
        <div>
          <h3>{t(labels.PERMISSIONS)}</h3>
          <hr></hr>
          {fetchingPermissions && renderSkeletons(4)}
          {!fetchingPermissions && renderPermissions()}
        </div>
      </div>
    </Page>
  )
}

export default styled(PermissionManagement)`
  hr {
    margin-bottom: 0;
  }

  .block-container {
    display: flex;
  }

  .block-container > div {
    flex: 50%;
    margin: 1em;
  }

  .group-card {
    padding: 2em;
    padding-left: 1em;
    border-bottom: 1px solid #bbb;
    cursor: pointer;
    display: grid;
    grid-template-columns: 100% 0%;
  }

  .selected > svg {
    display: block !important;
  }

  .group-card:hover {
    background-color: #eee;
  }

  .permissions-parent {
    list-style-type: none;
    padding: 0;
  }

  .permissions-children {
    list-style-type: none;
    display: grid;
  }

  .buttons-container {
    margin-top: 2rem;
  }
`
