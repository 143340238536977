import axios from 'axios'
import CandidateMessageExchangeContext from 'contexts/CandidateMessageExchangeContext'
import ConfigContext from 'contexts/ConfigContext'
import { uploadAnswerAttachment } from 'data/apis/exams'
import {
  createAnswer,
  createBulkAnswer,
  createItemAccess
} from 'data/apis/message-exchange'
import { AnswerIn, Message } from 'data/domain/message-exchange'
import { findNextAvaiableAnswer } from 'data/utils/answers'
import debounce from 'debounce-promise'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import { RollbarErrorTracking } from 'infra/rollbar'
import moment from 'moment'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { API_HOST, FIREBASE_ENABLED } from '../consts'
import db from '../db'
import {
  AnswerStateAction,
  IAlternative,
  IAnswer,
  IAnswerState,
  IItem
} from '../types'
import { ApplicationContext } from './ApplicationState'
import { AuthContext } from './AuthState'

type AnswerProps = {
  children: Function
}

const initialAnswerState: IAnswerState = {
  answer: undefined,
  item: undefined,
  fetchItemError: '',
  fetchingItem: false,
  remainingTime: '',
  previousAnswer: undefined,
  nextAnswer: undefined,
  updateAnswer: () => {},
  bulkUpdateAnswer: () => {},
  goAnswer: () => {},
  fetchingAnswer: false,
  fetchAnswerError: '',
  getAnswerFromPosition: () => undefined,
  fetchItem: () => {},
  updateFreeResponse: () => {},
  updateMultipleLinearScale: () => {},
  isAnswered: () => false,
  alreadyAnswered: false,
  changeAnswer: false,
  handleQuestionExpired: () => undefined,
  goToNextNotExpiredItem: () => {},
  itemAccessExpired: false,
  remainingItemTimeInitialDate: undefined,
  displayWarningModal: () => undefined,
  generateIncidentAutomatically: () => undefined,
  handleFinishAnswer: () => undefined,
  handleAnswerData: () => undefined,
  timeBadgeMessage: [],
  time: undefined,
  finishingAnswer: false,
  uploadAttachedFile: () => undefined,
  removeAttachment: () => undefined,
  answerAttachments: [],
  uploadingAttachment: false,
  uploadAttachmentSuccess: false,
  uploadAttachmentError: false,
  attachmentUploadingError: ''
}

const reducer = (
  state: IAnswerState,
  action: AnswerStateAction
): IAnswerState => {
  switch (action.type) {
    case 'FINISHING_ANSWER':
      return { ...state, finishingAnswer: true }
    case 'FETCH_ITEM':
      return {
        ...state,
        fetchingItem: true,
        fetchItemError: '',
        finishingAnswer: false
      }
    case 'FETCH_ITEM_ERROR':
      return { ...state, fetchingItem: false, fetchItemError: action.payload }
    case 'FETCH_ITEM_SUCCESS':
      return {
        ...state,
        item: action.payload,
        fetchingItem: false,
        fetchItemError: ''
      }
    case 'SET_ITEM_ACCESS_EXPIRED':
      return { ...state, itemAccessExpired: action.payload }
    case 'FETCH_ANSWER':
      return { ...state, fetchingAnswer: true, fetchAnswerError: '' }
    case 'FINISH_ANSWER':
      return { ...state, answer: action.payload }
    case 'FETCH_ANSWER_ERROR':
      return {
        ...state,
        fetchingAnswer: false,
        fetchAnswerError: action.payload
      }
    case 'FETCH_ANSWER_SUCCESS':
      return {
        ...state,
        answer: action.payload
      }
    case 'UPDATE_ANSWER_ALTERNATIVE':
      return { ...state, answer: action.payload }
    case 'UPDATE_BULK_ANSWER_ALTERNATIVE':
      return { ...state, answer: action.payload }
    case 'UPDATE_FREE_RESPONSE':
      return { ...state, answer: action.payload }
    case 'UPDATE_MULTIPLE_LINEAR_SCALE':
      return { ...state, answer: action.payload }
    case 'SET_ALREADY_ANSWERED':
      return { ...state, alreadyAnswered: action.payload }
    case 'SET_CHANGE_ANSWER':
      return { ...state, changeAnswer: action.payload }
    case 'UPDATE_REMAINING_ITEM_TIME_INITIAL_DATE':
      return { ...state, remainingItemTimeInitialDate: Date.now() }

    case 'FETCH_ANSWER_ATTACHMENTS':
      return {
        ...state,
        uploadingAttachment: false,
        uploadAttachmentError: false,
        uploadAttachmentSuccess: false,
        answerAttachments: action.payload
      }
    case 'UPLOAD_ATTACHMENT_START':
      return {
        ...state,
        uploadingAttachment: true,
        uploadAttachmentError: false,
        uploadAttachmentSuccess: false
      }
    case 'UPLOAD_ATTACHMENT_SUCCESS':
      return {
        ...state,
        uploadingAttachment: false,
        uploadAttachmentError: false,
        uploadAttachmentSuccess: true,
        answerAttachments: action.payload
      }
    case 'UPLOAD_ATTACHMENT_ERROR':
      return {
        ...state,
        uploadingAttachment: false,
        uploadAttachmentError: true,
        uploadAttachmentSuccess: false,
        attachmentUploadingError: action.payload
      }
    case 'REMOVE_ATTACHMENT':
      return {
        ...state,
        answerAttachments: action.payload
      }
  }
}

export const AnswerContext = createContext<IAnswerState>(initialAnswerState)

const AnswerState = ({ children }: AnswerProps) => {
  const { applicationId, answerId } = useParams<any>()
  const { answerAttachments } = useContext(AnswerContext)
  const {
    answers,
    dispatch: applicationContextDispatch,
    application,
    fetchAnswers,
    addMoreTime
  } = useContext(ApplicationContext)

  const { user } = useContext(AuthContext)
  const [paused, setIsPaused] = useState(false)
  const [timeBadgeMessage, setTimeBadgeMessage] = useState<Message[]>([])
  const [time, setTime] = useState<number>()
  const [state, dispatch] = useReducer(reducer, initialAnswerState)
  const ReactSwal = withReactContent(swal)
  const { t } = useTranslation()
  const configuration = useApplicationConfiguration(
    application?.exam.collection.applicationConfiguration
  )
  const theme = useContext(ThemeContext)
  const { answer, item, alreadyAnswered, changeAnswer, fetchingItem } = state
  const history = useHistory()
  const { defaultAnswerLog, firebaseAnswerLog, itemAccessLog } = useContext(
    ConfigContext
  )

  const {
    hasPendingBreak,
    breakRequest,
    setHasPending,
    forcePause,
    returnFromBreak,
    updateExamStatus,
    handlePendingBreak,
    candidate,
    badgeMessages,
    reloadRequests,
    showReloadRequests
  } = useContext(CandidateMessageExchangeContext)

  const isPaused = Boolean(candidate?.pause)
  const answerItemId = answer?.item?.id?.toString()
  const answerPosition = answer?.position

  const uploadAttachedFile = useCallback(
    async (formData, file, currentAttachments, anwser, application) => {
      dispatch({ type: 'UPLOAD_ATTACHMENT_START' })
      await uploadAnswerAttachment(formData, application)
        .then((data) => {
          data.url = URL.createObjectURL(file)
          data.filename = file.name
          let attachments = currentAttachments
          attachments = [...attachments, data]
          console.log('Argument', currentAttachments)
          console.log('State', answerAttachments)
          dispatch({ type: 'UPLOAD_ATTACHMENT_SUCCESS', payload: attachments })
        })
        .catch((e) =>
          dispatch({ type: 'UPLOAD_ATTACHMENT_ERROR', payload: e.message })
        )
    },
    [answerAttachments]
  )

  const removeAttachment = useCallback(
    async (attachmentIndex: number, attachments) => {
      if (
        window.confirm(t('Are you sure you want to delete this attachment?'))
      ) {
        const newAttachments = attachments.filter((x) => {
          return x !== attachments[attachmentIndex]
        })
        dispatch({ type: 'REMOVE_ATTACHMENT', payload: newAttachments })
      }
    },
    [t]
  )

  useEffect(() => {
    if (!itemAccessLog) {
      return
    }
    if (
      !user ||
      !application ||
      !answerItemId ||
      !answerPosition ||
      !FIREBASE_ENABLED
    ) {
      return
    }
    createItemAccess(
      application?.roomId?.toString(),
      user?.id.toString(),
      application?.id.toString(),
      application?.exam.name,
      {
        itemId: answerItemId,
        position: answerPosition
      }
    ).catch((e) => console.error('errorrrrrr:', e))
  }, [
    itemAccessLog,
    user,
    application,
    answerItemId,
    answerPosition,
    FIREBASE_ENABLED
  ])

  const navigateToNewAnswer = useCallback(
    (newAnswer: IAnswer) => {
      history.push(`/applications/${applicationId}/answers/${newAnswer.id}`)
    },
    [applicationId, history]
  )

  const confirmButtonColor = theme?.colors?.secondary

  const displayBreakModal = useCallback(() => {
    if (!paused) {
      setIsPaused(true)
    }
    const countdown = (
      <h1>
        <Countdown
          date={Date.now() + 120000}
          renderer={({ ...time }) => {
            let label = ''
            label += time.minutes.toString().padStart(2, '0')
            label += ':'
            label += time.seconds.toString().padStart(2, '0')
            return <h2 style={{ color: 'white' }}>{label}</h2>
          }}
        />
      </h1>
    )
    ReactSwal.fire({
      title: `<span style="color: white">${t('Remaining time')}:</span>`,
      html: countdown,
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: t('I am back!'),
      confirmButtonColor: theme.colors.secondary,
      backdrop: 'rgb(35, 96, 122, 1)',
      background: 'rgb(35, 96, 122, 1)',
      imageUrl: theme.restroomImg,
      imageAlt: t('Remaining time'),
      timer: 120000
    }).then((result) => {
      if (result && result.value) {
        setHasPending(false)
        setIsPaused(false)
        returnFromBreak()
      }
    })
  }, [
    ReactSwal,
    paused,
    returnFromBreak,
    setHasPending,
    t,
    theme.colors.secondary,
    theme.restroomImg
  ])

  if (paused || forcePause) {
    displayBreakModal()
  }

  const hasApprovedBreakRequest = breakRequest?.approved

  useEffect(() => {
    dispatch({ type: 'UPDATE_REMAINING_ITEM_TIME_INITIAL_DATE' })
  }, [isPaused])

  // Redirects to next or previous answer
  const goAnswer = useCallback(
    async (newAnswer: IAnswer, neverWarnOnLeave?: boolean) => {
      if (reloadRequests.length > 0) {
        showReloadRequests()
        return
      }
      if (hasPendingBreak && hasApprovedBreakRequest) {
        try {
          await handlePendingBreak()
        } catch (error) {
          // If there was an error while starting the break,
          // just ignore it and try again on the next item change.
          // However, error will be reported since it should not happen.
          RollbarErrorTracking.logError(error)
        }
      }
      navigateToNewAnswer(newAnswer)
    },
    [
      hasPendingBreak,
      handlePendingBreak,
      navigateToNewAnswer,
      hasApprovedBreakRequest,
      reloadRequests.length,
      showReloadRequests
    ]
  )

  const handleFinishAnswer = async (toFinishAnswer: IAnswer): Promise<void> => {
    await db.answers
      .where('id')
      .equals(toFinishAnswer.id)
      .modify((ans) => {
        ans.finished = true
        ans._changed = 1
      })
  }

  const handleAnswerData = useCallback(async (): Promise<void> => {
    const answerData = [
      {
        id: answer.id,
        alternativeId: answer.alternative?.id,
        freeResponse: answer.freeResponse,
        gradeLinear: answer.gradeLinear,
        seconds: answer.seconds,
        finished: answer?.finished === true
      }
    ]

    const response = await axios.put(
      `${API_HOST}/v1/applications/${application?.id}/answers/batch`,
      answerData
    )

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response?.status}`)
    }
  }, [answer, application])

  const displayWarningModal = useCallback(
    (newAnswer: IAnswer) => {
      ReactSwal.fire({
        title: t('Are you sure?'),
        text: t("You won't be able to return to this question."),
        input: 'checkbox',
        inputPlaceholder: t("Don't show again"),
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: t('Proceed'),
        confirmButtonColor,
        cancelButtonText: t('Cancel'),
        reverseButtons: true,
        timer: 120000
      }).then((result) => {
        if (result.dismiss) {
          return
        }
        dispatch({ type: 'FINISHING_ANSWER' })
        if (result.value) {
          localStorage.setItem(
            'showquestionwarning',
            JSON.stringify(result.value)
          )
        }
        handleAnswerData().then(() => handleFinishAnswer(answer))
        setTimeout(() => {
          goAnswer(newAnswer)
        }, 500)
      })
    },
    [t, goAnswer, ReactSwal, confirmButtonColor, handleAnswerData, answer]
  )

  const handleQuestionExpired = async () => {
    try {
      if (answer && answer.answerTimeLimit) {
        dispatch({ type: 'SET_ITEM_ACCESS_EXPIRED', payload: true })
        await axios.patch(
          `${API_HOST}/v1/applications/${application.id}/answers/${answerId}/set_item_expired`
        )
        await db.answers
          .where('id')
          .equals(answer.id)
          .modify((ans) => {
            if (!ans.timeoutDate) {
              ans.timeoutDate = moment(new Date()).format('YYYY-MM-DD HH:mm')
              ans.finished = true
              ans._changed = 1
            }
          })
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Fetches item from api ou local db
  const fetchItem = useCallback(
    async (force = false) => {
      // Do not load item if candidate application is paused
      // or if pause status is not known yet.
      if (FIREBASE_ENABLED) {
        if (candidate === null || !!candidate?.pause) {
          return
        }
      }

      if (!answer) {
        return
      }

      if (answer.item.id === item?.id && !force) {
        return
      }

      // Looks for item in local db
      let newItem: IItem | undefined
      try {
        newItem = await db.items.where({ id: answer.item.id }).first()
      } catch (_) {
        // If unable do get item, just ignore it
      }
      // If item not in local db, fetches from api and adds to local db
      if (!newItem || force) {
        try {
          dispatch({ type: 'FETCH_ITEM' })
          if (!force) {
            dispatch({ type: 'UPDATE_REMAINING_ITEM_TIME_INITIAL_DATE' })
          }

          const url = new URL(answer.item._url)
          const response = await axios.get(`${API_HOST}${url.pathname}`)
          newItem = response.data
          if (newItem) {
            try {
              await db.items.put(newItem)
            } catch (_) {
              // If unable to add item, just ignore it
            }
          }
        } catch (e) {
          dispatch({ type: 'FETCH_ITEM_ERROR', payload: e })
          return
        }
      }

      dispatch({ type: 'FETCH_ITEM_SUCCESS', payload: newItem })
    },
    [answer, item, candidate]
  )

  const generateIncidentAutomatically = async (
    description: string,
    room: number,
    user: number
  ) => {
    try {
      await axios.post(`${API_HOST}/v1/room_event_record`, {
        description,
        room,
        user
      })
    } catch (e) {
      dispatch({ type: 'FETCH_ITEM_ERROR', payload: e })
    }
  }

  // eslint-disable-next-line
  const addAnswerLog = useCallback(
    debounce(async () => {
      if (application) {
        const answers = await db.answers
          .where({ 'application.id': application.id })
          .toArray()
        const payload = {
          answers: answers.reduce((acc, d) => {
            return {
              ...acc,
              [d.position.toString()]:
                d.alternative?.letter ||
                d.freeResponse ||
                d.gradeLinear ||
                d.alternatives?.map((a) => a.letter).join()
            }
          }, {})
        }
        return axios.post(
          `${API_HOST}/v1/applications/${application.id}/answer_logs`,
          payload
        )
      }

      return Promise.resolve()
    }, 1000),
    [application]
  )

  // eslint-disable-next-line
  const addFirebaseAnswerLog = useCallback(
    debounce((answerIn: AnswerIn) => {
      const applicationId = application?.id.toString()
      const roomId = application?.roomId?.toString()
      const candidateId = user?.id.toString()
      createAnswer(roomId, candidateId, applicationId, answerIn).catch(
        RollbarErrorTracking.logError
      )
    }, 2000),
    // Without answerId, if an user is fast enough to answer two items within
    // the debounce wait time, only the last answer is saved.
    // So having answerId in the dependencies ensures that the debounce function
    // for each answer is different.
    [application, user, answerId]
  )

  // eslint-disable-next-line
  const addFirebaseBulkAnswerLog = useCallback(
    debounce((answerIn: AnswerIn[]) => {
      const applicationId = application?.id.toString()
      const roomId = application?.roomId?.toString()
      const candidateId = user?.id.toString()
      createBulkAnswer(roomId, candidateId, applicationId, answerIn).catch(
        RollbarErrorTracking.logError
      )
    }, 2000),
    // Without answerId, if an user is fast enough to answer two items within
    // the debounce wait time, only the last answer is saved.
    // So having answerId in the dependencies ensures that the debounce function
    // for each answer is different.
    [application, user, answerId]
  )

  const bulkUpdateAnswer = async (alternative: IAlternative) => {
    let newAlternative = []

    if (!answer) {
      return
    }

    if (!answer?.alternatives) {
      Object.assign(answer, { alternatives: [] })
    }

    const isRemovingAnswer = answer?.alternatives?.find(
      (x) => x?.id === alternative?.id
    )

    if (isRemovingAnswer) {
      newAlternative = answer?.alternatives?.filter(
        (x) => x?.id !== alternative?.id
      )
    } else {
      newAlternative = [
        ...answer?.alternatives,
        {
          ...alternative,
          content: undefined
        }
      ]
    }

    const newAnswer = {
      ...answer,
      // Since content may be too big, it is not necessary to store it in answer
      alternatives: newAlternative,
      _changed: 1
    }

    const bulkAnswer = newAlternative?.map((alternative) => {
      return {
        alternativeId: alternative?.id,
        itemId: answer.item?.id?.toString(),
        letter: alternative?.letter,
        position: alternative?.position
      }
    })

    await db.answers
      .where('id')
      .equals(answer.id)
      .modify((ans) => {
        ans.alternatives = newAlternative
        ans._changed = 1
      })

    defaultAnswerLog && addAnswerLog()
    FIREBASE_ENABLED &&
      firebaseAnswerLog &&
      addFirebaseBulkAnswerLog(bulkAnswer)
    dispatch({ type: 'UPDATE_BULK_ANSWER_ALTERNATIVE', payload: newAnswer })
    applicationContextDispatch({
      type: 'UPDATE_ANSWER_IN_ANSWERS',
      payload: answer
    })
    fetchAnswers()
  }

  const updateAnswer = async (alternative: IAlternative) => {
    if (!answer) {
      return
    }

    const isRemovingAnswer = alternative.id === answer.alternative?.id
    if (isRemovingAnswer) {
      return
    }
    const newAlternative = {
      ...alternative,
      content: undefined
    }

    const newAnswer = {
      ...answer,
      // Since content may be too big, it is not necessary to store it in answer
      alternative: newAlternative,
      _changed: 1
    }

    // Using modify here so it does not overwrite the seconds
    await db.answers
      .where('id')
      .equals(answer.id)
      .modify((ans) => {
        ans.alternative = newAlternative
        ans._changed = 1
      })

    defaultAnswerLog && addAnswerLog()
    FIREBASE_ENABLED &&
      firebaseAnswerLog &&
      addFirebaseAnswerLog({
        alternativeId: alternative?.id,
        itemId: answer.item?.id?.toString(),
        letter: alternative?.letter,
        position: answer.position
      })

    dispatch({ type: 'UPDATE_ANSWER_ALTERNATIVE', payload: newAnswer })
    applicationContextDispatch({
      type: 'UPDATE_ANSWER_IN_ANSWERS',
      payload: newAnswer
    })
  }

  const updateFreeResponse = async (freeResponse: string) => {
    if (!answer) {
      return
    }

    if (item?.freeResponseMaxLength !== undefined) {
      freeResponse = freeResponse.substring(0, item.freeResponseMaxLength)
    }

    const newAnswer = {
      ...answer,
      freeResponse,
      _changed: 1
    }

    // Using modify here so it does not overwrite the seconds
    await db.answers
      .where('id')
      .equals(answer.id)
      .modify((ans) => {
        ans.freeResponse = freeResponse
        ans._changed = 1
      })

    addAnswerLog()

    dispatch({ type: 'UPDATE_FREE_RESPONSE', payload: newAnswer })
    applicationContextDispatch({
      type: 'UPDATE_ANSWER_IN_ANSWERS',
      payload: newAnswer
    })
  }

  const updateMultipleLinearScale = async (gradeLinear: string) => {
    if (!answer) {
      return
    }

    const newAnswer = {
      ...answer,
      gradeLinear,
      _changed: 1
    }

    // Using modify here so it does not overwrite the seconds
    await db.answers
      .where('id')
      .equals(answer.id)
      .modify((ans) => {
        ans.gradeLinear = gradeLinear
        ans._changed = 1
      })

    addAnswerLog()

    dispatch({ type: 'UPDATE_MULTIPLE_LINEAR_SCALE', payload: newAnswer })
    applicationContextDispatch({
      type: 'UPDATE_ANSWER_IN_ANSWERS',
      payload: newAnswer
    })
  }

  const isAnswered = (ans: IAnswer) => {
    return !!(
      ans.alternative ||
      ans.freeResponse ||
      ans.gradeLinear ||
      ans.alternatives?.length > 0
    )
  }

  const getAnswerFromPosition = useCallback(
    (position: number): IAnswer | undefined => {
      return answers?.find((d) => d.position === position) || undefined
    },
    [answers]
  )

  const getNextItem = useCallback(async (): Promise<IAnswer> => {
    const nextAnswer = await findNextAvaiableAnswer(
      +applicationId,
      configuration
    )
    return nextAnswer
  }, [configuration, applicationId])

  useEffect(() => {
    // Prevents from running this effect if the user simply
    // changed the alternative/free response
    if (answerId && +answerId === answer?.id) {
      return
    }

    // Updates current, previous and next answers on answerId change
    ;(async () => {
      let newAnswer

      if (answerId && answers) {
        newAnswer = await db.answers.where({ id: +answerId }).first()
        if (newAnswer) {
          dispatch({
            type: 'SET_ALREADY_ANSWERED',
            payload: isAnswered(newAnswer)
          })
        }
      } else {
        newAnswer = undefined
      }

      dispatch({ type: 'FETCH_ANSWER_SUCCESS', payload: newAnswer })
    })()

    // Scrolls page to top
    window.scrollTo(0, 0)
  }, [answerId, answers, answer, getAnswerFromPosition])

  useEffect(() => {
    if (!configuration?.canBrowseAcrossItems && alreadyAnswered) {
      dispatch({ type: 'SET_CHANGE_ANSWER', payload: true })
    }
  }, [alreadyAnswered, application, configuration])

  const isAnswerAllowed = useCallback(() => {
    if (configuration?.canBrowseAcrossItems) return true
    return !changeAnswer // changedAnswer
  }, [configuration, changeAnswer])

  const goToReview = useCallback(() => {
    if (application) history.push(`/applications/${application.id}/review`)
  }, [application, history])

  const goToNextAllowedItem = useCallback(
    (nextAllowedItem: IAnswer, neverWarnOnLeave: boolean) => {
      dispatch({ type: 'SET_CHANGE_ANSWER', payload: false })
      goAnswer(nextAllowedItem, neverWarnOnLeave)
    },
    [goAnswer]
  )

  const redirectForbiddenAnswer = useCallback(
    async (neverWarnOnLeave) => {
      const firstNotAnswered = await getNextItem()
      firstNotAnswered
        ? goToNextAllowedItem(firstNotAnswered, neverWarnOnLeave)
        : goToReview()
    },
    [goToNextAllowedItem, goToReview, getNextItem]
  )

  useEffect(() => {
    if (isAnswerAllowed()) return
    redirectForbiddenAnswer(true)
  }, [isAnswerAllowed, goToNextAllowedItem, redirectForbiddenAnswer])

  useEffect(() => {
    fetchItem()
  }, [fetchItem])

  useEffect(() => {
    if (!application) {
      return
    }
    const shouldLoadAnswer =
      application.status === 'STARTED' ||
      application?.exam?.collection?.showApplicationAfterTimeout

    // TODO: Feedback user
    if (!shouldLoadAnswer) {
      history.push(`/applications/${application.id}/instructions`)
    }
  }, [application, history])

  // Creates a timer that is incremented by 1 each second
  const timerRef = useRef(0)
  useEffect(() => {
    const interval = setInterval(() => {
      timerRef.current += 1
    }, 1000)
    return () => {
      timerRef.current = 0
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!answer) {
        return
      }

      try {
        // Looks for the current answer and updates the seconds field
        // using the page timer
        await db.answers
          .where('id')
          .equals(answer.id)
          .modify((ans) => {
            if (!isPaused && answer?.item?.id === item?.id && !fetchingItem) {
              const currentItemDuration = (ans.seconds || 0) + timerRef.current
              ans.seconds = currentItemDuration
            }
            ans._changed = 1
          })
        timerRef.current = 0
      } catch (_) {
        // If operation failed, do not reset the timer so
        // it tries again after the next interval
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [answer, isPaused, item, fetchingItem])

  useEffect(() => {
    ;(async () => {
      let newAnswer

      if (answerId) {
        newAnswer = await db.answers.where({ id: +answerId }).first()
        dispatch({ type: 'FETCH_ANSWER_SUCCESS', payload: newAnswer })
      }
    })()
  }, [answerId, isPaused])

  useEffect(() => {
    if (!answer || !application) {
      return
    }
    updateExamStatus({
      currentQuestion: answer.position,
      examStartDate: application.startedAt,
      finished: false
    })
  }, [answer, application, updateExamStatus])

  useEffect(() => {
    const setTimeoutMessages = []
    badgeMessages.forEach((data) => {
      if (data.motive === 'MORE_TIME') {
        setTimeoutMessages.push(data)
      }
    })

    setTimeBadgeMessage(setTimeoutMessages)
  }, [badgeMessages])

  const goToNextNotExpiredItem = async () => {
    dispatch({ type: 'SET_ITEM_ACCESS_EXPIRED', payload: false })
    handleAnswerData().then(() => redirectForbiddenAnswer(true))
  }

  useEffect(() => {
    timeBadgeMessage &&
      timeBadgeMessage.map((data) => setTime(parseInt(data.timestamp)))
  }, [badgeMessages, timeBadgeMessage])

  const getApplicationTimeout = useCallback(async (application) => {
    const response = await axios.get(
      `${API_HOST}/v1/applications/${application.id}`
    )
    return response.data.secondsToTimeout
  }, [])

  const handleNewTimeout = useCallback(
    () =>
      timeBadgeMessage?.map(async () => {
        const newTimeout = await getApplicationTimeout(
          answer && answer.application
        )
        addMoreTime(newTimeout)
      }),
    [timeBadgeMessage, getApplicationTimeout, answer, addMoreTime]
  )

  useEffect(() => {
    if (!timeBadgeMessage) {
      return
    }
    handleNewTimeout()
  }, [timeBadgeMessage, badgeMessages, handleNewTimeout])

  const contextValue = {
    ...state,
    goAnswer,
    updateAnswer,
    fetchItem,
    getAnswerFromPosition,
    updateFreeResponse,
    updateMultipleLinearScale,
    isAnswered,
    handleQuestionExpired,
    badgeMessages,
    timeBadgeMessage,
    goToNextNotExpiredItem,
    displayWarningModal,
    generateIncidentAutomatically,
    item,
    time,
    bulkUpdateAnswer,
    handleFinishAnswer,
    handleAnswerData,
    uploadAttachedFile,
    removeAttachment
  }

  return (
    <AnswerContext.Provider value={contextValue}>
      {children(contextValue)}
    </AnswerContext.Provider>
  )
}

export default AnswerState
