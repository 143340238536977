import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import {
  faFile,
  faCheckSquare,
  faCalendar
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

type AppealSubtitlesInfosProps = {
  className?: string
  appealId?: any
  item?: any
  createdAt?: any
}

const AppealSubtitlesInfos = ({
  appealId,
  item,
  createdAt,
  className
}: AppealSubtitlesInfosProps) => {
  const { t } = useTranslation()

  return (
    <>
      <span className={className}>
        <FontAwesomeIcon className="icon-space" icon={faFile} />
        {t('Recurso').toUpperCase()} {appealId}
      </span>
      <span className={className}>
        <FontAwesomeIcon className="icon-space" icon={faCheckSquare} />
        {t('Questão').toUpperCase()}: {item}
      </span>
      <span className={className}>
        <FontAwesomeIcon className="icon-space" icon={faCalendar} />
        {t('CreatedAt').toUpperCase()} {createdAt}
      </span>
    </>
  )
}

export default styled(AppealSubtitlesInfos)`
  font-size: 12px;

  .icon-space {
    margin-right: 10px;
  }
`
