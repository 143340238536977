import React, { useCallback, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { API_HOST } from 'consts'
import axios from 'axios'
import { toast } from 'react-toastify'
import { get } from 'lodash'
import Page from './Page'
import { devices } from '../utils/devices'
import { Field, Form, Formik } from 'formik'
import MaskedInput from './Inputs/MaskedInput'
import TextEditor from './AppealComponents/TextEditor'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../states/AuthState'
import UserGroupsInput from './Inputs/UserGroupsInput'
import HierarchyFilter from './HierarchyFilter'

type CreateNoticeFormProps = {
  className?: string
  initialValues?: any
  edit?: any
}
export const standardInitialValues = {
  notice_title: '',
  notice_text: '',
  teachers_input: '',
  groups: null,
  active_since_day: null,
  active_since_time: null,
  active_until_day: null,
  active_until_time: null,
  status: false
}
const CreateNoticeForm = ({
  className,
  initialValues,
  edit
}: CreateNoticeFormProps) => {
  const [teatcher, setTeatcher] = React.useState<any>({})
  const [loaded, setLoaded] = React.useState(false)
  const [convertedText, setConvertedText] = React.useState<any>('')
  const { user } = useContext(AuthContext)
  const history = useHistory()

  const onSubmit = (values: any) => {
    if (values === standardInitialValues) {
      toast.error('Por Favor preencha o formulário.')
      return
    }

    const response = {
      conteudo: convertedText,
      result: values
    }

    axios
      .post(`${API_HOST}/billboard/create_notice`, response)
      .then((res) => {
        toast.success(res.data)
        history.push('/plural-quadro-de-avisos')
      })
      .catch((err) => {
        toast.error(err.response.data)
      })
  }

  const getTeatcher = useCallback(() => {
    setLoaded(true)
    const userJson: JSON = JSON.parse(localStorage.getItem('user'))
    return setTeatcher({
      userName: get(userJson, 'name'),
      userId: get(userJson, 'id')
    })
  }, [])

  useEffect(() => {
    if (!loaded) {
      getTeatcher()
    }
  }, [getTeatcher, loaded])

  const savedTextData = () => {
    const data = get(initialValues, 'noticeText')
    if (data && convertedText) {
      return convertedText
    } else if (data) {
      return data
    } else {
      return convertedText
    }
  }

  return (
    <div className={className}>
      <Page>
        <div className="title-content">
          <h1 className="title">{edit ? 'Editar Aviso' : 'Novo Aviso'}</h1>
          <hr className="hr" />
        </div>

        <Formik
          initialValues={initialValues || standardInitialValues}
          enableReinitialize={true}
          onSubmit={(values) => onSubmit(values)}
          render={() => (
            <Form>
              <div className="input-container outline">
                <label>Título:</label>
                <Field
                  name="noticeTitle"
                  className="input-title outline"
                  placeholder="Título do aviso"
                />
                <label>Grupo:</label>
                <UserGroupsInput name="groups" />
                <div style={{ marginTop: '10px' }}>
                  <HierarchyFilter
                    providerCodename={user?.provider?.codename}
                  />
                </div>
                <label>
                  <b>Conteúdo:</b>
                </label>
                <TextEditor
                  value={savedTextData()}
                  onChange={setConvertedText}
                  name="noticeText"
                />

                <div className="container-content">
                  <label className="label-size">Nome do autor:</label>
                  <Field
                    type="text"
                    className="input-name outline"
                    value={teatcher.userName}
                    name="teachersInput"
                  />

                  <div className="date-grid-container">
                    <div className="grid-item">
                      <label>Ativo a partir de:</label>

                      <div className="date-icons">
                        <MaskedInput
                          mask="99/99/9999"
                          className="input_date"
                          name="activeSinceDay"
                        />

                        <Field
                          type="time"
                          name="activeSinceTime"
                          className="input_time"
                        />
                      </div>
                    </div>
                    <div className="grid-item" style={{ marginLeft: '2rem' }}>
                      <label>Ativo até:</label>

                      <div className="date-icons">
                        <MaskedInput
                          mask="99/99/9999"
                          name="activeUntilDay"
                          className="input_date"
                        />

                        <Field
                          type="time"
                          className="input_time"
                          name="activeUntilTime"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="checkbox-container">
                    <Field
                      type="checkbox"
                      className="check-box"
                      name="status"
                    />
                    Deseja manter este aviso ativo?
                  </div>
                </div>
                <div className="class-button">
                  <button type="submit" className="button-send">
                    {edit ? 'Salvar' : 'Enviar'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        />
      </Page>
      {/* <select className="input-name outline"
        name="teachers-input"
        onChange={( e ) => createPayload( e.target.name, e.target.value )}
      >
        {teatcher ?
          teatcher.map( ( t: any ) => {
            return (
              <option key={t.id} value={t.nome}>{t.nome}</option>
            )
          } )
          :
          <></>  // VOU DEIXAR COMENTADO PARA DESENVOLVIMENTO POSTERIOR

        }
      </select> */}
    </div>
  )
}

export default styled(CreateNoticeForm)`
  position: relative;
  max-width: 1366px;

  background: white;
  border-radius: 10px;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.25);

  .title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .input-container {
    display: flex;
    flex-direction: column;
  }
  .container-content {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

  label {
    font-size: 16px;
    margin-top: 1rem;
    font-weight: bold;
  }

  input,
  textarea {
    resize: vertical;
    border-radius: 8px;
    margin-top: 0.6rem;
    padding: 0.5rem;
    border: 3px solid #e6e6e6;
    font-size: 14px;
  }
  .title {
    font-family: 'Open Sans', sans-serif;
    color: #723df4;
    font-size: 20px;
    line-height: 27.24px;
    display: flex;
    justify-content: left;
    white-space: nowrap;
  }

  .hr {
    width: 100%;
    height: 0px;
    margin-left: 10px;
    border: 1px solid #723df4;

    background: #723df4;
  }

  .checkbox-container {
    padding: 30px 0px;
    font-size: 16px;
    font-weight: bold;
  }

  .grid-item {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  .date-grid-container {
    font-size: 12px;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .date-icons {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
  }

  .input_time {
    font-size: 16px;
    padding: 5px;
    line-height: 1.5;
    background-color: white;
    border: 3px solid ${(props) => props.theme.colors.grayLight};
    border-radius: 5px;
    resize: none;
  }

  .button-send {
    background-color: #ff7d26;
    font-size: 16px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 4px 5px rgba(58, 58, 58, 0.1);
    width: auto;
    width: 112px;
    border-radius: 30px;
    padding: 8px 0px;
    color: white;
    &:hover {
      background-color: #ce6621;
    }
  }
  .class-button {
    display: flex;
    justify-content: flex-end;
  }
  .inputPlace {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 60%;
    height: 32.5px;
  }
  .input-file {
    background: #ff6600;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
    padding: 0.5rem;
    height: auto;
    input {
      margin-top: 10px;
      border: none;
      resize: none;
      &::-webkit-file-upload-button {
        display: none;
      }
      &::file-selector-button {
        display: none;
      }
    }
    &:hover {
      cursor: pointer;
      background: #ea6f2d;
    }
  }

  @media (max-width: ${devices.mobileL}) {
    .date-grid-container {
      font-size: 12px;
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .grid-item {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 0 !important;
    }
  }
`
