import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { get } from 'lodash'
import { API_HOST, EDUCAT_UI_URL } from 'consts'
import { IDashboardPreFilterState, IBooleanPreference } from 'types'
import styled from 'styled-components'
import devices from 'utils/devices'
import DashboardPreFilterState from 'states/DashboardPrefilterState'
import Page from 'components/Page'
import Skeleton from 'react-loading-skeleton'
import CardList from 'components/CardList'
import CardListToolbar from 'components/CardListToolbar'
import ResultsFound from 'components/ResultsFound'
import DashBoardPreFilterCard from 'components/DashboardPreFilterCard'
import SearchBarWrapper from 'components/ExamDashboardComponents/SearchBarWrapper'
import TabMenu from 'components/ExamDashboardComponents/TabMenu'
import usePreference from 'hooks/usePreference'
import PaginationFooter from 'components/PaginationFooter'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Button
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { testModeAPI } from 'react-ga'
import { update } from 'lodash'

type DashboardPreFilterProps = {
  className?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
)

const DashboardPreFilter = ({ className }: DashboardPreFilterProps) => {
  const classes = useStyles()
  const [schoolStage, setSchoolStage] = useState<any>()
  const [disciplineOptions, setDisciplineOptions] = useState<any>()
  const [dict, setDict] = useState<any>({})
  const [arrayFiltrado, setArrayFiltrado] = useState<any[]>([])
  const [resultsArray, setResultsArray] = useState<any[]>([])
  const [filtroStatus, setFiltroStatus] = useState(false)
  const [activeTab, setActiveTab] = useState<number>(1)
  const [dynamicPageSize, setDynamicPageSize] = useState<number>()
  const [tipoId, setTipoId] = useState<number>(1)
  const [ordering, setOrdering] = useState<string>('-end_time,id')
  const [isOrderingMenuOpen, setIsOrderingMenuOpen] = useState(false)
  const sharedFlow = usePreference<IBooleanPreference>(
    'Shared_Data__ActivateSharedFlow'
  )
  const examCategoryFlow = usePreference<IBooleanPreference>(
    'Application__ExamCategoryFlow'
  )

  const toggleOrderingMenu = () => {
    setIsOrderingMenuOpen((prev) => !prev)
  }
  const handleOrderingChange = (newOrdering: string) => {
    setOrdering(newOrdering)
  }
  const updateState = (stateKey, value) => {
    setDict((prevState) => ({
      ...prevState,
      [stateKey]: value
    }))
  }

  const handleTabChange = (tab: number) => {
    const tipoIdMap = {
      1: 1,
      2: 2,
      3: 3
    }
    setActiveTab(Number(tab))
    setTipoId(tipoIdMap[tab])
  }

  const getDisciplineOptions = async () => {
    await axios.get<any>(`${API_HOST}/v1/discipline`).then((res) => {
      setDisciplineOptions(res.data)
    })
  }

  const getSchoolStage = async () => {
    await axios.get<any>(`${API_HOST}/school_stage`).then((res) => {
      setSchoolStage(res.data.results)
    })
  }

  useEffect(() => {
    if (!schoolStage) {
      getSchoolStage()
    }
    if (!disciplineOptions) {
      getDisciplineOptions()
    }
  }, [])

  return (
    <Page customWidth={1366}>
      <DashboardPreFilterState ordering={ordering} tipoId={tipoId}>
        {({
          isLoading,
          results,
          totalResults,
          onSearchChange,
          pageSize,
          numPages,
          handlePageChange,
          onPageSizeChange,
          onFilter
        }: IDashboardPreFilterState) => {
          setResultsArray(results)

          return (
            <div className={className}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h1>Aplicações</h1>
              </div>
              <SearchBarWrapper
                placeholder="Pesquisar..."
                onSearchChange={onSearchChange}
              />
              {examCategoryFlow && get(examCategoryFlow, 'value') ? (
                <TabMenu activeTab={activeTab} onTabChange={handleTabChange} />
              ) : (
                <></>
              )}
              <Grid container spacing={2} style={{ margin: '0 auto', justifyContent:'space-between' }}>
                <Grid item xs={12} sm={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="Status-filter-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="Status-filter-select-label"
                      id="Status-filter-select"
                      onChange={(event) => {
                        onFilter({
                          status: event.target.value,
                          areaConhecimento: dict?.areaConhecimento,
                          etapa: dict?.etapa,
                          disciplina: dict?.disciplina
                        })
                        handlePageChange(1)
                      }}
                    >
                      <MenuItem value="">
                        <em>Escolha uma opção</em>
                      </MenuItem>
                      <MenuItem value={'FINISHED'}>Finalizada</MenuItem>
                      <MenuItem value={'AVAILABLE'}>Pendente</MenuItem>
                      <MenuItem value={'UNAVAILABLE'}>Indisponível</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    gap: '20px',
                    justifyContent: 'space-between'
                  }}
                >
                  {sharedFlow && get(sharedFlow, 'value') ? (
                    <Button
                      style={{
                        height: '50px',
                        backgroundColor: '#7C47F6',
                        borderRadius: '16px'
                      }}
                      onClick={() => {
                        window.location.href = `${EDUCAT_UI_URL}/filter-relatorio-individual-turma`
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '11px',
                          fontWeight: 550,
                          lineHeight: '20px',
                          letterSpacing: '0px',
                          textAlign: 'center',
                          color: '#fff'
                        }}
                      >
                        Relatório consolidador da turma
                      </Typography>
                    </Button>
                  ) : (
                    <></>
                  )}

                  <div
                    className={`ordering-dropdown ${
                      isOrderingMenuOpen ? 'open' : ''
                    }`}
                  >
                    <div
                      className="dropdown-toggle"
                      onClick={toggleOrderingMenu}
                    >
                      <Typography>
                        Ordenar
                        <i
                          className={`icon-Arrow-tip-down ${
                            isOrderingMenuOpen ? 'open' : ''
                          }`}
                        />
                      </Typography>
                    </div>
                    <div
                      className={`dropdown-menu ${
                        isOrderingMenuOpen ? 'open' : ''
                      }`}
                    >
                      <div onClick={() => handleOrderingChange('-end_time,id')}>
                        Mais recente
                      </div>
                      <div onClick={() => handleOrderingChange('end_time,id')}>
                        Mais antigo
                      </div>
                    </div>
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="areaConheciemento-filter-select-label">Área do Conhecimento</InputLabel>
                  <Select
                    labelId="areaConheciemento-filter-select-label"
                    id="areaConheciemento-filter-select"
                    value={dict?.areaConhecimento}
                    onChange={(event) => {
                      updateState('areaConhecimento', event.target.value )
                      // setAreaConheciemento(event.target.value )
                      onFilter({ areaConhecimento: event.target.value, status: dict?.status, etapa: dict?.etapa, disciplina: dict?.disciplina  });
                    }}
                  >
                    <MenuItem value="">
                      <em>Escolha uma opção</em>
                    </MenuItem>
                    <MenuItem value={"Linguagens, Códigos e suas Tecnologias"}>Linguagens, Códigos e suas Tecnologias</MenuItem>
                    <MenuItem value={"Matemática e suas Tecnologias"}>Matemática e suas Tecnologias</MenuItem>
                    <MenuItem value={"Ciências da Natureza e suas Tecnologias"}>Ciências da Natureza e suas Tecnologias</MenuItem>
                    <MenuItem value={"Ciências Humanas e suas Tecnologias"}>Ciências Humanas e suas Tecnologias</MenuItem>
                    <MenuItem value={"Ensino Religioso"}>Ensino Religioso</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
                {/* <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="etapa-filter-select-label">Etapa</InputLabel>
                  <Select
                    labelId="etapa-filter-select-label"
                    id="etapa-filter-select"
                    value={dict?.etapa}
                    onChange={(event) => {
                      updateState("etapa", event.target.value)
                      onFilter({ areaConhecimento: dict?.areaConhecimento, status: dict?.status, etapa: event.target.value, disciplina: dict?.disciplina  });

                    }}
                  >
                    <MenuItem value="">
                      <em>Escolha uma opção</em>
                    </MenuItem>
                    {schoolStage?.map((stage: any) => (
                      <MenuItem value={stage?.id}>{stage?.etapa}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
                {/* <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="disciplna-filter-select-label">Disciplina</InputLabel>
                  <Select
                    labelId="disciplna-filter-select-label"
                    id="disciplna-filter-select"

                    onChange={(event) => {
                      updateState("disciplina",event.target.value)
                      onFilter({ areaConhecimento: dict?.areaConhecimento, status: dict?.status, etapa: dict?.etapa , disciplina: event.target.value });
                    }}
                  >
                    <MenuItem value="">
                      <em>Escolha uma opção</em>
                    </MenuItem>
                    {disciplineOptions?.map((disci: any) => (
                      <MenuItem value={disci?.name}>{disci?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              </Grid>
              <div className="parent-wrapper">
                <div className="card-list-wrapper">
                  <CardList>
                    {isLoading && <Skeleton height={150} count={5} />}
                    <CardListToolbar>
                      {totalResults !== undefined && (
                        <ResultsFound resultsFound={totalResults} />
                      )}
                    </CardListToolbar>
                    {!isLoading &&
                      resultsArray
                        .filter((collectionPreFilter) => {
                          const shouldInclude =
                            activeTab === collectionPreFilter.categoria
                          return shouldInclude
                        })
                        .map((filteredPreFilter) => (
                          <DashBoardPreFilterCard
                            key={filteredPreFilter.id}
                            dashboardPreFilter={filteredPreFilter}
                          />
                        ))}
                  </CardList>

                  <PaginationFooter
                    pageSize={
                      dynamicPageSize !== null ? dynamicPageSize : pageSize
                    }
                    pageCount={numPages}
                    onPageChange={handlePageChange}
                    onPageSizeChange={onPageSizeChange}
                  >
                    <p style={{ paddingBottom: '15px' }}>
                      Mostrando {resultsArray.length} de {totalResults}{' '}
                      registros.
                    </p>
                  </PaginationFooter>
                </div>
              </div>
            </div>
          )
        }}
      </DashboardPreFilterState>
    </Page>
  )
}

export default styled(DashboardPreFilter)`
  .parent-wrapper {
    display: flex;
  }
  @media (max-width: ${devices.mobileL}) {
    .parent-wrapper {
      flex-direction: column;
      row-gap: 20px;
      column-gap: 0;
    }
  }

  .filter-wrapper {
    max-width: 400px;

    @media (min-width: ${devices.tablet}) {
      min-width: 400px;
      column-gap: 20px;
    }
  }

  .card-list-wrapper {
    flex-grow: 6;
  }
  .ordering-dropdown {
    position: relative;
    height: 40px;
    padding: 8px;
    background: #e4e4e4;
    border-radius: 70px;
    border: none;
    outline: none;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    .dropdown-toggle {
      cursor: pointer;
      user-select: none;
    }
    .icon-Arrow-tip-down {
      margin-left: 5px;
      color: #999999;
      font-size: 20px;
    }

    .dropdown-menu {
      display: none;
      position: absolute;
      width: 159px;
      height: 84px;
      border-radius: 8px;
      padding: 4px;
      gap: 4px;
      top: 100%;
      left: 0;
      background-color: #fff;

      &.open {
        display: block;
      }

      div {
        width: 151px;
        height: 36px;
        padding: 0 12px;
        gap: 8px;
        cursor: pointer;

        &:hover {
          background-color: #ccc;
        }
      }
    }

    &.open {
      .icon-Arrow-tip-down {
        transform: rotate(180deg);
      }
    }
  }
`
