import React, { useState } from 'react'
import styled from 'styled-components'
import { ICollection } from 'types'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import { collectionIsFinished } from 'data/utils/collections'
import { AnyARecord } from 'dns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { API_HOST } from '../../consts'

type ExamDashboardExamInfoProps = {
  className?: string
  collection: ICollection
  hasGroup: any
  setIsAddStudentModalOpen: any
}

const ExamDashboardExamInfo = ({
  className,
  collection,
  hasGroup,
  setIsAddStudentModalOpen
}: ExamDashboardExamInfoProps) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <span className="exam-dashboard-title">
        <span>{collection && collection.name}</span>
      </span>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="lower-row">
          <div className="action-buttons">
            {!collectionIsFinished(collection) &&
              (hasGroup('ADMINISTRADOR') ||
                hasGroup('Professor') ||
                hasGroup('COORDINATOR') ||
                hasGroup('Diretor')) && (
                <div data-testid="lower-row-trigger">
                  <Button
                    style={{
                      marginRight: '10px',
                      borderRadius: '70px',
                      boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.25)'
                    }}
                    action="primary"
                    onClick={() => setIsAddStudentModalOpen(true)}
                  >
                    <FontAwesomeIcon
                      icon="plus"
                      style={{ marginRight: '1rem' }}
                    />{' '}
                    <span>{t('Add Student')}</span>
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default styled(ExamDashboardExamInfo)`
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .exam-dashboard-title {
    color: #333333;
    font-size: 24px;
    font-weingth: 700;
  }

  .exam-dashboard-title > span {
    font-weight: bolder;
  }
`
