import { FIREBASE_ENABLED } from 'consts'
import { uploadSnapshot } from 'data/apis/camera-snapshot'
import { IUser } from 'types'

class SnapshotUploader {
  private isUploading: boolean
  private videoElement: HTMLVideoElement
  private user: IUser
  private intervalId = -1

  private createVideoElement(stream: MediaStream, onPlay: () => void) {
    this.videoElement = document.createElement('video')
    this.videoElement.autoplay = true
    this.videoElement.srcObject = stream
    this.videoElement.width = 320
    this.videoElement.height = 180
    this.videoElement.addEventListener('play', () => {
      this.videoElement.muted = true
      this.videoElement.volume = 0
      onPlay()
    })
  }

  private captureFrame() {
    const canvas = document.createElement('canvas')
    canvas.width = this.videoElement.videoWidth
    canvas.height = this.videoElement.videoHeight
    canvas.getContext('2d').drawImage(this.videoElement, 0, 0)
    const data = canvas.toDataURL('image/png')
    if (FIREBASE_ENABLED) {
      uploadSnapshot(`${this.user.id}`, data)
    }
  }

  public startUploadingSnapshots(stream: MediaStream, user: IUser) {
    if (this.isUploading || !user) {
      return
    }

    this.user = user
    this.isUploading = true
    this.createVideoElement(stream, () => {
      setInterval(() => this.captureFrame(), 2000)
    })
  }
}

const snapshotUploader = new SnapshotUploader()
export default snapshotUploader
