import React from 'react'
import { useTranslation } from 'react-i18next'
import HistoryItem, { HistoryItemProps } from './HistoryItem'

interface CandidateHistoryItemProps extends HistoryItemProps {
  userName: string
}

const CandidateHistoryItem: React.FC<Partial<CandidateHistoryItemProps>> = ({
  userName,
  item
}) => {
  const { t } = useTranslation()
  return (
    <HistoryItem
      author={`${t('Sent by')} ${userName}`}
      direction="left"
      item={item}
    />
  )
}

export default CandidateHistoryItem
