import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { IApplication } from 'types'

type SeeInstructionLinkProps = {
  application?: IApplication
  className?: string
}

const SeeInstructionLink = ({
  application,
  className
}: SeeInstructionLinkProps) => {
  const { t } = useTranslation()

  if (!application) {
    return <span></span>
  }

  return (
    <Link
      className={className}
      to={application.instructionsUrl}
      onClick={(e) => e.stopPropagation()}
    >
      {t('SEE INSTRUCTIONS')}
    </Link>
  )
}

export default styled(SeeInstructionLink)`
  font-weight: bold;
  color: ${(props) => props.theme.applicationStatus.AVAILABLE};
`
