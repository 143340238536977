import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import DraggableModal from './DraggableModal'
import Axios from 'axios'
import { API_HOST } from 'consts'
interface SymbolTableProps {
  isSymbolTableOpen: boolean
  setIsSymbolTableOpen: (value: boolean) => void
  collection: number
  className?: string
}
const SymbolTable: React.FC<SymbolTableProps> = ({
  isSymbolTableOpen,
  setIsSymbolTableOpen,
  collection,
  className
}) => {
  const [symbols, setSymbols] = useState([])
  const [filterText, setFilterText] = useState(undefined)

  const filterTextCallback = useCallback(
    (symbol) => {
      if (!filterText) return true

      return (
        symbol?.key?.toLowerCase().includes(filterText) ||
        symbol?.value?.toLowerCase().includes(filterText) ||
        symbol?.tag?.toLowerCase().includes(filterText)
      )
    },

    [filterText]
  )

  const fetchSymbols = useCallback(() => {
    if (!collection) return
    Axios.get(`${API_HOST}/v1/symbol_table`, {
      params: { collection: collection, ordering: 'key' }
    }).then((response) => {
      setSymbols(response.data)
    })
  }, [collection])

  const sortSymbols = (
    a: { key: string; value: string },
    b: { key: string; value: string }
  ) =>
    a.key.replace(/(<([^>]+)>)/gi, '').toLowerCase() >
    b.key.replace(/(<([^>]+)>)/gi, '').toLowerCase()
      ? 1
      : -1

  useEffect(() => {
    fetchSymbols()
  }, [fetchSymbols])

  return (
    <div className={className}>
      <DraggableModal
        isOpen={isSymbolTableOpen}
        onAction={() => setIsSymbolTableOpen(false)}
        defaultPosition={{ x: 0, y: 180 }}
        className={className}
      >
        <input
          type="text"
          id="myInput"
          onChange={(e) => setFilterText(e?.target?.value?.toLowerCase())}
          value={filterText}
          placeholder="Buscar..."
        />
        <section>
          <table>
            <thead>
              {symbols?.map((row) => (
                <tr key={row.valueName}>
                  <th> {row.keyName} </th>
                  <th> {row.valueName} </th>
                </tr>
              ))}
            </thead>
            {symbols[0]?.symbols
              .sort(sortSymbols)
              .filter(filterTextCallback)
              .map((row: { key: string; value: string }) => (
                <tr key={row.value}>
                  <td>
                    <div dangerouslySetInnerHTML={{ __html: row.key }}></div>
                  </td>
                  <td>
                    <div dangerouslySetInnerHTML={{ __html: row.value }}></div>
                  </td>
                </tr>
              ))}
          </table>
        </section>
      </DraggableModal>
    </div>
  )
}
export default styled(SymbolTable)`
  section {
    overflow-y: scroll;
    max-height: 500px;
    ::-webkit-scrollbar {
      height: 6px;
      width: 8px;
      border-radius: 16px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.colors.grayDark};
      border-radius: 8px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 50%;
    }
  }

  input {
    font-size: 1.2rem;
    width: 98%;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
    &:focus {
      outline: none;
    }
  }
  table {
    border: 1px solid #eee;
    border-collapse: collapse;
    background-color: white;
    width: 400px;
    &:hover {
      cursor: default;
    }
  }
  th {
    border: 0.1px solid #eee;
    border-collapse: collapse;
    text-align: left;
    padding: 5px 10px;
    background-color: ${(props) => props.theme.clientColors.primary};
    color: white;
  }
  td {
    border: 1px solid #eee;
    border-collapse: collapse;
    text-align: left;
    padding: 5px 10px;
    color: #444;
  }
  tr:nth-child(odd) {
    background: #ddd;
  }
`
