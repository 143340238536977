import useQuery from 'hooks/useQuery'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  ExamStatus,
  IDashboardVideosSocketState,
  childrenIsFunction,
  ICollection
} from '../types'
import { AuthContext } from './AuthState'
import RoomMessageExchangeContext from 'contexts/RoomMessageExchangeContext'
import useCandidates from 'hooks/firebase/useCandidates'
import useNotSeenRoomMessages from 'hooks/firebase/useNotSeenRoomMessages'
import useNotAnsweredBreakRequests from 'hooks/firebase/useNotAnsweredBreakRequests'
import {
  pauseApplication,
  resumeApplication,
  createMessage,
  answerBreakRequest,
  answerNursingBreakRequest,
  dismissMessage
} from 'data/apis/message-exchange'
import { AlertIn, Motive } from 'data/domain/message-exchange'
import useOnlineCandidates from 'hooks/firebase/useOnlineCandidates'
import { useTranslation } from 'react-i18next'
import Axios from 'axios'
import { API_HOST } from 'consts'
import useFeedback from 'hooks/useFeedback'
import { errors } from 'resources'

type RoomMessageExchangeStateProps = {
  children: any
  roomId: string
}

const RoomMessageExchangeState = ({
  children,
  roomId
}: RoomMessageExchangeStateProps) => {
  const { user, perms } = useContext(AuthContext)
  const { candidates } = useCandidates(roomId)
  const { t } = useTranslation()
  // Only load messages if user has permission to answer them
  const shouldAnswerMessages = perms?.Dashboard?.Videos?.AnswerMessages
  const { messages } = useNotSeenRoomMessages(shouldAnswerMessages && roomId)

  // Only load break requests if user has permission to answer them
  const shouldAnswerBreakRequests = perms?.Dashboard?.Videos?.AnswerBreakRequest
  const { breakRequests } = useNotAnsweredBreakRequests(
    shouldAnswerBreakRequests && roomId
  )

  const { handleErrorFeedback } = useFeedback()
  const [messagesList, setMessagesList] = useState([])
  const [collection, setCollection] = useState<ICollection>()
  // Get online candidates
  const { onlineCandidateIds } = useOnlineCandidates(roomId)

  const candidatesMessageStatus = {}
  messages.forEach((alert) => {
    candidatesMessageStatus[alert.candidateId] = alert
  })

  const candidatesPausedStatus = {}
  candidates
    .filter((candidate) => !!candidate.pause)
    .forEach((candidate) => {
      candidatesPausedStatus[candidate.id] = 'PAUSED'
    })

  const candidatesBreakInfo = {}
  breakRequests.forEach((br) => {
    candidatesBreakInfo[br.candidateId] = {
      status: 'PENDING',
      motive: br.motive
    }
  })

  const candidatesConnectionStatus = { ...onlineCandidateIds }
  const online = Object.keys(candidatesConnectionStatus).length

  const pauseCandidateExam = useCallback(
    (candidateId: number, reason?: string, showMessage?: boolean) => {
      return pauseApplication(roomId, candidateId.toString(), {
        message: reason,
        motive: 'PAUSE',
        dismissible: false,
        showMessage
      })
    },
    [roomId]
  )

  const resumeCandidateExam = useCallback(
    (candidateId: number) => {
      return resumeApplication(roomId, candidateId.toString(), {
        motive: 'PAUSE'
      })
    },
    [roomId]
  )

  const sendAlert = useCallback(
    (candidateId: number, content: string) => {
      return createMessage(roomId, candidateId.toString(), {
        authorId: user?.id.toString(),
        dismissible: false,
        message: content,
        notificationType: 'ALERT',
        author: 'PROCTOR'
      })
    },
    [roomId, user]
  )

  const setActivityAsRead = () => {
    return Promise.resolve()
  }

  const sendReloadRequest = (
    candidateId: number,
    reason: string
  ): Promise<any> => {
    return createMessage(roomId, `${candidateId}`, {
      author: 'PROCTOR',
      authorId: `${user.id}`,
      dismissible: false,
      notificationType: 'ALERT',
      message: t(
        'The applicator has requested for your window to be reloaded. This is a mandatory action and will not affect your answers. After reloading, you will need to accept the camera permission request again. Click ok to continue.'
      ),
      reason: reason,
      motive: 'RELOAD_REQUEST'
    })
  }

  const getExamStatus = (): Promise<ExamStatus> => {
    return new Promise((resolve) => {
      resolve({})
    })
  }

  const sendBreakResponse = async (
    breakRequestId: string,
    approved: boolean,
    candidateId: string
  ) => {
    answerBreakRequest(
      roomId,
      breakRequestId,
      approved,
      60 * 5, // TODO: Get from config
      candidateId
    )
  }

  const sendNursingBreakResponse = async (
    breakRequestId: string,
    approved: boolean,
    candidateId: string
  ) => {
    answerNursingBreakRequest(roomId, breakRequestId, approved, candidateId)
  }

  const sendNotificationToCandidate = (
    candidateId: number,
    motive: Motive,
    type: AlertIn['notificationType'] = 'TOAST',
    text?: string
  ) => {
    return createMessage(
      roomId,
      `${candidateId}`,
      {
        author: 'PROCTOR',
        authorId: `${user.id}`,
        dismissible: true,
        notificationType: type,
        message: motive,
        text: text || ''
      },
      false
    )
  }

  const fetchMessages = useCallback(async () => {
    try {
      const result = await Axios.get(`${API_HOST}/v1/dashboard_messages`)
      let data = result.data as Array<any>
      data = data.sort((a, b) => b.position - a.position)
      setMessagesList(data.map((message) => message.content))
    } catch (e) {
      handleErrorFeedback(e, t(errors.DEFAULT))
    }
  }, [handleErrorFeedback, t])

  useEffect(() => {
    fetchMessages()
  }, [fetchMessages])

  const sendNotificationToEveryone = async (content: string) => {
    const onlinePeople = candidates.filter(
      (candidate) => candidatesConnectionStatus[candidate.id]
    )
    for (const person of onlinePeople) {
      await sendAlert(+person.id, content)
    }
  }

  const joinRoomAsApplicator = () => {}

  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  const fetchCollection = useCallback(
    async (collectionId) => {
      try {
        const result = await Axios.get(
          `${API_HOST}/v1/collections/${collectionId}`
        )
        setCollection(result.data)
      } catch (e) {
        handleErrorFeedback(e, t(errors.DEFAULT))
      }
    },
    [handleErrorFeedback, t]
  )

  useEffect(() => {
    fetchCollection(collectionId)
  }, [fetchCollection, collectionId])

  const contextValue: IDashboardVideosSocketState = {
    socket: null,
    pauseCandidateExam,
    resumeCandidateExam,
    sendAlert,
    sendBreakResponse,
    sendNursingBreakResponse,
    setActivityAsRead,
    sendNotificationToCandidate,
    setMessageAsRead: (messageId: string) => dismissMessage(roomId, messageId),
    getExamStatus,
    sendReloadRequest,
    joinRoomAsApplicator,
    candidatesMessageStatus,
    candidatesPausedStatus,
    candidatesBreakInfo,
    candidatesConnectionStatus,
    candidatesFinishedStatus: {},
    candidatesActivityStatus: {},
    candidatesPermissionStatus: {},
    messages,
    messagesList,
    collection,
    breakRequests,
    candidates,
    finishedAmount: 0,
    onlineAmount: online,
    sendMessageToAllOnline: sendNotificationToEveryone
  }

  return (
    <RoomMessageExchangeContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </RoomMessageExchangeContext.Provider>
  )
}

export default RoomMessageExchangeState
