import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Drawer, FormControlLabel } from '@material-ui/core'
import Axios from 'axios'
import Button from 'components/Button'
import { API_HOST } from 'consts'
import useFeedback from 'hooks/useFeedback'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errors } from 'resources'
import { AuthContext } from 'states/AuthState'
import RoomMessageExchangeContext from '../../contexts/RoomMessageExchangeContext'
import styled from 'styled-components'
import { lowerCaseStringAndRemoveAccent } from 'utils/string'

type MessageToCandidateProps = {
  className?: string
  onClose: () => void
  onMessageChosen: (content: string, sendToAll?: boolean) => Promise<void | any>
  candidateName: string
  isOpen: boolean
}

const MessageToCandidate = ({
  className,
  candidateName,
  onMessageChosen: onMessageChoosen,
  onClose,
  isOpen
}: MessageToCandidateProps) => {
  const { t } = useTranslation()
  const { user, perms } = useContext(AuthContext)
  const { messagesList: messages } = useContext(RoomMessageExchangeContext)
  const { handleErrorFeedback } = useFeedback()
  const [saveToFavourites, setSaveToFavourites] = useState(false)
  const [sendToAll, setSendToAll] = useState(false)
  const [message, setMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')

  const handleMessageType = (value: string) => {
    setInputValue(value)
    setMessage(value)
  }

  const handleClose = useCallback(() => {
    onClose()
    setMessage('')
    setSaveToFavourites(false)
    setSendToAll(false)
  }, [onClose])

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)

    if (saveToFavourites) {
      try {
        await Axios.post(`${API_HOST}/v1/dashboard_messages`, {
          user: user.id,
          content: message
        })
        messages.push(message)
      } catch (e) {
        handleErrorFeedback(e, t(errors.ERROR_ON_MESSAGE_SEND))
        setSubmitting(false)
        return
      }
    }

    // Close drawer only if request is successful.
    // In this case, we don't want an optimistic update
    // since the user may want to try sending the message again.
    onMessageChoosen(message, sendToAll)
      .then(handleClose)
      .finally(() => setSubmitting(false))
  }, [
    message,
    handleClose,
    onMessageChoosen,
    saveToFavourites,
    sendToAll,
    handleErrorFeedback,
    user,
    t,
    messages
  ])

  const filterMessages = useCallback(
    (message) => {
      return lowerCaseStringAndRemoveAccent(message).includes(
        lowerCaseStringAndRemoveAccent(inputValue)
      )
    },
    [inputValue]
  )

  useEffect(() => {
    setInputValue('')
  }, [isOpen])

  return (
    <Drawer
      data-testid="send-message-to-candidate-component"
      open={isOpen}
      anchor="right"
      onBackdropClick={handleClose}
    >
      <div className={className}>
        <div className="top-container">
          <div className="header">
            <p>{candidateName}</p>
            <p onClick={handleClose} className="close-button">
              <FontAwesomeIcon icon="times" />
            </p>
          </div>
          <div className="help-text">
            <i>
              Escreva uma nova mensagem ou selecione uma dos modelos abaixo.
            </i>
          </div>
        </div>
        <div className="middle-container">
          <div className="fixed-message-container">
            {messages.filter(filterMessages).map((message, i) => {
              const translatedMessage = t(message)
              return (
                <div
                  key={i}
                  onClick={() => setMessage(translatedMessage)}
                  className="fixed-message"
                >
                  {translatedMessage}
                </div>
              )
            })}
          </div>
        </div>
        <div className="bottom-container">
          <div className="textarea-container">
            <textarea
              autoFocus
              data-testid="message-text"
              rows={3}
              value={message}
              onChange={(event) => handleMessageType(event.target.value)}
              placeholder="Escrever mensagem..."
            />
          </div>
          <div className="button-container">
            <div className="checkbox-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    value={saveToFavourites}
                    checked={saveToFavourites}
                    onChange={(event) =>
                      setSaveToFavourites(event.target.checked)
                    }
                  ></Checkbox>
                }
                label={'Salvar mensagem'}
              ></FormControlLabel>
              {perms?.Dashboard?.Videos?.SendMessageGlobal && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendToAll}
                      value={sendToAll}
                      onChange={(event) => setSendToAll(event.target.checked)}
                    ></Checkbox>
                  }
                  label={'Enviar para todos online'}
                ></FormControlLabel>
              )}
            </div>
            <Button
              className="send-button"
              action="secondary"
              disabled={!message}
              isLoading={submitting}
              onClick={handleSubmit}
            >
              {t('Send')}
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default styled(MessageToCandidate)`
  padding: 5px 10px;
  max-width: 400px;

  .top-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 75px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    > p {
      margin: 0;
    }
  }

  .help-text {
    font-size: 12px;
    font-weight: 600;
    margin: 10px 0;
    color: grey;
  }

  .close-button {
    color: #5a5a5a;
    font-weight: bold;
    cursor: pointer;
  }

  .middle-container {
    height: calc(100vh - 265px);
    overflow-y: scroll;

    ::-webkit-scrollbar {
      display: unset;
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.grayMedium};
      border-radius: 4px;
    }
    ::-webkit-scrollbar-track {
      background: white;
    }
  }

  .fixed-message-container {
    display: flex;
    display: grid;
    margin: 3px 0 3px 0;
  }

  .fixed-message {
    border: 1px solid #23607a;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 5px;
  }

  .fixed-message:hover {
    background-color: #eee;
    cursor: pointer;
  }

  .bottom-container {
    height: 170px;
  }

  .textarea-container {
    display: flex;
    justify-content: center;
  }

  textarea {
    align-self: flex-end;
    width: 100%;
    resize: none;
    border: 1px solid #c9c9c9;
    font-size: 14px;
    border-radius: 4px;
    font-family: Open Sans, sans-serif;
    padding: 5px;
  }

  .button-container {
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .checkbox-wrapper {
    display: grid;
  }
`
