import styled from 'styled-components'

export default styled.span`
  padding: 5px;
  background-color: ${(props) => props.theme.colors.grayLight};
  color: ${(props) => props.theme.clientColors.primary};
  font-size: 15pt;
  border-radius: 50%;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
`
