import React, { useEffect, ReactNode } from 'react'
import styled from 'styled-components'
import { Title, Content } from '.'
import Button from 'components/Button'
import devices from 'utils/devices'
import classnames from 'classnames'
import Countdown from 'react-countdown'

type BlockingAlertProps = {
  className?: string
  img?: string
  title?: ReactNode
  content?: ReactNode
  dismissible?: boolean
  visible?: boolean
  onDismiss?: () => any
  dismissText?: string
  maxDismissDate?: string | number | null
}

const BlockingAlert = ({
  visible,
  img,
  title,
  content,
  dismissible,
  className,
  onDismiss,
  dismissText = 'OK',
  maxDismissDate
}: BlockingAlertProps) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [visible])

  return (
    <div className={classnames(className, { visible })}>
      <div>
        {img && <img src={img} alt="Aviso" />}
        <Title>{title}</Title>
        <Content>{content}</Content>
        {maxDismissDate && (
          <Countdown
            date={maxDismissDate}
            renderer={({ ...time }) => {
              let label = ''
              label += time.minutes.toString().padStart(2, '0')
              label += ':'
              label += time.seconds.toString().padStart(2, '0')
              return <h2 style={{ color: 'white' }}>{label}</h2>
            }}
          />
        )}
        {dismissible && (
          <Button action="secondary" onClick={onDismiss}>
            {dismissText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default styled(BlockingAlert)`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 103;
  left: 0;
  top: 0;
  background-color: rgb(35, 96, 122, 1);
  overflow-x: hidden;
  overflow-y: hidden;
  opacity: 0;
  display: none;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 0.5s ease;

  &.visible {
    display: block;
    opacity: 1;
  }

  > div {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    > * {
      margin-bottom: 2rem;
    }

    img {
      zoom: 0.7;
    }

    @media (max-width: ${devices.mobileL}) {
      img {
        width: 80%;
      }
    }
  }
`
