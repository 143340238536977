import { Tooltip } from '@material-ui/core'
import axios from 'axios'
import { formatDateTimeString } from '../utils/date'
import ExamDashboardContext from 'contexts/ExamDashboardContext'
import { getStatusLabel } from 'data/utils/applications'
import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Button from '../components/Button'
import { ANALYSIS_API_HOST, API_HOST } from '../consts'
import { ModalContext } from '../states/ModalState'
import {
  IExam,
  IExamDashboardApplication,
  IInstitution,
  IInstitutionData
} from '../types'
import SetExamDateModal from './ExamDashboardComponents/SetExamDateModal'
import Modal from './Modal/Modal'
import SetRoomModal from './ExamDashboardComponents/SetRoomModal'
import swal from 'sweetalert'
import useFeedback from 'hooks/useFeedback'
import { errors } from 'resources'
import { collectionIsFinished } from '../data/utils/collections'
import ExamInformationCard from './ExamInformationCard'
import SetTimeoutModal from './ExamDashboardComponents/SetTimeoutModal'
import { createMessage } from 'data/apis/message-exchange'
import { sumTimeout } from 'utils/time'
import CheckApplicationTimeWindowModal from './CheckApplicationTimeWindowModal'
import { AuthContext } from 'states/AuthState'
import debounce from 'debounce-promise'
import ProgressHighchart from './ProgressHighchart'

type ExamDashbhoardApplicationCardProps = {
  application: IExamDashboardApplication
  exam: IExam | undefined
}

const ExamDashbhoardApplicationCard = ({
  application
}: ExamDashbhoardApplicationCardProps) => {
  const { t } = useTranslation()
  const [institutionsValues, setInstitutionsValues] = useState<IInstitution[]>(
    []
  )
  const [alreadyReset, setAlreadyReset] = useState(false)
  const [screenshoturl, setScreenshoturl] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const { showModal, hideModal } = useContext(ModalContext)
  const { perms } = useContext(AuthContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { fetchApplications } = useContext(ExamDashboardContext)
  const { handleErrorFeedback } = useFeedback()

  const examCanBeReset = () => {
    if (alreadyReset) {
      return false
    }
    const resetableExamStatuses = ['STARTED', 'FINISHED']
    return resetableExamStatuses.includes(application.status)
  }

  const examCanHaveDateChanged = () => {
    const changeableStatuses = ['AVAILABLE', 'UNAVAILABLE']
    return changeableStatuses.includes(application.status)
  }

  const handleResetApplication = async (clearAnswers) => {
    setIsModalOpen(false)
    try {
      await axios.post(`${API_HOST}/v1/applications/${application.id}/reset`, {
        clearAnswers
      })
      toast.success(
        clearAnswers
          ? t('Exam was reset successfully')
          : t('Exam time was reset successfully')
      )
      setAlreadyReset(true)
    } catch {
      toast.error(t('An error occurred. Please try again.'))
    }
  }

  const handleSetExamDateSubmit = (values: any): Promise<any> => {
    return axios
      .post(
        `${API_HOST}/v1/applications/${application.id}/set_application_dates`,
        values
      )
      .then(() => {
        fetchApplications(false)
      })
  }

  const handleSecondChanceExam = () => {
    return axios
      .post(`${API_HOST}/v1/applications/${application.id}/give_another_chance`)
      .then(() => {
        fetchApplications(false)
        toast.success('Segunda tentativa vinculada ao Estudante com sucesso!')
      })
      .catch((err) => {
        toast.error(
          'Ocorreu um erro ao vincular uma segunda tentativa a este Estudante. Tente novamente mais tarde.'
        )
        console.error(err)
      })
  }

  const handleRoomChangeSubmit = async (values: any) => {
    const roomId = values.room.id
    try {
      const result = await axios.patch(
        `${API_HOST}/v1/applications/${application.id}`,
        { room_id: roomId }
      )
      if (result.status === 200) {
        swal({
          icon: 'success',
          text: 'Sala alterada com sucesso'
        })
        fetchApplications(false)
      }
    } catch (e) {
      handleErrorFeedback(e, t(errors.ERROR_SAVING_TRY_AGAIN))
    } finally {
      hideModal()
    }
  }

  const updatingTimeoutToMoreThanOneApplication = async (
    minutes: number | null,
    newTimeout: string
  ) => {
    try {
      await axios.put(
        `${API_HOST}/v1/applications/exam/${application.exam.collection.id}/collection/${application.user.id}/user/set_timewindow`,
        {
          timeout: minutes,
          end_time: newTimeout
        }
      )

      if (application.timeout) {
        const response = await axios.put(
          `${API_HOST}/v1/applications/exam/${application.exam.collection.id}/collection/${application.user.id}/user`,
          {
            timeout: minutes,
            end_time: newTimeout
          }
        )

        if (response.status === 200) {
          application.timeout = newTimeout

          await createMessage(
            application.roomId.toString(),
            application.user.id.toString(),
            {
              message: 'MORE_TIME',
              text: minutes
                ? `Você ganhou mais ${minutes} minutos para fazer a atividade`
                : `A data de encerramento do exame alterou para ${newTimeout}`,
              authorId: application.user.id.toString(),
              notificationType: 'BADGE',
              author: 'PROCTOR',
              motive: 'MORE_TIME',
              time: minutes
            }
          )
        }
      }
      swal({
        icon: 'success',
        title: t('Timeout was changed with successfully')
      })
      hideModal()
    } catch {
      swal({
        icon: 'error',
        title: t('It was not possible to change timeout')
      })
    }
  }

  const updatingTimeoutToOneApplication = async (
    minutes: number | null,
    newTimeout: string
  ) => {
    try {
      const patchEndTime = await axios.patch(
        `${API_HOST}/v1/applications/${application.id}/set_endtime`,
        {
          end_time: minutes,
          timeout: newTimeout
        }
      )

      if (patchEndTime.status === 200) {
        application.timeWindows[0].endTime = newTimeout
      }

      if (application.timeout) {
        const response = await axios.patch(
          `${API_HOST}/v1/applications/${application.id}/set_timeout`,
          {
            timeout: minutes,
            end_time: newTimeout
          }
        )

        if (response.status === 200) {
          application.timeout = newTimeout
          await createMessage(
            application.roomId.toString(),
            application.user.id.toString(),
            {
              message: minutes
                ? `O candidato ganhou mais ${minutes} minutos para fazer a atividade`
                : `A data de encerramento do exame alterou para ${newTimeout}`,
              text: minutes
                ? `Você ganhou mais ${minutes} minutos para fazer a atividade`
                : `A data de encerramento do exame alterou para ${newTimeout}`,
              authorId: application.user.id.toString(),
              notificationType: 'BADGE',
              author: 'PROCTOR',
              motive: 'MORE_TIME',
              time: minutes
            }
          )
        }
      }

      swal({
        icon: 'success',
        title: t('Timeout was changed with successfully')
      })
      hideModal()
    } catch {
      swal({
        icon: 'error',
        title: t('It was not possible to change timeout')
      })
    }
  }

  const handleSetTimeoutSubmit = debounce(async (values: any) => {
    const data =
      values.newEndDate && values.newEndDate.split('/').reverse().join('-')
    const horario = values.newEndTime && values.newEndTime + ':00'

    const newTimeout =
      data && horario
        ? data + ' ' + horario
        : sumTimeout(
            application.timeout
              ? application.timeout
              : application.timeWindows[0].endTime,
            values.newTime
          )

    if (values.applyToCollection) {
      updatingTimeoutToMoreThanOneApplication(
        parseInt(values.newTime),
        newTimeout
      )
    } else {
      updatingTimeoutToOneApplication(
        !data ? parseInt(values.newTime) : null,
        newTimeout
      )
    }
  }, 2500)

  const canOpenSetTimeoutModal = async () => {
    const response = await axios.get(
      `${API_HOST}/v1/applications/${application?.id}/application_timewindow`
    )

    if (response.data.length > 0) {
      return showModal(() => (
        <SetTimeoutModal
          onSubmit={handleSetTimeoutSubmit}
          timeout={application?.timeWindows[0]?.endTime}
          userName={application.user.name}
          examName={application.exam.name}
          toAllCandidates={false}
          endTime={application?.timeWindows[0]?.endTime}
          application={application?.id}
          hideModal={showModal}
        />
      ))
    }

    return showModal(() => (
      <CheckApplicationTimeWindowModal
        onSubmit={() =>
          showModal(() => (
            <SetExamDateModal
              onSubmit={handleSetExamDateSubmit}
              application={application}
            />
          ))
        }
      />
    ))
  }

  const handleModalAnalysis = async () => {
    await axios
      .get(`${ANALYSIS_API_HOST}/cat/${application.id}`)
      .then((res) => {
        showModal(() => (
          <Modal title="Progresso CAT" isOpen onClose={hideModal}>
            <ProgressHighchart analysisValue={res?.data} />
          </Modal>
        ))
      })
      .catch((err) => {
        console.error(err)
        toast.info(
          'Estudante não iniciou a atividade, o status da atividade deve estar Iniciada ou Finalizada.'
        )
      })
  }

  const ScreenshotDuringExams = async () => {
    await axios
      .get(`${API_HOST}/screenshot`, {
        params: {
          user_id: application.user.id,
          provider_codename: application.user.provider.codename
        }
      })
      .then((res) => setScreenshoturl(res.data.screenshot))
  }
  useEffect(() => {
    if (!screenshoturl) {
      return
    }
    window.open(screenshoturl, 'popup', 'width=1536,height=934')
  }, [screenshoturl])

  const getLastChunkVideo = async () => {
    await axios
      .get(`${API_HOST}/video`, {
        params: {
          user_id: application.user.id,
          provider_codename: application.user.provider.codename
        }
      })
      .then((res) => setVideoUrl(res.data.video))
  }
  useEffect(() => {
    if (!videoUrl) {
      return
    }
    window.open(videoUrl, 'popup', 'width=800,height=600')
  }, [videoUrl])

  const { hasGroup } = useContext(AuthContext)

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        cancelText="Cancelar"
        actionText="OK"
        onAction={() => handleResetApplication(false)}
        onClose={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        title={t('Are you sure you want to reset the time for this exam?')}
        /**
         * This comment was made in order to keep the future expected behavior
         */
        // title={false ? t('Are you sure you want to delete this exam?') : t('Are you sure you want to reset the time for this exam?')}
      >
        {application.user.name && ' ' && application.exam.name}
      </Modal>
      <ExamInformationCard
        application={application}
        toggleMenuDisabled={collectionIsFinished(application?.exam.collection)}
      >
        {hasGroup('ADMINISTRADOR') && (
          <Button
            action="unstyled"
            type="button"
            disabled={!examCanBeReset()}
            onClick={() => setIsModalOpen(true)}
          >
            {t('Reset time')}
          </Button>
        )}
        <Tooltip
          title={
            !examCanHaveDateChanged()
              ? `Aplicação ${t(
                  getStatusLabel(application.status)
                ).toLowerCase()} não
                      pode ter a data modificada`
              : ''
          }
        >
          <Button
            action="unstyled"
            type="button"
            disabled={!examCanHaveDateChanged()}
            onClick={() =>
              showModal(() => (
                <SetExamDateModal
                  onSubmit={handleSetExamDateSubmit}
                  application={application}
                />
              ))
            }
          >
            {t('Set deadline for the activity')}
          </Button>
        </Tooltip>
        {hasGroup('ADMINISTRADOR') && (
          <Button
            action="unstyled"
            type="button"
            disabled={examCanBeReset()}
            onClick={() =>
              showModal(() => (
                <SetRoomModal onSubmit={handleRoomChangeSubmit} />
              ))
            }
          >
            Trocar sala
          </Button>
        )}
        <Button
          action="unstyled"
          type="button"
          onClick={canOpenSetTimeoutModal}
          disabled={!application?.startedAt}
        >
          Redefinir tempo de atividade
        </Button>
        <Button
          action="unstyled"
          type="button"
          onClick={() => handleModalAnalysis()}
        >
          Visualizar progresso
        </Button>
        {hasGroup('ADMINISTRADOR') && (
          <Button
            action="unstyled"
            type="button"
            disabled={application?.exam?.isSecondaryChance}
            onClick={() => handleSecondChanceExam()}
          >
            Vincular segunda tentativa
          </Button>
        )}
        {perms?.Dashboard?.Videos?.CandidateScreenshot && (
          <Button
            action="unstyled"
            type="button"
            onClick={ScreenshotDuringExams}
          >
            Ver tela candidato
          </Button>
        )}
        {perms?.Dashboard?.Videos?.CandidateScreenshot && (
          <Button action="unstyled" type="button" onClick={getLastChunkVideo}>
            {t('See last video chunk')}
          </Button>
        )}
        {}
      </ExamInformationCard>
    </>
  )
}

export default ExamDashbhoardApplicationCard
