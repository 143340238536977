import { createContext } from 'react'
import { IApplicationListState } from 'types'

export const initialState: IApplicationListState = {
  onSearchChange: () => undefined,
  handlePageChange: () => undefined,
  onPageSizeChange: () => undefined,
  onFilter: () => undefined,
  results: [],
  collectionId: undefined,
  exam: undefined,
  isLoading: false,
  fetched: false,
  hasError: false,
  count: 0,
  page: 0,
  pageSize: 0,
  filterParams: undefined,
  numPages: 0,
  ordering: '',
  search: '',
  totalResults: 0,
  handleFilter: function (): void {
    throw new Error('Function not implemented.')
  },
  handlePageSizeChange: function (): void {
    throw new Error('Function not implemented.')
  }
}

export default createContext<IApplicationListState>(initialState)
