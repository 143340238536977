import React, { ReactNode, useContext } from 'react'
import AlternativeOutline from './AlternativeOutline'
import { ThemeContext } from 'styled-components'

type Props = {
  children: ReactNode
  requestedBy?: string
}

const WrongAlternativeOutline = ({ children, requestedBy }: Props) => {
  const theme = useContext(ThemeContext)

  return (
    <AlternativeOutline
      color={theme.colors.danger}
      text={requestedBy === 'CANDIDATE' ? 'Você marcou' : 'Candidato marcou'}
    >
      {children}
    </AlternativeOutline>
  )
}

export default WrongAlternativeOutline
