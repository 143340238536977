import Card from 'components/Card/Card'
import CardSubtitle from 'components/ExamDashboardComponents/CardSubtitle'
import CardTitle from 'components/ExamDashboardComponents/CardTitle'
import Flex from 'components/Flex'
import React from 'react'
import styled from 'styled-components'
import RoomStatus from './RoomStatus'
import RoomSubtitlesInfos from './RoomSubtitles'
import RoomPicture from './RoomPicture'

type RoomInfoCardProps = {
  className?: string
  room: any
}

const RoomInfoCard: React.FC<RoomInfoCardProps> = ({ className, room }) => {
  return (
    <Card className={className}>
      <RoomPicture />
      <div>
        <CardTitle>
          {room && room?.name}
          <Flex>
            <RoomStatus className="room-status" status={room?.isRecording} />
          </Flex>
        </CardTitle>
        <CardSubtitle>
          <RoomSubtitlesInfos proctorName={room?.id} />
        </CardSubtitle>
      </div>
    </Card>
  )
}

export default styled(RoomInfoCard)`
  display: grid;
  grid-template-columns: 55px auto;
  grid-column-gap: 1rem;

  ${CardTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    div {
      margin-left: 1rem;
    }
  }
  ${CardSubtitle} {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
    padding-right: 1.5rem;
  }

  .subtitle-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  .appeal-content {
    display: grid;

    h4 {
      font-size: 0.9rem;
      margin-top: 6px;
    }
    span {
      text-align: justify;
      font-size: 0.8rem;
      margin-top: -16px;
    }
  }
  .appeal-bibliography {
    display: grid;

    h4 {
      font-size: 0.9rem;
      margin-top: 6px;
    }
    span {
      text-align: justify;
      font-size: 0.8rem;
      margin-top: -16px;
    }
  }
`
