import axios from 'axios'
import { API_HOST } from 'consts'
import { DashboardVideoApplication } from 'data/domain/applications'
import { IApplication } from 'types'

export const getDashboardVideoApplication = async (applicationId: number) => {
  const response = await axios.get<DashboardVideoApplication>(
    `${API_HOST}/v1/applications/${applicationId}/dashboard-video`
  )
  return response.data
}

export const checkIsInStayTimeWindow = async () => {
  return (
    (
      await axios.get(`${API_HOST}/v1/applications`, {
        params: {
          in_stay_time_window: true,
          page_size: 1
        }
      })
    ).data?.count > 0
  )
}

export const fetchMinStayTime = async (applicationId: number) => {
  const response = await axios.get<{ minStayTime: string }>(
    `${API_HOST}/v1/applications/${applicationId}/min_stay_time`
  )
  return response.data.minStayTime
}

export const fetchNextApplicationId = async (applicationId: number) => {
  const response = await axios.get<{ applicationId: number | null }>(
    `${API_HOST}/v1/applications/${applicationId}/next`
  )
  return response.data.applicationId
}

export const fetchApplication = async (applicationId: number) => {
  const response = await axios.get<IApplication>(
    `${API_HOST}/v1/applications/${applicationId}`
  )
  return response.data
}

export const fetchAnswers = async (applicationId: number) => {
  const response = await axios.get<
    {
      id: number
      alternative: { letter: string } | null
      alternatives: any[]
      freeResponse: string
      verboseName: string | null
      item: { id: number }
      seconds: number
      position: number
      timeoutDate: any
      answerTimeLimit: number | null
      value: string
      itemValue: string
      gradeLinear: string
      attachment: any[] | null
    }[]
  >(`${API_HOST}/v1/applications/${applicationId}/answers`, {
    params: {
      ordering: 'position'
    }
  })
  return response.data
}
