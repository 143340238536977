import React from 'react'
import InputSelect from './InputSelect'

const CorrectionStatusInput = (props) => {
  const correctionStatus = [
    { id: 'analysis', name: 'Aguardando Correção' },
    { id: 'replied', name: 'Corrigido' },
    { id: 'disclosed', name: 'Divulgado' }
  ]

  return (
    <InputSelect
      label="Status"
      options={correctionStatus}
      placeholder="Status"
      name="correction_status"
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      {...props}
    />
  )
}

export default CorrectionStatusInput
