import React, { useContext } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import allLocales from '@fullcalendar/core/locales-all'
import styled, { ThemeContext } from 'styled-components'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import ApplicationListState from 'states/ApplicationListState'
import { IApplicationListState } from 'types'
import Page from 'components/Page'
import devices from 'utils/devices'

interface EventsCalendarProps {
  className?: string
}

const EventsCalendar = ({ className }: EventsCalendarProps) => {
  const theme = useContext(ThemeContext)
  function getDate(dayString) {
    const today = new Date()
    const year = today.getFullYear().toString()
    let month = (today.getMonth() + 1).toString()

    if (month.length === 1) {
      month = '0' + month
    }

    if (dayString !== undefined) {
      return dayString.replace('YEAR', year).replace('MONTH', month)
    }
    return ''
  }

  const getEvents = (application) => {
    const event = []

    application.map((app) => {
      event.push({
        title: app.exam.collection.name,
        start: getDate(app?.exam?.timeWindows[0]?.startTime)
      })
    })
    return event
  }

  console.log(theme?.clientColors?.secondary)
  return (
    <div className={className}>
      <Page type={'aluno'}>
        <ApplicationListState>
          {({ results }: IApplicationListState) => (
            <div
              style={{
                boxShadow: '0px 1px 4px rgba(58, 58, 58, 0.25)',
                background: '#fff',
                padding: '1rem',
                borderRadius: '16px',
                minHeight: '580px'
              }}
            >
              <FullCalendar
                themeSystem="Simplex"
                locales={allLocales}
                locale="pt-br"
                defaultView="timeGridWeek"
                eventColor={theme?.clientColors?.secondary}
                header={{
                  left: 'prev,next',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                plugins={[dayGridPlugin, timeGridPlugin]}
                events={getEvents(results)}
              />
            </div>
          )}
        </ApplicationListState>
      </Page>
    </div>
  )
}

export default styled(EventsCalendar)`
  background: white;
  min-height: 1169px;
  .fc-button {
    margin: 0;
    background: ${(props) => props.theme.clientColors.secondary};
    border: none;
  }
  .fc-right .fc-prev-button,
  .fc-right .fc-next-button {
    background: #b1d583;
  }

  @media (max-width: ${devices.mobileL}) {
    .fc-toolbar,
    .fc-header-toolbar {
      display: flex;
      flex-direction: column;
    }
  }
`
