import Page from 'components/Page'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ErrorImage from '../assets/img/error-without-sign.png'
import styled from 'styled-components'

interface ResetPasswordExpiredProps {
  className?: string
}

const ResetPasswordExpired: React.FC<ResetPasswordExpiredProps> = ({
  className
}) => {
  const { t } = useTranslation()

  return (
    <Page className={className}>
      <div>
        <img src={ErrorImage} alt="Error" />
      </div>
      <div>
        <h1>{t('Oops! Looks like your token is invalid or has expired.')}</h1>
      </div>
    </Page>
  )
}

export default styled(ResetPasswordExpired)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 100%;
  }

  .button-container {
    width: 100%;
  }
`
