import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BlockingAlert from 'components/BlockingAlert/BlockingAlert'
import CandidateMessageExchangeContext, {
  initialState
} from 'contexts/CandidateMessageExchangeContext'
import {
  createBreakRequest,
  createFaceDetectionAlert,
  createMessage,
  createNursingBreakRequest,
  dismissMessage,
  resumeApplication,
  startBreak,
  startNursingBreak
} from 'data/apis/message-exchange'
import { setRoom } from 'data/apis/online-status'
import { Motive } from 'data/domain/message-exchange'
import useCandidate from 'hooks/firebase/useCandidate'
import useCandidateBreakRequest, {
  useCandidateHasPendingBreakRequest
} from 'hooks/firebase/useCandidateBreakRequest'
import useNotSeenCandidateMessages from 'hooks/firebase/useNotSeenCandidateMessages'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import useFeedback from 'hooks/useFeedback'
import useApplication from 'hooks/useRoom'
import { RollbarErrorTracking } from 'infra/rollbar'
import FaceDetectionManager from 'managers/faceDetection'
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import {
  childrenIsFunction,
  IApplicationSocketState,
  IApplicationSocketStateAction,
  LookDirectionType,
  SOCKET_EVENTS
} from '../types'
import { AuthContext } from './AuthState'
import { FIREBASE_ENABLED } from 'consts'

const reducer = (
  state: IApplicationSocketState,
  action: IApplicationSocketStateAction
): IApplicationSocketState => {
  switch (action.type) {
    case 'SOCKET_CONNECTED':
      return { ...state, socket: action.payload }
    case 'PAUSE_APPLICATION':
      return { ...state, status: 'PAUSED', pauseReason: action.payload }
    case 'RESUME_APPLICATION':
      return { ...state, status: 'NORMAL' }
    case 'ALERT':
      return { ...state, alertMessage: action.payload }
    case 'BREAK_ACCEPTED':
      return {
        ...state,
        status: 'BREAK',
        hasPendingBreak: action.payload.hasPendingBreak,
        forcePause: action.payload.forcePause
      }
  }
}

type ApplicationSocketProps = {
  children: Function
}

const CandidateMessageExchangeState = ({
  children
}: ApplicationSocketProps) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const [state] = useReducer(reducer, initialState)
  const socket = null
  const candidateId = user?.id.toString()
  const { applicationId } = useParams()
  const application = useApplication(applicationId)
  const roomId = application?.roomId?.toString()
  const configuration = useApplicationConfiguration(
    application?.exam.collection.applicationConfiguration
  )
  const { messages } = useNotSeenCandidateMessages(roomId, candidateId)
  const alerts = messages.filter(
    (d) => d.notificationType === 'ALERT' && d.motive !== 'RELOAD_REQUEST'
  )
  const reloadRequests = messages.filter((d) => d.motive === 'RELOAD_REQUEST')
  const badgeMessages = messages.filter((d) => d.notificationType === 'BADGE')
  const { breakRequest } = useCandidateBreakRequest(roomId, candidateId)
  const { candidate } = useCandidate(roomId, candidateId)
  const { handleInfoFeedback } = useFeedback()
  const [shouldRenderRequests, setShouldRenderRequests] = useState(false)
  const hasPendingBreakRequest = useCandidateHasPendingBreakRequest(
    roomId,
    candidateId
  )
  const renderAlert = useCallback(
    () =>
      alerts.length > 0 && (
        <BlockingAlert
          visible={true}
          title={<FontAwesomeIcon icon="exclamation-circle" />}
          content={alerts[0].message}
          dismissible={true}
          onDismiss={() => {
            dismissMessage(roomId, alerts[0].id)
          }}
        />
      ),
    [alerts, roomId]
  )

  const renderReloadRequest = useCallback(
    () =>
      shouldRenderRequests &&
      reloadRequests.length > 0 && (
        <BlockingAlert
          visible={true}
          title={t('Attention')}
          content={t(reloadRequests[0].message)}
          img={theme.pauseImg}
          dismissible={true}
          onDismiss={async () => {
            for (const request of reloadRequests) {
              await dismissMessage(roomId, request.id)
            }
            window.location.reload()
          }}
        />
      ),
    [t, theme, reloadRequests, roomId, shouldRenderRequests]
  )

  const getBlockingAlertImg = useCallback(
    (motive?: Motive) => {
      return (
        {
          BATHROOM: theme.restroomImg,
          BREAK: theme.restroomImg,
          PAUSE: theme.pauseImg,
          BREAK_START: theme.bathroomImg,
          NURSING_BREAK_START: theme.nursingImg
        }[motive] || theme.pauseImg
      )
    },
    [theme]
  )

  const CheckMotivePause = useCallback(
    (motive?: Motive) => {
      return {
        NURSING_BREAK_START: t('Nursing break'),
        BREAK_START: t('Your bathroom break request has been accepted'),
        PAUSE: t('Your application has been paused')
      }[motive]
    },
    [t]
  )

  const pause = candidate?.pause
  const renderPause = useCallback(
    () => (
      <BlockingAlert
        visible={!!pause}
        title={CheckMotivePause(pause?.motive)}
        content={pause?.showMessage ? pause?.message : null}
        maxDismissDate={
          pause?.maxEndDate ? pause?.maxEndDate.seconds * 1000 : null
        }
        img={getBlockingAlertImg(pause?.motive)}
        dismissible={pause?.dismissible}
        dismissText="Voltei!"
        onDismiss={() => {
          const motive =
            pause?.motive === 'NURSING_BREAK_START'
              ? 'NURSING_BREAK_END'
              : 'BREAK_END'
          resumeApplication(roomId, candidateId, { motive })
          badgeMessages?.length &&
            badgeMessages.map((message) => {
              return dismissMessage(roomId, message.id)
            })
        }}
      />
    ),
    [
      roomId,
      candidateId,
      getBlockingAlertImg,
      pause,
      badgeMessages,
      CheckMotivePause
    ]
  )

  const sendQuestion = useCallback(
    (content: string) => {
      if (!content || !candidateId) {
        return
      }
      return createMessage(roomId, candidateId, {
        message: content,
        authorId: candidateId,
        notificationType: null,
        author: 'CANDIDATE'
      })
    },
    [candidateId, roomId]
  )

  const askForBreak = useCallback(() => {
    const pendingBreakRequestErrorReason = {
      message: t('There is already a pending break request')
    }
    if (hasPendingBreakRequest || breakRequest) {
      return Promise.reject(pendingBreakRequestErrorReason)
    }
    return createBreakRequest(roomId, candidateId, {
      motive: 'BATHROOM'
    })
  }, [roomId, candidateId, hasPendingBreakRequest, breakRequest, t])

  const askForNursingBreak = useCallback(() => {
    const pendingBreakRequestErrorReason = {
      message: t('There is already a pending break request')
    }
    if (hasPendingBreakRequest || breakRequest) {
      return Promise.reject(pendingBreakRequestErrorReason)
    }
    return createNursingBreakRequest(roomId, candidateId, {
      motive: 'NURSING_BREAK'
    })
  }, [roomId, candidateId, hasPendingBreakRequest, breakRequest, t])

  const handleFaceDetectionAlert = useCallback(
    (message: LookDirectionType) => {
      if (roomId && candidateId) {
        createFaceDetectionAlert(roomId, candidateId, message)
      }
    },
    [roomId, candidateId]
  )

  useEffect(() => {
    const updatedBreakRequests = messages.filter(
      (d) => d.notificationType === 'TOAST' && d.dismissed === false
    )
    if (!updatedBreakRequests.length) {
      return
    }

    updatedBreakRequests
      .filter((breakRequest) => {
        return (
          breakRequest.message === 'REFUSE_BREAK' ||
          breakRequest.message === 'REFUSE_NURSING_BREAK'
        )
      })
      .forEach((breakRequest) => {
        handleInfoFeedback(t('The applicator rejected your break request.'))
        dismissMessage(roomId, breakRequest.id).catch(
          RollbarErrorTracking.logError
        )
      })
  }, [messages, roomId, t, handleInfoFeedback])

  useEffect(() => {
    FaceDetectionManager.setCallBack(handleFaceDetectionAlert)
    return () => {
      FaceDetectionManager.setCallBack(() => {})
    }
  }, [handleFaceDetectionAlert])

  useEffect(() => {
    if (!user || !roomId || !FIREBASE_ENABLED) {
      return
    }
    setRoom(user?.id.toString(), roomId.toString())
  }, [user, roomId])

  const setCameraAccepted = (accepted: boolean) => {
    socket && socket.emit(SOCKET_EVENTS.PERMISSION_STATUS_CHANGED, { accepted })
  }

  const sendCameraFrame = (base64Frame: string) => {
    socket &&
      socket.emit(SOCKET_EVENTS.NEW_CAMERA_FRAME, { frame: base64Frame })
  }

  const returnFromBreak = () => {}

  const updateExamStatus = () => {}

  const joinRoomAsCandidate = () => {}

  const handlePendingBreak = useCallback(() => {
    if (breakRequest.motive === 'NURSING_BREAK') {
      return startNursingBreak(roomId, candidateId, breakRequest.id)
    }
    return startBreak(
      roomId,
      candidateId,
      breakRequest.id,
      configuration.timeToReturnFromBreakInSeconds || 60
    )
  }, [roomId, candidateId, breakRequest, configuration])

  const showReloadRequests = () => {
    setShouldRenderRequests(true)
  }

  const contextValue = {
    ...state,
    socket,
    sendQuestion,
    askForBreak,
    askForNursingBreak,
    returnFromBreak,
    updateExamStatus,
    setCameraAccepted,
    sendCameraFrame,
    joinRoomAsCandidate,
    hasPendingBreak: !!breakRequest,
    breakRequest: breakRequest,
    handleFaceDetectionAlert,
    handlePendingBreak,
    candidate,
    badgeMessages,
    reloadRequests,
    showReloadRequests
  }

  // if (approvedModal) {
  //   return (
  //     <Modal
  //       isOpen={approvedModal}
  //       title={t('The applicator approved your break request.')}
  //       onClose={() => setApprovedModal(false)}
  //       onAction={() => setApprovedModal(false)}
  //     >
  //       {t('Your exam will be paused when you finish the current question.')}
  //     </Modal>
  //   )
  // }

  return (
    <CandidateMessageExchangeContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
      {renderPause()}
      {renderAlert()}
      {renderReloadRequest()}
    </CandidateMessageExchangeContext.Provider>
  )
}

export default CandidateMessageExchangeState
