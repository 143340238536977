import React, { createContext } from 'react'
import { EMAIL_API_HOST, EMAIL_PARTNER_TOKEN } from '../consts'
import useList, { initialState as useListInitialState } from '../hooks/useList'

type EmailListStateProps = {
  children: Function
}

const initialState = useListInitialState

export const EmailListContext = createContext(initialState)

export const EmailListState = ({ children }: EmailListStateProps) => {
  const state = useList({
    api: `${EMAIL_API_HOST}/v2/emails`,
    authorization: EMAIL_PARTNER_TOKEN,
    defaultPageSize: 10,
    defaultOrdering: 'user__name'
  })

  return (
    <EmailListContext.Provider value={state}>
      {children(state)}
    </EmailListContext.Provider>
  )
}

export default EmailListState
