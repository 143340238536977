import React, { useContext, useEffect, useState } from 'react'
import Educhat, {
  listUsers,
  currentUser,
  chatTheme,
  connectionConfig,
  chatConfig
} from 'educhat'
import Axios from 'axios'
import { API_HOST, CHAT_CONFIG } from 'consts'
import { AuthContext } from 'states/AuthState'

interface Props {
  room: string
}

const Chat = ({ room }: Props) => {
  const { user, hasGroup, theme } = useContext(AuthContext)

  const [contacts, setContacts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (contacts.length > 0 && user) {
      listUsers(contacts)

      currentUser(
        hasGroup('ADMINISTRADOR')
          ? {
              id: user?.id,
              name: user?.name
            }
          : hasGroup('PROCTOR') && {
              id: room,
              name: `Sala ${room}`
            }
      )

      chatTheme({
        fontFamily: 'Open Sans, sans-serif',
        primaryColor: theme?.buttonHover,
        secondaryColor: '#DFDFDE',
        fontColor: '#ffffff',
        messageMeBackground: theme?.secondaryColor,
        messageOtherBackground: theme?.primaryColor,
        messageMeColor: '#fff',
        messageOtherColor: '#fff'
      })

      connectionConfig(JSON.parse(CHAT_CONFIG))

      chatConfig({
        oneContact: false,
        provider: user?.provider?.codename,
        button: 'TOPBUTTON'
      })

      setIsLoading(true)
    }
  }, [contacts, user, hasGroup, theme, room])

  useEffect(() => {
    Axios.get(`${API_HOST}/v1/room_responsibles`, {
      params: hasGroup('ADMINISTRADOR') ? { responsible: user?.id } : { room }
    }).then((res) => {
      if (hasGroup('ADMINISTRADOR')) {
        setContacts(
          res.data.map((r) => {
            return { id: r.room, name: `Sala ${r.room} - ${r?.proctor}` }
          })
        )
      } else if (hasGroup('PROCTOR')) {
        setContacts(res.data.map((r) => r.responsible))
      }
    })
  }, [hasGroup, room, user])

  return <>{isLoading && <Educhat />}</>
}

export default Chat
