import React, { useContext, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DropdownMenuButton from '../Buttons/DropdownMenuButton'
import styled from 'styled-components'
import Modal from '../Modal/Modal'
import Textarea from '../Formik/Inputs/textarea'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import CandidateMessageExchangeContext from 'contexts/CandidateMessageExchangeContext'
import { PersonContext } from 'states/PersonState'
import useFeedback from 'hooks/useFeedback'
import { messages, errors } from 'resources'
import { toast } from 'react-toastify'

type ContactApplicatorButtonProps = {
  className?: string
}

type MessageFormValues = {
  message: string
}

const ContactApplicatorButton = ({
  className
}: ContactApplicatorButtonProps) => {
  const { t } = useTranslation()
  const { sendQuestion, askForBreak, askForNursingBreak } = useContext(
    CandidateMessageExchangeContext
  )
  const { person } = useContext(PersonContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { handleErrorFeedback, handleSuccessFeedback } = useFeedback()

  const maxMessageLength = 200

  const sendToilet = () => {
    askForBreak()
      .then(() => {
        handleSuccessFeedback(t(messages.REQUEST_SENT))
      })
      .catch((error) => {
        toast.error(t(error.message ? error.message : errors.DEFAULT))
      })
  }

  const sendNursingBreak = () => {
    askForNursingBreak()
      .then(() => {
        handleSuccessFeedback(t(messages.REQUEST_SENT))
      })
      .catch((error) => {
        toast.error(t(error.message ? error.message : errors.DEFAULT))
      })
  }

  const validateMessageForm = (values) => {
    const errors: { [key: string]: string } = {}
    const requiredMsg = t('This field is required.')
    const { message, ...hierarchy } = values

    if (!message) {
      errors.message = requiredMsg
    }

    Object.entries(hierarchy).forEach((entry) => {
      const [key, value] = entry
      if (!value) {
        errors[key] = requiredMsg
      }
    })
    return errors
  }

  const handleSubmit = async (
    values: MessageFormValues,
    { resetForm }: FormikHelpers<MessageFormValues>
  ) => {
    if (!values.message) {
      return
    }

    try {
      await sendQuestion(values.message)
      handleSuccessFeedback(t(messages.MESSAGE_SENT))
      setIsModalOpen(false)
      setTimeout(resetForm, 100)
    } catch (error) {
      handleErrorFeedback(error, t(errors.DEFAULT))
    }
  }

  const handleClose = useCallback((formik: FormikProps<MessageFormValues>) => {
    setIsModalOpen(false)
    formik.resetForm()
  }, [])

  const actionsList = [
    { label: t('Request a bathroom break'), onClick: sendToilet },
    { label: t('Send message'), onClick: () => setIsModalOpen(true) }
  ]

  if (person?.nursingMother) {
    actionsList.push({ label: t('Nursing break'), onClick: sendNursingBreak })
  }

  return (
    <div className={className}>
      <Formik
        initialValues={{ message: '' }}
        onSubmit={handleSubmit}
        validate={validateMessageForm}
      >
        {(formik) => (
          <Modal
            title={t('Send message')}
            actionText={t('Send')}
            onAction={formik.handleSubmit}
            isOpen={isModalOpen}
            onClose={() => handleClose(formik)}
            onCancel={() => handleClose(formik)}
            // onEntered={() => messageTextareaRef.current?.focus()}
            actionDisabled={!formik.values.message || formik.isSubmitting}
          >
            <Textarea name="message" maxLength={maxMessageLength} />
          </Modal>
        )}
      </Formik>
      <DropdownMenuButton
        action="secondary"
        label={t('Contact applicator')}
        actions={actionsList}
      />
    </div>
  )
}

export default styled(ContactApplicatorButton)``
