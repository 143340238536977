import { IApplicationConfigurationMapping } from '../domain/application-configuration'
import { IApplicationConfiguration } from 'types'
import axios from 'axios'
import { RollbarErrorTracking } from 'infra/rollbar'
import { API_HOST } from 'consts'

class ApplicationConfigurationStore {
  private __store: IApplicationConfigurationMapping

  constructor() {
    this.__store = {}
  }

  private async fetchConfiguration(
    id: string
  ): Promise<IApplicationConfiguration> {
    try {
      const response = await axios.get(
        `${API_HOST}/v1/application_configuration/${id}`
      )
      const configuration = response.data as IApplicationConfiguration
      return configuration
    } catch (e) {
      // if there is no application configuration on the DB,
      // we handle it
      if (e.isAxiosError && e.response.status === 404) {
        return null
      }

      RollbarErrorTracking.logError(e)
      return null
    }
  }

  private set(id: string, applicationConfiguration: IApplicationConfiguration) {
    this.__store[id] = applicationConfiguration
  }

  public async get(id: string): Promise<IApplicationConfiguration> {
    const applicationConfiguration = this.__store[id]
    // if we have it in cache, return
    if (applicationConfiguration) {
      return applicationConfiguration
    }

    // otherwise, let's try to fetch
    const fetchResult = await this.fetchConfiguration(id)
    this.set(id, fetchResult)
    return fetchResult
  }
}

const applicationConfigurationStore = new ApplicationConfigurationStore()
export default applicationConfigurationStore
