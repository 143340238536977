import Separator from 'components/ExamDashboardComponents/Separator'
import React, { useState } from 'react'
import styled from 'styled-components'
import { IExamDashboardApplication } from 'types'
import ChatHistoryDetails from './ChatHistoryDetails'
import TabSelector from '../TabSelector'
import OralVideo from './VideoHistory/OralVideo'
import InternetMeasurement from './InternetMeasurement/InternetMeasurement'
import PicturesReview from './PicturesReview/PicturesReview'
import LoginEventDetails from './LoginEvent/LoginEventDetails'
import CandidateScreenDetails from './CandidateScreen/CandidateScreen'

type ExpandableDetailsProps = {
  className?: string
  expanded?: boolean
  application: IExamDashboardApplication
}

const ExpandableDetails = ({
  className,
  application,
  expanded
}: ExpandableDetailsProps) => {
  const [tab, setTab] = useState('Chat')
  const tabs = [
    'Chat',
    'Internet',
    'Videos',
    'Pictures',
    'Logins',
    'Screenshot'
  ]

  const handleTabSelect = (tabCode: string) => {
    setTab(tabCode)
  }

  const renderSelectedTab = () => {
    return {
      Videos: function getVideoHistoryDetails() {
        return <OralVideo application={application} />
      },
      Chat: function getChatHistoryDetails() {
        return <ChatHistoryDetails application={application} scroll={true} />
      },
      Internet: function getInternetMeasurementDetails() {
        return <InternetMeasurement application={application} />
      },
      Pictures: function getPicturesForReview() {
        return <PicturesReview application={application} />
      },
      Logins: function getLoginEventDetails() {
        return <LoginEventDetails application={application} pagination={true} />
      },
      Screenshot: function getLoginEventDetails() {
        return (
          <CandidateScreenDetails application={application} pagination={true} />
        )
      }
    }[tab]()
  }

  return (
    <div className={`${className}`}>
      <div className={`content ${expanded ? 'expanded' : ''}`}>
        <TabSelector
          tabs={tabs}
          defaultTab={tab}
          onTabSelected={handleTabSelect}
          selectedTab={tab}
        ></TabSelector>
        <Separator style={{ width: '96%' }} />
        {expanded && renderSelectedTab()}
      </div>
    </div>
  )
}

export default styled(ExpandableDetails)`
  grid-column: auto / span 2;

  .content {
    visibility: hidden;
    border-radius: 5px;
    height: 0;
    transition: height 0.3s;
    border: 2px solid #f5f5f5;
    padding: 0.5rem;
  }

  .expanded {
    visibility: unset;
    display: block;
    height: auto;
    margin-bottom: 20px;
  }
`
