import React from 'react'
import styled from 'styled-components'

type HtmlProps = {
  children: string | undefined
  noHighlight?: boolean
  className?: string
  style?: {} | undefined
}

const Html = ({ className, children, style }: HtmlProps) => {
  return (
    <div
      style={style && style}
      className={className}
      dangerouslySetInnerHTML={{ __html: children || '' }}
    />
  )
}

export default styled(Html)`
  ${(props) =>
    props.noHighlight &&
    `
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      word-wrap: break-word;
    `}
`
