import React, { useState } from 'react'
import styled from 'styled-components'
import { IExamDashboardApplication } from 'types'
import axios from 'axios'
import Button from '../../Button'
import { API_HOST } from 'consts'
import { useTranslation } from 'react-i18next'

type CandidateScreenProps = {
  className?: string
  application: IExamDashboardApplication
  pagination?: boolean
}

const CandidateScreenDetails = ({
  className,
  application
}: CandidateScreenProps) => {
  const { t } = useTranslation()
  const [candidateScreen, setCandidateScreen] = useState([])
  const [datetimeScreenshot, setDatetimeScreenshot] = useState('')

  const ScreenshotPostExams = async () => {
    await axios
      .get(`${API_HOST}/screenshotpost`, {
        params: {
          user_id: application.user.id,
          provider_codename: application.user.provider.codename,
          selected_date: datetimeScreenshot,
          applicationStartedAtDate: application.startedAt
        }
      })
      .then((res) => setCandidateScreen(res.data.screenshotpost))
  }

  return (
    <>
      <div className={className}>
        <h4>{t('Select the datetime')}</h4>
        <input
          className={className}
          onChange={(event) => setDatetimeScreenshot(event.target.value)}
          type="datetime-local"
        />
        <Button
          type="button"
          className="screenshotPostButton"
          onClick={ScreenshotPostExams}
        >
          {t('Searching for screenshot')}
        </Button>
        {candidateScreen.map((screenshot_files) => {
          return (
            <>
              <p className="paragraphScreenshot">
                {t('Screenshot links from s3')}
              </p>
              <a
                href={screenshot_files}
                key={screenshot_files.id}
                target="blank"
                className="screenshotlinks"
              >
                {screenshot_files}
              </a>
            </>
          )
        })}
      </div>
    </>
  )
}

export default styled(CandidateScreenDetails)`
  padding: 0 1rem;

  .screenshotPostButton {
    background: ${(props) => props.theme.clientColors.secondary};

    &:hover {
      filter: brightness(0.9);
    }
  }

  .screenshotlinks {
    display: grid;
    font: bold 0.7rem Open Sans, sans-serif;
    text-decoration: none;

    &:link {
      color: #11096b;
    }

    &:visited {
      color: #163549;
    }
  }

  .paragraphScreenshot {
    display: grid;
    font: bold 0.8rem Open Sans, sans-serif;
  }

  input {
    font-family: Open Sans, sans-serif;
    height: 1.95rem;
    border-radius: 5px;
    border: 1px solid #cecece;
  }

  button {
    color: #fff;
    font-size: 14px;
    font-weight: 900;
    /* padding: 5px 1rem; */
    height: 2.1rem;
    margin-left: 0.7rem;
  }
`
