import React, { createContext, useReducer, ChangeEvent } from 'react'
import { API_HOST } from '../consts'
import {
  childrenIsFunction,
  IDashboardPreFilterState,
  DashboardPreFilterAction
} from '../types'
import useList from 'hooks/useList'

const reducer = (
  state: IDashboardPreFilterState,
  action: DashboardPreFilterAction
): IDashboardPreFilterState => {
  switch (action.type) {
    case 'FETCH':
      return { ...state, isLoading: true }
    case 'FETCH_SUCCESS':
      return { ...state, isLoading: false }
    case 'FETCH_ERROR':
      return { ...state, isLoading: false }
    default:
      return state
  }
} 

const initialState = {
  isLoading: true,
  totalResults: undefined,
  results: [],
  pageSize: undefined,
  numPages: undefined,
  onSearchChange: () => undefined,
  handlePageChange: () => undefined,
  onPageSizeChange: () => undefined,
  onFilter: () => undefined
}

export const DashboardPreFilterContext = createContext<
  IDashboardPreFilterState
>(initialState)

type DashboardPreFilterProps = {
  children: any
  tipoId: number
  ordering: any
}

const DashboardPreFilterState = ({ children , tipoId, ordering}: DashboardPreFilterProps) => {
  const [, dispatch] = useReducer(reducer, initialState)

  const {
    isLoading,
    results,
    count,
    pageSize,
    numPages,
    handleSearchChange,
    handlePageChange,
    handlePageSizeChange,
    handleFilter
  } = useList({
    api: `${API_HOST}/v1/collections_prefilter?categoria=${tipoId}&ordering=${ordering}`,
    defaultPageSize: 10,
    // defaultOrdering: ordering
  })

  const formatListFilter = (params) => {
    return {
      status: params?.status,
      name: params?.areaConhecimento,
      school_stage: params?.etapa,
      discipline: params?.disciplina
    }
  }

  const onFilter = (params: any) => {
    handleFilter(formatListFilter(params))
  }

  const onPageSizeChange = (event: any) => {
    handlePageSizeChange(event.target.value)
  }

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'ON_SEARCH_CHANGE', payload: event.target.value })
    handleSearchChange(event.target.value)
  }

  const contextValue: IDashboardPreFilterState = {
    isLoading,
    results,
    totalResults: count,
    pageSize,
    numPages,
    onSearchChange,
    onPageSizeChange,
    handlePageChange,
    onFilter
  }
  
  return (
    <DashboardPreFilterContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </DashboardPreFilterContext.Provider>
  )
}

export default DashboardPreFilterState
