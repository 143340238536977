import React, { ChangeEvent, ReactNode } from 'react'
import styled from 'styled-components'
import Paginate from './Paginate'

type PaginationFooterProps = {
  onPageSizeChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onPageChange: (page: number) => void
  pageSize: number
  pageCount: number
  children?: ReactNode
  className?: string
  disablePageSizeChange?: boolean
}

const PaginationFooter = ({
  onPageSizeChange,
  onPageChange,
  pageSize,
  pageCount,
  children,
  className,
  disablePageSizeChange
}: PaginationFooterProps) => {
  return (
    <div className={className}>
      <div className="top-row">
        <Paginate pageCount={pageCount} onPageChange={onPageChange} />
      </div>
      <div className="bottom-row">{children}</div>
    </div>
  )
}

export default styled(PaginationFooter)`
  font-size: 12px;

  .top-row {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .bottom-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  input[type='number'] {
    width: 3rem;
    padding: 8px;
    border: 1px solid #eee;
    height: 1rem;
    border-radius: 4px;
    box-shadow: 0px 1px 1px #00000029;
    outline: none;
    &::-webkit-inner-spin-button {
      opacity: 1;
    }
  }
`
