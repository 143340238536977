import axios from 'axios'
import { API_HOST } from 'consts'
import { DirectorSymptom, SymptomTableRow } from 'data/domain/repertorization'
import debounce from 'debounce-promise'
import repertorizationManager from 'managers/repertorizationManager'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IApplication } from 'types'

interface SymptomTableProps {
  className?: string
  application?: IApplication
}

const SymptomTable = ({ application, className }: SymptomTableProps) => {
  const [symptomTable, setSymptomTable] = useState([
    {
      symptom: undefined,
      rubric: undefined,
      pageColumn: undefined,
      position: undefined
    }
  ])
  const [directorSymptom, setDirectorSymptom] = useState('')
  const directorSymptomRef = useRef(null)
  const symptomTableRefs = useRef({})

  useEffect(() => {
    if (directorSymptomRef.current && directorSymptom) {
      directorSymptomRef.current.value = directorSymptom
    }
  }, [directorSymptom])

  const { t } = useTranslation()

  const handleChange = debounce(async (target, position) => {
    const payload = {
      application: application.id,
      position: position
    }
    payload[target.name] = target.value

    repertorizationManager.saveSymptomTableRow(payload as SymptomTableRow)
  }, 500)

  const handleSaveDirectorSymptom = debounce((value) => {
    const payload = {
      application: application.id,
      name: value
    }
    repertorizationManager.saveDirectorSymptom(payload as DirectorSymptom)
  }, 500)

  useEffect(() => {
    if (!application) {
      return
    }
    const params = {
      application: application.id
    }
    axios
      .get(`${API_HOST}/v1/symptom_table`, { params })
      .then((res) => setSymptomTable(res.data))

    axios
      .get(`${API_HOST}/v1/director_symptom`, { params })
      .then((res) => setDirectorSymptom(res.data[0]?.name))
  }, [application])

  useEffect(() => {
    symptomTable.forEach((st) => {
      const keyPrefixes = ['pageColumn', 'rubric', 'symptom']
      keyPrefixes.forEach((keyPrefix) => {
        const key = `${keyPrefix}${st.position}`
        if (symptomTableRefs.current[key]) {
          symptomTableRefs.current[key].value = st[keyPrefix]
        }
      })
    })
  }, [symptomTable])

  return (
    <div className={className}>
      <table>
        <tr>
          <th></th>
          <th>{t('Symptom')}</th>
          <th>{t('Rubrics')}</th>
          <th>{t('PAGE/COLUMN')}</th>
        </tr>
        {Array(10)
          .fill(0)
          .map((_, i) => (
            <tr
              key={i}
              onChange={(evt: any) => handleChange(evt.target, i + 1)}
            >
              <td key={i + 1}>{i + 1}</td>
              <td key={`symptom${i + 1}`}>
                <input
                  name="symptom"
                  ref={(el) => {
                    const key = `symptom${i + 1}`
                    symptomTableRefs.current[key] = el
                  }}
                />
              </td>
              <td key={`rubric${i + 1}`}>
                <input
                  name="rubric"
                  ref={(el) => {
                    const key = `rubric${i + 1}`
                    symptomTableRefs.current[key] = el
                  }}
                />
              </td>
              <td key={`pag/col${i + 1}`}>
                <input
                  name="pageColumn"
                  ref={(el) => {
                    const key = `pageColumn${i + 1}`
                    symptomTableRefs.current[key] = el
                  }}
                />
              </td>
            </tr>
          ))}
      </table>
      <table>
        <tr>
          <th>{t('Director symptom')}</th>
        </tr>
        <tr>
          <td>
            <input
              ref={directorSymptomRef}
              name="director_symptom"
              onChange={(evt: any) =>
                handleSaveDirectorSymptom(evt.target.value)
              }
            />
          </td>
        </tr>
      </table>
    </div>
  )
}

export default styled(SymptomTable)`
  padding-top: 1rem;
  input {
    margin-left: 10%;
    width: 80%;
    text-align: center;
  }
  table {
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }
  td {
    align-items: flex-start;
    justify-content: space-between;
  }
  .toggle-button {
    display: flex;
    justify-content: flex-end;
  }
`
