import React from 'react'
import PropTypes from 'prop-types'

type RawCheckboxProps = {
  label?: string
  onChange: any
  checked: boolean
  help?: string
  disabled?: boolean
  className?: string
}

const RawCheckbox = ({
  label,
  onChange,
  checked,
  help,
  disabled,
  className,
  ...rest
}: RawCheckboxProps) => (
  <label
    className={`container-check-custom ${className} ${
      disabled ? 'disabled' : ''
    }`}
  >
    {` ${label}`}
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    />
    <span className="checkmark" />
    {help && <small className="form-text text-muted">{help}</small>}
  </label>
)

RawCheckbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  help: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
}

RawCheckbox.defaultProps = {
  label: '',
  help: null,
  className: '',
  disabled: false,
  onChange: () => {}
}

export default RawCheckbox
