import React, { useEffect, useState } from 'react'
import { RollbarErrorTracking } from 'infra/rollbar'
import { useTranslation } from 'react-i18next'
import { dateTimeToHour } from 'utils/date'

type Props = {
  applicationId: number
  fetchMinStayTime: (applicationId: number) => Promise<string | null>
}

export const MinStayTimeIndicator = ({
  applicationId,
  fetchMinStayTime
}: Props) => {
  const { t } = useTranslation()
  const [minStayTime, setMinStayTime] = useState('')

  useEffect(() => {
    fetchMinStayTime(applicationId)
      .then(setMinStayTime)
      .catch(RollbarErrorTracking.logError)
  }, [applicationId, fetchMinStayTime])

  if (!minStayTime) {
    return null
  }

  return (
    <span>
      <strong>
        {t(
          'Attention to minimum stay time! You must not leave the platform until {{date}}',
          { date: dateTimeToHour(minStayTime) }
        )}
      </strong>
    </span>
  )
}
