import { isNull } from 'lodash'
import React, { useContext } from 'react'
import withRouter from 'react-router-dom/withRouter'
import { ThemeContext } from 'styled-components'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

/* eslint-disable */
const ParticipacaoDreColumn = ({ boletimaluno, hideHeader, ...rest }) => {
  const theme = useContext(ThemeContext)
  const toCamelCase = (valor) => {
    if (valor === 'Básico') {
      return 'básico'
    }
    if (valor === 'Avançado') {
      return 'avançado'
    }
    if (valor === 'Adequado') {
      return 'adequado'
    }
    if (valor === 'Abaixo do básico') {
      return 'abaixoDoBásico'
    }
  }

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: 'rgb(103, 53, 221)',
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  }))(TableCell)

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }))(TableRow)

  function createData(firstName, pontuacao, nivel, status) {
    return { firstName, pontuacao, nivel, status }
  }
  const rows = [
    createData(
      'Aluno',
      isNull(boletimaluno.nTALUNO) ? '-' : boletimaluno.nTALUNO,
      isNull(boletimaluno.nTALUNO) ? '-' : boletimaluno.proficienciaAluno,
      isNull(boletimaluno.nTALUNO)
        ? '-'
        : boletimaluno.proficienciaAluno
          ? boletimaluno.statusProficienciaAno[
          toCamelCase(boletimaluno.proficienciaAluno)
          ]
          : '-'
    ),
    createData(
      'DR',
      isNull(boletimaluno.nTDRE) ? '-' : boletimaluno.nTDRE,
      isNull(boletimaluno.nTDRE) ? '-' : boletimaluno.proficienciaDre,
      isNull(boletimaluno.nTDRE)
        ? '-'
        : boletimaluno.proficienciaDre
          ? boletimaluno.statusProficienciaAno[
          toCamelCase(boletimaluno.proficienciaDre)
          ]
          : '-'
    ),
    createData(
      'Rede',
      isNull(boletimaluno.nTREDE) ? '-' : boletimaluno.nTREDE,
      isNull(boletimaluno.nTREDE) ? '-' : boletimaluno.proficienciaRede,
      isNull(boletimaluno.nTREDE)
        ? '-'
        : boletimaluno.proficienciaRede
          ? boletimaluno.statusProficienciaAno[
          toCamelCase(boletimaluno.proficienciaRede)
          ]
          : '-'
    ),
    createData(
      'Escola',
      isNull(boletimaluno.nTESCOLA) ? '-' : boletimaluno.nTESCOLA,
      isNull(boletimaluno.nTESCOLA) ? '-' : boletimaluno.proficienciaEscola,
      isNull(boletimaluno.nTESCOLA)
        ? '-'
        : boletimaluno.proficienciaEscola
          ? boletimaluno.statusProficienciaAno[
          toCamelCase(boletimaluno.proficienciaEscola)
          ]
          : '-'
    )
  ]

  const useStyles = makeStyles({
    table: {
      minWidth: 700
    }
  })

  const classes = useStyles()

  return (
    <div style={{ marginTop: '3.2rem', marginBottom: '1rem' }}>
      <TableContainer
        style={{ borderColor: '#ccc', borderStyle: 'none solid none solid' }}
        component={Paper}
      >
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  borderTopLeftRadius: '10px',
                  borderBottomLeftRadius: '10px',
                  padding: '5px'
                }}
                colSpan={2}
                align="right"
              >
                Proficiência
              </StyledTableCell>
              <StyledTableCell
                style={{
                  borderTopRightRadius: '10px',
                  borderBottomRightRadius: '10px',
                  padding: '5px'
                }}
                colSpan={2}
                align="center"
              >
                Nível
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.firstName}>
                <StyledTableCell
                  style={{
                    borderColor: '#ccc',
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    borderStyle: 'none solid none none'
                  }}
                  component="th"
                  scope="row"
                  align="center"
                >
                  {row.firstName}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderColor: '#ccc',
                    borderStyle: 'none solid none none'
                  }}
                  align="center"
                >
                  {row.pontuacao}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderColor: '#ccc',
                    borderStyle: 'none solid none none'
                  }}
                  align="center"
                >
                  {row.nivel}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px'
                  }}
                  align="center"
                >
                  {row.status}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default withRouter(ParticipacaoDreColumn)
