import React from 'react'
import styled from 'styled-components'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Html from 'components/Html'
import LinearScaleModal from 'components/LinearScaleModal'

type SummaryCardProps = {
  className?: string
  answer?: any
  item?: any
  collection?: any
}

const SummaryCard = ({
  className,
  answer,
  item,
  collection
}: SummaryCardProps) => {
  const [openClose, setOpenClose] = React.useState(true)
  const multipleAlternatives = () => {
    let string = ''

    answer.alternatives.forEach((alternative, i) => {
      if (i === answer.alternatives?.length - 1) {
        string = `${string} ${alternative?.letter?.toUpperCase()}`
      } else if (i === answer.alternatives?.length - 2) {
        string = `${string} ${alternative?.letter?.toUpperCase()} e`
      } else {
        string = `${string} ${alternative?.letter?.toUpperCase()},`
      }
    })

    return string
  }
  const multipleCorrectAnswers = () => {
    let string = ''

    const answers = item?.answers || []

    answers.forEach((correctAnswer, i) => {
      if (i === item.answers?.length - 1) {
        string = `${string} ${correctAnswer?.letter?.toUpperCase()}`
      } else if (i === item.answers?.length - 2) {
        string = `${string} ${correctAnswer?.letter?.toUpperCase()} e`
      } else {
        string = `${string} ${correctAnswer?.letter?.toUpperCase()},`
      }
    })

    return string
  }

  const showCorrectAnswer = () =>
    item?.answers?.length > 0 ? multipleCorrectAnswers() : item?.answer
  return (
    <div className={className}>
      {!openClose ? (
        <div className="closed">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="item-id">Código da questão: </div>
            {answer.item.id}
          </div>
          <ArrowDropDownIcon
            onClick={() => setOpenClose(!openClose)}
            style={{ fontSize: '32px', cursor: 'pointer' }}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div className="open">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="item-id">Código da questão: </div>
              {answer.item.id}
            </div>
            <ArrowDropUpIcon
              onClick={() => setOpenClose(!openClose)}
              style={{ fontSize: '32px', cursor: 'pointer' }}
            />
          </div>
          <div className="open-container">
            <div className="container-note-and_answer">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <div className="title">Nota obtida:</div>
                  <div style={{ color: '#404040', marginLeft: '5px' }}>
                    {answer?.value || '-'}
                  </div>
                </div>
              </div>

              <div className="container_answer">
                <div className="student_answer">
                  <div className="title">Sua resposta:</div>
                  <div className="answer">
                    <Html>
                      {answer.alternative?.letter ||
                        answer?.freeResponse ||
                        multipleAlternatives()}
                    </Html>
                  </div>
                </div>
                <div className="teacher-comment">
                  <div className="title">Comentário do professor:</div>
                  <div className="answer">
                    <Html>{answer?.comments || '--'}</Html>
                  </div>
                </div>
              </div>
              {answer?.gradeLinear ? (
                <div style={{ marginTop: '1rem' }}>
                  <LinearScaleModal answer={answer} item={answer?.item} />
                </div>
              ) : null}
              <hr className="hrs" />
              <div className="correct-answer">
                <div className="correct-answe-title">Resposta correta:</div>
                <Html>{showCorrectAnswer()}</Html>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default styled(SummaryCard)`
  font-family: Arial, sans-serif;
  p {
    margin: 0;
  }
  .closed {
    width: 100%;
    height: 55px;
    box-shadow: 0px 2px 8.5px rgba(129, 129, 129, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f1f2;
    padding: 8px 24px;
    border-radius: 16px;
  }
  .open {
    width: 100%;
    height: 55px;
    box-shadow: 0px 2px 8.5px rgba(129, 129, 129, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f1f2;
    padding: 8px 24px;
    border-radius: 16px 16px 0px 0px;
  }
  .item-id {
    margin-right: 5px;
    font-weight: 700;
    color: #723df4;
  }
  .open-container {
    width: 100%;
    height: auto;
    padding: 24px 24px;
    border-radius: 0px 0px 16px 16px;
    box-shadow: 0px 2px 8.5px rgba(129, 129, 129, 0.25);
  }
  .container-note-and_answer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    align-items: flex-start;
    color: #4a4a4a;
  }
  .container_answer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
    margin-top: 10px;
  }
  .student_answer {
    max-width: 45%;
  }
  .teacher-comment {
    max-width: 45%;
  }
  .answer {
    text-align: justify;
    color: #4a4f58;
  }
  .hrs {
    margin-top: 24px;
    width: 100%;
    height: 0px;
    color: #e0e0e0;
    border: 1px solid #e0e0e0;
  }
  .correct-answer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: justify;
    margin-top: 24px;
  }
  .correct-answe-title {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    align-items: flex-start;
    color: #008262;
  }
`
