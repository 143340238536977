import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import humps from 'humps'
import { clearToken, getToken } from './auth'

export const configAuthorizationHeader = () => {
  // Adds/removes Authorization header
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = getToken()
    if (token) {
      config.headers.common.Authorization = `JWT ${token}`
    } else {
      delete config.headers.common.Authorization
    }

    return config
  })
}

configAuthorizationHeader()

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  // Converts incoming data from snake_case to camelCase
  config.transformResponse = [
    ...config.transformResponse,
    (data: any, headers: any) => {
      // Using startsWith instead of === because some clients (like Mockoon)
      // add charset=utf-8 to the header.
      if (!headers['content-type']?.startsWith('application/json')) {
        return data
      }
      return humps.camelizeKeys(data, (key, convert) => {
        // Keeps _ at start of string (EX: "_url")
        return key.charAt(0) === '_' ? '_' + convert(key) : convert(key)
      })
    }
  ]

  // Converts outgoing data from camelCase to snake_case
  config.transformRequest = [
    (data: any, headers: any) => {
      const contentType = headers['Content-Type']
      if (contentType && !contentType.includes('application/json')) {
        return data
      }
      return humps.decamelizeKeys(data)
    },
    ...config.transformRequest
  ]

  return config
})

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      clearToken()
      window.location.reload()
    }

    return Promise.reject(error)
  }
)
