import React, { useState, useContext, useEffect, useCallback } from 'react'
import Page from '../components/Page'
import { API_HOST, DEFAULT_LOGO } from '../consts'
import { useTranslation } from 'react-i18next'
import educatLogo from '../assets/img/educat.png'
import styled, { ThemeContext } from 'styled-components'
import Button from '../components/Button'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

interface ResetPasswordPage {
  className?: string
}

type ResetPasswordErrors = {
  password?: string[]
  confirmation?: string[]
}

const ResetPasswordSchema = yup.object().shape({
  confirmation: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'Confirmation must be equal to password'
    )
    .required()
})

const ResetPasswordPage: React.FC<ResetPasswordPage> = ({ className }) => {
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [errors, setErrors] = useState<ResetPasswordErrors>({})
  const [passwordChanged, setpasswordChanged] = useState(false)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const { token } = useParams<{ token: string }>()
  const history = useHistory()

  const validateToken = async (token: string) => {
    try {
      await axios.post(`${API_HOST}/reset_password/validate_token/`, { token })
      return true
    } catch (err) {
      return false
    }
  }

  const canAccess = useCallback(async () => {
    if (!token) history.replace('/login')
    const isTokenValid = await validateToken(token)
    if (!isTokenValid) history.replace('/reset-password-expired')
  }, [token, history])

  useEffect(() => {
    canAccess()
  }, [canAccess])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      await ResetPasswordSchema.validate({ password, confirmation })
      await axios.post(`${API_HOST}/reset_password/confirm/`, {
        password,
        token
      })
      setErrors({})
      setpasswordChanged(true)
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setErrors({
          password: [t(err.message)],
          confirmation: [t(err.message)]
        })
        return
      }
      if (err?.response?.status === 400) {
        setErrors(err.response.data)
        return
      }

      toast.error(t('An error occurred. Please try again.'))
    }
  }

  return (
    <Page className={className}>
      <img src={theme.logoImg || DEFAULT_LOGO || educatLogo} alt={theme.name} />
      <p>
        Digite sua senha, essa será utilizada para acessar o ambiente de provas
        e agendar seu pré-teste.
      </p>
      <form onSubmit={onSubmit}>
        <input
          required
          className={errors?.password ? 'input-error' : ''}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder={t('Password')}
        />
        {errors?.password && (
          <ul className="input-error-list">
            {errors.password.map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        )}
        <input
          required
          type="password"
          className={errors?.confirmation ? 'input-error' : ''}
          onChange={(e) => setConfirmation(e.target.value)}
          value={confirmation}
          placeholder={t('Confirm')}
        />
        {errors?.confirmation && (
          <ul className="input-error-list">
            {errors.password.map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        )}
        {!passwordChanged && (
          <>
            <Button action="primary" type="submit">
              {t('change password').toUpperCase()}
            </Button>
            <small>
              A sua senha deve ser uma combinação de letras e de números, com no
              mínimo 8 caracteres. Senhas muito comuns (EX: 123456) não serão
              aceitas.
            </small>
          </>
        )}
      </form>
      {passwordChanged && (
        <p>
          {t(
            'Password changed successfully, please access the platform with the new password.'
          )}
          <p>
            Clique aqui para agendar o seu{' '}
            <a href="https://agendamento.educat.net.br">pré-teste</a>
          </p>
        </p>
      )}
    </Page>
  )
}

export default styled(ResetPasswordPage)`
  margin-top: 5rem;
  text-align: center;

  img {
    margin-top: 100px;
    margin-bottom: 50px;
    max-height: 200px;
    max-width: 300px;
  }

  .input-error {
    color: #df4759;
  }

  .input-error-list {
    color: #df4759;
    margin-top: 0px;
    padding-left: 15px;
    text-align: left;
  }

  form {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 300px;
    justify-content: center;

    input {
      font-size: 16px;
      padding: 5px;
      line-height: 1.5;
      background-color: white;
      border: 2px solid ${(props) => props.theme.colors.graylight};
      border-radius: 5px;
    }
  }
`
