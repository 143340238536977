import styled from 'styled-components'
import Modal from '../Modal/ModalRootModal'
import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import ModalFooter from 'components/Modal/ModalFooter'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import MaskedInput from 'components/Inputs/MaskedInput'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import Checkbox from 'components/Inputs/Checkbox'
import QuestionMark from 'components/QuestionMark'
import Separator from './Separator'
import FilterValueGroup from 'components/DataDisplay/FilterValueGroup'
import FilterValue from 'components/DataDisplay/FilterValue'

interface SetTimeoutModalProps {
  onSubmit: (values: any) => Promise<any>
  className?: string
  toAllCandidates: boolean
  timeout?: string
  userName?: string
  examName: string
  filters?: string[]
  totalApplications?: number | undefined
  endTime?: string
  application?: number | string
  hideModal?: any
}

interface FormValues {
  newTime: number
  applyToCollection: boolean
  newEndDate: string
  newEndTime: string
}

const SetTimeoutModal = ({
  onSubmit,
  className,
  toAllCandidates,
  timeout,
  userName,
  examName,
  filters,
  totalApplications,
  endTime,
  hideModal
}: SetTimeoutModalProps) => {
  const { t } = useTranslation()

  const [time, setTime] = useState('')

  const parseHour = (time: string) => moment(time).format('HH:mm:ss')

  const initialValues: FormValues = {
    newTime: undefined,
    newEndTime: '',
    newEndDate: '',
    applyToCollection: false
  }

  useEffect(() => {
    if (!timeout) {
      setTime(endTime)
    } else {
      setTime(timeout)
    }
  }, [timeout, endTime])

  const sumValueBeforeSubmit = (value) => {
    const sumTime = moment(timeout).add(parseInt(value), 'minutes').format()

    const [, rightSide] = sumTime?.split('T')

    const onlyTime = rightSide?.split('-')[0]

    return onlyTime
  }

  const tryParseDate = (date: string) => {
    return moment(date, 'DD/MM/YYYY').isValid()
  }

  const tryParseHour = (hour: any) => {
    return moment(hour, 'HH:mm:ss').isValid()
  }

  const validateDate = (value: string) => {
    return tryParseDate(value)
  }

  const validateHour = (value: any) => {
    return tryParseHour(value)
  }

  const validate = (values: FormValues) => {
    const invalidMsg = t('Invalid format.')
    const errors: { [key: string]: string } = {}
    if (values.newEndDate && !validateDate(values.newEndDate)) {
      errors.newEndDate = invalidMsg
    }
    if (values.newEndTime && !validateHour(values.newEndTime)) {
      errors.newEndTime = invalidMsg
    }
    return errors
  }

  return (
    <>
      <Modal title={t('Extra Time Reset for Activity')}>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className={className}>
              <section
                className={toAllCandidates ? `min-height-top` : `top-wrapper`}
              >
                {toAllCandidates ? (
                  <span className="title-wrapper">
                    Atribua tempo extra a Atividade: <br /> <br />
                    <strong>{examName}</strong>
                  </span>
                ) : (
                  <span className="title-wrapper">
                    Atribua tempo extra a Atividade: <br /> <br />
                    <strong>{userName} </strong>
                    <strong>{examName}</strong>
                  </span>
                )}

                <div
                  className={
                    toAllCandidates ? `min-height-line` : `line-wrapper`
                  }
                >
                  <div className="input-wrapper">
                    <label>
                      Tempo extra <strong>(Em minutos)</strong> :{' '}
                    </label>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <MaskedInput
                        name="newTime"
                        mask="999"
                        placeholder={t('Minutes')}
                      />
                    </div>
                  </div>
                </div>
              </section>
              <hr style={{ border: '0.5px solid rgb(0,0,0,0.1)' }} />
              <section>
                <Grid
                  xs={12}
                  item
                  container
                  spacing={1}
                  style={{ margin: '1rem 0 1rem 0' }}
                >
                  <Grid item xs={12}>
                    <div className="input-wrapper">
                      <label>Nova data de término: </label>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <MaskedInput
                          name="newEndDate"
                          mask="99/99/9999"
                          className="masked-input"
                          placeholder={t('Date')}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="input-wrapper">
                      <label>Novo horário de término: </label>
                      <MaskedInput
                        name="newEndTime"
                        mask="99:99"
                        className="masked-input"
                        placeholder="Hora"
                      />
                    </div>
                  </Grid>
                </Grid>
              </section>

              <ModalFooter>
                <Button
                  className="button-add"
                  type="submit"
                  action="primary"
                  disabled={formik.isSubmitting}
                >
                  {t('Add')}
                </Button>
                <Button
                  className="button-cancel"
                  action="primary"
                  disabled={formik.isSubmitting}
                  onClick={() => hideModal()}
                >
                  {t('Cancel')}
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default styled(SetTimeoutModal)`
  .masked-input {
    display: flex;
    flex-direction: column;
  }
  .title-wrapper {
    width: 100%;
    text-align: start;
    margin-top: 24px;
  }

  .top-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .min-height-top {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .line-wrapper {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .min-height-line {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .column-wrapper {
    height: 70px;
    width: 300px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .button-add {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  }
  .button-cancel {
    background: #e4e4e4;
    border: none;
    color: #878787;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    margin-left: 30px;
  }
  .button-cancel:hover {
    background: #c5bfbf;
    border: none;
  }

  .label-wrapper {
    width: 220px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .input-wrapper {
    margin-right: 35px;
    width: 250px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;

    input {
      width: 100px;
      padding-left: 10px;
    }
  }
`
