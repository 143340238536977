import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import React from 'react'
import ItemTitle from './ItemTitle'
import styled from 'styled-components'
interface Props {
  data: string[]
  handleClick?: () => void
  handleDownload?: () => void
  title?: string
  className?: string
}

const CardInfo = ({
  data,
  handleClick,
  className,
  title,
  handleDownload
}: Props) => {
  return (
    <Box boxShadow={1} className={className}>
      <div className="title-wrapper">
        <ItemTitle>{title}</ItemTitle>
        <div>
          {handleClick && (
            <FontAwesomeIcon
              icon="print"
              className="icon"
              onClick={handleClick}
            />
          )}
        </div>
      </div>
      <Box className="info-wrapper" p={2}>
        {data.map((r, index) => (
          <p key={index}>{r}</p>
        ))}
      </Box>
    </Box>
  )
}

export default styled(CardInfo)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title-wrapper {
    margin: 0 10px;
    display: flex;
    justify-content: space-between;
  }

  .info-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 20px 15px 20px;
  }
`
