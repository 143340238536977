import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import React, { useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from './Button'

type CustomRemainingTimeProps = {
  className?: string
  secondsToTimeout: number | undefined
  initial: number | undefined
  title?: string
  onComplete?: () => undefined
  allowHideRemainingTime?: boolean
}

const CustomRemainingTime = ({
  className,
  initial,
  title,
  secondsToTimeout,
  allowHideRemainingTime = false,
  onComplete = () => undefined
}: CustomRemainingTimeProps) => {
  const [hide, setHide] = useState(false)

  const { t } = useTranslation()

  if (secondsToTimeout === undefined || !initial) {
    return (
      <span className={className}>
        <span>{t('Unavailable')}</span>
      </span>
    )
  }

  return (
    <Countdown
      date={initial + secondsToTimeout * 1000}
      onComplete={onComplete}
      renderer={({ ...time }) => {
        const secondsRemain = time.total / 1000

        const alertClass = secondsRemain <= 10
        const warningClass = secondsRemain <= 30

        const label = `${secondsRemain} ${t('seconds')}`

        return (
          <span className={className}>
            <span className="title">{title}</span>
            <span
              className={`${alertClass && 'alert'}${
                warningClass ? '-blink' : ''
              }`}
            >
              <p>{hide ? '-' : label}</p>
            </span>
            <Box marginTop="10px">
              {allowHideRemainingTime && (
                <Button action="icon" onClick={() => setHide(!hide)}>
                  <FontAwesomeIcon icon={hide ? 'eye' : 'eye-slash'} />
                </Button>
              )}
            </Box>
          </span>
        )
      }}
    />
  )
}

export default styled(CustomRemainingTime)`
  width: 90px;

  color: ${(props) => props.theme.colors.grayDark};

  .title {
    display: block;
  }

  p {
    margin-top: 3px;
  }

  .alert {
    color: red;

    &-blink {
      color: red;
      animation: blink 1s infinite;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  ${Button} {
    margin-left: 10px;
  }
  @media (min-width: 601px) and (max-width: 780px) {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 7px;
    }
    span {
      margin-top: 5px;
    }
  }

  @media (max-width: 600px) {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 7px;
    }
  }
`
