import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { API_HOST } from 'consts'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import ProfilePicture from './ProfilePicture'
import { Menu, MenuItem, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

type DropdownMenuExamProps = {
  user?: any
  application?: any
  askLogoutConfirmation?: any
  hasGroup?: any
  className?: string
  token?: any
}

const StyledMenuItem = withStyles(() => ({
  root: {
    marginTop: '0.6rem',
    color: 'rgb(114, 119, 128)',
    borderRadius: '8px',
    '&:active': {
      backgroundColor: '#eee4ff',
      borderRadius: '8px',
      color: '#8569c2'
    }
  }
}))(MenuItem)

const DropdownMenuExam = ({
  user,
  application,
  askLogoutConfirmation,
  hasGroup,
  className,
  token
}: DropdownMenuExamProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openDropdown = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const [questionSym, setQuestionSym] = React.useState<null | HTMLElement>(null)
  const dropdownQuestion = Boolean(questionSym)
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setQuestionSym(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setQuestionSym(null)
  }

  const groups =
    hasGroup('ADMINISTRADOR') ||
    hasGroup('COORDINATOR') ||
    hasGroup('PROCTOR') ||
    hasGroup('COMISSION')

  return (
    <Grid container className={className} direction="row">
      {/* {groups ? (
          <Button
            style={{
              border: 'none',
              background: 'transparent',
              marginLeft: '1rem'
            }}
            action="icon"
            onClick={() =>
              window.open(`${API_HOST}/v1/admin-redirect/${token}`)
            }
            data-testid="admin-button"
          >
            <FontAwesomeIcon icon="cog" />
          </Button>
        ) : (
          <></>
        )} */}

      {/* {application && (
        <Grid xs={9} className="name-application">
          <span>{application?.exam.name}</span>
        </Grid>
      )} */}

      <Grid item container xs={12} sm={10}>
        <Grid item container xs={4}>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <>
                <Button
                  style={{
                    background: 'transparent',
                    boxShadow: 'none',
                    fontSize: '20px',
                    marginTop: '3px'
                  }}
                  id="basic-button"
                  aria-haspopup="true"
                  onClick={handleSubmit}
                  {...bindTrigger(popupState)}
                >
                  <FontAwesomeIcon icon="bell" className="bell-icon" />
                </Button>
                <Menu
                  {...bindMenu(popupState)}
                  style={{ borderRadius: '16px' }}
                  PaperProps={{
                    style: {
                      textAlign: 'center',
                      width: '130px',
                      marginTop: '1.5rem',
                      borderRadius: '16px',
                      padding: '0.7rem'
                    }
                  }}
                >
                  <span style={{ fontSize: '14px' }}>0 notificações</span>
                </Menu>
              </>
            )}
          </PopupState>
        </Grid>

        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <Grid item container xs={4}>
              <React.Fragment>
                <Button
                  style={{
                    background: 'transparent',
                    boxShadow: 'none',
                    fontSize: '20px',
                    marginTop: '3px'
                  }}
                  id="basic-button"
                  aria-haspopup="true"
                  onClick={handleSubmit}
                  {...bindTrigger(popupState)}
                >
                  <FontAwesomeIcon
                    icon="question-circle"
                    className="question-icon"
                  />
                </Button>
                <Menu
                  {...bindMenu(popupState)}
                  style={{ borderRadius: '16px' }}
                  PaperProps={{
                    style: {
                      width: '150px',
                      marginTop: '1.5rem',
                      borderRadius: '16px',
                      padding: '0.5rem'
                    }
                  }}
                >
                  <span
                    style={{
                      textTransform: 'uppercase',
                      marginBottom: '0.5rem',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      fontFamily: 'Quicksand, sans-serif',
                      color: '#383838'
                    }}
                  >
                    AJUDA
                  </span>
                  {/*  <MenuItem onClick={popupState.close} style={{ marginTop: '5px' }}>
                <a
                    href="https://educat.freshdesk.com/support/login"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Quicksand, sans-serif',
                      textDecoration: 'none',
                      color: '#383838',
                    }}
                  >
                    Abrir chamado
                    <FontAwesomeIcon
                      className="icon"
                      style={{ marginLeft: '10px' }}
                      icon='external-link-alt'
                    />
                  </a>
                </MenuItem>*/}
                  <MenuItem onClick={popupState.close}>
                    <a
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Quicksand, sans-serif',
                        textDecoration: 'none',
                        color: '#383838',
                        marginLeft: '-15px'
                      }}
                      href="https://educat.com.br/faq/adaptativo"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      FAQ
                      <FontAwesomeIcon
                        style={{ fontSize: '14px', marginLeft: '10px' }}
                        icon="comment-dots"
                      />
                    </a>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            </Grid>
          )}
        </PopupState>

        <Grid item container xs={4}>
          <Button
            style={{ background: 'transparent', boxShadow: 'none' }}
            id="basic-button"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <div className="profile">
              {user && (
                <ProfilePicture className="profile-picture" user={user} />
              )}
              <FontAwesomeIcon icon="caret-down" />
            </div>
          </Button>
          <Menu
            PaperProps={{
              style: {
                // width: '304px',
                marginTop: '2rem',
                marginLeft: '1rem',
                borderRadius: '16px',
                padding: '0.7rem',
                fontSize: '14px'
              }
            }}
            aria-controls={openDropdown ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openDropdown ? 'true' : undefined}
            anchorEl={anchorEl}
            open={openDropdown}
            onClose={handleClose}
          >
            <span
              style={{
                textTransform: 'uppercase',
                marginBottom: '0.5rem',
                fontWeight: 'bold',
                fontSize: '12px',
                color: 'rgb(38, 41, 48)',
                marginLeft: '1rem'
              }}
            >
              Meu Perfil
            </span>
            <StyledMenuItem
              onClick={() =>
                window.open(
                  'https://conta.plurall.net/perfil/dados-pessoais',
                  '_self'
                )
              }
            >
              Dados pessoais e acesso
            </StyledMenuItem>
            {groups ? (
              <StyledMenuItem
                onClick={() =>
                  window.open(
                    'https://conta.plurall.net/perfil/dados-escolares',
                    '_self'
                  )
                }
              >
                Dados escolares
              </StyledMenuItem>
            ) : (
              ''
            )}
            {/* <StyledMenuItem
            onClick={() =>
              window.open(
                'https://conta.plurall.net/perfil/codigo-acesso',
                '_self'
              )
            }
          >
            Código de acesso
          </StyledMenuItem> */}
            <StyledMenuItem
              onClick={() =>
                window.open(
                  'https://conta.plurall.net/perfil/privacidade',
                  '_self'
                )
              }
            >
              Privacidade
            </StyledMenuItem>
            <hr
              style={{
                marginTop: '1.5em',
                opacity: '0.08',
                border: '1px solid rgb(38, 41, 48)',
                background: 'rgb(38, 41, 48)',
                width: '100%'
              }}
            />
            <StyledMenuItem onClick={askLogoutConfirmation}>
              Sair
            </StyledMenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default styled(DropdownMenuExam)`
  width: 100%;
  color: #4a4a4a;

  @media only screen and (max-width: 600px) {
    .name-application {
      display: none !important;
    }
  }

  .question-icon,
  .bell-icon {
    font-size: 22px;
    color: #4a4f58;
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.clientColors.primary};
    }
  }

  .profile {
    padding: 2px 6px 2px 2px;
    display: flex;
    align-items: center;
    .profile-picture {
      color: #4a4a4a;
      width: 32px;
      height: 32px;
      background: #ebe4fa !important;
      border: none;
      font-size: 14px;
    }

    &:hover {
      padding: 2px 6px 2px 2px;
      transition: all 0.2s;
      border-radius: 50px;
      background: #e3e5e8;
    }
  }

  .flex {
    display: flex;
    align-items: center;

    .name-application {
      font-weight: bold;
      color: #4a4a4a;
    }
  }
`
