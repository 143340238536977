class LogoutObservable {
  private observers: Function[]

  constructor() {
    this.observers = []
  }

  subscribe(fn: Function) {
    this.observers.push(fn)
  }

  unsubscribe(fn) {
    this.observers = this.observers.filter((observer) => observer !== fn)
  }

  notify() {
    this.observers.forEach((observer) => observer())
  }
}

export default new LogoutObservable()
