import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IAnswer, IApplication } from '../types'
import Button from './Button'
import CircledLink from './CircledLink'
import CircledSpan from './CircledSpan'
import RepertorizationModal from './RepertorizationModal'
import LinearScaleModal from './LinearScaleModal'

type AnswerReviewListItemProps = {
  className?: string
  answer: IAnswer
  application?: IApplication
  showItemId?: boolean
}

const AnswerReviewListItem = ({
  application,
  answer,
  showItemId,
  className
}: AnswerReviewListItemProps) => {
  const { t } = useTranslation()
  const { alternative, freeResponse, alternatives, item } = answer
  const configuration = useApplicationConfiguration(
    application?.exam?.collection?.applicationConfiguration
  )
  const [showRepertorizationModal, setShowRepertorizationModal] = useState(
    false
  )

  const checkAnswersPage =
    application?.exam?.collection?.showApplicationAfterTimeout &&
    application?.status === 'FINISHED'

  const shouldShowAnswerLink =
    !checkAnswersPage && configuration?.canBrowseAcrossItems

  let answerLabel = '--'

  if (alternative) {
    answerLabel = `${t('Alternative')}: ${alternative.letter.toUpperCase()}`
  } else if (freeResponse) {
    answerLabel = freeResponse
  } else if (alternatives?.length > 0) {
    let string = ''

    if (alternatives?.length === 1) {
      string = `${t('Alternative')}:`
    } else {
      string = `${t('Alternatives')}:`
    }

    alternatives.forEach((alternative, i) => {
      if (i === alternatives?.length - 1) {
        string = `${string} ${alternative?.letter?.toUpperCase()}`
      } else if (i === alternatives?.length - 2) {
        string = `${string} ${alternative?.letter?.toUpperCase()} e`
      } else {
        string = `${string} ${alternative?.letter?.toUpperCase()},`
      }
    })

    answerLabel = string
  }

  if (showItemId) {
    answerLabel = `${t('Code')} ${answer.item?.id} - ${answerLabel}`
  }

  return (
    <>
      <span className={className}>
        {shouldShowAnswerLink ? (
          <CircledLink
            to={`/applications/${application?.id}/answers/${answer.id}`}
          >
            {answer.position}
          </CircledLink>
        ) : (
          <CircledSpan>{answer.position}</CircledSpan>
        )}
        {answer.itemCategory === 'REPERTORIZATION_TABLE' ? (
          <Button onClick={() => setShowRepertorizationModal(true)}>
            {t('See repertorization table')}
          </Button>
        ) : answer.itemCategory === 'MULTIPLE_LINEAR_SCALE' ? (
          <LinearScaleModal answer={answer} item={item} />
        ) : answer.itemCategory === 'MULTIPLE_FREE_RESPONSE' ||
          answer.itemCategory === 'GAP_MULTIPLE_RESPONSE' ? (
          answerLabel
            .split(',')
            .slice(answerLabel.split(',').length * 0.5)
            .map((e) => {
              return <span key={e}>{e}</span>
            })
        ) : (
          <span>{answerLabel}</span>
        )}
      </span>
      <RepertorizationModal
        application={application}
        isOpen={showRepertorizationModal}
        onClose={() => setShowRepertorizationModal(false)}
      />
    </>
  )
}

export default styled(AnswerReviewListItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eeeeee;

  span {
    font-size: 13pt;
    font-weight: 400;
    text-align: left;
    margin-left: 10px;
  }
`
