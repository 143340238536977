import React, { useEffect, useCallback, useState } from 'react'
import styled from 'styled-components'
import QuestionGradedCard from '../components/StatisticsDashboardComponents/QuestionGradedCard'
import LightBorderCard from '../components/Card/LightBorderCard'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import axios from 'axios'
import { API_HOST } from 'consts'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import ItemStatsDisplay from 'components/StatisticsDashboardComponents/ItemStatsDisplay'
import DashboardApplicationDonutChart from 'components/StatisticsDashboardComponents/DashboardApplicationDonutChart'
import ExamInputSelect from 'components/Inputs/ExamInputSelect'
import { toast } from 'react-toastify'
import useQuery from 'hooks/useQuery'
import BulkQuestionGradedCard from 'components/StatisticsDashboardComponents/BulkQuestionGradedCard'

type DashboardStatisticsProps = {
  className?: string
}

type StatisticsItem = {
  id: number
  question: string
  category: string
  position: number
}

type ItemAnswerStats = {
  correct: number
  wrong: number
}

export type StatisticsResponse = {
  applications: {
    totalApplications: number
    unfinished: number
    finished: number
    missing: number
  }
  answers: {
    total: number
    correct: number
    wrong: number
    mostCorrect: {
      item: StatisticsItem
      stats: ItemAnswerStats
    }
    mostWrong: IMostWrong[]
    averageTimePerQuestion: number
  }
}
export interface IMostWrong {
  item: StatisticsItem
  stats: ItemAnswerStats
}
const DashboardStatistics = ({ className }: DashboardStatisticsProps) => {
  const { t } = useTranslation()
  const query = useQuery()

  const [loading, setLoading] = useState(false)
  const [examId, setExamId] = useState(undefined)
  const [statistics, setStatistics] = useState<StatisticsResponse>(null)

  const collectionId = +(query.get('collection') || '')

  const handleExamChange = (event) => {
    if (!event) {
      setExamId(null)
      setStatistics(null)
      return
    }

    setExamId(event.id)
  }

  const getFormattedTime = () => {
    if (!statistics) {
      return
    }

    return moment
      .unix(statistics.answers.averageTimePerQuestion)
      .utc()
      .format('HH:mm:ss')
  }

  const getQuestionIndex = () => {
    if (!statistics) {
      return
    }
    return statistics.answers.mostWrong
  }

  const getQuestionHitPercentageWrong = () => {
    if (!statistics) {
      return
    }

    return statistics.answers.mostWrong
  }

  const getQuestionIndexCorrect = (type: 'mostCorrect') => {
    if (!statistics) {
      return
    }
    return statistics.answers[type].item?.position
  }

  const getQuestionHitPercentageCorrect = (type: 'mostCorrect') => {
    if (!statistics) {
      return
    }

    const stats = statistics.answers[type].stats
    return stats && (stats.correct / (stats.correct + stats.wrong)) * 100
  }

  const fetch = useCallback(async () => {
    if (!examId) {
      return
    }
    setLoading(true)
    try {
      const response = await axios.get(
        `${API_HOST}/v1/exams/${examId}/statistics`
      )
      const stats = response.data as StatisticsResponse
      setStatistics(stats)
    } catch (e) {
      toast.error(t('An error occurred. Please try again.'))
    } finally {
      setLoading(false)
    }
  }, [examId, t])

  useEffect(() => {
    fetch()
  }, [fetch, examId])

  return (
    <div className={className}>
      <div className="filter-wrapper">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <ExamInputSelect
            collectionId={collectionId}
            onChange={handleExamChange}
            label={t('Exam')}
            usePreSelect={true}
          />
        </Formik>
      </div>
      {loading && <Skeleton height={300} />}
      {!loading && statistics && (
        <div className="chart-parent">
          <DashboardApplicationDonutChart examsStatistics={statistics} />
          <div className="square-grid">
            <LightBorderCard title={t('Average time per question')}>
              <p className="time">{getFormattedTime()}</p>
            </LightBorderCard>
            <QuestionGradedCard
              success
              percentage={getQuestionHitPercentageCorrect('mostCorrect')}
              questionIndex={getQuestionIndexCorrect('mostCorrect')}
            />
            <LightBorderCard title={t('Success rate per question')}>
              <ItemStatsDisplay selectedExamId={examId}></ItemStatsDisplay>
            </LightBorderCard>
            <BulkQuestionGradedCard
              percentage={getQuestionHitPercentageWrong()}
              questionIndex={getQuestionIndex()}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default styled(DashboardStatistics)`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 1rem;

  .square-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-left: 1rem;
  }

  .progress {
    margin-top: 1rem;
    width: 100%;
  }

  .chart-parent {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .time {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.94px;
    color: #606060;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;

    .square-grid {
      grid-template-columns: 1fr;
    }
  }
`
