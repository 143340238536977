import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import swal from 'sweetalert2'
import { AnswerContext } from '../states/AnswerState'
import { ApplicationContext } from '../states/ApplicationState'
import { IAnswer } from '../types'
import Button from './Button'
import ReviewButton from './Buttons/ReviewButton'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import {
  dismissMessage,
  messageToCandidateAboutYourBehavior,
  messageToProctorAboutCandidateBehavior
} from '../data/apis/message-exchange'
import { AuthContext } from 'states/AuthState'
import ObserveCandidateBehavior from './CandidateBehavior/observeCandidateBehavior'
import Axios from 'axios'
import { API_HOST, FIREBASE_ENABLED } from 'consts'
import FirebaseSuspectResponse from 'utils/firebaseSuspectResponse'
import db from '../db'
import Attachment from './Attachment'
import { toast } from 'react-toastify'
import { findNextAvaiableAnswer } from 'data/utils/answers'
import { useHistory } from 'react-router-dom'

type ChangeAnswerButtonsProps = {
  className: string
  canPassToNextAnswer: boolean
}

const ChangeAnswerButtons = ({
  className,
  canPassToNextAnswer
}: Partial<ChangeAnswerButtonsProps>) => {
  const {
    answer,
    goAnswer,
    getAnswerFromPosition,
    isAnswered,
    displayWarningModal,
    generateIncidentAutomatically,
    handleFinishAnswer,
    handleAnswerData,
    item
  } = useContext(AnswerContext)
  const { badgeMessages } = useContext(AnswerContext)
  const { application } = useContext(ApplicationContext)
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const configuration = useApplicationConfiguration(
    application?.exam.collection.applicationConfiguration
  )
  const position = answer?.position
  const applicationId = application?.id
  const history = useHistory()

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const previousAnswer = answer
    ? getAnswerFromPosition(answer.position - 1)
    : undefined

  const [nextAnswer, setNextAnswer] = useState(undefined)
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  useEffect(() => {
    if (applicationId && configuration && position) {
      findNextAvaiableAnswer(applicationId, configuration, position).then(
        setNextAnswer
      )
    }
  }, [answer, applicationId, configuration, position])

  const handleRedirect = async (newAnswer: IAnswer) => {
    if (!newAnswer) {
      return
    }
    if (configuration?.canBrowseAcrossItems === false) {
      const warningHasBeenDismissed = JSON.parse(
        window.localStorage.getItem('showquestionwarning')
      )
      if (!warningHasBeenDismissed) {
        displayWarningModal(nextAnswer)
        return
      }
    }

    handleFinishAnswer(answer)

    if (application?.exam?.adaptative) {
      const response = await Axios.post(
        `${API_HOST}/v1/applications/${applicationId}/adaptative_next_item`,
        { answer_id: answer?.id }
      )
      const answerId = response.data
      if (answerId) {
        console.log('answerId:', answerId)
        history.push(`/applications/${application?.id}/answers/${answerId}`)
      }
    } else {
      goAnswer(newAnswer)
    }
  }

  const clearBadgeMessages = () => {
    badgeMessages?.length &&
      badgeMessages.map((message) => {
        return dismissMessage(application.roomId.toString(), message.id)
      })
  }

  const checkCandidateBehavior = async (value: number) => {
    generateIncidentAutomatically(
      `${user.name} está com um comportamento suspeito, fique atento`,
      application?.roomId,
      application?.user?.id
    )

    messageToCandidateAboutYourBehavior(
      application?.roomId?.toString(),
      application?.user?.id?.toString(),
      value,
      application.exam?.alertConfiguration?.title
    )

    messageToProctorAboutCandidateBehavior(
      application?.roomId?.toString(),
      application?.user?.id?.toString(),
      'Este candidato está respondendo os objetos de aprendizagem rapidamente, chame seu itinerante',
      value
    )

    await Axios.post(`${API_HOST}/v1/save_alert`, {
      alert_type: 1,
      message: `O candidato ${application?.user?.name} está respondendo os objetos de aprendizagem rapidamente`,
      user: application?.user?.id,
      collection_id: application?.exam?.collection?.id,
      exam_id: application?.exam?.id
    })
  }

  const handleCandidateBehavior = async () => {
    const currentAnswer = await db.answers.where({ id: +answer.id }).first()
    const currentItem = await db.items.where({ id: +answer.item.id }).first()

    const observeCandidateBehavior = () => {
      const singleton = ObserveCandidateBehavior.getInstance()
      const firebase = new FirebaseSuspectResponse(application.id.toString())
      singleton.initialize(application, currentAnswer, firebase, currentItem)
      return singleton
    }
    if (FIREBASE_ENABLED) {
      const currentSuspectResponse = await observeCandidateBehavior().getCurrentSuspectResponse()

      if (await observeCandidateBehavior().verifyCandidateAnswerTime()) {
        await checkCandidateBehavior(currentSuspectResponse)
      }
    }
  }

  const handleNextAnswer = async () => {
    if (application?.exam?.adaptative) {
      setIsLoadingBtn(true)
      await Axios.post(
        `${API_HOST}/v1/applications/${applicationId}/adaptative_next_item`,
        { answer_id: answer?.id }
      )
        .then((res) => {
          setIsLoadingBtn(false)
          const answerId = res?.data
          if (answerId) {
            history.push(`/applications/${application?.id}/answers/${answerId}`)
          }
        })
        .catch((err) => {
          setIsLoadingBtn(false)
          console.error(err)
        })
    } else {
      if (!canPassToNextAnswer) {
        return swal.fire({
          icon: 'warning',
          title: 'Confira o recebimento da mensagem'
        })
      }

      clearBadgeMessages()

      if (isLoading) {
        return swal.fire({
          icon: 'warning',
          title: t('Please wait loading image')
        })
      }

      // NAO VAI FICAR ASSIM, FOI FEITO PARA A APRESENTACAO DO PLURALL (26/07/2022)
      if (
        answer &&
        !isAnswered(answer) &&
        !configuration?.canBrowseAcrossItems &&
        item.category !== 'REPERTORIZATION_TABLE' &&
        item.category !== 'GAP_MULTIPLE_RESPONSE' &&
        item.category !== 'VIDEO' &&
        item.category !== 'IMAGEM' &&
        item.category !== 'CONCEITO' &&
        item.category !== 'TABELA_EDITAVEL' &&
        item.category !== 'MULTIPLE_LINEAR_SCALE' &&
        item.category !== 'FLASH_CARD'
      ) {
        swal.fire({
          icon: 'warning',
          title: t('Please answer the question to go to the next item')
        })
      } else {
        if (nextAnswer) {
          handleCandidateBehavior()
          handleAnswerData()
            .then(() => handleRedirect(nextAnswer))
            .catch(() => toast.error(t('Your answer has not been save')))
          setFiles([])
        }
      }
    }
  }

  const getFiles = (file: any) => {
    setFiles([...files, file])
  }

  const toggleIsLoading = (value: boolean) => {
    setIsLoading(value)
  }

  return (
    <div className={className}>
      {/*
        Divs here are necessary to always render previous button on the left
        and next button on the right
      */}
      <div>
        {configuration?.canBrowseAcrossItems &&
        !application?.exam?.adaptative ? (
          <Button
            action="primary"
            type="button"
            onClick={() => {
              handleRedirect(previousAnswer)
            }}
            style={{
              visibility: previousAnswer ? 'visible' : 'hidden',
              borderRadius: '100px',
              boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.25)'
            }}
          >
            {t('previous')}
          </Button>
        ) : (
          ''
        )}
      </div>
      <div>
        <div className="wrapper-button">
          {item?.hasAttachment && (
            <Attachment
              user={user?.id}
              answer={answer?.id}
              application={application?.id}
              getFiles={getFiles}
              files={files}
              toggleIsLoading={toggleIsLoading}
            />
          )}
          <Button
            action="primary"
            type="button"
            isLoading={isLoadingBtn}
            onClick={() => {
              ;(nextAnswer || application?.exam?.adaptative) &&
                handleNextAnswer()
            }}
            style={{
              visibility:
                nextAnswer || application?.exam?.adaptative
                  ? 'visible'
                  : 'hidden',
              display: nextAnswer || application?.exam?.adaptative ? '' : 'none'
            }}
          >
            {t('Next(a)', { context: 'fs' })}
          </Button>
        </div>
        {/* {console.log()} */}
        <ReviewButton
          buttonLabel={t('Finish')}
          style={{
            display: !nextAnswer && !application?.exam?.adaptative ? '' : 'none'
          }}
          canNavigateOnExamItems={false}
          callback={handleFinishAnswer}
          typeAnswer={application?.exam?.type}
        />
      </div>
    </div>
  )
}

export default styled(ChangeAnswerButtons)`
  display: flex;
  justify-content: space-between;

  .wrapper-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
