import { FIREBASE_ENABLED } from 'consts'
import {
  AlertIn,
  BreakRequestIn,
  PauseApplicationIn,
  ResumeApplicationIn,
  StatusChangeIn,
  FaceDetectionAlert,
  CurrentSituationIn,
  CurrentSituation,
  AnswerIn,
  ItemAccessIn,
  Connection
} from 'data/domain/message-exchange'

import firebase, { db } from 'infra/firebase'
import { Event, FirebaseEvent, LookDirectionType } from 'types'

export const createStatusChange = (
  roomId: string,
  candidateId: string,
  statusChangeIn: StatusChangeIn
) => {
  if (FIREBASE_ENABLED) {
    return db
      .collection('rooms')
      .doc(roomId)
      .collection('statusChanges')
      .add({
        ...statusChangeIn,
        candidateId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
  }
}

export const createMessage = async (
  roomId: string,
  candidateId: string,
  alert: AlertIn,
  shouldCreateStatusChange = true
) => {
  if (shouldCreateStatusChange || FIREBASE_ENABLED) {
    await createStatusChange(roomId, candidateId, {
      motive: alert.motive ? alert.motive : 'MESSAGE',
      dismissible: true,
      message: alert.message,
      status: 'NOT_CHANGED',
      author: alert.author,
      reason: alert.reason || null,
      time: alert.time || null
    })
  }

  return db
    .collection('rooms')
    .doc(roomId)
    .collection('messages')
    .add({
      ...alert,
      candidateId,
      dismissed: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    })
}

const getMaxEndDate = (maxDuration: number): Date | null => {
  let maxEndDate = null
  if (maxDuration) {
    maxEndDate = new Date()
    maxEndDate.setSeconds(maxEndDate.getSeconds() + maxDuration)
  }
  return maxEndDate
}

export const getPauseApplicationBatch = (
  roomId: string,
  candidateId: string,
  pauseApplicationIn: PauseApplicationIn
) => {
  if (FIREBASE_ENABLED) {
    const batch = db.batch()

    const statusChangeDoc = db
      .collection('rooms')
      .doc(roomId)
      .collection('statusChanges')
      .doc()
    batch.set(statusChangeDoc, {
      status: 'PAUSED',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      candidateId,
      ...pauseApplicationIn
    })

    const candidateDoc = db
      .collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
    batch.set(
      candidateDoc,
      {
        pause: {
          maxEndDate: getMaxEndDate(pauseApplicationIn.maxDurationInSeconds),
          status: 'PAUSED',
          message: pauseApplicationIn.message || null,
          dismissible: pauseApplicationIn.dismissible,
          motive: pauseApplicationIn.motive,
          showMessage: pauseApplicationIn.showMessage
        }
      },
      { merge: true }
    )

    return batch
  }
}

export const pauseApplication = (
  roomId: string,
  candidateId: string,
  pauseApplicationIn: PauseApplicationIn
) => {
  return getPauseApplicationBatch(
    roomId,
    candidateId,
    pauseApplicationIn
  ).commit()
}

export const resumeApplication = async (
  roomId: string,
  candidateId: string,
  resumeApplicationIn: ResumeApplicationIn
) => {
  if (FIREBASE_ENABLED) {
    const batch = db.batch()

    const statusChangeDoc = db
      .collection('rooms')
      .doc(roomId)
      .collection('statusChanges')
      .doc()
    batch.set(statusChangeDoc, {
      status: 'RESUMED',
      candidateId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      ...resumeApplicationIn
    })

    const candidateDoc = db
      .collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
    batch.set(
      candidateDoc,
      {
        pause: null
      },
      { merge: true }
    )

    return batch.commit()
  }
}

export const getCandidates = (roomId: string) => {
  return db.collection('rooms').doc(roomId).collection('candidates')
}

export const getCandidate = (roomId: string, candidateId: string) => {
  if (FIREBASE_ENABLED) {
    return db
      .collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
  }
}

export const createCandidate = (roomId: string, candidateId: string) => {
  if (FIREBASE_ENABLED) {
    db.collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
      .set({}, { merge: true })
  }
}

export const incrementApplicationCounter = (
  roomId: string,
  candidateId: string,
  isInApplication: boolean
) => {
  if (FIREBASE_ENABLED) {
    db.collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
      .set(
        {
          applicationCounter: firebase.firestore.FieldValue.increment(1),
          isInApplication: isInApplication
        },
        { merge: true }
      )
  }
}

export const setApplicationState = (
  roomId: string,
  candidateId: string,
  isInApplication: boolean
) => {
  if (FIREBASE_ENABLED) {
    db.collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
      .set(
        {
          isInApplication: isInApplication
        },
        { merge: true }
      )
  }
}

export const setStartApplication = (
  roomId: string,
  candidateId: string,
  isInApplication: boolean
) => {
  if (FIREBASE_ENABLED) {
    db.collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
      .set(
        {
          applicationCounter: firebase.firestore.FieldValue.increment(1),
          isInApplication: isInApplication
        },
        { merge: true }
      )
  }
}

export const setCountApplications = (
  roomId: string,
  candidateId: string,
  totalApplications: number
) => {
  if (FIREBASE_ENABLED) {
    db.collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
      .set(
        {
          totalApplications
        },
        { merge: true }
      )
  }
}

export const clearApplicationCounter = (
  roomId: string,
  candidateId: string
) => {
  if (FIREBASE_ENABLED) {
    db.collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
      .set(
        {
          applicationCounter: 0
        },
        { merge: true }
      )
  }
}

export const getCurrentSituation = async (candidateId: string) => {
  const result = await db.collection('currentSituations').doc(candidateId).get()
  return result.data() as CurrentSituation
}

export const getInternetMeasurementDuringApplication = async (
  candidateId: string,
  startDate: string,
  endDate: string
) => {
  if (FIREBASE_ENABLED) {
    const data = await db
      .collection('connection')
      .doc(candidateId)
      .collection('measures')
      .where('when', '>', startDate)
      .where('when', '<', endDate || new Date().toISOString())
      .get()

    const results = []
    data.docs.forEach((doc) => {
      results.push({ ...doc.data(), when: new Date(doc.data().when) })
    })
    return results as Connection[]
  }
}

// export const getInternetRecords = async () => {
//     let result = [];
//     const records = ["1","1002","1039","1003","1039","1040"].map(async (number) => {
//     const data = await db.collection('connection').doc(number).collection("measures").get()
//       data.docs.forEach(doc => result.push(doc.data()))

//     })
//     await Promise.all(records);

//   let delayAverageSum = result.reduce((sum, item ) => {
//     sum = sum || 0;
//     return sum += item.measuredDelay
//   }, 0)

//   let totalDelayAverage = delayAverageSum / result.length;

//   return {
//     totalDelayAverage,
//     totalRecords:result.length,
//     results:result
//     }

// }

export const updateCurrentSituation = (
  candidateId: string,
  currentSituationIn: CurrentSituationIn
) => {
  if (FIREBASE_ENABLED) {
    return db.doc(`currentSituations/${candidateId}`).set(
      {
        ...currentSituationIn,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      { merge: true }
    )
  }
}

export const clearCurrentSituation = (candidateId: string) => {
  if (FIREBASE_ENABLED) {
    return db.doc(`currentSituations/${candidateId}`).set(
      {
        examName: null,
        itemId: null,
        itemPosition: null,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      { merge: true }
    )
  }
}

export const getNotAnsweredBreakRequests = (roomId: string) => {
  if (FIREBASE_ENABLED) {
    return db
      .collection('rooms')
      .doc(roomId)
      .collection('breakRequests')
      .orderBy('createdAt', 'asc')
      .where('dismissed', '==', false)
  }
}

export const getNotSeenRoomMessages = (roomId: string) => {
  if (FIREBASE_ENABLED) {
    return db
      .collection('rooms')
      .doc(roomId)
      .collection('messages')
      .orderBy('createdAt', 'asc')
      .where('dismissed', '==', false)
      .where('author', '==', 'CANDIDATE')
  }
}

export const getNotSeenCandidateMessages = (
  roomId: string,
  candidateId: string
) => {
  if (FIREBASE_ENABLED) {
    return db
      .collection('rooms')
      .doc(roomId)
      .collection('messages')
      .orderBy('createdAt', 'asc')
      .where('candidateId', '==', candidateId)
      .where('dismissed', '==', false)
      .where('author', '==', 'PROCTOR')
  }
}

export const getCandidatePendingBreakRequest = (
  roomId: string,
  candidateId: string
) => {
  if (FIREBASE_ENABLED) {
    return db
      .collection('rooms')
      .doc(roomId)
      .collection('breakRequests')
      .where('candidateId', '==', candidateId)
      .where('pending', '==', true)
      .limit(1)
  }
}

export const getCandidateNotAnsweredBreakRequests = (
  roomId: string,
  candidateId: string
) => {
  if (FIREBASE_ENABLED) {
    return db
      .collection('rooms')
      .doc(roomId)
      .collection('breakRequests')
      .where('candidateId', '==', candidateId)
      .where('dismissed', '==', false)
      .limit(1)
  }
}

export const dismissMessage = (roomId: string, messageId: string) => {
  if (FIREBASE_ENABLED) {
    return db
      .collection('rooms')
      .doc(roomId)
      .collection('messages')
      .doc(messageId)
      .update({
        dismissed: true
      })
  }
}

export const answerBreakRequest = async (
  roomId: string,
  breakRequestId: string,
  approved: boolean,
  maxDuration: number,
  candidateId: string
) => {
  if (FIREBASE_ENABLED) {
    await createStatusChange(roomId, candidateId, {
      status: 'NOT_CHANGED',
      author: 'PROCTOR',
      dismissible: true,
      motive: approved ? 'ACCEPT_BREAK' : 'REFUSE_BREAK'
    })

    return db
      .collection('rooms')
      .doc(roomId)
      .collection('breakRequests')
      .doc(breakRequestId)
      .update({
        approved,
        dismissed: true,
        pending: approved,
        maxDuration
      })
  }
}

export const answerNursingBreakRequest = async (
  roomId: string,
  breakRequestId: string,
  approved: boolean,
  candidateId: string
) => {
  if (FIREBASE_ENABLED) {
    await createStatusChange(roomId, candidateId, {
      status: 'NOT_CHANGED',
      author: 'PROCTOR',
      dismissible: true,
      motive: approved ? 'ACCEPT_NURSING_BREAK' : 'REFUSE_NURSING_BREAK'
    })

    return db
      .collection('rooms')
      .doc(roomId)
      .collection('breakRequests')
      .doc(breakRequestId)
      .update({
        approved,
        dismissed: true,
        pending: approved
      })
  }
}

export const createBreakRequest = async (
  roomId: string,
  candidateId: string,
  breakRequestIn: BreakRequestIn
) => {
  if (FIREBASE_ENABLED) {
    await createStatusChange(roomId, candidateId, {
      status: 'NOT_CHANGED',
      author: 'CANDIDATE',
      dismissible: true,
      motive: 'BREAK'
    })

    return db
      .collection('rooms')
      .doc(roomId)
      .collection('breakRequests')
      .add({
        ...breakRequestIn,
        candidateId,
        dismissed: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
  }
}

export const createNursingBreakRequest = async (
  roomId: string,
  candidateId: string,
  breakRequestIn: BreakRequestIn
) => {
  if (FIREBASE_ENABLED) {
    await createStatusChange(roomId, candidateId, {
      status: 'NOT_CHANGED',
      author: 'CANDIDATE',
      dismissible: true,
      motive: 'NURSING_BREAK'
    })

    return db
      .collection('rooms')
      .doc(roomId)
      .collection('breakRequests')
      .add({
        ...breakRequestIn,
        candidateId,
        dismissed: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
  }
}

export const startBreak = (
  roomId: string,
  candidateId,
  breakRequestId: string,
  maxDurationInSeconds: number
) => {
  if (FIREBASE_ENABLED) {
    const batch = getPauseApplicationBatch(roomId, candidateId, {
      motive: 'BREAK_START',
      message: 'Por favor, retorne dentro do prazo abaixo.',
      dismissible: true,
      maxDurationInSeconds: maxDurationInSeconds || 60 * 5,
      showMessage: true
    })

    const breakRequestDoc = db
      .collection('rooms')
      .doc(roomId)
      .collection('breakRequests')
      .doc(breakRequestId)
    batch.update(breakRequestDoc, { pending: false })

    return batch.commit()
  }
}

export const startNursingBreak = (
  roomId: string,
  candidateId,
  breakRequestId: string
) => {
  if (FIREBASE_ENABLED) {
    const batch = getPauseApplicationBatch(roomId, candidateId, {
      motive: 'NURSING_BREAK_START',
      message:
        'Seu pedido de pausa para amamentação foi aceito. Pode se ausentar da sala e, quando retornar, clique no botão abaixo e retome a atividade.',
      dismissible: true,
      maxDurationInSeconds: null,
      showMessage: true
    })

    const breakRequestDoc = db
      .collection('rooms')
      .doc(roomId)
      .collection('breakRequests')
      .doc(breakRequestId)
    batch.update(breakRequestDoc, { pending: false })

    return batch.commit()
  }
}

export const getHistory = async (roomId: string, candidateId) => {
  if (FIREBASE_ENABLED) {
    const data = await db
      .collection('rooms')
      .doc(roomId)
      .collection('statusChanges')
      .where('candidateId', '==', candidateId)
      .get()

    const result = []
    data.forEach((doc) => {
      const d = doc.data()
      const nonUtcTimestamp = d.createdAt
        ? new firebase.firestore.Timestamp(
            d.createdAt.seconds,
            d.createdAt.nanoseconds
          ).toDate()
        : new Date()
      result.push({
        timestamp: nonUtcTimestamp,
        status: d.status,
        motive: d.motive,
        author: d.author,
        message: d.message,
        reason: d.reason
      })
    })
    return result.sort((prev, next) => {
      return next.timestamp - prev.timestamp
    })
  }
}

export const createFaceDetectionAlert = async (
  roomId: string,
  candidateId: string,
  message: LookDirectionType
) => {
  if (FIREBASE_ENABLED) {
    const faceDetectionAlert: FaceDetectionAlert = {
      message,
      candidateId,
      dismissed: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }

    const shouldCreateAlert = message !== ''

    try {
      const candidateSnapShot = await getCandidate(roomId, candidateId).get()
      const candidate = candidateSnapShot.data()

      const shouldUpdateCandidate =
        candidate?.faceDetectionAlert?.message !== message

      if (shouldUpdateCandidate) {
        db.collection('rooms')
          .doc(roomId)
          .collection('candidates')
          .doc(candidateId)
          .set(
            {
              faceDetectionAlert
            },
            { merge: true }
          )
      }
    } catch (e) {}

    if (shouldCreateAlert) {
      db.collection('rooms')
        .doc(roomId)
        .collection('faceDetectionAlerts')
        .add(faceDetectionAlert)
    }
  }
}

export const createItemAccess = (
  roomId: string,
  candidateId: string,
  applicationId: string,
  examName: string,
  itemAccessIn: ItemAccessIn,
  isInInstructions = false
) => {
  if (FIREBASE_ENABLED) {
    const batch = db.batch()

    const itemAccessDoc = db
      .collection(
        `rooms/${roomId}/candidates/${candidateId}/applications/${applicationId}/itemAccess`
      )
      .doc()
    batch.set(itemAccessDoc, {
      ...itemAccessIn,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    })

    const currentSituationDoc = db.doc(`currentSituations/${candidateId}`)
    batch.set(
      currentSituationDoc,
      {
        applicationId: applicationId,
        examName: examName,
        itemId: itemAccessIn.itemId,
        itemPosition: itemAccessIn.position,
        isInInstructions,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      { merge: true }
    )

    return batch.commit()
  }
}

export const createAnswer = async (
  roomId: string,
  candidateId: string,
  applicationId: string,
  answerIn: AnswerIn
) => {
  if (FIREBASE_ENABLED) {
    await db
      .collection(
        `rooms/${roomId}/candidates/${candidateId}/applications/${applicationId}/answers`
      )
      .add({
        ...answerIn,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })

    const applicationDocRef = db.doc(
      `rooms/${roomId}/candidates/${candidateId}/applications/${applicationId}`
    )
    const { itemId, letter } = answerIn
    return db.runTransaction(async (transaction) => {
      const doc = await transaction.get(applicationDocRef)
      const data = doc.data()
      transaction.set(applicationDocRef, {
        answers: { ...data?.answers, [itemId]: letter }
      })
    })
  }
}

export const createBulkAnswer = async (
  roomId: string,
  candidateId: string,
  applicationId: string,
  answerIn: AnswerIn[]
) => {
  if (FIREBASE_ENABLED) {
    db.collection(
      `rooms/${roomId}/candidates/${candidateId}/applications/${applicationId}/answers`
    ).add({
      answerArray: answerIn,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    })

    const applicationDocRef = db.doc(
      `rooms/${roomId}/candidates/${candidateId}/applications/${applicationId}`
    )
    db.runTransaction(async (transaction) => {
      const doc = await transaction.get(applicationDocRef)
      const data = doc.data()
      transaction.set(applicationDocRef, {
        answers: { ...data?.answerArray, answerIn }
      })
    })
  }
}

export const getApplication = async (
  roomId: string,
  candidateId: string,
  applicationId: string
) => {
  if (FIREBASE_ENABLED) {
    return db.doc(
      `rooms/${roomId}/candidates/${candidateId}/applications/${applicationId}`
    )
  }
}

export const setCameraAccepted = (
  roomId: number | string,
  candidateId: number | string,
  accepted?: boolean
) => {
  if (FIREBASE_ENABLED) {
    return db.doc(`rooms/${roomId}/candidates/${candidateId}`).set(
      {
        hasAcceptedCamera: accepted || false
      },
      { merge: true }
    )
  }
}

export const messageToProctorAboutCandidateBehavior = async (
  roomId: string,
  authorId: string,
  messageTitle: string,
  numberOfQuestions: number
) => {
  if (FIREBASE_ENABLED) {
    try {
      await createMessage(roomId, authorId, {
        message: messageTitle,
        authorId: authorId,
        dismissible: true,
        notificationType: 'BADGE',
        author: 'CANDIDATE',
        motive: 'IMPROPER_BEHAVIOR',
        reason: `${numberOfQuestions} questões respondidas em um tempo menor que o sugerido`
      })
    } catch {}
  }
}

export const messageToCandidateAboutYourBehavior = async (
  roomId: string,
  authorId: string,
  numberOfQuestions: number,
  text: string
) => {
  if (FIREBASE_ENABLED) {
    try {
      await createMessage(roomId, authorId, {
        message: 'Comportamento suspeito por parte do candidato',
        text,
        authorId: authorId,
        dismissible: true,
        notificationType: 'BADGE',
        author: 'PROCTOR',
        motive: 'IMPROPER_BEHAVIOR',
        reason: `${numberOfQuestions} questões respondidas em um tempo menor que o sugerido`
      })
    } catch {}
  }
}

export const getLoginEvents = async (
  candidateId: string,
  applicationStart: Date
) => {
  if (FIREBASE_ENABLED) {
    const startDate = new Date(applicationStart)
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setMilliseconds(0)
    startDate.setSeconds(0)

    const endDate = new Date(applicationStart)
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setMilliseconds(999)
    endDate.setSeconds(59)

    const data = await db
      .collection('events')
      .where('userId', '==', candidateId)
      .where('when', '>=', startDate)
      .where('when', '<=', endDate)
      .orderBy('when', 'asc')
      .get()

    const results = []
    data.docs.forEach((doc) => {
      const docData = doc.data() as FirebaseEvent
      const shapedDoc: Event = { ...docData, when: docData.when.toDate() }
      results.push(shapedDoc)
    })
    return results as Event[]
  }
}
