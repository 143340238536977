import React, { useState } from 'react'
import axios from 'axios'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { API_HOST } from '../consts'
import ImportBase from '../components/Imports/ImportBase'
import styled from 'styled-components'
import icone from '../assets/img/importImg.png'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { toast } from 'react-toastify'
import { theme } from 'highcharts'
import classNames from 'classnames'

type ImportVectorPageProps = {
  className?: string
}

const ImportVectoresPage = ({ className }: ImportVectorPageProps) => {
  const { t } = useTranslation()
  const [file, setFile] = useState(new Blob())
  const [errors] = useState([])
  const [successMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const importVector = (data) => {
    axios
      .post(`${API_HOST}/v1/exams/import_vectors`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          toast.success('Importação dos vetores concluída com sucesso!')
        }
      })
      .catch((err) => {
        toast.error(
          'Ocorreu um erro no processo de importação dos vetores, tente novamente.'
        )
        setLoading(false)
        console.error(err)
      })
  }

  const handleSubmit = () => {
    setLoading(true)
    if (file !== undefined) {
      if (file.type === 'text/csv') {
        const formData = new FormData()
        formData.append('file', file)
        importVector(formData)
      } else {
        setLoading(false)
        toast.error(
          'Não é permitido o uso de outros formatos de arquivos, utilize somente a extensão .csv'
        )
      }
    } else {
      setLoading(false)
      toast.error(
        'É necessário o anexo de um arquivo .csv, para realizar a importação.'
      )
    }
  }

  const instructions = (
    <span>{t('Receive a .csv file with the content of the exam.')}</span>
  )

  return (
    <Formik initialValues={{ updateIfExists: false }} onSubmit={handleSubmit}>
      {(formik) => (
        <div
          className={className}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              width: '45%',
              display: 'flex',
              flexDirection: 'row',
              borderRadius: '16px',
              boxShadow: '0px 1px 4px rgba(58, 58, 58, 0.25)'
            }}
          >
            <div
              className="icon-styled"
              // style={{
              //   margin: '28px 0 0 28px',
              //   borderRadius: '100%',
              //   width: '120px',
              //   height: '120px',
              //   display: 'flex',
              //   padding: '3rem',
              //   alignItems: 'center',
              //   justifyContent: 'center'
              // }}
            >
              <InsertDriveFileIcon style={{ fontSize: '50px' }} />
            </div>
            <div style={{ width: '100%' }}>
              <ImportBase
                className="import-vetores"
                title={t('Import vectors')}
                instructions={instructions}
                onSubmit={formik.submitForm}
                onFileChange={(event) =>
                  setFile(get(event, 'target.files[0]', {}))
                }
                errors={errors}
                successMessage={successMessage}
                loading={loading}
              >
                <div
                  style={{
                    marginTop: '0.3rem',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span style={{ margin: '0.2rem 0 0.5rem 0' }}>
                    {get(file, 'name', '')}
                  </span>
                </div>
              </ImportBase>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default styled(ImportVectoresPage)`
  .icon-styled {
    background: ${(props) => props.theme.clientColors.colorSecondIcon};
    color: ${(props) => props.theme.clientColors.colorPrimaryIcon};
    margin: 28px 0 0 28px;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    display: flex;
    padding: 3rem;
    align-items: center;
    justify-content: center;
  }
`
