import React, { useEffect, useState } from 'react'
import DraggableResizableModal from './DraggableResizableModal'
import styled from 'styled-components'

interface DrawingPanelProps {
  isDrawingPanelOpen: boolean
  setIsDrawingPanelOpen: (value: boolean) => unknown
  className?: string
}

const DrawingPanel: React.FC<DrawingPanelProps> = ({
  isDrawingPanelOpen,
  setIsDrawingPanelOpen,
  className
}) => {
  const [width, setWidth] = useState(400)
  const [height, setHeight] = useState(325)

  const onResize = (e, dir, refToElement, delta, position) => {
    console.log(position)
    setWidth(refToElement.style.width)
    setHeight(+refToElement.style.height.replace('px', '') - 75)
  }

  useEffect(() => {
    setWidth(400)
    setHeight(325)
  }, [isDrawingPanelOpen])

  return (
    <DraggableResizableModal
      isOpen={isDrawingPanelOpen}
      onAction={() => setIsDrawingPanelOpen(false)}
      className={className}
      onResize={onResize}
    >
      <iframe
        className="handle"
        src="https://d3p51tg2yfeyjg.cloudfront.net"
        height={height}
        width={width}
        frameBorder="0"
        title="Drawing Painel"
      ></iframe>
    </DraggableResizableModal>
  )
}

export default styled(DrawingPanel)`
  z-index: 101;
  height: 800px;
  width: 800px;
`
