// @ts-nocheck
import React, { useEffect, useContext, useReducer } from 'react'
import styled from 'styled-components'
import useQuery from '../hooks/useQuery'
import { useHistory, useParams } from 'react-router-dom'
import LoadingIndicator from 'components/LoadingIndicator'
import { AuthContext } from 'states/AuthState'

export const reducer = (state: any, action: any): IAuthState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, authFlowState: 'running' }

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authFlowState: 'success'
      }

    case 'LOGIN_ERROR':
      return {
        ...state,
        authFlowState: 'error'
      }

    default:
      return state
  }
}

const initialState = {
  authFlowState: 'idle'
}

const ExternalAuthWrapper = ({ token }) => {
  const { handleEducatBridgeLogin } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initialState)

  const history = useHistory()
  const query = useQuery()

  useEffect(() => {
    const h = async () => {
      try {
        await handleEducatBridgeLogin(token)

        dispatch({ type: 'LOGIN_SUCCESS' })
      } catch {
        dispatch({ type: 'LOGIN_ERROR' })
      }
    }

    if (state.authFlowState !== 'idle' || !token) return

    dispatch({ type: 'LOGIN' })

    h()
  }, [state.authFlowState, handleEducatBridgeLogin, token])

  useEffect(() => {
    if (state.authFlowState !== 'success') return

    const next = query.get('next') || '/'
    history.push(next)
  }, [state.authFlowState, query, history])

  return (
    <>
      <span>Conectando..</span>
      <LoadingIndicator />
    </>
  )
}

const ExternalAuthPage = ({ className }) => {
  const { token } = useParams<{ token: string }>()

  return (
    <div className={className}>
      <ExternalAuthWrapper token={token} />
    </div>
  )
}

export default styled(ExternalAuthPage)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin-top: 0.5rem;
    font-weight: bold;
  }
`
