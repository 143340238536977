import { Skeleton } from '@material-ui/lab'
import Modal from 'components/Modal/Modal'
import { getUserConfiguration } from 'data/apis/user-config'
import { getDefaultUserConfiguration } from 'data/utils/user-configuration'
import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { UserConfigurationOptions } from '../../data/domain/user-config'
import CheckBoxInput from '../Inputs/CheckBoxInput'

type UserConfigurationModalProps = {
  candidateId?: string
  onClose: () => void
}

const UserConfigurationModal = ({
  candidateId,
  onClose
}: UserConfigurationModalProps) => {
  const formik = useFormikContext()
  const userConfigurationKey = ['get-current-situation', candidateId]
  const { t } = useTranslation()

  const _getUserConfig = async () => {
    if (candidateId) {
      return await getUserConfiguration(candidateId.toString())
    }
  }

  const {
    isFetching: fetchingUserConfiguration,
    refetch: refetchUserConfig
  } = useQuery(userConfigurationKey, _getUserConfig, {
    enabled: false,
    onSuccess: (userConfiguration) => {
      const defaultValues = getDefaultUserConfiguration()
      formik.setValues({
        ...defaultValues,
        ...userConfiguration
      })
    }
  })

  const onAction = () => {
    formik.submitForm().then(onClose)
  }

  return (
    <Modal
      isOpen={Boolean(candidateId)}
      onEntered={refetchUserConfig}
      onClose={onClose}
      onCancel={onClose}
      onAction={onAction}
      title={t('User configuration')}
    >
      {fetchingUserConfiguration ? (
        <Skeleton />
      ) : (
        <>
          {UserConfigurationOptions.map((configOption) => (
            <CheckBoxInput
              key={configOption.name}
              name={configOption.name}
              label={t(configOption.verboseName)}
            />
          ))}
        </>
      )}
    </Modal>
  )
}

export default UserConfigurationModal
