import ViewFile from 'components/AppealComponents/ViewFile'
import Html from 'components/Html'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import ItemTitle from './ItemTitle'

type Props = {
  children: ReactNode
  className?: string
  correctAnswer?: string
  givenAnswer?: string
  requestedBy?: string
  attachment?: any
}

const AnswerSheetFreeResponse = ({
  correctAnswer,
  givenAnswer,
  className,
  attachment,
  requestedBy = 'CANDIDATE'
}: Props) => (
  <div className={className}>
    <div className="title-wrapper">
      <div className="item-wrapper">
        <ItemTitle>
          {requestedBy === 'CANDIDATE'
            ? 'Sua resposta'
            : 'Resposta do candidato'}{' '}
        </ItemTitle>
      </div>
      <ItemTitle>Total de caracteres: {givenAnswer?.length}</ItemTitle>
    </div>
    <pre>{givenAnswer}</pre>
    <ViewFile attachments={attachment} attachmentsLimit={1} />
    <p>Resposta esperada</p>
    <Html>{correctAnswer}</Html>
  </div>
)

export default styled(AnswerSheetFreeResponse)`
  > p {
    font-weight: 600;
    color: ${(props) => props.theme.clientColors.primary};
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  pre {
    font-family: sans-serif;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`
