import React, { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  text: string
  children: ReactNode
  className?: string
  color: string
}

const AlternativeOutline = ({ text, children, className }: Props) => (
  <div className={className}>
    <small>{text}</small>
    <div>{children}</div>
  </div>
)

export default styled(AlternativeOutline)`
  text-align: right;
  margin: 5px 0;

  small {
    color: ${(props) => props.color};
    font-weight: 600;
  }

  > div {
    margin-top: 5px;
    border-radius: 4px;
    border: ${(props) => (props.color ? `1px solid ${props.color}` : '')};
  }
`
