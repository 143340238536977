import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import Tag from './Tag'
import { getStatusLabel } from 'data/utils/applications'
import { ITimeWindow } from 'types'
import moment from 'moment'

type ApplicationStatusProps = {
  status: string
  reseted?: boolean
  className?: string
  timeWindows?: ITimeWindow[]
}

const ApplicationStatus = ({
  status,
  reseted,
  timeWindows,
  className
}: ApplicationStatusProps) => {
  const [shouldDisplayTimeToEnter, setShouldDisplayTimeToEnter] = useState(
    false
  )
  const [timeToEnterApplication, setTimeToEnterApplication] = useState('')
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const color =
    theme.applicationStatus[status] || theme.applicationStatus.UNKNOWN

  const getClosestTimeWindow = useCallback(() => {
    if (status !== 'UNAVAILABLE') {
      setShouldDisplayTimeToEnter(false)
      return
    }

    const closestTimeWindow = timeWindows.filter((timeWindow) => {
      const dateFormat = 'DD/MM/YYYY'
      const applicationDay = moment(new Date(timeWindow.startTime)).format(
        dateFormat
      )
      const today = moment(new Date()).format(dateFormat)
      return today === applicationDay
    })

    const timeWindow = closestTimeWindow.reduce(
      (_, curr) => curr,
      {} as ITimeWindow
    )

    if (!timeWindow.startTime) return

    setShouldDisplayTimeToEnter(true)
    setTimeToEnterApplication(t('Soon'))
  }, [t, timeWindows, status])

  useEffect(() => {
    timeWindows && getClosestTimeWindow()
  }, [timeWindows, getClosestTimeWindow])

  return (
    <Tag color={color} className={className}>
      {!shouldDisplayTimeToEnter && t(getStatusLabel(status))}
      {shouldDisplayTimeToEnter && timeToEnterApplication}
      {reseted ? ` (${t('time reseted')})` : ''}
      {reseted ? ` (${t('time reseted')})` : ''}
    </Tag>
  )
}

export default styled(ApplicationStatus)`
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
`
