import { IUser } from '../../types'
import { RollbarErrorTracking } from '../../infra/rollbar'
import { uploadRecordToS3 } from '../../infra/s3'
import { API_HOST } from 'consts'
import axios from 'axios'

// this is sad, but necessary
declare let MediaRecorder: any
class VideoRecordingManager {
  private mediaRecorder
  private blob: any
  private storageInstance
  private user: IUser | undefined
  private canRequestData = true
  private currentStream: MediaStream | null = null
  private isRecording = false
  // Media Recorder resolution/quality
  private readonly AUDIO_BITS_PER_SECOND = 128000
  private readonly VIDEO_BITS_PER_SECOND = 128000
  // records consistent chunks of 5 seconds
  private readonly DEFAULT_RECORDING_CHUNK_SIZE = 5000
  private readonly DEFAULT_RECORDING_OFFSET = 50

  private hasRecordingEnabled = async () => {
    try {
      await axios.get(`${API_HOST}/recording/is_recording`)
      return true
    } catch {
      return false
    }
  }

  public async startRecordingForUser(stream: MediaStream, user: IUser) {
    if (this.isRecording || !user) {
      return
    }
    if (await this.hasRecordingEnabled()) {
      this.isRecording = true
      this.currentStream = stream
      this.user = user
      try {
        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'video/webm; codecs="vp8, opus"',
          audioBitsPerSecond: this.AUDIO_BITS_PER_SECOND,
          videoBitsPerSecond: this.VIDEO_BITS_PER_SECOND
        })
        this.setUpMediaRecorderEvents()
        this.mediaRecorder.start()
      } catch (e) {
        console.log(e)
      }
    }
  }

  public destroy() {
    // It is expected that some tracks or the media recorder
    // itself fails to stop sometimes.
    try {
      if (this.mediaRecorder) {
        this.mediaRecorder.addEventListener('stop', () => {})
        this.mediaRecorder.stop()
      }
      if (this.currentStream) {
        this.currentStream
          .getTracks() // get all tracks from the MediaStream
          .forEach((track) => track.stop()) // stop each of them
      }
    } catch (e) {
      RollbarErrorTracking.logError(e)
    }
    this.isRecording = false
  }

  private onStopRec = () => {
    setTimeout(() => this.mediaRecorder.start(), this.DEFAULT_RECORDING_OFFSET)
  }

  private onStartRec = () => {
    setTimeout(
      () => this.mediaRecorder.stop(),
      this.DEFAULT_RECORDING_CHUNK_SIZE
    )
  }

  private setUpMediaRecorderEvents() {
    this.mediaRecorder.addEventListener('dataavailable', this.uploadChunk)
    this.mediaRecorder.addEventListener('stop', this.onStopRec)
    this.mediaRecorder.addEventListener('start', this.onStartRec)
  }

  private uploadChunk = async (e: any) => {
    const chunkData = e.data
    const date = new Date()
    const timestamp = new Date()
      .toISOString()
      .replace(/\./g, '_')
      .replace(/:/g, '_')
    const key = `${this.user.provider.codename}/${this.user.id}/${
      date.getFullYear() + '_' + date.getMonth() + '_' + date.getDate()
    }/${timestamp}.webm`

    uploadRecordToS3(chunkData, key)
  }
}

const videoManager = new VideoRecordingManager()
export default videoManager
