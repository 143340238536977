import React, { useCallback } from 'react'
import styled from 'styled-components'
import { IRoom } from 'types'
import { API_HOST } from 'consts'
import axios from 'axios'
import { RollbarErrorTracking } from 'infra/rollbar'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'

type RoomHeaderProps = {
  className?: string
  room: IRoom | null
  setRoom: (room: IRoom | null) => void
}

const RoomHeader = ({ className, room, setRoom }: RoomHeaderProps) => {
  const { t } = useTranslation()
  const roomId = room?.id

  const fetchRooms = useCallback(
    async (search?: string): Promise<IRoom[]> => {
      try {
        const response = await axios.get(`${API_HOST}/v1/rooms`, {
          // Ignore any char that is not a digit
          params: {
            search: search?.replace(/\D/g, ''),
            opened: true
          }
        })
        const rooms = response.data.results
        // If loading rooms for the first time and no room is selected,
        // then select the first room automatically.
        if (rooms.length > 0 && !room) {
          setRoom(rooms[0])
        }
        return rooms
      } catch (e) {
        RollbarErrorTracking.logError(e)
        return []
      }
    },
    [room, setRoom]
  )

  return (
    <div className={className}>
      <h3 style={{ margin: 0 }}>
        {roomId === -1 ? (
          <Skeleton width={50} />
        ) : (
          <>
            {t('Room')} {roomId}
          </>
        )}
      </h3>
      <div>
        <label htmlFor="room">{t('Change room')}</label>
        <AsyncSelect
          id="room"
          isSearchable
          defaultOptions
          placeholder={t('Nº')}
          loadOptions={fetchRooms}
          getOptionValue={(d) => d.id.toString()}
          getOptionLabel={(d) => d.id.toString()}
          onChange={(value) => setRoom(value as IRoom)}
          value={room}
          isClearable={false}
        />
      </div>
    </div>
  )
}

export default styled(RoomHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;

  > div {
    display: flex;
    align-items: center;

    > div {
      width: 100px;
    }

    label {
      margin-right: 10px;
    }
  }
`
