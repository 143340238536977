import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Axios from 'axios'
import { API_HOST } from 'consts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'
import Select from 'react-select'
import { toast } from 'react-toastify'

interface Props {
  className?: string
}

interface IAdmin {
  id: number | string
  name: string
}

const ConfigSchedulingMenu = ({ className }: Props) => {
  const [periods, setPeriods] = useState([])
  const [menu, setMenu] = useState([])
  const [periodMenu, setPeriodMenu] = useState([])
  const [yearPeriodFilter, setYearPeriodFilter] = useState([])
  const [munuSelecionado, setMunuSelecionado] = useState()
  const [selectedPeriodAdd, setSelectedPeriodAdd] = useState([])
  const [selectedPeriodRemove, setSelectedPeriodRemove] = useState([])

  const fetchPeriods = async () => {
    await Axios.get(`${API_HOST}/v1/year_period`)
      .then((res) => {
        setPeriods(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const fetchPeriodMenu = async (value: any) => {
    await Axios.get(
      `${API_HOST}/periodo_menus?menu__id=${value?.id ? value?.id : value}`
    )
      .then((res) => {
        setPeriodMenu(res.data)
        setMunuSelecionado(value?.id ? value?.id : value)
        setSelectedPeriodAdd([])
        setYearPeriodFilter(
          periods.filter(
            (item1) => !res.data.find((item2) => item2.periodoAno === item1.id)
          )
        )
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const fetchMenus = async () => {
    await Axios.get(`${API_HOST}/menu_agendamento`)
      .then((res) => {
        setMenu(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const buildOptions = () =>
    menu?.map((op) => {
      return { value: op?.id, label: op.name }
    })

  useEffect(() => {
    fetchPeriods()
    fetchMenus()
  }, [])

  const verifySelectPeriodAdd = (value: number) => {
    if (selectedPeriodAdd.length === 0) {
      setSelectedPeriodAdd([...periods.filter((item) => item.id === value)])
    } else if (
      selectedPeriodAdd?.filter((item) => item.id === value)[0]?.id === value
    ) {
      setSelectedPeriodAdd([
        ...selectedPeriodAdd.filter((item) => item.id !== value)
      ])
    } else {
      setSelectedPeriodAdd([
        ...selectedPeriodAdd,
        ...periods.filter((item) => item.id === value)
      ])
    }
  }

  const verifySelectPeriodRemove = (value: number) => {
    if (selectedPeriodRemove.length === 0) {
      setSelectedPeriodRemove([
        ...periodMenu.filter((item) => item.id === value)
      ])
    } else if (
      selectedPeriodRemove?.filter((item) => item.id === value)[0]?.id === value
    ) {
      setSelectedPeriodRemove([
        ...selectedPeriodRemove.filter((item) => item.id !== value)
      ])
    } else {
      setSelectedPeriodRemove([
        ...selectedPeriodRemove,
        ...periodMenu.filter((item) => item.id === value)
      ])
    }
  }

  const addPeriod = async () => {
    if (selectedPeriodAdd?.length <= 0) {
      toast.error('Por favor selecione algum período ano')
      return
    }
    const payload = selectedPeriodAdd.map((period) => ({
      periodo_ano: period.id,
      menu: munuSelecionado,
      visible: true
    }))

    await Axios.post(`${API_HOST}/periodo_menus`, payload)
      .then((res) => {
        fetchPeriods()
        fetchPeriodMenu(payload[0]?.menu)
        toast.success('Período vinculado com sucesso')
      })
      .catch((err) => {
        toast.error(`Ocorreu um erro ao vincular o período no menu.`)
      })
  }

  const removePeriod = async () => {
    if (selectedPeriodRemove?.length <= 0) {
      toast.error('Por favor selecione algum período ano')
      return
    }
    const periodIds = selectedPeriodRemove.map((period) => period.id)

    await Axios.delete(`${API_HOST}/periodo_menus/${periodIds.join(',')}`)
      .then((res) => {
        fetchPeriods()
        fetchPeriodMenu(selectedPeriodRemove[0]?.menu)
        toast.success('Período removido com sucesso')
      })
      .catch((err) => {
        toast.error('Ocorreu um erro ao remover o período no menu.')
      })
  }

  return (
    <section className={className}>
      <p className="title-header">Relacione os periodos anos no menu.</p>
      <Select
        placeholder="Selecione um menu"
        className="select"
        options={buildOptions()}
        onChange={(e: any) => fetchPeriodMenu({ id: e.value, name: e.label })}
      />
      <section className="box-wrapper">
        <div className="user-box">
          <div className="title-card">
            <p>Período Ano</p>
            <input
              className="checkbox"
              type="checkbox"
              onChange={() =>
                selectedPeriodAdd.length <= 0
                  ? setSelectedPeriodAdd(periods)
                  : setSelectedPeriodAdd([])
              }
            />
          </div>
          <div className="input-card">
            <input
              className="input-text"
              // onChange={(e) => roomFilterExist(e.target.value)}
              disabled
            />
            {/* <FontAwesomeIcon icon={faSearch} /> */}
          </div>
          <div className="user-box-body">
            {yearPeriodFilter?.map((periodo, i) => (
              <>
                {!periodo?.checked && (
                  <div
                    key={periodo?.id}
                    className="card"
                    style={{
                      background:
                        selectedPeriodAdd.filter(
                          (item) => item.id === periodo.id
                        )[0]?.id !== periodo.id
                          ? ''
                          : 'rgba(0,0,0,0.3)'
                    }}
                    onClick={() => verifySelectPeriodAdd(periodo?.id)}
                  >
                    {periodo?.periodoId?.dsPeriodo}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="button-wrapper">
          <button onClick={addPeriod}>
            <FontAwesomeIcon icon={faChevronRight} size="2x" color="green" />
            <h5>adicionar</h5>
          </button>
          <button onClick={removePeriod}>
            <FontAwesomeIcon icon={faChevronLeft} size="2x" color="red" />
            <h5>remover</h5>
          </button>
        </div>
        <div className="user-box">
          <div className="title-card">
            <p>Período Ano vinculado</p>
            <input
              className="checkbox"
              type="checkbox"
              onChange={() =>
                selectedPeriodRemove.length <= 0
                  ? setSelectedPeriodRemove(periodMenu)
                  : setSelectedPeriodRemove([])
              }
            />
          </div>
          <div className="input-card">
            <input
              className="input-text"
              // onChange={(e) => roomFilterBelong(e.target.value)}
              disabled
            />
            {/* <FontAwesomeIcon icon={faSearch} /> */}
          </div>
          <div className="user-box-body">
            {periodMenu?.map((periodo) => (
              <>
                <div
                  key={periodo?.id}
                  className="card"
                  style={{
                    background:
                      selectedPeriodRemove.filter(
                        (item) => item.id === periodo.id
                      )[0]?.id !== periodo.id
                        ? ''
                        : 'rgba(0,0,0,0.3)'
                  }}
                  onClick={() => verifySelectPeriodRemove(periodo?.id)}
                >
                  {periodo?.periodoNome}
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </section>
  )
}

export default styled(ConfigSchedulingMenu)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #777777;

  .title-header {
    color: ${(props) => props.theme.clientColors.primary};
    font-size: 32px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  .select {
    width: 400px;
    margin-bottom: 20px;
    z-index: 2;
  }

  .box-wrapper {
    width: 1000px;
    height: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-wrapper {
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      button {
        svg {
          margin-top: 12px;
        }
        color: #777777;

        border: none;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

        background: #f5f4f3;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border-radius: 8px;
        height: 90px;
        width: 90px;
        transition: filter 300ms;

        &:hover {
          filter: brightness(85%);
        }
      }
    }
  }

  section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-box {
      box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.25);
      height: 640px;
      width: 400px;
      border-radius: 4px;
      border: 1px solid #c4c4c4;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title-card {
        width: 85%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${(props) => props.theme.clientColors.primary};
        font-weight: 600;

        .checkbox {
          height: 20px;
          width: 20px;
        }
      }

      .input-card {
        height: 52px;
        width: 90%;
        border-radius: 4px;
        margin-top: 20px;

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        background: #f5f4f3;

        .input-text {
          width: 300px;
          height: 31px;
          border-radius: 4px;
          border: 1px solid #c4c4c4;
          padding: 0 33px 0 10px;

          &:focus {
            outline: none;
            border: 2px solid #c4c4c4;
          }
        }

        svg {
          color: #777777;
          position: absolute;
          right: 25px;
          z-index: 1;
        }
      }

      .user-box-body {
        height: 550px;
        width: 400px;
        border-radius: 4px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;

        ::-webkit-scrollbar {
          display: unset;
          width: 8px;
        }
        ::-webkit-scrollbar-thumb {
          background: ${(props) => props.theme.colors.grayMedium};
          border-radius: 4px;
        }
        ::-webkit-scrollbar-track {
          background: white;
        }

        .card {
          background: #f5f4f3;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          width: 361px;
          border-radius: 4px;
          margin-top: 10px;
          font-size: 18px;
          padding: 10px 0;

          p {
            width: 90%;
            text-align: start;
          }

          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
          transition: background 500ms;

          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
`
