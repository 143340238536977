import React, { ReactNode } from 'react'
import Button from '../Button'
import ModalFooter from './ModalFooter'
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ModalHeader from './ModalHeader'
import { Add } from '@material-ui/icons'

type ModalProps = {
  className?: string
  isOpen: boolean
  onAction?: () => void
  actionText?: string
  onClose?: () => void
  onCancel?: () => void
  cancelText?: string
  children?: ReactNode
  actionDisabled?: boolean
  disableBackdropClick?: boolean
  title?: string
  subtitle?: string
  onEntered?: () => void
  width?: number
  maxWidth?: number
  height?: number | any
}

const Modal = ({
  className,
  isOpen,
  onAction,
  actionText,
  onClose,
  onCancel,
  cancelText = 'Cancel',
  children,
  actionDisabled,
  disableBackdropClick,
  onEntered,
  title = '',
  subtitle = '',
  height
}: ModalProps) => {
  const { t } = useTranslation()
  const handleAction = () => {
    onAction && onAction()
  }
  return (
    <Dialog
      className={className}
      onClose={onClose}
      open={isOpen}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableBackdropClick}
      onEntered={onEntered}
    >
      <ModalHeader title={title} onClose={onClose} subtitle={subtitle} />
      {children}
      {(onAction || onCancel) && (
        <ModalFooter>
          {onAction && (
            <Button
              style={{
                height: '40px',
                width: '100%',
                borderRadius: '70px',
                backgroundColor: '#863BFF',
                color: '#FFFFFF',
                border: 'none',
                boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.25)'
              }}
              disabled={actionDisabled}
              action="primary"
              onClick={handleAction}
              data-testid="modal-action-button"
              className={actionDisabled && 'btn-disable'}
            >
              {actionText ? actionText : `${t('Add')}`}
            </Button>
          )}
          {onCancel && (
            <Button
              style={{
                height: '40px',
                width: '100%',
                borderRadius: '70px',
                backgroundColor: '#E4E4E4',
                boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.25)',
                color: '#878787',
                border: 'none'
              }}
              action="secondary"
              onClick={onCancel}
              data-testid="modal-cancel-button"
            >
              {t(cancelText)}
            </Button>
          )}
        </ModalFooter>
      )}
    </Dialog>
  )
}
export default styled(Modal)`
  .btn-disable {
    background-color: #e4e4e4;
    color: #ffffff;
  }
  .MuiDialog-paper {
    color: ${(props) => props.theme.colors.text};
    padding: 2rem;
    max-width: ${(props) => props.maxWidth || 1000}px;
    width: ${(props) => props.width || 700}px;
    height: ${(props) => props.height};
  }
`
