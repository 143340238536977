import React, { useContext, useEffect, useState } from 'react'
import debounce from 'debounce-promise'
import styled from 'styled-components'
import { Radio } from '@material-ui/core'
import { AnswerContext } from 'states/AnswerState'

type MultipleLinearScaleItemProps = {
  item: any
  className?: string
}

const MultipleLinearScaleItem = ({
  item,
  className
}: MultipleLinearScaleItemProps) => {
  const { answer, updateMultipleLinearScale } = useContext(AnswerContext)
  const table = JSON.parse(item?.gradeLinear?.replace(/'/g, '"'))
  const answerTable = JSON.parse(answer?.gradeLinear)
  const [selectedValue, setSelectedValue] = useState<any>(answerTable ?? [])
  const [scrollLeft, setScrollLeft] = useState(0)

  const updateResponse = debounce((response) => {
    const rows = response.map((d) => {
      return { position: d.row, value: d.rowValue }
    })
    const columns = response.map((d) => {
      return { position: d.column, value: d.columnValue }
    })

    const gradeLinearString = JSON.stringify([
      ...response,
      { rows: rows, columns: columns }
    ])
    updateMultipleLinearScale(gradeLinearString)
  }, 300)

  useEffect(() => {
    setSelectedValue(answerTable ?? [])
  }, [answer.position])

  const handleChange = (
    row: number,
    rowValue: string,
    column: number,
    columnValue: string
  ) => {
    if (!selectedValue) {
      setSelectedValue([{ row, rowValue, column, columnValue }])
      updateResponse([{ row, rowValue, column, columnValue }])
    } else {
      const arr = selectedValue.filter((value) => value.row !== row)
      setSelectedValue([...arr, { row, rowValue, column, columnValue }])
      updateResponse([...arr, { row, rowValue, column, columnValue }])
    }
  }

  const handleScroll = (event) => {
    setScrollLeft(event.currentTarget.scrollLeft)
  }

  return (
    <div className={className}>
      <div style={{ width: '100%', display: 'block' }}>
        <div
          style={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            letterSpacing: '.2px',
            lineHeight: '20px',
            fontSize: '14px'
          }}
        >
          <div
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
              paddingBottom: '10px'
            }}
            onScroll={handleScroll}
          >
            <div style={{ display: 'table', width: '100%' }}>
              <div style={{ display: 'table-row' }}>
                <div
                  style={{
                    minWidth: '48px',
                    maxWidth: '144px',
                    display: 'table-cell',
                    height: '2.5em',
                    verticalAlign: 'middle',
                    whiteSpace: 'pre-wrap',
                    width: '120px'
                  }}
                ></div>

                {table?.column &&
                  table?.column.map((column) => (
                    <div
                      key={column?.position}
                      style={{
                        display: 'table-cell',
                        height: '2.5em',
                        minWidth: '48px',
                        padding: '0.25em',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        whiteSpace: 'pre-wrap',
                        width: '120px'
                      }}
                    >
                      {column?.value}
                    </div>
                  ))}
              </div>

              {table?.row &&
                table?.row?.map((r) => (
                  <>
                    <div
                      style={{
                        display: 'table-row-group',
                        backgroundColor: '#eceff1',
                        borderRadius: '5px'
                      }}
                    >
                      <span style={{ display: 'table-row' }}>
                        <div
                          key={r?.position}
                          style={{
                            minWidth: '48px',
                            maxWidth: '144px',
                            padding: '8px',
                            textAlign: 'left'
                          }}
                        >
                          {r?.value}
                        </div>

                        {table?.column &&
                          table?.column.map((column) => (
                            <div
                              key={column?.position}
                              style={{
                                display: 'table-cell',
                                height: '2.5em',
                                minWidth: '48px',
                                padding: '0.25em',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                whiteSpace: 'pre-wrap',
                                width: '120px',
                                zIndex: 1
                              }}
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  height: '20px',
                                  position: 'relative',
                                  verticalAlign: 'middle',
                                  width: '20px'
                                }}
                              >
                                <Radio
                                  className="radio__button__fake"
                                  color="primary"
                                  name={`${r?.value} ${column.value}`}
                                  value={`${r?.position} ${column.position}}`}
                                  checked={
                                    selectedValue &&
                                    !!selectedValue?.find(
                                      (value) =>
                                        value.column === column.position &&
                                        value.row === r.position
                                    )
                                  }
                                  onChange={() => {
                                    handleChange(
                                      r?.position,
                                      r?.value,
                                      column?.position,
                                      column?.value
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                      </span>
                    </div>
                    <div style={{ height: '4px' }}></div>
                  </>
                ))}
            </div>
          </div>

          <div
            style={{
              width: '100%',
              display: 'table',
              position: 'absolute',
              left: '-1',
              top: '0'
            }}
          >
            <div style={{ display: 'table-row' }}>
              <div
                style={{
                  minWidth: '48px',
                  maxWidth: '144px',
                  display: 'table-cell',
                  height: '2.5em',
                  verticalAlign: 'middle',
                  whiteSpace: 'pre-wrap',
                  width: '120px',
                  backgroundColor: '#FAFAFA',
                  boxShadow: scrollLeft > 0 ? '5px 0 5px -1px #FFFFFF' : ''
                }}
              ></div>

              {table?.column &&
                table?.column.map((column) => (
                  <div
                    key={column?.position}
                    style={{
                      display: 'table-cell',
                      height: '2.5em',
                      minWidth: '48px',
                      padding: '0.25em',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      whiteSpace: 'pre-wrap',
                      width: '120px',
                      visibility: 'hidden'
                    }}
                  >
                    {column?.value}
                  </div>
                ))}
            </div>

            {table?.row &&
              table?.row?.map((r) => (
                <>
                  <div
                    style={{
                      display: 'table-row-group',
                      borderTopLeftRadius: '5px',
                      borderBottomLeftRadius: '5px'
                    }}
                  >
                    <div
                      key={r?.position}
                      style={{
                        minWidth: '48px',
                        maxWidth: '144px',
                        padding: '8px',
                        textAlign: 'left',
                        // border: "1px solid #000000",
                        backgroundColor: '#eceff1',
                        boxShadow: '5px 0 5px -1px #e0e0e0'
                      }}
                    >
                      {r?.value}
                    </div>

                    {table?.column &&
                      table?.column.map((column) => (
                        <div
                          key={column?.position}
                          style={{
                            display: 'table-cell',
                            height: '2.5em',
                            minWidth: '48px',
                            padding: '0.25em',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            whiteSpace: 'pre-wrap',
                            width: '120px'
                          }}
                        >
                          <div
                            style={{
                              display: 'inline-block',
                              height: '20px',
                              position: 'relative',
                              verticalAlign: 'middle',
                              width: '20px',
                              zIndex: 0,
                              visibility: 'hidden'
                            }}
                          >
                            <Radio
                              className="radio__button__fake"
                              color="primary"
                              name={`${r?.value} ${column.value}`}
                              value={`${r?.position} ${column.position}}`}
                              checked={
                                selectedValue &&
                                !!selectedValue?.find(
                                  (value) =>
                                    value.column === column.position &&
                                    value.row === r.position
                                )
                              }
                              onChange={() => {
                                handleChange(
                                  r?.position,
                                  r?.value,
                                  column?.position,
                                  column?.value
                                )
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                  <div style={{ height: '4px' }}></div>
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default styled(MultipleLinearScaleItem)`
  margin-top: 2rem;
  padding: 20px;
  background: #fafafa;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  table {
    width: 100%;
  }

  th {
    &:nth-child(n + 2) {
      border: 1px solid #2e2e2e;
      font-size: 14px;
      padding: 10px;
    }
  }

  tbody {
    color: #2e2e2e;
  }

  td {
    padding: 10px;
    text-align: center;
    border: 1px solid #2e2e2e;
  }

  .radio__button__fake {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    bottom: calc(50% - 7px);
  }
`
