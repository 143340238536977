import React from 'react'
import CorrectAlternativeOutline from './CorrectAlternativeOutline'
import WrongAlternativeOutline from './WrongAlternativeOutline'
import CandidateHitTheQuestion from './CandidateHitTheQuestion'

const NoOutline = ({ children }) => <>{children}</>

export default (
  isCorrect: boolean,
  isPicked: boolean,
  isCancelled: boolean
) => {
  if (isPicked && isCorrect && !isCancelled) {
    return CandidateHitTheQuestion
  }

  if (isCorrect && !isCancelled) {
    return CorrectAlternativeOutline
  }
  if (isPicked) {
    return WrongAlternativeOutline
  }

  return NoOutline
}
