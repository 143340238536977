import { updateUserConfiguration } from 'data/apis/user-config'
import { getDefaultUserConfiguration } from 'data/utils/user-configuration'
import { Formik } from 'formik'
import React from 'react'
import UserConfigurationModal from './UserConfigurationModal'

type UserConfigurationModalFormProps = {
  candidateId?: string
  onClose: () => void
}

const UserConfigurationModalForm = ({
  candidateId,
  onClose
}: UserConfigurationModalFormProps) => {
  const onSubmit = (values: object) => {
    updateUserConfiguration(candidateId.toString(), values)
  }

  return (
    <Formik initialValues={getDefaultUserConfiguration()} onSubmit={onSubmit}>
      <UserConfigurationModal candidateId={candidateId} onClose={onClose} />
    </Formik>
  )
}

export default UserConfigurationModalForm
