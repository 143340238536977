import React, { ReactNode } from 'react'
import styled from 'styled-components'

type PageProps = {
  type?: any
  title?: string
  children: ReactNode
  className?: string
  customWidth?: number
  fluid?: boolean
}
const Page = ({ type, title, children, className }: PageProps) => {
  return (
    <div className={className}>
      <section className={`${type === 'aluno' ? 'aluno' : className}`}>
        {title && (
          <h1 style={{ color: '#4A4A4A', width: '100%', marginLeft: '20px' }}>
            {title}
          </h1>
        )}
        <>{children}</>
      </section>
    </div>
  )
}

export default styled(Page)`
  padding: 20px;
  margin: auto;
  max-width: ${(props) =>
    props.fluid ? 'none' : `${props.customWidth || 1000}px`};
  .aluno {
    padding: 20px;
    margin: auto;
    background: white;
    max-width: ${(props) =>
      props.fluid ? 'none' : `${props.customWidth || 1000}px`};
  }
  @media(max-width:768px){
    padding:0;
  }
`
