import React, { useContext, useEffect, useState } from 'react'
import {
  Redirect,
  Switch,
  useRouteMatch,
  useHistory,
  useParams
} from 'react-router-dom'
import FrameCaptureState from 'states/FrameCaptureState'
import ReviewAnswers from '../components/ReviewAnswers'
import PrivateRoute from '../components/PrivateRoute'
import db from '../db'
import useQuery from '../hooks/useQuery'
import ApplicationState from '../states/ApplicationState'
import CandidateMessageExchangeState from '../states/CandidateMessageExchangeState'
import { AuthContext } from '../states/AuthState'
import Answer from './Answer'
import Instructions from './Instructions'
import ApplicationFinished from './ApplicationFinished'
import {
  fetchNextApplicationId,
  fetchApplication
} from 'data/apis/applications'

const Application = () => {
  const query = useQuery()
  const match = useRouteMatch()
  const { user } = useContext(AuthContext)
  const { applicationId } = useParams()
  const [application, setApplication] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (query.get('clear_database')) {
      db.delete().then(() => {
        window.location.href = '/'
      })
    }
  }, [query, user])

  useEffect(() => {
    fetchApplication(+applicationId)
      .then((res) => setApplication(res))
      .catch((err) => console.error(err))
  }, [applicationId])

  const handleGoBackToApplicationsList = () => {
    const collectionId = application?.exam?.collection.id
    const enableAdaptativeFlow =
      application?.exam?.collection?.enableAdaptativeFlow
    const setUnavailableToApplications =
      application?.exam?.collection?.setUnavailableToApplications

    if (enableAdaptativeFlow || setUnavailableToApplications) {
      history.push(`/applications?collection=${collectionId}`)
    } else {
      history.push('/applications')
    }
  }
  return (
    <CandidateMessageExchangeState>
      {() => (
        <FrameCaptureState>
          {() => (
            <ApplicationState>
              {() => (
                <div>
                  <Switch>
                    <PrivateRoute
                      path={match.path + '/instructions'}
                      component={Instructions}
                    />
                    <PrivateRoute
                      path={match.path + '/review'}
                      component={ReviewAnswers}
                    />
                    <PrivateRoute
                      path={match.path + '/answers/:answerId'}
                      component={Answer}
                    />
                    <PrivateRoute
                      path={match.path + '/finished'}
                      component={(props) => (
                        <ApplicationFinished
                          fetchNextApplicationId={fetchNextApplicationId}
                          onContinueToNextApplication={history.push}
                          onBackToApplicationsList={() =>
                            handleGoBackToApplicationsList()
                          }
                          applicationType={application?.exam?.type}
                          {...props}
                        />
                      )}
                    />
                    <Redirect to={match.url + '/instructions'} />
                  </Switch>
                </div>
              )}
            </ApplicationState>
          )}
        </FrameCaptureState>
      )}
    </CandidateMessageExchangeState>
  )
}

export default Application
