import React, { Component } from 'react'
import axios from 'axios'
import { API_HOST } from 'consts'
import StackedAreaChart from '../components/StackedAreaChart'
import ParticipacaoDreColumn from '../components/ParticipacaoDreColumn'
import HeaderRelatorio from '../components/HeaderRelatorio'
import { get } from 'lodash'
import waiting from '../assets/img/mascot-waiting.png'
import loadingGif from '../assets/img/loading.gif'
import InsufficientData from 'components/InsufficientData'

class ReportStudent extends Component {
  state = {
    loading: false,
    boletimData: {}
  }

  componentDidMount() {
    this.fetchBoletimData()
  }

  componentWillUnmount() {
    this.state.loading = true
  }

  fetchBoletimData = () => {
    const avaliaPk = this.props.match.params.id

    axios
      .get(`${API_HOST}/boletim_mt/${avaliaPk}/get_relatorio`)
      .then((res) => {
        /* eslint-disable react/no-direct-mutation-state */
        this.setState({ boletimData: res.data, loading: true })
      })
      .catch((err) => {
        console.error('an error occurred while loading:', err)
        this.setState({ loading: true })
      })
  }

  render() {
    const { boletimData, loading } = this.state
    return (
      <>
        {!loading ? (
          <div
            style={{
              width: '100%',
              height: `100%`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img src={loadingGif} alt="loading" style={{ height: '50px' }} />
          </div>
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {boletimData.nTALUNO ? (
                <div
                  style={{
                    marginTop: '3rem',
                    background: '#fff',
                    width: '80%',
                    maxWidth: '1110px',
                    borderRadius: '16px',
                    boxShadow: '0px 1px 5px rgba(58, 58, 58, 0.25)'
                  }}
                >
                  <div className="container mb-4 pb-4">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '100%', borderRadius: '16px' }}>
                        <div style={{ width: '100%' }}>
                          <div style={{ width: '100%' }}>
                            <h1
                              style={{
                                marginLeft: '1rem',
                                fontSize: '30px',
                                color: '#262930'
                              }}
                            >
                              Relatório Individual do Aluno - Nota e
                              Proficiência
                            </h1>
                            <hr style={{ height: '1px', width: '95%' }} />
                          </div>
                          <div
                            className="card-body"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              minWidth: '100%'
                            }}
                          >
                            <HeaderRelatorio
                              schoolName={get(boletimData, 'nMESCOLA')}
                              schoolYear={get(boletimData, 'nMETAPA', '')}
                              dreAndMunicipio={get(boletimData, 'nMDRE')}
                              student={get(boletimData, 'nMALUNO', '')}
                            />
                            {loading && (
                              <div style={{ width: '77.5%' }}>
                                <StackedAreaChart
                                  dataDot={boletimData}
                                  pontos={true}
                                  useBase={true}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                marginTop: '2rem',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <ParticipacaoDreColumn
                                boletimaluno={boletimData}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'right',
                              marginBottom: '1rem',
                              marginLeft: '-1rem'
                            }}
                          >
                            <button
                              style={{
                                color: 'white',
                                backgroundColor: 'rgb(103, 53, 221)',
                                border: '1px solid rgba(58, 58, 58, 0.25)',
                                fontSize: '12pt',
                                textAlign: 'center',
                                cursor: 'pointer',
                                borderRadius: '8px',
                                boxShadow: '2px 4px 5px rgba(58, 58, 58, 0.25)',
                                letterSpacing: '1px',
                                padding: '4px 16px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                              }}
                              onClick={() =>
                                window.open(
                                  `/aluno-acertos/${this.props.match.params.id}`,
                                  '_self'
                                )
                              }
                            >
                              Relatório de Habilidade
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    borderRadius: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    minHeight: '300px',
                    padding: '2rem',
                    flexDirection: 'column'
                  }}
                >
                  <div style={{ height: '700px', padding: '1rem' }}><InsufficientData /></div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                    <button
                      style={{
                        color: 'white',
                        backgroundColor: 'rgb(103, 53, 221)',
                        border: '1px solid rgba(58, 58, 58, 0.25)',
                        fontSize: '12pt',
                        textAlign: 'center',
                        cursor: 'pointer',
                        borderRadius: '8px',
                        boxShadow: '2px 4px 5px rgba(58, 58, 58, 0.25)',
                        letterSpacing: '1px',
                        padding: '4px 16px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                      onClick={() => window.open(`/applications`, '_self')}
                    >
                      Voltar para atividades
                    </button>
                    <button
                      style={{
                        color: 'white',
                        backgroundColor: 'rgb(103, 53, 221)',
                        border: '1px solid rgba(58, 58, 58, 0.25)',
                        fontSize: '12pt',
                        textAlign: 'center',
                        cursor: 'pointer',
                        borderRadius: '8px',
                        boxShadow: '2px 4px 5px rgba(58, 58, 58, 0.25)',
                        letterSpacing: '1px',
                        padding: '4px 16px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                      onClick={() =>
                        window.open(
                          `/aluno-acertos/${this.props.match.params.id}`,
                          '_self'
                        )
                      }
                    >
                      Relatório de Habilidade
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    )
  }
}

export default ReportStudent
