import React from 'react'
import styled from 'styled-components'
import Html from 'components/Html'
import Card from 'components/Card/Card'

type AnswerToAppealProps = {
  providerCodename?: string
  appealDescription?: any
  repliedAt?: any
  appealResponse?: any
  className?: string
}

const AnswerToAppeal = ({
  className,
  appealDescription,
  repliedAt,
  appealResponse
}: AnswerToAppealProps) => {
  return (
    <>
      <div className={className}>
        <Card>
          <div className="title-defer">
            <span>Análise do recurso</span>
          </div>
          <div className="defer-header">
            <div>
              <span>
                <b>Status do Deferimento</b>
              </span>
              <p>
                <b>{appealDescription}</b>
              </p>
            </div>
            <div>
              <span>
                <b>Data da Resposta</b>
              </span>
              <p>{repliedAt}</p>
            </div>
          </div>
          <div className="response-to-candidate">
            <span>
              <b>Resposta para Candidato</b>
            </span>
            <Html>{appealResponse}</Html>
          </div>
        </Card>
      </div>
    </>
  )
}

export default styled(AnswerToAppeal)`
  ${Card} {
    padding: 2rem;
  }
  span {
    color: ${(props) => props.theme.clientColors.primary};
    font-size: 1.1rem;
    position: relative;
    font-weight: 600;
    bottom: 0.5rem;
  }
  p {
    color: #606060;
  }
  .title-defer {
    span {
      color: ${(props) => props.theme.clientColors.primary};
      font-size: 1.4rem;
      position: relative;
      font-weight: 600;
      bottom: 1rem;
      margin-top: 200px;
    }
  }
  .defer-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .buttons {
    display: flex;
    justify-content: flex-start;
    gap: 0.5em;
    margin-top: 0.2rem;
    & > * {
      padding: 10px 30px;
      margin: 2px 0px 0px 0px;
      font-size: 15px;
    }
  }
  .response-to-candidate {
    ${Html} {
      margin-top: 0.8rem;
      text-align: justify;
    }
  }
`
