import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChrome as fabChrome,
  faFirefoxBrowser as fabFirefoxBrowser,
  faOpera as fabOpera,
  faSafari as fabSafari
} from '@fortawesome/free-brands-svg-icons'
import {
  faCalendarAlt as farCalendarAlt,
  faClock as farClock
} from '@fortawesome/free-regular-svg-icons'
import {
  faLongArrowAltUp,
  faExternalLinkAlt,
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faDownload,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faHourglassHalf,
  faPrint,
  faSignOutAlt,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faQuestionCircle,
  faCommentDots,
  faExclamation,
  faExclamationCircle,
  faHistory,
  faPause,
  faPlay,
  faInfoCircle,
  faTimesCircle,
  faCheckCircle,
  faCalculator,
  faClipboard,
  faBaby,
  faUserCog,
  faSearchPlus,
  faSearchMinus,
  faPencilAlt,
  faTable,
  faAsterisk,
  faSpinner,
  faBook,
  faArrowAltCircleUp,
  faDesktop,
  faPoll,
  faCogs,
  faEnvelope,
  faCaretDown,
  faCaretUp,
  faHome,
  faCog,
  faCalendarDay,
  faListAlt,
  faFolderOpen,
  faChartBar,
  faSearch,
  faProjectDiagram,
  faTasks,
  faUser,
  faBookOpen,
  faTh,
  faCalendarPlus,
  faFile,
  faShapes,
  faBell,
  faGraduationCap,
  faTrash,
  faEdit,
  faPlus
} from '@fortawesome/free-solid-svg-icons'
import {
  faCalendarExclamation as farCalendarExclamation,
  faCheck as farCheck,
  faHorizontalRule as farHorizontalRule,
  faMarker,
  faMinusCircle as farMinusCircle,
  faPause as farPause,
  faPlay as farPlay,
  faRedo,
  faTrashAlt,
  faArrowAltCircleRight
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faExternalLinkAlt,
  faCalendar,
  faHourglassHalf,
  faChevronRight,
  faChevronLeft,
  faCircleNotch,
  faEye,
  faEyeSlash,
  faSignOutAlt,
  farCalendarExclamation,
  farClock,
  farCalendarAlt,
  farCheck,
  farPause,
  farPlay,
  farMinusCircle,
  farHorizontalRule,
  fabFirefoxBrowser,
  fabChrome,
  fabSafari,
  fabOpera,
  faClock,
  faThumbsUp,
  faThumbsDown,
  faPrint,
  faTimes,
  faCheck,
  faEllipsisV,
  faDownload,
  faArrowRight,
  faArrowLeft,
  faChevronUp,
  faChevronDown,
  faQuestionCircle,
  faExclamation,
  faExclamationCircle,
  faHistory,
  faPlay,
  faPause,
  faInfoCircle,
  faTimesCircle,
  faCheckCircle,
  faRedo,
  faCalculator,
  faClipboard,
  faBaby,
  faUserCog,
  faSearchPlus,
  faSearchMinus,
  faPencilAlt,
  faMarker,
  faTable,
  faAsterisk,
  faSpinner,
  faTrashAlt,
  faBook,
  faPoll,
  faCogs,
  faArrowAltCircleUp,
  faDesktop,
  faEnvelope,
  faCaretDown,
  faCaretUp,
  faHome,
  faCog,
  faCalendarDay,
  faListAlt,
  faFolderOpen,
  faSearch,
  faChartBar,
  faProjectDiagram,
  faTasks,
  faUser,
  faBookOpen,
  faTh,
  faCalendarPlus,
  faFile,
  faShapes,
  faBell,
  faGraduationCap,
  faTrash,
  faEdit,
  faCommentDots,
  faLongArrowAltUp,
  faArrowAltCircleRight,
  faPlus
)
