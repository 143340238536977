import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'
import InputSelect from './InputSelect'

const GradeInput = ({
  formikValues,
  basic,
  params,
  label = 'School Grade',
  // onChange,
  ...props
}: {
  params?: any
  basic?: boolean
  label?: string
  formikValues?: any
  // onChange?: void
}) => {
  const { t } = useTranslation()
  const [data, setData] = useState()
  // eslint-disable-next-line
  const fetchStages = useCallback(
    debounce(async (formikValues) => {
      const response = await axios.get(`${API_HOST}/school_stage-users/user_grades`, {
        params: {
          school: formikValues?.school?.codIntegrador,
          ...params
        }
      })
      setData(response.data)
      // return response.data
    }, 500),
    [params]
  )
  useEffect(() => {
    fetchStages(formikValues)
    console.log(data)
  }, [formikValues])

  return (
    <InputSelect
      key={JSON.stringify(params)}
      options={data}
      label={t(label)}
      name="grade"
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.name}
      {...props}
    />
  )
}

export default GradeInput
