import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IExamDashboardApplication, IApplication } from 'types'
import styled from 'styled-components'
import { getHistory } from 'data/apis/message-exchange'
import HistoryItemSkeleton from './HistoryItemSkeleton'
import useFeedback from 'hooks/useFeedback'
import { useTranslation } from 'react-i18next'
import { errors } from 'resources'
import ProctorHistoryItem from './HistoryItems/ProctorHistoryItem'
import CandidateHistoryItem from './HistoryItems/CandidateHistoryItem'
import ItemTitle from 'pages/AnswerSheet/components/ItemTitle'

type ChatHistoryProps = {
  className?: string
  application: IExamDashboardApplication | IApplication
  scroll?: boolean
}

const ChatHistoryDetails = ({
  className,
  application,
  scroll
}: ChatHistoryProps) => {
  const roomId = `${application.roomId}`
  const candidateId = `${application.user.id}`
  const [scrolled, setScrolled] = useState(false)
  const { handleErrorFeedback } = useFeedback()
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const messageContainerRef = useRef<HTMLDivElement>(null)

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const result = await getHistory(roomId, candidateId)
      setData(result)
    } catch (e) {
      handleErrorFeedback(e, t(errors.DEFAULT))
    } finally {
      setLoading(false)
    }
  }, [candidateId, roomId, handleErrorFeedback, t])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const renderMockSkeletons = () => {
    const skeletons = []
    const directions = {
      0: 'right',
      1: 'left'
    }
    for (let i = 0; i < 10; i++) {
      const factor = Math.round(Math.random())
      skeletons.push(<HistoryItemSkeleton direction={directions[factor]} />)
    }

    return skeletons
  }

  const scrollSmoothly = () => {
    if (!data || data.length === 0 || scrolled) {
      return
    }

    const maxTime = 0.25
    const height = messageContainerRef.current.clientHeight
    const target = messageContainerRef.current.scrollHeight
    const step = target / (maxTime * 1000)

    const interval = setInterval(() => {
      let current = messageContainerRef.current.scrollTop
      current += step
      messageContainerRef.current.scrollTop = current

      if (current + height >= target) {
        setScrolled(true)
        clearInterval(interval)
      }
    }, 1)
  }

  const renderItems = () => {
    if (!data || !data.length) return <p>Nenhum histórico encontrado</p>

    const items = []
    data.forEach((item, index) => {
      const itemTypes = {
        CANDIDATE: () =>
          items.push(
            <CandidateHistoryItem
              key={index}
              userName={application.user.name}
              item={item}
            />
          ),
        PROCTOR: () =>
          items.push(<ProctorHistoryItem key={index} item={item} />)
      }
      itemTypes[item.author || 'CANDIDATE']()
    })

    setTimeout(() => {
      scrollSmoothly()
    }, 100)

    return items.reverse()
  }

  return (
    <>
      <div ref={messageContainerRef} className={className}>
        {!scroll && (
          <ItemTitle className="title-wrapper">
            Histórico de conversas entre candidato e aplicador
          </ItemTitle>
        )}
        {isLoading && (
          <div className="message-wrapper">{renderMockSkeletons()}</div>
        )}
        {!isLoading && (
          <div id="messageExchange-history" className="message-wrapper">
            {renderItems()}
          </div>
        )}
      </div>
    </>
  )
}

export default styled(ChatHistoryDetails)`
  padding: 10px;
  height: ${(props) => (props.scroll ? '350px' : 'auto')};
  border: ${(props) => (props.scroll ? 'none' : '1px solid #f5f5f5')};
  box-shadow: ${(props) =>
    props.scroll ? 'none' : '0px 2px 2px rgba(0, 0, 0, 0.1)'};
  margin-top: ${(props) => (props.scroll ? '0' : '15px')};
  overflow-y: scroll;

  .title-wrapper {
    margin-right: 15px;
  }

  .message-wrapper {
    display: grid;
  }
`
