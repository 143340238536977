import axios from 'axios'
import { API_HOST } from 'consts'
import { AnswerSheet } from 'data/domain/exams'
import { IExam } from 'types'

export const fetchAnswerSheet = async (examId: number) => {
  if (examId !== null) {
    const response = await axios.get<AnswerSheet>(
      `${API_HOST}/v1/exams/${examId}/answer_sheet`
    )
    return response.data
  }
}

export const fetchAnswerSheetWithContent = async (examId: number) => {
  const response = await axios.get<AnswerSheet>(
    `${API_HOST}/v1/exams/${examId}/answer_sheet`,
    {
      params: {
        append_item_content: 'true'
      }
    }
  )
  return response.data
}

export const fetchBulkAnswerSheetHeader = async (
  examId: number,
  userId: number
) => {
  const response = await axios.get(
    `${API_HOST}/v1/exams/${examId}/${userId}/answer_sheet_user_header`
  )
  return response.data
}

export const fetchExams = async (params = {}) => {
  const response = await axios.get<IExam[]>(`${API_HOST}/v1/exams`, { params })
  return response.data
}

export const uploadAnswerAttachment = async (formData, applicationId) => {
  const response = await axios.post(
    `${API_HOST}/v1/applications/${applicationId}/answers/awnser_attachment`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return response.data
}
