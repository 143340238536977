import React, { useCallback, useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Formik, Form } from 'formik'
import Textarea from 'components/Formik/Inputs/textarea'
import AnswerSheetItemContent from 'pages/AnswerSheet/components/AnswerSheetItemContent'
import Button from 'components/Button'
import moment from 'moment'
import { Prompt, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { fetchItem } from 'data/apis/items'
import { AppealProps } from 'data/domain/appeals'
import { AppealContext } from 'states/AppealState'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useBeforeunload } from 'react-beforeunload'
import AttachmentUpload from 'components/AppealComponents/AttachmentUpload'
import qs from 'qs'
import { API_HOST, CORRECAO_QUESTAO_ABERTA_UI_URL } from 'consts'
import axios from 'axios'
import Card from 'components/Card/Card'

const AppealForm = ({
  application,
  className,
  initialValues,
  handleSubmit,
  postItemNavigation,
  isCancelled
}: AppealProps) => {
  const history = useHistory()
  const [selectedItem, setSelectedItem] = useState(null)
  const [correctionVistaUrl, setCorrectionVistaUrl] = useState('')
  const [candidateAnswer, setCandidateAnswer] = useState(null)
  const [getFreeResponse, setGetFreeResponse] = useState(null)
  const [correctAnswer, setCorrectAnswer] = useState(null)
  const [formChanged, setFormChanged] = useState(false)
  const fileSizeLimit = 1024 * 1024 * 10
  const { t } = useTranslation()
  const {
    appealAttachments,
    removeAttachment,
    uploadAttachedFile,
    uploadingAttachment
  } = React.useContext(AppealContext)

  const allowedMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/heic',
    'image/heif',
    'image/gif',
    'image/bmp',
    'image/*'
  ]
  const location = useLocation()
  const { a, exam_item, ch, bi } = qs.parse(location.search)

  const getAppealedItem = useCallback(async (exam_item: any): Promise<void> => {
    const handleSetItem = (res) => {
      setGetFreeResponse(res.category)
      setSelectedItem(res)
    }

    await fetchItem(exam_item)
      .then((res) => handleSetItem(res))
      .catch((e) => console.error(e))
  }, [])

  const getCorrectAnswer = useCallback(async (exam_item: any): Promise<
    void
  > => {
    await axios
      .get(`${API_HOST}/v1/items/${exam_item}/answer_sheet`)
      .then((res) => setCorrectAnswer(res.data.correctAnswer))
      .catch((err) => console.log(err))
  }, [])

  const getCandidateAnswer = useCallback(
    async (a: any, exam_item: any): Promise<void> => {
      await axios
        .get(`${API_HOST}/v1/applications/${a}/answers?item__id=${exam_item}`)
        .then((res) => setCandidateAnswer(res.data[0]))
        .catch((err) => console.log(err))
    },
    []
  )

  useEffect(() => {
    if (initialValues?.item && selectedItem) {
      getAppealedItem(initialValues.item)
    }
  })

  useEffect(() => {
    if (exam_item && !correctAnswer && postItemNavigation) {
      getAppealedItem(exam_item)
      getCandidateAnswer(a, exam_item)
      getCorrectAnswer(exam_item)
    }
  }, [
    a,
    exam_item,
    correctAnswer,
    postItemNavigation,
    getAppealedItem,
    getCandidateAnswer,
    getCorrectAnswer
  ])

  useBeforeunload((e) => {
    if (formChanged) {
      e.preventDefault()
    }
  })

  const handleAttachmentChange = async (e) => {
    const file = e.target.files[0]
    if (!file) return
    if (allowedMimeTypes.includes(file.type)) {
      if (file.size < fileSizeLimit) {
        const formData = new FormData()
        formData.append('file', file)
        return uploadAttachedFile(formData, file, appealAttachments)
      }
      return toast.error(t('File format is not allowed'))
    }
    return toast.error(t('File size must be less than 10Mb'))
  }

  const backToList = () => {
    history.push(`/answer-sheets/${application.id}/summary`)
  }

  const enableSeeCorrection =
    application?.exam.collection?.appealIssuingWindow.enableItemCorrection

  const getVistaUrl = useCallback(() => {
    if (!application) return
    axios
      .get(
        `${API_HOST}/v1/applications/${application.id}/item_correction_token`
      )
      .then((res) => {
        const token = res.data.token
        const url = `${CORRECAO_QUESTAO_ABERTA_UI_URL}/vista-prova-externa/${token}`
        setCorrectionVistaUrl(url)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [application])

  useEffect(() => {
    if (enableSeeCorrection) {
      getVistaUrl()
    }
  }, [enableSeeCorrection, getVistaUrl])

  return (
    <div className={className}>
      <Prompt
        when={formChanged}
        message={t(
          'Are you sure do you want to leave? Unsaved changes will be lost.'
        )}
      />
      <div className="appeal-issuing-form">
        <Card>
          <div className="appeal-text">
            <span>Abertura de recurso</span>
          </div>
          <div>
            <div>
              <p className="title">Avaliação: </p>
              <p>
                {application ? (
                  application.exam.name
                ) : (
                  <Skeleton width={150} height={35} />
                )}
              </p>
            </div>
            <div>
              <p className="title">Data da aplicação: </p>
              <p>
                {application ? (
                  moment(application?.startedAt).format('DD/MM/YYYY HH:mm')
                ) : (
                  <Skeleton width={150} height={35} />
                )}
              </p>
            </div>
          </div>
        </Card>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit({ ...values, item: +exam_item })
            setFormChanged(true)
          }}
        >
          {() => (
            <Form>
              <Card>
                <div style={{ margin: '30px 0px' }}>
                  <span className="question-text">{t('Question')} </span>
                  {candidateAnswer && selectedItem && (
                    <AnswerSheetItemContent
                      item={selectedItem}
                      correctAnswer={correctAnswer}
                      givenAnswer={
                        getFreeResponse === 'MULTIPLE_CHOICE'
                          ? candidateAnswer?.alternative?.letter
                          : candidateAnswer?.freeResponse
                      }
                      isCancelled={isCancelled}
                      postAlternativeExplanationVisualization={false}
                    />
                  )}
                </div>
              </Card>
              {enableSeeCorrection === true && (
                <>
                  <div className="item-correction">
                    <a
                      href={correctionVistaUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="button-item-correction" type="button">
                        Ver Vista de Atividade
                      </Button>
                    </a>
                  </div>
                </>
              )}
              <Card>
                <div className="candidate-texts">
                  <span className="textarea-titles">Justificativa *</span>
                  <Textarea name="content" maxLength={ch} />
                  <span className="textarea-titles">Bibliografia *</span>
                  <Textarea name="bibliography" maxLength={bi} />
                </div>
                <p>* Campos obrigatórios.</p>
                <AttachmentUpload
                  uploadingAttachment={uploadingAttachment}
                  attachments={appealAttachments}
                  attachmentsLimit={1}
                  onDeleteAttachment={removeAttachment}
                  onFileSelect={handleAttachmentChange}
                />
              </Card>
              <div className="buttons-container">
                <Button type="submit" action="primary">
                  Enviar
                </Button>
                <Button action="error" onClick={backToList}>
                  Voltar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default styled(AppealForm)`
  display: flex;
  justify-content: center;

  ${Card} {
    padding: 2rem;
    margin-top: 22px;
  }

  .appeal-text {
    color: ${(props) => props.theme.clientColors.primary};
    font-size: 1.5rem;
    position: relative;
    font-weight: 600;
    bottom: 0.5rem;
  }
  .question-text {
    color: ${(props) => props.theme.clientColors.primary};
    font-size: 1.1rem;
    position: relative;
    font-weight: 600;
    bottom: 0.5rem;
  }
  .text-area {
    margin: 30px 0px;
  }
  .textarea-titles {
    color: ${(props) => props.theme.clientColors.primary};
    font-size: 1.1rem;
    position: relative;
    font-weight: 600;
    bottom: 0.5rem;
  }
  .appeal-issuing-form {
    width: 50vw;
  }
  .appeal-issuing-form > div {
    &:nth-child(2) {
      margin: 30px 0px;
      display: flex;
      justify-content: space-between;

      div {
        width: 70%;
        p:nth-child(0) {
          font-weight: 400;
          font-size: 18;
        }
      }
    }
  }
  .title {
    font-size: 15px;
    font-weight: 700;
  }
  .buttons-container {
    display: flex;
    justify-content: flex-start;
    gap: 2.5em;
    & > * {
      padding: 10px 30px;
      margin: 20px 0px 25px 0px;
      font-size: 15px;
    }
  }
  hr {
    opacity: 0.5;
    margin: 20px 0px 50px 0px;
  }
  .attachment-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  .attachment-helper-text {
    font-size: 12px;
    margin: 10px;
  }
  .view-attachment {
    padding-left: 30px;
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
  }
  .fa-icon {
    cursor: pointer;
    padding: 7px;
    border-radius: 50%;
    background-color: #ddd;
    color: #333;
  }

  .item-correction {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
  }

  .button-item-correction {
    border: 1px solid ${(props) => props.theme.clientColors.secondary};
    background-color: #fff;
    font-size: 1rem;
    &:hover {
      color: #fff;
      background: ${(props) => props.theme.clientColors.secondaryDark};
    }
  }

  .iframe {
    width: 100%;
    background: #fff;
    height: 55vh;
  }

  .draggableModal {
    width: 55vw;
    height: 60vh;
    display: flex;
    background: #fff;
    border: 1px solid #ddd;
    z-index: 1;
    justify-content: center;
  }

  @media (max-width: 800px) {
    .iframe {
      width: 95vw;
      background: #fff;
      height: 65vh;
    }

    .draggableModal {
      width: 105vw;
      height: 75vh;
      display: flex;
      background: #fff;
      border: 1px solid #ddd;
      z-index: 1;
      justify-content: center;
    }

    .appeal-issuing-form {
      width: 100%;
    }
    .view-attachment {
      width: 80%;
    }
  }
`
