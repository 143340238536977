import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type CardFetchErrorProps = {
  className?: string
  onRetry: () => Promise<any>
}

const CardFetchError = ({ className, onRetry }: CardFetchErrorProps) => (
  <div className={className}>
    <button onClick={onRetry}>
      <FontAwesomeIcon icon={['far', 'redo']} />
      <span>Tentar novamente</span>
    </button>
  </div>
)

export default styled(CardFetchError)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    transition: all 0.3s;
    font-size: 14px;
    border: none;
    background-color: transparent;
    padding: 0;
    color: #606060;
    letter-spacing: 1px;
    cursor: pointer;

    span {
      margin-left: 10px;
      color: #606060;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`
