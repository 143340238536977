import React, { useState } from 'react'
import clsx from 'clsx'
import { Button } from 'reactstrap'
import {
  makeStyles,
  createStyles,
  useTheme,
  createTheme
} from '@material-ui/core/styles'
import MenuDrawer from './MenuDrawerEducat'
import { Link } from 'react-router-dom'
import { AppMenuDrawer } from './ExamDashboardComponents/AppMenuDrawer'
import styled from 'styled-components'
import DropdownMenuExam from './DropdownMenuExam'
import {
  Grid,
  Drawer,
  AppBar,
  CssBaseline,
  IconButton,
  Toolbar,
  useMediaQuery,
  Typography
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

type HeaderMenuDrawerProps = {
  theme?: any
  user?: any
  application?: any
  token?: any
  askLogoutConfirmation?: any
  hasGroup?: any
  className?: string
  children?: any
}

const drawerWidth = 300

const themeWidth = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1380,
      xl: 1920
    }
  }
})

const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    },
    inputRoot: {
      color: 'inherit'
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [themeWidth.breakpoints.up('lg')]: {
        display: 'none'
      }
    },
    appBar: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      border: '2px solid #E6E6E6',
      borderLeft: 'none',
      // boxShadow: '0px 2px 0px 0px rgb(38, 41, 48 , 0.1)',
      boxShadow: 'none',
      width: '100%'
    },
    appBarStudent: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),

      borderLeft: 'none',
      // boxShadow: '0px 2px 0px 0px rgb(38, 41, 48 , 0.1)',
      boxShadow: 'none',
      height: '64px'
    },
    appBarShift: {
      height: '64px',
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    hide: {
      display: 'none'
    },
    drawer: {
      display: 'flex'
    },
    drawerOpen: {
      display: 'flex',
      flexDirection: 'column',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      backgroundColor: theme.palette.primary.main,
      border: 'none',
      overflowX: 'hidden'
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: '64px'
      },
      backgroundColor: theme.palette.primary.main,
      border: 'none'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(4)
    }
  })
)

const HeaderDrawer = ({
  theme,
  user,
  application,
  token,
  askLogoutConfirmation,
  hasGroup,
  children,
  className
}: HeaderMenuDrawerProps) => {
  const classes = useStyles()
  const themes = useTheme()

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const mdUp = useMediaQuery(themes.breakpoints.up('lg'))

  return (
    <div>
      <div>
        <CssBaseline />

        <div className={classes.grow}>
          <AppBar
            position="fixed"
            style={{ height: '60px', backgroundColor: '#fff' }}
          >
            <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
              <div className={classes.sectionMobile}>
                <IconButton
                  className="btn-menu-drawer"
                  size="medium"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={!open ? handleDrawerOpen : handleDrawerClose}
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <Link to="/" style={{ marginLeft: '2.5rem' }}>
                <img height={55} src={theme.logoImg} alt={theme.name} />
              </Link>
              {application && (
                <Typography
                  className={classes.title}
                  style={{ color: 'black' }}
                >
                  {application?.exam.name}
                </Typography>
              )}
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <DropdownMenuExam
                  user={user}
                  application={application}
                  askLogoutConfirmation={askLogoutConfirmation}
                  hasGroup={hasGroup}
                  token={token}
                />
              </div>

            </Toolbar>
          </AppBar>
        </div>

        <div className={className}>
          {mdUp ? (
            <Drawer
              className={`side-drawer ${open && 'visible'}`}
              onMouseMove={handleDrawerOpen}
              onMouseLeave={handleDrawerClose}
              variant="permanent"
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open
                })
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  overflow: 'hiden'
                }}
              >
                {clsx({
                  [classes.drawerClose]: open
                }) ? (
                  <img
                    className="img-plurall"
                    src={theme.logoSideBar}
                    style={{
                      height: '50px',
                      marginBottom: '16px',
                      marginTop: '16px',
                      justifyContent: 'center',
                      alignSelf: 'center'
                    }}
                  />
                ) : (
                  <img
                    className="img-plurall-close"
                    src={theme.smallLogo}
                    style={{ height: '40px', margin: '16px 12px 16px 12px' }}
                  />
                )}
                <AppMenuDrawer>
                  <MenuDrawer
                    drawer={clsx({
                      [classes.drawerClose]: !open
                    })}
                    leave={!open}
                  />
                </AppMenuDrawer>
              </div>
            </Drawer>
          ) : (
            <Drawer
              className={`side-drawer ${open && 'visible'}`}
              open={open}
              onClose={handleDrawerClose}
              variant="temporary"
              classes={{ paper: classes.drawerOpen }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  overflow: 'hiden'
                }}
              >
                <AppMenuDrawer>
                  {clsx({
                    [classes.drawerClose]: open
                  }) ? (
                    <img
                      className="img-plurall"
                      src={theme.logoSideBar}
                      style={{
                        height: '50px',
                        marginBottom: '16px',
                        marginTop: '16px',
                        justifyContent: 'center',
                        alignSelf: 'center'
                      }}
                    />
                  ) : (
                    <img
                      className="img-plurall-close"
                      src={theme.smallLogo}
                      style={{ height: '40px', margin: '16px 12px 16px 12px' }}
                    />
                  )}
                  <MenuDrawer
                    drawer={clsx({
                      [classes.drawerClose]: !open
                    })}
                    leave={!open}
                  />
                  <Grid item container justifyContent="center" xs={12}>
                    <Grid xs={12} className="menu-exam-mbile">
                      <Button
                        style={{
                          textAlign: 'left',
                          width: '100%',
                          justifyContent: 'flex-start',
                          borderRadius: '8px',
                          color: '#fff',
                          padding: '1rem',
                          fontWeight: 700,
                          marginTop: '-1rem'
                        }}
                        onClick={askLogoutConfirmation}
                        className="dropdown-toggle-container"
                      >
                        <ExitToAppIcon style={{ marginRight: '0.8rem' }} /> Sair
                      </Button>
                    </Grid>
                  </Grid>
                </AppMenuDrawer>
              </div>
            </Drawer>
          )}
        </div>
        <main className={classes.content}>{children}</main>
      </div>
    </div>
  )
}

export default styled(HeaderDrawer)`
  width: 100%;
  color: #4a4a4a;

  .visible {
    display: flex !important;
  }

  @media only screen and (max-width: 1440px) {
    .close-menu-mobile {
      display: flex;
      padding: 8px 8px 0 0;
      path {
        fill: #e3e5e8;
      }
    }
    .side-drawer {
      display: none;
    }
    .top-menu {
      display: none !important;
    }
    .menu-exam-mbile {
      display: flex;
      justify-content: center;
      padding: 0;
      width: 100%;

      path {
        fill: #e3e5e8 !important;
      }

      .name-application {
        display: none;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    .close-menu-mobile {
      display: none;
    }
    .btn-menu-drawer {
      display: none;
    }
    .menu-exam-mbile {
      display: none !important;
    }
  }

  .profile {
    padding: 2px 6px 2px 2px;
    display: flex;
    align-items: center;
    .profile-picture {
      color: #fff;
      width: 32px;
      height: 32px;
      background: ${(props) => props.theme.clientColors.primary} !important;
      border: none;

      font-size: 14px;
    }

    &:hover {
      padding: 2px 6px 2px 2px;
      transition: all 0.2s;
      border-radius: 50px;
      background: ${(props) => props.theme.clientColors.apllication} !important;
    }
  }
  .header {
    display: flex;
    max-width: 1400px;
    width: 100%;
    height: 64px;
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
  }

  .flex {
    display: flex;
    align-items: center;

    .name-application {
      /* margin-right: 0.9rem; */
      color: #4a4a4a;
      text-align: center;
      align-content: center;
    }
  }

  ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 110%;
    list-style: none;
    align-items: center;
    text-align: right;
    padding: 10px 30px 0px 0px;
  }

  @media (max-width: 1500px) and (min-width: 426px) {
    .header img {
      margin-left: 50%;
    }
  }
`
