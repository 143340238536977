import '@brainhubeu/react-carousel/lib/style.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/css/main.css'
import './i18n'
import './index.css'
import * as serviceWorker from './serviceWorker'
import './utils/axios'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { SENTRY_KEY } from 'consts'

// Sentry.init({
//   dsn: SENTRY_KEY,
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })

ReactDOM.render(
  <React.StrictMode>
    <App browserIsCompatible />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
