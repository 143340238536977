import { AGORA_CHANNEL_PREFIX } from 'consts'
import { addStreamingLog } from 'data/apis/streaming-logs'
import { StreamingLog } from 'data/domain/streaming-log'

export const getChannelName = (provider: string, roomId: number) =>
  `${AGORA_CHANNEL_PREFIX}${provider}_${roomId}`

export const getEncodingSettings = () => ({
  frameRate: {
    min: 15,
    max: 30
  },
  resolution: {
    width: 120,
    height: 120
  },
  bitrate: {
    min: 15,
    max: 30
  }
})

export const getDevices = async () => {
  return await navigator.mediaDevices.enumerateDevices()
}

export const setAgoraClientListenerLog = (
  client: any,
  candidateId: number,
  prefix = ''
) => {
  const events = [
    'crypt-error',
    'client-banned',
    'liveStreamingFailed',
    'liveStreamingStopped',
    'streamInjectedStatus',
    'onTokenPrivilegeWillExpire',
    'onTokenPrivilegeDidExpire',
    'error',
    'network-type-changed',
    'recording-device-changed',
    'playout-device-changed',
    'camera-changed',
    'stream-type-changed',
    'connection-state-change',
    'stream-reconnect-start',
    'stream-reconnect-end',
    'reconnect',
    'rejoin',
    'stream-fallback',
    'exception',
    'enable-local-video',
    'disable-local-video',
    'channel-media-relay-event',
    'channel-media-relay-state'
  ]

  events.forEach((event) => {
    client.on(event, (evt: any) => {
      addStreamingLog({
        action: `${prefix}_${event.toUpperCase()}`,
        message: JSON.stringify(evt),
        candidateId
      } as StreamingLog)
    })
  })
}
