import { createContext } from 'react'
import { IDashboardVideosSocketState } from 'types'

export const initialState: IDashboardVideosSocketState = {
  candidatesActivityStatus: {},
  candidatesBreakInfo: {},
  candidatesConnectionStatus: {},
  candidatesMessageStatus: {},
  candidatesFinishedStatus: {},
  candidatesPausedStatus: {},
  candidatesPermissionStatus: {},
  pauseCandidateExam: () => Promise.resolve(),
  resumeCandidateExam: () => Promise.resolve(),
  sendAlert: () => Promise.resolve(),
  sendBreakResponse: () => undefined,
  setActivityAsRead: () => undefined,
  setMessageAsRead: () => undefined,
  getExamStatus: () => undefined,
  sendReloadRequest: () => Promise.resolve(),
  joinRoomAsApplicator: () => undefined,
  sendNotificationToCandidate: () => undefined,
  socket: null,
  onlineAmount: 0,
  finishedAmount: 0,
  sendMessageToAllOnline: () => undefined,
  messagesList: []
}

export default createContext<IDashboardVideosSocketState>(initialState)
