import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

type CircularBorderLoaderProps = {
  className?: string
  percentage: number
  size: number
}

const CircularBorderLoader = ({
  className,
  percentage,
  size
}: CircularBorderLoaderProps) => {
  const [offset, setOffset] = useState(0)

  const lineWidth = 4
  const radius = size >> 1
  const radiusNormalization = radius - lineWidth * 2
  const circumference = radiusNormalization * 2 * Math.PI

  useEffect(() => {
    const offsetCalc = circumference - (percentage / 100) * circumference
    setOffset(offsetCalc)
  }, [percentage, circumference])

  return (
    <svg className={className} height={60} width={60}>
      <circle
        stroke="#ff0000"
        fill="transparent"
        strokeWidth={lineWidth}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset: offset }}
        r={radiusNormalization}
        cx={radius}
        cy={radius}
      />
    </svg>
  )
}

export default styled(CircularBorderLoader)`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`
