import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'

const AppealResultInput = ({
  params,
  ...props
}: {
  params?: any
  basic?: boolean
}) => {
  // eslint-disable-next-line
  const fetchAppealResult = useCallback(
    debounce(async (search) => {
      const response = await axios.get(`${API_HOST}/v1/appeals-result`, {
        params: {
          search,
          ...params
        }
      })
      return response.data
    }, 500),
    [params]
  )

  return (
    <InputAsync
      key={JSON.stringify(params)}
      defaultOptions
      loadOptions={fetchAppealResult}
      name="result"
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.description}
      {...props}
    />
  )
}

export default styled(AppealResultInput)``
