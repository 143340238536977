import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import {toast} from 'react-toastify'
import axios from 'axios'
import { API_HOST } from 'consts'
import Skeleton from 'react-loading-skeleton'
import ModalReprove from 'components/ModalReprove'
import ExamInputSelect from 'components/Inputs/ExamInputSelect'
import { fetchAnswerSheet } from 'data/apis/exams'
import { AnswerSheet } from 'data/domain/exams'
import usePreference from 'hooks/usePreference'
import { AuthContext } from 'states/AuthState'

import { useQuery } from 'react-query'
import { get } from 'lodash'

import MultiQuestionModal from 'components/ExamDashboardComponents/MultiQuestionModal'


type DashboardViewProps = {
    className?: string
    answerSheet?: AnswerSheet
}

type StatisticsItem = {
    id: number
    question: string
    category: string
    position: number
}

type ItemAnswerStats = {
    correct: number
    wrong: number
}

export type StatisticsResponse = {
    applications: {
        totalApplications: number
        unfinished: number
        finished: number
        missing: number
    }
    answers: {
        total: number
        correct: number
        wrong: number
        mostCorrect: {
            item: StatisticsItem
            stats: ItemAnswerStats
        }
        mostWrong: IMostWrong[]
        averageTimePerQuestion: number
    }
}
export interface IMostWrong {
    item: StatisticsItem
    stats: ItemAnswerStats
}
const DashboardView = ({ className }: DashboardViewProps) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const [examId, setExamId] = useState(undefined)
    const [isEvaluation, setIsEvaluation] = useState(undefined)
    const [listItem, setListItem] = useState([])
    const [isApproved,setIsApproved] = useState(false)
    const [modalReprove, setModalReprove] = useState(false)
    const SharedFlow =get(usePreference(
        'Shared_Data__ActivateSharedFlow'
      ),'value')
    const { hasGroup } = useContext(AuthContext)
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const collectionId = urlParams.get('collection') && parseInt(urlParams.get('collection'))

    const { data: answerSheet } = useQuery(
        ['answer-sheet', examId],
        (_, _examId: number) => fetchAnswerSheet(_examId),
        {
            refetchOnMount: false
        }
    )

    const handleExamChange = (event) => {
        if (!event) {
            setExamId(null)
            return
        }

        setExamId(event.id)
        setIsEvaluation(event.collection.categoryExam == 1);
    }
    useEffect(() => {
        axios.get(
            `${API_HOST}/v1/exams/${examId}/exam_item_by_position`
        ).then((res) => { setLoading(false); setListItem(res.data) });
        axios.get(
            `${API_HOST}/v1/exam_approval/get_approval?exam_id=${examId}`
        ).then((res) => setIsApproved(res.data.data))
    }, [examId]
    )
    const handleApprove = (examId, value, justification) => {
        const params = {
            exam_id : examId,
            approval : value,
            justification: justification,
        }
        try {
          axios.get(`${API_HOST}/v1/exam_approval/set_approval`, {params}).then((response) => {
            setModalReprove(false);
            setIsApproved(true);
            toast.success('Sua avaliação foi enviada com sucesso!')
          })
        } catch (e) {
          toast.error('Ocorreu um erro em efetuar a sua avaliaçõa.')
        }
    }

    return (
        <div className={className}>
            <ModalReprove
                className='modal'
                setIsOpen={setModalReprove}
                isOpen = {modalReprove}
                fetchReproved={handleApprove}
                examId={examId}
            />
            <div className="filter-wrapper">
                <Formik initialValues={{}} onSubmit={() => { }}>
                    <ExamInputSelect
                        collectionId={collectionId}
                        onChange={handleExamChange}
                        label={t('Exam')}
                        usePreSelect={true}
                    />
                </Formik>
                {(!isApproved && examId && isEvaluation && SharedFlow && hasGroup('Professor')) &&
                <>
                <button
                    className='btn'
                    style={{background:'rgb(134, 59, 255)'}}
                    onClick={()=>handleApprove(examId, true, '')}>
                    Aprovar
                </button>
                <button
                    className='btn'
                    onClick={()=>setModalReprove(true)}>
                    Reprovar
                </button>
                </>
                }
            </div>
            {loading && <Skeleton height={300} />}
            {!loading &&
                < MultiQuestionModal
                    examId={examId}
                    item={listItem}
                    answerSheet={answerSheet}
                />}
        </div>
    )
}

export default styled(DashboardView)`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 1rem;

  .square-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-left: 1rem;
  }

  .progress {
    margin-top: 1rem;
    width: 100%;
  }

  .chart-parent {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .time {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.94px;
    color: #606060;
  }

  .btn{
    margin-top:1rem;
    color:#fff;
    background:#c63f3f;
    width:100%;
    border:none;
    height:30px;
    border-radius:8px;
    transition: 0.5s;
    text-align: center;
  }



  .btn:hover{
    opacity: 0.7;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;

    .square-grid {
      grid-template-columns: 1fr;
    }
  }
`
