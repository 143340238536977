import React, { useEffect, useState } from 'react'
import { Config } from 'data/domain/config'
import ConfigContext, { initialConfig } from 'contexts/ConfigContext'

type ConfigStateProps = {
  children: React.ReactNode
}

export const ConfigState = ({ children }: ConfigStateProps) => {
  const [config] = useState<Config>(initialConfig)

  useEffect(() => {
    // TODO: Fetch config from API
  }, [])

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}

export default ConfigState
