import Grid from '@material-ui/core/Grid'
import Axios from 'axios'
import CardPlural from 'components/CardPlural'
import Page from 'components/Page'
import { API_HOST } from 'consts'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import ErrorWithMessage from 'components/ErrorWithMessage'
import SearchBarWrapper from 'components/ExamDashboardComponents/SearchBarWrapper'

type BillBoardPageProps = {
  className?: string
}

const BillBoardPage = ({ className }: BillBoardPageProps) => {
  const [notices, setNotices] = React.useState<any>(null)

  const searchField = (search: any) => {
    Axios.get(`${API_HOST}/billboard?search=${search}`).then((res) => {
      setNotices(res.data)
    })
  }

  const fetchNotices = () => {
    Axios.get(`${API_HOST}/billboard/get_notices`)
      .then((res) => {
        const data = res.data
        if (data.length > 0) {
          return setNotices(data)
        }
        return setNotices('')
      })
      .catch((err) => {
        toast.error('Não foi possível encontrar avisos')
        console.error(err)
      })
  }

  useEffect(() => {
    if (notices === null) {
      fetchNotices()
    }
  }, [notices])

  return (
    <div className={className}>
      <Page>
        <div className="search-content">
          <SearchBarWrapper
            placeholder="Pesquisar..."
            onSearchChange={(e) => searchField(e.target.value)}
          />
        </div>
        {notices === '' ? (
          <ErrorWithMessage message="Nenhuma mensagem disponível " />
        ) : (
          <Grid
            container
            spacing={2}
            style={{ width: '100%', marginTop: '10px' }}
          >
            {notices &&
              notices.map((notice, index) => (
                <>
                  <Grid item xs={6} key={index} style={{ width: '100%' }}>
                    <CardPlural
                      key={index}
                      title={notice.noticeTitle}
                      content={notice.noticeText}
                      activeSinceDay={notice?.activeSinceDay}
                      activeSinceTime={notice?.activeSinceTime}
                      refetch={fetchNotices}
                      noticeId={notice?.id}
                      status={notice?.status}
                      index={index}
                    />
                  </Grid>
                </>
              ))}
          </Grid>
        )}
      </Page>
    </div>
  )
}
export default styled(BillBoardPage)`
  position: relative;
  left: 0;
`
