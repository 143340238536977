/* eslint no-unused-vars: 0 */
import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IMostWrong } from 'pages/DashboardStatistics'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LightBorderCard from '../Card/LightBorderCard'

type QuestionGradedCardProps = {
  success?: boolean
  questionIndex: IMostWrong[]
  percentage: IMostWrong[]
  className?: string
}

const QuestionGradedCard = ({
  success = false,
  questionIndex,
  percentage,
  className
}: QuestionGradedCardProps) => {
  const text = success
    ? 'Question with the highest success rate'
    : 'Questions with the highest failure rate'
  const { t } = useTranslation()

  const getQuestionHitPercentage = (item: IMostWrong) => {
    const stats = item.stats
    return stats && (stats.wrong / (stats.correct + stats.wrong)) * 100
  }

  const sortMostWrongItem = (a: IMostWrong, b: IMostWrong) => {
    if (a.item && b.item) {
      if (a.item.position > b.item.position) return 1
      if (a.item.position < b.item.position) return -1
    }
    return 0
  }

  return (
    <LightBorderCard className={className}>
      <span>
        {success ? (
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="2x"
            color="green"
          ></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon
            icon={faTimesCircle}
            size="2x"
            color="red"
          ></FontAwesomeIcon>
        )}
      </span>
      <span className="link">{t(text)}</span>
      {percentage.sort(sortMostWrongItem).map((question) => (
        <p className="percent" key={question.item && question.item.id}>
          <span
            className="question-number"
            key={question.item && question.item.position}
          >
            Nº{question.item?.position}
          </span>
          :{' '}
          {getQuestionHitPercentage(question) !== undefined
            ? getQuestionHitPercentage(question).toFixed(2) + '%'
            : ''}
          {t('of students got it wrong')}
        </p>
      ))}
      {questionIndex.length === 0 && <p>{t('Not enough data to calculate')}</p>}
    </LightBorderCard>
  )
}

export default styled(QuestionGradedCard)`
  text-align: center;
  .question-number {
    font-weight: 600;
  }

  .flexbox-horizontal {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    font-weight: 600;
    font-size: 25px;
  }

  .link {
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.47px;
    color: #10a4b7;
  }

  .percent {
    font-size: 13px;
    height: 5px;
  }
`
