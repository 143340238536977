import React from 'react'
import { CandidateMessage } from './CandidateVideo'
import { Popover } from '@material-ui/core'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/pt-br'

type CandidateMessagePopoverProps = {
  question: CandidateMessage
  anchorElement: any
  open: boolean
  onClose: () => void
  className?: string
}

const CandidateMessagePopover = ({
  question,
  anchorElement,
  open,
  onClose,
  className
}: CandidateMessagePopoverProps) => {
  if (!anchorElement) {
    return <div></div>
  }

  const style = {
    padding: '15px'
  }

  const questionTime = moment(question.when).locale('pt-br').fromNow()

  return (
    <Popover
      className={`${className} root-element`}
      anchorEl={anchorElement}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      onClose={onClose}
      style={style}
    >
      <div className="inner-container">
        <p className="question-text">{question.message}</p>
        <hr />
        <p className="question-time">{questionTime}</p>
      </div>
    </Popover>
  )
}

export default styled(CandidateMessagePopover)`
  .question-text {
    line-break: auto;
  }

  .inner-container {
    width: 300px;
    padding: 15px;
  }

  .question-time {
    font-size: 11px;
  }
`
