import { IAnswer, IApplicationConfiguration } from 'types'
import db from '../../db'

export const isAnswered = (answer: IAnswer) =>
  !!(
    answer.alternative ||
    answer.freeResponse ||
    answer.alternatives?.length > 0
  )

export const isFinished = (answer: IAnswer) => answer.finished === true

export const findNextAvaiableAnswer = async (
  applicationId: string | number,
  configuration: IApplicationConfiguration,
  startsWith = 0
): Promise<IAnswer> => {
  return await db.answers
    .orderBy('position')
    .filter((element) => {
      const conditions = [
        element.timeoutDate === null,
        +element.application.id === +applicationId,
        element.position > startsWith
      ]

      if (!configuration?.canBrowseAcrossItems) {
        conditions.push(!isFinished(element))
      }

      return conditions.every((v) => v)
    })
    .first()
}
