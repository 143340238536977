import axios from 'axios'
import { API_HOST } from 'consts'
import { RoomUserList } from 'data/domain/rooms'

export const getRoomUsers = (roomId: string) => {
  return axios
    .get<RoomUserList>(`${API_HOST}/v1/rooms/${roomId}/users`, {
      params: { pageSize: 50 }
    })
    .then((response) => response.data.results)
}
