import React from 'react';
import styled from 'styled-components';

type ProgressBarProps = {
  current: number;
  total: number;
};

const ProgressBarContainer = styled.div`
  display: flex;
  width: 12.5rem;
  padding: 0rem;
  align-items: center;
  background-color: #E4E4E4;
  overflow: hidden;
  height: 1.25rem;
  position: relative;
  margin-top: 10px;
`;

const Progress = styled.div<{ width: number; completed: boolean }>`
  height: 100%;
  width: ${(props) => (props.completed ? 100 : Math.min(props.width, 100))}%;
  background-color: ${(props) => (props.completed ? '#7c47f6' : '#7c47f6')};
`;

const TextOverlay = styled.div`
 position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.25rem;
  width: 100%;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({ current, total }) => {
  const progress = (current / total) * 100;
  const completed = current === total;
  return (
    <ProgressBarContainer>
      <Progress width={progress} completed={completed} />
      <TextOverlay>
        {current} / {total === null ? 0 : total}
      </TextOverlay>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
