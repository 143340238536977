import { getGroup } from 'data/apis/groups'
import { Group } from 'data/domain/groups'
import { useEffect, useState } from 'react'

const useGroup = (groupCode: string) => {
  const [group, setGroup] = useState<Group>(undefined)

  useEffect(() => {
    if (!groupCode) {
      return
    }
    getGroup(groupCode).then((data) => setGroup(data))
  }, [groupCode])

  return group
}

export default useGroup
