import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'
import { childrenIsFunction, IDashboardVideosState } from '../types'
import { getRoomUsers } from 'data/apis/rooms'
import { useQuery } from 'react-query'
import RoomStreamingManager from 'managers/roomStreaming'

const initialState = {
  fetching: false,
  applications: [],
  fetchError: '',
  roomId: -1,
  socket: null,
  roomData: null,
  pauseCandidateExam: () => {},
  resumeCandidateExam: () => Promise.resolve(),
  sendAlert: () => {},
  candidatesConnectionStatus: {},
  candidatesMessageStatus: {},
  candidatesActivityStatus: {},
  candidatesBreakInfo: {},
  setMessageAsRead: () => undefined,
  setActivityAsRead: () => undefined,
  sendBreakResponse: () => undefined,
  users: []
}

export const DashboardVideosContext = createContext<IDashboardVideosState>(
  initialState
)

type DashboardVideosStateProps = {
  roomId: number
  children: Function | ReactNode
}

const DashboardVideosState = ({
  roomId,
  children
}: DashboardVideosStateProps) => {
  const [streamings, setStreamings] = useState([])

  const { data, isLoading } = useQuery(
    ['room-users', roomId.toString()],
    (_, _roomId: string) => {
      return getRoomUsers(_roomId)
    },
    {
      // initialData: []
    }
  )
  const contextValue = {
    applications: [],
    fetching: isLoading,
    users: data || [],
    fetchError: '',
    roomId,
    roomData: null,
    streamings
  }

  const onStreamAdded = useCallback(
    (client, obj) => {
      const newStreamings = {
        ...streamings,
        [obj.stream.streamId]: {
          obj,
          client,
          action: 'JOIN'
        }
      }
      setStreamings(newStreamings)
    },
    [streamings]
  )

  useEffect(() => {
    setStreamings([])
  }, [roomId])

  const onPeerLeave = useCallback(
    (client, obj) => {
      const newStreamings = {
        ...streamings,
        [obj.stream.streamId]: {
          obj,
          client,
          action: 'LEAVE'
        }
      }
      setStreamings(newStreamings)
    },
    [streamings]
  )

  const getChannels = useCallback(
    () => [
      ...new Set(data?.filter((u) => u.channelName).map((u) => u.channelName))
    ],
    [data]
  )

  useEffect(() => {
    const channels = getChannels()
    if (!channels.length) return
    channels.forEach((channelName) => {
      if (!RoomStreamingManager.client[channelName]) {
        RoomStreamingManager.createClientStreaming(
          channelName,
          onStreamAdded,
          onPeerLeave
        )
      }
    })
    Object.keys(RoomStreamingManager.client).forEach((streamChannel) => {
      if (!channels.includes(streamChannel)) {
        RoomStreamingManager.leaveChannel(streamChannel)
      }
    })
  }, [getChannels, onStreamAdded, onPeerLeave, roomId])

  return (
    <DashboardVideosContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </DashboardVideosContext.Provider>
  )
}

export default DashboardVideosState
