import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type SubtitleDisplayRoomProps = {
  className?: string
  room?: number
}

const SubtitleDisplayRoom = ({ room, className }: SubtitleDisplayRoomProps) => {
  const { t } = useTranslation()

  return (
    <>
      {room && (
        <span className={className}>
          <FontAwesomeIcon className="icon-space" icon={faHome} />
          {t('Room').toUpperCase()}: {room}
        </span>
      )}
    </>
  )
}

export default styled(SubtitleDisplayRoom)`
  font-size: 12px;

  .icon-space {
    margin-right: 10px;
  }
`
