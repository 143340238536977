import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
}

const CancelledItemIndicator = ({ className }: Props) => (
  <span className={className}>Questão anulada</span>
)

export default styled(CancelledItemIndicator)`
  font-weight: 500;
  color: ${(props) => props.theme.colors.danger};
`
