import React from 'react'
import { AnswerSheetOrder } from '../../types'
import BulkOrderInfoCard from './BulkOrderInfoCard'

type AnswerSheetBulkOrderCardProps = {
  answerSheetOrder: AnswerSheetOrder
}

const AnswerSheetBulkOrderCard = ({
  answerSheetOrder
}: AnswerSheetBulkOrderCardProps) => {
  return <BulkOrderInfoCard answerSheetOrder={answerSheetOrder} />
}

export default AnswerSheetBulkOrderCard
