import React from 'react'
import Breadcrumb from 'components/Breadcrumb'
import BreadcrumbLink from 'components/BreadcrumbLink'

type Props = {
  className?: string
  collectionId: number
  collectionName: string
  examName: string
  applicationId: number
}

const AnswerSheetBreadcrumb = ({
  applicationId,
  examName,
  collectionId,
  collectionName
}: Props) => {
  return (
    <Breadcrumb>
      <BreadcrumbLink to="/">Início</BreadcrumbLink>
      <BreadcrumbLink to={`/applications?collection=${collectionId}`}>
        {collectionName}
      </BreadcrumbLink>
      <BreadcrumbLink to={`/answer-sheets/${applicationId}/summary`} isActive>
        {examName}
      </BreadcrumbLink>
    </Breadcrumb>
  )
}
export default AnswerSheetBreadcrumb
