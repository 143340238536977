import React from 'react'
import { useTranslation } from 'react-i18next'
import HistoryItem, { HistoryItemProps } from './HistoryItem'

const ProctorHistoryItem: React.FC<Partial<HistoryItemProps>> = ({ item }) => {
  const { t } = useTranslation()
  return (
    <HistoryItem
      author={t('Sent by the proctor')}
      direction="right"
      item={item}
    />
  )
}

export default ProctorHistoryItem
