import { useEffect, useCallback } from 'react'
import {
  setOnlineStatus,
  setOfflineOnDisconnect,
  onConnected
} from 'data/apis/online-status'
import { RollbarErrorTracking } from 'infra/rollbar'
import { FIREBASE_ENABLED } from 'consts'

const useOnline = (userId: string) => {
  const set = useCallback(
    (online: boolean) => {
      if (!userId || !FIREBASE_ENABLED) {
        return
      }
      return setOnlineStatus(userId, online).catch(
        RollbarErrorTracking.logError
      )
    },
    [userId]
  )

  const setOnline = useCallback(() => {
    return set(true)
  }, [set])

  const setOffline = useCallback(() => {
    return set(false)
  }, [set])

  // Add disconnect listener
  useEffect(() => {
    if (!userId || !FIREBASE_ENABLED) {
      return
    }
    const cancelToken = onConnected(() => {
      setOnlineStatus(userId, true).catch(RollbarErrorTracking.logError)
      setOfflineOnDisconnect(userId).catch(RollbarErrorTracking.logError)
    })
    return () => {
      cancelToken()

      if (userId) {
        setOnlineStatus(userId, false).catch(RollbarErrorTracking.logError)
      }
    }
  }, [userId])

  return { setOnline, setOffline }
}

export default useOnline
