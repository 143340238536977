import Button from 'components/Button'
import Page from 'components/Page'
import { RollbarErrorTracking } from 'infra/rollbar'
import { getDevices } from 'infra/streaming'
import React, { useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import styled from 'styled-components'

type CameraEnumerationProps = {
  className?: string
  onSelect: ({ video, audio }: { video: string; audio: string }) => void
  onError: () => void
}

const CameraEnumeration = ({
  className,
  onSelect,
  onError
}: CameraEnumerationProps) => {
  const [loading, setLoading] = useState(false)
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([])
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([])
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<
    MediaDeviceInfo
  >(undefined)
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<
    MediaDeviceInfo
  >(undefined)

  useEffect(() => {
    if (videoDevices.length) {
      return
    }
    setLoading(true)
    try {
      getDevices()
        .then((dev) => {
          const videoInputDevices = dev.filter((elem) => {
            return elem.kind === 'videoinput' && elem.deviceId !== 'default'
          })
          const audioInputDevices = dev.filter((elem) => {
            return elem.kind === 'audioinput' && elem.deviceId !== 'default'
          })
          // if there is only one video input, select the first one
          if (
            videoInputDevices.length === 1 &&
            audioInputDevices.length === 1
          ) {
            onSelect({
              video: videoInputDevices[0].deviceId,
              audio: audioInputDevices[0].deviceId
            })
            return
          }
          setVideoDevices(videoInputDevices)
          setAudioDevices(audioInputDevices)

          setSelectedVideoDevice(videoInputDevices[0])
          setSelectedAudioDevice(audioInputDevices[0])
        })
        .catch((e) => {
          RollbarErrorTracking.logError(e)
        })
    } catch (e) {
      onError()
    } finally {
      setLoading(false)
    }
  }, [videoDevices.length, setLoading, onSelect, onError])

  const handleClick = useCallback(() => {
    if (!selectedVideoDevice || !selectedAudioDevice) {
      toast.error('Por favor, selecione um microfone e uma camera')
    }
    onSelect({
      video: selectedVideoDevice?.deviceId,
      audio: selectedAudioDevice?.deviceId
    })
  }, [selectedVideoDevice, selectedAudioDevice, onSelect])

  return (
    <Page
      customWidth={1366}
      title="Seleção de dispositivos"
      className={className}
    >
      {!loading && videoDevices.length > 0 && (
        <p className="title">
          Detectamos mais de uma câmera e/ou microfone no seu sistema. Selecione
          os dispositivos que deseja utilizar para esta atividade.
        </p>
      )}
      {loading && <Skeleton count={3} height={80}></Skeleton>}
      {!loading && videoDevices.length === 0 && (
        <h4>
          Nenhuma câmera foi encontrada, por favor conecte uma e reinicie o
          sistema.
        </h4>
      )}
      {!loading && (
        <>
          <div className="device-group">
            <span>Dispositivos de video</span>
            {videoDevices.map((device) => (
              <div
                key={device.deviceId}
                className={`input-card ${
                  device.deviceId === selectedVideoDevice?.deviceId
                    ? 'input-card-selected'
                    : ''
                }`}
                onClick={() => setSelectedVideoDevice(device)}
              >
                {device.label}
              </div>
            ))}
          </div>
          <div className="device-group">
            <span>Dispositivos de audio</span>
            {audioDevices.map((device) => (
              <div
                key={device.deviceId}
                className={`input-card ${
                  device.deviceId === selectedAudioDevice?.deviceId
                    ? 'input-card-selected'
                    : ''
                }`}
                onClick={() => setSelectedAudioDevice(device)}
              >
                {device.label}
              </div>
            ))}
          </div>
          <div className="btn-container">
            <Button action="primary" onClick={handleClick}>
              Salvar
            </Button>
          </div>
        </>
      )}
    </Page>
  )
}

export default styled(CameraEnumeration)`
  .react-loading-skeleton {
    margin-bottom: 1em;
  }

  .title {
    font-size: 15px;
    margin-bottom: 1rem;
  }

  .input-card {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 20px;
    cursor: pointer;
  }

  .input-card:hover {
    background-color: #eee;
  }

  .input-card-selected {
    background-color: #eee;
  }

  .btn-container {
    margin-top: 10px;
  }

  .device-group {
    margin-top: 10px;
  }
`
