import React from 'react'

const FirefoxTutorial = () => (
  <p>
    Na barra de endereços, a esquerda, procure pelos ícones de câmera e
    microfone.
  </p>
)

export default FirefoxTutorial
