import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import socketIOClient from 'socket.io-client'
import { ThemeContext } from 'styled-components'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Modal from '../components/Modal/Modal'
import { SOCKET_HOST, VIDEO_RECORDING_ENABLED } from '../consts'
import {
  childrenIsFunction,
  ExamStatus,
  IApplicationSocketState,
  IApplicationSocketStateAction,
  SOCKET_EVENTS
} from '../types'
import { buildBlockingAlertOptions } from '../utils/swal'
import { AuthContext } from './AuthState'

const initialState: IApplicationSocketState = {
  socket: null,
  status: 'NORMAL',
  pauseReason: '',
  alertMessage: '',
  connected: false,
  sendQuestion: () => Promise.resolve(),
  askForBreak: () => Promise.resolve(),
  askForNursingBreak: () => Promise.resolve(),
  hasPendingBreak: false,
  breakRequest: null,
  forcePause: false,
  setHasPending: () => undefined,
  updateExamStatus: () => undefined,
  returnFromBreak: () => undefined,
  setCameraAccepted: () => undefined,
  sendCameraFrame: () => undefined,
  joinRoomAsCandidate: () => undefined,
  handlePendingBreak: () => undefined,
  showReloadRequests: () => undefined
}

const reducer = (
  state: IApplicationSocketState,
  action: IApplicationSocketStateAction
): IApplicationSocketState => {
  switch (action.type) {
    case 'SOCKET_CONNECTED':
      return { ...state, socket: action.payload }
    case 'PAUSE_APPLICATION':
      return { ...state, status: 'PAUSED', pauseReason: action.payload }
    case 'RESUME_APPLICATION':
      return { ...state, status: 'NORMAL' }
    case 'ALERT':
      return { ...state, alertMessage: action.payload }
    case 'BREAK_ACCEPTED':
      return {
        ...state,
        status: 'BREAK',
        hasPendingBreak: action.payload.hasPendingBreak,
        forcePause: action.payload.forcePause
      }
  }
}

export const ApplicationSocketContext = createContext<IApplicationSocketState>(
  initialState
)

type ApplicationSocketProps = {
  children: Function
}

const usedSocketEvents = [
  SOCKET_EVENTS.CANDIDATE_MESSAGE,
  SOCKET_EVENTS.CANDIDATE_BREAK,
  SOCKET_EVENTS.CANDIDATE_BREAK_RESPONSE,
  'disconnect',
  'reconnect'
]

const ApplicationSocketState = ({ children }: ApplicationSocketProps) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [socket, setSocket] = useState<SocketIOClient.Socket>(null)
  const [approvedModal, setApprovedModal] = useState(false)
  const [hasReloadRequest, setHasReloadRequest] = useState(false)
  const ReactSwal = withReactContent(swal)

  const { pauseReason, alertMessage, status } = state

  useEffect(() => {
    if (status === 'PAUSED') {
      ReactSwal.fire(
        buildBlockingAlertOptions(
          t('Your exam was paused'),
          `${t('Reason')}: ${pauseReason}`,
          theme.pauseImg,
          t('Your exam was paused')
        )
      )
    } else {
      if (swal.isVisible()) {
        swal.close()
      }
    }

    if (alertMessage) {
      ReactSwal.fire(
        buildBlockingAlertOptions(
          t('Attention'),
          alertMessage,
          theme.pauseImg,
          t('Your exam was paused'),
          true
        )
      ).then((reason) => {
        if (reason && reason.value) {
          dispatch({ type: 'ALERT', payload: '' })
        }
      })
    }
  }, [status, alertMessage, ReactSwal, pauseReason, t, theme.pauseImg])

  const setPendingBreak = (hasPending: boolean, forcePause = false) => {
    dispatch({
      type: 'BREAK_ACCEPTED',
      payload: { hasPendingBreak: hasPending, forcePause }
    })
  }

  // ### Socket emission methods

  const sendQuestion = (content: string) => {
    if (!content || !socket) {
      return
    }
    socket && socket.emit(SOCKET_EVENTS.CANDIDATE_MESSAGE, { content })
    return Promise.resolve()
  }

  const askForBreak = () => {
    if (!socket) {
      return
    }
    socket &&
      socket.emit(SOCKET_EVENTS.CANDIDATE_BREAK, { examsUserId: user.id })
    return Promise.resolve()
  }

  const setCameraAccepted = (accepted: boolean) => {
    socket && socket.emit(SOCKET_EVENTS.PERMISSION_STATUS_CHANGED, { accepted })
  }

  const sendCameraFrame = (base64Frame: string) => {
    socket &&
      socket.emit(SOCKET_EVENTS.NEW_CAMERA_FRAME, { frame: base64Frame })
  }

  // ### End Socket emission methods

  const handleSocketSuccess = useCallback(() => {
    if (!socket) {
      return null
    }

    socket.emit(
      SOCKET_EVENTS.AUTH,
      { examsUserId: user.id, role: 'CANDIDATE' },
      () => {
        socket.emit(
          SOCKET_EVENTS.JOIN_ROOM,
          { roomId: user.provider.codename },
          () => {
            socket.emit('get-status', null, (result) => {
              dispatch({ type: 'SOCKET_CONNECTED', payload: socket })
              if (result === 'PAUSED') {
                dispatch({
                  type: 'PAUSE_APPLICATION',
                  payload: 'Pausado pelo aplicador'
                })
              } else if (result === 'BREAK') {
                setPendingBreak(false, true)
              } else if (result === 'NORMAL') {
                dispatch({ type: 'RESUME_APPLICATION' })
              }
            })
          }
        )
      }
    )
  }, [user, socket])

  const attachSocketEvents = useCallback(() => {
    if (!socket) {
      return
    }

    socket.on(SOCKET_EVENTS.PAUSE, (payload) => {
      dispatch({ type: 'PAUSE_APPLICATION', payload: payload.reason })
    })

    socket.on(SOCKET_EVENTS.RESUME, () => {
      dispatch({ type: 'RESUME_APPLICATION' })
    })

    socket.on(SOCKET_EVENTS.ALERT, (payload) => {
      dispatch({ type: 'ALERT', payload: payload.content })
    })

    socket.on(SOCKET_EVENTS.CANDIDATE_BREAK_RESPONSE, (payload) => {
      if (!payload.result) {
        toast.error(t('The applicator rejected your break request.'))
        return
      }
      setPendingBreak(true)
      setApprovedModal(true)
    })

    socket.on(SOCKET_EVENTS.RELOAD_REQUEST, () => {
      setHasReloadRequest(true)
    })

    /* eslint-disable @typescript-eslint/no-use-before-define */
    socket.on('reconnect', () => {
      handleSocketSuccess()
    })
  }, [socket, t, handleSocketSuccess])

  const returnFromBreak = () => {
    if (!socket) {
      return
    }
    socket.emit(SOCKET_EVENTS.CANDIDATE_BACK_FROM_BREAK)
    setPendingBreak(false, false)
  }

  const updateExamStatus = (examStatus: ExamStatus) => {
    if (!socket) {
      return
    }
    socket.emit(SOCKET_EVENTS.UPDATE_EXAM_STATUS, examStatus)
  }

  const joinRoomAsCandidate = () => {}

  const connectSocket = useCallback(() => {
    if (!user) {
      return
    }

    setSocket(
      socketIOClient(SOCKET_HOST, {
        reconnection: true,
        rejectUnauthorized: false
      })
    )
  }, [user])

  useEffect(() => {
    return () => {
      if (socket) {
        usedSocketEvents.forEach((event) => socket.off(event))
      }
    }
  }, [socket])

  useEffect(() => {
    if (VIDEO_RECORDING_ENABLED) {
      connectSocket()
    }
  }, [connectSocket])

  useEffect(() => {
    handleSocketSuccess()
  }, [handleSocketSuccess])

  useEffect(() => {
    attachSocketEvents()
  }, [attachSocketEvents])

  const contextValue = {
    ...state,
    socket,
    sendQuestion,
    askForBreak,
    returnFromBreak,
    updateExamStatus,
    setCameraAccepted,
    sendCameraFrame,
    joinRoomAsCandidate
  }

  if (approvedModal) {
    return (
      <Modal
        isOpen={approvedModal}
        title={t('The applicator approved your break request.')}
        onClose={() => setApprovedModal(false)}
        onAction={() => setApprovedModal(false)}
      >
        {t('Your exam will be paused when you finish the current question.')}
      </Modal>
    )
  }

  if (hasReloadRequest) {
    return (
      <Modal
        isOpen={hasReloadRequest}
        title={t('Attention')}
        onClose={() => window.location.reload()}
        onAction={() => window.location.reload()}
      >
        {t(
          'The applicator has requested for your window to be reloaded. This is a mandatory action and will not affect your answers. Click ok to continue.'
        )}
      </Modal>
    )
  }

  return (
    <ApplicationSocketContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </ApplicationSocketContext.Provider>
  )
}

export default ApplicationSocketState
