import axios from 'axios'
import Modal from 'components/Modal/Modal'
import { API_HOST } from 'consts'
import debounce from 'debounce-promise'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Button from './Button'
import TextArea from './TextArea'

type ApproveExamModalProps = {
  className?: string
  application?: any
  collection?: any
  roomId?: any
}

const ApproveExamModal = ({
  className,
  application,
  collection,
  roomId
}: ApproveExamModalProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const [applicationId, setApplicationId] = useState<any>()
  const [approveButton, setApproveButton] = useState(false)
  const [repproveButton, setRepproveButton] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState('')
  const handleClose = () => {
    setIsOpen(false)
  }

  const getApplicationData = () => {
    if (applicationId === undefined) {
      axios
        .get(
          `${API_HOST}/v1/applications?user=${application?.user?.id}&room=${roomId}`
        )
        .then((res) => setApplicationId(res?.data?.results[0]?.id))
        .catch((err) => console.error(err))
    }
  }

  const setApproved = debounce(async () => {
    setApproveButton(true)
    if (textAreaValue !== '') {
      toast.error(
        'Para aprovar um pré-teste, o campo "motivo de reprovação" deve estar em branco'
      )
      setApproveButton(false)
    } else {
      axios
        .post(`${API_HOST}/v1/applications/${applicationId}/approve_exam`)
        .then(() => {
          toast.success('Atividade foi aprovada com sucesso!')
          setIsOpen(false)
          setApproveButton(false)
        })
        .catch((err) => {
          toast.error('Ocorreu um erro ao tentar APROVAR esta atividade.')
          console.error(err)
        })
    }
  }, 500)

  const getTextReproved = debounce(async () => {
    setRepproveButton(true)
    if (textAreaValue === '') {
      toast.error('É necessário escrever o motivo para reprovar esta atividade')
      setRepproveButton(false)
    } else {
      axios
        .post(`${API_HOST}/v1/applications/${applicationId}/reprove_exam`, {
          disapproved_reason: textAreaValue
        })
        .then(() => {
          toast.success('Atividade foi reprovado com sucesso!')
          setIsOpen(false)
          setRepproveButton(false)
        })
        .catch((err) => {
          toast.error('Ocorreu um erro ao tentar REPROVAR esta atividade.')
          console.error(err)
        })
    }
  }, 500)

  useEffect(() => {
    getApplicationData()
  })

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <p
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '25px'
        }}
      >
        Tem certeza que deseja aprovar o pré-teste de {application?.user.name}?
      </p>
      <TextArea
        autoFocus={false}
        maxLength={1000}
        value={textAreaValue}
        onChange={(event) => setTextAreaValue(event.target.value)}
        placeholder="Escreva aqui o motivo para REPROVAÇÃO..."
      />
      <div className="button">
        <Button
          action="primary"
          isLoading={approveButton}
          onClick={setApproved}
        >
          Aprovar
        </Button>
        <Button
          action="secondary"
          isLoading={repproveButton}
          onClick={getTextReproved}
        >
          Reprovar
        </Button>
      </div>
    </Modal>
  )
}

export default styled(ApproveExamModal)`
  display: grid;
  align-items: center;
  padding: 1rem;
  font-size: 255px;
  font-weight: bold;
`
