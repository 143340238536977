import { Skeleton } from '@material-ui/lab'
import React from 'react'
import styled from 'styled-components'

type HistoryItemSkeletonProps = {
  className?: string
  direction: 'left' | 'right'
}

const HistoryItemSkeleton = ({
  className,
  direction
}: HistoryItemSkeletonProps) => {
  const width = Math.floor(Math.random() * 40) + 15

  return (
    <div className={className}>
      <Skeleton
        animation="wave"
        style={{ width: `${width}%`, height: '100%' }}
        className={direction}
      ></Skeleton>
    </div>
  )
}

export default styled(HistoryItemSkeleton)`
  width: 100%;
  height: 70px;

  .right {
    float: right;
  }
`
