import { Permissions } from 'data/domain/groups'
import {
  AlertIn,
  BreakRequest,
  Candidate,
  Message,
  Motive
} from 'data/domain/message-exchange'
import { AppealAttachments } from 'data/domain/appeals'
import { RoomUser } from 'data/domain/rooms'
import { UserConfiguration } from 'data/domain/user-config'
import { ChangeEvent, ReactNode } from 'react'
import { AnswerAttachments } from 'data/domain/exams'

export const childrenIsFunction = function (
  children: Function | ReactNode
): children is Function {
  return typeof children === 'function'
}

export interface INestedResource {
  _url: string
  id: number
}

export interface IAlternative {
  id: number
  content: string
  letter: string
  position: number
  explanation?: string
}

export interface IItem {
  id: number
  maxResponses: any
  position?: number
  introduction: string
  question: string
  alternatives: IAlternative[]
  category:
    | 'FREE_RESPONSE'
    | 'MULTIPLE_CHOICE'
    | 'MULTIPLE_ALTERNATIVES'
    | 'REPERTORIZATION_TABLE'
    | 'CONCEITO'
    | 'TABELA_EDITAVEL'
    | 'IMAGEM'
    | 'VIDEO'
    | 'FLASH_CARD'
    | 'MULTIPLE_FREE_RESPONSE'
    | 'GAP_MULTIPLE_RESPONSE'
    | 'MULTIPLE_LINEAR_SCALE'
  freeResponseMaxLength: number | undefined
  hasAttachment: boolean
  answerMinimumTime: number | undefined
  maxAllowedToMark: number | undefined
  gradeLinear?: any
  expectedFreeResponse?: string
}

export interface IItemWithFreeResponse {
  id?: number
  position?: number
  fetchingItems?: boolean
  introduction?: string
  question?: string
  category?: 'FREE_RESPONSE' | 'MULTIPLE_CHOICE' | 'MULTIPLE_ALTERNATIVES'
  handleFilter?: (params?: any) => void
  handlePageChange?: (page: number) => void
  handlePageSizeChange?: (pageSize: number) => void
  onFilter: (params?: any) => void
  totalItems?: any
  pageSize?: any | undefined
  numPages?: any | undefined
  freeResponse?: any
  application?: any
  item?: any
  itemVerboseName?: string
  itemPosition?: number
  itemValue?: number
  correctionStatus?: string
  attachment?: any
  correctionValue?: number
  correctionId?: number
  comment?: string
}

export interface IRoomListState {
  id?: number
  position?: number
  fetchingProctors?: boolean
  introduction?: string
  question?: string
  category?: 'FREE_RESPONSE' | 'MULTIPLE_CHOICE' | 'MULTIPLE_ALTERNATIVES'
  handleFilter?: (params?: any) => void
  handlePageChange?: (page: number) => void
  handlePageSizeChange?: (pageSize: number) => void
  onFilter: (params?: any) => void
  totalItems?: any
  pageSize?: any | undefined
  numPages?: any | undefined
  proctorsList?: any
  application?: any
  item?: any
  itemVerboseName?: string
  itemPosition?: number
  itemValue?: number
  correctionStatus?: string
  correctionValue?: number
  correctionId?: number
}

export interface IAnswerAlternative {
  id: number
  letter: string
  position: number
  content: undefined
}

export interface IAnswer {
  id: number
  position: number
  alternative?: IAnswerAlternative
  alternatives?: IAnswerAlternative[]
  freeResponse: string
  gradeLinear: string
  item: INestedResource
  application: INestedResource
  lastAnswered: boolean
  seconds: number
  timeoutDate: string
  verboseName?: string
  _changed: number // Must be number because booleans cannot be indexed
  answerTimeLimit?: number
  finished?: boolean
  itemCategory?: string
  attachments?: AnswerAttachments | undefined
  attachment?: any
}

export interface IAppealFormData {
  answer_id?: number
  content: string
  bibliography: string
  application: number
  item: number
  attachments?: AppealAttachments | undefined
  created_at?: any
}

export interface IAppeal {
  id: number
  createdAt: Date
  content?: string
  bibliography?: string
  response?: string
  formatted_response?: string
  application?: any
  item: number
  status: any
  appealIssuingWindow: AppealIssuingWindow
  position: any
  answerValue?: any
}

export interface ITimeWindow {
  startTime: string
  endTime: string
  maxDuration: number
}

export interface IExam {
  id: number
  name: string
  timeWindows?: ITimeWindow[]
  timeout?: string
  duration: number
  instructions?: string
  items: number[]
  numItems: number
  collection: ICollection
  alertConfiguration?: IAlertConfiguration
  shuffleItems?: boolean
  adaptative?: boolean
  examsOral?: boolean
  mainExam?: any
  isSecondaryChance?: any
  type?: any
  habilidade?: string
  totalExams?: number
  totalAnswer?: number
  componenteCurricularDescription?: string
  areaDoConhecimento?: number
}

export interface IAlertConfiguration {
  title: string
  maximumSuspiciousItemAnswerTime: number
}

export interface IReportCardItemExtra {
  label: string
  values?: string[]
}

export interface IReportCardItem {
  id: number
  position: number
  reportCardExtras: IReportCardItemExtra[]
  isCancelled: boolean
  answerIsCorrect: boolean
}

export interface IReportCardExam {
  id: number
  name: string
  items?: IReportCardItem[]
}

type TApplicationStatus =
  | 'STARTED'
  | 'FINISHED'
  | 'AVAILABLE'
  | 'AVAILABLE_SOON'
  | 'UNAVAILABLE'

export type FinishReason =
  | 'CANDIDATE'
  | 'TIMEOUT'
  | 'EXPIRED_GRACE_PERIOD'
  | 'MANUALLY'

export interface IApplication {
  duration: string
  timeout: any
  id: number
  idn: number
  shouldUpdateAnswers: boolean
  forceItemUpdates: number[]
  exam: IExam
  timeWindows?: ITimeWindow[]
  isAvailable: boolean
  startedAt?: string
  finishedAt?: string
  title: string
  instructionsUrl: string
  triGrade?: any
  tctGrade?: any
  status: TApplicationStatus
  mandatoryCollectionUnfinished?: boolean
  mandatoryCollectionName?: string
  // Be cautious when using this value, because it is only correct when
  // application is loaded
  secondsToTimeoutWhenLoaded?: number
  secondsToTimeout: number
  asCollection?: boolean
  reportCardId?: number
  roomId?: number
  reseted: boolean
  finishReason: FinishReason
  hideMaxDuration?: boolean
  showInstructionLink?: boolean
  user?: IUser
  channelName?: string
  isMultipleApplication?: boolean
  approvedExam?: any
  type?: any
  categoryExam?: any
}

export interface ApplicationResponse {
  id: number
  shouldUpdateAnswers: boolean
  forceItemUpdates: number[]
  exam: INestedResource
}

export interface IListState {
  results: any[]
  isLoading: boolean
  fetched: boolean
  hasError: boolean
  count: undefined | number
  next?: string
  previous?: string
  page: number
  pageSize: number
  filterParams: any
  numPages: number
  ordering: string
  search: string | undefined
  handleFilter: (params?: any) => void
  handlePageChange: (page: number) => void
  handlePageSizeChange: (pageSize: number) => void
}

export type ListStateAction =
  | { type: 'FETCH_LIST_SUCCESS'; payload: any }
  | { type: 'HANDLE_FILTER'; payload: any }
  | { type: 'CHANGE_PAGE'; payload: number }
  | { type: 'CHANGE_PAGE_SIZE'; payload: number }
  | { type: 'CHANGE_SEARCH'; payload: string | undefined }
  | { type: 'FETCH_LIST_ERROR' }
  | { type: 'FETCH_LIST' }

export interface IApplicationListState extends IListState {
  collectionId?: number
  filterParams: any
  exam: IExam | undefined
  totalResults: number
  pageSize: number
  numPages: number
  onSearchChange(event: ChangeEvent<HTMLInputElement>): void
  handlePageChange(page: number): void
  onPageSizeChange(event: ChangeEvent<HTMLInputElement>): void
  onFilter: (params?: any) => void
}

export type INetworkStateAction = {
  type: 'SET_OFFLINE_MESSAGE'
  payload: { message: string; isConnected: boolean }
}

export interface INetworkState {
  dispatch: (action: INetworkStateAction) => void
  offlineMessage: string
  isConnected: boolean
}

export type IApplicationStateAction =
  | { type: 'FETCH_APPLICATION' }
  | { type: 'FETCH_APPLICATION_ERROR' }
  | {
      type: 'FETCH_APPLICATION_SUCCESS'
      payload: {
        application: IApplication
        secondsToTimeout: number | undefined
      }
    }
  | { type: 'FETCH_ANSWERS' }
  | { type: 'FETCH_ANSWERS_ERROR' }
  | { type: 'FETCH_ANSWERS_SUCCESS'; payload: IAnswer[] }
  | { type: 'UPDATE_ANSWER_IN_ANSWERS'; payload: IAnswer }
  | { type: 'START_APPLICATION' }
  | { type: 'START_APPLICATION_SUCCESS'; payload: number }
  | { type: 'START_APPLICATION_ERROR'; payload: string }
  | { type: 'FINISH_APPLICATION' }
  | { type: 'FINISH_APPLICATION_SUCCESS' }
  | { type: 'FINISH_APPLICATION_ERROR'; payload: string }
  | { type: 'ADD_MORE_TIME'; payload: number }

export interface IApplicationState {
  application: IApplication | undefined
  answers: IAnswer[]
  fetchingApplication: boolean
  fetchingAnswers: boolean
  fetchApplicationError: boolean
  fetchAnswersError: boolean
  dispatch: (action: IApplicationStateAction) => void
  startApplication: () => void
  fetchAnswers: () => void
  resumeApplication: (data: any) => void
  finishApplication: () => void
  checkApplicationAnswers: () => void
  returnToApplicationList: () => void
  checkAnswersPage?: boolean
  startingApplication: boolean
  startApplicationError: string
  finishingApplication: boolean
  finishApplicationError: string
  secondsToTimeout: number | undefined
  remainingTimeInitialDate: number | undefined
  handleTimeout: () => void
  addMoreTime: (minutes: number) => void
  fetchApplication: () => void
}

export interface IAppealState {
  currentAppeal: IAppealFormData | undefined
  appeals: IAppeal[] | undefined
  fetchingAppeals: Boolean
  fetchAppealsError: Boolean
  finishFetchingAppeals: Boolean
  dispatch: (action: AppealStateAction) => void
  fetchAppeal: (appealId: number) => void
  issueAppeal?: (newAppealData: IAppealFormData) => void
  updateAppeal?: (appealId: number, newAppealData: IAppealFormData) => void
  uploadAttachedFile: (
    formData: FormData,
    file: object,
    appealAttachments: AppealAttachments
  ) => void
  removeAttachment: (index: number, attachments: AppealAttachments) => void
  creatingAppeal: Boolean
  createAppealSuccess: Boolean
  createAppealError: Boolean
  appealCreationError: string
  updatingAppeal: Boolean
  updateAppealSuccess: Boolean
  updateAppealError: Boolean
  appealUpdatingError: string
  fetchingAppeal: Boolean
  fetchAppealSuccess: Boolean
  fetchAppealError: Boolean
  appealFetchingError: string
  appealAttachments: AppealAttachments | undefined
  uploadingAttachment: Boolean
  uploadAttachmentSuccess: Boolean
  uploadAttachmentError: Boolean
  attachmentUploadingError: string
  totalAppeals?: any | undefined
  handleFilter?: (params?: any) => void
  handlePageChange?: (page: number) => void
  handlePageSizeChange?: (pageSize: number) => void
  onPageSizeChange(event: ChangeEvent<HTMLInputElement>): void
  onFilter: (params?: any) => void
  pageSize?: any | undefined
  numPages?: any | undefined
}

export const SOCKET_EVENTS = {
  AUTH: 'authenticate',
  JOIN_ROOM: 'join-room',
  PAUSE: 'pause-exam',
  RESUME: 'resume-exam',
  ALERT: 'alert',
  SERVER_PULSE: 'room-pulse',
  CANDIDATE_CONNECT: 'candidate-connected',
  CANDIDATE_DISCONNECT: 'candidate-disconnected',
  CANDIDATE_MESSAGE: 'candidate-message',
  CANDIDATE_BREAK: 'candidate-break',
  CANDIDATE_BREAK_RESPONSE: 'candidate-break-response',
  CANDIDATE_BACK_FROM_BREAK: 'candidate-return-from-break',
  CANDIDATE_ACTIVITY: 'candidate-activity',
  UPDATE_EXAM_STATUS: 'exam-status-update',
  GET_EXAM_STATUS: 'get-candidate-status',
  EXAM_STATUS_UPDATED: 'exam-status-updated',
  PERMISSION_STATUS_CHANGED: 'permission-status-changed',
  NEW_CAMERA_FRAME: 'new-camera-frame',
  RELOAD_REQUEST: 'reload-request',
  SET_ROOM: 'set-room'
}

export type AnswerStateAction =
  | { type: 'FETCH_ITEM' }
  | { type: 'FINISHING_ANSWER' }
  | { type: 'FETCH_ITEM_ERROR'; payload: string }
  | { type: 'FETCH_ITEM_SUCCESS'; payload: IItem | undefined }
  | { type: 'FETCH_ANSWER' }
  | { type: 'FETCH_ANSWER_ERROR'; payload: string }
  | { type: 'FETCH_ANSWER_SUCCESS'; payload: IAnswer | undefined }
  | { type: 'FINISH_ANSWER'; payload: IAnswer | undefined }
  | { type: 'UPDATE_ANSWER_ALTERNATIVE'; payload: IAnswer }
  | { type: 'UPDATE_BULK_ANSWER_ALTERNATIVE'; payload: IAnswer }
  | { type: 'UPDATE_FREE_RESPONSE'; payload: IAnswer }
  | { type: 'UPDATE_MULTIPLE_LINEAR_SCALE'; payload: IAnswer }
  | { type: 'SET_ALREADY_ANSWERED'; payload: boolean }
  | { type: 'SET_CHANGE_ANSWER'; payload: boolean }
  | { type: 'PAUSE_APPLICATION'; payload: string }
  | { type: 'SET_ITEM_ACCESS_EXPIRED'; payload: boolean }
  | { type: 'UPDATE_REMAINING_ITEM_TIME_INITIAL_DATE' }
  | { type: 'FETCH_ANSWER_ATTACHMENTS'; payload: AnswerAttachments }
  | { type: 'UPLOAD_ATTACHMENT_START' }
  | {
      type: 'UPLOAD_ATTACHMENT_SUCCESS'
      payload: AnswerAttachments | undefined
    }
  | { type: 'UPLOAD_ATTACHMENT_ERROR'; payload: string }
  | { type: 'REMOVE_ATTACHMENT'; payload: AnswerAttachments }

export type AppealStateAction =
  | { type: 'FETCH_APPEALS_START' }
  | { type: 'FETCH_APPEALS_SUCCESS'; payload: IAppeal[] | undefined }
  | { type: 'FETCH_APPLICATION_ITEMS_START' }
  | { type: 'FETCH_APPLICATION_ITEMS_SUCCESS'; payload: IItem[] }
  | { type: 'FETCH_APPLICATION_ITEMS_ERROR' }
  | { type: 'CREATE_APPEAL_START' }
  | { type: 'CREATE_APPEAL_SUCCESS' }
  | { type: 'CREATE_APPEAL_ERROR'; payload: string }
  | { type: 'UPDATE_APPEAL_START' }
  | { type: 'UPDATE_APPEAL_SUCCESS'; payload: IAppealFormData | undefined }
  | { type: 'UPDATE_APPEAL_ERROR'; payload: string }
  | { type: 'FETCH_APPEAL_START' }
  | { type: 'FETCH_APPEAL_SUCCESS'; payload: IAppealFormData | undefined }
  | { type: 'FETCH_APPEAL_ERROR'; payload: string }
  | { type: 'FETCH_APPEAL_ATTACHMENTS'; payload: AppealAttachments }
  | { type: 'UPLOAD_ATTACHMENT_START' }
  | {
      type: 'UPLOAD_ATTACHMENT_SUCCESS'
      payload: AppealAttachments | undefined
    }
  | { type: 'UPLOAD_ATTACHMENT_ERROR'; payload: string }
  | { type: 'REMOVE_ATTACHMENT'; payload: AppealAttachments }

export interface IVideoStreamingState {
  triedToAskForPermission: boolean
  hasPermissionError: boolean
  granted: boolean
  startStreaming: (
    shouldStreamToFirebase,
    shouldUploadToS3,
    shouldStreamToAgora,
    shouldFaceDetect,
    shouldTakeSnapshots,
    shouldUploadScreenshotToS3
  ) => void
  permissionsAreOk: () => Promise<boolean>
  setPermissionGranted: (granted: boolean) => void
  setCameraId: (cameraId: string) => void
  setMicrophoneId: (microhoneId: string) => void
  isPermissionModalOpen: boolean
  shouldStreamToFirebase: boolean
  shouldUploadToS3: boolean
  shouldStreamToAgora: boolean
  shouldFaceDetect: boolean
  shouldUploadScreenshotToS3: boolean
}

export type VideoStreamingStateAction =
  | {
      type: 'ASKING_FOR_PERMISSION'
      payload: {
        isModalOpen: boolean
        shouldStreamToFirebase: boolean
        shouldUploadToS3: boolean
        shouldStreamToAgora: boolean
        shouldFaceDetect: boolean
        shouldUploadScreenshotToS3: boolean
      }
    }
  | { type: 'CAMERA_PERMISSION_RESULT'; payload: { granted: boolean } }
  | { type: 'CAMERA_START_RESULT'; payload: { hasCameraError: boolean } }

export interface IAnswerState {
  answer?: IAnswer
  item: IItem | undefined
  updateAnswer(alternative: IAlternative): void
  goAnswer(answer: IAnswer): void
  getAnswerFromPosition(position: number): IAnswer | undefined
  remainingTime: string
  previousAnswer: IAnswer | undefined
  nextAnswer: IAnswer | undefined
  fetchingItem: boolean
  itemAccessExpired: boolean
  fetchItemError: string
  fetchingAnswer: boolean
  fetchAnswerError: string
  alreadyAnswered: boolean
  changeAnswer: boolean
  finishingAnswer: boolean
  badgeMessages?: Message[]
  uploadAttachedFile: (
    formData: FormData,
    file: object,
    answerAttachments: AnswerAttachments,
    answer: number,
    application: number
  ) => void
  answerAttachments: AnswerAttachments | undefined
  removeAttachment: (index: number, attachments: AnswerAttachments) => void
  uploadingAttachment: Boolean
  uploadAttachmentSuccess: Boolean
  uploadAttachmentError: Boolean
  attachmentUploadingError: string
  updateFreeResponse(freeResponse: string): void
  updateMultipleLinearScale(gradeLinear: string): void
  fetchItem?(force?: boolean): void
  goToNextNotExpiredItem(): void
  isAnswered(ans: IAnswer): boolean
  handleQuestionExpired(): void
  displayWarningModal(newAnswer: IAnswer): void
  remainingItemTimeInitialDate?: number
  generateIncidentAutomatically: (
    description: string,
    room: number,
    user: number
  ) => void
  timeBadgeMessage?: Message[]
  time?: number
  bulkUpdateAnswer(alternative: IAlternative): void
  handleFinishAnswer(answer: IAnswer): void
  handleAnswerData: () => Promise<void>
}

export interface IExtraField {
  name: string
  value: string
}

export interface IPerson {
  institution?: string
  gender?: string
  cpf: string
  crm?: string
  phone?: string
  cellphone: string
  city?: string
  specialNeed?: string
  nursingMother?: boolean
  birthDate?: string
}

export interface IPersonState {
  person?: IPerson
  loadingPerson: boolean
}

export interface IUser {
  id: number
  name: string
  email: string
  groups: string[]
  extra?: {
    hierarchy?: {
      [key: string]: IExtraField
    }
  }
  provider?: IProvider
  publicIdentifier: string
  access_url: string
  disableFaceDetection?: boolean
  person?: IPerson
  isInBrowser?: any
}

export interface IEmail {
  createdAt: string
  failed: boolean
  id: string
  recipientEmail: string
  sentAt: string
  type: string
}

export interface IHierarchyCategory {
  id?: number
  parentId?: number | null
  codename: string
  name: string
  description: string
  parent?: IHierarchyCategory
}
export interface IInstitutionData {
  group: any
  grupo: any
  name: any
  institucion: any
  id?: number
  ds_escola?: string
}
export interface IInstitutionFilterData {
  name?: string
  id?: number
  cdInstitucional?: number
}

export interface Igroup {
  group: any
}
export interface IClasses {
  id?: number
  turma_ds?: string
}
export interface IPeriodo {
  id?: number
  name?: string
}
export interface IEtapa {
  id?: number
  name?: string
}
export interface IInstitution {
  id?: number
  name?: string
}

export interface IHierarchy {
  id: number
  name: string
  type: string
  value: string
}

export type FormValues = {
  // Hierarchy values, which are dynamic, but always have the name attribute
  [key: string]: IHierarchy | undefined
} & {
  collection?: ICollection
}

export interface ILoginError {
  nonFieldErrors: string[]
  token: string[]
}

export type ISession = {
  id: number
  clientId: string
  browser: string
  os: string
}

export type ShowNewSessionPage = {
  showNewSessionPage: boolean
  newSessionMessage: string
  activeSession: ISession
}

export type ILocalStorageObjects = {
  theme: ITheme | undefined
  user: IUser | undefined
}

export type IAuthStateAction =
  | { type: 'LOGIN' }
  | { type: 'LOGIN_ERROR'; payload: ILoginError | undefined }
  | { type: 'LOGIN_SUCCESS'; payload: ILocalStorageObjects }
  | { type: 'NETWORK_ERROR' }
  | { type: 'UPDATE_USER'; payload: IUser | undefined }
  | { type: 'SHOW_NEW_SESSION_PAGE'; payload: ShowNewSessionPage }
  | { type: 'HIDE_NEW_SESSION_PAGE' }
  | { type: 'UPDATE_LOCAL_STORAGE_OBJECTS'; payload: ILocalStorageObjects }
  | { type: 'SET_USER_CONFIG'; payload: any }

export type AuthStateLoginArgs = {
  username: string
  password: string
  setNewClient?: boolean
}

export type AuthStateLogin = (args: AuthStateLoginArgs) => Promise<boolean>

export type AuthStateProviderLoginArgs = {
  provider: string
  providerToken: string
  setNewClient?: boolean
}

export type AuthStateProviderLogin = (
  args: AuthStateProviderLoginArgs
) => Promise<boolean>

export interface IProvider {
  codename: string
  logoutRedirectUrl: string
}

export interface IAuthState {
  user: IUser | undefined
  token: string | undefined
  theme: any | undefined
  dispatch: (action: IAuthStateAction) => void
  isSubmitting: boolean
  loginError: boolean
  networkError: boolean
  detailedErrors: ILoginError | undefined
  login: AuthStateLogin
  providerLogin: AuthStateProviderLogin
  handleEducatBridgeLogin: (bridgeToken: string) => Promise<boolean>
  logout: () => Promise<void>
  showNewSessionPage: boolean
  newSessionMessage: string
  activeSession?: ISession
  hasGroup: (group: string) => boolean
  perms: Permissions
  userConfiguration?: UserConfiguration
}

export type INotificationStateAction =
  | { type: 'FLUSH_ITEMS'; payload: { items: number[] } }
  | {
      type: 'FLUSH_ANSWERS'
      payload: { applicationId: number; fetchAnswers: () => null }
    }

export interface IExamDashboardApplicationUser {
  extra: any
  id: number
  name: string
  username?: string
  publicIdentifier: string
  img: string
  provider: IProvider | null
}

export interface IExamDashboardApplication {
  id: number
  numAnswered: number
  user: IExamDashboardApplicationUser
  status: TApplicationStatus
  startedAt?: string
  finishedAt?: string
  numItems: number
  grades: {
    correct: number
    wrong: number
    total: number
  }
  timeout?: string
  timeWindows: ITimeWindow[]
  exam: IExam
  reseted: boolean
  roomId?: number
  videoProcessStatus?: string
  nursingMother: boolean
  specialNeed: string
}

export type ExamDashboardStateAction =
  | { type: 'FETCH_EXAM' }
  | { type: 'FETCH_EXAM_SUCCESS'; payload: IExam }
  | { type: 'FETCH_EXAM_ERROR'; payload: string }
  | { type: 'FETCH_APPLICATIONS' }
  | { type: 'FETCH_APPLICATIONS_SUCCESS'; payload: IExamDashboardApplication[] }
  | { type: 'FETCH_APPLICATIONS_ERROR'; payload: string }
  | { type: 'ON_SEARCH_CHANGE'; payload: string }
  | { type: 'ON_FILTER_CHANGE'; payload: any }

export type ApplicationStateAction =
  | { type: 'ON_SEARCH_CHANGE'; payload: string }
  | { type: 'ON_FILTER_CHANGE'; payload: any }

export interface IExamDashboardState extends IListState {
  exam: IExam | undefined
  applications: IExamDashboardApplication[]
  fetchingExam: boolean
  fetchExamError: string
  fetchedApplications: boolean
  fetchingApplications: boolean
  fetchApplicationsError: string
  filterParams: any
  totalApplications: number | undefined
  onSearchChange(event: ChangeEvent<HTMLInputElement>): void
  onPageSizeChange(event: ChangeEvent<HTMLInputElement>): void
  onFilter: (params) => void
  appliedFilters: {
    [key: string]: {
      id: number
      name: string
      value: string
      specialNeed: string
    }
  }
  setBulkTimeWindow: (values: any) => Promise<any>
  getVerboseFilters: () => string[]
  fetchApplications: (shouldDispatchFetching?: boolean) => Promise<void>
  handleSetTimeoutSubmit: (values: any) => Promise<any>
}

export type DashboardVideosStateAction =
  | { type: 'FETCH_APPLICATIONS' }
  | { type: 'FETCH_APPLICATIONS_SUCCESS'; payload: any }
  | { type: 'FETCH_APPLICATIONS_ERROR'; payload: string }
  | { type: 'SET_FACE_API'; payload: any }

export type DashboardVideosSocketStateAction =
  | { type: 'SOCKET_CONNECT_SUCCESS'; payload: any }
  | { type: 'ROOM_PULSE_RECEIVED'; payload: any }
  | { type: 'CANDIDATE_CONNECT'; payload: any }
  | { type: 'CANDIDATE_DISCONNECT'; payload: any }
  | { type: 'CANDIDATE_MESSAGE'; payload: any }
  | { type: 'CANDIDATE_BREAK'; payload: any }
  | { type: 'CANDIDATE_ACTIVITY'; payload: any }
  | { type: 'CANDIDATE_FINISHED_EXAM'; payload: any }

export type CandidateConnectionInfo = {
  [key: number]: boolean
}

export type CandidateMessageInfo = {
  [key: number]: string
}

export type BreakStatus = {
  status: 'PENDING' | 'REJECTED' | 'CONFIRMED' | 'FINISHED'
  motive: string
}

export type CandidatesBreakInfo = {
  [key: number]: BreakStatus
}

export type PauseStatus = 'PAUSED' | 'NORMAL'

export type CandidatesPauseInfo = {
  [key: number]: PauseStatus
}

export type CandidateFinishedStatus = {
  [key: number]: boolean
}

export type ActivityStatus =
  | 'RIGHT'
  | 'LEFT'
  | 'UP'
  | 'DOWN'
  | 'MISSING'
  | 'MULTIPLE'
  | ''

export type CandidatesActivityStatusInfo = {
  [key: number]: {
    reason?: ActivityStatus
    when: string
  }
}

export interface IDashboardVideosState {
  applications: IExamDashboardApplication[]
  fetching: boolean
  fetchError: string
  roomId: number
  roomData: any
  users: RoomUser[]
  faceAPI?: any
  streamings?: object
}

export interface IDashboardVideosSocketState {
  socket: SocketIOClient.Socket
  pauseCandidateExam: (
    candidateId: number,
    message: string,
    showMessage: boolean
  ) => Promise<void>
  resumeCandidateExam: (candidateId: number) => Promise<void>
  sendAlert: (candidateId: number, content: string) => Promise<void | any>
  candidatesConnectionStatus: CandidateConnectionInfo
  candidatesMessageStatus: CandidateMessageInfo
  messages?: Message[]
  breakRequests?: BreakRequest[]
  candidates?: Candidate[]
  candidatesBreakInfo: CandidatesBreakInfo
  candidatesActivityStatus: CandidatesActivityStatusInfo
  candidatesFinishedStatus: CandidateFinishedStatus
  candidatesPausedStatus: CandidatesPauseInfo
  candidatesPermissionStatus: CandidateConnectionInfo
  onlineAmount: number
  finishedAmount: number
  setMessageAsRead: (alertId: string) => Promise<void | any>
  setActivityAsRead: (candidateId: number) => Promise<void | any>
  sendBreakResponse: (
    breakRequestId: string,
    result: boolean,
    candidateId: string
  ) => Promise<void | any>
  sendNursingBreakResponse?: (
    breakRequestId: string,
    result: boolean,
    candidateId: string
  ) => Promise<void | any>
  sendNotificationToCandidate?: (
    candidateId: number,
    motive: Motive,
    type?: AlertIn['notificationType'],
    text?: string
  ) => Promise<void | any>
  getExamStatus: (candidateId: number) => Promise<ExamStatus>
  sendReloadRequest: (
    candidateId: number,
    message?: string
  ) => Promise<any | void>
  joinRoomAsApplicator: (roomId: number) => void
  sendMessageToAllOnline: (content: string) => Promise<void>
  messagesList?: string[]
  collection?: ICollection
  newStreamAdded?: boolean
}

export type ExamStatus = {
  currentQuestion?: number
  finished?: boolean
  examStartDate?: string
}

export type IApplicationConfiguration = {
  timeToReturnFromBreakInSeconds: number
  canBrowseAcrossItems: boolean
  canOpenInAnyBrowser: boolean
  canUpdateAnswer: boolean
  shouldStreamToFirebase: boolean
  shouldUploadToS3: boolean
  shouldUploadScreenshotToS3: boolean
  shouldFaceDetect: boolean
  shouldTakeSnapshots: boolean
  canUseCalculator: boolean
  canUseScientificCalculator: boolean
  canUseNotepad: boolean
  canUseDrawingPanel: boolean
  canUseSymbolTable: boolean
  canUseTextMarker: boolean
  setUnavailableToApplications: boolean
}

export interface IApplicationSocketState {
  socket: SocketIOClient.Socket
  pauseReason: string
  status: 'NORMAL' | 'PAUSED' | 'BREAK'
  alertMessage: string
  connected: boolean
  showReloadRequests: () => void
  sendQuestion: (content: string) => Promise<void | any>
  askForBreak: () => Promise<void | any>
  askForNursingBreak: () => Promise<void | any>
  hasPendingBreak: boolean
  breakRequest?: BreakRequest
  setHasPending: (hasPending: boolean) => void
  joinRoomAsCandidate: (roomId: number) => void
  returnFromBreak: () => void
  updateExamStatus: (examStatus: ExamStatus) => void
  setCameraAccepted: (accepted: boolean) => void
  sendCameraFrame: (base64Frame: string) => void
  forcePause: boolean
  handlePendingBreak: () => void
  candidate?: Candidate
  badgeMessages?: Message[]
  reloadRequests?: Message[]
}

export type IApplicationSocketStateAction =
  | { type: 'SOCKET_CONNECTED'; payload: any }
  | { type: 'PAUSE_APPLICATION'; payload: string }
  | { type: 'RESUME_APPLICATION' }
  | { type: 'ALERT'; payload: string }
  | { type: 'BREAK_ACCEPTED'; payload: any }

export type AppealIssuingWindow = {
  startDate: string
  endDate: string
  discloseAppeal: boolean
  enableItemCorrection?: boolean
  numCharactersContent: number
  numCharactersBibliography: number
  hideCandidateName?: boolean
  paymentAppeal: boolean
  forAppealValue?: number
}

export interface ICollection {
  categoryExam: number
  canDiscloseReport: any
  id: number
  instructions?: string
  exams?: any
  name: string
  applicationConfiguration: number
  showApplicationAfterTimeout?: boolean
  enableAdaptativeFlow?: boolean
  startTime: string
  endTime: string
  gracePeriod?: number | null
  hideGracePeriod?: boolean
  answerSheetDisclosed?: boolean
  postAnswerVisualization?: boolean
  postAlternativeExplanationVisualization?: boolean
  postItemNavigation?: boolean
  allowInstructionsCommunication: boolean
  allowRevisionCommunication: boolean
  canUploadFileAsAnswer?: boolean
  allowProctorCommunication?: boolean
  showItemId?: boolean
  shouldStreamToAgora: boolean
  shouldStartRecordingScreenFlow: boolean
  setUnavailableToApplications: boolean
  shouldUploadScreenshotToS3: boolean
  showAnswerSheetOverview: boolean
  showCandidateGrade: boolean
  showAnswerSheetToCandidate: boolean
  hasRepertorizationTable: boolean
  appealIssuingWindow: AppealIssuingWindow
  allowApproveExam?: any
  createdBy?: any
  discipline?: any
  iconName?: any
  knowledgeArea?: any
  knowledgeAreaDescription?: any
  totalExams?: any
  viewTri?: boolean
  viewTct?: boolean
  seriesYear?: any
  countExamsAvailable?: any
  typeId?: any
  isConfirmed?: any
  categoria?: any
}

export interface ICollectionState {
  collection?: ICollection
  fetchingCollection: boolean
  fetchCollectionError: string
}

export type CollectionStateAction =
  | { type: 'FETCH_COLLECTION' }
  | { type: 'FETCH_COLLECTION_SUCCESS'; payload: ICollection }
  | { type: 'FETCH_COLLECTION_ERROR'; payload: string }

export interface ITheme {
  name: string
  primary: string
  secondary: string
  secondaryDark: string
  text: string
  grayLight: string
  grayMedium: string
  grayDark: string
  danger: string
  AVAILABLE: string
  STARTED: string
  FINISHED: string
  AVAILABLE_SOON: string
  UNAVAILABLE: string
  UNKNOWN: string
  STOPPED: string
  logoImg: string
  errorImg: string
  footerMessage: string
  footerEmail: string
  footerNumber: string
  footerImageOrText: string
}

export interface IIndividualExamReportCard {
  id: number
  generalGrade: number
  classGrade: number
  totalItens: number
  exam: IReportCardExam
}

export interface IIndividualReportCard {
  id: number
  generalGrade: number
  classGrade: number
  totalItens: number
  exams: IIndividualExamReportCard[]
  collection: ICollection
  user: IUser
  pdfUrl?: string | null
}

export interface IIndividualReportCardState {
  individualReportCard?: IIndividualReportCard
  fetchingIndividualReportCard: boolean
  fetchIndividualReportCardError: string
}

export interface IDashboardPreFilterState {
  onFilter?: any
  isLoading: boolean
  totalResults: number
  results: DashboardPreFilter[]
  pageSize: number
  numPages: number
  onSearchChange(event: ChangeEvent<HTMLInputElement>): void
  handlePageChange(page: number): void
  onPageSizeChange(event: ChangeEvent<HTMLInputElement>): void
}

export type DashboardPreFilter = {
  id: number
  name: string
  createdBy: string
  totalExams: number
  totalApplications: number
  startTime: Date
  endTime: Date
  discipline?: string
  knowledgeArea?: string
  knowledgeAreaDescription?: string
  iconName?: any
  isMochila: boolean
  avaliada: boolean
  seriesYear: string
  countExamsAvailable: number
  typeId: number
  isConfirmed: string
  categoria: number
}

export type DashboardPreFilterAction =
  | { type: 'FETCH' }
  | { type: 'FETCH_SUCCESS'; payload: any }
  | { type: 'FETCH_ERROR'; payload: string }
  | { type: 'ON_SEARCH_CHANGE'; payload: string }

export type IndividualReportCardStateAction =
  | { type: 'FETCH_INDIVIDUAL_REPORT_CARD' }
  | {
      type: 'FETCH_INDIVIDUAL_REPORT_CARD_SUCCESS'
      payload: IIndividualReportCard
    }
  | { type: 'FETCH_INDIVIDUAL_REPORT_CARD_ERROR'; payload: string }

export interface IIndividualReportCardListState extends IListState {
  downloadCollectionReportCards: (collection: ICollection) => void
}

export interface IImportExam {
  submitting: boolean
  file: Blob
}

export type PreferenceName =
  | 'Login__DocUploadOnFirstAccess'
  | 'Application__PublishFinishedEvent'
  | 'Application__RequiredFiltersCollectionResult'
  | 'Application__RequiredFiltersSetBulkTimeWindow'
  | 'Application__EmailFlowEnabled'
  | 'Application__PauseNeedsExplanation'
  | 'Application__ReloadRequestNeedsExplanation'
  | 'Application__ShowMaxDuration'
  | 'Application__ShowReviewButton'
  | 'Application__RedirectToStudentReport'
  | 'Application__ExternalStudentReportLink'
  | 'Application__ExamCategoryFlow'
  | 'Shared_Data__ActivateSharedFlow'

export interface IPreference {
  section: string
  name: string
  identifier: string
  verboseName: string | null
  helpText: string | null
  additionalData: any
}

export interface IStringPreference extends IPreference {
  value: string | null
}

export interface IBooleanPreference extends IPreference {
  value: boolean | null
}

export interface IEmailTemplate {
  id: number
  code: string
  description?: string
  body: string
  sender: string
  subject: string
  partnerId: number
  createdAt: string
  updatedAt: string
}

export interface IEmailState {
  templates: IEmailTemplate[]
  templateLoading?: boolean
  children?: any
}

export type IRoom = {
  id: number
  name: string
  opened: boolean
  openedAt: Date
  closedAt: Date
  capacity: number
  isRecording: boolean
  updatedAt: any
}

export type RoomRecord = {
  id: number
  description: string
  createdAt: Date
  updatedAt: Date
  room: IRoom
  user: IUser
}

export interface IAnswerSheetOrderState {
  isLoading: boolean
  totalResults: number
  results: AnswerSheetOrder[]
  pageSize: number
  numPages: number
  onSearchChange(event: ChangeEvent<HTMLInputElement>): void
  handlePageChange(page: number): void
  onPageSizeChange(event: ChangeEvent<HTMLInputElement>): void
  onFilter?: (params?: any) => void
  handleFilter?: (params?: any) => void
}

export type AnswerSheetOrder = {
  id: number
  application: any
  status: any
  bulkOrder: any
  errors: any
}

export type LookDirectionType =
  | 'RIGHT'
  | 'LEFT'
  | 'UP'
  | 'DOWN'
  | 'MISSING'
  | 'MULTIPLE'
  | ''

export interface IFaceDetectionState {
  faceAPI?: any
  createFaceDetectionEventListener: Function
  faceDetectionAlert?: string
}

export type IFaceDetectionStateAction = { type: 'SET_FACE_API'; payload: any }

export type CandidatesFaceDetectionAlert = {
  [key: number]: string
}

export type InternetConnectionCallback = (
  lastMeasure: number,
  averageMeasure: number,
  score: number
) => void

export interface InternetConnectionDetectionStrategy {
  /**
   * Adds a listener for when there is new data collected
   * from internet connection measurement.
   * @returns The identifier of the callback for later removal.
   */
  addListener: (callback: InternetConnectionCallback) => number

  /**
   * Removes the listener with given id
   */
  removeListener: (id: number) => void

  /**
   * Starts the detection
   */
  start: () => void

  /**
   * Stops the detection
   */
  stop: () => void
}

export interface LineChartItem {
  id: string
  x: string
  y: number
}

export interface FirebaseEvent {
  type: 'login' | 'logout'
  userId: string
  when: firebase.firestore.Timestamp
}

export interface Event {
  type: 'login' | 'logout'
  userId: string
  when: Date
}
