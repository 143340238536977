import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const AppMenuDrawer = styled.div`
  .img-plurall {
    margin-top: 12px;
    margin-left: 100px;
  }
  .img-plurall-close {
    margin-top: 12px;
    margin-left: 5px;
  }
  nav {
    width: 100% !important;
    font-weight: 400;
    /* padding: 4px 7px 1px 0px; */
    text-align: center;
    .dropdown {
      width: 100% !important;
      margin: 0px;
      margin-left: -10px !important;
      a {
        &:hover {
          color: white;
        }
      }
    }
    .dropdown-toggle::after {
      margin-left: 10px;
    }
    ul > div > a {
      text-transform: uppercase;
      letter-spacing: 1.5px;
      color: ${(props) => props.theme.menu};
      font-size: 13px;
      padding: 0 !important;
      margin: 0px;
      margin-right: 28px !important;
      padding-bottom: 12px !important;
      border-bottom: 3px solid transparent;
      font-weight: 500;
    }
    .active-menu-drawer {
      transition: all 0.3s;
      color: white;
      &:hover {
        background-color: ${(props) => props.theme.clientColors.secondary};
      }
    }

    .dropdown-menu {
      top: -2px !important;
      padding: 0;
      width: 100% !important;
      border: none !important;
      border-radius: 0px 0px 5px 5px;
      padding-top: 10px !important;
      padding-bottom: 12px !important;
      z-index: 99;
      a,
      span {
        margin: 0;
        font-size: 13px;
        text-transform: uppercase;
        color: $black;
        min-width: 100%;
        padding: 8px 30px !important;
        letter-spacing: 1px !important;
        font-weight: 600;
        &:hover {
          background: ${(props) => props.theme.hover};
          color: ${(props) => props.theme.primary};
          text-decoration: none;
        }
      }
      .disabled {
        color: ${(props) => props.theme.cinza} !important;
        font-size: 30px !important;
        cursor: default !important;
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
      .divider-menu {
        cursor: default;
        &:hover {
          background: transparent !important;
        }
      }
      .children-menu {
        font-size: 10px !important;
        padding-left: 50px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        font-weight: 400 !important;
        letter-spacing: 1.2px !important;
      }
    }
  }
`
