export default class KioskToken {
  private name = 'kiosk_token'

  get() {
    return localStorage.getItem(this.name)
  }

  set(kiosk_token) {
    if (!kiosk_token || !this.name) return null
    return localStorage.setItem(this.name, kiosk_token)
  }

  clear() {
    localStorage.removeItem(this.name)
  }
}
