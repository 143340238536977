import React from 'react'
import { Popover } from '@material-ui/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

type CandidateBreakComponentProps = {
  anchorElement: any
  open: boolean
  message: string
  onClose: (confirmed: boolean) => void
  className?: string
}

const CandidateBreakComponent = ({
  onClose,
  anchorElement,
  open,
  className,
  message
}: CandidateBreakComponentProps) => {
  const { t } = useTranslation()
  if (!anchorElement) {
    return <div></div>
  }

  const style = {
    padding: '15px'
  }

  return (
    <Popover
      className={`${className} root-element`}
      anchorEl={anchorElement}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      style={style}
    >
      <div className="inner-container">
        <span>{message}</span>
        <div className="buttons">
          <Button action="default" onClick={() => onClose(false)}>
            {t('No')}
          </Button>
          <Button
            className="confirm"
            action="primary"
            onClick={() => onClose(true)}
          >
            {t('Yes')}
          </Button>
        </div>
      </div>
    </Popover>
  )
}

export default styled(CandidateBreakComponent)`
  text-align: left;
  line-height: 1.5rem;

  .inner-container {
    width: 200px;
    padding: 1rem;
  }

  .buttons {
    font-size: 10pt;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    .confirm {
      margin-left: 0.5rem;
    }
  }
`
