import {
  UserConfiguration,
  UserConfigurationOptions
} from 'data/domain/user-config'

export const shouldEnableFaceDetection = (
  shouldFaceDetect: boolean,
  userConfiguration: UserConfiguration
) => {
  const disabledFaceDetection =
    userConfiguration?.faceDetectionEnabled === false

  if (disabledFaceDetection) {
    return false
  }

  return shouldFaceDetect
}

export const getDefaultUserConfiguration = () => {
  const defaultValues = {}
  UserConfigurationOptions.forEach((configOption) => {
    defaultValues[configOption.name] = configOption.default
  })
  return defaultValues
}
