import React from 'react'
import styled from 'styled-components'

type ColorfulProgressBarProps = {
  className?: string
  showValues?: boolean
  showProgressValues?: boolean
  max?: number
  value?: number
}

const getPercentage = (value?: number, max?: number): number => {
  if (max === undefined || max <= 0 || value === undefined || value < 0) {
    return 0
  }

  return Math.trunc((100 * value) / max)
}

const getColor = (value?: number, max?: number): string => {
  const percentage = getPercentage(value, max)

  if (percentage < 30) {
    return 'negative'
  }

  if (percentage < 75) {
    return 'neutral'
  }

  return 'positive'
}

const ColorfulProgressBar = ({
  className,
  max = 0,
  value = 0,
  showValues = false,
  showProgressValues = false,
  ...rest
}: ColorfulProgressBarProps) => (
  <span className={className}>
    {showValues && (
      <span className="percentage-value">{getPercentage(value, max)}%</span>
    )}
    <progress
      max={max}
      value={value}
      data-label={showProgressValues ? `${value}/${max}` : ''}
      {...rest}
    />
  </span>
)

export default styled(ColorfulProgressBar)`
  height: 1.5em;
  line-height: 1.5em;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);

  .percentage-value {
    display: block;
  }

  progress {
    border-radius: 5px;
  }

  progress[value]:before {
    content: attr(data-label);
    position: absolute;
    width: 100%;
    z-index: 1;
    display: inline-block;
    text-align: center;
    color: white;
    font-size: 0.8em;
    font-weight: bold;
  }

  progress[value],
  progress[value]::-webkit-progress-bar {
    position: relative;
    height: 1.5em;
    line-height: 1.5em;
    background-color: ${(props) => props.theme.colors.grayMedium};
  }

  progress::-moz-progress-bar {
    background: ${(props) =>
      props.theme.reportColors[getColor(props.value, props.max)]};
  }
  progress::-webkit-progress-value {
    background: ${(props) =>
      props.theme.reportColors[getColor(props.value, props.max)]};
  }
  progress {
    color: ${(props) =>
      props.theme.reportColors[getColor(props.value, props.max)]};
  }
`
