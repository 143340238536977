import { faCamera } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApproveExamModal from 'components/ApproveExamModal'
import ToggleMenuButtonItem from 'components/Buttons/ToggleMenuButtonItem'
import UserConfigurationModalForm from 'components/userConfiguration/UserConfigurationModalForm'
import CandidateContext from 'contexts/CandidateContext'
import RoomMessageExchangeContext from 'contexts/RoomMessageExchangeContext'
import { getCurrentSituation } from 'data/apis/message-exchange'
import useFeedback from 'hooks/useFeedback'
import usePreference from 'hooks/usePreference'
import qs from 'qs'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { errors, messages } from 'resources'
import { AuthContext } from 'states/AuthState'
import { ModalContext } from 'states/ModalState'
import styled from 'styled-components'
import ToggleMenuButton from '../../components/Buttons/ToggleMenuButton'
import {
  ActivityStatus,
  BreakStatus,
  IBooleanPreference,
  PauseStatus
} from '../../types'
import CandidateBehaviourDisplay from './CandidateBehaviourDisplay'
import ExamInfoIndicator from './ExamInfoIndicator'
import ExamStatusIndicator from './ExamStatusIndicator'
import ExplanationDrawer from './ExplanationDrawer'
import MessageToCandidate from './MessageToCandidate'
import StreamingWrapper from './StreamingWrapper'
import VideoIconQueue from './VideoIconQueue'

type CandidateVideoProps = {
  className?: string
  application: any
  isOnline?: boolean
  question?: string
  breakStatus?: BreakStatus
  activity?: ActivityStatus
  finished?: boolean
  pauseStatus?: PauseStatus
  permissionStatus?: boolean
  collection?: any
  roomId?: any
}

export type CandidateOnlineStatus = {
  lastPingTimestamp: string
  exam_status: ExamStatus
  questions: CandidateMessage
  break: CandidateBreakPacket
}

export type ExamStatus = {
  status: 'PAUSED' | 'NORMAL' | 'FINISHED'
  reason: string
}

export type CandidateAlert = {
  message: string
  when: string
}

export type CandidateMessage = {
  message: string
  when: string
}

export type CandidateBreakPacket = {
  when: Date
  confirmedStatus: 'CONFIRMED' | 'REJECTED' | 'PENDING'
}

const CandidateVideo = ({
  className,
  application,
  collection,
  isOnline,
  question,
  breakStatus,
  // activity,
  // finished,
  pauseStatus,
  roomId
}: // permissionStatus
CandidateVideoProps) => {
  const { t } = useTranslation()
  const {
    pauseCandidateExam,
    resumeCandidateExam,
    sendAlert,
    sendReloadRequest,
    sendMessageToAllOnline
  } = useContext(RoomMessageExchangeContext)
  const history = useHistory()
  const location = useLocation()
  // const [paused, setPaused] = useState(false)
  const [sendingMessage, setSendingMessage] = useState(false)
  const [
    configurationModalCandidateId,
    setConfigurationModalCandidateId
  ] = useState(undefined)
  const [isInInstructions, setIsInInstructions] = useState(false)
  const [isReceivingStream, setIsReceivingStream] = useState(true)
  const [staticImageOnly, setStaticImageOnly] = useState(false)
  const { candidate } = useContext(CandidateContext)
  const paused = !!candidate?.pause
  const acceptedCamera = !!candidate?.hasAcceptedCamera
  const finished = false
  const { showModal } = useContext(ModalContext)
  const permissionStatus = true
  const { handleErrorFeedback, handleSuccessFeedback } = useFeedback()
  const { perms } = useContext(AuthContext)
  const [pauseExplanationDrawer, setPauseExplanationDrawer] = useState(false)
  const [
    reloadRequestExplanationDrawer,
    setReloadRequestExplanationDrawer
  ] = useState(false)
  const pauseNeedsExplanation = usePreference<IBooleanPreference>(
    'Application__PauseNeedsExplanation'
  )
  const reloadRequestNeedsExplanation = usePreference<IBooleanPreference>(
    'Application__ReloadRequestNeedsExplanation'
  )
  const handleError = useCallback(
    (error: Error) => {
      handleErrorFeedback(error, t(errors.DEFAULT))
    },
    [t, handleErrorFeedback]
  )
  const allowInstructionsCommunication =
    collection?.allowInstructionsCommunication
  const sendPausePacket = (message: string, showMessage: boolean) => {
    return pauseCandidateExam(application.user.id, message, showMessage)
      .then(() => handleSuccessFeedback(t(messages.REQUEST_SENT)))
      .catch(handleError)
  }

  const sendResumePacket = () => {
    resumeCandidateExam(application.user.id)
      .then(() => handleSuccessFeedback(t(messages.REQUEST_SENT)))
      .catch(handleError)
  }

  const sendReloadRequestPacket = (message: string) => {
    return sendReloadRequest(application.user.id, message)
      .then(() => handleSuccessFeedback(t(messages.REQUEST_SENT)))
      .catch(handleError)
  }

  const handlePause = () => {
    if (pauseNeedsExplanation?.value) {
      setPauseExplanationDrawer(true)
    } else {
      sendPausePacket(t('Paused by applicator'), true)
    }
  }

  const handleMessage = (content: string, sendToAll?: boolean) => {
    if (!content) {
      return
    }

    if (sendToAll) {
      return sendMessageToAllOnline(content)
        .then(() => handleSuccessFeedback(t(messages.MESSAGE_SENT)))
        .catch((error) => {
          handleError(error)
          throw error
        })
    }

    return sendAlert(application.user.id, content)
      .then(() => handleSuccessFeedback(t(messages.MESSAGE_SENT)))
      .catch((error) => {
        // We throw an error here so MessageToCandidate knows the request failed
        handleError(error)
        throw error
      })
  }

  const handleReloadRequest = useCallback(
    (message?: string) => {
      if (reloadRequestNeedsExplanation?.value) {
        setReloadRequestExplanationDrawer(true)
      } else {
        sendReloadRequest(application.user.id, message)
          .then(() => handleSuccessFeedback(t(messages.REQUEST_SENT)))
          .catch(handleError)
      }
    },
    [
      application,
      t,
      handleSuccessFeedback,
      sendReloadRequest,
      reloadRequestNeedsExplanation,
      handleError
    ]
  )

  const shouldShowPaused = () => {
    if (paused) {
      return true
    }
    return pauseStatus === 'PAUSED'
  }

  const updateCurrentSituation = () => {
    const id = candidate?.id
    if (!id) {
      return
    }

    getCurrentSituation(id)
      .then((situation) => {
        setIsInInstructions(situation.isInInstructions)
      })
      .catch(() => {
        setIsInInstructions(false)
      })
  }

  return (
    <div className={className}>
      <UserConfigurationModalForm
        candidateId={configurationModalCandidateId}
        onClose={() => setConfigurationModalCandidateId(undefined)}
      />
      <VideoIconQueue
        application={application}
        breakStatus={breakStatus}
        question={question}
      />
      {isOnline &&
        !finished &&
        permissionStatus &&
        isReceivingStream &&
        acceptedCamera && (
          <StreamingWrapper
            user={application.user}
            collection={collection}
            staticImageOnly={staticImageOnly}
          />
        )}
      {/* {isOnline && (
        <VideoWrapper
          onDisconnect={handleDisconnect}
          onConnect={handleConnect}
          application={application}
        />
      )} */}
      {(!isOnline || finished) && (
        <div className="disconnected-info">
          <p>{t('Disconnected')}</p>
        </div>
      )}
      {!acceptedCamera && isOnline && (
        <div className="disconnected-info">
          <p style={{ textAlign: 'center' }}>
            <h1>
              <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
            </h1>
            {t('Camera permission pending')}
          </p>
        </div>
      )}
      {!isReceivingStream && isOnline && (
        <div className="disconnected-info">
          <p style={{ textAlign: 'center' }}>
            <h1>
              <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
            </h1>
            {t('Video receiving disabled')}
          </p>
        </div>
      )}
      <MessageToCandidate
        isOpen={sendingMessage}
        candidateName={application.user.name}
        onClose={() => setSendingMessage(false)}
        className="message-choosing-container"
        onMessageChosen={handleMessage}
      />
      <ExplanationDrawer
        title="Pause exam"
        isOpen={pauseExplanationDrawer}
        candidateName={application.user.name}
        onClose={() => setPauseExplanationDrawer(false)}
        onSubmit={sendPausePacket}
      />
      <ExplanationDrawer
        title="Reload request"
        showMessageToCandidate={false}
        isOpen={reloadRequestExplanationDrawer}
        candidateName={application.user.name}
        onClose={() => setReloadRequestExplanationDrawer(false)}
        onSubmit={sendReloadRequestPacket}
      />
      {isOnline && (
        <CandidateBehaviourDisplay
          className="candidate-behaviour-display"
          lookDirection={candidate?.faceDetectionAlert?.message}
        ></CandidateBehaviourDisplay>
      )}
      <div className="video-footer">
        <div className="video-footer-content">
          <div className="video-footer-sub-section">
            <ExamStatusIndicator
              status={
                shouldShowPaused() ? 'paused' : isOnline ? 'online' : 'offline'
              }
            />
            <p className="candidate-name">{application.user.name}</p>
          </div>
          <div className="video-footer-sub-section">
            {perms?.Dashboard?.Videos?.ViewInfo && (
              <ExamInfoIndicator
                candidateName={application.user.name}
                status={
                  finished
                    ? 'finished'
                    : paused
                    ? 'paused'
                    : isOnline
                    ? 'online'
                    : 'offline'
                }
                cellphone={application?.user?.person?.cellphone}
                userId={application?.user?.id}
              />
            )}
            <div
              className="kebab-menu"
              onClick={() => updateCurrentSituation()}
            >
              <ToggleMenuButton
                action="icon"
                data-testid="resume-toggle-menu-button"
                disabled={false}
              >
                {perms?.Dashboard?.Videos?.SendAlert && (
                  <ToggleMenuButtonItem
                    action="unstyled"
                    type="button"
                    disabled={
                      isInInstructions && !allowInstructionsCommunication
                    }
                    onClick={() => setSendingMessage(true)}
                  >
                    {t('Send alert')}
                  </ToggleMenuButtonItem>
                )}
                {perms?.Dashboard?.Videos?.SendReloadRequest && (
                  <ToggleMenuButtonItem
                    action="unstyled"
                    type="button"
                    disabled={
                      isInInstructions && !allowInstructionsCommunication
                    }
                    onClick={() => handleReloadRequest()}
                  >
                    {t('Send reload request')}
                  </ToggleMenuButtonItem>
                )}
                {perms?.Dashboard?.Videos?.PauseResume && (
                  <ToggleMenuButtonItem
                    action="unstyled"
                    type="button"
                    // If application is paused, always allow it to be resumed
                    // Otherwise, do not allow pause if user is in instructions
                    disabled={shouldShowPaused() ? false : isInInstructions}
                    onClick={
                      shouldShowPaused() ? sendResumePacket : handlePause
                    }
                  >
                    {shouldShowPaused() ? t('Resume exam') : t('Pause exam')}
                  </ToggleMenuButtonItem>
                )}
                {
                  <ToggleMenuButtonItem
                    action="unstyled"
                    type="button"
                    onClick={() => setIsReceivingStream(!isReceivingStream)}
                  >
                    {isReceivingStream
                      ? t('Stop receiving video')
                      : t('Resume receiving video')}
                  </ToggleMenuButtonItem>
                }
                {
                  <ToggleMenuButtonItem
                    action="unstyled"
                    type="button"
                    onClick={() => setStaticImageOnly(!staticImageOnly)}
                  >
                    {staticImageOnly
                      ? 'Visualizar stream'
                      : 'Manter imagem estatica somente'}
                  </ToggleMenuButtonItem>
                }
                {perms?.Dashboard?.Videos?.ViewHistory && (
                  <ToggleMenuButtonItem
                    action="unstyled"
                    type="button"
                    onClick={() =>
                      history.push(
                        location.pathname +
                          '?' +
                          qs.stringify({
                            ...qs.parse(location.search.slice(1)),
                            candidateId: application.user.id
                          }),
                        { candidateName: application.user.name }
                      )
                    }
                  >
                    <FontAwesomeIcon icon="history" /> {t('View history')}
                  </ToggleMenuButtonItem>
                )}
                {collection?.allowApproveExam ? (
                  <ToggleMenuButtonItem
                    action="unstyled"
                    type="button"
                    onClick={() =>
                      showModal(() => (
                        <ApproveExamModal
                          roomId={roomId}
                          collection={collection}
                          application={application}
                        />
                      ))
                    }
                  >
                    {t('Aprove exam')}
                  </ToggleMenuButtonItem>
                ) : (
                  ''
                )}
                {
                  <ToggleMenuButtonItem
                    action="unstyled"
                    type="button"
                    onClick={() =>
                      setConfigurationModalCandidateId(application.user.id)
                    }
                  >
                    {t('User configuration')}
                  </ToggleMenuButtonItem>
                }
              </ToggleMenuButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default styled(CandidateVideo)`
  flex: 1 1 20%;
  height: 210px;
  display: grid;
  grid-template-rows: 80% 20%;
  border: 1px solid #707070;
  margin: 8px;
  position: relative;

  .candidate-behaviour-display {
    position: absolute;
    bottom: 20%;
    width: 100%;
  }

  .message-choosing-container {
    height: 100%;
    z-index: 2;
    position: absolute;
  }

  .video-footer {
    position: absolute;
    bottom: 0;
    height: 20%;
    width: 100%;
    background-color: #e3e3e3;
    align-self: flex-end;
    outline: 1px solid #d5d5d5;
  }

  .video-footer-content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    font: Bold 12px Open Sans, sans-serif;
    color: #5a5a5a;
  }

  .video-footer-sub-section {
    display: flex;
    align-items: center;
  }

  .candidate-name {
    text-transform: capitalize;
    margin-left: 5px;
  }

  .kebab-menu > button {
    border: none;
    width: 0.7rem;
    padding: 2px;
    margin-left: 4px;
  }

  .disconnected-info {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.colors.text};
    background: ${(props) => props.theme.colors.grayMedium};

    &.bathroom {
      box-shadow: inset 0px 0px 0px 5px #528652;
      opacity: 0.61;
      background: ${(props) => props.theme.colors.grayDark};
      color: white;
    }
  }

  .disconnected-info > p {
    align-self: center;
    font-weight: bold;
  }

  .has-error {
    border: 1px solid red;
  }
`
