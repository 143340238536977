import React, { useState } from 'react'
import axios from 'axios'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { API_HOST } from '../consts'
import { getStringListFromObject } from '../utils/list'
import ImportBase from '../components/Imports/ImportBase'
import CheckBoxInput from '../components/Inputs/CheckBoxInput'
import styled from 'styled-components'
import icone from '../assets/img/importImg.png'

const ImportExamPage = () => {
  const { t } = useTranslation()
  const [file, setFile] = useState(new Blob())
  const [errors, setErrors] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const importedMessage = t('Exam successfully imported!')

  const createExam = (data) => {
    axios
      .post(`${API_HOST}/v1/exams`, data)
      .then(() => {
        setSuccessMessage(importedMessage)
        setLoading(false)
      })
      .catch((err) => {
        const errList = getStringListFromObject(
          get(err, 'response.data', {})
        ) as []
        setErrors(errList)
        setLoading(false)
      })
  }

  const handleSubmit = (values) => {
    setErrors([])
    setLoading(true)
    setSuccessMessage('')
    if (file) {
      if (file.type === 'application/json') {
        const reader = new FileReader()
        reader.onload = (event) => {
          const jsonString = event?.target?.result
          if (jsonString && typeof jsonString === 'string') {
            try {
              const data = {
                ...values,
                ...JSON.parse(jsonString)
              }
              createExam(data)
            } catch {
              setErrors([t('JSON format is invalid.')])
              setLoading(false)
            }
          }
        }
        reader.readAsText(file)
      } else {
        setErrors([t('File should be .json.')])
        setLoading(false)
      }
    }
  }

  const instructions = (
    <span>
      {t(
        'Receive a .json file with the content of the exam. This file can be generated by exporting a exam in the item creation system.'
      )}
    </span>
  )

  return (
    <Formik initialValues={{ updateIfExists: false }} onSubmit={handleSubmit}>
      {(formik) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              width: '45%',
              display: 'flex',
              flexDirection: 'row',
              borderRadius: '16px',
              boxShadow: '0px 1px 4px rgba(58, 58, 58, 0.25)'
            }}
          >
            <div
              style={{
                marginLeft: '3.7%',
                marginTop: '6.2%',
                width: '115px',
                height: '115px'
              }}
            >
              <img height={115} src={icone} />{' '}
            </div>
            <div style={{ width: '100%' }}>
              <ImportBase
                title={t('Import exam')}
                instructions={instructions}
                onSubmit={formik.submitForm}
                onFileChange={(event) =>
                  setFile(get(event, 'target.files[0]', {}))
                }
                errors={errors}
                successMessage={successMessage}
                loading={loading}
              >
                <div
                  style={{
                    marginTop: '0.3rem',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span style={{ margin: '0.2rem 0 0.5rem 0' }}>
                    {get(file, 'name', '')}
                  </span>
                  <CheckBoxInput
                    label="Atualizar dados que ja existirem"
                    name="updateIfExists"
                    disabled={loading}
                  />
                </div>
              </ImportBase>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default styled(ImportExamPage)`
  .backButton1 {
    border: none;
    border: none;
    display: flex;
    background: #ff6600;
    &:hover {
      background: #ea6f2d;
    }
  }
`
