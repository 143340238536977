import React, { createContext } from 'react'
import { IRoomListState } from '../types'
import useList from '../hooks/useList'
import useQuery from 'hooks/useQuery'
import { DASHBOARD_API_HOST } from 'consts'
import { FormikValues } from 'formik'
import { get } from 'lodash'

type RoomProps = {
  children: Function
  initialValues?: any
}

const initialProctorState: IRoomListState = {
  proctorsList: [],
  fetchingProctors: false,
  item: undefined,
  id: undefined,
  position: undefined,
  introduction: undefined,
  question: undefined,
  category: undefined,
  handleFilter: () => undefined,
  handlePageChange: () => undefined,
  handlePageSizeChange: () => undefined,
  // onPageSizeChange: () => undefined,
  onFilter: () => undefined,
  totalItems: undefined,
  pageSize: undefined,
  numPages: undefined
}

export const ItemWithFreeResponseContext = createContext<IRoomListState>(
  initialProctorState
)

const ProctorState = ({ children, initialValues }: RoomProps) => {
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  const {
    isLoading: fetchingProctors,
    results: proctorsList,
    count: totalItems,
    handleFilter,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    numPages
  } = useList({
    api: `${DASHBOARD_API_HOST}/v1/rooms?opened=true&collection=${collectionId}`,
    defaultPageSize: 10
  })

  const mapFilters = (values: FormikValues) => {
    const { is_recording } = values
    return {
      is_recording: get(is_recording, 'value')
    }
  }

  const onFilter = (values: FormikValues) => {
    const filterParams = mapFilters(values)
    handleFilter(filterParams)
  }

  const contextValue = {
    proctorsList,
    totalItems,
    fetchingProctors,
    handleFilter,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    numPages,
    onFilter
  }

  return (
    <ItemWithFreeResponseContext.Provider value={contextValue}>
      {children(contextValue)}
    </ItemWithFreeResponseContext.Provider>
  )
}

export default ProctorState
