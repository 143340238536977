import React from 'react'
import ReactQuill from 'react-quill'
import EditorToolbar, { modules, formats } from './EditorToolbar'
import 'react-quill/dist/quill.snow.css'

type TextEditorProps = {
  value?: any
  className?: string
  onChange?: any
  name?: any
}

const TextEditor = ({
  value,
  onChange,
  className,
  name,
  ...props
}: TextEditorProps) => {
  return (
    <div className={className}>
      <EditorToolbar />
      <ReactQuill
        {...props}
        modules={modules}
        formats={formats}
        theme="snow"
        value={value}
        onChange={onChange}
        style={{ width: '100%', maxWidth: '1100px' }}
      />
    </div>
  )
}

export default TextEditor
