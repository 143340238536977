import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faFile, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

type ItemSubtitlesInfosProps = {
  className?: string
  itemPosition?: any
  itemValue?: any
}

const ItemSubtitlesInfos = ({
  itemPosition,
  itemValue,
  className
}: ItemSubtitlesInfosProps) => {
  const { t } = useTranslation()

  return (
    <>
      <span className={className}>
        <FontAwesomeIcon className="icon-space" icon={faFile} />
        {t('Questão').toUpperCase()}: {itemPosition}
      </span>
      <span className={className}>
        <FontAwesomeIcon className="icon-space" icon={faCheckSquare} />
        {t('Valor da questão').toUpperCase()}: {itemValue}
      </span>
    </>
  )
}

export default styled(ItemSubtitlesInfos)`
  font-size: 12px;

  .icon-space {
    margin-right: 10px;
  }
`
