import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'
import { AuthContext } from 'states/AuthState'
import { checkIsInStayTimeWindow } from 'data/apis/applications'

const getConfirmButtonText = (inStayTimeWindow: boolean | undefined) =>
  inStayTimeWindow ? 'Leave anyway' : 'Leave'

const getCancelButtonText = () => 'Cancel'

const getText = (inStayTimeWindow: boolean) => {
  if (inStayTimeWindow === true) {
    return 'If you leave now, you will be disclassified, since the minimum stay time was not reached yet.'
  }
  if (inStayTimeWindow === false) {
    return ''
  }
  return 'Please check if you are not within the minimum stay time.'
}

const useLogoutConfirmation = () => {
  const { t } = useTranslation()
  const { logout, user } = useContext(AuthContext)

  const getIsInStayTimeWindow = async () => {
    let isInStayTimeWindow
    try {
      isInStayTimeWindow = await checkIsInStayTimeWindow()
    } catch {
      isInStayTimeWindow = undefined
    }
    return isInStayTimeWindow
  }

  const handleConfirmation = async () => {
    try {
      await logout()
    } finally {
      window.location.href = user.provider.logoutRedirectUrl
    }
  }

  const renderAlert = async () => {
    const isInStayTimeWindow = await getIsInStayTimeWindow()

    const confirmButtonText = t(
      getConfirmButtonText(isInStayTimeWindow)
    ).toUpperCase()
    const cancelButtonText = t(getCancelButtonText()).toUpperCase()
    const text = t(getText(isInStayTimeWindow))

    return swal({
      title: t('Leave the testing site?'),
      icon: 'warning',
      buttons: [cancelButtonText, confirmButtonText],
      dangerMode: true,
      text
    })
  }

  const askConfirmation = async () => {
    const confirmed = await renderAlert()
    if (confirmed) {
      handleConfirmation()
    }
  }

  return askConfirmation
}

export default useLogoutConfirmation
