import * as firebase from 'firebase/app'
import 'firebase/firebase-firestore'
import 'firebase/firebase-analytics'
import 'firebase/firebase-auth'
import 'firebase/firebase-storage'
import 'firebase/firebase-database'
import { FIREBASE_CONFIG, FIREBASE_ENABLED } from 'consts'

let config
let db
let storage
let oldRealTimeDb

const firebaseEnabled = FIREBASE_ENABLED

if (firebaseEnabled) {
  try {
    config = FIREBASE_CONFIG && JSON.parse(FIREBASE_CONFIG)
  } catch (_) {
    throw new Error(
      'Invalid Firebase config. Please set REACT_APP_FIREBASE_CONFIG to continue.'
    )
  }
  firebase.initializeApp(config)
  db = firebase.firestore()
  storage = firebase.storage()
  oldRealTimeDb = firebase.database()
}
export { db, storage, oldRealTimeDb }
// firebase.analytics()

export default firebase
