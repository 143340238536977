import { storage } from '../../infra/firebase'

export const uploadBase64 = (
  candidateId: string,
  subFolder = '',
  base64String: string,
  cancellationToken?: any
): Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const task = storage
        .ref(`/${candidateId}/${subFolder}`)
        .putString(base64String, 'data_url', { contentType: 'image/jpg' })
      task.on('state_changed', null, null, async () => {
        const downloadUrl = await task.snapshot.ref.getDownloadURL()
        resolve(downloadUrl)
      })

      if (cancellationToken !== undefined) {
        cancellationToken.cancel = () => {
          task.cancel()
          reject(
            new Error(
              'Base 64 uploading was cancelled by the cancellation token.'
            )
          )
        }
      }
    } catch (e) {
      reject(
        new Error(
          `Error while uploading base 64 string to ${candidateId} folder. ${e.message}`
        )
      )
    }
  })
}
