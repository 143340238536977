import Axios from 'axios'
import swal from 'sweetalert2'
import { FormikValues } from 'formik'
import { get, isEmpty } from 'lodash'
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { API_HOST, DASHBOARD_API_HOST } from '../consts'
import useList from '../hooks/useList'
import {
  childrenIsFunction,
  ExamDashboardStateAction,
  IExamDashboardState,
  IHierarchy,
  IInstitution,
  IInstitutionData
} from '../types'
import ConfigContext from 'contexts/ConfigContext'
import useQuery from 'hooks/useQuery'
import ExamDashboardContext, {
  initialState
} from 'contexts/ExamDashboardContext'
import { createMessage } from 'data/apis/message-exchange'
import { useTranslation } from 'react-i18next'
import { ModalContext } from './ModalState'
import { sumTimeout } from 'utils/time'
import { AuthContext } from './AuthState'

type ExamDashboardStateProps = {
  children: Function | ReactNode
}
const reducer = (
  state: IExamDashboardState,
  action: ExamDashboardStateAction
): IExamDashboardState => {
  switch (action.type) {
    case 'FETCH_EXAM':
      return { ...state, fetchingExam: true, fetchExamError: '' }
    case 'FETCH_EXAM_SUCCESS':
      return {
        ...state,
        fetchingExam: false,
        fetchExamError: '',
        exam: action.payload
      }
    case 'FETCH_EXAM_ERROR':
      return { ...state, fetchingExam: false, fetchExamError: action.payload }
    case 'FETCH_APPLICATIONS':
      return {
        ...state,
        fetchedApplications: false,
        fetchingApplications: true,
        fetchApplicationsError: ''
      }
    case 'FETCH_APPLICATIONS_SUCCESS':
      return {
        ...state,
        fetchedApplications: true,
        fetchingApplications: false,
        fetchApplicationsError: '',
        applications: action.payload
      }
    case 'FETCH_APPLICATIONS_ERROR':
      return {
        ...state,
        fetchedApplications: true,
        fetchingApplications: false,
        fetchApplicationsError: action.payload
      }
    case 'ON_SEARCH_CHANGE':
      return { ...state, search: action.payload }
    case 'ON_FILTER_CHANGE':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const ExamDashboardState = ({ children }: ExamDashboardStateProps) => {
  const { hasGroup } = useContext(AuthContext)
  const { t } = useTranslation()
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')
  const { hideModal } = useContext(ModalContext)
  const [isFilter, setIsFilter] = useState<number>()
  const [api, setApi] = useState<any>()

  const { syncApplicationsExamDashboardInterval } = useContext(ConfigContext)
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchSchool = useCallback(async () => {
    try {
      if (!hasGroup('ADMINISTRADOR')) {
        const response = await Axios.get<IInstitutionData[]>(`${API_HOST}/v1/users_group_by_institucions`);
        const result = response.data.map((schools) => ({
          id: schools.institucion['id'],
          name: schools.institucion['dsEscola'],
          grupo: schools.group['name'],
        }));



        setApi(`${DASHBOARD_API_HOST}/v1/applications/dashboard?collection=${collectionId}`)
      } else {
        setApi(
          `${DASHBOARD_API_HOST}/v1/applications/dashboard?collection=${collectionId}`
        )
      }
    } catch (err) { }
  }, [])

  const {
    isLoading: fetchingApplications,
    fetched: fetchedApplications,
    results: applications,
    fetchList: fetchApplications,
    count: totalApplications,
    handleFilter,
    handleSearchChange,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    numPages
  } = useList({
    api: api,
    defaultPageSize: 10

  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Axios.get(`${API_HOST}/v1/applications`, {
          params: { collection: collectionId }
        });

        fetchSchool();

        if (result.status === 200) {
          setIsFilter(result.data.count);
        }
      } catch {
        setIsFilter(0);
      }
    };

    fetchData();
  }, [collectionId]);

  // useEffect(() => {
  //   console.log('schoolData', schoolData);
  //   // Aqui você pode realizar as ações necessárias com os dados em schoolData
  // }, [schoolData]);

  const onPageSizeChange = (event: any) => {
    handlePageSizeChange(event.target.value)
  }

  const syncApplications = useCallback(() => {
    fetchApplications(false)
  }, [fetchApplications])

  // Syncs answers with the server in a set interval
  useEffect(() => {
    const timer = setInterval(
      syncApplications,
      syncApplicationsExamDashboardInterval
    )
    return () => clearTimeout(timer)
  }, [syncApplications, syncApplicationsExamDashboardInterval])

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'ON_SEARCH_CHANGE', payload: event.target.value })
    handleSearchChange(event.target.value)
  }

  const mapHierarchy = (hierarchy: {
    [key: string]: IHierarchy | undefined
  }) => {
    // Maps hierarchy filter to how the api expects
    if (!hierarchy) {
      return undefined
    }

    const value = Object.entries(hierarchy)
      .filter((entry) => entry[1])
      .reduce(
        (acc, entry) => ({
          ...acc,
          [entry[0]]: { value: get(entry[1], 'value') }
        }),
        {}
      )

    // If no value selected, returns undefined
    // so it is not added in query string by axios
    return isEmpty(value) ? undefined : value
  }

  const mapFilters = (values: FormikValues) => {
    // Maps filters from how they are saved in form to how the api expects
    const {
      collection,
      user,
      exam,
      Turmas,
      nursing_mother,
      special_need,
      finished_applications,
      Escolas,
      ...hierarchy
    } = values
    const specialNeed = get(special_need, 'specialNeed')
    return {
      collection: get(collection, 'id'),
      exam: get(exam, 'id'),
      user: get(user, 'id'),
      classes: get(Turmas, 'id'),
      schools: get(Escolas, 'id'),
      hierarchy: mapHierarchy(hierarchy),
      status: get(finished_applications, 'value'),
      is_nursing_mother: get(nursing_mother, 'value'),
      is_special_need: !specialNeed ? '' : specialNeed
    }
  }

  const onFilter = (values: FormikValues) => {
    const filterParams = mapFilters(values);
    const payload = {
      filterParams,
      appliedFilters: values
    };
    dispatch({ type: 'ON_FILTER_CHANGE', payload });
    handleFilter(filterParams);
  };

  const setBulkTimeWindow = async (values: FormikValues) => {
    let allStudentIds: string[] = [];

    if (applications.length === 10) {
      const fetchAllApplications = async (page: number = 1) => {
        const response = await Axios.get(`${api}&page=${page}`, {
          params: {
            schools: state.appliedFilters?.Escolas?.id || undefined,
            exam: state.appliedFilters?.Exam?.id || undefined,
            classes: state.appliedFilters?.Turmas?.id || undefined,
            status: state.appliedFilters?.finished_applications?.value || undefined,
            is_special_need: state.appliedFilters?.special_need?.specialNeed || undefined,
            user: state.appliedFilters?.user?.id || undefined,
          }
        });
        const applications2 = response.data.results;
        const studentIds = applications2.flatMap((application: any) => application.user.id);
        allStudentIds = [...allStudentIds, ...studentIds];

        const totalPages = response.data.numPages;
        if (page < totalPages) {
          await fetchAllApplications(page + 1);
        }
      };

      await fetchAllApplications();
    } else {
      allStudentIds = applications.map((application: any) => application.user.id);
    }

    return Axios.post(
      `${API_HOST}/v1/applications/bulk_set_application_dates`,
      {
        search: state.search,
        ...values,
        ...mapFilters(state.appliedFilters),
        collection: collectionId,
        selectedStudents: allStudentIds,
      }
    ).then(() => {
      fetchApplications(false);
    });
  };

  const getVerboseFilters = () => {
    const verboseFilters = Object.entries(state.appliedFilters)
      .filter((entry) => entry[1])
      .map((entry) => entry[1].name)

    if (state.search) {
      verboseFilters.push(state.search)
    }

    return verboseFilters
  }

  // const setTimeoutByFilters = (values: any) => {
  //   try {
  //     console.log(totalApplications)
  //     applications.forEach((data) => {
  //       Axios.patch(`${API_HOST}/v1/applications/${data.id}/set_timeout`, {
  //         timeout: parseInt(values.newTime)
  //       }).then(() => {
  //         createMessage(data.roomId.toString(), data.user.id.toString(), {
  //           message: 'MORE_TIME',
  //           text: `Você ganhou mais ${values.newTime} minutos para fazer a atividade`,
  //           authorId: data.user.id.toString(),
  //           notificationType: 'BADGE',
  //           author: 'PROCTOR',
  //           motive: 'MORE_TIME',
  //           time: parseInt(values.newTime)
  //         })

  //         data.timeout = sumTimeout(data.timeout, values.newTime)
  //       })
  //       data.timeWindows[0].endTime = sumTimeout(
  //         data.timeWindows[0].endTime,
  //         values.newTime
  //       )
  //     })

  //     swal.fire({
  //       icon: 'success',
  //       title: t('Timeout was changed with successfully')
  //     })

  //     hideModal()
  //   } catch {
  //     swal.fire({
  //       icon: 'error',
  //       title: t('It was not possible to change timeout')
  //     })

  //     hideModal()
  //   }
  // }

  const setTimeoutByCollection = async (values: any) => {
    try {
      await Axios.put(
        `${API_HOST}/v1/applications/exam/${collectionId}/set_timewindow`,
        {
          endTime: parseInt(values.newTime)
        }
      )

      const response = await Axios.put(
        `${API_HOST}/v1/applications/bulk_set_timeout`,
        {
          collection: collectionId,
          timeout: parseInt(values.newTime),
          ...mapFilters(state.appliedFilters)
        }
      )

      if (response.status === 200) {
        applications.forEach((data) => {
          createMessage(data.roomId.toString(), data.user.id.toString(), {
            message: 'MORE_TIME',
            text: `Você ganhou mais ${values.newTime} minutos para fazer a atividade`,
            authorId: data.user.id.toString(),
            notificationType: 'BADGE',
            author: 'PROCTOR',
            motive: 'MORE_TIME',
            time: parseInt(values.newTime)
          })
          if (data?.timeout) {
            data.timeout = sumTimeout(data.timeout, values?.newTime)
          }
        })
      }

      swal.fire({
        icon: 'success',
        title: t('Timeout was changed with successfully')
      })
      hideModal()
    } catch {
      swal.fire({
        icon: 'error',
        title: t('It was not possible to change timeout')
      })

      hideModal()
    }
  }

  const handleSetTimeoutSubmit = async (values: any) => {
    if (isFilter !== totalApplications) {
      // setTimeoutByFilters(values)
      setTimeoutByCollection(values)
    } else {
      setTimeoutByCollection(values)
    }
  }


  const contextValue = {
    ...state,
    fetchedApplications,
    fetchingApplications,
    applications,
    totalApplications,
    onSearchChange,
    onFilter,
    handlePageChange,
    onPageSizeChange,
    numPages,
    pageSize,
    setBulkTimeWindow,
    getVerboseFilters,
    fetchApplications,
    handleSetTimeoutSubmit
  }

  return (
    <ExamDashboardContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </ExamDashboardContext.Provider>
  )
}

export default ExamDashboardState
