import axios from 'axios'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import { RollbarErrorTracking } from 'infra/rollbar'
import { API_HOST } from '../consts'
import { IPersonState, childrenIsFunction } from '../types'

type PersonStateProps = {
  children: Function | ReactElement
}

export const initialState: IPersonState = {
  person: undefined,
  loadingPerson: false
}

export const PersonContext = createContext<IPersonState>(initialState)

const PersonState = ({ children }: PersonStateProps) => {
  const [person, setPerson] = useState(undefined)
  const [loadingPerson, setLoadingPerson] = useState(false)

  const fetchPerson = useCallback(async () => {
    setLoadingPerson(true)
    const response = await axios.get(`${API_HOST}/v1/person/me`)
    try {
      setPerson(response.data)
      setLoadingPerson(false)
    } catch (error) {
      RollbarErrorTracking.logError(error)
      setLoadingPerson(false)
    }
  }, [])

  useEffect(() => {
    fetchPerson()
  }, [fetchPerson])

  const contextValue = { person, loadingPerson }

  return (
    <PersonContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </PersonContext.Provider>
  )
}

export default PersonState
