import React from 'react'
import Button from './Button'
import ModalFooter from './Modal/ModalFooter'
import Modal from './Modal/ModalRootModal'
import { useTranslation } from 'react-i18next'

interface Props {
  onSubmit: () => void
}

const CheckApplicationTimeWindowModal = ({ onSubmit }: Props) => {
  const { t } = useTranslation()

  return (
    <Modal title="Essa aplicacao ainda nao possui tempo de atividade">
      Deseja adicionar um tempo de atividade?
      <ModalFooter>
        <Button type="submit" action="primary" onClick={onSubmit}>
          {t('Confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CheckApplicationTimeWindowModal
