import { Formik, FormikValues } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import LightBorderCard from '../../components/Card/LightBorderCard'
import HierarchyFilter from '../../components/HierarchyFilter'
import Page from '../../components/Page'
import PromiseButton from '../../components/PromiseButton'
import { API_HOST } from '../../consts'
import usePreference from '../../hooks/usePreference'
import { AuthContext } from '../../states/AuthState'
import { IHierarchy, IStringPreference } from '../../types'
import { downloadFile } from '../../utils/files'

const initialValues: FormikValues = {}

const Download = () => {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const requiredHierarchiesP = usePreference<IStringPreference>(
    'Application__RequiredFiltersCollectionResult'
  )
  const requiredHierarchies = requiredHierarchiesP?.value
    ? requiredHierarchiesP?.value.split(',')
    : []

  const mapHierarchy = (hierarchy: {
    [key: string]: IHierarchy | undefined
  }) => {
    // Maps hierarchy filter to how the api expects
    if (!hierarchy) {
      return undefined
    }

    const value = Object.entries(hierarchy)
      .filter((entry) => entry[1])
      .reduce(
        (acc, entry) => ({
          ...acc,
          [entry[0]]: { value: get(entry[1], 'value') }
        }),
        {}
      )

    // If no value selected, returns undefined
    // so it is not added in query string by axios
    return isEmpty(value) ? undefined : value
  }

  const mapFilters = (values: FormikValues) => {
    // Maps filters from how they are saved in form to how the api expects
    const { collection, ...hierarchy } = values
    return {
      collection: get(collection, 'id'),
      hierarchy: mapHierarchy(hierarchy)
    }
  }

  const handleSubmit = async (values: FormikValues) => {
    return downloadFile({
      url: `${API_HOST}/v1/collection_result`,
      params: {
        format: 'excel',
        ...mapFilters(values)
      }
    })
  }

  return (
    <Page>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <div style={{ borderRadius: '40px', background: '#3e2' }}>
            <LightBorderCard title="Preencha os campos abaixo para exportar as notas">
              <HierarchyFilter
                providerCodename={user?.provider?.codename}
                required={requiredHierarchies}
              />
              <PromiseButton
                icon="download"
                action="secondary"
                onClick={() => formik.submitForm()}
                disabled={requiredHierarchies.some(
                  (d) => formik.values[d] === null
                )}
              >
                {t('Grades').toUpperCase()}
              </PromiseButton>
            </LightBorderCard>
          </div>
        )}
      </Formik>
    </Page>
  )
}

export default Download
