import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import axios from 'axios';
import styled from 'styled-components';
import { API_HOST } from 'consts';
import CloseIcon from '@material-ui/icons/Close';

const PreClass = ({ images, onClose, storyId, className, storyView, setView }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleStoryView = () => {
    if(!storyView){
      axios.post(`${API_HOST}/post_view/`, {
        stories_id: storyId
      })
        .then(response => {
          console.log('Resposta do servidor:', response.data);
        })
        .catch(error => {
          console.error('Erro:', error);
        });
    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    const totalPages = images.length;
    const nextPage = currentPage + 1;

    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
    } else {
      handleStoryView()
      onClose();
      setView(true)
    }
  };

  return (
    <div className={className}>
      <div className="container">
        <div className='close' onClick={onClose}><CloseIcon style={{color:'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}}/></div>
        <div className="prev" onClick={handlePrevPage}>
          <svg
            width="41"
            height="42"
            viewBox="0 0 41 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_3348_1654)">
              <path
                d="M36.5 17C36.5 22.75 33.4375 28 28.5 30.875C23.5 33.75 17.4375 33.75 12.5 30.875C7.5 28 4.5 22.75 4.5 17C4.5 11.3125 7.5 6.0625 12.5 3.1875C17.4375 0.3125 23.5 0.3125 28.5 3.1875C33.4375 6.0625 36.5 11.3125 36.5 17ZM18.9375 8.9375L11.9375 16C11.3125 16.5625 11.3125 17.5 11.9375 18.0625L18.9375 25.0625C19.5 25.6875 20.4375 25.6875 21 25.0625C21.625 24.5 21.625 23.5625 21 22.9375L16.5625 18.5H28C28.8125 18.5 29.5 17.875 29.5 17C29.5 16.1875 28.8125 15.5 28 15.5H16.5625L21 11.0625C21.625 10.5 21.625 9.5625 21 8.9375C20.4375 8.375 19.5 8.375 18.9375 8.9375Z"
                fill="white"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_3348_1654"
                x="0.5"
                y="0.3125"
                width="40"
                height="41.4375"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3348_1654" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3348_1654" result="shape" />
              </filter>
            </defs>
          </svg>
        </div>
        <div className="next" onClick={handleNextPage}>
          <svg width="105" height="130" viewBox="0 0 105 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_3348_1655)">
              <path d="M48.5 49C48.5 43.3125 51.5 38.0625 56.5 35.1875C61.4375 32.3125 67.5 32.3125 72.5 35.1875C77.4375 38.0625 80.5 43.3125 80.5 49C80.5 54.75 77.4375 60 72.5 62.875C67.5 65.75 61.4375 65.75 56.5 62.875C51.5 60 48.5 54.75 48.5 49ZM66.0625 57.0625L73.0625 50.0625C73.625 49.5 73.625 48.5625 73.0625 47.9375L66.0625 41C65.4375 40.375 64.5 40.375 63.9375 41C63.3125 41.5625 63.3125 42.5 63.9375 43.0625L68.375 47.5H57C56.125 47.5 55.5 48.1875 55.5 49C55.5 49.875 56.125 50.5 57 50.5L68.375 50.5625L63.9375 55C63.3125 55.5625 63.3125 56.5 63.9375 57.0625C64.5 57.6875 65.4375 57.6875 66.0625 57.0625Z" fill="white" />
            </g>
            <defs>
              <filter id="filter0_d_3348_1655" x="0.5" y="0.3125" width="128" height="129.438" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="16" />
                <feGaussianBlur stdDeviation="24" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3348_1655" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3348_1655" result="shape" />
              </filter>
            </defs>
          </svg>
        </div>

        <img src={images && images[currentPage - 1]} alt={`Imagem ${currentPage}`} />
        <Pagination
          count={images && images.length}
          page={currentPage}
          onChange={handlePageChange}
          className="pagination"
          hidePrevButton
          size='small'
          hideNextButton
          renderItem={(item) => (
            <PaginationItem
              {...item}
              style={{
                backgroundColor: currentPage === item.page ? 'white' : 'rgba(255, 255, 255, 0.5)',
                color: 'transparent',
                borderRadius: '50%',
                width: currentPage === item.page ? '15px' : '10px',
                height: currentPage === item.page ? '15px' : '10px',
                minWidth: 'auto',
                margin: '0 4px',
                transition: 'all 0.5s ease'
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default styled(PreClass)`

  width: auto;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: #262626;
  z-index: 21474836483;

  @media (max-width: 768px) {
      align-items: center;
  }

  .close {
    position:absolute;
    right: 10px;
    top:10px;
    cursor: pointer;
  }

  .container {
    width: auto;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    position: relative;
    
    margin: 0 auto;
    max-height: 180vw;
  }

  .prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 12px;
    cursor: pointer;
  }

  .next {
    position: absolute;
    right: 0;
    top: 51.5%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  img {
    max-width: 56vh;
    height: 100%;
    object-fit: cover;
  }

  .pagination {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
