import styled from 'styled-components'

const AnswerReviewList = styled.div`
  display: grid;
  grid-auto-columns: 100px;
  grid-template-columns: 1fr;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
`

export default AnswerReviewList
