import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Timestamp } from 'data/domain/message-exchange'
import Countdown from 'react-countdown'
import CircularBorderLoader from 'components/DataDisplay/CircularBorderLoader'

type TimeoutVideoIconProps = {
  className?: string
  type: string
  icon: IconProp
  onClick?: () => void
  maxDuration: Timestamp
}

const TimeoutVideoIcon = ({
  className,
  icon,
  onClick,
  maxDuration
}: TimeoutVideoIconProps) => {
  const [percentage, setPercentage] = useState(0)

  const start = useMemo(() => new Date(), [])
  start.setTime(start.getTime())
  const end = useMemo(() => new Date(), [])
  end.setTime(maxDuration.seconds * 1000)
  const range = useMemo(() => end.getTime() - start.getTime(), [end, start])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const elapsedFromNow = new Date().getTime() - start.getTime()
      const percentageElapsed = (elapsedFromNow / range) * 100
      setPercentage(percentageElapsed)
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [maxDuration, start, end, range])

  return (
    <div className={className} onClick={onClick} role="button">
      <Countdown
        date={end}
        renderer={({ ...time }) => {
          let label = ''
          label += time.minutes.toString().padStart(2, '0')
          label += ':'
          label += time.seconds.toString().padStart(2, '0')
          return <h4 style={{ color: 'white' }}>{label}</h4>
        }}
      />

      <div className={`icon-container ${percentage >= 100 ? 'pulse' : ''}`}>
        <CircularBorderLoader
          className="circular-loader"
          percentage={percentage}
          size={50}
        ></CircularBorderLoader>
        <FontAwesomeIcon className="icon" icon={icon} />
      </div>
    </div>
  )
}

export default styled(TimeoutVideoIcon)`
  display: flex;
  align-items: center;

  h4 {
    margin: 0;
    width: 45px;
  }

  .circular-loader {
    top: -50%;
    left: 50%;
    position: absolute;
  }

  .icon-container {
    margin-left: 5px;
    background: ${(props) => props.theme.videoColors[props.type]};
    border-radius: 50%;
    padding: 5px;

    &.pulse {
      animation: pulse-shadow 2s infinite;
    }
  }

  .icon {
    border-radius: 50%;
    color: white;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.85);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.85);
    }
  }

  @keyframes pulse-shadow {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.theme.videoColors[props.type]};
    }

    70% {
      box-shadow: 0 0 0 12px #ffffff00;
    }

    100% {
      box-shadow: 0 0 0 0 #ffffff00;
    }
  }
`
