import styled from 'styled-components'

export default styled.button`
  transition: all 0.3s;
  font-size: 40px;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #ccc;
  color: #606060;
  background-color: #ccc;

  :not(.active) {
    background-color: white;
  }
  :not(.active):hover {
    background-color: #ccc;
  }
`
