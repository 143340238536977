import debounce from 'debounce-promise'
import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AnswerContext } from '../states/AnswerState'
import { ApplicationContext } from '../states/ApplicationState'
import TextArea from './TextArea'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import { toast } from 'react-toastify'
import Html from './Html'
import { API_HOST } from 'consts'
import Axios from 'axios'

type MultipleFreeResponseProps = {
  className?: string
}

const MultipleFreeResponse = ({ className }: MultipleFreeResponseProps) => {
  const {
    answer,
    updateFreeResponse,
    item,
    alreadyAnswered,
    finishingAnswer
  } = useContext(AnswerContext)
  const { t } = useTranslation()
  const [responseValue, updateResponseValue] = useState('')
  const [countResponses, setCountResponses] = useState(
    (answer?.freeResponse && answer?.freeResponse.split(',').length * 0.5) || 0
  )
  const [studentResponses] = useState({})
  const [itemInfos, setItemInfos] = useState(undefined)
  const [indexs] = useState([])
  const [renderResponse, updateRenderResponse] = useState(false)
  const [numberOfLetters] = useState(
    item?.freeResponseMaxLength / item?.maxResponses - 2 || 0
  )

  const get_item_infos = useCallback((id: number) => {
    Axios.get(`${API_HOST}/v1/items/${id}/with_response`)
      .then((res) => {
        setItemInfos(res.data)
      })
      .catch((err) => console.error(err))
  }, [])
  const initialResponse = (list) => {
    const step = list.length / 2
    list.forEach((element: string, index: number) => {
      if (index < step) {
        studentResponses[list[index + step]] = parseFloat(list[index])
      }
    })
    return studentResponses
  }

  const { secondsToTimeout, application } = useContext(ApplicationContext)
  const configuration = useApplicationConfiguration(
    application?.exam.collection.applicationConfiguration
  )
  const updateResponse = debounce((responseText: string) => {
    updateFreeResponse(responseText)
  }, 300)

  // Rule is disabled here because in fact we want this function to run
  // only when the answer changes.
  useEffect(() => {
    updateResponseValue('')
    if (!itemInfos) {
      get_item_infos(item?.id)
    }
    if (answer?.freeResponse !== null) {
      initialResponse(answer?.freeResponse.split(','))
    }
    // eslint-disable-next-line
  }, [answer.position])

  const handleResponseChange = (value: string) => {
    updateResponseValue(value)
  }
  const shouldUpdateAnswer =
    configuration?.canUpdateAnswer ||
    answer?.finished !== true ||
    !alreadyAnswered

  const isDisabled =
    secondsToTimeout === 0 ||
    !shouldUpdateAnswer ||
    answer?.timeoutDate !== null ||
    finishingAnswer

  const handleClick = () => {
    if (responseValue) {
      if (!Object.keys(studentResponses).includes(responseValue)) {
        itemInfos.expectedFreeResponse.forEach(
          (element: string, index: number) => {
            if (element.includes(responseValue)) {
              if (!indexs.includes(index)) {
                studentResponses[element[0]] = 1
                updateResponseValue('')
                indexs.push(index)
                setCountResponses(countResponses + 1)
              }
            } else if (
              !Object.keys(studentResponses).includes(responseValue) &&
              !itemInfos.allResponses.includes(responseValue)
            ) {
              studentResponses[responseValue] = 0
              updateResponseValue('')
              setCountResponses(countResponses + 1)
            }
          }
        )
      } else {
        toast.error('Não é possível utilizar a mesma resposta duas vezes.')
      }

      updateResponse(
        `${Object.values(studentResponses).concat(
          Object.keys(studentResponses)
        )}`
      )
    }
  }
  return (
    <div className={className}>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <div className="counter-conteiner">
          {countResponses}/{itemInfos?.maxResponses}
        </div>
      </div>
      <div className="question-conteiner">
        {/* <div dangerouslySetInnerHTML={{ __html: item?.introduction }}></div> */}
        <Html>{item?.introduction}</Html>
        <Html>{item?.question}</Html>
      </div>
      {countResponses < itemInfos?.maxResponses ? (
        <>
          {Object.entries(studentResponses).map(([key, value]) => (
            <div
              key={key}
              style={{
                color: `${value && value === 1 ? 'green' : 'red'}`,
                marginBottom: '1rem',
                fontSize: '26px'
              }}
            >
              {key}
            </div>
          ))}
          <TextArea
            autoFocus={false}
            disabled={isDisabled}
            maxLength={numberOfLetters}
            value={responseValue}
            onChange={(event) => handleResponseChange(event.target.value)}
            rows={1}
            placeholder={`${t('Write your answer')}...`}
          />

          <button className="btn-enviar" onClick={() => handleClick()}>
            Enviar
          </button>
        </>
      ) : (
        <div
          style={{
            borderRadius: '8px',
            boxShadow: '0px 1px 5px rgba(58, 58, 58, 0.5)',
            width: '70%',
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {!renderResponse ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <h2>Suas Respostas</h2>
              {Object.entries(studentResponses).map(([key, value]) => (
                <div
                  key={key}
                  style={{
                    color: `${value && value === 1 ? 'green' : 'red'}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: '1rem',
                    fontSize: '26px'
                  }}
                >
                  {key}
                </div>
              ))}
              <button
                className="btn-enviar"
                onClick={() => updateRenderResponse(!renderResponse)}
              >
                Respostas aceitas
              </button>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <h2>Respostas aceitas</h2>
              {itemInfos?.correctResponse.map((e) => (
                <div
                  key={e}
                  style={{
                    color: 'green',
                    marginBottom: '1rem',
                    fontSize: '26px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  {e}
                </div>
              ))}
              <button
                className="btn-enviar"
                onClick={() => updateRenderResponse(!renderResponse)}
              >
                Suas respostas
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default styled(MultipleFreeResponse)`
  border: 1px solid black;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  box-shadow: 0px 1px 5px rgba(58, 58, 58, 0.5);
  ${TextArea} {
    margin-top: 20px;
    width: 50%;
  }
  .question-conteiner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    word-break: break-word;
    padding: 12px;
  }
  .counter-conteiner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 8px;
    box-shadow: 0px 1px 5px rgba(58, 58, 58, 0.5);
  }
  .btn-enviar {
    margin: 1rem;
    width: 30%;
    height: 30px;
    color: #fff;
    background: #6a35dd;
    border-radius: 8px;
    border: none;
    &:hover {
      background: #bebebe;
    }
  }
`
