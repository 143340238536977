import styled from 'styled-components'

const InstructionsCard = styled.div`
  text-align: left;
  padding: 2rem;
  background-color: white;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 0px 4px rgba(58, 58, 58, 0.25);
  margin: 0.5rem;

  h3 {
    color: #808080;
    font-size: 18px;
    margin-top: 0;
    font-weight: 600;
  }
`

export default InstructionsCard
