import Modal from 'components/Modal/Modal'
import React, { useCallback, useContext, useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import { toast } from 'react-toastify'
import axios from 'axios'
import { API_HOST } from 'consts'
import { AuthContext } from 'states/AuthState'

type OpenAppealUserResultModalProps = {
  refetchAppeals?: () => void
  className?: string
  data?: any
}

const OpenAppealUserResultModal = ({
  refetchAppeals,
  className,
  data
}: OpenAppealUserResultModalProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const { user } = useContext(AuthContext)

  const handleClose = () => {
    setIsOpen(false)
  }

  const text = `Deseja realizar a abertura do recurso, do resultado: ${data.selectionProcessName}, da etapa: ${data.sectionName}?`

  const contentText = `Recurso aberto para resultado: ${data.sectionName}`
  const bibliographyText = `Recurso aberto para resultado: ${data.sectionName}`

  const handleCreateAppeal = useCallback(async () => {
    const appealData = {
      user: user,
      userResult: data?.id,
      useResultAppeal: true,
      content: contentText,
      bibliography: bibliographyText
    }
    axios
      .post(`${API_HOST}/v1/appeals/create_appeal_user_result`, appealData)
      .then(() => {
        axios
          .put(`${API_HOST}/v1/user_result/${data.id}/set_opened_appeal`)
          .then(() => {
            toast.success('Recurso criado com sucesso!')
            handleClose()
            refetchAppeals()
          })
          .catch((err) => {
            console.error(err)
            toast.error('Ocorreu um erro durante a criação do recurso!')
          })
      })
      .catch((err) => {
        console.error(err)
        toast.error('Ocorreu um erro durante a criação do recurso!')
      })
  }, [user, contentText, bibliographyText, data, refetchAppeals])

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={className}>
        <div className="modal-body">
          <div className="modal-title">
            <span>{text}</span>
          </div>
          <div className="modal-buttons">
            <Button
              className="button"
              action="indef"
              type="button"
              onClick={() => handleClose()}
            >
              Cancelar
            </Button>
            <Button
              className="button"
              action="primary"
              type="button"
              onClick={() => handleCreateAppeal()}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default styled(OpenAppealUserResultModal)`
  display: flex;

  .modal-body {
    .modal-title {
      span {
        font-weight: bold;
      }
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 1.2rem;
    .button {
      margin-right: 10px;
    }
  }
`
