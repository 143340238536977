import React from 'react'
import { IExam } from '../../types'
import RoomInfoCard from './RoomInfoCard'

type RoomCardProps = {
  room: any
  exam?: IExam | undefined
}

const RoomCard = ({ room }: RoomCardProps) => {
  return <RoomInfoCard room={room} />
}

export default RoomCard
