import React from 'react'
import { CardMedia, Card, CardHeader } from '@material-ui/core'
import styled from 'styled-components'

type Props = {
  src: string
  title: string
  time?: string
  className?: string
}

const CardImage = ({ src, title, time, className }: Props) => {
  return (
    <Card className={className}>
      <CardHeader title={title} />
      <CardMedia
        image={src}
        title={title}
        style={{
          height: 0,
          paddingTop: '56.25%' // 16:9
        }}
      />
      {time && <h3>{time}</h3>}
    </Card>
  )
}

export default styled(CardImage)`
  margin-top: 15px;
  width: 49%;
  position: relative;

  > h3 {
    background: #fff;
    position: absolute;
    bottom: -18px;
    right: 0;
    padding: 1px;
  }

  @media print {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
`
