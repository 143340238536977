import { useFormikContext } from 'formik'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from './Button'
import LightBorderCard from './Card/LightBorderCard'
import HierarchyFilter from './HierarchyFilter'

type HierarchyFilterBoxProps = {
  providerCodename?: string
  title?: string
  children?: ReactNode
  className?: string
  distinct?: boolean
  isSchoolValid?: boolean
}

const HierarchyFilterBox = ({
  providerCodename,
  children,
  className,
  distinct = true,

  title = 'Filters'
}: HierarchyFilterBoxProps) => {
  const { t } = useTranslation()
  const formik = useFormikContext()

  return (
    <form className={className} onSubmit={formik.handleSubmit}>
      <LightBorderCard title={title}>
        <HierarchyFilter
          distinct={distinct}
          providerCodename={providerCodename}
        />
        {children}
        <Button
          style={{
            marginRight: '10px',
            borderRadius: '70px',
            boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.25)'
          }}
          action="primary"
          type="submit"
        >
          {t('Apply')}
        </Button>
      </LightBorderCard>
    </form>
  )
}

export default styled(HierarchyFilterBox)``
