import React, { createContext, useCallback, useReducer } from 'react'
import { IAppealState, AppealStateAction, IAppealFormData } from '../types'
import {
  createAppeal,
  updateAppealById,
  fetchAppealById,
  uploadAttachment
} from '../data/apis/appeals'
import { useTranslation } from 'react-i18next'
import useList from '../hooks/useList'
import { DASHBOARD_API_HOST } from 'consts'
import useQuery from 'hooks/useQuery'
import { FormikValues } from 'formik'

type AppealProps = {
  children: Function
  initialValues?: any
}

const initialAppealState: IAppealState = {
  appeals: [],
  fetchingAppeals: false,
  finishFetchingAppeals: false,
  fetchAppealsError: false,
  dispatch: () => {},
  fetchAppeal: () => undefined,
  issueAppeal: () => undefined,
  updateAppeal: () => undefined,
  uploadAttachedFile: () => undefined,
  removeAttachment: () => undefined,
  handleFilter: () => undefined,
  handlePageChange: () => undefined,
  handlePageSizeChange: () => undefined,
  onPageSizeChange: () => undefined,
  onFilter: () => undefined,
  creatingAppeal: false,
  createAppealSuccess: false,
  createAppealError: false,
  appealCreationError: '',
  updatingAppeal: false,
  updateAppealSuccess: false,
  updateAppealError: false,
  appealUpdatingError: '',
  fetchingAppeal: false,
  fetchAppealSuccess: false,
  fetchAppealError: false,
  appealFetchingError: '',
  currentAppeal: undefined,
  appealAttachments: [],
  uploadingAttachment: false,
  uploadAttachmentSuccess: false,
  uploadAttachmentError: false,
  attachmentUploadingError: '',
  totalAppeals: undefined,
  pageSize: undefined,
  numPages: undefined
}

const reducer = (
  state: IAppealState,
  action: AppealStateAction
): IAppealState => {
  switch (action.type) {
    case 'FETCH_APPEALS_START':
      return { ...state, fetchingAppeals: true, fetchAppealsError: false }
    case 'FETCH_APPEALS_SUCCESS':
      return {
        ...state,
        fetchingAppeals: false,
        fetchAppealsError: false,
        appeals: action.payload
      }
    case 'CREATE_APPEAL_START':
      return {
        ...state,
        creatingAppeal: true,
        createAppealSuccess: false,
        createAppealError: false
      }
    case 'CREATE_APPEAL_SUCCESS':
      return {
        ...state,
        createAppealSuccess: true,
        creatingAppeal: false
      }
    case 'CREATE_APPEAL_ERROR':
      return {
        ...state,
        createAppealError: true,
        appealCreationError: action.payload
      }
    case 'UPDATE_APPEAL_START':
      return {
        ...state,
        updatingAppeal: true,
        updateAppealError: false,
        updateAppealSuccess: false
      }
    case 'UPDATE_APPEAL_SUCCESS':
      return {
        ...state,
        updateAppealSuccess: true,
        updatingAppeal: false,
        updateAppealError: false,
        currentAppeal: action.payload
      }
    case 'UPDATE_APPEAL_ERROR':
      return {
        ...state,
        updatingAppeal: false,
        updateAppealSuccess: false,
        updateAppealError: true,
        appealUpdatingError: action.payload
      }
    case 'FETCH_APPEAL_START':
      return {
        ...state,
        fetchingAppeal: true,
        fetchAppealSuccess: false,
        fetchAppealError: false
      }
    case 'FETCH_APPEAL_SUCCESS':
      return {
        ...state,
        fetchingAppeal: false,
        fetchAppealSuccess: true,
        fetchAppealError: false,
        currentAppeal: action.payload,
        appealAttachments: action.payload.attachments
      }
    case 'FETCH_APPEAL_ERROR':
      return {
        ...state,
        fetchingAppeal: false,
        fetchAppealSuccess: false,
        fetchAppealError: true,
        appealFetchingError: action.payload
      }

    case 'FETCH_APPEAL_ATTACHMENTS':
      return {
        ...state,
        uploadingAttachment: false,
        uploadAttachmentError: false,
        uploadAttachmentSuccess: false,
        appealAttachments: action.payload
      }
    case 'UPLOAD_ATTACHMENT_START':
      return {
        ...state,
        uploadingAttachment: true,
        uploadAttachmentError: false,
        uploadAttachmentSuccess: false
      }
    case 'UPLOAD_ATTACHMENT_SUCCESS':
      return {
        ...state,
        uploadingAttachment: false,
        uploadAttachmentError: false,
        uploadAttachmentSuccess: true,
        appealAttachments: action.payload
      }
    case 'UPLOAD_ATTACHMENT_ERROR':
      return {
        ...state,
        uploadingAttachment: false,
        uploadAttachmentError: true,
        uploadAttachmentSuccess: false,
        attachmentUploadingError: action.payload
      }
    case 'REMOVE_ATTACHMENT':
      return {
        ...state,
        appealAttachments: action.payload
      }
    default:
      return state
  }
}

export const AppealContext = createContext<IAppealState>(initialAppealState)

const AppealState = ({ children, initialValues }: AppealProps) => {
  const [state, dispatch] = useReducer(reducer, initialAppealState)
  const { t } = useTranslation()
  const { appealAttachments } = React.useContext(AppealContext)
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  const formatListFilter = (params: FormikValues) => {
    return {
      user: params?.user?.id,
      id: params?.appeal,
      exam: params?.exam?.id,
      status: params?.status?.id,
      item_position: params?.position?.position
    }
  }

  const {
    isLoading: fetchingAppeals,
    results: appeals,
    count: totalAppeals,
    handleFilter,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    numPages
  } = useList({
    api: `${DASHBOARD_API_HOST}/v1/appeals?collection=${collectionId}`,
    defaultPageSize: 10,
    defaultOrdering: 'user_name',
    defaultFilterParams: formatListFilter(initialValues)
  })

  const saveFilterOnLocalStorage = (params) => {
    const parsedFilter = {}
    Object.entries(params).forEach(([key, value]) => {
      parsedFilter[key] = value === null ? undefined : value
    })
    localStorage.setItem('appeal-list-filter', JSON.stringify(parsedFilter))
  }

  const onFilter = (params: FormikValues) => {
    saveFilterOnLocalStorage(params)
    handleFilter(formatListFilter(params))
  }

  const updateAppeal = useCallback(
    async (appealId: number, appealData: IAppealFormData) => {
      dispatch({ type: 'UPDATE_APPEAL_START' })
      await updateAppealById(appealId, appealData)
        .then((res) =>
          dispatch({ type: 'UPDATE_APPEAL_SUCCESS', payload: res })
        )
        .catch((e) =>
          dispatch({ type: 'UPDATE_APPEAL_ERROR', payload: String(e.message) })
        )
    },
    []
  )

  const uploadAttachedFile = useCallback(
    async (formData, file, currentAttachments) => {
      dispatch({ type: 'UPLOAD_ATTACHMENT_START' })
      await uploadAttachment(formData)
        .then((data) => {
          data.url = URL.createObjectURL(file)
          data.filename = file.name
          let attachments = currentAttachments
          attachments = [...attachments, data]
          console.log('Argument', currentAttachments)
          console.log('State', appealAttachments)
          dispatch({ type: 'UPLOAD_ATTACHMENT_SUCCESS', payload: attachments })
        })
        .catch((e) =>
          dispatch({ type: 'UPLOAD_ATTACHMENT_ERROR', payload: e.message })
        )
    },
    [appealAttachments]
  )

  const issueAppeal = useCallback(async (appealData: IAppealFormData) => {
    dispatch({ type: 'CREATE_APPEAL_START' })
    await createAppeal(appealData)
      .then(() => dispatch({ type: 'CREATE_APPEAL_SUCCESS' }))
      .catch((e) => {
        const errorKey = Object.keys(e.response.data).shift()
        const errorMessage = e.response.data[errorKey].shift()

        dispatch({ type: 'CREATE_APPEAL_ERROR', payload: String(errorMessage) })
      })
  }, [])

  const fetchAppeal = useCallback(async (appealId: number) => {
    dispatch({ type: 'FETCH_APPEAL_START' })
    await fetchAppealById(appealId)
      .then((res) => {
        dispatch({ type: 'FETCH_APPEAL_SUCCESS', payload: res })
      })
      .catch((e) =>
        dispatch({ type: 'FETCH_APPEAL_ERROR', payload: e.message })
      )
  }, [])

  const removeAttachment = useCallback(
    async (attachmentIndex: number, attachments) => {
      if (
        window.confirm(t('Are you sure you want to delete this attachment?'))
      ) {
        const newAttachments = attachments.filter((x) => {
          return x !== attachments[attachmentIndex]
        })
        dispatch({ type: 'REMOVE_ATTACHMENT', payload: newAttachments })
      }
    },
    [t]
  )

  const contextValue = {
    ...state,
    issueAppeal,
    updateAppeal,
    fetchAppeal,
    uploadAttachedFile,
    removeAttachment,
    fetchingAppeals,
    appeals,
    totalAppeals,
    handleFilter,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    numPages,
    onFilter
  }

  return (
    <AppealContext.Provider value={contextValue}>
      {children(contextValue)}
    </AppealContext.Provider>
  )
}

export default AppealState
