import styled from 'styled-components'

export default styled.div`
  background-color: white;
  padding: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #f5f5f5;

  img:hover {
    cursor: zoom-in;
  }

  @media (max-width: 400px) {
    img {
      max-width: 216px;
    }
  }

  @media (min-width: 400px) and (max-width: 500px) {
    img {
      max-width: 288px;
    }
  }

  @media (min-width: 600px) {
    img {
      max-width: 550px;
    }
  }
`
