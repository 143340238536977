import React from 'react'
import styled from 'styled-components'

type Props = {
  letter: string
  className?: string
}

const Letter = ({ className, letter }: Props) => (
  <span className={className}>{letter.toUpperCase()}</span>
)

export default styled(Letter)`
  font-size: 16pt;
  margin-right: 1rem;
  margin-left: 8px;
  font-weight: 600;
`
