import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IApplication } from 'types'
import { API_HOST } from 'consts'
import { find, get } from 'lodash'
import ItemTableStyle from 'syles/ItemTableStyle'

interface SymptomTableReviewProps {
  className?: string
  application: IApplication
}

const SymptomTableReview = ({
  className,
  application
}: SymptomTableReviewProps) => {
  const { t } = useTranslation()
  const [symptomTable, setSymptomTable] = useState<any>()
  const [directorSymptom, setDirectorSymptom] = useState<any>()

  useEffect(() => {
    if (!application) {
      return
    }
    const params = {
      application: application.id
    }
    axios
      .get(`${API_HOST}/v1/symptom_table`, { params })
      .then((res) => setSymptomTable(res.data))

    axios
      .get(`${API_HOST}/v1/director_symptom`, { params })
      .then((res) => setDirectorSymptom(res.data[0]?.name))
  }, [application])

  console.log(symptomTable)
  console.log(directorSymptom)

  const getSymptomValue = useCallback(
    (i) => {
      return get(
        find(symptomTable, (st) => st.position === i + 1),
        'symptom'
      )
    },
    [symptomTable]
  )

  const getRubricValue = useCallback(
    (i) => {
      return get(
        find(symptomTable, (st) => st.position === i + 1),
        'rubric'
      )
    },
    [symptomTable]
  )

  const getPageColumnValue = useCallback(
    (i) => {
      return get(
        find(symptomTable, (st) => st.position === i + 1),
        'pageColumn'
      )
    },
    [symptomTable]
  )

  return (
    <div className={className}>
      <ItemTableStyle>
        <table>
          <tr>
            <th></th>
            <th>{t('Symptom')}</th>
            <th>{t('Rubrics')}</th>
            <th>{t('PAGE/COLUMN')}</th>
          </tr>
          {Array(10)
            .fill(0)
            .map((_, i) => (
              <tr key={i}>
                <td key={i + 1}>{i + 1}</td>
                <td key={`symptom${i + 1}`}>
                  <label>{getSymptomValue(i)}</label>
                </td>
                <td key={`rubric${i + 1}`}>
                  <label>{getRubricValue(i)}</label>
                </td>
                <td key={`pag/col${i + 1}`}>
                  <label>{getPageColumnValue(i)}</label>
                </td>
              </tr>
            ))}
        </table>
        <table className="director-table">
          <tr>
            <th>{t('Director symptom')}</th>
          </tr>
          <tr>
            <td>
              <label>{directorSymptom}</label>
            </td>
          </tr>
        </table>
      </ItemTableStyle>
    </div>
  )
}
export default styled(SymptomTableReview)`
  padding-top: 1rem;
  input {
    margin-left: 10%;
    width: 80%;
    text-align: center;
  }
  table {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    border: 1px solid;
  }
  td {
    align-items: flex-start;
    justify-content: space-between;
    overflow-y: scroll;
  }
  .toggle-button {
    display: flex;
    justify-content: flex-end;
  }
  .director-table {
    margin-top: 10px;
  }
`
