import React from 'react'
import { IItem } from '../../types'
import Alternative from './Alternative'
import BulkAlternative from './BulkAlternative'

type AlternativeGroupProps = {
  item: IItem
}

const AlternativeGroup = ({ item }: AlternativeGroupProps) => {
  return (
    <div>
      <div className="radio-group">
        {item.alternatives
          .sort((a, b) => a.position - b.position)
          .map((d) => {
            if (item?.category === 'MULTIPLE_ALTERNATIVES') {
              return (
                <BulkAlternative
                  {...d}
                  key={d.id}
                  itemId={item.id}
                  itemAlternatives={item.alternatives.length}
                  maxAllowedToMark={item.maxAllowedToMark}
                />
              )
            } else {
              return <Alternative {...d} key={d.id} itemId={item.id} />
            }
          })}
      </div>
    </div>
  )
}

export default AlternativeGroup
