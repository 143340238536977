import { Formik, FieldArray } from 'formik'
import axios from 'axios'
import { get, isEmpty } from 'lodash'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import CardList from '../components/CardList'
import CollectionInput from '../components/Inputs/CollectionInput'
import ExamInputSelect from '../components/Inputs/ExamInputSelect'
import UserInput from '../components/Inputs/UserInput'
import ApplicationStatusInput from '../components/Inputs/ApplicationStatusInput'
import useFeedback from 'hooks/useFeedback'
import Button from '../components/Button'
import HierarchyFilterBox from '../components/HierarchyFilterBox'
import ListPage from '../components/ListPage'
import PaginationFooter from '../components/PaginationFooter'
import RecipientCard from '../components/Email/RecipientCard'
import Stripe from '../components/Stripe'
import { AuthContext } from '../states/AuthState'
import EmailRecipientListState from '../states/EmailRecipientListState'
import CheckboxListState from '../states/CheckboxListState'
import { API_HOST } from '../consts'
import { FormValues, IHierarchy } from 'types'

type EmailRecipientListProps = {
  className?: string
}

const EmailRecipientList = ({ className }: EmailRecipientListProps) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const formInitialValues: FormValues = {
    collection: undefined,
    exam: undefined,
    application_status: undefined
  }
  const { handleErrorFeedback } = useFeedback()

  const mapHierarchy = (hierarchy: {
    [key: string]: IHierarchy | undefined
  }) => {
    // Maps hierarchy filter to how the api expects
    if (!hierarchy) {
      return undefined
    }

    const value = Object.entries(hierarchy)
      .filter((entry) => entry[1])
      .reduce(
        (acc, entry) => ({
          ...acc,
          [entry[0]]: { name: get(entry[1], 'name') }
        }),
        {}
      )

    // If no value selected, returns undefined
    // so it is not added in query string by axios
    return isEmpty(value) ? undefined : value
  }

  const mapFilters = (values: FormValues) => {
    // Maps filters from how they are saved in form to how the api expects
    const { collection, exam, application_status, user, ...hierarchy } = values
    return {
      hierarchy: mapHierarchy(hierarchy),
      collection: get(collection, 'id'),
      exam: get(exam, 'id'),
      application_status: get(application_status, 'id'),
      id: get(user, 'id')
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    const params = {
      ...values,
      checked: values.checked.join(','),
      checked_exception: values.allCheckedExceptions.join(',')
    }
    try {
      const response = await axios.get(`${API_HOST}/v1/users/email`, { params })
      history.push({
        pathname: '/emails/recipients/form',
        state: { users: response.data }
      })
      setLoading(false)
    } catch (error) {
      handleErrorFeedback(
        error,
        t('An error ocurred while trying to select the users')
      )
      setLoading(false)
    }
  }

  return (
    <EmailRecipientListState>
      {({
        results,
        numPages,
        handleFilter,
        handlePageChange,
        handlePageSizeChange,
        count,
        isLoading,
        pageSize
      }) => (
        <CheckboxListState>
          {({ renderCheckAllCheckbox, checked, allCheckedExceptions }) => (
            <div className={className}>
              <Stripe title={t('Select the recipients')} />
              <ListPage>
                <Formik
                  initialValues={formInitialValues}
                  onSubmit={(values) => handleFilter(mapFilters(values))}
                >
                  {(formik) => (
                    <>
                      <HierarchyFilterBox
                        providerCodename={user?.provider?.codename}
                      >
                        <CollectionInput />
                        <ExamInputSelect
                          name="exam"
                          collectionId={formik.values?.collection?.id}
                          onChange={(value) =>
                            formik.setFieldValue('exam', value)
                          }
                          label={t('Exam')}
                        />
                        <ApplicationStatusInput />
                        <UserInput basic params={mapFilters(formik.values)} />
                      </HierarchyFilterBox>
                      <div>
                        {renderCheckAllCheckbox(count)}
                        <CardList>
                          {isLoading ? (
                            <Skeleton height={110} count={pageSize} />
                          ) : (
                            <FieldArray
                              name="users"
                              render={() => (
                                <div>
                                  {results.map((user) => (
                                    <RecipientCard key={user.id} user={user} />
                                  ))}
                                </div>
                              )}
                            />
                          )}
                        </CardList>
                        <PaginationFooter
                          pageSize={pageSize}
                          pageCount={numPages}
                          onPageChange={handlePageChange}
                          onPageSizeChange={handlePageSizeChange}
                        >
                          <p>
                            Mostrando {results.length} de {count} registros.
                          </p>
                        </PaginationFooter>
                        <div className="button-div">
                          <Button
                            onClick={() =>
                              handleSubmit({
                                checked,
                                allCheckedExceptions,
                                ...mapFilters(formik.values)
                              })
                            }
                            disabled={loading}
                            action="primary"
                          >
                            {loading ? t('Loading...') : 'Enviar emails'}
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </Formik>
              </ListPage>
            </div>
          )}
        </CheckboxListState>
      )}
    </EmailRecipientListState>
  )
}

export default styled(EmailRecipientList)`
  .button-div {
    float: right;
  }
`
