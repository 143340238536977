import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { getEvent, getMotive } from 'data/utils/message-exchange'

export const Timestamp = styled.div`
  font-size: 14px;
  font-weight: 600;

  > span {
    margin-right: 1rem;
  }

  > span:first-child {
    font-weight: 800;
    color: grey;
  }

  margin-bottom: 5px;
`

export const Explanation = styled.div`
  margin-bottom: 0.5rem;
`

export const Extra = styled.div`
  margin-bottom: 0.5rem;
  span {
    display: flex;
  }
  h5 {
    margin: 0;
    padding-right: 0.5em;
  }
  p {
    margin: 0;
    font-size: 13px;
  }
`

export const Author = styled.div`
  margin-bottom: 1rem;
  font-size: 13px;
`

const getAuthor = (author: string) => {
  return {
    CANDIDATE: 'candidato',
    PROCTOR: 'aplicador'
  }[author]
}

type CandidateHistoryItemProps = {
  className?: string
  timestamp?: Date
  event?: string
  motive?: string
  author?: 'CANDIDATE' | 'PROCTOR'
  extra?: string
  reason?: string
}

const CandidateHistoryItem = ({
  className,
  timestamp,
  event,
  motive,
  author,
  extra,
  reason
}: CandidateHistoryItemProps) => {
  const { t } = useTranslation()

  const getFormattedTimestamp = () => {
    if (!timestamp) {
      return null
    }

    return timestamp.toLocaleString(['pt-BR'])
  }

  const getFormattedAuthor = () => {
    if (!author) {
      return null
    }

    return t('Created by {{author}}', { author: getAuthor(author) })
  }

  const getExtra = () => {
    if (!extra) {
      return
    }

    return (
      <span>
        <h5>Extra: </h5>
        <p>{extra}</p>
      </span>
    )
  }

  const getReason = () => {
    if (!reason) {
      return
    }

    return (
      <span>
        <h5>Justificativa: </h5>
        <p>{reason}</p>
      </span>
    )
  }

  return (
    <div className={className}>
      <Timestamp>
        <span>
          <FontAwesomeIcon icon="clock" /> {getFormattedTimestamp()}
        </span>
        <span>{getEvent(event)}</span>
      </Timestamp>
      <Explanation>
        {getMotive(motive) || <i>Motivo desconhecido</i>}
      </Explanation>
      <Extra>{getExtra()}</Extra>
      <Extra>{getReason()}</Extra>
      <Author>{getFormattedAuthor()}</Author>
    </div>
  )
}

export default CandidateHistoryItem
