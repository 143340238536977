import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  separator?: string
}

const BreadcrumbSeparator = ({ separator = '/', className }: Props) => (
  <span className={className}>{separator}</span>
)

export default styled(BreadcrumbSeparator)`
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 600;
  color: #606060;
`
