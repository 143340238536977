import AnswerReviewList from 'components/AnswerReviewList'
import Page from 'components/Page'
import Space from 'components/Space'
import { fetchAnswers, fetchApplication } from 'data/apis/applications'
import { fetchAnswerSheet } from 'data/apis/exams'
import { deleteAppeal, fetchAppeals } from 'data/apis/appeals'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { find } from 'lodash'
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch
} from 'react-router-dom'
import styled from 'styled-components'
import AnswerSheetBreadcrumb from './components/AnswerSheetBreadcrumb'
import AnswerSheetItem from './components/AnswerSheetItem'
import Summary from './components/Summary'
import { AnswerSheetOverview } from 'data/domain/exams'
import useAppealProcess from 'hooks/appeal/useAppealProcess'
import { toast } from 'react-toastify'

type Props = {
  className?: string
}

const ApplicationAnswerSheet = ({ className }: Props) => {
  const { applicationId } = useParams<{ applicationId: string }>()
  const { url } = useRouteMatch()

  const { data: application } = useQuery(
    ['application', applicationId],
    (_, _applicationId: number) => fetchApplication(_applicationId)
  )

  const {
    postItemNavigation,
    postAnswerVisualization,
    postAlternativeExplanationVisualization,
    showItemId,
    appealIssuingWindow
  } = application?.exam.collection || {}

  const examId = application?.exam.id
  const { data: answerSheet } = useQuery(
    ['answer-sheet', examId],
    (_, _examId: number) => fetchAnswerSheet(_examId)
  )

  const { data: answers } = useQuery(
    ['answers', applicationId],
    (_, _applicationId: number) => fetchAnswers(_applicationId)
  )
  const { data: appeals, refetch: refetchAppeals } = useQuery(
    ['appeals', applicationId],
    (_, _applicationId: number) => fetchAppeals(_applicationId)
  )
  const [answerSheetOverview, setAnswerSheetOverview] = useState<
    AnswerSheetOverview
  >(undefined)

  const onDeleteAppeal = async (appealId: number) => {
    await deleteAppeal(appealId)
    refetchAppeals()
    toast.success('Recurso removido com sucesso.')
  }

  const { appealProcess } = useAppealProcess(+applicationId)

  useEffect(() => {
    if (
      answerSheet &&
      answers &&
      application?.exam?.collection?.showAnswerSheetOverview
    ) {
      const totalCancelled = answerSheet.reduce(
        (acc, ansSheet) => acc + +ansSheet.isCancelled,
        0
      )
      const totalAvailable = answerSheet.reduce(
        (acc, ansSheet) => acc + +ansSheet.isAvailable,
        0
      )

      const totalCorrectAnswers = answers.reduce((acc, answer) => {
        const currentAnswerSheet = find(
          answerSheet,
          (ansSheet) => ansSheet.itemId === answer.item.id
        )
        if (!currentAnswerSheet.isAvailable) return acc
        if (currentAnswerSheet.isCancelled) return acc + 1
        return acc + +(answer.alternative?.letter === currentAnswerSheet.answer)
      }, 0)

      setAnswerSheetOverview({
        totalCancelled,
        totalAvailable,
        totalCorrectAnswers
      })
    }
  }, [answerSheet, answers, application])

  const getItemUrl = (itemId) =>
    postItemNavigation ? `${url}/items/${itemId}` : ''

  const getAnswer = (itemId: number) => {
    const answer = answers?.find((d) => d.item.id === +itemId)
    const item = answerSheet?.find((d) => d.itemId === itemId)
    // TODO: Create an Answer class and add this methods
    return {
      position: answer?.position,
      verboseName: answer?.verboseName,
      givenAnswer: answer?.alternative?.letter || answer?.freeResponse,
      givenBulkAnswer: answer?.alternatives,
      correctAnswer: item?.answer,
      correctBulkAnswer: item?.answers,
      isCancelled: item?.isCancelled,
      isTimeout: !!answer?.timeoutDate,
      attachment: answer?.attachment
    }
  }

  return (
    <Page className={className} title="Conferir respostas">
      {application && (
        <AnswerSheetBreadcrumb
          collectionName={application.exam.collection.name}
          collectionId={application.exam.collection.id}
          examName={application.exam.name}
          applicationId={application.id}
        />
      )}
      <Space value="20px" />
      <Switch>
        {postAnswerVisualization && (
          <Route
            path={`${url}/summary`}
            component={() => (
              <Summary
                appealProcess={appealProcess}
                answers={answers}
                answerSheet={answerSheet}
                getItemUrl={getItemUrl}
                postItemNavigation={postItemNavigation}
                showItemId={showItemId}
                answerSheetOverview={answerSheetOverview}
                appeals={appeals?.results}
                appealIssuingWindow={appealIssuingWindow}
                onDeleteAppeal={onDeleteAppeal}
                collection={application?.exam.collection}
              />
            )}
          />
        )}
        {postItemNavigation && (
          <Route
            path={`${url}/items/:itemId`}
            component={() => (
              <AnswerSheetItem
                getAnswer={getAnswer}
                showItemId={showItemId}
                postAlternativeExplanationVisualization={
                  postAlternativeExplanationVisualization
                }
              />
            )}
          />
        )}
        <Redirect to={`${url}/summary`} />
      </Switch>
    </Page>
  )
}

export default styled(ApplicationAnswerSheet)`
  padding: 20px;

  ${AnswerReviewList} {
    margin-bottom: 30px;
    margin-top: 30px;

    > span {
      max-width: 60vw;
    }
  }
`
