import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { AuthContext } from '../states/AuthState'
import { ApplicationContext } from '../states/ApplicationState'
import useLogoutConfirmation from './useLogoutConfirmation'
import HeaderDrawer from './HeaderDrawer'

type LateralMenuProps = {
  className?: string
  children?: any
}

const LateralMenu = ({ className, children }: LateralMenuProps) => {
  const theme = useContext(ThemeContext)
  const { user, hasGroup } = useContext(AuthContext)
  const { application } = useContext(ApplicationContext)
  const askLogoutConfirmation = useLogoutConfirmation()
  const token = localStorage.getItem('token')

  /* After 18/04/2021 uncomment code below */

  // const screenZoom = useRef(1)

  // const zoomChangeStep = useMemo(() => 0.1, [])

  // const increaseScreenZoom = () => {
  //   if (screenZoom.current === 2) return
  //   screenZoom.current += zoomChangeStep
  //   console.log(document.body.style.transform)
  //   document.body.style.transform = `scale(${screenZoom.current})`
  //   document.body.style.transformOrigin = '0 0'
  // }

  // const decreaseScreenZoom = () => {
  //   if (screenZoom.current === 1) return
  //   screenZoom.current -= zoomChangeStep
  //   document.body.style.transform = `scale(${screenZoom.current})`
  //   document.body.style.transformOrigin = '0 0'
  // }

  return (
    <div className={className}>
      <HeaderDrawer
        theme={theme}
        token={token}
        user={user}
        application={application}
        askLogoutConfirmation={askLogoutConfirmation}
        hasGroup={hasGroup}
        children={children}
      />
    </div>
  )
}

export default styled(LateralMenu)`
  background: white;
  width: 100%;

  nav {
    max-width: 1366px;
    margin: auto;
  }

  .header {
    display: flex;
    max-width: 1400px;
    width: 100%;
    height: 64px;
    display: flex;
    padding: 10px 80px;
    justify-content: space-between;
  }

  @media only screen and (max-width: 425px) {
    .header {
      padding: 10px !important;
    }
  }

  .flex {
    display: flex;
    align-items: center;

    .name-application {
      /* margin-right: 0.9rem; */
    }
    .zoom {
      display: flex;
    }

    button {
      flex-shrink: 0;
      margin-left: 10px;
    }
  }

  ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 110%;
    list-style: none;
    align-items: center;
    text-align: right;
    padding: 10px 30px 0px 0px;

    li {
      align-items: center;
      padding: 2px;
      margin-top: 1rem;

      p {
        margin: 0;
        line-height: 1.5;
      }
    }

    @media print {
      p,
      .flex {
        display: none !important;
      }
    }

    @media (max-width: 600px) {
      p,
      .zoom {
        display: none !important;
      }

      .flex {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`
