import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BadgeMessage from 'components/BadgeMessage'
import Modal from 'components/Modal/Modal'
import ConfigContext from 'contexts/ConfigContext'
import React, { useContext, useState } from 'react'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import AnswerCountdown from '../components/ApplicationToolbarComponents/AnswerCountdown'
import ApplicationToolbar from '../components/ApplicationToolbarComponents/ApplicationToolbar'
import ChangeAnswerButtons from '../components/ChangeAnswerButtons'
import Item from '../components/Item/Item'
import ItemNavigation from '../components/ItemNavigation/ItemNavigation'
import Tag from '../components/Tag'
import AnswerState from '../states/AnswerState'
import PersonState from '../states/PersonState'
import { ApplicationContext } from '../states/ApplicationState'
import { IAnswer, IAnswerState, IItem } from '../types'
import Notepad from '../components/Notepad'
import { Box } from '@material-ui/core'
import Calculator from 'components/Calculator'
import ScientificCalculator from 'components/ScientificCalculator'
import TooltipButton from 'components/TooltipButton'
import CandidateMessageExchangeContext from 'contexts/CandidateMessageExchangeContext'
import usePreference from 'hooks/usePreference'
import { IBooleanPreference } from 'types'
import AnswerRemainingTime from 'components/AnswerRemainingTime'
import DrawingPanel from 'components/DrawingPanel'
import { dismissMessage } from 'data/apis/message-exchange'
import SymbolTable from 'components/SymbolTable'

type AnswerProps = {
  className?: string
}

const Answer = ({ className }: AnswerProps) => {
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false)
  const [isScientificCalculatorOpen, setIsScientificCalculatorOpen] = useState(
    false
  )
  const [isDrawingPanelOpen, setIsDrawingPanelOpen] = useState(false)
  const [isSymbolTableOpen, setIsSymbolTableOpen] = useState(false)
  const [isNotepadOpen, setIsNotepadOpen] = useState(false)
  const {
    secondsToTimeout,
    answers,
    remainingTimeInitialDate,
    application
  } = useContext(ApplicationContext)
  const configuration = useApplicationConfiguration(
    application?.exam?.collection?.applicationConfiguration
  )
  const { allowHideRemainingTimeAnswerPage } = useContext(ConfigContext)
  const { t } = useTranslation()
  const { candidate } = useContext(CandidateMessageExchangeContext)
  const isPaused = Boolean(candidate?.pause)
  const showReviewButton = usePreference<IBooleanPreference>(
    'Application__ShowReviewButton'
  )
  const [isTextMarkerEnabled, setIsTextMarkerEnabled] = useState(false)

  const shouldShowAnswerCountdown = (item: IItem, answer: IAnswer): boolean => {
    return (
      item?.id === answer?.item?.id &&
      Boolean(answer?.answerTimeLimit) &&
      !isPaused
    )
  }

  return (
    <PersonState>
      <AnswerState>
        {({
          answer,
          item,
          handleQuestionExpired,
          badgeMessages,
          itemAccessExpired,
          goToNextNotExpiredItem,
          remainingItemTimeInitialDate,
          timeBadgeMessage
        }: IAnswerState) => {
          const canPassToNextAnswer = () => {
            if (timeBadgeMessage.length === 0) {
              return true
            } else {
              return false
            }
          }

          const handleButtonSubmit = (id: string) => {
            return timeBadgeMessage?.map(async (message) => {
              if (message.id === id) {
                dismissMessage(application.roomId.toString(), message.id)
              }
            })
          }

          return (
            <div className={className}>
              <ItemNavigation />
              <div className="card answer-card">
                <div className="main-content">
                  <ApplicationToolbar
                    collection={application?.exam?.collection}
                    showReviewButton={showReviewButton?.value}
                  />
                  {badgeMessages?.length > 0 &&
                    badgeMessages.map((message) => (
                      <>
                        {message.motive === 'MORE_TIME' ? (
                          <BadgeMessage
                            key={message.id}
                            warningType="info"
                            message={message}
                            button={true}
                            handleButtonSubmit={handleButtonSubmit}
                          />
                        ) : (
                          <BadgeMessage
                            key={message.id}
                            warningType="warning"
                            message={message}
                          />
                        )}
                      </>
                    ))}
                  {shouldShowAnswerCountdown(item, answer) && (
                    <>
                      <AnswerCountdown
                        key={remainingItemTimeInitialDate}
                        initial={remainingItemTimeInitialDate}
                        secondsToTimeout={
                          +answer.answerTimeLimit - +answer.seconds
                        }
                        onComplete={handleQuestionExpired}
                      />
                    </>
                  )}
                  <Modal
                    isOpen={itemAccessExpired}
                    title={t('Response time timed out for this item')}
                    onAction={() => goToNextNotExpiredItem()}
                    actionText={'OK'}
                  >
                    {t(
                      'The time to answer this item has been reached. You will be directed to the next question.'
                    )}
                  </Modal>
                  <div className="card-header">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box marginRight="1rem">
                        {configuration?.canUseCalculator && (
                          <TooltipButton
                            onClick={() => setIsCalculatorOpen(true)}
                            tooltipText="Calculadora"
                            action="icon"
                          >
                            <FontAwesomeIcon icon={['fas', 'calculator']} />{' '}
                          </TooltipButton>
                        )}
                      </Box>
                      <Box marginRight="1rem">
                        {configuration?.canUseScientificCalculator && (
                          <TooltipButton
                            onClick={() => setIsScientificCalculatorOpen(true)}
                            tooltipText="Calculadora Cientifica"
                            action="icon"
                          >
                            <FontAwesomeIcon icon={['fas', 'calculator']} />{' '}
                          </TooltipButton>
                        )}
                      </Box>
                      <Box marginRight="1rem">
                        {configuration?.canUseTextMarker && (
                          <TooltipButton
                            onClick={() =>
                              setIsTextMarkerEnabled(!isTextMarkerEnabled)
                            }
                            tooltipText="Marcador de Texto"
                            action="icon"
                          >
                            <FontAwesomeIcon icon={['far', 'marker']} />{' '}
                          </TooltipButton>
                        )}
                      </Box>
                      <Box marginRight="1rem">
                        {configuration?.canUseDrawingPanel && (
                          <TooltipButton
                            onClick={() => setIsDrawingPanelOpen(true)}
                            tooltipText="Bloco de desenhos"
                            action="icon"
                          >
                            <FontAwesomeIcon icon={['fas', 'pencil-alt']} />{' '}
                          </TooltipButton>
                        )}
                      </Box>
                      <Box marginRight="1rem">
                        {configuration?.canUseSymbolTable && (
                          <TooltipButton
                            onClick={() => setIsSymbolTableOpen(true)}
                            tooltipText="Tabela de Siglas"
                            action="icon"
                          >
                            <FontAwesomeIcon icon={['fas', 'table']} />{' '}
                          </TooltipButton>
                        )}
                      </Box>
                      <div>
                        {configuration?.canUseNotepad && (
                          <TooltipButton
                            tooltipText="Bloco de notas"
                            action="icon"
                            onClick={() => setIsNotepadOpen(true)}
                          >
                            <FontAwesomeIcon icon={['fas', 'clipboard']} />
                          </TooltipButton>
                        )}
                      </div>
                      <Calculator
                        isCalculatorOpen={isCalculatorOpen}
                        setIsCalculatorOpen={setIsCalculatorOpen}
                      />
                      <ScientificCalculator
                        isScientificCalculatorOpen={isScientificCalculatorOpen}
                        setIsScientificCalculatorOpen={
                          setIsScientificCalculatorOpen
                        }
                      />
                      <Notepad
                        isNotepadOpen={isNotepadOpen}
                        setIsNotepadOpen={setIsNotepadOpen}
                      />
                      <DrawingPanel
                        isDrawingPanelOpen={isDrawingPanelOpen}
                        setIsDrawingPanelOpen={setIsDrawingPanelOpen}
                      />
                      <SymbolTable
                        isSymbolTableOpen={isSymbolTableOpen}
                        setIsSymbolTableOpen={setIsSymbolTableOpen}
                        collection={application?.exam?.collection?.id}
                      />
                    </Box>
                    <AnswerRemainingTime
                      secondsToTimeout={secondsToTimeout}
                      allowHideRemainingTime={allowHideRemainingTimeAnswerPage}
                      remainingTimeInitialDate={remainingTimeInitialDate}
                      showAnswerCountdown={shouldShowAnswerCountdown(
                        item,
                        answer
                      )}
                      secondsToAnswerTimeout={
                        +answer?.answerTimeLimit - +answer?.seconds
                      }
                      answerInitialTime={remainingItemTimeInitialDate}
                    />
                  </div>
                  <Item
                    isTextMarkerEnabled={isTextMarkerEnabled}
                    answers={answers}
                    answer={answer}
                    items={item}
                  />
                  <ChangeAnswerButtons
                    canPassToNextAnswer={canPassToNextAnswer()}
                  />
                </div>
              </div>
            </div>
          )
        }}
      </AnswerState>
    </PersonState>
  )
}

export default styled(Answer)`
  .main-content {
    margin: auto;
    max-width: 1000px;
    padding-top: 1rem;
    padding-bottom: 3rem;
  }

  .answer-card {
    min-height: 100vh;
  }

  .card {
    background-color: ${(props) => props.theme.colors.grayLight};
    font-size: 11pt;

    ${ChangeAnswerButtons}:last-of-type {
      margin-top: 20px;
    }

    &-header {
      color: ${(props) => props.theme.clientColors.text};
      font-size: 12pt;
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      p {
        .answer-label {
          font-size: 15pt;
        }
        ${Tag} {
          margin-left: 10px;
        }
      }

      .subtitle {
        font-style: italic;
        font-weight: 400;
        margin-left: 10px;
      }

      @media (max-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        justify-content: center;

        p {
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .card {
      padding-left: 15%;
      padding-right: 15%;
    }
  }

  @media (max-width: 800px) {
    .card {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  @media (max-width: 768px) {
    .card {
      flex: 0 1 calc(50% - 1em) !important;
    }
  }

  @media (max-width: 600px) {
    .answer-card {
      min-height: 130vh;
    }
  }

  @media (max-width: 480px) {
    .card {
      flex: 0 1 calc(100% - 1em);
    }
  }
`
