import useQuery from 'hooks/useQuery'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../components/Button'
import ButtonGrid from '../components/ButtonGrid'
import LogoPage from '../components/LogoPage'
import { AuthContext } from '../states/AuthState'

type NewSessionProps = {
  className?: string
  onConfirm: Function
}

const NewSession = ({ className, onConfirm }: NewSessionProps) => {
  const { newSessionMessage, isSubmitting, dispatch } = useContext(AuthContext)
  const query = useQuery()
  const kioskToken = query.get('kiosk_token')
  const history = useHistory()
  const { t } = useTranslation()

  const onCancel = () => {
    dispatch({ type: 'HIDE_NEW_SESSION_PAGE' })
    history.push(`/login?kiosk_token=${kioskToken}`)
  }

  return (
    <LogoPage className={className}>
      <p>{newSessionMessage}</p>
      <p>{t('Do you wish to start a new session?')}</p>
      <p>{t('You will be disconnected from your other session.')}</p>
      <ButtonGrid>
        <Button
          action="primary"
          isLoading={isSubmitting}
          onClick={() => onConfirm()}
        >
          {t('continue')}
        </Button>
        <Button action="secondary" onClick={() => onCancel()}>
          {t('cancel')}
        </Button>
      </ButtonGrid>
    </LogoPage>
  )
}

export default styled(NewSession)`
  ${ButtonGrid} {
    margin-top: 30px;
  }
`
