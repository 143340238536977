import React, { createContext, useReducer, ChangeEvent } from 'react'
import { API_HOST } from '../consts'
import {
  childrenIsFunction,
  IAnswerSheetOrderState,
  DashboardPreFilterAction
} from '../types'
import useList from 'hooks/useList'
import useQuery from 'hooks/useQuery'
import { FormikValues } from 'formik'

const reducer = (
  state: IAnswerSheetOrderState,
  action: DashboardPreFilterAction
): IAnswerSheetOrderState => {
  switch (action.type) {
    case 'FETCH':
      return { ...state, isLoading: true }
    case 'FETCH_SUCCESS':
      return { ...state, isLoading: false }
    case 'FETCH_ERROR':
      return { ...state, isLoading: false }
    default:
      return state
  }
}

const initialState = {
  isLoading: true,
  totalResults: undefined,
  results: [],
  pageSize: undefined,
  numPages: undefined,
  onFilter: () => undefined,
  handleFilter: () => undefined,
  onSearchChange: () => undefined,
  handlePageChange: () => undefined,
  onPageSizeChange: () => undefined
}

export const AnswerSheetOrderContext = createContext<IAnswerSheetOrderState>(
  initialState
)

type DashboardPreFilterProps = {
  children: any
  initialValues: any
}

const AnswerSheetOrderState = ({
  children,
  initialValues
}: DashboardPreFilterProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  const formatListFilter = (params: FormikValues) => {
    return {
      user: params?.user?.id,
      exam: params?.exam?.id,
      status: params?.status?.id
    }
  }

  const {
    isLoading,
    results,
    count,
    pageSize,
    numPages,
    handleSearchChange,
    handleFilter,
    handlePageChange,
    handlePageSizeChange
  } = useList({
    api: `${API_HOST}/v1/answer-sheet-order?collection=${collectionId}`,
    defaultPageSize: 10,
    defaultOrdering: 'user_name',
    defaultFilterParams: formatListFilter(initialValues)
  })

  const saveFilterOnLocalStorage = (params) => {
    const parsedFilter = {}
    Object.entries(params).forEach(([key, value]) => {
      parsedFilter[key] = value === null ? undefined : value
    })
    localStorage.setItem('order-list-filter', JSON.stringify(parsedFilter))
  }
  const onFilter = (params: FormikValues) => {
    saveFilterOnLocalStorage(params)
    handleFilter(formatListFilter(params))
  }

  const onPageSizeChange = (event: any) => {
    handlePageSizeChange(event.target.value)
  }

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'ON_SEARCH_CHANGE', payload: event.target.value })
    handleSearchChange(event.target.value)
  }

  const contextValue: IAnswerSheetOrderState = {
    ...state,
    isLoading,
    results,
    totalResults: count,
    pageSize,
    numPages,
    onSearchChange,
    onPageSizeChange,
    handleFilter,
    handlePageChange,
    onFilter
  }

  return (
    <AnswerSheetOrderContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </AnswerSheetOrderContext.Provider>
  )
}

export default AnswerSheetOrderState
