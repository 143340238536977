import Button from '../Button'
import RoomInput from 'components/Inputs/RoomInput'
import ModalFooter from 'components/Modal/ModalFooter'
import { Formik } from 'formik'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalContext } from '../../states/ModalState'
import { FormValues } from '../../types'
import Modal from '../Modal/ModalRootModal'

interface SetRoomModalProps {
  className?: string
  onSubmit: (values: any) => Promise<any>
}

const SetRoomModal = ({ onSubmit, className }: SetRoomModalProps) => {
  const { t } = useTranslation()
  const { hideModal } = useContext(ModalContext)

  const validate = (values: FormValues) => {
    const errors: { [key: string]: string } = {}
    if (!values.room) {
      errors.room = t('This field is required.')
    }
    return errors
  }

  return (
    <Modal className={className} title={t('Set room')} onClose={hideModal}>
      <Formik onSubmit={onSubmit} initialValues={{}} validate={validate}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <RoomInput />
            <ModalFooter>
              <Button
                type="submit"
                action="primary"
                disabled={formik.isSubmitting}
              >
                {t('Confirm')}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default styled(SetRoomModal)`
  form {
    height: 20vh;
  }
`
