import AnswerSheetItemContent from 'pages/AnswerSheet/components/AnswerSheetItemContent'
import React from 'react'
import CancelledItemIndicator from './CancelledItemIndicator'
import ItemTitle from './ItemTitle'
import { AnswerSheet, ICorrectAnswer } from 'data/domain/exams'
import styled from 'styled-components'
import ItemVoidIndicator from './ItemVoidIndicator'

const ItemPageBreak = styled.div`
  @media print {
    page-break-after: always;
  }
`

type Props = {
  postAlternativeExplanationVisualization: boolean
  application: any
  getAnswer: (
    itemId: number
  ) => {
    itemId: number
    position: number
    givenAnswer: string
    answerGradeLinear?: any
    correctAnswer: string
    correctBulkAnswer: ICorrectAnswer[]
    isCancelled: boolean
    responseTime: number
    maximumTime: number
    isTimeout: boolean
    value: string
    itemValue: string
  }
  answerSheet: AnswerSheet
  className?: string
}

const AnswerSheetBulkItems = ({
  postAlternativeExplanationVisualization,
  getAnswer,
  application,
  answerSheet,
  className
}: Props) => {
  let allAnswers = {}

  const getAllAnswers = (items) => {
    const answers = {}
    if (!items.length) return
    items.forEach((item) => {
      answers[item.id] = { ...getAnswer(item.id) }
    })
    allAnswers = answers
  }

  if (answerSheet) {
    const items = answerSheet.map((item) => {
      if (item.item) {
        return item.item
      }
      return null
    })
    getAllAnswers(items)
    items.sort((a, b) => allAnswers[a.id].position - allAnswers[b.id].position)
    return (
      <>
        {items.map((item) => {
          return (
            <section key={item.id}>
              <ItemPageBreak className={className}>
                <div className="title-wrapper">
                  <div className="item-wrapper">
                    <ItemTitle>
                      Item{' '}
                      {allAnswers[item.id].verboseName
                        ? allAnswers[item.id].verboseName
                        : allAnswers[item.id].position}
                    </ItemTitle>
                    <ItemTitle>
                      Código do objeto de aprendizagem{' '}
                      {allAnswers[item.id].itemId}
                    </ItemTitle>
                    {allAnswers[item.id].value ? (
                      <>
                        <ItemTitle>
                          Valor da questão: {allAnswers[item.id].itemValue}
                        </ItemTitle>
                        <ItemTitle>
                          Nota obtida:{' '}
                          {Number(allAnswers[item.id].value).toFixed(2)}
                        </ItemTitle>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <ItemTitle>
                    Tempo gasto: {allAnswers[item.id].responseTime}s
                  </ItemTitle>
                </div>
                <div className="errors-wrapper">
                  {!allAnswers[item.id].givenAnswer &&
                    allAnswers[item.id].givenBulkAnswer?.legnth > 0 && (
                      <ItemVoidIndicator />
                    )}
                  {allAnswers[item.id].isCancelled && (
                    <CancelledItemIndicator />
                  )}
                </div>

                <AnswerSheetItemContent
                  postAlternativeExplanationVisualization={
                    postAlternativeExplanationVisualization
                  }
                  item={item}
                  application={application}
                  // fetchItemError={fetchItemError}
                  // fetchingItem={fetchingItem}
                  correctAnswer={allAnswers[item.id].correctAnswer}
                  answerGradeLinear={allAnswers[item.id].answerGradeLinear}
                  correctBulkAnswer={allAnswers[item.id].correctBulkAnswer}
                  bulkAnswer={allAnswers[item.id].givenBulkAnswer}
                  givenAnswer={allAnswers[item.id].givenAnswer}
                  isCancelled={allAnswers[item.id].isCancelled}
                />
              </ItemPageBreak>
            </section>
          )
        })}
      </>
    )
  }
  return null
}

export default styled(AnswerSheetBulkItems)`
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  margin-top: 10px;

  .item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 650px;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .errors-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 65px;
    margin-bottom: 10px;
  }
`
