/* eslint no-unused-vars: 0 */
import React, { useState, useContext } from 'react'
import ExamItemInputSelect from 'components/Inputs/ItemInputSelect'
import axios from 'axios'
import styled from 'styled-components'
import { API_HOST } from 'consts'
import { Formik } from 'formik'
import ColorfulProgressBar from 'components/ColorfulProgressBar'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { fetchAnswerSheet } from 'data/apis/exams'
import QuestionsModal from 'components/ExamDashboardComponents/QuestionsModal'
import { useQuery } from 'react-query'
import { AnswerSheet } from 'data/domain/exams'
import { AuthContext } from 'states/AuthState'

type ItemStats = {
  correct: number
  wrong: number
}

type ItemStatsDisplayProps = {
  className?: string
  selectedExamId: number
  answerSheet?: AnswerSheet
}

const ItemStatsDisplay = ({
  className,
  selectedExamId
}: ItemStatsDisplayProps) => {
  const { t } = useTranslation()
  const [statistics, setStatistics] = useState(null)
  const [isQuestionRecordModalOpen, setIsQuestionRecordModalOpen] = useState(
    false
  )
  const [item, setItem] = useState(undefined)
  const { perms } = useContext(AuthContext)

  const { data: answerSheet } = useQuery(
    ['answer-sheet', selectedExamId],
    (_, _examId: number) => fetchAnswerSheet(_examId),
    {
      refetchOnMount: false
    }
  )

  const handleItemChange = async (item) => {
    if (!item || !item.id) {
      setStatistics(null)
      setItem(null)
      return
    }
    setItem(item.id)
    try {
      const id = item.id
      const response = await axios.get(`${API_HOST}/v1/item/${id}/statistics`, {
        params: { examId: selectedExamId }
      })
      const stats = response.data as ItemStats
      setStatistics(stats)
    } catch (e) {
      toast.error(t('An error occurred. Please try again.'))
    }
  }

  return (
    <div className={className}>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <ExamItemInputSelect
          onChange={handleItemChange}
          examId={selectedExamId}
        />
      </Formik>
      {statistics && (
        <ColorfulProgressBar
          className="success-percentage"
          showValues
          showProgressValues
          max={statistics.correct + statistics.wrong}
          value={statistics.correct}
        ></ColorfulProgressBar>
      )}
      {item && perms?.Dashboard?.Statistics?.SeeItem && (
        <Button
          className="button"
          action="unstyled"
          onClick={() => setIsQuestionRecordModalOpen(true)}
        >
          {t('See item')}
        </Button>
      )}
      <QuestionsModal
        examId={selectedExamId}
        item={item}
        isOpen={isQuestionRecordModalOpen}
        answerSheet={answerSheet}
        setIsOpen={setIsQuestionRecordModalOpen}
      />
    </div>
  )
}

export default styled(ItemStatsDisplay)`
  .success-percentage > .percentage-value {
    margin-top: 1rem;
    font-size: 25px;
    font-weight: bold;
  }

  .success-percentage > progress {
    width: 100%;
  }

  .button {
    margin: 2.5rem;
  }
`
