import debounce from 'debounce-promise'
import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { AnswerContext } from 'states/AnswerState'
import styled from 'styled-components'
import Html from './Html'
import { fetchItem } from 'data/apis/items'

type LacunaProps = {
  className?: string
}

const replace_all = (text: string, data: string, replace: string) => {
  if (data !== replace) {
    while (text.includes(data)) {
      text = text.replace(data, replace)
    }
  }
  return text
}

const substitue_last = (text, word, data) => {
  const start_sub = text.lastIndexOf(word)
  const sub_final = start_sub + word.length
  return text.slice(0, start_sub) + data + text.slice(sub_final, text.leght)
}

const auth = (dt) =>
  [dt.length > 0, dt.includes('['), dt.includes(']')].every((e) => e === true)

const Lacuna = ({ className }: LacunaProps) => {
  const { answer, item, updateFreeResponse } = useContext(AnswerContext)
  const updateResponse = debounce((responseText: string) => {
    updateFreeResponse(responseText)
  }, 300)

  const [viewSolution, setViewSolution] = useState(false)
  const [answered, setAnswered] = useState(0)
  const [sent, setSent] = useState(false)
  const [solution, setSolution] = useState<string>()
  const [studentResponses] = useState([])

  const inputs = document.getElementsByTagName('input')
  const responses = []

  let pergunta = item && item.question ? item.question : ''
  const pergunta_auth = auth(pergunta)
  const resposta =
    item && item.expectedFreeResponse ? item.expectedFreeResponse : ''
  const resposta_auth = auth(resposta)

  const enunciado_text = item && item.introduction ? item.introduction : ''

  if (resposta_auth && pergunta_auth) {
    let res = resposta
      .split('[')
      .map((r: any) => (r.includes(']') ? r.split(']') : ''))
    res = res.map((c: any) => c[0])
    res.map((r: any) => {
      if (r !== undefined) {
        responses.push(r.split('|'))
      }
    })

    pergunta = substitue_last(pergunta, ']', '];')
    pergunta = replace_all(pergunta, '[', '')
    pergunta = replace_all(pergunta, '];', ' ')

    let id = 0
    while (pergunta.includes('__')) {
      pergunta = pergunta.replace('__', `<input id="${id}" /> `)
      id++
    }
  }

  const [question, setQuestion] = useState<string>(pergunta)

  const updateAnswered = () => {
    let count = 0
    responses.forEach((_, i) => {
      inputs[i].value.length > 0 && count++
    })
    setAnswered(count)
  }

  const fetchItemData = async () => {
    await fetchItem(item?.id)
      .then((res) => {
        return res
      })
      .catch((e) => console.error(e))
  }

  const renderResult = () => {
    let newQuestion = pergunta
    let expectedAnswer = pergunta
    if (answer?.freeResponse) {
      /* eslint no-unused-vars: 0 */
      let id = 0
      let idExpected = 0
      const values = answer?.freeResponse.split(',')
      while (newQuestion?.includes('input')) {
        const colors = []
        responses.forEach((r, i) => {
          colors.push(r.includes(values[i]) ? '#089C03' : '#9c0303')
        })

        expectedAnswer = expectedAnswer.replace(
          `<input id="${id}" /> `,
          `<b style="color: black !important;"}> ${responses[id][0]} </b>`
        )

        newQuestion = newQuestion.replace(
          `<input id="${id}" /> `,
          `<b style="color: ${colors[id]}"}> ${values[id]} </b>`
        )
        id++
        idExpected++
      }
    }
    setQuestion(newQuestion)
    setSolution(expectedAnswer)
    fetchItemData()
  }

  const handleClick = () => {
    if (answered === responses.length) {
      responses.map((r, i) => {
        studentResponses.push(inputs[i] ? inputs[i].value : '')
      })

      setSent(true)
      renderResult()
      updateResponse(studentResponses.join(','))
    } else {
      return toast.error('Há lacunas vazias!')
    }
  }

  useEffect(() => {
    if (answer.freeResponse !== null) {
      renderResult()
    }

    responses.forEach((_, i) => {
      if (inputs[i]) {
        inputs[i].onchange = () => updateAnswered()
      }
    })
  }, [answer.position, answer?.freeResponse, fetchItemData])

  return (
    <div className={className}>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <div className="counter-conteiner">
          {!sent ? answered : responses.length}/{responses.length}
        </div>
      </div>
      <div className="question-conteiner">
        <Html>{enunciado_text}</Html>
        {!sent && !answer?.freeResponse ? (
          <Html>{pergunta}</Html>
        ) : !viewSolution ? (
          <div
            style={{
              padding: '1rem',
              marginBottom: '1rem',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <b>Resposta Enviada!</b>
            <button
              className="btn-enviar"
              onClick={() => {
                setViewSolution(true)
              }}
            >
              Conferir resposta
            </button>
          </div>
        ) : (
          <div
            style={{
              borderRadius: '8px',
              boxShadow: '0px 1px 5px rgba(58, 58, 58, 0.5)',
              width: '100%',
              marginBottom: '1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '12px'
            }}
          >
            <p>
              <b>Sua resposta: </b>
              <Html>{question}</Html>
            </p>
            <p>
              <b>Resposta esperada: </b>
              <Html>{solution}</Html>
            </p>
          </div>
        )}
      </div>
      {!sent && !answer?.freeResponse && (
        <div
          style={{
            paddingBottom: '2rem',
            width: '70%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <button
            className="btn-enviar"
            onClick={() => {
              handleClick()
            }}
          >
            Enviar
          </button>
        </div>
      )}
    </div>
  )
}

export default styled(Lacuna)`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  box-shadow: 0px 1px 5px rgba(58, 58, 58, 0.5);
  .question-conteiner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    word-break: break-word;
    padding: 12px;
  }
  .counter-conteiner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 8px;
    box-shadow: 0px 1px 5px rgba(58, 58, 58, 0.5);
  }
  input {
    outline: 0;
    border-width: 0 0 2px;
    width: fit-content;
  }
  .btn-enviar {
    margin: 1rem;
    width: 100%;
    height: 30px;
    color: #fff;
    background: #6a35dd;
    border-radius: 8px;
    border: none;
    &:hover {
      background: #bebebe;
    }
  }
`
