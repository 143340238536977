import InputAsync from 'components/Inputs/InputAsync'
import React from 'react'

const IsRecordingInput = ({
  basic,
  params,
  ...props
}: {
  params?: any
  basic?: boolean
}) => {
  const fetchOptions = async () => {
    return [
      { value: 1, name: 'sim' },
      { value: 0, name: 'não' }
    ]
  }

  return (
    <InputAsync
      key={JSON.stringify(params)}
      defaultOptions
      loadOptions={fetchOptions}
      label="Iniciou Gravação"
      name="is_recording"
      getOptionValue={(d) => d.value}
      getOptionLabel={(d) => d.name}
      {...props}
    />
  )
}

export default IsRecordingInput
