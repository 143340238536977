import React from 'react'
import { useTranslation } from 'react-i18next'
import InputSelect from './InputSelect'

const ApplicationStatusInput = (props) => {
  const { t } = useTranslation()
  const applicationStatus = [
    { id: 'AVAILABLE_SOON', name: t('Available soon') },
    { id: 'AVAILABLE', name: t('Available') },
    { id: 'STARTED', name: t('Started') },
    { id: 'FINISHED', name: t('Finished') },
    { id: 'UNAVAILABLE', name: t('Unavailable') },
    { id: 'UNKNOWN', name: t('Unknown') },
    { id: 'STOPPED', name: t('Stopped') }
  ]

  return (
    <InputSelect
      label="Status"
      options={applicationStatus}
      placeholder="Status"
      name="application_status"
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      {...props}
    />
  )
}

export default ApplicationStatusInput
