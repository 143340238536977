import { Box } from '@material-ui/core'
import { getLoginEvents } from 'data/apis/message-exchange'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Event, IApplication, IExamDashboardApplication } from 'types'
import Moment from 'moment'
import Page from 'components/Page'
import styled from 'styled-components'
import { Skeleton } from '@material-ui/lab'
import Paginate from 'components/Paginate'
import ItemTitle from 'pages/AnswerSheet/components/ItemTitle'
import { FIREBASE_ENABLED } from 'consts'

interface LoginEventDetailsProps {
  application: IApplication | IExamDashboardApplication
  className?: string
  border?: boolean
  title?: string
  pagination?: boolean
}

type ShapedEvents = { [key: number]: Event[] }

const LoginEventDetails: React.FC<LoginEventDetailsProps> = ({
  application,
  className,
  pagination = true,
  title
}) => {
  const [totalPages, setTotalPages] = useState<number>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [noEvents, setNoEvents] = useState<boolean>(false)
  const [events, setEvents] = useState<ShapedEvents>({})
  const [eventsWithoutPagination, setEventsWithoutPagination] = useState([])
  const [loading, setLoading] = useState<boolean>(true)
  const { t } = useTranslation()

  const pageSize = 20

  const getEventDays = (
    application: IExamDashboardApplication | IApplication
  ) => {
    const eventDays = []
    if (application.startedAt) {
      eventDays.push(application.startedAt)
    } else if (application.timeWindows.length) {
      application.timeWindows.forEach((tw) => eventDays.push(tw.startTime))
    } else {
      application.exam.timeWindows.forEach((tw) => eventDays.push(tw.startTime))
    }
    return eventDays
  }

  const handleLoginEvents = useCallback(async () => {
    const eventDays = getEventDays(application)

    let events: Event[] = []
    const getEvents = eventDays.map(async (eventDay) => {
      const dayEvents = await getLoginEvents(
        application.user.id.toString(),
        new Date(eventDay)
      )
      events = [...events, ...dayEvents]
    })

    await Promise.all(getEvents)

    if (!events.length) {
      setNoEvents(true)
      setLoading(false)
      return
    }

    let pageCount = 0

    const shapedEvents: ShapedEvents = {}
    events.forEach((event, i) => {
      if (i % pageSize === 0) {
        pageCount++
        shapedEvents[pageCount] = []
      }
      shapedEvents[pageCount].push(event)
    })

    setTotalPages(Math.ceil(events.length / pageSize))
    setEventsWithoutPagination(events)
    setEvents(shapedEvents)
    setLoading(false)
  }, [application])

  useEffect(() => {
    if (FIREBASE_ENABLED) {
      handleLoginEvents()
    }
  }, [handleLoginEvents])

  return (
    <Page className={className}>
      {title && <ItemTitle className="title-wrapper">{title}</ItemTitle>}
      {loading && <Skeleton height="400px" />}
      {noEvents && !loading && (
        <Box display="flex" justifyContent="center">
          <h1>{t('No login events found.')}</h1>
        </Box>
      )}
      {!noEvents && !loading && (
        <div>
          <Box
            padding="10px"
            display="flex"
            justifyContent="space-between"
            className="event-header"
            fontWeight="bold"
          >
            <p className="event-information">{t('Event')}</p>
            <p className="event-information">{t('Time')}</p>
          </Box>
          {pagination ? (
            <>
              {events[currentPage]?.map((event) => (
                <Box
                  padding="10px"
                  display="flex"
                  justifyContent="space-between"
                  key={event?.when?.getTime()}
                  className="event-item"
                >
                  <p className="event-information">{event.type}</p>
                  <p className="event-information">
                    {Moment(event?.when?.getTime()).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                  </p>
                </Box>
              ))}
              <Paginate onPageChange={setCurrentPage} pageCount={totalPages} />
            </>
          ) : (
            <>
              {eventsWithoutPagination?.map((event) => (
                <Box
                  padding="10px"
                  display="flex"
                  justifyContent="space-between"
                  key={event?.when?.getTime()}
                  className="event-item"
                >
                  <p className="event-information">{event.type}</p>
                  <p className="event-information">
                    {Moment(event?.when?.getTime()).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                  </p>
                </Box>
              ))}
            </>
          )}
        </div>
      )}
    </Page>
  )
}

export default styled(LoginEventDetails)`
  border: ${(props) => (props.border ? '1px solid #f5f5f5' : 'none')};
  box-shadow: ${(props) =>
    props.border ? '0px 2px 2px rgba(0, 0, 0, 0.1)' : 'none'};
  margin-top: ${(props) => (props.border ? '15px' : '0')};

  .title-wrapper {
    margin: 0 0 15px -10px;
  }

  .event-item {
    &:nth-of-type(odd) {
      background: #f5f5f5;
    }
  }

  .event-header {
    background: #f5f5f5;
  }

  .event-information {
    margin: 0;
  }

  @media print {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
`
