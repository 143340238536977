import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ImportBase from '../components/Imports/ImportBase'
import { API_HOST } from '../consts'
import usePreference from 'hooks/usePreference'
import { IBooleanPreference } from '../types'
import { get } from 'lodash'

const UploadUserDocument = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [selectedFile, setSelectedFile] = useState()
  const [errors, setErrors] = useState([])
  const fileSizeLimit = 1024 * 1024 * 10
  const shouldUploadDocumentOnFirstAccess = usePreference<IBooleanPreference>(
    'Login__DocUploadOnFirstAccess'
  )

  useEffect(() => {
    if (shouldUploadDocumentOnFirstAccess?.value === false) {
      history.replace('/applications')
    }
  }, [shouldUploadDocumentOnFirstAccess, history])

  const allowedMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/heic',
    'image/heif',
    'image/gif',
    'image/bmp',
    'image/*'
  ]

  const onFileChange = (event) => {
    const file = event.target.files[0]

    if (file.size < fileSizeLimit) {
      if (allowedMimeTypes.includes(file.type)) {
        setSelectedFile(file)
      } else {
        toast.error(t('File format is not allowed'))
      }
    } else {
      console.log(file.size)
      toast.error(t('File size must be less than 10Mb'))
    }
  }

  const handleSubmit = async () => {
    if (selectedFile) {
      const formData = new FormData()
      formData.append('file', selectedFile)
      return await axios
        .put(`${API_HOST}/v1/user_document`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(() => {
          toast.success(t('Document was sent!'))
          history.replace('/applications')
        })
        .catch((e) => {
          if (e.status === 400) {
            setErrors(e.data.errors)
          }
          toast.error(t('An error occurred. Please try again.'))
        })
    } else {
      toast.error(t('No file was selected'))
    }
  }

  const boldTextStyle = {
    fontWeight: 700,
    color: 'black'
  }
  const instructions = (
    <span>
      {t(
        'Upload an identification document (identity card, passport, drivers license) up to 10mb in size in one of the following formats png, jpg, pdf'
      )}
      <p style={boldTextStyle}>
        {'** '}
        {t(
          'ATTENTION! You will only be allowed to attach 1 document, so the front and back of the document must be contained in a single file.'
        )}
      </p>
    </span>
  )

  return (
    <Formik initialValues={{ updateIfExists: false }} onSubmit={handleSubmit}>
      {(formik) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '40%',
              borderRadius: '16px',
              boxShadow: '0px 1px 5px rgba(58, 58, 58, 0.25)',
              border: 'none'
            }}
          >
            <ImportBase
              title={t('Identification Document Upload')}
              instructions={instructions}
              onSubmit={formik.submitForm}
              onFileChange={onFileChange}
              errors={errors}
              successMessage={''}
              loading={false}
            ></ImportBase>
            {get(selectedFile, 'name') ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  minHeight: '50px',
                  maxWidth: '90%'
                }}
              >
                <span style={{ marginLeft: '4rem' }}>
                  {get(selectedFile, 'name', '')}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </Formik>
  )
}

export default UploadUserDocument
