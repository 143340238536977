import Html from 'components/Html'
import Modal from 'components/Modal/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  explanation: string
  alternativeLetter: string
  isOpen: boolean
  onClose: () => void
  className?: string
}
const AlternativeExplanationModal = ({
  alternativeLetter,
  explanation,
  isOpen,
  onClose,
  className
}: Props) => {
  const { t } = useTranslation()

  return (
    <Modal
    isOpen={isOpen}
    onClose={onClose}
    onCancel={onClose}
    cancelText={t('Close')}
    title={`${t('Alternative explanation')} ${alternativeLetter}`}
    >
      <div className={className}>
        {explanation ? (
          <div className="explanation-content">
            <Html>{explanation}</Html>
          </div>
        ) : (
          <p>{t('Alternative explanation not informed')}</p>
        )}
      </div>
    </Modal>
  )
}

export default styled(AlternativeExplanationModal)`
  .explanation-content {
    img {
      max-width: 100%;
    }
  }
`
