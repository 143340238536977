import { Message } from 'data/domain/message-exchange'
import React from 'react'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import Button from './Button'

interface IBadgeWarningProps {
  warningType: 'info' | 'warning' | 'success' | 'error'
  message: Message
  button?: boolean
  handleButtonSubmit?: (id: string) => void
}

const BadgeMessage = ({
  warningType,
  message,
  button = false,
  handleButtonSubmit = () => undefined
}: IBadgeWarningProps) => {
  return (
    <AlertSpacing>
      <Alert severity={warningType}>{message.text}</Alert>
      {button && (
        <Button
          action="secondary"
          onClick={() => handleButtonSubmit(message.id)}
        >
          Confirmar
        </Button>
      )}
    </AlertSpacing>
  )
}

export default BadgeMessage

const AlertSpacing = styled.div`
  margin-top: 1rem;
  border-radius: 1rem;
  position: relative;

  > button {
    position: absolute;
    top: 15%;
    right: 20px;
  }
`
