import { createContext } from 'react'
import { IApplicationSocketState } from '../types'

export const initialState: IApplicationSocketState = {
  socket: null,
  status: 'NORMAL',
  pauseReason: '',
  alertMessage: '',
  badgeMessages: [],
  reloadRequests: [],
  showReloadRequests: () => undefined,
  connected: false,
  sendQuestion: () => Promise.resolve(),
  askForBreak: () => Promise.resolve(),
  askForNursingBreak: () => Promise.resolve(),
  hasPendingBreak: false,
  breakRequest: null,
  forcePause: false,
  setHasPending: () => undefined,
  updateExamStatus: () => undefined,
  returnFromBreak: () => undefined,
  setCameraAccepted: () => undefined,
  sendCameraFrame: () => undefined,
  joinRoomAsCandidate: () => undefined,
  handlePendingBreak: () => undefined
}

const CandidateMessageExchangeContext = createContext<IApplicationSocketState>(
  initialState
)

export default CandidateMessageExchangeContext
