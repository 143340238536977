import axios from 'axios'
import UserInput from 'components/Inputs/UserInput'
import { API_HOST } from 'consts'
import { Formik, FormikValues } from 'formik'
import useQuery from 'hooks/useQuery'
import { RollbarErrorTracking } from 'infra/rollbar'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import CardList from '../components/CardList'
import CardListToolbar from '../components/CardListToolbar'
import ExamDashboardApplicationCard from '../components/ExamDashboardApplicationCard'
import HierarchyFilterBox from '../components/HierarchyFilterBox'
import PaginationFooter from '../components/PaginationFooter'
import ResultsFound from '../components/ResultsFound'
import { AuthContext } from '../states/AuthState'
import { useTranslation } from 'react-i18next'
import ExamDashboardState from '../states/ExamDashboardState'
import { ICollection, IExamDashboardState, IInstitutionData } from '../types'
import devices from '../utils/devices'
import FinishedApplicationsFilter from 'components/Inputs/FinishedApplicationsFilter'
import SpecialNeedInput from 'components/Inputs/SpecialNeedInput'
import ExamInputSelect from 'components/Inputs/ExamInputSelect'
import Button from 'components/Button'
import { downloadFile } from 'utils/files'
import { ModalContext } from 'states/ModalState'
import SetBulkTimeWindowModal from 'components/ExamDashboardComponents/SetBulkTimeWindowModal'
import ActionMenuButton from 'components/Buttons/ActionMenuButton'
import { collectionIsFinished } from '../data/utils/collections'
import SetTimeoutModal from 'components/ExamDashboardComponents/SetTimeoutModal'
import ReportStudentPermissionsModal from 'components/ExamDashboardComponents/ReportStudentPermissionsModal'
import LoadingIndicator from 'components/LoadingIndicator'

type ExamDashboardProps = {
  className?: string
}

const ExamDashboard = ({ className }: ExamDashboardProps) => {
  const { user, hasGroup } = useContext(AuthContext)
  const { t } = useTranslation()
  const { showModal, hideModal } = useContext(ModalContext)
  const [collection, setCollection] = useState<ICollection>(undefined)
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')
  const [school, setSchool] = useState()

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const fetchSchool = useCallback(async () => {
    try {

      const response = await axios.get<IInstitutionData[]>(
        `${API_HOST}/v1/users_group_by_institucions`
      );
      const result = response.data.map((schools) => ({
        id: schools.institucion['id'],
        grupo: schools.group['name'],
      }));

      const schoolIds = result.map((school) => school.id);
      setSchool(schoolIds[0]);
      const isAdmin = response.data.some((schools) => schools.group['name'] === 'ADMINISTRADOR');
      setIsAdmin(isAdmin);



    } catch (err) {

      // Tratar erro
    }
  }, []);

  useEffect(() => {
    fetchSchool();
  }, []);

  const formInitialValues: FormikValues = {
    collection: undefined,
    exam: undefined,
    Escolas: undefined
  };

  const fetchCollection = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/v1/collections/${collectionId}`
      )
      const collection = response.data
      setCollection(collection)
    } catch (e) {
      // @ts-ignore
      RollbarErrorTracking.logError(e)
    }
  }, [collectionId])

  useEffect(() => {
    fetchCollection()
  }, [fetchCollection])

  const initialCollectionValues = {
    answerSheetDisclosed: collection?.answerSheetDisclosed,
    postAlternativeExplanationVisualization:
      collection?.postAlternativeExplanationVisualization,
    postAnswerVisualization: collection?.postAnswerVisualization,
    postItemNavigation: collection?.postItemNavigation,
    showAnswerSheetOverview: collection?.showAnswerSheetOverview,
    showCandidateGrade: collection?.showCandidateGrade,
    canDiscloseReport: collection?.canDiscloseReport,
    showAnswerSheetToCandidate: collection?.showAnswerSheetToCandidate
  }

  return (
    <ExamDashboardState>
      {({
        applications,
        exam,
        fetchingApplications,
        fetchedApplications,
        onFilter,
        totalApplications,
        handlePageChange,
        onPageSizeChange,
        numPages,
        pageSize,
        getVerboseFilters,
        setBulkTimeWindow,
        handleSetTimeoutSubmit
      }: IExamDashboardState) => (
        <div className={className}>
          <div className="parent-wrapper">
            <div className="filter-wrapper">
              <Formik initialValues={formInitialValues} onSubmit={onFilter}>
                {() => (
                  <>
                    <HierarchyFilterBox
                      distinct={false}

                      providerCodename={user?.provider?.codename}
                    >
                      <UserInput basic />
                      <FinishedApplicationsFilter />
                      <SpecialNeedInput />
                      <ExamInputSelect
                        collectionId={collection?.id}
                        label={t('Exam_name')}
                        name="exam"
                      />
                    </HierarchyFilterBox>
                  </>
                )}
              </Formik>
            </div>
            <div className="card-list-wrapper">
              <CardList>
                {totalApplications === undefined&&(
                  <Skeleton height={150} count={pageSize} />
                )}
                <CardListToolbar>
                {totalApplications !== undefined && (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        marginBottom: '-50px'
                      }}
                    >
                      <hr
                        style={{
                          width: '155px',
                          height: '1px',
                          color: '#CBCBCB',
                          marginRight: '10px'
                        }}
                      />
                      <>
                        <ResultsFound resultsFound={totalApplications} />
                        <hr
                          style={{
                            width: '155px',
                            height: '1px',
                            color: '#CBCBCB',
                            marginLeft: '10px'
                          }}
                        />
                      </>
                    </div>
                  )}

                  {!collectionIsFinished(collection) && (
                    <div style={{ marginTop: '1.2rem' }}>
                      <ActionMenuButton label={t('Actions')}>
                        <Button
                          action="unstyled"
                          onClick={() =>
                            showModal(() => (
                              <SetBulkTimeWindowModal
                                filters={getVerboseFilters()}
                                totalApplications={totalApplications}
                                onSubmit={setBulkTimeWindow}
                              />
                            ))
                          }
                        >
                          {`${t(
                            'Set deadline for the activity'
                          )} (${totalApplications?.toLocaleString()})`}
                        </Button>
                        <Button
                          action="unstyled"
                          disabled={!hasGroup('ADMINISTRADOR')}
                          onClick={() =>
                            downloadFile({
                              url: `${API_HOST}/v1/collections/${collectionId}/rooms_list`,
                              params: { format: 'excel' }
                            })
                          }
                        >
                          {t('Get rooms list')}
                        </Button>
                        <Button
                          action="unstyled"
                          onClick={() =>
                            showModal(() => (
                              <SetTimeoutModal
                                onSubmit={handleSetTimeoutSubmit}
                                examName={collection.name}
                                filters={getVerboseFilters()}
                                totalApplications={totalApplications}
                                toAllCandidates={true}
                              />
                            ))
                          }
                        >
                          Redefinir tempo de atividade
                        </Button>
                        {hasGroup('ADMINISTRADOR') && (
                          <Button
                            action="unstyled"
                            onClick={() =>
                              showModal(() => (
                                <ReportStudentPermissionsModal
                                  collection={initialCollectionValues}
                                  collectionId={collectionId}
                                  hideModal={hideModal}
                                  fetchCollection={fetchCollection}
                                />
                              ))
                            }
                          >
                            {t('Student Permissions')}
                          </Button>
                        )}
                      </ActionMenuButton>
                    </div>
                  )}
                </CardListToolbar>
                {!fetchingApplications &&
                  applications.map((application) => (
                    <ExamDashboardApplicationCard
                      key={application.id}
                      application={application}
                      exam={exam}
                    />
                  ))}
              </CardList>
              <PaginationFooter
                pageSize={pageSize}
                pageCount={numPages}
                onPageChange={handlePageChange}
                onPageSizeChange={onPageSizeChange}
                disablePageSizeChange
              >
                <p>
                  Mostrando {applications.length} de {totalApplications}{' '}
                  registros.
                </p>
              </PaginationFooter>
            </div>
          </div>
        </div>
      )}
    </ExamDashboardState>
  )
}

export default styled(ExamDashboard)`
  .parent-wrapper {
    display: flex;
    column-gap: 20px;
  }

  @media (max-width: 1000px) {
    .parent-wrapper {
      row-gap: 10px;
      column-gap: 0;
    }
  }
  @media (max-width: ${devices.mobileL}) {
    .parent-wrapper {
      flex-direction: column;
      row-gap: 20px;
      column-gap: 0;
    }
  }

  .filter-wrapper {
    max-width: 400px;
    @media (min-width: ${devices.tablet}) {
      min-width: 400px;
      column-gap: 20px;
    }
  }

  .card-list-wrapper {
    padding-left: 1rem;
    flex-grow: 6;
  }
`
