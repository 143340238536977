import { UserConfiguration } from 'data/domain/user-config'
import { db } from '../../infra/firebase'

export const getUserConfiguration = async (candidateId: string) => {
  const snapshot = db.collection('userConfig').doc(candidateId).get()

  return (await snapshot).data() as UserConfiguration
}

export const updateUserConfiguration = (
  candidateId: string,
  values: object
) => {
  return db.doc(`userConfig/${candidateId}`).set(values)
}
