import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ButtonHTMLAttributes, ReactNode, Ref, forwardRef } from 'react'
import styled from 'styled-components'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  action?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'icon'
    | 'unstyled'
    | 'link'
    | 'error'
    | 'defer'
    | 'deferParc'
    | 'indef'
  size?: 'large'
  children?: ReactNode
  className?: string
  isLoading?: boolean
  marginTop?: string
  marginBottom?: string
}

const Button = forwardRef(
  (
    {
      action = 'default',
      children,
      className,
      isLoading = false,
      disabled,
      marginTop = '1rem',
      marginBottom = '1rem',
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <button
        className={`${action}-btn ${className}`}
        disabled={isLoading || disabled}
        ref={ref}
        {...rest}
      >
        {isLoading && <FontAwesomeIcon spin icon="circle-notch" />} {children}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default styled(Button)`
  ${({ size }) =>
    size === 'large' &&
    `
    font-size: 20px;
  `}
  transition: all 0.3s;
  cursor: pointer;
  padding: 7px 20px;
  border-radius: 70px;
  font-weight: 500;
  letter-spacing: 1px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border: none;

  &.primary-btn {
    color: white;
    background: ${(props) => props.theme.clientColors.primary};
    border: 1px solid ${(props) => props.theme.clientColors.primary};
    font-size: 12pt;

    &:disabled,
    &:disabled:hover {
      background-color: ${(props) => props.theme.colors.grayDark};
      border-color: ${(props) => props.theme.colors.grayDark};
      cursor: not-allowed;
    }

    &:hover {
      background: ${(props) => props.theme.clientColors.secondaryDark};
      border: 1px solid ${(props) => props.theme.clientColors.secondaryDark};
    }

    &:focus {
      outline: none;
    }
  }

  &.defer-btn {
    color: white;
    background: #00a202;
    font-size: 12pt;

    &:hover {
      background: #045205;
    }

    &:focus {
      outline: none;
    }
  }

  &.deferParc-btn {
    color: white;
    background: #da9f17;
    font-size: 12pt;

    &:hover {
      background: #7a5600;
    }

    &:focus {
      outline: none;
    }
  }

  &.indef-btn {
    color: white;
    background: #991010;
    font-size: 12pt;

    &:hover {
      background: #5a0c1a;
    }

    &:focus {
      outline: none;
    }
  }

  &.error-btn {
    color: white;
    background: #c7687a;
    font-size: 12pt;

    &:disabled,
    &:disabled:hover {
      background-color: ${(props) => props.theme.colors.grayDark};
      border-color: ${(props) => props.theme.colors.grayDark};
      cursor: not-allowed;
    }

    &:hover {
      background: #903546;
    }

    &:focus {
      outline: none;
    }
  }

  &.secondary-btn {
    @extend %default-transition;
    background-color: white;
    border: ${(props) => props.theme.clientColors.primary} solid 1pt;
    color: ${(props) => props.theme.clientColors.primary};
    font-size: 12pt;
    &:focus {
      outline: none;
    }

    &:hover:not(:disabled) {
      background-color: ${(props) => props.theme.clientColors.primary};
      color: white;
    }
  }

  &.icon-btn {
    background: none;
    border: 1px solid #ccc;
    box-shadow: none;
    padding: 5px;
    font-size: 14px;
    width: 40px;
    color: ${(props) => props.theme.colors.grayDark};

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &:disabled,
    &:disabled:hover {
      color: ${(props) => props.theme.colors.grayMedium};
      cursor: not-allowed;
      background-color: none;
    }
  }

  &.unstyled-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    box-shadow: none;

    &:disabled,
    &:disabled:hover {
      color: ${(props) => props.theme.colors.grayMedium};
      cursor: not-allowed;
    }
  }

  &.link-btn {
    color: ${(props) => props.theme.clientColors.primary};
    margin-top: ${({ marginTop }) => marginTop || '1rem'};
    margin-bottom: ${({ marginBottom }) => marginBottom || '1rem'};
    padding: 0;
    text-decoration: underline;
    outline: none;
    box-shadow: none;
    background: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 50%;
  }

  &.backButton1 {
    color: white;
    margin-left: 30%;
    background: ${(props) => props.theme.clientColors.primary};
    font-size: 12pt;

    &:hover {
      background: ${(props) => props.theme.clientColors.secondaryDark};
    }

    &:focus {
      outline: none;
    }
  }
`
