import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Axios from 'axios'
import { get } from 'lodash'
import { API_HOST } from '../consts'
import { Nav } from 'reactstrap'
import HighchartSkill from './HighchartSkill'
import HeaderAvalia from '../components/HeaderAvalia'
import Page from 'components/Page'
import waiting from '../assets/img/mascot-waiting.png'
import loadingGif from '../assets/img/loading.gif'

const ReportStudentSkillLevel = ({ match }) => {
  const [habilidadeData, setHabilidadeData] = useState(undefined)
  const [boletimData, setBoletimData] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const avaliaId = match.params.id

  useEffect(
    () => {
      const fetchHabilidade = () => {
        Axios.get(`${API_HOST}/estudante_desempenho?idn=${avaliaId}`)
          .then((res) => {
            setHabilidadeData(res.data.results)
          })
          .catch((err) => {
            console.error(err)
          })
      }
      const fetchBoletimData = () => {
        Axios.get(`${API_HOST}/boletim_mt/${avaliaId}/get_relatorio`)
          .then((res) => {
            setBoletimData(res.data)
            setLoading(false)
          })
          .catch((err) => {
            console.error(err)
            setLoading(false)
          })
      }

      fetchHabilidade()
      fetchBoletimData()
    },
    [avaliaId],
    [loading]
  )

  const titulo = [
    'Linguagens, Códigos e suas Tecnologias',
    'Matemática e suas Tecnologias'
  ]

  return (
    <Page>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: `100%`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={loadingGif} alt="loading" style={{ height: '50px' }} />
        </div>
      ) : (
        <>
          <div
            id="nav-expand"
            className="nav-expand"
            style={{ background: 'rgb(103, 53, 221)' }}
          >
            <Nav
              className="navbar-links"
              style={{ background: 'rgb(103, 53, 221)', border: 'none' }}
            />
          </div>
          {boletimData ? (
            <div style={{ marginTop: '5rem', background: '#fff' }}>
              <div
                className="container mb-4 pb-4"
                style={{
                  marginTop: '3rem',
                  backgroundColor: '#ffff',
                  width: '100%',
                  maxWidth: '1110px',
                  borderRadius: '10px',
                  boxShadow: 'rgba(58, 58, 58, 0.25) 0px 1px 5px',
                  padding: '12px 20px'
                }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h1 style={{ fontSize: '30px', color: '#292929' }}>
                          Relatório individual do Estudante - desempenho por
                          habilidade{' '}
                        </h1>
                        <hr />
                      </div>
                      <div
                        className="card-body"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <HeaderAvalia
                          schoolName={
                            boletimData ? get(boletimData, 'nMESCOLA', '') : ''
                          }
                          schoolYear={
                            boletimData ? get(boletimData, 'nMETAPA', '') : ''
                          }
                          dreAndMunicipio={
                            boletimData ? get(boletimData, 'nMDRE', '') : ''
                          }
                          student={
                            boletimData ? get(boletimData, 'nMALUNO', '') : ''
                          }
                        />
                        <div className="infos__school">
                          <h2>Habilidades a serem desenvolvidas</h2>
                          <span className="example-hability">
                            Os dados abaixo dizem respeito as habilidades, a
                            partir de informações extraídas do processamento do
                            resultado de todas as escolas até o momento.
                            Demonstra a porcentagem de acertos do estudante
                            dentro do seu grupo de proficiência por habilidade.
                          </span>
                        </div>
                        <div className="school__material">
                          <span>
                            {
                              titulo[
                                boletimData
                                  ? get(boletimData, 'cDDISCIPLINA', '') - 1
                                  : ''
                              ]
                            }
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            width: '100%',
                            fontSize: '18px',
                            fontWeight: 'bold'
                          }}
                        >
                          <span>
                            {boletimData ? get(boletimData, 'nMALUNO', '') : ''}{' '}
                            :
                          </span>
                          <div
                            style={{
                              marginLeft: '1rem',
                              width: '40px',
                              height: '20px',
                              border: 'none',
                              borderRadius: '8px',
                              background: `${
                                boletimData
                                  ? get(boletimData, 'cor', '')
                                  : '#fff'
                              }`
                            }}
                          ></div>
                          <span style={{ margin: '1rem' }}>
                            {boletimData
                              ? get(
                                  boletimData,
                                  'proficienciaAluno',
                                  'indefinida'
                                )
                              : 'indefinida'}
                          </span>
                        </div>
                        <div className="table-proficiency">
                          <span>
                            {boletimData ? get(boletimData, 'nMETAPA', '') : ''}
                          </span>
                        </div>
                        <hr className="pro-hr" />
                        {habilidadeData &&
                          habilidadeData.map((dataHability) => (
                            <div
                              style={{ width: '100%' }}
                              key={dataHability.idn}
                            >
                              <div className="proeficiency__infos">
                                <>
                                  <div className="axis__infos">
                                    {window.location.hostname.includes(
                                      'avaliamt'
                                    ) ? (
                                      <div className="axis__text">
                                        <span>
                                          Eixo de Conhecimento:{' '}
                                          <span
                                            style={{
                                              color: '#3C4583',
                                              marginLeft: '1rem',
                                              fontWeight: '500'
                                            }}
                                          >
                                            {get(
                                              dataHability,
                                              'competencia',
                                              ''
                                            )}
                                          </span>
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    <div className="habilidade">
                                      <span>Habilidade: </span>
                                      <span className="skill-text">
                                        {get(dataHability, 'habilidade', '')}
                                      </span>
                                    </div>
                                  </div>
                                </>
                                <div className="graphic">
                                  <HighchartSkill data={dataHability} />
                                </div>
                              </div>
                            </div>
                          ))}
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'right',
                            margin: '1rem'
                          }}
                        >
                          <button
                            style={{
                              color: 'white',
                              backgroundColor: 'rgb(103, 53, 221)',
                              border: '1px solid rgba(58, 58, 58, 0.25)',
                              fontSize: '12pt',
                              textAlign: 'center',
                              cursor: 'pointer',
                              borderRadius: '8px',
                              boxShadow: '2px 4px 5px rgba(58, 58, 58, 0.25)',
                              letterSpacing: '1px',
                              padding: '4px 16px',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                            onClick={() =>
                              window.history.back()(
                                `/aluno-relatorio/${avaliaId}`,
                                '_self'
                              )
                            }
                          >
                            Relatório do Estudante
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                borderRadius: '16px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                minHeight: '300px',
                padding: '2rem',
                flexDirection: 'column'
              }}
            >
              <h1 style={{ color: 'rgb(103, 53, 221)' }}>
                Até o momento não foi processada nota para essa prova.
              </h1>
              <img src={waiting} alt="waiting" />
              <button
                style={{
                  color: 'white',
                  backgroundColor: 'rgb(103, 53, 221)',
                  border: '1px solid rgba(58, 58, 58, 0.25)',
                  fontSize: '12pt',
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  boxShadow: '2px 4px 5px rgba(58, 58, 58, 0.25)',
                  letterSpacing: '1px',
                  padding: '4px 16px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                onClick={() => window.open(`/applications`, '_self')}
              >
                Voltar para atividades
              </button>
            </div>
          )}
        </>
      )}
    </Page>
  )
}

export default withRouter(ReportStudentSkillLevel)
