import React from 'react'
import styled from 'styled-components'
import { useField, useFormikContext } from 'formik'

type InputTextProps = {
  name: string
  label?: string
  className?: string
  onChange?: any
  required?: boolean
  disabled?: boolean
}

const InputText = ({
  label,
  className,
  onChange,
  required,
  disabled,
  ...rest
}: InputTextProps) => {
  const [field, meta] = useField(rest)
  const { setFieldValue } = useFormikContext()

  return (
    <div className={className}>
      <div className="input-type-style">
        <div
          className={
            meta.error && meta.touched ? 'input-error red' : 'input-style'
          }
        >
          <input
            {...field}
            {...rest}
            disabled={disabled}
            onChange={(event) => {
              setFieldValue(field.name, event.target.value)
              if (onChange) {
                onChange(event.target.value)
              }
            }}
          />
          <label
            htmlFor={field.name}
            className={`
              input-label-style
              ${meta.error && meta.touched ? 'red' : 'dim'}
              ${required ? 'input-required' : ''}
            `}
          >
            {label}
          </label>
        </div>
        {meta.error && <span className="input-helper red">{meta.error}</span>}
      </div>
    </div>
  )
}

export default styled(InputText)`
  .input-type-style {
    margin-top: 2rem;
    position: relative;
  }
  .input-type-style .input-style,
  .input-type-style .input-error {
    width: 100%;
  }
  .input-type-style .input-style textarea,
  .input-type-style .input-error textarea {
    min-height: 38px;
    width: 100%;
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    outline-offset: inherit;
    outline: none;
    resize: none;
    background: transparent;
    font-family: 'Open Sans', sans-serif;
  }
  .input-type-style .input-style input,
  .input-type-style .input-error input {
    min-height: 38px;
    width: 100%;
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
    border: 1px solid #dddddd;
  }
  .input-type-style .input-style input::placeholder,
  .input-type-style .input-error input::placeholder {
    color: #808080;
  }
  .input-type-style .input-style input:focus,
  .input-type-style .input-error input:focus {
    box-shadow: none;
  }
  .input-type-style .input-style .quill .ql-toolbar,
  .input-type-style .input-error .quill .ql-toolbar {
    border-top-color: #ff5046;
    border-right-color: #ff5046;
    border-left-color: #ff5046;
  }
  .input-type-style .input-style .quill .ql-container,
  .input-type-style .input-error .quill .ql-container {
    border-left-color: #ff5046;
    border-right-color: #ff5046;
  }
  .input-type-style.input-type-border .input-style {
    border: 1px solid #dddddd;
  }
  .input-type-style.input-type-border .input-style textarea {
    padding: 1rem;
  }
  .input-type-style.input-type-border .input-style:before {
    display: none;
  }
  .input-type-style.input-type-border .input-style:hover {
    border: 1px solid #3f928b;
  }
  .input-type-style.input-type-border .input-style:hover:before {
    display: none;
  }
  .input-type-style.input-type-border .input-style:focus-within {
    border: 1px solid #039f97;
  }
  .input-type-style.input-type-border .input-style:focus-within:after {
    display: none;
  }
  .input-type-style.input-type-border .input-label-style {
    padding-bottom: 2rem;
  }
  .input-type-style.input-type-border .input-error {
    border: 1px solid #ff5046;
  }
  .input-type-style .input-style,
  .input-type-style .input-error,
  .input-type-style .dropdown__control {
    position: relative;
  }
  .input-type-style .input-style input,
  .input-type-style .input-error input,
  .input-type-style .dropdown__control input {
    width: 100%;
    background: transparent;
    font-family: 'Open Sans', sans-serif;
  }
  .input-type-style .input-style:hover:before,
  .input-type-style .input-error:hover:before,
  .input-type-style .input-style:focus-within:after,
  .input-type-style .input-error:focus-within:after,
  .input-type-style .dropdown__control:focus-within:after {
    transform: scaleX(1);
  }
  .input-type-style .input-style:before,
  .input-type-style .input-error:before,
  .input-type-style .dropdown__control:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    pointer-events: none;
  }
  .input-type-style .input-style:after,
  .input-type-style .input-error:after,
  .input-type-style .dropdown__control:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  .input-type-style .input-label-style {
    padding-bottom: 1rem;
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-10%, -55%) scale(0.9);
    font-weight: 600;
    margin-left: 4px;
  }
  .input-type-style .input-label-style.text-area-label {
    font-size: 14px;
    position: relative;
    margin-left: 0;
  }
  .input-type-style .dropdown__value-container .dropdown__single-value {
    transform: initial;
    margin: 0;
    position: relative;
    overflow: initial;
    max-width: initial;
    white-space: initial;
    top: 0;
    box-sizing: inherit;
    text-overflow: initial;
    min-height: initial;
  }
  .input-type-style:focus-within .input-label-style {
    color: #039f97;
  }
  .input-type-style .input-error:before {
    border-color: #ff5046;
  }
  .input-type-style .input-error .dropdown__control:before {
    border-color: #ff5046;
  }
  .input-type-style .input-error .dropdown__control .css-1g6gooi {
    margin: 0;
    padding: 0;
  }
  .input-type-style .input-error .dropdown__menu {
    color: #313131;
  }
  .input-type-style .input-error + .input-label-style {
    color: #ff5046;
  }
  .input-type-style .input-error + .input-label-style + .input-helper {
    color: #ff5046;
  }
  .input-type-style .input-error + .input-helper {
    color: #ff5046;
  }
  .input-type-style .input-helper {
    font-size: 12px;
    color: #585858;
  }
`
