import MuiCheckbox from '@material-ui/core/Checkbox'
import { useFormikContext, useField } from 'formik'
import React, { ReactNode } from 'react'
import { FormControlLabel } from '@material-ui/core'

type CheckboxProps = {
  name: string
  label?: ReactNode
}

const Checkbox = ({ name, label }: CheckboxProps) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField({ name })

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          color="primary"
          onChange={(e) => setFieldValue(field.name, e.target.checked)}
          checked={field.checked}
          {...field}
        />
      }
      label={label}
    />
  )
}

export default Checkbox
