import useQuery from 'hooks/useQuery'
import DashboardStatistics from 'pages/DashboardStatistics'
import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { VIDEO_RECORDING_ENABLED } from '../../consts'
import DashboardVideos from '../../pages/DashboardVideos'
import ExamDashboard from '../../pages/ExamDashboard'
import AppealList from '../../pages/AppealsList'
import ItemsList from 'pages/ItemsList'
import ProctorsList from '../../pages/proctosList'
import PostExam from 'pages/PostExam/PostExam'
import AnswerSheetBulkOrderList from 'pages/BulkOrderPage/AnswerSheetBulkOrderList'
import DashboardView from 'pages/DashboardView'

type DashboardRouterProps = {
  roomId: string
}

const DashboardRouter = ({ roomId }: DashboardRouterProps) => {
  const { path } = useRouteMatch()
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  if (!collectionId) {
    return <Redirect to="/exams/dashboard-pre-filter"></Redirect>
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/list`} />
      </Route>
      <Route path={`${path}/list`} component={ExamDashboard} />
      <Route path={`${path}/statistics`} component={DashboardStatistics} />
      <Route path={`${path}/view`} component={DashboardView} />
      <Route path={`${path}/appeal`} component={AppealList} />
      <Route path={`${path}/proctor`} component={ProctorsList} />
      <Route path={`${path}/items`} component={ItemsList} />
      <Route
        path={`${path}/answer-sheet-generate`}
        component={AnswerSheetBulkOrderList}
      />
      <Route path={`${path}/post-exam`} component={PostExam} />
      {VIDEO_RECORDING_ENABLED && (
        <Route
          path={`${path}/videos`}
          render={() => <DashboardVideos roomId={roomId}></DashboardVideos>}
        />
      )}
    </Switch>
  )
}

export default DashboardRouter
