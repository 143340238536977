import { IApplication } from 'types'

export const getStatusLabel = (status: string): string => {
  return (
    {
      AVAILABLE: 'Available',
      STARTED: 'Started',
      FINISHED: 'Finished',
      AVAILABLE_SOON: 'Available soon',
      UNAVAILABLE: 'Unavailable',
      STOPPED: 'Stopped'
    }[status] || 'Unknown'
  )
}

export const postAnswerVisualization = (application: IApplication) =>
  application.status === 'FINISHED' &&
  application?.exam?.collection?.postAnswerVisualization
