import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import { Rnd, RndResizeCallback } from 'react-rnd'

interface DraggableResizableModalProps {
  className?: string
  onAction: () => unknown
  isOpen: boolean
  defaultPosition?: {
    x: number
    y: number
  }
  onResize: RndResizeCallback
}

const DraggableResizableModal: React.FC<DraggableResizableModalProps> = ({
  children,
  className,
  onAction,
  isOpen,
  onResize,
  defaultPosition = { x: 0, y: 0 }
}) => {
  if (!isOpen) return <></>

  return (
    <Rnd
      className={className}
      default={{
        x: defaultPosition.x,
        y: defaultPosition.y,
        width: 400,
        height: 400
      }}
      bounds="#root"
      onResize={onResize}
    >
      <div className="handle">
        <div className="draggable-body">
          <Box display="flex" justifyContent="flex-end" paddingBottom="10px">
            <FontAwesomeIcon
              onClick={onAction}
              className="close-icon"
              icon={['fas', 'times']}
              size="2x"
            />
          </Box>
          {children}
        </div>
      </div>
    </Rnd>
  )
}

export default styled(DraggableResizableModal)`
  position: fixed;
  top: 5%;
  z-index: 103;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.196);

  .draggable-body {
    background: white;
    padding: 1rem;
  }

  .close-icon {
    cursor: pointer;
  }
`
