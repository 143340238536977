import React, { Fragment, useContext } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle, Button } from 'reactstrap'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get, map } from 'lodash'
// import Icon from 'react-fa';
import axios from 'axios'
// import Icon from 'react-fa';
import { API_HOST } from 'consts'
import queryString from 'query-string'
import { ThemeContext } from 'styled-components'
import { DropDownStyle, DropDownFixed } from './style'

class DropdownControlledDrawer extends React.Component {
  state = {
    isOpen: false,
    isHovering: false,
    menu: {}
  }
  static context = ThemeContext

  // FIXME: Corrigir isso para um módulo configuravel via admin por exemplo.
  // Estou fazendo isso para não ter que reconfigurar diversos menus
  // Não vai funcionar em dev...
  goToExternalLink = (link) => {
    const parser = document.createElement('a')
    parser.href = link

    if (parser.hostname.includes('exams')) {
      const pathName = `/external-application-middleware?${queryString.stringify(
        { applicationName: 'exams', path: parser.pathname, forceLogout: false }
      )}`
      window.open(pathName, '_self')
    } else {
      window.open(link, '_self')
    }
  }
  toggle = (url) => {
    const { isOpen } = this.state

    this.setState({ isOpen: !isOpen })

    const {
      location: { pathname }
    } = this.props
    if (pathname === url) {
      window.location.reload()
    }
  }
  fetchMenu() {
    axios.get(`${API_HOST}/menu/menu`).then((response) => {
      this.setState({ menu: response.data })
    })
  }

  handleMouseBillboardEnter = () => {
    this.setState({ isHovering: true })
  }

  handleMouseBillboardLeave = () => {
    this.setState({ isHovering: false })
  }

  handleMouseLeaveMenu = () => {
    this.setState({ isOpen: false })
  }

  handleMouseEnter = () => {
    this.setState({ isHovering: true })
  }

  handleMouseLeave = () => {
    this.setState({ isHovering: false })
  }

  componentDidMount() {
    this.fetchMenu()
    const { leave } = this.props
    if (!leave) {
      this.setState({ isOpen: false })
    }
  }

  mapOption = (option) => {
    const { menu, history } = this.props
    const isExternalLink = get(option, 'isExternalLink')

    if (!option.enabled) {
      return (
        <button
          key={option.id}
          className="dropdown-item children-menu disabled"
        >
          {option.name}
        </button>
      )
    }
    return (
      <Link
        key={option.id}
        className="dropdown-item children-menu"
        style={{ color: '#fff', wordWrap: 'break-word', maxWidth: '100px' }}
        onClick={() =>
          isExternalLink
            ? this.goToExternalLink(option.url)
            : this.toggle(option.url)
        }
        to={isExternalLink || option.url || '/'}
      >
        {option.name}
      </Link>
    )
  }

  mapSection = (section) => {
    const options = get(section, 'options') || []

    return (
      <Fragment key={section.id}>
        {section.name && (
          <span
            className="dropdown-item divider-menu"
            style={{ color: '#fff', textTransform: 'capitalize' }}
          >
            {section.name}
          </span>
        )}
        {map(options, this.mapOption)}
      </Fragment>
    )
  }

  render() {
    const { isOpen, isHovering } = this.state
    const { menu, drawer, leave, history } = this.props
    const { theme } = this.context
    const name = get(menu, 'name')
    const iconName = get(menu, 'iconName')
    const sections = get(menu, 'sections') || []
    const isExternalLink = get(menu, 'isExternalLink')

    return (
      <>
        {sections.length === 0 ? (
          <Dropdown
            className="nav"
            isOpen={isOpen}
            toggle={this.toggle}
            style={{ width: '100%', margin: '0' }}
            onMouseLeave={this.handleMouseLeaveMenu}
          >
            <DropDownStyle>
              <Button
                style={{
                  textAlign: 'left',
                  width: '100%',
                  justifyContent: 'flex-start',
                  borderRadius: '8px',
                  color: '#fff',
                  padding: '1rem',
                  marginTop: '4px'
                }}
                className="dropdown-toggle-container"
                onMouseOver={this.handleMouseBillboardEnter}
                onMouseLeave={this.handleMouseBillboardLeave}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    isExternalLink
                      ? this.goToExternalLink(menu.url)
                      : this.toggle(menu.url)
                  }}
                  to={isExternalLink || menu.url || '/'}
                >
                  <span
                    className={`icon-${iconName}`}
                    style={{
                      color: '#fff',
                      fontSize: '24px',
                      marginLeft: '0.3rem'
                    }}
                  ></span>
                  <span
                    style={{
                      opacity: drawer.length !== 0 ? '0' : '1',
                      marginLeft: drawer.length !== 0 ? '3.5rem' : '0.9rem',
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                  >
                    {name}
                  </span>
                </Link>
              </Button>
            </DropDownStyle>
          </Dropdown>
        ) : (
          <Dropdown
            className="nav"
            isOpen={isOpen}
            toggle={this.toggle}
            style={{ width: '100%', margin: '0' }}
          >
            <DropDownStyle isOpen={isOpen}>
              <DropdownToggle
                key={name}
                className={`dropdown-toggle-container  active-menu-drawer`}
                style={{
                  textAlign: 'left',
                  width: '100%',
                  borderRadius: '8px',
                  color: '#fff',
                  padding: '1rem',
                  marginTop: '4px'
                }}
                onMouseOver={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                caret
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <span
                    className={`icon-${iconName}`}
                    style={{
                      color: '#fff',
                      fontSize: '24px',
                      marginLeft: '0.3rem'
                    }}
                  ></span>
                  <span
                    style={{
                      fontFamily: 'Public Sans',
                      opacity: drawer ? '0' : '1',
                      marginLeft: '0.9rem',
                      color: '#fff',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                  >
                    {name}
                  </span>
                </div>
              </DropdownToggle>
            </DropDownStyle>

            <DropdownMenu
              className="dropdown-menu-drawer"
              style={{
                transition: 'all 0.2s',
                width: '100%',
                marginLeft: drawer ? '10px' : '0',
                th: '100%',
                display:
                  drawer.length != 0 || !isOpen
                    ? 'none'
                    : isOpen
                    ? 'contents'
                    : ''
              }}
            >
              <DropDownFixed>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingBottom: '8px',
                    borderRadius: '0 0 5px 5px'
                  }}
                >
                  {map(sections, this.mapSection)}
                </div>
              </DropDownFixed>
            </DropdownMenu>
          </Dropdown>
        )}
      </>
    )
  }
}

DropdownControlledDrawer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  menu: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
          }).isRequired
        ).isRequired
      }).isRequired
    ).isRequired
  }).isRequired
}

export default withRouter(DropdownControlledDrawer)
