import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { IExamDashboardApplication } from '../../types'
import {
  formatDateTimeString,
  diffBetweenHoursReadable
} from '../../utils/date'
import ExamInfoLabel from '../ExamInfoLabel'

type CardFooterProps = {
  application?: IExamDashboardApplication | undefined
  className?: string
  children?: ReactNode
  showTimeInfo?: boolean
}

const CardFooter = ({
  application,
  className,
  children,
  showTimeInfo = true
}: CardFooterProps) => {
  const startDate = application?.startedAt
    ? formatDateTimeString(application.startedAt)
    : undefined
  const endDate = application?.finishedAt
    ? formatDateTimeString(application.finishedAt)
    : undefined
  const timeOfExam = application
    ? diffBetweenHoursReadable(application.startedAt, application.finishedAt)
    : null

  const renderTime = () => {
    if (!timeOfExam) {
      return null
    }

    return `${timeOfExam?.hours}h ${timeOfExam?.minutes}min`
  }

  return (
    <div className={className}>
      {showTimeInfo && (
        <div className="icon">
          {<ExamInfoLabel label="Start time">{startDate}</ExamInfoLabel>}
          {<ExamInfoLabel label="End time">{endDate}</ExamInfoLabel>}
          {<ExamInfoLabel label="Time">{renderTime()}</ExamInfoLabel>}
        </div>
      )}
      {children}
    </div>
  )
}

export default styled(CardFooter)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 1rem;
  align-items: flex-start;
  font-size: 12px;

  .icon {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 55%;
    column-gap: 8px;
  }
`
