import { Formik, FormikValues } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import LightBorderCard from '../components/Card/LightBorderCard'
import Page from '../components/Page'
import PromiseButton from '../components/PromiseButton'
import { API_HOST } from '../consts'
import usePreference from 'hooks/usePreference'
import { AuthContext } from '../states/AuthState'
import { IHierarchy, IStringPreference } from '../types'
import { downloadFile } from '../utils/files'
import HierarchyInputSelect from 'components/Inputs/HierarchyInputSelect'
import CollectionInput from 'components/Inputs/CollectionInput'
import { toast } from 'react-toastify'

const initialValues: FormikValues = {}

const PerformanceByHierarchy = () => {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const requiredHierarchiesP = usePreference<IStringPreference>(
    'Application__RequiredFiltersCollectionResult'
  )
  const requiredHierarchies = requiredHierarchiesP?.value
    ? requiredHierarchiesP?.value.split(',')
    : []

  const mapHierarchy = (hierarchy: {
    [key: string]: IHierarchy | undefined
  }) => {
    if (!hierarchy) {
      return undefined
    }

    let categories = hierarchy.hierarchies[0].codename
    for (let x = 1; x in hierarchy.hierarchies; x++) {
      categories = categories + ',' + hierarchy.hierarchies[x].codename
    }

    return isEmpty(categories) ? undefined : categories
  }

  const mapFilters = (values: FormikValues) => {
    const { collection, ...hierarchies } = values
    return {
      collection: get(collection, 'id'),
      hierarchies: mapHierarchy(hierarchies)
    }
  }

  const handleSubmit = async (values: FormikValues) => {
    console.log(values)
    if (values.hierarchies.length > 0) {
      return downloadFile({
        url: `${API_HOST}/v1/hierarchies_performance`,
        params: {
          format: 'excel',
          ...mapFilters(values)
        }
      })
    } else {
      toast.error('Selecione uma categoria para continuar')
    }
  }

  return (
    <Page>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <LightBorderCard title="Preencha os campos abaixo para exportar as notas">
            <CollectionInput />
            <HierarchyInputSelect provider={user?.provider?.codename} />
            <PromiseButton
              icon="download"
              action="secondary"
              onClick={() => formik.submitForm()}
              disabled={requiredHierarchies.some(
                (d) => formik.values[d] === null
              )}
            >
              {t('Grades').toUpperCase()}
            </PromiseButton>
          </LightBorderCard>
        )}
      </Formik>
    </Page>
  )
}

export default PerformanceByHierarchy
