import { Link, LinkProps } from 'react-router-dom'
import styled, { StyledProps } from 'styled-components'
import CircledSpan from 'components/CircledSpan'
import React from 'react'

type Props = StyledProps<any> &
  LinkProps & {
    disabled?: boolean
  }

const CircledLink = ({ className, children, ...rest }: Props) => (
  <Link className={className} {...rest}>
    <CircledSpan>{children}</CircledSpan>
  </Link>
)

export default styled<Props>(CircledLink)`
  text-decoration: none;

  ${CircledSpan} {
    min-width: 2rem;
    min-height: 2rem;

    :hover {
      background-color: ${(props) => props.theme.clientColors.primary};
      color: ${(props) => props.theme.colors.grayLight};
    }

    :visited,
    :not(:visited) {
      color: ${(props) => props.theme.clientColors.primary};
    }
  }
`
