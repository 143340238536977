import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Tag from '../Tag'

type VideoProcessStatusProps = {
  videoProcessStatus: any
  className?: string
}

const VideoProcessStatus = ({
  videoProcessStatus,
  className
}: VideoProcessStatusProps) => {
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (videoProcessStatus) {
      setStatus(videoProcessStatus)
    }
  }, [videoProcessStatus, setStatus])

  const color = () => {
    return (
      {
        ERROR: '#bf2727',
        TO_PROCESS: '#BF6827',
        COMPLETED: '#63B288',
        PROCESSING: '#1A3A60'
      }[status] || 'Unknown'
    )
  }

  const getStatusLabel = (status: string): string => {
    return (
      {
        PROCESSING: 'Processando vídeos',
        ERROR: 'Ocorreu um erro',
        TO_PROCESS: 'Aguardando processamento',
        COMPLETED: 'Finalizado o processamento'
      }[status] || 'Unknown'
    )
  }
  return (
    <Tag color={color()} className={className}>
      {getStatusLabel(status)}
    </Tag>
  )
}

export default styled(VideoProcessStatus)`
  padding: 0.6rem;
  font-size: 16px;
`
