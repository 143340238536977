import Fade from 'components/Fade'
import SeeAnswersLink from 'components/SeeAnswersLink'
import { createItemAccess } from 'data/apis/message-exchange'
import { postAnswerVisualization } from 'data/utils/applications'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import Button from '../components/Button'
import InstructionsCard from '../components/Card/InstructionsCard'
import Error from '../components/Error'
import Html from '../components/Html'
import RemainingTime from '../components/RemainingTime'
import ApplicationToolbar from '../components/ApplicationToolbarComponents/ApplicationToolbar'
import { ApplicationContext } from '../states/ApplicationState'
import { AuthContext } from '../states/AuthState'
import { formatDateTimeString, formatDuration } from '../utils/date'
import { useHistory } from 'react-router-dom'
import { API_HOST, EXAMS_ORAL_URL, FIREBASE_ENABLED } from 'consts'
import { getToken } from 'utils/auth'
import axios from 'axios'

type InstructionsProps = {
  className?: string
}

const Instructions = ({ className }: InstructionsProps) => {
  const {
    application,
    startApplication,
    resumeApplication,
    fetchingApplication,
    startingApplication,
    startApplicationError,
    secondsToTimeout,
    remainingTimeInitialDate,
    fetchApplication
  } = useContext(ApplicationContext)
  const { user, hasGroup } = useContext(AuthContext)
  const { t } = useTranslation()
  const history = useHistory()

  const exam = application?.exam
  const collection = exam?.collection
  const timeWindows = exam?.timeWindows || []
  const timeWindow = timeWindows?.length > 0 ? timeWindows[0] : undefined
  const timeAllowedPreview = formatDuration((timeWindow?.maxDuration || 0) * 60)

  useEffect(() => {
    const fetchInterval = 1000 * 10
    const intervalId = setInterval(fetchApplication, fetchInterval)

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [application, fetchApplication])

  useEffect(() => {
    if (!application || !user || !FIREBASE_ENABLED) {
      return
    }
    createItemAccess(
      `${application?.roomId}`,
      `${user?.id}`,
      `${application?.id}`,
      exam.name,
      {
        itemId: '0',
        position: 0
      },
      true
    )
  }, [application, user, exam])

  const renderWelcomeMessage = () => {
    if (fetchingApplication) {
      return (
        <p>
          <Skeleton width={200} height={15} />
        </p>
      )
    }

    if (hasGroup('RESPONSIBLE')) {
      return ''
    } else {
      const messages = {
        STARTED() {
          const startedAt = application?.startedAt
            ? formatDateTimeString(application.startedAt)
            : '-'
          return (
            <div className="wrapper-text">
              <p>
                {t('You started your exam at {{time}}.', { time: startedAt })}
              </p>
              <p>
                Tempo restante para a entrega:{' '}
                <strong>
                  <RemainingTime
                    initial={remainingTimeInitialDate}
                    secondsToTimeout={secondsToTimeout}
                    allowHideRemainingTime={false}
                  />{' '}
                </strong>
              </p>
              {/* <p>
                <strong>
                  <RemainingTime
                    initial={remainingTimeInitialDate}
                    secondsToTimeout={secondsToTimeout}
                    allowHideRemainingTime={false}
                  />
                </strong>{' '}
                {t('left to submit it.')}
              </p> */}
            </div>
          )
        },

        FINISHED() {
          return <p>{t('This exam has already been submitted.')}</p>
        },

        STOPPED() {
          return <p>{t('This exam has been stopped. Please wait.')}</p>
        },

        AVAILABLE() {
          return (
            <div className="message-starting-exam">
              <p>
                {t('After starting your exam, you will have')}
                <strong> {timeAllowedPreview} </strong>
                {t('to submit it', { context: 'fs' })}
              </p>
            </div>
          )
        },

        AVAILABLE_SOON() {
          return <p>{t('This exam will be available soon.')}</p>
        },

        UNAVAILABLE() {
          return (
            <div className="message-starting-exam">
              <p>
                {t('After starting your exam, you will have')}
                <strong> {timeAllowedPreview} </strong>
                {t('to submit it', { context: 'fs' })}
              </p>
            </div>
          )
        },

        UNKNOWN() {
          return <p>{t('Exam status is unknown. Please wait.')}</p>
        }
      }

      return (messages[application?.status || 'UNKNOWN'] || messages.UNKNOWN)()
    }
  }

  const BackToApplications = () => {
    history.push('/applications')
  }

  const renderApplicationToolBar = () => {
    if (collection?.allowInstructionsCommunication) {
      return <ApplicationToolbar collection={collection} />
    }
  }

  const renderGoToAnswersButton = () => {
    if (hasGroup('RESPONSIBLE')) {
      return ''
    } else {
      const buttons = {
        STARTED() {
          return (
            <Button
              className="backButton2"
              action="primary"
              isLoading={startingApplication}
              onClick={() => resumeApplication('')}
            >
              {t('Continue')}
            </Button>
          )
        },

        FINISHED() {
          return (
            postAnswerVisualization(application) && (
              <SeeAnswersLink applicationId={application?.id} />
            )
          )
        },

        STOPPED() {
          return null
        },

        AVAILABLE() {
          return (
            <Button
              className="backButton2"
              action="primary"
              isLoading={startingApplication}
              onClick={startApplication}
            >
              {t('start')}
            </Button>
          )
        },

        AVAILABLE_SOON() {
          return null
        },

        UNAVAILABLE() {
          return (
            <Button disabled action="primary">
              {t('Unavailable')}
            </Button>
          )
        },

        UNKNOWN() {
          return null
        }
      }
      return (buttons[application?.status || 'UNKNOWN'] || buttons.UNKNOWN)()
    }
  }

  useEffect(() => {
    const userToken = getToken()
    if (application?.exam?.examsOral && application?.status === 'AVAILABLE') {
      axios.post(
        `${API_HOST}/v1/applications/${application.id}/create_waiting_queue`,
        {
          application: application.id
        }
      )
      window.location.replace(`${EXAMS_ORAL_URL}redirect/${userToken}`)
    }
  }, [application])
  return (
    <Fade>
      <div className={className}>
        <div className="exam-welcome">
          <div style={{ marginBottom: '2.5rem' }}>
            <Button
              className="backButton"
              action="primary"
              onClick={BackToApplications}
            >
              {t('back to applications')}
            </Button>
          </div>
          <h1>Olá, {user?.name}</h1>
          {renderWelcomeMessage()}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {renderGoToAnswersButton()}
          </div>
          {renderApplicationToolBar()}
          {startApplicationError && (
            <p>
              <Error>{startApplicationError}</Error>
            </p>
          )}
        </div>
        <div className="instructions">
          <InstructionsCard>
            <p>
              <strong>{application?.exam.name}</strong>
            </p>
            <h3>{t('Instructions')}</h3>
            <span>
              {fetchingApplication && !application ? (
                <Skeleton height={200} />
              ) : (
                <Html>
                  {exam?.instructions || t('No instructions available')}
                </Html>
              )}
            </span>
          </InstructionsCard>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '2rem',
              marginTop: '2rem'
            }}
          >
            {renderGoToAnswersButton()}
          </div>
          {startApplicationError && (
            <p>
              <Error>{startApplicationError}</Error>
            </p>
          )}
        </div>
      </div>
    </Fade>
  )
}

export default styled(Instructions)`
  background: white;
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 2rem;

  .exam-welcome {
    max-width: 50rem;
    margin: auto;
    text-align: center;
    display: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    h1 {
      color: #333333;
      font-size: 24pt;
      margin-top: 0px;
      font-weight: 600;
    }

    p {
      color: #333333;
      font-size: 12pt;
    }
  }

  .backButton,
  .backButton2 {
    background: ${(props) => props.theme.clientColors.instructionsButton};
    border: 1px solid ${(props) => props.theme.clientColors.instructionsButton};
  }

  .instructions {
    max-width: 50rem;
    margin: auto;
    margin-top: 0.5rem;
    text-align: center;
    display: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    h1 {
      color: #333333;
      font-size: 24pt;
      margin-top: 0px;
      font-weight: 600;
    }

    p {
      color: $font-color;
      font-size: 12pt;
    }
  }

  .message-starting-exam {
    p {
      color: $font-color;
      font-size: 22px;
    }
  }

  button {
    margin: 0 0.9rem;
  }

  @media (max-width: 600px) {
    .wrapper-text {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      max-width: 400px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`
