import React, { useReducer, createContext } from 'react'
import { xor } from 'lodash'
import { childrenIsFunction } from '../types'
import RawCheckbox from '../components/Inputs/RawCheckbox'

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_CHECKBOX':
      if (state.allChecked) {
        return {
          ...state,
          allCheckedExceptions: xor(state.allCheckedExceptions, [
            action.payload
          ])
        }
      }
      return {
        ...state,
        checked: xor(state.checked, [action.payload])
      }
    case 'CHECK_ALL':
      return {
        ...state,
        checked: [],
        allCheckedExceptions: [],
        allChecked: !state.allChecked
      }
    default:
      return state
  }
}

export const CheckboxListContext = createContext({
  renderCheckbox: (value) => value
})

type CheckboxListStateProps = {
  children: any
}

const CheckboxListState = ({ children }: CheckboxListStateProps) => {
  const initialState = {
    checked: [],
    allCheckedExceptions: [],
    allChecked: false
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const onCheckboxChange = (payload) => {
    dispatch({ type: 'CHANGE_CHECKBOX', payload })
  }

  const onCheckAll = () => {
    dispatch({ type: 'CHECK_ALL' })
  }

  const isChecked = (cmp) => {
    const { allChecked, checked, allCheckedExceptions } = state
    return allChecked ? !allCheckedExceptions.some(cmp) : checked.some(cmp)
  }

  const getCheckedCount = (count) => {
    const { allChecked, checked, allCheckedExceptions } = state
    return allChecked ? count - allCheckedExceptions.length : checked.length
  }

  const renderCheckAllCheckbox = (count) => {
    const checkedCount = getCheckedCount(count)
    return (
      <div className="d-flex align-items-center">
        <RawCheckbox
          onChange={onCheckAll}
          checked={state.allChecked}
          className="font-weight-600"
          label="Selecionar todos"
        />
        <span
          className={checkedCount > 0 ? 'gray-light' : 'gray-dark'}
        >{`${checkedCount} ${
          checkedCount === 1 ? 'item' : 'objetos de aprendizagem'
        } selecionados`}</span>
      </div>
    )
  }

  const renderCheckbox = (value) => (
    <RawCheckbox
      onChange={() => onCheckboxChange(value)}
      checked={isChecked((d) => d === value)}
    />
  )

  const contextValue = {
    ...state,
    onCheckboxChange,
    onCheckAll,
    isChecked,
    getCheckedCount,
    renderCheckAllCheckbox,
    renderCheckbox
  }

  return (
    <CheckboxListContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </CheckboxListContext.Provider>
  )
}

export default CheckboxListState
