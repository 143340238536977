import styled from 'styled-components'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import SelectionProcessResultCardStat from './SelectionProcessResultCardStat'
import devices from 'utils/devices'
import { SelectionProcessResult } from 'data/domain/selection-processes'
import { Alert, AlertTitle, Color } from '@material-ui/lab'
import Button from './Button'
import { ModalContext } from 'states/ModalState'
import OpenAppealUserResultModal from './OpenAppealUserResultModal'
import UserResulAppealDetail from './UserResulAppealDetail'
import axios from 'axios'
import { API_HOST } from 'consts'

type Props = SelectionProcessResult & {
  className?: string
}

const SelectionProcessResultCard = ({ className, ...rest }: Props) => {
  const { showModal } = useContext(ModalContext)
  const [appeal, setAppeal] = useState(undefined)
  const userResultId = rest?.id

  const getSeverity = (category: string): Color => {
    if (['success', 'info', 'warning', 'error'].includes(category)) {
      return category as Color
    }

    return 'info'
  }

  const fetchAppealsUserResult = useCallback(async (userResult): Promise<
    void
  > => {
    const response = await axios.get(
      `${API_HOST}/v1/appeals/list_by_user?user_result=${userResult}`
    )
    setAppeal(response?.data)
    return response?.data
  }, [])

  const renderAppealButtons = () => {
    if (appeal?.count > 0) {
      return (
        <>
          <Button
            action="primary"
            type="button"
            onClick={() =>
              showModal(() => (
                <UserResulAppealDetail data={rest} appeal={appeal?.results} />
              ))
            }
          >
            Visualizar recurso
          </Button>
        </>
      )
    } else {
      return (
        <>
          <Button
            action="primary"
            type="button"
            onClick={() =>
              showModal(() => (
                <OpenAppealUserResultModal
                  data={rest}
                  refetchAppeals={() => fetchAppealsUserResult(userResultId)}
                />
              ))
            }
          >
            Abrir recurso
          </Button>
        </>
      )
    }
  }

  useEffect(() => {
    if (appeal === undefined) {
      fetchAppealsUserResult(userResultId)
    }
  }, [appeal, fetchAppealsUserResult, userResultId])

  return (
    <Alert
      severity={getSeverity(rest.category)}
      className={className}
      icon={false}
    >
      <AlertTitle>
        <strong>Resultado {rest.selectionProcessName}</strong>
        <small style={{ display: 'block' }}>{rest.sectionName}</small>
      </AlertTitle>
      <div className="card-content">
        {/* <SelectionProcessResultCardStat
          label="Acertos"
          value={`${rest.numCorrectAnswers}/${rest.numItems} (${rest.pctCorrectAnswers}%)`}
        /> */}
        {rest.value !== null && rest.value !== undefined && rest.showGrade && (
          <SelectionProcessResultCardStat
            label="Pontuação"
            value={`${rest.value}/${rest.totalValue} (${rest.valuePercentage}%)`}
          />
        )}
        {rest.showStatus && (
          <SelectionProcessResultCardStat
            label="Situação"
            value={rest.status}
            color={rest.color}
          />
        )}
        {rest.passingScore && (
          <SelectionProcessResultCardStat
            label="Nota de corte"
            value={rest.passingScore}
          />
        )}
      </div>
      <div>
        {rest.shouldShowAppealButton && (
          <div className="buttons">{renderAppealButtons()}</div>
        )}
      </div>
    </Alert>
  )
}

export default styled(SelectionProcessResultCard)`
  ${(props) =>
    props.category === 'disabled' &&
    `
      background-color: #d3d3d3 !important;
      color: black !important;
    `}

  .card-content {
    display: grid;
    column-gap: 2em;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;

    ${Button} {
      margin-top: 5px;
      width: 200px;
    }

    @media (max-width: ${devices.tablet}) {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: unset;
      row-gap: 10px;
    }
  }
`
