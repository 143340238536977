import { useCallback } from 'react'
import { BreakRequest } from 'data/domain/message-exchange'
import { getNotAnsweredBreakRequests } from 'data/apis/message-exchange'
import useFirebaseCollection from './useFirebaseCollection'

function useNotAnsweredBreakRequests(roomId: string | undefined) {
  const _getNotAnsweredBreakRequests = useCallback(() => {
    if (!roomId) {
      return null
    }
    return getNotAnsweredBreakRequests(roomId)
  }, [roomId])

  const { collection: breakRequests } = useFirebaseCollection<BreakRequest>(
    _getNotAnsweredBreakRequests
  )

  return { breakRequests }
}

export default useNotAnsweredBreakRequests
