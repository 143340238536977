import axios from 'axios'
import { API_HOST } from 'consts'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IApplication } from 'types'
import InputMask from 'react-input-mask'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import repertorizationManager from 'managers/repertorizationManager'
import { Rubric } from 'data/domain/repertorization'

interface RepertorizationTableProps {
  className?: string
  application?: IApplication
}

const RepertorizationTable = ({
  className,
  application
}: RepertorizationTableProps) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [repertorizationItems, setRepertorizationItems] = useState([])
  const [rubrics, setRubrics] = useState([
    {
      item: undefined,
      position: undefined,
      value: undefined
    }
  ])

  const formatValue = (value) => {
    const formattedValue = parseInt(value)
    if (formattedValue >= 1 && formattedValue <= 3) {
      return formattedValue
    }
    return 0
  }

  const handleRubricChange = useCallback(
    async (item, position, value) => {
      let formattedValue = formatValue(value)
      if (value > 3) {
        toast.error(t('Rubric value must be between 1 and 3'))
        return
      } else if (formattedValue === 0) {
        formattedValue = null
      }

      setRubrics((rs) => {
        const existing_rubrics = rs.map((r) => {
          if (r.item === item && r.position === position) {
            return {
              ...r,
              value
            }
          }

          return r
        })
        if (!rs.find((r) => r.item === item && r.position === position)) {
          existing_rubrics.push({
            item,
            position,
            value
          })
        }
        return existing_rubrics
      })

      repertorizationManager.saveRubric({
        item: item,
        position: position,
        value: formattedValue,
        application: application.id
      } as Rubric)
    },
    [application, t]
  )

  const handleLoad = useCallback(async () => {
    await axios
      .get(`${API_HOST}/v1/repertorization_items`)
      .then((response) => setRepertorizationItems(response.data))
    const params = {
      application: application.id
    }
    await axios
      .get(`${API_HOST}/v1/rubrics`, { params })
      .then((response) => setRubrics(response.data))
  }, [application])

  const getRubricValue = useCallback(
    (item: any, position: number) => {
      const rubricValue = rubrics.find(
        (r) => r.item === item.id && r.position === position
      )
      return rubricValue?.value || null
    },
    [rubrics]
  )

  const getItemCover = useCallback(
    (item_id: number) => {
      const rubrics_clone = rubrics
      const itemRubrics = rubrics_clone.filter(
        (r) => r.item === item_id && formatValue(r.value)
      )

      return itemRubrics.length
    },
    [rubrics]
  )

  const getItemSum = useCallback(
    (item_id: number) => {
      const sum_rubrics = (partial_sum, rubric) => {
        return partial_sum + formatValue(rubric.value)
      }

      const rubrics_clone = rubrics
      const itemRubrics = rubrics_clone.filter((r) => r.item === item_id)
      return itemRubrics.reduce(sum_rubrics, 0)
    },
    [rubrics]
  )

  useEffect(() => {
    handleLoad()
    setLoading(false)
  }, [handleLoad])

  return (
    <div className={className}>
      <table>
        <tr>
          <th>{t('Medicament')}</th>
          {Array(10)
            .fill(0)
            .map((_, i) => (
              <th key={i + 1}>{i + 1}</th>
            ))}
          <th>{t('Cover')}</th>
          <th>{t('Value')}</th>
        </tr>
        {!loading &&
          repertorizationItems.map((item, i) => (
            <tr key={i + 1}>
              <td key={i + 1}>{item.description}</td>
              {Array(item.rubricsLength)
                .fill(0)
                .map((_, i) => (
                  <td key={i + 1}>
                    <InputMask
                      alwaysShowMask
                      maskChar=""
                      mask="9"
                      value={getRubricValue(item, i)}
                      onChange={(evt: any) =>
                        handleRubricChange(item.id, i, evt.target.value)
                      }
                    />
                  </td>
                ))}
              <td className="item-cover">{getItemCover(item.id)}</td>
              <td className="item-sum">{getItemSum(item.id)}</td>
            </tr>
          ))}
      </table>
    </div>
  )
}

export default styled(RepertorizationTable)`
  padding-top: 1rem;
  input {
    width: 80%;
    text-align: center;
  }
  table {
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }
  .item-cover {
    text-align: center;
  }
  .item-sum {
    text-align: center;
  }
  .toggle-button {
    display: flex;
    justify-content: flex-end;
  }
`
