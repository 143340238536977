import firebase, { db } from 'infra/firebase'
import { uploadBase64 } from './file-storage'
import { DocumentPictures } from 'data/domain/document-pictures'
import { RollbarErrorTracking } from 'infra/rollbar'
import { v4 as uuidv4 } from 'uuid'

export const getCandidateDocuments = (
  candidateId: string,
  date: Date | string
) => {
  return db
    .collection('documentsByCandidate')
    .doc(candidateId)
    .collection('allDocuments')
    .where('candidateId', '==', candidateId)
    .where('dateUploaded', '>=', date)
    .get()
}

export const getCandidateDocumentsAsArray = async (
  candidateId: string,
  date: Date
): Promise<DocumentPictures[]> => {
  const startDate = new Date(date)
  startDate.setHours(0)
  startDate.setMinutes(0)
  startDate.setMilliseconds(0)
  startDate.setSeconds(0)

  const endDate = new Date(date)
  endDate.setHours(23)
  endDate.setMinutes(59)
  endDate.setMilliseconds(999)
  endDate.setSeconds(59)

  const documents = await db
    .collection('documentsByCandidate')
    .doc(candidateId)
    .collection('allDocuments')
    .where('candidateId', '==', candidateId)
    .where('dateUploaded', '>=', startDate)
    .where('dateUploaded', '<=', endDate)
    .get()

  if (!documents.docs.length) return []
  return documents.docs.map((doc) => doc.data() as DocumentPictures)
}

export const updateCandidateDocument = async (
  document: DocumentPictures,
  userId: number,
  userName: string
) => {
  const docRef = await getCandidateDocuments(
    document.candidateId,
    document.dateUploaded
  )
  await docRef.docs[0]?.ref.update({
    ...document,
    confirmedBy: { id: userId, name: userName },
    confirmedWhen: firebase.firestore.FieldValue.serverTimestamp()
  })
}

export const uploadCandidateDocuments = async (
  candidateId: string,
  documents: Array<string>
) => {
  if (documents.length !== 2) {
    throw new Error(
      'Invalid argument. Documents must be an array with the first element being the documentPicture, and the second element being the documentWithFace picture.'
    )
  }
  const folderName = 'documentPictures'

  const documentPicture = documents[0]
  const documentWithFacePicture = documents[1]

  const documentPictureToken: any = {}
  const documentFacePictureToken: any = {}

  // cancels document uploading after 15 seconds
  setTimeout(() => {
    documentPictureToken.cancel()
    documentFacePictureToken.cancel()
  }, 15000)

  let [documentPictureUrl, documentPictureWithFaceUrl] = await Promise.all([
    uploadBase64(
      candidateId,
      `${folderName}/documentPicture-${uuidv4()}.jpg`,
      documentPicture,
      documentPictureToken
    ),
    uploadBase64(
      candidateId,
      `${folderName}/documentPictureWithFace-${uuidv4()}.jpg`,
      documentWithFacePicture,
      documentFacePictureToken
    )
  ]).catch((e) => {
    RollbarErrorTracking.logError(e)
    return ''
  })

  if (!documentPictureUrl) {
    documentPictureUrl = 'not_uploaded'
  }
  if (!documentPictureWithFaceUrl) {
    documentPictureWithFaceUrl = 'not_uploaded'
  }

  const documentUrls = {
    candidateId,
    documentPictureUrl,
    documentPictureWithFaceUrl,
    dateUploaded: firebase.firestore.FieldValue.serverTimestamp(),
    confirmedWhen: null,
    confirmedBy: null,
    compatibility: null
  }

  const mostRecentDocumentRef = db
    .collection('documentsByCandidate')
    .doc(candidateId)
  const lastAddedDocumentRef = db
    .collection('documentsByCandidate')
    .doc(candidateId)
    .collection('allDocuments')
    .doc()

  db.runTransaction(async (t) => {
    t.set(mostRecentDocumentRef, documentUrls, { merge: true })
    t.set(lastAddedDocumentRef, documentUrls)
  })
}
