import AnswerSheetItemContent from 'pages/AnswerSheet/components/AnswerSheetItemContent'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ItemTitle from 'pages/AnswerSheet/components/ItemTitle'
import axios from 'axios'
import { API_HOST } from 'consts'
import { AnswerSheet, AnswerSheetItem } from 'data/domain/exams'
import { get } from 'lodash'

type QuestionModalProps = {
    answerSheet?: AnswerSheet
    item: any
    examId: number
}

const MultiQuestionModal = ({
    answerSheet,
    item,
    examId
}: QuestionModalProps) => {


    const [itemContent, setItemContent] = useState(undefined)

    
    const fetchItem = async (itemId: number) => {
        const response = await axios.get(`${API_HOST}/v1/items/${itemId}`)
        setItemContent(response.data)
    }
    
    useEffect(() => {
        if (item && examId) {
            fetchItem(item)
        }
    }, [item, examId])

    if (!answerSheet) return <></>

    return (
        <div>
            <div style={{marginTop:'30px'}}></div>
            <div style={{backgroundColor:'#ffff', padding:'1rem'}}>
                <div className="title-wrapper">
                    <div className="item-wrapper">
                        <ItemTitle className="colorText"style={{
                            fontFamily: 'Public Sans',
                            fontSize: '18px',
                            fontWeight: 500,
                            lineHeight: '18.8px',
                            letterSpacing: '0em',
                            textAlign: 'left',  
                        }}>Código: {item}</ItemTitle>
                    </div>
                    {get(itemContent,'habilidade') ? (
                    <div className="item-wrapper">
                        <ItemTitle style={{
                            color:' #262930',
                            fontFamily: 'Public Sans',
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            letterSpacing: '0em',
                            textAlign: 'left',  
                        }}>Habilidade:</ItemTitle>
                        <div style={{
                            backgroundColor:' #FBFBFB',
                            padding: '20px',
                            marginTop: '8px',
                            marginBottom:'16px',
                            borderRadius:'4px'
                        }}>
                            <ItemTitle style={{
                                color:' #262930',
                                fontFamily: 'Public Sans',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                letterSpacing: '0em',
                                textAlign: 'left',  
                            }}>{get(itemContent,'habilidade')}</ItemTitle>
                        </div>
                    </div>
                    ): (
                        <></>
                    )}
                </div>
                <AnswerSheetItemContent
                    item={itemContent}
                    correctAnswer={
                        answerSheet.find((i: AnswerSheetItem) => i.itemId === item)?.answer
                    }
                    correctBulkAnswer={[]}
                    isCancelled={
                        answerSheet.find((i: AnswerSheetItem) => i.itemId === item)
                            ?.isCancelled
                    }
                    postAlternativeExplanationVisualization={false}
                />
            </div>
        </div>
    )
}

export default styled(MultiQuestionModal)`
  .colorText {
    color: #000;
  }

  .item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
  }
`
