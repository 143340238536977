import React, { useContext } from 'react'
import styled from 'styled-components'
import { IUser } from '../../types'
import Card from '../Card/Card'
import { CheckboxListContext } from '../../states/CheckboxListState'
import CardSubtitle from '../ExamDashboardComponents/CardSubtitle'
import CardTitle from '../ExamDashboardComponents/CardTitle'

type RecipientCardProps = {
  className?: string
  user: IUser
}

const RecipientCard = ({ className, user }: RecipientCardProps) => {
  const { renderCheckbox } = useContext(CheckboxListContext)

  return (
    <Card className={className}>
      <div>
        <CardTitle>
          {renderCheckbox(user.id)}
          {user.name}
        </CardTitle>
        <CardSubtitle>{user.email}</CardSubtitle>
      </div>
    </Card>
  )
}

export default styled(RecipientCard)``
