import {
  faPauseCircle,
  faRecordVinyl
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import useQuery from 'hooks/useQuery'
import { RollbarErrorTracking } from 'infra/rollbar'
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { API_HOST } from '../../consts'
import { AuthContext } from '../../states/AuthState'
import { ICollection } from '../../types'
import Button from '../Button'
import Page from '../Page'
import AddStudentModal from './AddStudentModal'
import DashboardRouter from './DashboardRouter'
import ExamDashboardExamInfo from './ExamDashboardExamInfo'
import RoomHeader from './RoomHeader'
import RoomRecordModal from './RoomRecordModal'
import Separator from './Separator'
import { collectionIsFinished } from '../../data/utils/collections'
import proctorScreenManager from 'components/Recording/screenRecordingProctor'

type HeaderProps = {
  children?: ReactNode
  className?: string
  match?: any
}

const Header = ({ className, match }: HeaderProps) => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const location = useLocation()
  const query = useQuery()

  const { user, hasGroup, perms } = useContext(AuthContext)
  const [room, setRoom] = useState(null)
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false)
  const [isRoomRecordModalOpen, setIsRoomRecordModalOpen] = useState(false)
  const [buttonName, setButtonName] = useState('record')
  const [collection, setCollection] = useState<ICollection>(undefined)

  const isOnVideos = location.pathname.includes('/videos')

  const collectionId = +(query.get('collection') || '')
  const roomId = +room?.id || -1
  const fetchCollection = useCallback(async () => {
    if (!collectionId) {
      return
    }
    try {
      const response = await axios.get(
        `${API_HOST}/v1/collections/${collectionId}`
      )
      const collection = response.data
      setCollection(collection)
    } catch (e) {
      RollbarErrorTracking.logError(e)
    }
  }, [collectionId])

  const sendProctorStatus = async (data) => {
    if (roomId !== -1) {
      await axios
        .put(`${API_HOST}/v1/rooms/${roomId}`, { is_recording: data })
        .then(() => {
          console.log('Success')
        })
        .catch((err) => console.log(err))
    }
  }

  window.addEventListener('beforeunload', () => {
    if (hasGroup('PROCTOR')) {
      sendProctorStatus(false)
    }
  })

  const getDisplayStream = () => {
    navigator.mediaDevices
      // eslint-disable-next-line
      // @ts-ignore
      .getDisplayMedia({ video: { width: 1366, height: 768 }, audio: true })
      .then((stream) => {
        proctorScreenManager.startRecordingForUser(stream, user, roomId)
        sendProctorStatus(true)
        setButtonName('recording')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const stopRecording = () => {
    window.location.reload()
  }

  const recordScreenButton = () => {
    if (buttonName === 'record') {
      return (
        <Button
          className="record"
          onClick={() => {
            getDisplayStream()
          }}
        >
          <FontAwesomeIcon
            className="icon"
            icon={faRecordVinyl}
            size="2x"
          ></FontAwesomeIcon>
          Gravar Tela
        </Button>
      )
    } else {
      return (
        <Button
          className="recording"
          onClick={() => {
            stopRecording()
          }}
        >
          <FontAwesomeIcon
            className="icon"
            icon={faPauseCircle}
            size="2x"
          ></FontAwesomeIcon>
          Parar Gravação
        </Button>
      )
    }
  }

  useEffect(() => {
    fetchCollection()
  }, [fetchCollection])

  useEffect(() => {
    if (match.path === '/exams/dashboard-open') {
      setIsAddStudentModalOpen(true)
    }
  }, [match.path])

  return (
    <Page className={className} customWidth={1366}>
      <div className="sub-info">
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className="nav-links"
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignContent="center"
          >
            <ExamDashboardExamInfo
              collection={collection}
              hasGroup={(group: string) => hasGroup(group)}
              setIsAddStudentModalOpen={setIsAddStudentModalOpen}
            />
          </Grid>

          <Separator className="main-separator" />

          <Grid
            item
            container
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '1rem' }}
          >
            {perms?.Dashboard?.Statistics?.Enable && (
              <Grid item container justifyContent="center" xs={12} sm={3}>
                <NavLink
                  activeClassName="active"
                  to={`${url}/statistics?collection=${collectionId}`}
                >
                  <span style={{ fontSize: '20px', fontWeight: 400 }}>
                    {t('Statistics')}
                  </span>
                </NavLink>
              </Grid>
            )}
            {perms?.Dashboard?.List?.Enable && (
              <Grid item container justifyContent="center" xs={12} sm={2}>
                <NavLink
                  activeClassName="active"
                  to={`${url}/list?collection=${collectionId}`}
                >
                  <span style={{ fontSize: '20px', fontWeight: 400 }}>
                    {t('Students List')}
                  </span>
                </NavLink>
              </Grid>
            )}
            {perms?.Dashboard?.List?.Enable && (
              <Grid item container justifyContent="center" xs={12} sm={3}>
                <NavLink
                  activeClassName="active"
                  to={`${url}/view?collection=${collectionId}`}
                >
                  <span style={{ fontSize: '20px', fontWeight: 400 }}>
                    Visualização
                  </span>
                </NavLink>
              </Grid>
            )}
            {collectionIsFinished(collection) && (
              <Grid item container justifyContent="center" xs={12} sm={4}>
                <NavLink
                  activeClassName="active"
                  to={`${url}/items?collection=${collectionId}`}
                >
                  <span style={{ fontSize: '20px', fontWeight: 400 }}>
                    {' '}
                    {t('Items List')}
                  </span>
                </NavLink>
              </Grid>
            )}
            {hasGroup('ADMINISTRADOR') && (
              <Grid item container justifyContent="center" xs={12} sm={2}>
                <NavLink
                  activeClassName="active"
                  to={`${url}/post-exam?collection=${collectionId}`}
                >
                  <span style={{ fontSize: '20px', fontWeight: 400 }}>
                    {t('See results')}
                  </span>
                </NavLink>
              </Grid>
            )}
            {collectionIsFinished(collection) &&
              perms?.Dashboard?.Appeal?.SeeAppealButton && (
                <Grid item container justifyContent="center" xs={12} sm={2}>
                  <NavLink
                    activeClassName="active"
                    to={`${url}/appeal?collection=${collectionId}`}
                  >
                    <span style={{ fontSize: '20px', fontWeight: 400 }}>
                      {' '}
                      {t('See appeals')}
                    </span>
                  </NavLink>
                </Grid>
              )}
            {!collectionIsFinished(collection) && isOnVideos && (
              <Grid item container justifyContent="center" xs={12} sm={2}>
                <Button
                  action="secondary"
                  onClick={() => setIsRoomRecordModalOpen(true)}
                >
                  <span style={{ fontSize: '20px', fontWeight: 400 }}>
                    {t('Records history')}
                  </span>
                </Button>
              </Grid>
            )}
            {collectionIsFinished(collection) &&
              perms?.Dashboard?.ReportCard?.Enable && (
                <Grid item container justifyContent="center" xs={12} sm={2}>
                  <NavLink
                    activeClassName="active"
                    to={`${url}/answer-sheet-generate?collection=${collectionId}`}
                  >
                    <span style={{ fontSize: '20px', fontWeight: 400 }}>
                      {t('Generate report card')}
                    </span>
                  </NavLink>
                </Grid>
              )}
            {collection?.shouldStartRecordingScreenFlow &&
              hasGroup('PROCTOR') &&
              isOnVideos &&
              recordScreenButton()}
          </Grid>
        </Grid>
      </div>

      {isOnVideos && <RoomHeader room={room} setRoom={setRoom} />}
      <AddStudentModal
        isOpen={isAddStudentModalOpen}
        setIsOpen={setIsAddStudentModalOpen}
        providerCodename={user?.provider?.codename}
      />
      <RoomRecordModal
        isOpen={isRoomRecordModalOpen}
        setIsOpen={setIsRoomRecordModalOpen}
        roomId={roomId}
      ></RoomRecordModal>
      <DashboardRouter roomId={roomId.toString()} />
    </Page>
  )
}

export default styled(Header)`
  @media only screen and (max-width: 1419px) {
    margin-left: 50px;
    margin-right: 20px;
  }

  .sub-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
  }

  .lower-row {
    display: flex;
    justify-content: space-between;
    grid-template-columns: none;
  }

  .nav-links {
    /* Changed display from flex to none to deploy with this disabled in order for other work to be made */
    display: flex;
    font-weight: 700;
    font-size: 16px;

    .active {
      color: #333333;
      span {
        font-weight: 500 !important;
      }
      &::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }

    .record {
      display: flex;
      align-items: center;
      background-color: #f5f5f568;
      color: #f51c00 !important;
      font-weight: 800 !important;
      font-size: 16px;
      border: 1px solid #f51c00;

      .icon {
        padding-right: 10px;
      }
      &:hover {
        background: #c5c5c583;
        transition: all 0.3s;
      }
    }

    .recording {
      display: flex;
      align-items: center;
      background-color: #f51c00;
      color: #fff !important;
      font-weight: 800 !important;
      font-size: 16px;
      border: 1px solid ${(props) => props.theme.clientColors.secondary};

      .icon {
        padding-right: 10px;
      }

      &:hover {
        background: #c21b04;
        transition: all 0.3s;
      }
    }

    a {
      color: ${(props) => props.theme.colors.grayDark};
      text-decoration: none;
      position: relative;
      margin-left: 1rem;
      &:hover {
        color: #333333;
        &::before {
          visibility: visible;
          transform: scaleX(1);
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: -5px;
        left: 0;
        background-color: #ff7d26;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }
    }
    ${Button} {
      margin-left: 0.5rem;
    }
  }

  .show-lower-row {
    max-width: 100px;
  }

  .main-separator {
    margin-top: 1rem;
  }

  @media screen and (max-width: 1000px) {
    .sub-info {
      flex-direction: column;
      align-items: center;
      margin-bottom: 2em;
    }

    .nav-links {
      /* flex-direction: column; */
      margin-bottom: 8px;
      a {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
`
