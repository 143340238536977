import Page from 'components/Page'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BulkCreateApplicationContext } from 'states/BulkCreateApplicationState'
import BulkCreateApplicationPageInfo from './BulkCreateApplicationPageInfo'
import { fetchExams as fetchExamsAPI } from 'data/apis/exams'
import { ICollection, IExam } from 'types'
import ExamInfoLabel from 'components/ExamInfoLabel'
import { formatDateTimeString } from 'utils/date'
import styled from 'styled-components'
import Card from 'components/Card/Card'
import CardTitle from 'components/ExamDashboardComponents/CardTitle'
import CardDetails from 'components/CardDetails'
import CardSubtitle from 'components/ExamDashboardComponents/CardSubtitle'
import Button from 'components/Button'

const CollectionTimeWindowsComponent = ({
  collection,
  exams,
  className
}: {
  collection: ICollection
  exams: IExam[]
  className?: string
}) => {
  const examsCollection = exams.filter((e) => e.collection.id === collection.id)

  return (
    <Card className={className}>
      <CardTitle>{collection.name}</CardTitle>
      {examsCollection.map((exam) => (
        <div key={exam.id} className="exam-info">
          <CardSubtitle>{exam.name}</CardSubtitle>
          <CardDetails>
            {exam.timeWindows.map((tw) => (
              <>
                <span>
                  <ExamInfoLabel
                    label="Start time"
                    icon={['far', 'calendar-alt']}
                  >
                    {formatDateTimeString(tw.startTime)}
                  </ExamInfoLabel>
                </span>
                <span>
                  <ExamInfoLabel
                    label="End time"
                    icon={['far', 'calendar-alt']}
                  >
                    {formatDateTimeString(tw.endTime)}
                  </ExamInfoLabel>
                </span>
                <span>
                  <ExamInfoLabel label="Duration" icon={['far', 'clock']}>
                    {tw.maxDuration} minutos
                  </ExamInfoLabel>
                </span>
              </>
            ))}
          </CardDetails>
        </div>
      ))}
    </Card>
  )
}

const CollectionTimeWindows = styled(CollectionTimeWindowsComponent)`
  margin: 10px;

  span {
    margin: 10px;
  }

  .exam-info {
    margin-top: 10px;
  }
`

const ChooseTimeWindows = ({ className }: { className?: string }) => {
  const {
    currentStep,
    selectedCollections,
    submitting,
    bulkCreateApplications
  } = useContext(BulkCreateApplicationContext)
  const { t } = useTranslation()
  const [exams, setExams] = useState<IExam[]>([])

  const fetchExams = useCallback(async () => {
    const data = await fetchExamsAPI({
      collections: selectedCollections.map((c) => c.id).join()
    })
    setExams(data)
  }, [selectedCollections])

  useEffect(() => {
    if (selectedCollections.length > 0) {
      fetchExams()
    }
  }, [selectedCollections, fetchExams])

  if (currentStep !== 4) {
    return <></>
  }

  return (
    <div className={className}>
      <Page customWidth={1366}>
        <BulkCreateApplicationPageInfo
          title={t('Batch activity allocation')}
          instructions={t(
            'Review the application windows, applications will be generated respecting the following schedules'
          )}
        />
        <>
          {selectedCollections.map((c) => (
            <CollectionTimeWindows key={c.id} collection={c} exams={exams} />
          ))}
          <div className="submit-button">
            <Button
              type="submit"
              onClick={bulkCreateApplications}
              disabled={submitting}
              action="primary"
            >
              {submitting ? 'Criando...' : 'Criar'}
            </Button>
          </div>
        </>
      </Page>
    </div>
  )
}

export default styled(ChooseTimeWindows)`
  .submit-button {
    margin-top: 10px;
    float: right;
  }
`
