import classnames from 'classnames'
import ConfigContext from 'contexts/ConfigContext'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import React, { useContext } from 'react'
import styled from 'styled-components'
import ItemTableStyle from 'syles/ItemTableStyle'
import { AnswerContext } from '../../states/AnswerState'
import { ApplicationContext } from '../../states/ApplicationState'
import { IAlternative } from '../../types'
import Html from '../Html'

interface AlternativeProps extends IAlternative {
  className?: string
  itemId: number
}

const Alternative = ({ className, itemId, ...rest }: AlternativeProps) => {
  const { answer, updateAnswer, alreadyAnswered, finishingAnswer } = useContext(
    AnswerContext
  )
  const { disableItemTextHighlight } = useContext(ConfigContext)
  const { secondsToTimeout, application } = useContext(ApplicationContext)
  const { id, letter, content } = rest
  const configuration = useApplicationConfiguration(
    application?.exam.collection.applicationConfiguration
  )

  const shouldUpdateAnswer =
    configuration?.canUpdateAnswer ||
    answer?.finished !== true ||
    !alreadyAnswered

  const isSelected = answer?.item.id === itemId && answer.alternative?.id === id
  const isDisabled =
    secondsToTimeout === 0 ||
    !shouldUpdateAnswer ||
    answer?.timeoutDate !== null ||
    finishingAnswer

  return (
    <div className={className}>
      <label
        htmlFor={letter}
        className={classnames('radio-button', {
          selected: isSelected,
          disabled: isDisabled
        })}
      >
        <input
          readOnly
          type="checkbox"
          id={letter}
          checked={isSelected}
          disabled={isDisabled}
          onClick={() => !isDisabled && updateAnswer && updateAnswer(rest)}
        />
        <div className="radio-mark"></div>
        <div className="radio-button-content">
          <span className="letter">{`${letter.toUpperCase()}.`}</span>
          <ItemTableStyle>
            <Html noHighlight={disableItemTextHighlight}>{content}</Html>
          </ItemTableStyle>
        </div>
      </label>
    </div>
  )
}

export default styled(Alternative)`
  .radio-button {
    display: flex;
    position: relative;
    margin-top: 20px;
    background: white;
    padding: 0px 20px;
    cursor: pointer;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    &.disabled {
      cursor: not-allowed;
    }

    &-content {
      display: flex;
      line-height: 1.5rem;
      vertical-align: text-bottom;
      color: black;

      .letter {
        font-size: 16pt;
        margin-right: 1rem;
        font-weight: 600;
        align-self: center;
      }
    }

    &.selected {
      background: ${(props) => props.theme.clientColors.alternativeColor};
    }

    &:hover:not(.disabled) {
      outline: ${(props) => props.theme.colors.grayDark} solid 1px;
    }

    .radio-mark {
      display: none;
    }

    input[type='checkbox'] {
      opacity: 0;
      position: absolute;
    }

    input:checked ~ .radio-mark:after {
      display: flex;
    }
  }
`
