import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import BreadcrumbItem from './BreadcrumbItem'

type Props = {
  to: string
  children: ReactNode
  className?: string
  isActive?: boolean
}

const BreadcrumbLink = ({ to, children, className, isActive }: Props) => (
  <Link to={to} className={className}>
    <BreadcrumbItem isActive={isActive}>{children}</BreadcrumbItem>
  </Link>
)

export default styled(BreadcrumbLink)`
  &:visited {
    color: unset;
  }
`
