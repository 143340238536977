import React, { useState } from 'react'
import permissionsImg from '../assets/img/camera-permission.png'
import Page from '../components/Page'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Fade from 'components/Fade'
import Browsers from 'components/CameraPermissionTutorial/Browsers'
import Tutorial from 'components/CameraPermissionTutorial/Tutorial'
import { browserName } from 'react-device-detect'
import RoundishButton from 'components/Buttons/RoundishButton'

type CameraPermissionProps = {
  className?: string
}

const CameraPermissionTutorial = ({ className }: CameraPermissionProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [active, setActive] = useState<string>(browserName)

  return (
    <Fade>
      <Page customWidth={1366} className={className}>
        <div className="grid">
          <div>
            <img
              className="instruction-image"
              src={permissionsImg}
              alt="Permissões necessárias"
            />
            <p className="title">{t('Attention')}</p>
          </div>
          <div>
            <p className="description">
              {t(
                'Since the page has been reloaded, you will need to grant the camera permissions once again.'
              )}{' '}
              {t(
                "If the system cannot detect that the permission has been granted, it means you refused the permission at some point, and you must manually update your browser permissions, otherwise you won't be able to continue."
              )}
            </p>
            <div className="buttons-container">
              <RoundishButton
                action="primary"
                onClick={() => history.push('/applications')}
              >
                {t('Try again')}
              </RoundishButton>
            </div>
            <Tutorial active={active} />
            <Browsers active={active} onChange={setActive} />
          </div>
        </div>
      </Page>
    </Fade>
  )
}

export default styled(CameraPermissionTutorial)`
  display: flex;
  justify-content: center;
  line-height: 1.5;

  @media (min-width: 1000px) {
    max-width: 1000px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @media (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .instruction-image {
    width: 100%;
  }

  .centered {
    width: 400px;
  }

  .preview-container {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
  }

  .info-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .title {
    margin-bottom: 0;
    margin-top: 10px;
    text-align: center;
    color: #23607a;
    font: Semibold 35px/49px Open Sans, sans-serif;
    font-size: 35px;
  }

  .description {
    font-size: 18px;
  }

  .buttons-container {
    display: flex;
  }
`
