import { useState, useEffect } from 'react'
import { API_HOST } from 'consts'
import axios from 'axios'
import { IApplication } from 'types'

function useApplication(applicationId: number | string) {
  const [application, setApplication] = useState<IApplication>()

  useEffect(() => {
    axios
      .get(`${API_HOST}/v1/applications/${applicationId}`)
      .then((response) => {
        const result = response.data as IApplication
        setApplication(result)
      })
  }, [applicationId])

  return application
}

export default useApplication
