import React, { useEffect, useState, useCallback, useContext } from 'react'
import { IExamDashboardApplicationUser, ICollection } from '../../types'
import VideoVolume from './VideoVolume'
import { getLastSnapShot } from 'data/apis/camera-snapshot'
import { getChannelName } from 'infra/streaming'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DashboardVideosContext } from 'states/DashboardVideosState'
import { FIREBASE_ENABLED } from 'consts'

type StreamingWrapperProps = {
  user: IExamDashboardApplicationUser
  staticImageOnly: Boolean
  className?: string
  collection?: ICollection
}

const StreamingWrapper = ({
  user,
  className,
  staticImageOnly,
  collection
}: StreamingWrapperProps) => {
  const { streamings } = useContext(DashboardVideosContext)
  const [currentStream, setCurrentStream] = useState(undefined)
  const [hasAgoraStreaming, setHasAgoraStreaming] = useState(false)
  const [fallbackStrategyElement, setFallbackStrategyElement] = useState(null)
  const [agoraStreamingStatus, setAgoraStreamingStatus] = useState(
    'try to connection stream'
  )

  const { t } = useTranslation()

  let channelName = ''
  if (user) {
    channelName = getChannelName(user.provider.codename, user.id)
  }

  useEffect(() => {
    if (!collection.shouldStreamToAgora) {
      setAgoraStreamingStatus('stream disabled')
    } else if (collection.shouldStreamToAgora) {
    }
  }, [collection.shouldStreamToAgora])

  const onStreamAdded = useCallback(
    (client, obj) => {
      setHasAgoraStreaming(true)
      setAgoraStreamingStatus('stream connected')
      const stream = obj.stream
      const currentVideo = document.getElementById(`${channelName}_container`)
      if (currentVideo && currentVideo.innerHTML) {
      } else {
        client.subscribe(stream)
        setCurrentStream(stream)
        stream.play(`${channelName}_container`)
        stream.muteAudio()
      }
    },
    [channelName]
  )

  const onPeerLeave = useCallback(
    (client, obj) => {
      setHasAgoraStreaming(false)
      setAgoraStreamingStatus('lost connection from stream')
      const stream = obj.stream
      try {
        client.unsubscribe(stream)
      } catch (e) {}
      const currentVideo = document.getElementById(`${channelName}_container`)
      if (currentVideo) {
        currentVideo.innerHTML = ''
      }
    },
    [channelName]
  )

  useEffect(() => {
    const stream = streamings[user.id]

    if (stream) {
      if (stream.action === 'JOIN') {
        onStreamAdded(stream.client, stream.obj)
      } else if (stream.action === 'LEAVE') {
        onPeerLeave(stream.client, stream.obj)
      }
    }
  }, [streamings, onStreamAdded, onPeerLeave, user.id])

  const onToggleMute = (muted: boolean) => {
    if (currentStream === undefined) {
      return
    }

    if (muted) {
      currentStream.muteAudio()
    } else {
      currentStream.unmuteAudio()
    }
  }

  const createFallbackStrategyElement = useCallback(
    (snapshot) => {
      setFallbackStrategyElement(
        <>
          <label
            style={{
              float: 'left',
              position: 'absolute',
              backgroundColor: '#F0E68C',
              padding: '2px'
            }}
          >
            Imagem Estática
          </label>
          <img width="100%" alt={user.name} src={snapshot.url}></img>
        </>
      )
    },
    [user]
  )

  useEffect(() => {
    let intervalId = -1
    if (FIREBASE_ENABLED) {
      if (!hasAgoraStreaming || staticImageOnly) {
        intervalId = setInterval(() => {
          getLastSnapShot(`${user.id}`, createFallbackStrategyElement)
        }, 2000)
      }

      return () => clearInterval(intervalId)
    }
  }, [
    staticImageOnly,
    hasAgoraStreaming,
    createFallbackStrategyElement,
    user.id
  ])

  return (
    <div
      id={channelName}
      className={className}
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <VideoVolume
        onToggleMute={onToggleMute}
        style={{ position: 'absolute', right: 5, top: 5, zIndex: 1 }}
      />
      {(!hasAgoraStreaming || staticImageOnly) && (
        <div className="disconnected-info">
          {fallbackStrategyElement || <p>{t(agoraStreamingStatus)}</p>}
        </div>
      )}
      {
        <div
          id={`${channelName}_container`}
          style={{
            width: '100%',
            height: '100%',
            display: staticImageOnly ? 'none' : ''
          }}
        />
      }
    </div>
  )
}

export default styled(StreamingWrapper)`
  .disconnected-info {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.colors.text};
    background: ${(props) => props.theme.colors.grayMedium};

    &.bathroom {
      box-shadow: inset 0px 0px 0px 5px #528652;
      opacity: 0.61;
      background: ${(props) => props.theme.colors.grayDark};
      color: white;
    }
  }

  .disconnected-info > p {
    align-self: center;
    text-align: center;
    font-weight: bold;
  }
`
