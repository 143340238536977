import _ from 'lodash'
import {
  InternetConnectionCallback,
  InternetConnectionDetectionStrategy
} from 'types'

export default class FileDownloadStrategy
  implements InternetConnectionDetectionStrategy {
  // Fields
  private callbackCount = 0
  private callbacks: {
    [key: number]: InternetConnectionCallback
  } = {}

  private intervalId = -1
  private isRunning = false

  // Constants
  private readonly IMAGE_URL =
    'https://d2qwb5oql6jx19.cloudfront.net/EXAMS/image_uploading_test.png'

  private attempts = 0
  private delaySum = 0
  private lastDelay = 0
  private score = 100
  private hasYieldedResult = true

  public addListener(callback: InternetConnectionCallback) {
    this.callbackCount++
    this.callbacks[this.callbackCount] = callback
    return this.callbackCount
  }

  public removeListener(id: number) {
    delete this.callbackCount[id]
  }

  public start() {
    if (this.isRunning) {
      return
    }
    this.intervalId = setInterval(this.doDetection.bind(this), 10000)
    this.isRunning = true
  }

  public stop() {
    clearInterval(this.intervalId)
    this.isRunning = false
  }

  private calculateScore(newDelay: number) {
    if (this.lastDelay === 0) {
      return
    }
    const diff = Math.abs(newDelay - this.lastDelay) / 10
    if (newDelay > this.lastDelay) {
      this.score -= diff
    } else {
      this.score += diff
    }
    this.score = _.clamp(this.score, 0, 100)
  }

  private doDetection() {
    if (!this.hasYieldedResult) {
      return
    }
    this.hasYieldedResult = false
    const startTime = new Date().getTime()
    const image = new Image()
    image.src = `${this.IMAGE_URL}?t=${startTime}`
    image.onload = () => {
      this.attempts += 1
      const endTime = new Date().getTime()
      const elapsedTime = endTime - startTime
      this.delaySum += elapsedTime
      const averageDelay = this.delaySum / this.attempts
      this.calculateScore(elapsedTime)
      this.yieldResults(this.lastDelay, averageDelay, this.score)
      this.lastDelay = elapsedTime
      this.hasYieldedResult = true
    }
  }

  private yieldResults(last: number, average: number, score: number) {
    for (const key in this.callbacks) {
      this.callbacks[key](last, average, score)
    }
  }
}
