import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import logoImg from '../assets/img/educat.png'
import permissionsImg from '../assets/img/camera-permission.png'
import errorWithoutSign from '../assets/img/mascot-waiting.png'
import errorImg from '../assets/img/error.png'
import pauseImg from '../assets/img/girlsad.png'
import restroomImg from '../assets/img/run.png'
import bathroomImg from '../assets/img/bathroom.png'
import { AuthContext } from '../states/AuthState'

const clientColors = {
  primary: '#6A35DD',
  secondary: '#6A35DD',
  secondaryDark: '#BEBEBE',
  text: '#5A5A5A'
}

const colors = {
  grayLight: '#F5F5F5',
  grayMedium: '#C6C6C6',
  grayDark: '#606060',
  danger: 'darkred',
  success: '#21D650'
}

const reportColors = {
  positive: '#63B288',
  negative: '#BF6827',
  neutral: '#1C8CC5',
  principal: '#80298F'
}

const videoColors = {
  question: '#B15329',
  restroom: '#72423B',
  restroomAccepted: '#4E6017',
  suspectBehavior: '#FAD038'
}

const applicationStatus = {
  AVAILABLE: 'darkorange',
  STARTED: 'orange',
  FINISHED: 'limegreen',
  AVAILABLE_SOON: 'gold',
  UNAVAILABLE: 'silver',
  UNKNOWN: 'silver',
  STOPPED: 'darkred'
}

export const initialTheme = {
  name: 'eduCAT',
  logoImg: logoImg,
  permissionsImg,
  errorImg,
  pauseImg,
  restroomImg,
  bathroomImg,
  colors,
  clientColors,
  reportColors,
  videoColors,
  applicationStatus,
  brandColors: {
    Chrome: '#F2B635',
    Firefox: '#F19A30',
    Safari: '#37A1E6',
    Opera: '#EE5447'
  },
  errorWithoutSign,
  footerMessage: '',
  footerNumber: '',
  footerEmail: '',
  footerImageOrText: ''
}

type DefaultThemeProps = {
  children: ReactNode
}

const DefaultTheme = ({ children }: DefaultThemeProps) => {
  const [defaultTheme, setDefaultTheme] = useState(initialTheme)
  const { theme } = useContext(AuthContext)
  useEffect(() => {
    if (theme) {
      const {
        name,
        logoImg,
        logoSideBar,
        footerMessage,
        footerNumber,
        footerEmail,
        footerImageOrText,
        smallLogo,
        logoDontHaveApplication,
        maintenanceImg
      } = theme

      const newClientColors = {
        primary: theme.primaryColor,
        secondary: theme.secondaryColor,
        secondaryDark: theme.buttonHover,
        alternativeColor: theme.alternativeColor,
        instructionsButton: theme.instructionsButton,
        colorPrimaryIcon: theme.colorPrimaryIcon,
        colorSecondIcon: theme.colorSecondIcon,
        buttonSearchPrimary: theme.buttonSearchPrimary,
        buttonSearchSecondary: theme.buttonSearchSecondary,
        accent: theme.accentColor,
        text: theme.textDarkColor
      }

      const newApplicationStatusColors = {
        AVAILABLE: theme.applicationAvailableColor,
        STARTED: theme.applicationStartedColor,
        FINISHED: theme.applicationFinishedColor,
        AVAILABLE_SOON: theme.applicationAvailableSoonColor,
        UNAVAILABLE: theme.applicationUnavailableColor,
        UNKNOWN: theme.applicationUnknownColor,
        STOPPED: theme.applicationStoppedColor
      }

      const clientTheme = {
        ...initialTheme,
        name,
        logoImg,
        logoSideBar,
        smallLogo,
        logoDontHaveApplication,
        maintenanceImg,
        reportColors,
        clientColors: newClientColors,
        applicationStatus: newApplicationStatusColors,
        footerMessage,
        footerNumber,
        footerEmail,
        footerImageOrText
      }
      setDefaultTheme(clientTheme)
    }
  }, [theme])

  const muiTheme = createMuiTheme({
    palette: {
      primary: {
        main: defaultTheme.clientColors.primary
      }
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: 14,
          backgroundColor: '#000'
        }
      }
    }
  })

  return (
    <ThemeProvider theme={defaultTheme}>
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </ThemeProvider>
  )
}

export default DefaultTheme
