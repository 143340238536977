import styled from 'styled-components'
import { fetchSelectionProcessResults } from 'data/apis/selection-processes'
import { SelectionProcessResult } from 'data/domain/selection-processes'
import React, { useEffect, useState } from 'react'
import SelectionProcessResultCard from './SelectionProcessResultCard'

type Props = {
  className?: string
}

const SelectionProcessResults = ({ className }: Props) => {
  const [data, setData] = useState<SelectionProcessResult[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        setTimeout(
          async () => setData(await fetchSelectionProcessResults()),
          2000
        )
      } catch {
        // Simply ignore error
      }
    })()
  }, [])

  return (
    <div className={className}>
      {data.map((selectionProcessResult) => (
        <SelectionProcessResultCard
          key={selectionProcessResult.selectionProcessName}
          {...selectionProcessResult}
        />
      ))}
    </div>
  )
}

export default styled(SelectionProcessResults)`
  display: grid;
  grid-gap: 1em;
`
