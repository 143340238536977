import axios from 'axios'
import Modal from 'components/Modal/Modal'
import { API_HOST } from 'consts'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useReducer
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { childrenIsFunction, ICollection, IExam, IUser } from '../types'

interface IBulkCreateResult {
  created: number
  alreadyCreated: number
}

export interface IBulkCreateApplicationState {
  selectedCollections: ICollection[]
  selectedExam: IExam
  selectedUsers: IUser[]
  currentStep: number
  customFilters: any
  successModalIsOpen: boolean
  bulkCreateResult: IBulkCreateResult
  setSelectedCollections: (collection: ICollection[]) => void
  setSelectedExam: (exam: IExam) => void
  setSelectedUsers: (users: unknown[], customFilters: any) => void
  setCurrentStep: (step: number) => void
  bulkCreateApplications: () => void
  submitting: boolean
}

type IBulkCreateApplicationsActionsType =
  | 'SET_SELECTED_COLLECTIONS'
  | 'SET_SELECTED_EXAM'
  | 'SET_SELECTED_USERS'
  | 'SET_CURRENT_STEP'
  | 'SET_TIME_WINDOW'
  | 'SET_FILTERS'
  | 'SHOW_SUCCESS_MODAL'
  | 'HIDE_SUCCESS_MODAL'
  | 'HANDLE_SUBMIT'

type IBulkCreateApplicationsAction = {
  type: IBulkCreateApplicationsActionsType
  payload: any
}

type IBulkCreateApplicationStateProps = {
  children: Function | ReactElement
}

export const initialState: IBulkCreateApplicationState = {
  selectedCollections: [],
  selectedExam: null,
  selectedUsers: null,
  currentStep: 1,
  successModalIsOpen: false,
  bulkCreateResult: { created: 0, alreadyCreated: 0 },
  setSelectedCollections: () => null,
  setSelectedExam: () => null,
  setSelectedUsers: () => null,
  setCurrentStep: () => null,
  bulkCreateApplications: () => null,
  submitting: false,
  customFilters: {}
}

export const BulkCreateApplicationContext = createContext<
  IBulkCreateApplicationState
>(initialState)

const reducer = (
  state: IBulkCreateApplicationState,
  action: IBulkCreateApplicationsAction
): IBulkCreateApplicationState => {
  switch (action.type) {
    case 'SET_SELECTED_COLLECTIONS':
      return { ...state, selectedCollections: action.payload }
    case 'SET_SELECTED_EXAM':
      return { ...state, selectedExam: action.payload }
    case 'SET_SELECTED_USERS':
      return { ...state, selectedUsers: action.payload }
    case 'SET_CURRENT_STEP':
      return { ...state, currentStep: action.payload }
    case 'SET_FILTERS':
      return { ...state, customFilters: action.payload }
    case 'HANDLE_SUBMIT':
      return { ...state, submitting: true }
    case 'SHOW_SUCCESS_MODAL':
      return {
        ...state,
        successModalIsOpen: true,
        bulkCreateResult: action.payload
      }
    case 'HIDE_SUCCESS_MODAL':
      return {
        ...state,
        successModalIsOpen: false
      }
    default:
      return state
  }
}

const BulkCreateApplicationState = ({
  children
}: IBulkCreateApplicationStateProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { selectedCollections, customFilters } = state
  const { t } = useTranslation()
  const history = useHistory()

  const setSelectedCollections = (collection: ICollection[]) => {
    dispatch({ type: 'SET_SELECTED_COLLECTIONS', payload: collection })
    dispatch({ type: 'SET_CURRENT_STEP', payload: 3 })
  }

  const setSelectedExam = (exam: IExam) => {
    dispatch({ type: 'SET_SELECTED_EXAM', payload: exam })
    dispatch({ type: 'SET_CURRENT_STEP', payload: 3 })
  }

  const setSelectedUsers = (users: unknown[], customFilters: any) => {
    dispatch({ type: 'SET_SELECTED_USERS', payload: users })
    dispatch({ type: 'SET_CURRENT_STEP', payload: 4 })
    dispatch({ type: 'SET_FILTERS', payload: customFilters })
  }

  const bulkCreateApplications = useCallback(async () => {
    const requestData = {
      ...customFilters,
      collections: selectedCollections.map((c) => c.id)
    }

    dispatch({ type: 'HANDLE_SUBMIT', payload: null })

    await axios
      .post(`${API_HOST}/v1/collections/bulk_create_applications`, requestData)
      .then((r) => {
        dispatch({ type: 'SHOW_SUCCESS_MODAL', payload: r.data })
      })
      .catch(() => {
        toast.error('An error occurred. Please try again.')
      })
  }, [selectedCollections, customFilters])

  const contextValue = {
    ...state,
    setSelectedCollections,
    setSelectedExam,
    setSelectedUsers,
    bulkCreateApplications
  }

  return (
    <BulkCreateApplicationContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
      <Modal
        isOpen={state.successModalIsOpen}
        title={t('Applications created.')}
        onClose={() => history.push('/exams/dashboard-pre-filter')}
      >
        <>
          <p>
            {state.bulkCreateResult.created} {t('Applications created.')}
          </p>
          <p>
            {state.bulkCreateResult.alreadyCreated}{' '}
            {t('applications already created.')}
          </p>
        </>
      </Modal>
    </BulkCreateApplicationContext.Provider>
  )
}

export default BulkCreateApplicationState
