import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import Page from '../Page'
import Button from '../Button'
import Error from '../Error'
import ImportPageInfo from './ImportPageInfo'
import styled from 'styled-components'
type ImportBaseProps = {
  onSubmit: (params?: any) => void
  onFileChange: (params?: any) => void
  instructions: ReactNode
  title: string | ''
  errors: string[]
  successMessage?: string | ''
  loading?: boolean
  children?: any
  className?: string
}

const ImportBase = ({
  onSubmit,
  onFileChange,
  instructions,
  title,
  errors,
  successMessage,
  loading,
  children,
  className
}: ImportBaseProps) => {
  const { t } = useTranslation()

  return (
    <Page customWidth={1366}>
      <ImportPageInfo title={title} instructions={instructions} />
      <Formik initialValues={{}} onSubmit={onSubmit}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <div className={className}>
              <div className="input-file">
                <label htmlFor="inputGroupFile">Escolher Arquivo</label>
                <input
                  type="file"
                  accept=".csv,.csv, .json, .json"
                  id="inputGroupFile"
                  onChange={onFileChange}
                  style={{ display: 'none' }}
                ></input>
              </div>
              <Button className="backButton1" disabled={loading} type="submit">
                {loading ? t('Importing') : t('Import')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {errors.map((err) => (
        <p key={err}>
          <Error>{err}</Error>
        </p>
      ))}
      {successMessage && <p>{successMessage}</p>}
      {children}
    </Page>
  )
}

export default styled(ImportBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 60%;
  height: 32.5px;

  .input-file {
    background: ${(props) => props.theme.clientColors.primary};
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 0.5rem;
    height: auto;
    input {
      border: none;
      resize: none;
      &::-webkit-file-upload-button {
        display: none;
      }
      &::file-selector-button {
        display: none;
      }
    }
    &:hover {
      cursor: pointer;
      background: ${(props) => props.theme.clientColors.secondaryDark};
    }
  }
`
