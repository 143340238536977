import axios, { AxiosResponse } from 'axios'
import { getUserConfiguration } from 'data/apis/user-config'
import { logoutObservable } from 'data/observables'
import useGroup from 'hooks/firebase/useGroup'
import useOnline from 'hooks/firebase/useOnline'
import useQuery from 'hooks/useQuery'
import firebase from 'infra/firebase'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useReducer
} from 'react'
import KioskToken from 'utils/kioskToken'
import streamingManager from 'videoStreaming'
import { API_HOST, FIREBASE_ENABLED } from '../consts'
import db from '../db'
import {
  AuthStateLogin,
  AuthStateProviderLoginArgs,
  childrenIsFunction,
  IAuthState,
  IAuthStateAction
} from '../types'
import {
  clearToken,
  getClientId,
  getTheme,
  getUser,
  getUserGroups,
  saveTheme,
  saveToken,
  saveUser
} from '../utils/auth'
import { configAuthorizationHeader } from '../utils/axios'

const { auth } = firebase

type AuthStateProps = {
  children: Function | ReactElement
}

export const reducer = (
  state: IAuthState,
  action: IAuthStateAction
): IAuthState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isSubmitting: true, loginError: false }
    case 'LOGIN_ERROR':
      return {
        ...state,
        isSubmitting: false,
        loginError: true,
        detailedErrors: action.payload
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
        loginError: false,
        ...action.payload
      }
    case 'UPDATE_USER':
      return { ...state, user: action.payload }
    case 'SET_USER_CONFIG':
      return { ...state, userConfiguration: action.payload }
    case 'SHOW_NEW_SESSION_PAGE':
      return { ...state, ...action.payload, isSubmitting: false }
    case 'HIDE_NEW_SESSION_PAGE':
      return { ...state, showNewSessionPage: false }
    case 'UPDATE_LOCAL_STORAGE_OBJECTS':
      return { ...state, ...action.payload }
    case 'NETWORK_ERROR':
      return {
        ...state,
        loginError: false,
        networkError: true,
        isSubmitting: false
      }
    default:
      return state
  }
}

export const initialState: IAuthState = {
  user: undefined,
  token: undefined,
  theme: undefined,
  dispatch: () => {},
  isSubmitting: false,
  loginError: false,
  networkError: false,
  detailedErrors: undefined,
  login: () => Promise.resolve(false),
  providerLogin: () => Promise.resolve(false),
  handleEducatBridgeLogin: () => Promise.resolve(false),
  logout: () => Promise.resolve(),
  hasGroup: () => false,
  showNewSessionPage: false,
  newSessionMessage: '',
  activeSession: undefined,
  perms: undefined,
  userConfiguration: undefined
}

export const AuthContext = createContext<IAuthState>(initialState)
export const kioskToken = new KioskToken()

const AuthState = ({ children }: AuthStateProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const group = useGroup(state.user?.groups?.[0])

  const { setOnline, setOffline } = useOnline(state?.user?.id?.toString())
  const query = useQuery()
  const { user } = state

  const hasGroup = (_group: string): boolean => {
    const groups = getUserGroups()
    return groups.includes(_group)
  }

  const handleLoginError = (error: any) => {
    // We cannot and should not recover from a network error in this case.
    // When a network error occurs, axios throws a new Error('Network error'),
    // which does not have a status
    if (!error?.response?.status) {
      dispatch({ type: 'NETWORK_ERROR' })
      return
    }

    const status = error?.response?.status
    const data = error?.response?.data

    // in this case, the user is already logged in
    // on another session, so we show him a warning page
    if (status === 400 && data.clientId) {
      const [newSessionMessage, activeSession] = data.clientId
      dispatch({
        type: 'SHOW_NEW_SESSION_PAGE',
        payload: {
          showNewSessionPage: true,
          newSessionMessage,
          activeSession
        }
      })

      return
    }

    // if it's neither a network error, neither a session mismatch,
    // we just handle a generic loging error
    dispatch({ type: 'LOGIN_ERROR', payload: data })
  }

  const handleFirebaseLogin = async (token: string) => {
    const firebaseTokenResponse = await axios
      .create()
      .get(`${API_HOST}/v1/users/firebase`, {
        headers: {
          Authorization: `JWT ${token}`
        }
      })
    await auth().signInWithCustomToken(firebaseTokenResponse.data.token)
  }

  const saveKioskToken = useCallback(() => {
    const kiosk_token = query.get('kiosk_token')
    kioskToken.set(kiosk_token)
    axios.post(`${API_HOST}/v1/users/${user?.id}/is_in_browser`, {
      is_in_browser: kiosk_token !== null
    })
  }, [query, user])

  const handleEducatBridgeLogin = useCallback(
    async (bridgeToken) => {
      const response = await axios.post(
        `${API_HOST}/external-auth/educat-bridge/sign`,
        {
          token: bridgeToken
        }
      )

      const { token, user, theme } = response.data

      if (FIREBASE_ENABLED) {
        try {
          await handleFirebaseLogin(token)
        } catch (error) {
          handleLoginError({
            // Workaround until error handling is refactored
            response: {
              data: {
                nonFieldErrors: [
                  'Ocorreu um erro durante o processo de autenticação.'
                ]
              },
              status: 400
            }
          })
          return false
        }
      }

      saveToken(token)
      saveKioskToken()
      const groups = getUserGroups()
      const payload = { theme, user: { ...user, groups } }
      dispatch({ type: 'LOGIN_SUCCESS', payload: payload })
      saveUser(payload.user)
      saveTheme(theme)
      configAuthorizationHeader()
      setOnline()

      try {
        await db.delete()
        await db.open()
      } catch (_) {
        // Nothing
      }

      return true
    },
    [setOnline, saveKioskToken]
  )

  const handleLogin = useCallback(
    async (loginUrl: string, params: any): Promise<boolean> => {
      dispatch({ type: 'LOGIN' })

      let response: AxiosResponse

      try {
        response = await axios.post(loginUrl, params)
      } catch (error) {
        handleLoginError(error)
        return false
      }

      const { token, user, theme } = response.data

      if (FIREBASE_ENABLED) {
        try {
          await handleFirebaseLogin(token)
        } catch (error) {
          handleLoginError({
            // Workaround until error handling is refactored
            response: {
              data: {
                nonFieldErrors: [
                  'Ocorreu um erro durante o processo de autenticação.'
                ]
              },
              status: 400
            }
          })
          return false
        }
      }
      saveToken(token)
      saveKioskToken()
      const groups = getUserGroups()
      const payload = { theme, user: { ...user, groups } }
      dispatch({ type: 'LOGIN_SUCCESS', payload: payload })
      saveUser(payload.user)
      saveTheme(theme)
      configAuthorizationHeader()
      setOnline()

      if (FIREBASE_ENABLED) {
        try {
          await db.delete()
          await db.open()
        } catch (_) {
          // Nothing
        }
      }
      return true
    },
    [setOnline, saveKioskToken]
  )

  const login: AuthStateLogin = useCallback(
    ({ username, password, setNewClient }) => {
      return handleLogin(`${API_HOST}/v1/login`, {
        username,
        password,
        setNewClient,
        clientId: getClientId()
      })
    },
    [handleLogin]
  )

  const providerLogin = useCallback(
    ({
      provider,
      providerToken,
      setNewClient = true
    }: AuthStateProviderLoginArgs) => {
      return handleLogin(`${API_HOST}/v1/provider/${provider}/login`, {
        setNewClient,
        token: providerToken,
        clientId: getClientId()
      })
    },
    [handleLogin]
  )

  const logout = async () => {
    try {
      streamingManager.destroy()
      await axios.post(`${API_HOST}/v1/logout`)
      await setOffline()
      await auth().signOut()
      logoutObservable.notify()
    } finally {
      clearToken()
      // clearTheme()
      if (FIREBASE_ENABLED) {
        try {
          db.delete()
        } catch (_) {
          // Nothing
        }
      }
    }
  }

  useEffect(() => {
    const savedUser = getUser()
    const savedTheme = getTheme()
    const payload = { theme: savedTheme, user: savedUser }
    dispatch({ type: 'UPDATE_LOCAL_STORAGE_OBJECTS', payload: payload })
  }, [])

  const setUserConfig = useCallback(async () => {
    const userId = user?.id?.toString()
    if (!userId || !FIREBASE_ENABLED) return

    const userConfiguration = await getUserConfiguration(userId)
    dispatch({ type: 'SET_USER_CONFIG', payload: userConfiguration })
  }, [user])

  useEffect(() => {
    if (user) {
      setUserConfig()
    }
  }, [setUserConfig, user])

  const contextValue = {
    ...state,
    dispatch,
    login,
    logout,
    providerLogin,
    hasGroup,
    handleEducatBridgeLogin,
    perms: group?.Permissions
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </AuthContext.Provider>
  )
}

export default AuthState
