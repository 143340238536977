import randomColor from 'randomcolor'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { IExamDashboardApplicationUser } from '../types'

type ProfilePictureProps = {
  user: IExamDashboardApplicationUser
  className?: string
}

const ProfilePicture = ({ user, className }: ProfilePictureProps) => {
  // Creates a random color for profile picture background
  // that does not change in every render
  const colorRef = useRef(randomColor({ luminosity: 'dark' }))
  const initialLetter = user.name && user.name.length > 0 ? user.name[0] : '-'

  return (
    <span
      className={className}
      style={{
        backgroundColor: colorRef.current
      }}
    >
      {user.img ? (
        <img alt={user.name || 'User without name'} src={user.img} />
      ) : (
        <h2>{initialLetter}</h2>
      )}
    </span>
  )
}

export default styled(ProfilePicture)`
  width: 50px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 50%;
  font-size: 19px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`
