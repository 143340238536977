import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Author,
  Explanation,
  Extra,
  Timestamp
} from 'components/ExamDashboardComponents/CandidateHistoryItem'
import Separator from 'components/ExamDashboardComponents/Separator'
import { HistoryRecord } from 'data/domain/message-exchange'
import { getEvent, getMotive } from 'data/utils/message-exchange'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export type HistoryItemProps = {
  className?: string
  direction: 'left' | 'right'
  item: HistoryRecord
  author: string
}

const HistoryItem = ({
  className,
  direction,
  item,
  author
}: HistoryItemProps) => {
  const { t } = useTranslation()

  const renderMessageContent = () => {
    if (!item.message) {
      return null
    }

    return (
      <>
        <h5>{t('Content')}:</h5>
        <p>{item.message}</p>
      </>
    )
  }

  const renderDate = () => item?.timestamp?.toLocaleString(['pt-BR'])

  return (
    <div className={className}>
      <div className={`${direction} item`}>
        <div className="item-content">
          <Timestamp>
            <span>
              <FontAwesomeIcon icon="clock" /> {renderDate()}
            </span>
            <span>{getEvent(item.status)}</span>
          </Timestamp>
          <Explanation>
            {getMotive(item.motive) || <i>Motivo desconhecido</i>}
          </Explanation>
          <Extra>{renderMessageContent()}</Extra>
          <Separator />
          <Author>
            <p>{author}</p>
          </Author>
        </div>
      </div>
    </div>
  )
}

export default styled(HistoryItem)`
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  display: flex;
  justify-content: ${(props) =>
    props.direction === 'left' ? 'flex-start' : 'flex-end'};

  .item {
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: white;
    max-width: 45%;
    min-width: 30%;
    padding: 10px;
  }
`
