import React, { ReactNode, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import Konva from 'konva'

type Tools = 'marker'

interface StrokeOption {
  width: number
  color: string
}

export type CanvasContainerProps = {
  className?: string
  children: ReactNode
  canvasWidth: number
  canvasHeight: number
  isEnabled: boolean
}

const CanvasContainer = ({
  className,
  children,
  canvasHeight,
  canvasWidth,
  isEnabled
}: CanvasContainerProps) => {
  const container = useRef<HTMLDivElement>(null)

  const strokeOptions: { [key in Tools]: StrokeOption } = useMemo(
    () => ({
      marker: {
        width: 20,
        color: 'yellow'
      }
    }),
    []
  )

  useEffect(() => {
    if (!isEnabled) return
    if (!container.current) return
    const state = {
      tool: 'marker',
      lastPointerPosition: null,
      isPaint: true,
      currentLine: new Konva.Line(),
      scale: 1,
      lines: [],
      drawHistory: [],
      arrows: []
    }

    const stage = new Konva.Stage({
      container: 'container',
      width: canvasWidth,
      height: canvasHeight
    })

    const layer = new Konva.Layer()
    stage.add(layer)

    const onDrawInit = () => {
      const { tool } = state
      if (tool === 'marker') {
        state.isPaint = true
        state.lastPointerPosition = stage.getPointerPosition()
        state.currentLine = new Konva.Line({
          points: [],
          strokeWidth: strokeOptions[tool].width,
          stroke: strokeOptions[tool].color,
          globalCompositeOperation: 'multiply',
          opacity: 0.6
        })
        layer.add(state.currentLine)
        layer.draw()
      }
    }

    const onDraw = () => {
      const { tool } = state

      if (tool === 'marker') {
        if (state.isPaint) {
          const points = (state.currentLine as any).getPoints()
          const x =
            stage.getPointerPosition().x / state.scale - stage.x() / state.scale
          const y =
            stage.getPointerPosition().y / state.scale - stage.y() / state.scale
          points.push(x)
          points.push(y)
          layer.draw()
        }
      }
    }

    const onDrawEnd = () => {
      const { tool } = state

      if (tool === 'marker') {
        if (state.currentLine) {
          state.isPaint = false
          state.lines.push(state.currentLine)
          state.drawHistory.push(state.currentLine)
          state.currentLine = null
        }
      }
    }

    layer.draw()
    stage.on('contentMousedown.proto', () => onDrawInit())
    window.addEventListener('mouseup', () => onDrawEnd())
    stage.on('contentMousemove.proto', () => onDraw())
  }, [canvasWidth, canvasHeight, strokeOptions, isEnabled])

  return (
    <div ref={container} className={className}>
      {children}
      <div id="container" />
    </div>
  )
}

export default styled(CanvasContainer)`
  .konvajs-content {
    position: absolute !important;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: transparent;
  }
`
