import React from 'react'
import { useTranslation } from 'react-i18next'
import Tag from '../Tag'

type BulkOrderStatusProps = {
  status: any
  className?: string
}

const BulkOrderStatus = ({ status, className }: BulkOrderStatusProps) => {
  const { t } = useTranslation()
  const color = () => {
    return (
      {
        solicitado: '#BF6827',
        'gerando payload': '#1A3A60',
        'gerando pdf': '#1d1a60',
        'finalizado com sucesso': '#63B288',
        'finalizado com erros': '#840202'
      }[status] || 'Unknown'
    )
  }

  const getStatusLabel = (status: string): string => {
    return (
      {
        solicitado: 'solicitado',
        'gerando payload': 'gerando payload',
        'gerando pdf': 'gerando pdf',
        'finalizado com sucesso': 'finalizado com sucesso',
        'finalizado com erros': 'finalizado com erros'
      }[status] || 'Unknown'
    )
  }
  return (
    <Tag color={color()} className={className}>
      {t(getStatusLabel(status))}
    </Tag>
  )
}

export default BulkOrderStatus
