import { FIREBASE_ENABLED } from 'consts'
import firebase from 'firebase'
import { oldRealTimeDb, db } from 'infra/firebase'

let getUserOnlineStatusRef
let setOnlineStatus
let onConnected
let setOfflineOnDisconnect
let setRoom
let getOnlineUsers

if (FIREBASE_ENABLED) {
  getUserOnlineStatusRef = (userId: string) =>
    oldRealTimeDb.ref(`/users-online-status/${userId}`)

  const isOfflineForDatabase = {
    state: 'offline',
    lastChanged: firebase.database.ServerValue.TIMESTAMP
  }

  const isOnlineForDatabase = {
    state: 'online',
    lastChanged: firebase.database.ServerValue.TIMESTAMP
  }

  setOnlineStatus = (userId: string, online: boolean) => {
    return getUserOnlineStatusRef(userId).set(
      online ? isOnlineForDatabase : isOfflineForDatabase
    )
  }

  onConnected = (callback: Function) => {
    const ref = oldRealTimeDb.ref('.info/connected')
    const listener = ref.on('value', (snapshot) => {
      const val = snapshot.val()
      if (val === true) {
        callback()
      }
    })
    return () => {
      ref.off('value', listener)
    }
  }

  setOfflineOnDisconnect = (userId: string) => {
    if (FIREBASE_ENABLED) {
      return getUserOnlineStatusRef(userId)
        .onDisconnect()
        .set(isOfflineForDatabase)
    }
  }

  setRoom = (userId: string, roomId: string) => {
    return db.doc(`/users/${userId}`).set({ roomId }, { merge: true })
  }

  getOnlineUsers = (roomId: string) => {
    return db.collection('users').where('roomId', '==', roomId)
  }
}

export {
  getUserOnlineStatusRef,
  setOnlineStatus,
  onConnected,
  setOfflineOnDisconnect,
  setRoom,
  getOnlineUsers
}
