import { useState, useRef, useCallback, useEffect, useContext } from 'react'
import firebase from 'infra/firebase'
import { Candidate } from 'data/domain/message-exchange'
import ConfigContext from 'contexts/ConfigContext'
import { RollbarErrorTracking } from 'infra/rollbar'
import { getCandidate, createCandidate } from 'data/apis/message-exchange'
import { FIREBASE_ENABLED } from 'consts'

interface UseCandidateOut {
  candidate: Candidate | null
}

function useCandidate(
  roomId: string | undefined,
  candidateId: string | undefined
): UseCandidateOut {
  const [candidate, _setCandidate] = useState<Candidate>(null)
  const config = useContext(ConfigContext)
  const candidateRef = useRef(candidate)
  const setCandidate = (data: Candidate) => {
    candidateRef.current = data
    _setCandidate(data)
  }

  useEffect(() => {
    if (!roomId || !candidateId || !FIREBASE_ENABLED) {
      return
    }

    const query = getCandidate(roomId, candidateId)

    const unsubscribe = query.onSnapshot(function (doc) {
      const _candidate = doc.data() as Candidate
      setCandidate(_candidate)
    })

    query
      .get()
      .then((data) => {
        if (!data.exists) {
          createCandidate(roomId, candidateId)
        }
      })
      .catch(RollbarErrorTracking.logError)

    return unsubscribe
  }, [candidateId, roomId, FIREBASE_ENABLED])

  const updateLastSeen = useCallback(() => {
    if (!roomId || !candidateId || !FIREBASE_ENABLED) {
      return
    }

    firebase
      .firestore()
      .collection('rooms')
      .doc(roomId)
      .collection('candidates')
      .doc(candidateId)
      .set(
        { lastSeen: firebase.firestore.FieldValue.serverTimestamp() },
        { merge: true }
      )
  }, [roomId, candidateId, FIREBASE_ENABLED])

  useEffect(() => {
    if (!config.lastSeenUpdate) {
      return
    }
    updateLastSeen()
    const interval = setInterval(updateLastSeen, config.lastSeenUpdateInterval)
    return () => clearInterval(interval)
  }, [updateLastSeen, config.lastSeenUpdate, config.lastSeenUpdateInterval])

  return { candidate }
}

export default useCandidate
