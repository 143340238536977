import styled from 'styled-components'
import Page from 'components/Page'
import { fetchAnswers, fetchApplication } from 'data/apis/applications'
import {
  fetchAnswerSheetWithContent,
  fetchBulkAnswerSheetHeader
} from 'data/apis/exams'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import AnswerSheetBulkItems from './components/AnswerSheetBulkItems'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
// import { LoadingIndicator } from 'react-select/src/components/indicators'
import { Skeleton } from '@material-ui/lab'
import { Box } from '@material-ui/core'
import { IApplication } from 'types'
import { oldRealTimeDb } from 'infra/firebase'
import { convertTZ } from '../../utils/date'
import CardImage from 'components/CardImage'
import moment from 'moment'
import CardInfo from './components/CardInfo'
import LoginEventDetails from 'components/PostExam/LoginEvent/LoginEventDetails'
import InternetMeasurement from 'components/PostExam/InternetMeasurement/InternetMeasurement'
import ItemTitle from './components/ItemTitle'
import ChatHistoryDetails from 'components/PostExam/ChatHistoryDetails'
import html2canvas from 'html2canvas'
import domtoimage from 'dom-to-image'
import JsPDF from 'jspdf'
import Button from 'components/Button'
import { FIREBASE_ENABLED } from 'consts'

interface AnswerListProps {
  className?: string
}

const AnswersList: React.FC<AnswerListProps> = ({ className }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { applicationId } = useParams<{ applicationId: string }>()
  // const { url } = useRouteMatch()
  const [firstPicture, setFirstPicture] = useState<string>('')
  const [lastPicture, setLastPicture] = useState<string>('')
  const [middlePicture, setMiddlePicture] = useState<string>('')
  const [firstPictureTime, setFirstPictureTime] = useState<string>('')
  const [middlePictureTime, setMiddlePictureTime] = useState<string>('')
  const [lastPictureTime, setLastPictureTime] = useState<string>('')
  const [loadingPictures, setLoadingPictures] = useState<boolean>(false)
  const [answeredQuestions, setAnsweredQuestions] = useState<number>(0)
  const [cancelledQuestions, setCancelledQuestions] = useState<number>(0)
  const [correctAnswers, setCorrectAnswers] = useState<number>(0)

  const { data: application, isLoading: loadingApplication } = useQuery(
    ['application', applicationId],
    (_, _applicationId: number) => fetchApplication(_applicationId),
    {
      refetchOnMount: false
    }
  )
  const history = useHistory()
  const {
    // postItemNavigation,
    // postAnswerVisualization,
    postAlternativeExplanationVisualization
  } = application?.exam.collection || {}

  const examId = application?.exam.id
  let doc = new JsPDF('p', 'mm', 'a4')
  const { data: answerSheet, isLoading: loadingItens } = useQuery(
    ['answer-sheet', examId],
    (_, _examId: number) => {
      if (_examId) return fetchAnswerSheetWithContent(_examId)
      return []
    },
    {
      refetchOnMount: false
    }
  )

  const { data: header, isLoading: loadingHeader } = useQuery(
    ['answer-sheet-header', application],
    (_, application: IApplication) => {
      if (application)
        return fetchBulkAnswerSheetHeader(
          application?.exam.id,
          application?.user.id
        )
      return []
    },
    {
      refetchOnMount: false
    }
  )
  const { data: answers } = useQuery(
    ['answers', applicationId],
    (_, _applicationId: number) => fetchAnswers(_applicationId),
    {
      refetchOnMount: false
    }
  )
  // const getItemUrl = (itemId) =>
  //   postItemNavigation ? `${url}/items/${itemId}` : ''

  const getAnswer = useCallback(
    (itemId: number) => {
      const answer = answers?.find((d) => d.item.id === +itemId)
      const item = answerSheet?.find((d) => d.itemId === itemId)

      // TODO: Create an Answer class and add these methods
      return {
        itemId: item?.itemId,
        position: answer?.position,
        givenAnswer: answer?.alternative?.letter || answer?.freeResponse,
        givenBulkAnswer: answer?.alternatives,
        responseTime: answer?.seconds,
        maximumTime: answer?.answerTimeLimit,
        correctAnswer: item?.answer,
        correctBulkAnswer: item?.answers,
        isCancelled: item?.isCancelled,
        isTimeout: !!answer?.timeoutDate,
        verboseName: answer?.verboseName,
        value: answer?.value,
        itemValue: answer?.itemValue,
        answerGradeLinear: answer
      }
    },
    [answers, answerSheet]
  )

  useEffect(() => {
    const getCandidateImages = async () => {
      const TZDB = 'America/Sao_Paulo'
      const db = oldRealTimeDb.ref(`cameraSnapshots/${application?.user?.id}`)
      const convertedStartedAt = convertTZ(application?.startedAt, TZDB)
      const convertedFinishedAt = convertTZ(application?.finishedAt, TZDB)

      const first = await db
        .limitToFirst(1)
        .orderByChild('when')
        .startAt(convertedStartedAt.toISOString())
        .once('child_added')

      setFirstPicture(first.val().url)
      const convertFirstPictureTime = convertTZ(first.val().when, TZDB)
      setFirstPictureTime(moment(convertFirstPictureTime).format('HH:mm:ss'))

      const timeGap = Math.abs(
        (convertedFinishedAt.valueOf() - convertedStartedAt.valueOf()) / 2
      )
      const halfDateTime = new Date()
      halfDateTime.setTime(convertedStartedAt.getTime() + timeGap)

      const middle = await db
        .limitToFirst(1)
        .orderByChild('when')
        .startAt(halfDateTime.toISOString())
        .once('child_added')

      setMiddlePicture(middle.val().url)
      const convertMiddlePictureTime = convertTZ(middle.val().when, TZDB)
      setMiddlePictureTime(moment(convertMiddlePictureTime).format('HH:mm:ss'))

      const last = await db
        .limitToLast(1)
        .orderByChild('when')
        .endAt(convertedFinishedAt.toISOString())
        .once('child_added')

      setLastPicture(last.val().url)
      const convertLastPictureTime = convertTZ(last.val().when, TZDB)
      setLastPictureTime(moment(convertLastPictureTime).format('HH:mm:ss'))

      setLoadingPictures(true)
    }

    if (application && FIREBASE_ENABLED) {
      getCandidateImages()
    }
  }, [application])

  const addPagesToPdf = async (componentId: string) => {
    await html2canvas(document.querySelector(componentId)).then(
      async (canvas) => {
        if (canvas.height < 32000) {
          let position = 0
          const imgWidth = 210
          const pageHeight = 295
          const imgHeight = (canvas.height * imgWidth) / canvas.width
          let heightLeft = imgHeight
          const canvasData = canvas.toDataURL('image/jpeg')
          doc.addImage(canvasData, 'JPEG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight
            doc.addPage()
            doc.addImage(canvasData, 'JPEG', 0, position, imgWidth, imgHeight)
            heightLeft -= pageHeight
          }
        } else {
          const component = document.querySelector(componentId)
          const image = await domtoimage.toPng(component)
          let position = 0
          const imgWidth = 210
          const pageHeight = 295
          const imgHeight = (canvas.height * imgWidth) / canvas.width
          let heightLeft = imgHeight
          doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight
            doc.addPage()
            doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight)
            heightLeft -= pageHeight
          }
        }
      }
    )
  }

  const addPngAsPageToPdf = async (componentId: string) => {
    let position = 0
    const element = document.getElementById(componentId).children.length
    for (const key of Array(element).keys()) {
      const message = document.getElementById(componentId).children[key]
      if (position + message.clientHeight > 295) {
        position = 0
        doc.addPage()
      }
      const img = await domtoimage.toPng(message)
      const imgWidth = 210
      const imgHeight = (message.clientHeight * imgWidth) / message.clientWidth
      doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight)
      position += imgHeight + 5
    }
  }

  const addPagesBasedOnSectionsToPdf = async (componentId: string) => {
    const container = document.getElementById(componentId)
    const sections = container.children[0].getElementsByTagName('section')
    let position = 0
    while (position < sections.length) {
      const section = await html2canvas(sections[position])
      doc.addPage()
      const data = section.toDataURL('image/jpeg')
      doc.addImage(
        data,
        'JPEG',
        0,
        0,
        210,
        (section.height * 210) / section.width
      )
      position++
    }
  }

  const checkLocationPropExists = (prop: string) => {
    if (!location.state) return
    return location.state[0][prop]
  }

  const handlePrint = () => {
    window.print()
  }

  const handleDownload = async () => {
    try {
      doc = new JsPDF('p', 'mm', 'a4')
      toast.info(
        t('Your document is being prepared, the download will start soon')
      )
      if (checkLocationPropExists('ShowHeader')) {
        await addPagesToPdf('#header')
      }
      if (checkLocationPropExists('ShowInternetHistory')) {
        doc.addPage()
        await addPagesToPdf('#internet-history')
      }
      if (checkLocationPropExists('ShowLoginHistory')) {
        doc.addPage()
        await addPagesToPdf('#login-history')
      }

      if (checkLocationPropExists('ShowMessageExchangeHistory')) {
        doc.addPage()
        await addPngAsPageToPdf('messageExchange-history')
      }
      if (checkLocationPropExists('ShowAnswers')) {
        await addPagesBasedOnSectionsToPdf('answers')
      }

      doc.save('boletim.pdf')
      toast.success(t('Your document is ready, download started'))
    } catch {
      toast.error(t('An error ocurred while preparing your document'))
    }
  }

  useEffect(() => {
    if (answers && answers?.length > 0) {
      const answeredQuantity = answers?.reduce((accumulator, data) => {
        if (data.freeResponse && data.freeResponse !== '') {
          return accumulator + 1
        } else if (!data.freeResponse && data.alternative) {
          return accumulator + 1
        }
        return accumulator
      }, 0)
      setAnsweredQuestions(answeredQuantity)
    }
  }, [answers, answeredQuestions, header])

  useEffect(() => {
    if (answerSheet) {
      const cancelledQuantity = answerSheet?.reduce((accumulator, data) => {
        if (data.isCancelled) {
          return accumulator + 1
        }
        return accumulator
      }, 0)

      setCancelledQuestions(cancelledQuantity)
    }
  }, [answerSheet, cancelledQuestions])

  useEffect(() => {
    if (answerSheet) {
      const correctAnswersQuantity = answerSheet?.reduce(
        (accumulator, data) => {
          const answer = getAnswer(data.itemId)
          if (answer?.givenAnswer === answer?.correctAnswer) {
            return accumulator + 1
          }
          return accumulator
        },
        0
      )
      setCorrectAnswers(correctAnswersQuantity)
    }
  }, [answerSheet, correctAnswers, getAnswer])

  return (
    <div id="wholepage">
      <Page className={className}>
        {loadingHeader &&
        loadingItens &&
        loadingPictures &&
        loadingApplication ? (
          <>
            <Skeleton height="400px" />
            <Skeleton height="600px" />
            <Skeleton height="400px" />
            <Skeleton height="120px" />
            <Skeleton height="120px" />
            <Skeleton height="120px" />
            <Skeleton height="120px" />
          </>
        ) : checkLocationPropExists('ShowHeader') ? (
          <div id="header">
            <div
              style={{
                marginBottom: '1rem',
                display: 'flex',
                alignItems: 'left'
              }}
            >
              <Button
                className="backButton2"
                action="primary"
                onClick={() => {
                  history.goBack()
                }}
              >
                Voltar
              </Button>
            </div>
            <>
              <ItemPageBreak>
                <CardInfo
                  title={t('Candidate Informations')}
                  data={[
                    `${t('Name')}: ${header?.name || '-'}`,
                    `${t('CPF')}: ${header?.cpf || '-'}`,
                    `${t('Email')}: ${header?.email || '-'}`,
                    `${t('Cellphone')}: ${header?.cellphone || '-'}`,
                    `${t('Provider')}: ${header?.provider || '-'}`,
                    `${t('Exam')}: ${header?.exam || '-'}`,
                    `${t('Nota')}: ${header?.grade}/${header?.total}`,
                    `${t('Questions Answered')}: ${answeredQuestions}/${
                      answers?.length
                    }`,
                    `${t('Correct Answers Quantity')}:  ${correctAnswers}/${
                      answers?.length
                    }`,
                    `${t('Questions Cancelled')}: ${cancelledQuestions}`,
                    `${t('Start Time Application')}: ${moment(
                      application?.startedAt
                    ).format('HH:mm:ss')} `,
                    `${t('Finish Time Application')}: ${moment(
                      application?.finishedAt
                    ).format('HH:mm:ss')} `,
                    `${t('Room')}: ${application?.roomId}`
                  ]}
                  handleClick={handlePrint}
                  handleDownload={handleDownload}
                />
                <div className="image-wrapper">
                  {header?.identificationDocumentUrl && (
                    <CardImage
                      src={header?.identificationDocumentUrl}
                      title="Documento com foto"
                    />
                  )}
                  {header?.documentPictureWithFaceUrl && (
                    <CardImage
                      src={header?.documentPictureWithFaceUrl}
                      title="Imagem do candidato"
                    />
                  )}
                  {firstPicture && (
                    <CardImage
                      src={firstPicture}
                      title="Foto do início da aplicação"
                      time={firstPictureTime}
                    />
                  )}
                  {middlePicture && (
                    <CardImage
                      src={middlePicture}
                      title="Foto do meio da aplicação"
                      time={middlePictureTime}
                    />
                  )}
                </div>
              </ItemPageBreak>
              <div className="image-wrapper">
                {lastPicture && (
                  <CardImage
                    src={lastPicture}
                    title="Foto do fim da aplicação"
                    time={lastPictureTime}
                  />
                )}
              </div>
            </>
          </div>
        ) : undefined}
        {application &&
          checkLocationPropExists('ShowInternetHistory') &&
          FIREBASE_ENABLED && (
            <div id="internet-history">
              <ItemPageBreak>
                <Box boxShadow={1} className="internet-box">
                  <ItemTitle>
                    {t(`User's internet quality during exam`)}
                  </ItemTitle>
                  <InternetMeasurement application={application} />
                </Box>
              </ItemPageBreak>
            </div>
          )}
        {application &&
          checkLocationPropExists('ShowLoginHistory') &&
          FIREBASE_ENABLED && (
            <div id="login-history">
              <ItemPageBreak>
                <LoginEventDetails
                  application={application}
                  border={true}
                  title="Histórico de Login e Logout"
                  pagination={false}
                />
              </ItemPageBreak>
            </div>
          )}
        {application &&
          checkLocationPropExists('ShowMessageExchangeHistory') &&
          FIREBASE_ENABLED && (
            <div>
              <ItemPageBreak>
                <ChatHistoryDetails application={application} scroll={false} />
              </ItemPageBreak>
            </div>
          )}
        {application && checkLocationPropExists('ShowAnswers') && (
          <div id="answers">
            <ItemPageBreak>
              <AnswerSheetBulkItems
                application={application}
                getAnswer={getAnswer}
                postAlternativeExplanationVisualization={
                  postAlternativeExplanationVisualization
                }
                answerSheet={answerSheet}
              />
            </ItemPageBreak>
          </div>
        )}
      </Page>
    </div>
  )
}

const ItemPageBreak = styled.div`
  @media print {
    page-break-after: always;
  }
`

export default styled(AnswersList)`
  button {
    margin: 0 0.9rem;
  }

  .backButton {
    display: flex;

    &:hover {
      padding: 1rem;
    }
  }
  .backButton2 {
    border: none;
    display: flex;
    justify-content: center;
    aling-items: center;
    background: #ff6600;
    &: hover {
      background: #ea6f2d;
    }
  }
  .title-wrapper {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .image-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .internet-box {
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0 10px;
    margin-top: 40px;
  }

  .icon {
    width: 40px;
    color: ${(props) => props.theme.clientColors.primary};
    cursor: pointer;
    transition: filter 300ms;

    &:focus {
      outline: none;
    }

    &:hover {
      filter: brightness(80%);
    }
  }

  @media (max-width: 900px) {
    .wholepage {
      min-width: 900px;
    }
  }
`
