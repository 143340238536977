import permissionsImg from '../assets/img/camera-permission.png'

import React, { useContext, useState, useEffect, useCallback } from 'react'
import Page from '../components/Page'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'
import { VideoStreamingContext } from '../states/VideoStreamingState'
import { useHistory } from 'react-router-dom'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faUndo } from '@fortawesome/pro-regular-svg-icons'
import { AuthContext } from 'states/AuthState'
import {
  getCandidateDocuments,
  uploadCandidateDocuments
} from 'data/apis/document-pictures'
import { DocumentPictures } from 'data/domain/document-pictures'
import Fade from 'components/Fade'
import { RollbarErrorTracking } from 'infra/rollbar'
import CameraEnumeration from './CameraEnumeration'
import logoutObservable from 'data/observables/logout-observable'
import { addStreamingLog } from 'data/apis/streaming-logs'
import { StreamingLog } from 'data/domain/streaming-log'

type CameraPermissionProps = {
  className?: string
  autoSelectCamera?: boolean
}

const CameraPermission = ({
  className,
  autoSelectCamera
}: CameraPermissionProps) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const [selectedCamera, setSelectedCamera] = useState('')
  const {
    setPermissionGranted,
    granted,
    hasPermissionError,
    setCameraId,
    setMicrophoneId,
    triedToAskForPermission
  } = useContext(VideoStreamingContext)
  const [isRequestingPermission, setIsRequestingPermission] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [videoPreviewElement, setVideoPreviewElement] = useState(undefined)
  const [currentStep, setCurrentStep] = useState(0)
  const [hasPendingSnapshot, setHasPendingSnapshot] = useState(false)
  const [pictures, setPictures] = useState([])
  const [hasPickedCamera, setHasPickedCamera] = useState(false)
  const [hasEnumerationError, setHasEnumerationError] = useState(false)

  const checkForExistingDocuments = useCallback(async () => {
    if (!user) {
      return
    }

    const currentDocuments = await getCandidateDocuments(
      `${user.id}`,
      new Date()
    )
    if (!currentDocuments.empty) {
      const data = currentDocuments.docs[0].data() as DocumentPictures
      if (data.documentPictureUrl && data.documentPictureWithFaceUrl) {
        setCurrentStep(2)
      }
    }
  }, [user])

  // const handleCancelClick = () => {
  //   setPermissionGranted(false)
  //   history.replace('/applications')
  // }

  const handleConfirmClick = () => {
    setIsRequestingPermission(true)
    setPermissionGranted(true)
  }

  const getCameraPreview = useCallback(() => {
    if (
      hasPermissionError ||
      !granted ||
      !videoPreviewElement ||
      !hasPickedCamera
    ) {
      return
    }

    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: selectedCamera } })
      .then((stream) => {
        videoPreviewElement.srcObject = stream
        logoutObservable.subscribe(() => {
          stream.getTracks().forEach((track) => track.stop())
        })
      })
      .catch((e) => {
        addStreamingLog({
          action: 'GET_CAMERA_PREVIEW_ERROR',
          message: e?.message,
          candidateId: user?.id
        } as StreamingLog)
        RollbarErrorTracking.logError(e)
      })
  }, [
    granted,
    user,
    hasPermissionError,
    videoPreviewElement,
    hasPickedCamera,
    selectedCamera
  ])

  useEffect(() => {
    getCameraPreview()
  }, [getCameraPreview])

  useEffect(() => {
    checkForExistingDocuments()
  }, [checkForExistingDocuments])

  const saveFiles = useCallback(async () => {
    try {
      setUploading(true)
      await uploadCandidateDocuments(`${user.id}`, pictures)
    } catch (e) {
      RollbarErrorTracking.logError(e)
    } finally {
      setPictures([])
      setUploading(false)
    }
  }, [user, pictures])

  useEffect(() => {
    if (currentStep === 2 && pictures.length === 2) {
      saveFiles()
    }
  }, [currentStep, pictures.length, saveFiles])

  const captureFrame = () => {
    const canvas = document.createElement('canvas')
    canvas.width = videoPreviewElement.videoWidth
    canvas.height = videoPreviewElement.videoHeight
    const canvasContext = canvas.getContext('2d')
    if (canvasContext) {
      canvasContext.drawImage(videoPreviewElement, 0, 0)
    }
    const data = canvas.toDataURL('image/png')
    return data
  }

  const advanceStep = () => {
    if (currentStep < 2) {
      setPictures([...pictures, captureFrame()])
    }
    setCurrentStep(currentStep + 1)
    setHasPendingSnapshot(false)
    videoPreviewElement.play()
  }

  const resetSteps = () => {
    setPictures([])
    setHasPendingSnapshot(false)
    setCurrentStep(0)
    videoPreviewElement.play()
  }

  const takeSnapshot = () => {
    videoPreviewElement.pause()
    setHasPendingSnapshot(true)
  }

  const onDevicesSelected = ({
    audio,
    video
  }: {
    audio: string
    video: string
  }) => {
    setCameraId(video || '')
    setMicrophoneId(audio || '')
    setSelectedCamera(video)
    setHasPickedCamera(true)
  }

  const onEnumerationError = () => {
    setHasEnumerationError(true)
  }

  const stepsToRender = []
  stepsToRender.push(
    <div className="info-container">
      <div>
        <p className="title">
          {t('Position your identifying document with picture at your side.')}
        </p>
        <p className="description">
          {t(
            'Align your document with the square on the side with the picture part visible, then click on "Take picture"'
          )}
        </p>
        <div className="buttons-container">
          <div className="icon-container-wrapper">
            <p className="title description">
              {t(hasPendingSnapshot ? 'Confirm' : 'Take picture')}
            </p>
            <Button
              id="goNext"
              data-testid="go-next-step"
              className="icon-container"
              onClick={hasPendingSnapshot ? advanceStep : takeSnapshot}
              action={hasPendingSnapshot ? 'primary' : 'default'}
            >
              {!hasPendingSnapshot && <FontAwesomeIcon icon={faCamera} />}
              {hasPendingSnapshot && <FontAwesomeIcon icon={faCheck} />}
            </Button>
          </div>
          <div className="icon-container-wrapper">
            <p
              className={`title description ${
                !hasPendingSnapshot ? 'text-muted' : ''
              }`}
            >
              {t('Try again')}
            </p>
            <Button
              className="icon-container"
              disabled={!hasPendingSnapshot}
              onClick={resetSteps}
              action="error"
            >
              <FontAwesomeIcon icon={faUndo} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
  stepsToRender.push(
    <div className="info-container">
      <p className="title">{t('Now take a picture of your face')}</p>
      <p className="description">
        {t(
          'For this, do not wear a mask, glasses, hat or any sort of head adornment. Position your face on the center of the square and then click on "Take picture"'
        )}
      </p>
      <div className="buttons-container">
        <div className="icon-container-wrapper">
          <p className="title description">
            {t(hasPendingSnapshot ? 'Confirm' : 'Take picture')}
          </p>
          <Button
            id="goNext"
            className="icon-container"
            onClick={hasPendingSnapshot ? advanceStep : takeSnapshot}
            action={hasPendingSnapshot ? 'primary' : 'default'}
          >
            {!hasPendingSnapshot && <FontAwesomeIcon icon={faCamera} />}
            {hasPendingSnapshot && <FontAwesomeIcon icon={faCheck} />}
          </Button>
        </div>
        <div className="icon-container-wrapper">
          <p
            className={`title description ${
              !hasPendingSnapshot ? 'text-muted' : ''
            }`}
          >
            {t('Try again')}
          </p>
          <Button
            className="icon-container"
            action="error"
            onClick={resetSteps}
          >
            <FontAwesomeIcon icon={faUndo} />
          </Button>
        </div>
      </div>
    </div>
  )
  stepsToRender.push(
    <div className="info-container">
      <p className="title">{t('All set!')}</p>
      <p className="description align-left">
        {t(
          'Before going on, please align your camera in order to show your entire face frontally.'
        )}
      </p>
      <p className="description">
        {t('If you find any issues, contact the technical support.')}
      </p>
      <p className="description">
        {t('Then, click on the button below to go to the exam selection area')}
      </p>
      <div className="buttons-container">
        <Button
          className="roundish-button allow-button"
          onClick={() => history.replace('/applications')}
          isLoading={uploading}
        >
          {t(uploading ? 'Sending photos...' : 'Exams')}
        </Button>
      </div>
    </div>
  )

  if (hasPermissionError === true || hasEnumerationError) {
    return (
      <Page customWidth={1366} className={className}>
        <div>
          <img src={theme.errorImg} width={350} alt="Erro"></img>
          <p className="title">
            {t(
              'There was an error while trying to request access to your camera and microphone'
            )}
          </p>
          <p className="description align-left">
            {t('You can follow the steps below to try to find the problem')}:
          </p>
          <p className="description">
            <ul className="fix-instructions align-left">
              <li>{t('Close our safe exam platform')}</li>
              <li>{t('Open the web browser of your preference')}</li>
              <li>{t('Type in meet.google.com in the URL bar')}</li>
              <li>{t('Start an instant meeting')}</li>
              <li>
                {t(
                  'If you can see yourself on the screen, contact the technical support'
                )}
              </li>
              <li>
                <strong>
                  {t(
                    'Otherwise, this means your camera is not working properly, and you will need specialized support'
                  )}
                </strong>
              </li>
            </ul>
            <Button
              className="roundish-button allow-button go-back-button"
              onClick={() => history.replace('/applications')}
            >
              {t('Click to go back to the exams list')}
            </Button>
          </p>
        </div>
      </Page>
    )
  }

  if (!hasPickedCamera && isRequestingPermission && !autoSelectCamera) {
    return (
      <CameraEnumeration
        onSelect={onDevicesSelected}
        onError={onEnumerationError}
      />
    )
  }

  if (
    hasPermissionError === false &&
    granted === true &&
    (hasPickedCamera || autoSelectCamera)
  ) {
    return (
      <Page customWidth={1366} className={className}>
        <div className="centered preview-container">
          <div className="video-preview">
            <video
              style={{ width: '100%' }}
              autoPlay
              ref={(elem) => setVideoPreviewElement(elem)}
              muted={true}
              playsInline
            ></video>
          </div>
          {stepsToRender[currentStep]}
        </div>
      </Page>
    )
  }

  if (!triedToAskForPermission) {
    history.push('applications')
  }

  return (
    <Fade>
      <Page customWidth={1366} className={className}>
        <div className="grid">
          <div>
            <img
              className="instruction-image"
              src={permissionsImg}
              alt="Permissões necessárias"
            />
          </div>
          <div>
            <p className="title">
              {t('Permission to use camera and microphone')}
            </p>
            <p className="description">
              {t(
                'To proceed on this exam(s), you will need to grant us permission to access your camera and microphone. If you refuse, you will not me able to proceed.'
              )}
            </p>

            <div className="buttons-container">
              {/* <Button
              className="roundish-button cancel-button"
              isLoading={isRequestingPermission}
              onClick={handleCancelClick}
            >
              {t('Cancel')}
            </Button> */}
              <Button
                className="roundish-button allow-button"
                isLoading={isRequestingPermission}
                onClick={handleConfirmClick}
              >
                {t('OK')}
              </Button>
            </div>
          </div>
        </div>
      </Page>
    </Fade>
  )
}

export default styled(CameraPermission)`
  padding: 0 20px;
  margin-top: 1rem;

  @media (min-width: 1000px) {
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .instruction-image {
    width: 100%;
  }

  .centered {
    width: 400px;
  }

  .preview-container {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
  }

  .info-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .title {
    color: #23607a;
    font-size: 35px;
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 35px;
    margin-top: 1rem;
    margin-bottom: 0;

    @media (min-width: 600px) and (max-width: 900px) {
      font-size: 24px;
    }
  }

  .description {
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.5;
  }

  .text-muted {
    color: #606060;
  }

  .roundish-button {
    box-shadow: none;
    border: 0.6px solid #95989a;
    font: Bold 16px/30px Open Sans, sans-serif;
    width: 130px;
    height: 35px;
    padding: 0;
  }

  .cancel-button {
    background-color: white;
  }

  .allow-button {
    background-color: #1a3a60;
    color: white;
  }

  @media screen and (max-width: 800px) {
    .preview-container {
      grid-template-columns: none;
    }
  }

  .icon-container {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    font-size: 30px;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .align-left {
    text-align: left;
  }

  .fix-instructions {
    list-style-type: decimal;
    margin-top: 5rem;
    padding-left: 1em;
    display: grid;
    justify-content: center;
  }

  .go-back-button {
    width: 450px;
    margin: 0;
  }
`
