import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import Button, { ButtonProps } from '../Button'
import swal from 'sweetalert2'
import messages from '../../resources/messages'
import { AnswerContext } from 'states/AnswerState'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import { ApplicationContext } from 'states/ApplicationState'
import { IAnswer, IBooleanPreference } from 'types'
import usePreference from 'hooks/usePreference'

export interface ReviewButtonProps extends ButtonProps {
  canNavigateOnExamItems?: boolean
  buttonLabel: string
  showModal?: boolean
  callback?: (answer: IAnswer) => void
  typeAnswer?: any
}

const ReviewButton = ({
  showModal = false,
  buttonLabel,
  callback = () => undefined,
  typeAnswer,
  ...rest
}: ReviewButtonProps) => {
  const { t } = useTranslation()
  const { applicationId } = useParams<any>()
  const history = useHistory()
  const { answer, isAnswered, item } = useContext(AnswerContext)
  const { application, finishApplication } = useContext(ApplicationContext)
  const configuration = useApplicationConfiguration(
    application?.exam?.collection?.applicationConfiguration
  )

  const reviewButtonEnabled = usePreference<IBooleanPreference>(
    'Application__ShowReviewButton'
  )

  const showingModalToConfirmSeeFeedback = () => {
    if (
      reviewButtonEnabled &&
      reviewButtonEnabled.value &&
      showModal &&
      !configuration?.canBrowseAcrossItems
    ) {
      return swal
        .fire({
          icon: 'warning',
          text: t(messages.NOT_POSSIBLE_TO_CHANGE_ANSWER),
          title: t('Attention'),
          showCancelButton: true,
          cancelButtonText: t('Cancel')
        })
        .then((result) => {
          if (result.value === true) {
            callback(answer)
            history.push(`/applications/${applicationId}/review`)
          }
        })
    } else if (reviewButtonEnabled && reviewButtonEnabled.value && showModal) {
      callback(answer)
      history.push(`/applications/${applicationId}/review`)
    }
  }

  const confirmSeeFeedback = () => {
    showingModalToConfirmSeeFeedback()
    const canUploadFileAsAnswer =
      application?.exam?.collection?.canUploadFileAsAnswer
    // NAO VAI FICAR ASSIM, FOI FEITO PARA A APRESENTACAO DO PLURALL (26/07/2022)
    if (
      isAnswered(answer) ||
      canUploadFileAsAnswer ||
      item.category === 'REPERTORIZATION_TABLE' ||
      item.category === 'VIDEO' ||
      item.category === 'IMAGEM' ||
      item.category === 'CONCEITO' ||
      item.category === 'GAP_MULTIPLE_RESPONSE' ||
      item.category === 'TABELA_EDITAVEL' ||
      item.category === 'MULTIPLE_LINEAR_SCALE' ||
      item.category === 'FLASH_CARD'
    ) {
      callback(answer)
      history.push(`/applications/${applicationId}/review`)
    } else if (!showModal) {
      swal.fire({
        icon: 'warning',
        title: t('Please answer the question to end the test')
      })
    }
  }

  return typeAnswer?.id === 3 ? (
    <Button
      action="secondary"
      onClick={() => {
        finishApplication()
      }}
      {...rest}
    >
      {t('shut down')}
    </Button>
  ) : (
    <Button action="secondary" onClick={confirmSeeFeedback} {...rest}>
      {buttonLabel}
    </Button>
  )
}

export default ReviewButton
