export default {
  REQUEST_SENT: 'Solicitação enviada.',
  MESSAGE_SENT: 'Mensagem enviada.',
  NO_GROUP_FOUND: 'Nenhum grupo encontrado',
  CHANGES_ARE_SAVED_AUTOMATICALLY_WHEN_CHECKBOX:
    'The changes are saved automatically when you click on the checkboxes.',
  SAVED_SUCCESSFULLY: 'Saved successfully.',
  NOT_POSSIBLE_TO_CHANGE_ANSWER:
    'Be aware that if you proceed to see the feedback, your answer on the current question will ne automatically saved, and when you get back, you will be presented to the new question.'
}
