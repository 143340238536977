import React from 'react';
import PropTypes from 'prop-types';

class InsufficientData extends React.Component {
    render() {
        return (
            <div style={{ margin: '5px', backgroundColor: '#D6D3E0', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                <span
                    style={{
                        backgroundColor: 'c4c4c4c4',
                        width: '10px',
                        height: '10px',
                        marginRight: '5px',
                        display: 'inline-block',
                    }}
                />
                <h1 style={{ color: '#535059' }}>Ainda não há dados suficientes para gerar este relatório</h1>
            </div>
        );
    }
}

export default InsufficientData;
