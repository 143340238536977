/* eslint no-unused-vars: 0 */
import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as d3 from 'd3'
import LightBorderCard from '../Card/LightBorderCard'
import { StatisticsResponse } from 'pages/DashboardStatistics'

type ParticipationStatisticsProps = {
  size?: number
  examsStatistics: StatisticsResponse
}

const DashboardApplicationDonutChart = ({
  size = 350,
  examsStatistics
}: ParticipationStatisticsProps) => {
  const { t } = useTranslation()

  // eslint-disable-next-line
  const data = {
    Finished: examsStatistics ? examsStatistics.applications.finished : 0,
    Started: examsStatistics ? examsStatistics.applications.unfinished : 0,
    Absent: examsStatistics ? examsStatistics.applications.missing : 0
  }

  const calcTotal = useCallback(() => {
    const values = Object.values<number>(data)
    return values.reduce((x, y) => x + y, 0)
  }, [data])

  // creates the visualization
  const createChart = useCallback(() => {
    // set the dimensions and margins of the graph
    const margin = 60

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = Math.min(size, size) / 2 - margin

    // append the svg object to the div we want to render
    const svg = d3
      .select('.donut-chart')
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', -size / 2 + ' ' + -size / 2 + ' ' + size + ' ' + size)
      .append('g')

    // set the color scale based on data
    const color = d3
      .scaleOrdinal()
      .domain(Object.keys(data))
      .range(d3.schemeDark2)

    // Compute the position of each group on the pie:
    const pie = d3
      .pie()
      .sort(null) // Do not sort group by size
      .value((d) => d.value)
    const dataReady = pie(Object.entries(data))

    // The arc generator for the donut chart
    const arc = d3
      .arc()
      .innerRadius(radius * 0.7) // This is the size of the donut hole
      .outerRadius(radius * 0.8)

    // Arc for line/label positioning
    const outerArc = d3
      .arc()
      .innerRadius(radius * 1)
      .outerRadius(radius * 1)

    // Build the donut chart: each section is built with the arc function.
    svg
      .selectAll('allSlices')
      .data(dataReady)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => color(d.data.key))
      .attr('stroke', 'white')
      .style('stroke-width', '2px')
      .style('opacity', 0.7)

    // Add the polylines between the chart and labels
    svg
      .selectAll('allPolylines')
      .data(dataReady)
      .enter()
      .append('polyline')
      .attr('stroke', '#C9C9C9')
      .style('fill', 'none')
      .style('stroke-width', 2)
      .attr('points', (d) => {
        const posA = arc.centroid(d) // line starts in the middle of the section
        const posB = outerArc.centroid(d) // line break
        const posC = outerArc.centroid(d) // line ends
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // angle to determine if the label will be on the right or left
        posC[0] = radius * 1 * (midangle < Math.PI ? 1 : -1) // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC]
      })

    // Add label for percentage
    svg
      .selectAll('allLabels')
      .data(dataReady)
      .enter()
      .append('text')
      .text((d) => `${d.data.value === undefined ? '0%' : `${Math.floor((d.data.value / calcTotal()) * 100)}%`}`)
      .style('font-size', '20px')
      .style('font-weight', '600')
      .style('fill', '#606060')
      .attr('transform', (d) => {
        const pos = outerArc.centroid(d)
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
        pos[0] = radius * 1.05 * (midangle < Math.PI ? 1 : -1)
        return 'translate(' + pos + ')'
      })
      .style('text-anchor', (d) => {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
        return midangle < Math.PI ? 'start' : 'end'
      })

    // Add sub-label for number of students
    svg
      .selectAll('allLabels')
      .data(dataReady)
      .enter()
      .append('text')
      .text((d) => `${t('Total')}: ${d.data.value === undefined ? 0 : d.data.value}`)
      .style('font-size', '10px')
      .style('fill', '#707070')
      .attr('transform', (d) => {
        const pos = outerArc.centroid(d)
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
        pos[0] = radius * 1.05 * (midangle < Math.PI ? 1 : -1)
        pos[1] = pos[1] + 15
        return 'translate(' + pos + ')'
      })
      .style('text-anchor', (d) => {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
        return midangle < Math.PI ? 'start' : 'end'
      })

    // Add text in the center
    svg
      .append('text')
      .text(calcTotal())
      .style('fill', '#606060')
      .style('font-weight', '600')
      .style('font-size', '1.5em')
      .style('text-anchor', 'middle')

    svg
      .append('text')
      .attr('dy', 20)
      .text(t('Total students'))
      .style('fill', '#9F9F9F')
      .style('font-size', '.7em')
      .style('text-anchor', 'middle')

    // Add other circle designs
    svg
      .append('circle')
      .attr('r', 75)
      .style('stroke', '#EBF2F7')
      .style('stroke-width', 4)
      .style('fill', 'none')

    svg
      .append('circle')
      .attr('r', 105)
      .style('stroke', '#C9C9C9')
      .style('stroke-width', 2)
      .style('fill', 'none')

    // Add a legend
    const legend = d3
      .select('.legend')
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', 0 + ' ' + 0 + ' ' + size + ' ' + 10)
      .append('g')

    // Add one dot in the legend for each category
    legend
      .selectAll('dots')
      .data(Object.keys(data))
      .enter()
      .append('circle')
      .attr('cy', 5)
      .attr('cx', (d, i) => {
        return 5 + i * 95
      })
      .attr('r', 4)
      .style('fill', (d) => color(d))
      .style('padding-right', '1rem')
    // .attr('transform', function (d, i) { return 'translate(' + i % Object.keys(data).length * size + ',' + Math.floor(i / Object.keys(data).length) * size + ')' })

    // Add category name to legend
    legend
      .selectAll('labels')
      .data(Object.keys(data))
      .enter()
      .append('text')
      .attr('y', 5)
      .attr('x', (d, i) => {
        return 15 + i * 95
      })
      .style('fill', '#707070')
      .style('font-size', '.7em')
      .style('font-weight', '600')
      .text((d) => {
        return d === 'Finished' ? t('Finished', { context: 'mp' }) : t(d)
      })
      .style('alignment-baseline', 'middle')
  }, [data, calcTotal, size, t])

  // Creates a new graph for each change in data
  useEffect(() => {
    d3.select('.statistics-donut-chart').selectAll('svg').remove().exit()
    createChart()
  }, [data, createChart])

  return (
    <LightBorderCard title={t('Students')}>
      <div className="statistics-donut-chart">
        <div className="donut-chart" />
        <div className="legend" />
      </div>
    </LightBorderCard>
  )
}

export default DashboardApplicationDonutChart
