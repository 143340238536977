import React from 'react'
import { Candidate, Message, BreakRequest } from 'data/domain/message-exchange'

export type CandidateContextType = {
  candidate: Candidate
  messages: Message[]
  breakRequest: BreakRequest
}

const CandidateContext = React.createContext<CandidateContextType>({
  candidate: undefined,
  messages: [],
  breakRequest: undefined
})

export default CandidateContext
