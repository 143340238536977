import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

type FetchItemErrorProps = {
  onRetry?: () => void
}

const FetchItemError = ({ onRetry }: FetchItemErrorProps) => {
  const { t } = useTranslation()

  return (
    <>
      <p>
        {t('An error ocurred while loading this question.')}{' '}
        {t('Please verify your Internet connection and try again.')}
      </p>
      {onRetry && (
        <div>
          <Button action="secondary" type="button" onClick={onRetry}>
            {t('Try again')}
          </Button>
        </div>
      )}
    </>
  )
}

export default FetchItemError
