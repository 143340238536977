import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LightBorderCard from '../Card/LightBorderCard'

type QuestionGradedCardProps = {
  success?: boolean
  questionIndex: number
  percentage: number
  className?: string
}

const QuestionGradedCard = ({
  success = false,
  questionIndex,
  percentage,
  className
}: QuestionGradedCardProps) => {
  const text = success
    ? 'Question with the highest success rate'
    : 'Question with the highest failure rate'
  const { t } = useTranslation()
  return (
    <LightBorderCard className={className}>
      <span>
        {success ? (
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="2x"
            color="green"
          ></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon
            icon={faTimesCircle}
            size="2x"
            color="red"
          ></FontAwesomeIcon>
        )}
      </span>
      {questionIndex && <span className="number">Nº{questionIndex}</span>}
      <span className="link">{t(text)}</span>
      {questionIndex && (
        <p className="percent">
          {percentage.toFixed(2)}% {t('of students got it right')}
        </p>
      )}
      {!questionIndex && <p>{t('Not enough data to calculate')}</p>}
    </LightBorderCard>
  )
}

export default styled(QuestionGradedCard)`
  text-align: center;
  .number {
    font-weight: 600;
    font-size: 30px;
  }

  .link {
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.47px;
    color: #10a4b7;
  }

  .percent {
    font-size: 13px;
  }
`
