import { useState, useEffect, useCallback } from 'react'
import store from '../data/apis/application-configuration'
import { IApplicationConfiguration } from 'types'

const defaultApplicationConfiguration: IApplicationConfiguration = {
  timeToReturnFromBreakInSeconds: 60 * 5,
  canBrowseAcrossItems: true,
  canOpenInAnyBrowser: true,
  canUpdateAnswer: true,
  shouldStreamToFirebase: false,
  shouldUploadToS3: false,
  shouldUploadScreenshotToS3: false,
  setUnavailableToApplications: false,
  shouldFaceDetect: false,
  shouldTakeSnapshots: false,
  canUseCalculator: false,
  canUseScientificCalculator: false,
  canUseNotepad: false,
  canUseDrawingPanel: false,
  canUseTextMarker: false,
  canUseSymbolTable: false
}

const useApplicationConfiguration = (
  applicationConfigurationId: string | number | undefined
) => {
  const [configuration, setConfiguration] = useState<
    IApplicationConfiguration
  >()

  const getConfiguration = useCallback(async () => {
    if (!applicationConfigurationId) {
      setConfiguration(defaultApplicationConfiguration)
      return
    }
    const configuration = await store.get(applicationConfigurationId.toString())
    setConfiguration(configuration)
  }, [applicationConfigurationId])

  useEffect(() => {
    getConfiguration()
  }, [getConfiguration])

  return configuration
}

export default useApplicationConfiguration
