import React, { useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import styled from 'styled-components'

type Props = {
  analysisValue?: any
  className?: any
}

const ProgressHighchart = ({ analysisValue, className }: Props) => {
  let itensRespondidos = []

  const proficienciaTransformada = analysisValue['listProficienciaTransformada']

  const listErroPadrao = analysisValue['listErroPadrao']

  const itemRespondido = analysisValue['itemRespondido']

  if (itemRespondido.length > 1) {
    itensRespondidos = itemRespondido.split(', ')
  } else {
    itensRespondidos = itemRespondido
  }

  const proficienciaAtual =
    proficienciaTransformada[Object.keys(proficienciaTransformada).pop()]
  const erroPadrao = listErroPadrao[Object.keys(listErroPadrao).pop()]

  const data = proficienciaTransformada.map(function (item) {
    return +item.replace(',', '.')
  })

  const [chartOptions] = useState({
    chart: {
      zoomType: 'xy',
      renderTo: 'container',
      width: 600
    },
    title: {
      text:
        'Progresso do Teste Adaptativo (Proficiência = ' +
        proficienciaAtual +
        ' / Erro Padrão: ' +
        erroPadrao +
        ')'
    },
    series: [
      {
        data,
        color: 'blue',
        name: 'Proficiência'
      }
    ],
    xAxis: {
      title: {
        text: 'Objetos de Aprendizagem'
      },
      categories: itensRespondidos.map(function (data) {
        return 'Q' + data
      })
    },
    yAxis: {
      title: {
        text: 'Proficiência'
      },
      plotLines: [
        {
          value: proficienciaAtual,
          color: '#8b0000',
          width: 2
        }
      ]
    }
  })

  return (
    <div className={className}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default styled(ProgressHighchart)`
  .highcharts-credits {
    display: none !important;
  }
`
