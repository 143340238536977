import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback, useEffect, useState } from 'react'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'

type UserGroupsInputProps = {
  onChange?: any
  name?: string
  params?: any
}

const UserGroupsInput = ({ params, onChange, name }: UserGroupsInputProps) => {
  const [groups, setGroups] = useState([])

  // eslint-disable-next-line
  const fetchItems = useCallback(
    debounce(async (search) => {
      const response = await axios.get(`${API_HOST}/v1/groups`)
      setGroups(response.data)
    }, 500),
    []
  )

  useEffect(() => {
    fetchItems('')
  }, [fetchItems])

  const loadOptions = async (search) => {
    await fetchItems(search)
    return groups
  }

  return (
    <InputAsync
      key={JSON.stringify({ ...params })}
      defaultOptions={groups}
      loadOptions={loadOptions}
      name={name}
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.name}
      onChange={onChange}
    />
  )
}

export default UserGroupsInput
