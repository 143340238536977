import { DjangoGroup, Group } from 'data/domain/groups'
import { API_HOST } from 'consts'
import Axios from 'axios'

const capitalizeKeys = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map(capitalizeKeys)
  } else if (obj !== null && typeof obj === 'object') {
    let new_obj: any = {}
    for (let [key, value] of Object.entries(obj)) {
      let new_key = key.charAt(0).toUpperCase() + key.slice(1)
      new_obj[new_key] = capitalizeKeys(value)
    }
    return new_obj
  }
  return obj
}

export const getGroup = async (groupCode: string) => {
  // const snapshot = db.collection('groups').doc(groupCode).get()
  // const dbGroup = (await snapshot).data() as Group
  // console.log('dbGroup2: ', dbGroup)
  let dbGroupSql = undefined
  await Axios.get(
    `${API_HOST}/v1/users_group_permissions?group=${groupCode}`
  ).then((res) => {
    dbGroupSql = capitalizeKeys(res?.data[0])
  })
  return dbGroupSql
}

export const getGroupList = async () => {
  return (await Axios.get(`${API_HOST}/v1/groups`)).data as DjangoGroup[]
}

export const updatePermissions = async (groupName: string, group: Group) => {
  console.log('groupName:', groupName)
  console.log('group:', group?.Permissions)
  const groupJson = JSON.stringify(group)

  await Axios.post(
    `${API_HOST}/v1/users_group_permissions?group=${groupName}`,
    { groupJson }
  )
  // const ref = db.collection('groups').doc(groupName)
  // await ref.set(group)
}
