import React from 'react'
import styled from 'styled-components'

import MultiQuestionModalContent from './MultiQuestionModalContent'
import { AnswerSheet } from 'data/domain/exams'

type QuestionModalProps = {
    answerSheet?: AnswerSheet
    item: any
    examId: number
}

const MultiQuestionModal = ({

    answerSheet,
    item,
    examId
}: QuestionModalProps) => {
    return (
        <div style={{ width: '100%' }}>
            {item.map((item_map) =>
                <MultiQuestionModalContent
                    examId={examId}
                    item={item_map.itemId}
                    answerSheet={answerSheet} />
            )}
        </div>
    )
}

export default styled(MultiQuestionModal)`
  .colorText {
    color: #000;
    with: 700px;
  }

  .item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    with: 700px;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    with: 700px;
  }
`
