import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

type FooterProps = {
  className?: string
}

const Footer = ({ className }: FooterProps) => {
  const theme = useContext(ThemeContext)
  const { footerMessage, footerNumber, footerEmail, footerImageOrText } = theme
  return (
    <div className={className}>
      <div className="footer">
        <div className="all-footer">
          <div className="left-footer">
            <b className="left-item-footer">{footerMessage}</b>
          </div>

          <div className="links-educat">
            <div className="contato">
              <a className="email-footer">{footerEmail}</a>
              <a
                className="tel-footer"
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {footerNumber}
              </a>
              {footerEmail && footerNumber && <div className="hr" />}
            </div>
            <a
              href="https://educat.com.br/"
              className="img-footer"
              target="_blank"
            >
              <img className="logo" alt="logo_educat" src={footerImageOrText} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default styled(Footer)`
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    justify-content: space-between;
    bottom: 0;
    background-color: white !important;
    width: 100%;
    box-shadow: 5px 1px 5px rgba(58, 58, 58, 0.5);
    height: 60px;
    left: 0;
  }

  .all-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .links-educat {
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contato {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .email-footer {
    width: 200px;
    margin: 0 20px;
  }

  .tel-footer {
    width: 200px;
  }

  .img-footer {
    margin: 0 60px;
  }

  .logo {
    width: 130px;
  }

  a {
    width: 100%;
    height: 20%;
    color: black;
    text-decoration: none;
    display: flex;
    justify-content: center;
    &:visited {
      color: black;
    }
  }

  .left-item-footer {
    width: 200px;
    margin-left: 100px;
  }

  b {
    color: black;
    white-space: nowrap;
    font-weight: 300;
  }

  .hr {
    width: 0px;
    height: 30px;
    color: #363636;
    border: solid 1px #383838;
  }

  @media screen and (max-width: 1024px) {
    .img-footer {
      margin: 0 10px;
    }

    .logo {
      width: 75px;
    }

    .tel-footer {
      width: fill-available;
      margin: 0 10px;
      white-space: nowrap;
    }

    .email-footer {
      width: fill-available;
      margin: 0 10px;
      white-space: nowrap;
    }

    .contato {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: fill-available;
    }

    .links-educat {
      height: auto;
      width: 550px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media screen and (max-width: 768px) {
    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: fixed;
      justify-content: space-between;
      bottom: 0;
      box-shadow: 5px 1px 5px rgba(58, 58, 58, 0.5);
      background-color: white !important;
      width: 100vw;
      height: 60px;
      margin: auto;
    }

    .img-footer {
      margin: 0 10px;
    }

    .logo {
      width: 75px;
    }

    .tel-footer {
      width: fill-available;
      margin: 0 10px;
      white-space: nowrap;
    }

    .email-footer {
      width: fill-available;
      margin: 0 10px;
      white-space: nowrap;
    }

    .contato {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: fill-available;
    }

    .links-educat {
      height: auto;
      width: 550px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .left-footer {
      width: 43%;
    }

    .left-item-footer {
      white-space: pre-wrap;
      margin: 0;
    }
  }

  @media screen and (max-width: 425px) {
    .img-footer {
      margin: 0 10px;
    }

    .all-footer {
      display: flex;
      justify-content: space-between;
    }

    .logo {
      width: 55px;
    }

    .contato {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: fill-available;
    }

    .links-educat {
      height: auto;
      width: 225px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .tel-footer {
      font-size: 0.8rem;
    }

    .email-footer {
      font-size: 0.8rem;
    }

    b {
      font-size: 0.8rem;
    }
  }
`
