import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import DraggableModal from './DraggableModal'
import TextArea from './TextArea'

interface NotepadProps {
  isNotepadOpen: boolean
  setIsNotepadOpen: (value: boolean) => unknown
  className?: string
}

const Notepad: React.FC<NotepadProps> = ({
  isNotepadOpen,
  setIsNotepadOpen,
  className
}) => {
  const [textAreaValue, setTextAreaValue] = useState('')

  useEffect(() => {
    setTextAreaValue(window.localStorage.getItem('examnotes') || '')
  }, [])

  function changeTextAreaValue(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setTextAreaValue(e.target.value)
    window.localStorage.setItem('examnotes', e.target.value)
  }

  return (
    <DraggableModal
      isOpen={isNotepadOpen}
      onAction={() => setIsNotepadOpen(false)}
    >
      <div className={className}>
        <div className="textarea-wrapper">
          <TextArea
            maxLength={6000}
            value={textAreaValue}
            onChange={changeTextAreaValue}
          />
        </div>
      </div>
    </DraggableModal>
  )
}

export default styled(Notepad)`
  .textarea-wrapper {
    width: min(60vh, 700px);
  }
`
