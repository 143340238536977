import { uploadBase64 } from './file-storage'
import { oldRealTimeDb } from 'infra/firebase'
import { v4 as uuidv4 } from 'uuid'

const getNodeKey = (userId: string) => {
  return `cameraSnapshots/${userId}`
}

export const listenToSnapshot = (userId: string, callback: Function) => {
  const nodeKey = getNodeKey(userId)
  return oldRealTimeDb
    .ref(nodeKey)
    .limitToLast(1)
    .on('child_added', (snapshot) => callback(snapshot.toJSON()))
}

export const getLastSnapShot = (userId: string, callback: Function) => {
  const nodeKey = getNodeKey(userId)
  return oldRealTimeDb
    .ref(nodeKey)
    .limitToLast(1)
    .once(
      'value',
      (snapshot) => snapshot.forEach((d) => callback(d.val())),
      console.log
    )
}

export const uploadSnapshot = async (userId: string, data: string) => {
  const nodeKey = getNodeKey(userId)
  const uploadedResult = await uploadBase64(
    `${userId}`,
    `cameraSnapshots/${uuidv4()}.jpg`,
    data
  )
  await oldRealTimeDb.ref(nodeKey).push({
    when: new Date().toISOString(),
    url: uploadedResult,
    candidateId: userId
  })
}
