import React, { useContext } from 'react'
import { AppealIssuingWindow } from 'types'
import styled from 'styled-components'
import AnswerReviewList from 'components/AnswerReviewList'
import { AnswerSheet, AnswerSheetOverview } from 'data/domain/exams'
import CartModal from 'components/AppealComponents/CartModal'
import { ModalContext } from 'states/ModalState'
import PayableAppealHelper from 'components/Appeal/PayableAppealHelper'
import { AppealProcess } from 'data/domain/appeals'
import SummaryCard from 'components/Card/SummaryCard'

type SummaryProps = {
  className?: string
  answers: any[]
  answerSheet: AnswerSheet
  getItemUrl: (itemId: number) => string
  postItemNavigation
  showItemId?: boolean
  answerSheetOverview?: AnswerSheetOverview
  appeals: any
  collection: any
  appealIssuingWindow: AppealIssuingWindow
  appealProcess: AppealProcess
  onDeleteAppeal: (appealId: number) => Promise<any>
}

const Summary = ({
  className,
  answers,
  answerSheet,
  answerSheetOverview,
  appeals,
  appealIssuingWindow,
  appealProcess,
  onDeleteAppeal,
  collection
}: SummaryProps) => {
  const { showModal } = useContext(ModalContext)
  // const appealIssuingAvailable = () => {
  //   if (!appealIssuingWindow) return false
  //   const { startDate, endDate } = appealIssuingWindow
  //   if (new Date(startDate) <= new Date() && new Date(endDate) > new Date())
  //     return true
  //   return false
  // }
  // essa função serve para criaçao de recurso de nota que será implementado na tela do summary card posteriormente

  return (
    <div className={className}>
      {/* deixei comentado pois o componente é utilizado em outros lugares e não sei se pode quebrar */}

      {/* caso quebre é só descomentar a seçao */}

      {/* <small>
        {postItemNavigation
          ? 'Clique no número do objeto de aprendizagem para visualizá-lo'
          : 'Navegação entre os objetos de aprendizagem ainda não está disponível'}
      </small> */}
      {answerSheetOverview && (
        <div className="overview">
          <span className="tags_data">
            <b>Meus acertos:</b> {answerSheetOverview?.totalCorrectAnswers}/
            {answerSheetOverview?.totalAvailable}
          </span>
          <span className="tags_data">
            <b>Objetos de aprendizagem anulados:</b>{' '}
            {answerSheetOverview?.totalCancelled}
          </span>
        </div>
      )}
      {appealIssuingWindow?.paymentAppeal && (
        <PayableAppealHelper
          appealProcess={appealProcess}
          onClickShopCart={() => {
            showModal(() => (
              <CartModal
                appeals={appeals}
                appealProcessId={appealProcess?.id}
                perAppealValue={appealIssuingWindow.forAppealValue}
                onDeleteAppeal={onDeleteAppeal}
              />
            ))
          }}
          appealCreatedCount={appeals?.length}
        />
      )}
      <AnswerReviewList>
        {answers?.map((answer, index) => {
          const item = answerSheet?.find((d) => d.itemId === answer.item.id)
          // essa função serve para criaçao de recurso de nota que será implementado na tela do summary card posteriormente
          // const appeal = appeals?.find((a) => a.item === answer.item.id)
          return (
            <SummaryCard
              key={index}
              answer={answer}
              item={item}
              collection={collection}
            />
          )
        })}
      </AnswerReviewList>
    </div>
  )
}

export default styled(Summary)`
  padding: 20px;
  text-align: center;

  .overview {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      display: block;
      font-weight: inherit;
    }
  }
  ${AnswerReviewList} {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .shopCart {
    width: 25px;
  }
  .btnCart {
    /* padding: 13px; */
    padding-bottom: 10px;
  }
  .tags_data {
    color: #4a4f58;
    width: 50%;
    background-color: #ffff;
    box-shadow: 0px 2px 8.5px rgba(129, 129, 129, 0.25);
    border-radius: 16px;
    max-width: 45%;
    padding: 10px;
  }
`
