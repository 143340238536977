import React from 'react'
import styled from 'styled-components'
import ReviewButton from '../Buttons/ReviewButton'
import ContactApplicatorButton from '../ApplicationToolbarComponents/ContactApplicatorButton'
import { useTranslation } from 'react-i18next'

type ApplicationToolbarProps = {
  className?: string
  showReviewButton?: boolean
  collection?: any
}

const ApplicationToolbar = ({
  className,
  collection,
  showReviewButton = false
}: ApplicationToolbarProps) => {
  const { t } = useTranslation()

  const allowProctorCommunication = collection?.allowProctorCommunication

  return (
    <div className={className}>
      {allowProctorCommunication && (
        <>
          <ContactApplicatorButton className="button-left" />
        </>
      )}
      {showReviewButton && (
        <ReviewButton buttonLabel={t('See feedback')} showModal={true} />
      )}
    </div>
  )
}

export default styled(ApplicationToolbar)`
  text-align: right;

  display: flex;
  justify-content: space-between;

  .button-left {
    float: left;
    margin: auto 0.5rem;
  }
`
