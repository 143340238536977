import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IReportCardItem } from '../../types'

type ItemExtraProps = {
  items?: IReportCardItem[]
  className?: string
}

const ItemExtra = ({ items, className }: ItemExtraProps) => {
  const itemsWithExtraValues = items
    ? items.filter((item) => item.reportCardExtras)
    : []
  const { t } = useTranslation()

  const getItemSituation = (item) => {
    if (item.isCancelled) {
      return (
        <span className="exam-details cancelled">{t('CANCELLED ITEM')}</span>
      )
    }
    if (item.answerIsCorrect) {
      return <span className="exam-details correct">{t('Correct answer')}</span>
    }
    if (item.answerIsCorrect === false) {
      return <span className="exam-details wrong">{t('Wrong answer')}</span>
    }
    return (
      <span className="exam-details not-answered">{t('Not answered')}</span>
    )
  }

  return (
    <div className={className}>
      {itemsWithExtraValues.map((item) => (
        <div key={item.id}>
          <span className="exam-details">
            Item {item.position}:{getItemSituation(item)}
          </span>
          {item.reportCardExtras.map((itemExtra) => (
            <div key={item.id + itemExtra.label}>
              <span className="exam-details">
                {itemExtra.values &&
                  itemExtra.values.map((val) => (
                    <li key={val} className="exam-details">
                      {val}
                    </li>
                  ))}
              </span>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default styled(ItemExtra)`
  .exam-details {
    font-weight: 500;
    font-size: 12px;
    margin-left: 10px;
  }

  .cancelled {
    color: #168cd5;
    font-weight: 600;
  }

  .wrong {
    color: #d61527;
    font-weight: 600;
  }

  .correct {
    color: #018821;
    font-weight: 600;
  }

  .not-answered {
    color: #d61527;
    font-weight: 600;
  }
`
