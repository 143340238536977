import Axios from 'axios'
import { API_HOST } from 'consts'
import SelectionProcessResults from 'components/SelectionProcessResults'
import usePreference from 'hooks/usePreference'
import { get } from 'lodash'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import ApplicationCard from '../components/ApplicationCard'
import Card from '../components/Card/Card'
import CardList from '../components/CardList'
import ErrorWithMessage from '../components/ErrorWithMessage'
import Html from '../components/Html'
import Page from '../components/Page'
import ApplicationListState from '../states/ApplicationListState'
import CollectionState from '../states/CollectionState'
import {
  IApplication,
  IApplicationListState,
  ICollectionState,
  IBooleanPreference
} from '../types'
import image from '../assets/img/hand.png'
import { AuthContext } from 'states/AuthState'
import SearchBarWrapper from 'components/ExamDashboardComponents/SearchBarWrapper'
import { ThemeContext } from 'styled-components'
import PaginationFooter from 'components/PaginationFooter'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@material-ui/core'
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles'
import { FormikValues } from 'formik'
import TabMenu from 'components/ExamDashboardComponents/TabMenu'
import StoryComponent from 'components/StoryMock/ExamsStories'
const { innerHeight } = window

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
)

const ApplicationList = () => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const { user, hasGroup } = useContext(AuthContext)
  const classes = useStyles()
  const [initialValues, setInitialValues] = useState<FormikValues>({})
  const [story, setStory] = useState([])
  const [storyView, setStoryView] = useState([])
  // const [loadingInitialValues, setLoadingInitialValues] = useState<boolean>(true)
  const [status, setStatus] = useState(undefined)
  const [areaConheciemento, setAreaConheciemento] = useState(undefined)
  const [activeTab, setActiveTab] = useState<number>(1)
  const [tipoId, setTipoId] = useState<number>(1)

  const fetchStory = () => {
    Axios.get(`${API_HOST}/get_story/?classe=1`).then((res) => {
      setStory(res.data)
    })
  }

  const fetchView = () => {
    Axios.get(`${API_HOST}/post_view/`).then((res) => {
      setStoryView(res.data)
    })
  }

  useEffect(() => {
    setInitialValues(
      JSON.parse(localStorage.getItem('appeal-list-filter') || '{}')
    )
    fetchStory()
    fetchView()
  }, [])

  const handleTabChange = (tab: number) => {
    const tipoIdMap = {
      1: 1,
      2: 2,
      3: 3
    }
    setActiveTab(Number(tab))
    setTipoId(tipoIdMap[tab])
  }

  const sharedFlow = usePreference<IBooleanPreference>(
    'Shared_Data__ActivateSharedFlow'
  )
  const examCategoryFlow = usePreference<IBooleanPreference>(
    'Application__ExamCategoryFlow'
  )

  const renderWelcomeMessage = () => {
    if (hasGroup('RESPONSIBLE')) {
      return (
        <>
          <h3
            style={{
              fontFamily: 'Public Sans',
              fontSize: '1.125rem',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: 'normal',
              color: '#000'
            }}
          >
            Olá, {user?.name}
          </h3>
          {/* <img height={25} src={image} /> */}
        </>
      )
    }
    return (
      <>
        <h3
          style={{
            fontFamily: 'Public Sans',
            fontSize: '1.125rem',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            color: '#000'
          }}
        >
          Olá, {user?.name}! <br />
          Confira suas atividades, trilhas e avaliações
        </h3>
        {/* <img height={25} src={image} /> */}
      </>
    )
  }
  return (
    <div style={{ minHeight: `${innerHeight}px`, background: 'white' }}>
      {/* <ApplicationSocketState>
        {() => ( */}
      <ApplicationListState
        sharedFlowValidation={get(sharedFlow, 'value')}
        tipoId={tipoId}
      >
        {({
          results,
          isLoading,
          collectionId,
          totalResults,
          onSearchChange,
          pageSize,
          numPages,
          handlePageChange,
          onPageSizeChange,
          onFilter
        }: IApplicationListState) => (
          <Page type={'aluno'}>
            <div style={{ marginBottom: '38px' }}>{renderWelcomeMessage()}</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
              {story.map((storys) => (
                <StoryComponent
                  title={storys.title}
                  id={storys.id}
                  key={storys.id}
                  view={storyView.some((view) => view.stories === storys.id)}
                />
              ))}
            </div>
            {examCategoryFlow && get(examCategoryFlow, 'value') ? (
              <Grid style={{ marginBottom: '50px', marginTop: '32px' }}>
                <TabMenu activeTab={activeTab} onTabChange={handleTabChange} />
              </Grid>
            ) : (
              <></>
            )}
            <SelectionProcessResults />
            {collectionId !== undefined && collectionId > 0 && (
              <CollectionState collectionId={collectionId}>
                {({ collection, fetchingCollection }: ICollectionState) => (
                  <>
                    <h2>
                      {fetchingCollection ? <Skeleton /> : collection?.name}
                    </h2>
                    {fetchingCollection ? (
                      <Skeleton height="200px" />
                    ) : (
                      <Card>
                        <Html>{collection?.instructions}</Html>
                      </Card>
                    )}
                  </>
                )}
              </CollectionState>
            )}

            <Grid
              container
              spacing={2}
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '22px'
              }}
            >
              <Grid container xs={12} sm={8} style={{display:'flex', alignItems:'center'}}>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  style={{ width: '100%', marginRight: '30px' }}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel id="Status-filter-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="Status-filter-select-label"
                      id="Status-filter-select"
                      value={status}
                      onChange={(event) => {
                        onFilter({
                          status: event.target.value,
                          areaConheciemento: areaConheciemento
                        })
                        setStatus(event.target.value)
                      }}
                    >
                      <MenuItem value="">
                        <em>Escolha uma opção</em>
                      </MenuItem>
                      <MenuItem value={'FINISHED'}>finalizada</MenuItem>
                      <MenuItem value={'AVAILABLE'}>pedente</MenuItem>
                      <MenuItem value={'STARTED'}>iniciada</MenuItem>
                      <MenuItem value={'UNAVAILABLE'}>indisponível</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ width: '100%', marginRight: '30px' }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="areaConheciemento-filter-select-label">
                      Área do Conhecimento
                    </InputLabel>
                    <Select
                      labelId="areaConheciemento-filter-select-label"
                      id="areaConheciemento-filter-select"
                      value={areaConheciemento}
                      onChange={(event) => {
                        onFilter({
                          areaConheciemento: event.target.value,
                          status: status
                        })
                        setAreaConheciemento(event.target.value)
                      }}
                    >
                      <MenuItem value="">
                        <em>Escolha uma opção</em>
                      </MenuItem>
                      <MenuItem value={'1'}>
                        Linguagens, Códigos e suas Tecnologias
                      </MenuItem>
                      <MenuItem value={'2'}>
                        Matemática e suas Tecnologias
                      </MenuItem>
                      <MenuItem value={'3'}>
                        Ciências da Natureza e suas Tecnologias
                      </MenuItem>
                      <MenuItem value={'4'}>
                        Ciências Humanas e suas Tecnologias
                      </MenuItem>
                      <MenuItem value={'5'}>Ensino Religioso</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
              <Grid item xs={12} sm={4} style={{ height: '40px' }}>
                <SearchBarWrapper
                  placeholder="Pesquisar..."
                  onSearchChange={onSearchChange}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left'
              }}
            ></div>
            <CardList>
              {isLoading && <Skeleton height={110} count={5} />}
              {!isLoading && results.length === 0 && (
                <ErrorWithMessage
                  message={t('There are no exams available at the moment.')}
                />
              )}

              {sharedFlow && get(sharedFlow, 'value')
                ? results
                    .filter(
                      (d: IApplication) =>
                        Number(d.exam.collection.categoryExam) === activeTab
                    )
                    .map((d: IApplication) => (
                      <ApplicationCard key={d.id} application={d} />
                    ))
                : results.map((d: IApplication) => (
                    <ApplicationCard key={d.id} application={d} />
                  ))}
            </CardList>
            <PaginationFooter
              pageSize={pageSize}
              pageCount={numPages}
              onPageChange={handlePageChange}
              onPageSizeChange={onPageSizeChange}
            >
              <p>
                Mostrando {results.length} de {totalResults} registros.
              </p>
            </PaginationFooter>
          </Page>
        )}
      </ApplicationListState>
    </div>
  )
}

export default ApplicationList
