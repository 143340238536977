import classnames from 'classnames'
import { useField, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'

interface InputAsyncProps extends OptionTypeBase {
  loadOptions: (search?: any) => Promise<any>
  className?: string
  label?: string
  name: string
  help?: string
  defaultOptions?: boolean
  placeholder?: string
  getOptionLabel?: (option: any) => string
  getOptionValue?: (option: any) => string
  onChange?: Function
  required?: boolean
  preSelect?: any
}

const InputAsync = ({
  help,
  preSelect,
  label,
  className,
  defaultOptions,
  placeholder,
  loadOptions,
  name,
  onChange,
  required,
  ...rest
}: InputAsyncProps) => {
  const { setFieldValue, submitCount } = useFormikContext()
  const [value, setValue] = useState<any>(preSelect)
  const [field, meta] = useField({
    name
  })
  const error = submitCount > 0 ? meta.error : undefined

  useEffect(() => {
    if (!value) {
      setValue(preSelect);
    }

  }, [preSelect]);

  useEffect(()=>{
    if(preSelect){
      if(value == preSelect){
        onChange && onChange(value)
        setFieldValue('items', value)
      }
    }
  },[value])

  return (
    <div className={className}>
      {label && (
        <span
          className={classnames('input-label', { 'input-required': required })}
        >
          {label}
        </span>
      )}
      {
        preSelect ? (
          value && (
            <AsyncSelect
              className="inner-field"
              isClearable
              placeholder={placeholder || 'Buscar...'}
              loadingMessage={() => 'Carregando...'}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              cacheOptions={false}
              defaultOptions={defaultOptions}
              defaultValue={value}
              loadOptions={loadOptions}
              onChange={(value) => {
                setFieldValue(field.name, value)
                onChange && onChange(value)
              }}
              {...rest}
            />
          )
        ) : (
          <AsyncSelect
            className="inner-field"
            isClearable
            placeholder={placeholder || 'Buscar...'}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => 'Nenhum resultado encontrado'}
            cacheOptions={false}
            defaultOptions={defaultOptions}
            defaultValue={field.value}
            loadOptions={loadOptions}
            onChange={(value) => {
              setFieldValue(field.name, value)
              onChange && onChange(value)
            }}
            {...rest}
          />
        )
      }
      {error && <span className="input-helper red">{error}</span>}
      {error && help && <br />}
      {help && <span className="input-helper">{help}</span>}
    </div>
  )
}

export default styled(InputAsync)`
  .input-label {
    font-weight: bolder;
  }

  .inner-field {
    margin-top: 5px;
  }

  .input-required {
    &:after {
      content: '*';
    }
  }
`
