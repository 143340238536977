import React, { useContext, useEffect, useState, useCallback } from 'react'
import useQuery from '../hooks/useQuery'
import { AppealContext } from 'states/AppealState'
import { fetchApplication } from 'data/apis/applications'
import { IAppealFormData } from '../types'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import AppealForm from 'components/AppealComponents/AppealForm'
import { useHistory } from 'react-router-dom'

type AppealProps = {
  className?: string
  location: any
}

type AppealIssuingForm = {
  content: string
  bibliography: string
  application: number | undefined
  item: number | undefined
}

const initialFormValues: AppealIssuingForm = {
  content: '',
  bibliography: '',
  application: undefined,
  item: undefined
}

const AppealCreateForm = ({ className, location }: AppealProps) => {
  const { t } = useTranslation()
  const [application, setApplication] = useState(null)
  const { postItemNavigation, isCancelled } = location?.state
  const query = useQuery()
  const history = useHistory()
  const applicationId = query.get('application')

  const {
    issueAppeal,
    createAppealSuccess,
    createAppealError,
    appealCreationError,
    appealAttachments
  } = useContext(AppealContext)

  const handleSubmit = useCallback(
    async (values) => {
      const appealData: IAppealFormData = {
        application: application.id,
        item: values.item,
        content: values.content,
        bibliography: values.bibliography,
        attachments: appealAttachments
      }
      await issueAppeal(appealData)
      history.push(`/answer-sheets/${application.id}/summary`)
    },
    [issueAppeal, application, appealAttachments, history]
  )

  useEffect(() => {
    fetchApplication(+applicationId)
      .then((res) => setApplication(res))
      .catch(() =>
        toast.error(
          t('An error ocurred whilst fetching application informations.')
        )
      )
  }, [applicationId, t])

  useEffect(() => {
    if (createAppealError) toast.error(appealCreationError)
    if (createAppealSuccess) toast.success(t('Appeal successfully issued.'))
  }, [createAppealError, createAppealSuccess, t, appealCreationError])

  return (
    <AppealForm
      className={className}
      postItemNavigation={postItemNavigation}
      isCancelled={isCancelled}
      application={application}
      initialValues={initialFormValues}
      handleSubmit={handleSubmit}
    />
  )
}

export default AppealCreateForm
