import React, { createContext } from 'react'

import { AppealReplyData, IAppealResponseState } from 'data/domain/appeals'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { fetchAppealDetail, replyAppeal } from 'data/apis/appeals'
import { toast } from 'react-toastify'

type AppealResponseStateProps = {
  children: Function
}

const initialAppealState: IAppealResponseState = {
  appeal: undefined,
  handleResponseAppeal: () => undefined,
  responseAppealLoading: false,
  responseAppealSuccess: false,
  responseAppealError: undefined
}

export const AppealResponseContext = createContext<IAppealResponseState>(
  initialAppealState
)

const AppealResponseState = ({ children }: AppealResponseStateProps) => {
  const { appealId } = useParams<{ appealId: string }>()

  const { data: appeal, refetch: refetchAppealDetail } = useQuery(
    ['appeal', appealId],
    (_, _appealId: number) => fetchAppealDetail(_appealId)
  )

  const [
    handleResponseAppeal,
    {
      error: responseAppealError,
      isLoading: responseAppealLoading,
      isSuccess: responseAppealSuccess
    }
  ] = useMutation(
    (data: AppealReplyData) => {
      return replyAppeal(+appealId, data)
    },
    {
      onSuccess: () => {
        refetchAppealDetail()
        toast.success('Recurso respondido com sucesso.')
      }
    }
  )

  const contextValue = {
    appeal,
    responseAppealError,
    handleResponseAppeal,
    responseAppealLoading,
    responseAppealSuccess
  }

  return (
    <AppealResponseContext.Provider value={contextValue}>
      {children(contextValue)}
    </AppealResponseContext.Provider>
  )
}

export default AppealResponseState
