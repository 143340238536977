import AlternativeContent from 'components/AlternativeGroup/AlternativeContent'
import { makeAlternativeOutline } from 'components/AlternativeGroup/AlternativeOutline'
import Button from 'components/Button'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AlternativeExplanationModal from './AlternativeExplanationModal'
import ItemCard from '../../../components/Item/ItemCard'
import ItemTitle from 'pages/AnswerSheet/components/ItemTitle'
import Html from '../../../components/Html'
import ConfigContext from 'contexts/ConfigContext'
import {
  Accordion,
  AccordionSummary,
} from '@material-ui/core';
import {ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

type Props = {
  content: string
  letter: string
  isCorrect: boolean
  isPicked: boolean
  isCancelled: boolean
  explanation?: string
  postAlternativeExplanationVisualization: boolean
  correctAnswerByCandidate?: boolean
  requestedBy?: string
}

const AnswerSheetAlternative = ({
  content,
  letter,
  isCorrect,
  isPicked,
  isCancelled,
  explanation,
  postAlternativeExplanationVisualization,
  requestedBy = 'CANDIDATE'
}: Props) => {
  const AlternativeOutline = makeAlternativeOutline(
    isCorrect,
    isPicked,
    isCancelled
    )
    
  const { disableItemTextHighlight } = useContext(ConfigContext)
  const { t } = useTranslation()
  const [justificationModalIsOpen, setJustificationModalIsOpen] = useState(
    false
  )

  return (
    <>
    <AlternativeOutline requestedBy={requestedBy}>
       <Accordion 
       style={{
        backgroundColor:' #FBFBFB',
        borderRadius:'4px',
        boxShadow: 'none',
        border: 'none',
        width: '100%',
      }}>
        <AccordionSummary
          className="expanded"
          expandIcon={<ExpandMoreIcon style={{ color: '#262930' }} />}
        >
        <AlternativeContent content={content} letter={letter} /> 
        </AccordionSummary>
        {explanation ? (
           <ItemTitle style={{
              color:' #262930',
              fontFamily: 'Public Sans',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '14px',
              letterSpacing: '0em',
              marginLeft: '20px',
              textAlign: 'left',  
          }}>Justificativa</ItemTitle>       
          ) : (
            <></>
            )}
            <Html 
              style={{
            color:' #262930',
            fontFamily: 'Public Sans',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: '0em',
            marginLeft: '20px',
            textAlign: 'left',  
              }}
            noHighlight={disableItemTextHighlight}>{explanation}</Html>
      </Accordion>
      </AlternativeOutline>
      <div style={{backgroundColor:'#ffff', padding:'8px'}}></div>
      {postAlternativeExplanationVisualization && (
        <>
          <Button
            action="link"
            onClick={() => setJustificationModalIsOpen(true)}
          >
            {t('See alternative explanation')}
          </Button>
          <AlternativeExplanationModal
            isOpen={justificationModalIsOpen}
            explanation={explanation}
            onClose={() => setJustificationModalIsOpen(false)}
            alternativeLetter={letter}
          />
        </>
      )}
    </>
  )
}

export default AnswerSheetAlternative
