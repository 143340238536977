import { IAnswer, IApplication, IItem } from 'types'
import FirebaseSuspectResponse from 'utils/firebaseSuspectResponse'
export default class ObserveCandidateBehavior {
  private static _instance: ObserveCandidateBehavior
  private currentSuspectAnswersQuantity: number
  private firebase: FirebaseSuspectResponse
  private maximumSuspectAnswers: number
  private minimumAcceptedTime: number
  private answer: IAnswer

  public static getInstance(): ObserveCandidateBehavior {
    if (!ObserveCandidateBehavior._instance) {
      ObserveCandidateBehavior._instance = new ObserveCandidateBehavior()
    }
    return ObserveCandidateBehavior._instance
  }

  public async initialize(
    application: IApplication,
    answer: IAnswer,
    firebase: FirebaseSuspectResponse,
    item: IItem
  ) {
    this.setProps(application, answer, firebase, item)
    this.currentSuspectAnswersQuantity = await this.getCurrentSuspectResponse()
    this.minimumAcceptedTime = item?.answerMinimumTime
    this.maximumSuspectAnswers =
      application?.exam?.alertConfiguration?.maximumSuspiciousItemAnswerTime
  }

  private setProps(
    application: IApplication,
    answer: IAnswer,
    firebase: FirebaseSuspectResponse,
    item: IItem
  ) {
    this.answer = answer
    this.firebase = firebase
  }

  public async verifyCandidateAnswerTime() {
    try {
      if (this.minimumAcceptedTime > this.answer?.seconds) {
        this.incrementSuspectResponse()
      }

      if (this.currentSuspectAnswersQuantity >= this.maximumSuspectAnswers) {
        await this.firebase.clear()
        return true
      }
      return false
    } catch {
      return false
    }
  }

  private async incrementSuspectResponse(): Promise<void> {
    await this.firebase.set()
  }

  public async getCurrentSuspectResponse(): Promise<number> {
    return await this.firebase.get()
  }
}
