import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface ExamInfoLabelProps {
  className?: string
  children?: ReactNode
  label: string
  icon?: IconProp
}

const ExamInfoLabel = ({
  children,
  label,
  icon,
  className
}: ExamInfoLabelProps) => {
  const { t } = useTranslation()
  return (
    <span className={className}>
      <>
        <FontAwesomeIcon icon={icon} />
      </>
      <span>{t(label)}: </span>
      <>{children === null ? '-' : children}</ > 
    </span>
  )
}

export default styled(ExamInfoLabel)`
  font-size: 15px;
  color: #646464;
  align-items: stretch;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  white-space: 'nowrap';
  margin-top: 10px;
  strong {
    color: black;
    margin-left: 15px;
  }
`
