import { useLiveQuery } from 'dexie-react-hooks'
import db from 'db'

const useRepertorizationSync = () => {
  const synchronizingRubrics = useLiveQuery(() => {
    return db.rubrics
      .where('syncStatus')
      .equals('not synchronized')
      .or('syncStatus')
      .equals('synchronizing')
      .count()
  })

  const synchronizingSymptomTableRows = useLiveQuery(() => {
    return db.symptomTableRows
      .where('syncStatus')
      .equals('not synchronized')
      .or('syncStatus')
      .equals('synchronizing')
      .count()
  })

  const synchronizingDirectorSymptoms = useLiveQuery(() => {
    return db.directorSymptoms
      .where('syncStatus')
      .equals('not synchronized')
      .or('syncStatus')
      .equals('synchronizing')
      .count()
  })

  const allRubrics = useLiveQuery(() => {
    return db.rubrics.count()
  })

  const allSymptomTableRows = useLiveQuery(() => {
    return db.symptomTableRows.count()
  })

  const allDirectorSymptoms = useLiveQuery(() => {
    return db.directorSymptoms.count()
  })

  const synchronizingAnswers =
    synchronizingRubrics +
    synchronizingSymptomTableRows +
    synchronizingDirectorSymptoms

  const allAnswers = allRubrics + allSymptomTableRows + allDirectorSymptoms

  return [synchronizingAnswers, allAnswers]
}

export default useRepertorizationSync
