import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import styled from 'styled-components'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { Popover } from '@material-ui/core'

type ActionMenuButtonProps = {
  children?: ReactNode
  className?: string
  label?: string
}

const ActionMenuButton = ({ children, label }: ActionMenuButtonProps) => {
  const { t } = useTranslation()
  let enabledElements = 0

  React.Children.forEach(children, (child) => {
    /* eslint-disable dot-notation */
    if (child && !child['props'].disabled) {
      enabledElements++
    }
  })

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button
            action="secondary"
            aria-controls="menu-Popup"
            aria-haspopup="true"
            className="button-icon"
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
              borderRadius: '70px'
            }}
            {...bindTrigger(popupState)}
          >
            {label}
            {popupState.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Button>
          <Popover
            id="menu-Popup"
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            style={{ borderRadius: '60px', marginTop: '5px' }}
          >
            {enabledElements > 0 ? (
              children &&
              React.Children.map(children, (actionItem) => {
                return (
                  <MenuItem onClick={popupState.close}>{actionItem}</MenuItem>
                )
              })
            ) : (
              <MenuItem>{t('No action available')}</MenuItem>
            )}
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  )
}

export default styled(ActionMenuButton)`
  .MuiPaper-root {
    border-radius: 60px !important;
  }
  .MuiList-root .MuiMenu-list .MuiList-padding {
    border-radius: 60px !important;
  }
  @media screen and (max-width: 800px) {
    .button-icon {
      width: 60%;
    }
  }
`
