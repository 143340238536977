import React, { ReactNode } from 'react'
import styled from 'styled-components'

const ModalFooter = ({
  className,
  children
}: {
  className?: string
  children: ReactNode
}) => (
  <div className={className}>
    {React.Children.map(children, (child) => {
      return <div className="item">{child}</div>
    })}
  </div>
)

export default styled(ModalFooter)`
  height: 60%;
  margin-top: 1rem;
  bottom: 0px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: flex-end;
  .item {
    margin-left: 15px;
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: center;
  }
`
