import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IExamDashboardApplication } from '../../types'
import SetTimeWindowModal from './SetTimeWindowModal'

interface SetExamDateModalProps {
  application: IExamDashboardApplication
  className?: string
  onSubmit: (values: any) => Promise<any>
}

const SetExamDateModal = ({
  application,
  onSubmit,
  ...rest
}: SetExamDateModalProps) => {
  const { t } = useTranslation()

  return (
    <SetTimeWindowModal applyToCollectionOption onSubmit={onSubmit} {...rest}>
      <span>
        {t('Select new time window for ')}
        <strong>{application.exam.name}</strong>
        {` ${t('of')} `}
        <strong>{application.user.name}</strong>.
      </span>
    </SetTimeWindowModal>
  )
}

export default styled(SetExamDateModal)``
