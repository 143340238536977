import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback, useEffect, useState } from 'react'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'

type ExamByCollectionInputProps = {
  collectionId: number
  onChange?: any
  label: any
  name?: string
  params?: any
}

const ExamByCollectionInput = ({
  collectionId,
  params,
  onChange,
  label,
  name
}: ExamByCollectionInputProps) => {
  const [exams, setExams] = useState([])

  // eslint-disable-next-line
  const fetchItems = useCallback(
    debounce(async (search) => {
      const url = `${API_HOST}/v1/exams?collection=${collectionId}`
      const response = await axios.get(url, {
        // Need to change endpoint
        params: {
          search
        }
      })
      setExams(response.data)
    }, 500),
    [collectionId]
  )

  useEffect(() => {
    fetchItems('')
  }, [collectionId, fetchItems])

  const loadOptions = async (search) => {
    await fetchItems(search)
    return exams
  }

  return (
    <InputAsync
      key={JSON.stringify({ ...params, collectionId })}
      defaultOptions={exams}
      loadOptions={loadOptions}
      name={name || 'items'}
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.name}
      onChange={onChange}
      label={label}
    />
  )
}

export default ExamByCollectionInput
