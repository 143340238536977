import { Appeal, AppealAttachment } from 'data/domain/appeals'
import AnswerSheetItemContent from 'pages/AnswerSheet/components/AnswerSheetItemContent'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Separator from 'components/ExamDashboardComponents/Separator'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { Box } from '@material-ui/core'

type AppealDetailProps = {
  appeal: Appeal
  className?: string
}

const AppealDetail = ({ appeal, className }: AppealDetailProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { id, item, hide } = qs.parse(location.search)

  return (
    <div className={className}>
      <span className="appeal-text">{t('Appeal Informations')} </span>
      <Box className="box-header">
        <div className="header">
          <div>
            <span>
              {t('Name')}:
              {hide ? appeal?.user?.publicIdentifier : appeal?.user.name}
            </span>
          </div>
          <div>
            <span>
              {t('Exam')}: {appeal?.exam?.name}
            </span>
          </div>
          <div>
            <span>
              {t('Question')}: {item}
            </span>
          </div>
          <div>
            <span>
              {t('AppealId')} {id}
            </span>
          </div>
          {appeal?.answerValue === null ? (
            ''
          ) : (
            <div>
              <span>
                {t('Grade/Notes')}: {appeal?.answerValue?.total}
              </span>
            </div>
          )}
          <div>
            <span>
              {t('CreatedAt')} {appeal?.createdAt}
            </span>
          </div>
          <div>
            <span>
              {t('Status')}: {appeal?.status?.description}
            </span>
          </div>
        </div>
      </Box>
      <Separator style={{ width: '100%' }} />
      <Box className="box-header">
        <div className="item-selected">
          <span className="question-text">{t('Question')} </span>
          <AnswerSheetItemContent
            item={appeal?.item}
            correctAnswer={appeal?.item?.correctAnswer}
            givenAnswer={appeal?.givenAnswer}
            isCancelled={false}
            postAlternativeExplanationVisualization={false}
            requestedBy="OTHER"
            className="answer-sheet"
          />
        </div>
      </Box>
      <Separator style={{ width: '100%' }} />
      <Box className="box-header">
        <div className="candidate-content">
          <span>Descrição do recurso solicitado pelo candidato</span>
          <p>{appeal?.content}</p>
        </div>
        <div className="candidate-bibliography">
          <span>Bibliografia apresentada pelo candidato</span>
          <p>{appeal?.bibliography}</p>
        </div>
        <div className="attachments">
          <span>Anexos</span>
          {appeal?.attachments.map((att: AppealAttachment, index: number) => (
            <div key={index} className="view-attachment">
              <a href={att.url} target="_blank" rel="noreferrer">
                Anexo {index + 1}
              </a>
            </div>
          ))}
        </div>
      </Box>
    </div>
  )
}

export default styled(AppealDetail)`
  display: grid;
  gap: 0.6rem;

  ${Separator} {
    background-color: #ffffff81;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .box-header {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.103);
    padding: 1rem;
  }

  .appeal-text {
    color: ${(props) => props.theme.clientColors.primary};
    font-size: 1.5rem;
    position: relative;
    font-weight: 600;
    bottom: 0.5rem;
  }

  .candidate-content {
    span {
      color: ${(props) => props.theme.clientColors.primary};
      font-size: 1.2rem;
      position: relative;
      font-weight: 600;
      bottom: 0.5rem;
    }
    p {
      color: #000000;
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  .candidate-bibliography {
    margin-top: 30px;
    span {
      color: ${(props) => props.theme.clientColors.primary};
      font-size: 1.3rem;
      position: relative;
      font-weight: 600;
      bottom: 0.5rem;
    }
    p {
      color: #000000;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .attachments {
    span {
      color: ${(props) => props.theme.clientColors.primary};
      font-size: 1.2rem;
      position: relative;
      font-weight: 600;
      bottom: 0.5rem;
    }
  }

  .item-selected {
    width: 1024px;
    .question-text {
      color: ${(props) => props.theme.clientColors.primary};
      font-size: 1.2rem;
      position: relative;
      font-weight: 600;
      bottom: 0.5rem;
    }
  }
  .view-attachment {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  margin: 5px;
`
