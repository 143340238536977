import React, { useCallback, useContext, useEffect } from 'react'
import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'
import Button from 'components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from 'react-query'
import { getDashboardVideoApplication } from 'data/apis/applications'
import CandidateContext from 'contexts/CandidateContext'
import { DashboardVideoApplication } from 'data/domain/applications'
import Skeleton from 'react-loading-skeleton'
import usePopover from 'hooks/usePopover'
import { CurrentSituation } from 'data/domain/message-exchange'
import { getCurrentSituation } from 'data/apis/message-exchange'
import CardFetchError from 'components/CardFetchError'
import moment from 'moment'
import ApplicationStatusIcon from 'components/ApplicationStatusIcon'
import { AVERAGE_LATENCY_IN_MILISSECONDS } from '../../consts'
import { AuthContext } from '../../states/AuthState'

type ExamInfoIndicatorProps = {
  className?: string
  status: string
  candidateName?: {
    candidateName?: string
  }
  cellphone: string
  userId?: any
}

const averageLatency = AVERAGE_LATENCY_IN_MILISSECONDS

const ExamInfoIndicator = ({
  className,
  candidateName,
  cellphone,
  userId
}: ExamInfoIndicatorProps) => {
  const { candidate } = useContext(CandidateContext)
  const { anchorEl, handleClick, handleClose, open } = usePopover()
  const { hasGroup } = useContext(AuthContext)
  // Fetch candidate current application and item
  const currentSituationKey = ['get-current-situation', candidate?.id]
  const _getCurrentSituation = useCallback((): Promise<CurrentSituation> => {
    if (!candidate?.id) {
      return
    }
    return getCurrentSituation(candidate?.id)
  }, [candidate])

  const {
    data: currentSituation,
    isFetching: fetchingCurrentSituation,
    refetch: refetchCurrentSituation,
    error: currentSituationError
  } = useQuery(currentSituationKey, _getCurrentSituation, {
    // Ensure fetch does not occur at component mount (when popover is closed)
    enabled: false
  })

  // After fetching current application, fetchnumber of right/wrong answers
  const dashboardVideoApplicationKey = [
    'get-dashboard-video-application',
    candidate?.id
  ]
  const _getDashboardVideoApplication = useCallback((): Promise<
    DashboardVideoApplication
  > => {
    if (!currentSituation?.applicationId) {
      return
    }
    return getDashboardVideoApplication(currentSituation?.applicationId)
  }, [currentSituation])
  const {
    data,
    isFetching: fetchingDashboardVideoApplication,
    refetch: refetchDashboardVideoApplication,
    error: dashboardVideoApplicationError
  } = useQuery(dashboardVideoApplicationKey, _getDashboardVideoApplication, {
    enabled: false
  })

  const handleRefetch = useCallback(async () => {
    await refetchCurrentSituation()
    return await refetchDashboardVideoApplication()
  }, [refetchCurrentSituation, refetchDashboardVideoApplication])

  // Refetch stale data everytime popover is open
  useEffect(() => {
    open && handleRefetch()
  }, [open, handleRefetch])

  const isLoading =
    fetchingCurrentSituation || fetchingDashboardVideoApplication

  const renderExamName = () => {
    return isLoading ? (
      <Skeleton width={300} />
    ) : (
      <div className="header-wrapper">
        <span className="candidate-name">{candidateName}</span>
        <p className="infos-card">
          <b>ID: </b>
          {userId}
        </p>
        {hasGroup('ADMINISTRADOR') && (
          <p className="internet-delay acceptable-latency">
            Telefone Celular: {cellphone}
          </p>
        )}
        <strong>
          {currentSituation?.examName || <i>Nenhuma atividade atual</i>}
          {currentSituation?.isInInstructions ? ' (nas instruções)' : ''}
        </strong>
      </div>
    )
  }

  const renderNumCorrectAnswers = () => {
    return isLoading ? (
      <Skeleton width={80} />
    ) : (
      <>
        Acertos:{' '}
        <strong>
          {(currentSituation?.examName && data?.current.numCorrectAnswers) ||
            '-'}
        </strong>
      </>
    )
  }

  const renderCurrentItem = () => {
    return isLoading ? (
      <Skeleton width={80} />
    ) : (
      <>
        Questão atual:{' '}
        <strong>
          {(currentSituation?.examName && currentSituation?.itemPosition) ||
            '-'}
        </strong>
      </>
    )
  }

  const renderCurrentTotal = () => {
    return isLoading ? (
      <Skeleton width={80} />
    ) : (
      <>
        Total de Questões:{' '}
        <strong>
          {(currentSituation?.examName && data?.current.numTotalAnswers) || '-'}
        </strong>
      </>
    )
  }
  const renderNumWrongAnswers = () => {
    return isLoading ? (
      <Skeleton width={80} />
    ) : (
      <>
        Erros:{' '}
        <strong>
          {(currentSituation?.examName && data?.current.numWrongAnswers) || '-'}
        </strong>
      </>
    )
  }

  const renderInternetDetails = () => {
    let internetQualityClass = 'normal'
    if (currentSituation) {
      const delay = currentSituation.averageInternetDelay || 1000
      if (delay <= averageLatency / 2) {
        internetQualityClass = 'good'
      } else if (delay >= averageLatency + averageLatency / 2) {
        internetQualityClass = 'bad'
      }
    }
    return isLoading ? (
      <Skeleton width={80} />
    ) : (
      <>
        <span className="internet-delay">
          Tempo médio de resposta(milissegundos):{' '}
        </span>
        <strong className={`${internetQualityClass} internet-delay`}>
          {`${(currentSituation?.averageInternetDelay || 0).toFixed(2)}ms`}
        </strong>
        <div className="exam-details">
          <ul>
            <li>
              Tempo de resposta atual (milissegundos):{' '}
              {(currentSituation?.lastMeasuredDelay || 0).toFixed(2)}
            </li>
            {/* <li>
              Tempo de resposta médio (milissegundos):{' '}
              {(currentSituation?.averageInternetDelay || 0).toFixed(2)}
            </li> */}
          </ul>
          <p className="internet-delay acceptable-latency">
            Tempo médio de resposta aceitável (milissegundos): {averageLatency}
          </p>
        </div>
      </>
    )
  }

  const renderTotalAndFinished = () => {
    const finishedText =
      data?.finished !== undefined && data?.total !== undefined ? (
        <strong>
          {data?.finished} de {data?.total}
        </strong>
      ) : (
        '-'
      )
    return isLoading ? (
      <Skeleton width={80} />
    ) : (
      <>Provas finalizadas: {finishedText}</>
    )
  }

  const renderAllApplications = () => {
    return isLoading ? (
      <Skeleton width={80} />
    ) : (
      data?.allApplications.map((application) => {
        return (
          <>
            <div className="exam-details">
              <strong>{application.exam.name}:</strong>
              <ul>
                <li>
                  Início:{' '}
                  {application.startedAt
                    ? moment(application.startedAt).format('HH:mm:ss')
                    : '-'}
                </li>
                <li>
                  Fim:{' '}
                  {application.finishedAt
                    ? moment(application.finishedAt).format('HH:mm:ss')
                    : '-'}
                </li>
                <li>Acertos: {application.numCorrectAnswers || '-'}</li>
                <li>Erros: {application.numWrongAnswers || '-'}</li>
              </ul>
            </div>
          </>
        )
      })
    )
  }

  const error = currentSituationError || dashboardVideoApplicationError

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '47px',
          marginLeft: '4px'
        }}
      >
        <ApplicationStatusIcon candidate={candidate} />
        <Button
          action="unstyled"
          onClick={handleClick}
          style={{ fontSize: 18 }}
        >
          <FontAwesomeIcon icon="info-circle" />
        </Button>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        // Renders popover just below the button instead of in front of it
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleClose}
      >
        <div className={className}>
          {error && !isLoading ? (
            <CardFetchError onRetry={handleRefetch} />
          ) : (
            <>
              <div>{renderExamName()}</div>
              <div>{renderNumCorrectAnswers()}</div>
              <div>{renderNumWrongAnswers()}</div>
              <div>{renderCurrentItem()}</div>
              <div>{renderCurrentTotal()}</div>
              <hr></hr>
              <div>{renderInternetDetails()}</div>
              <hr></hr>
              <div>{renderTotalAndFinished()}</div>
              <div>{renderAllApplications()}</div>
            </>
          )}
        </div>
      </Popover>
    </>
  )
}

export default styled(ExamInfoIndicator)`
  padding: 10px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 350px;

  > div {
    margin: 6px;
  }

  hr {
    width: 100%;
  }

  .infos-card {
    font-size: 14px;
    margin-top: -2px;
  }

  .candidate-name {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .header-wrapper {
    display: flex;
    flex-direction: column;
  }

  .exam-details {
    font-size: 12px;
  }

  .good {
    color: green;
  }

  .normal {
    color: #d9c032;
  }

  .bad {
    color: red;
  }
  .internet-delay {
    font-size: 12px;
  }
  .acceptable-latency {
    font-weight: 600;
  }
`
