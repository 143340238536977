import React, { useEffect, useState } from 'react'
import { IRoomListState } from 'types'
import { Formik, FormikValues } from 'formik'
import CardList from 'components/CardList'
import PaginationFooter from 'components/PaginationFooter'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import devices from 'utils/devices'
import Page from 'components/Page'
import Skeleton from 'react-loading-skeleton'
import ResultsFound from 'components/ResultsFound'
import ProctorState from 'states/ProctorsState'
import RoomCard from 'components/Proctors/RoomCard'
import IsRecordingInput from 'components/Proctors/StatusInput'
import BorderRoomCard from 'components/Proctors/BorderRoomCard'

type ProctorsListProps = {
  className?: string
}

const ProctorsList = ({ className }: ProctorsListProps) => {
  const { t } = useTranslation()
  const [initialValues, setInitialValues] = useState<FormikValues>({})
  const [loadingInitialValues, setLoadingInitialValues] = useState<boolean>(
    true
  )

  useEffect(() => {
    setInitialValues(
      JSON.parse(localStorage.getItem('room-list-filter') || '{}')
    )
    setLoadingInitialValues(false)
  }, [])

  return (
    <Page customWidth={1366}>
      {!loadingInitialValues && (
        <ProctorState initialValues={initialValues}>
          {({
            fetchingProctors,
            proctorsList,
            totalItems,
            handlePageChange,
            pageSize,
            numPages,
            onFilter
          }: IRoomListState) => (
            <div className={className}>
              {!loadingInitialValues && (
                <div className="parent-wrapper">
                  <div className="filter-wrapper">
                    <Formik initialValues={initialValues} onSubmit={onFilter}>
                      {(formik) => (
                        <BorderRoomCard>
                          <IsRecordingInput />
                        </BorderRoomCard>
                      )}
                    </Formik>
                  </div>
                  <div className="card-list-wrapper">
                    <CardList>
                      {fetchingProctors && (
                        <Skeleton height={150} count={pageSize} />
                      )}
                      <ResultsFound resultsFound={totalItems} />
                      {!fetchingProctors &&
                        proctorsList.map((results) => (
                          <RoomCard key={results?.id} room={results} />
                        ))}
                    </CardList>
                    <PaginationFooter
                      pageSize={pageSize}
                      pageCount={numPages}
                      onPageChange={handlePageChange}
                      disablePageSizeChange
                    >
                      <p>
                        {t('Showing {{listLenght}} of {{totalList}} found', {
                          listLenght: proctorsList.length,
                          totalList: totalItems
                        })}
                      </p>
                    </PaginationFooter>
                  </div>
                </div>
              )}
            </div>
          )}
        </ProctorState>
      )}
    </Page>
  )
}

export default styled(ProctorsList)`
  .parent-wrapper {
    display: flex;
    column-gap: 20px;
  }

  @media (max-width: ${devices.mobileL}) {
    .parent-wrapper {
      flex-direction: column;
      row-gap: 20px;
      column-gap: 0;
    }
  }

  .filter-wrapper {
    max-width: 400px;

    @media (min-width: ${devices.tablet}) {
      min-width: 400px;
      column-gap: 20px;
    }
  }

  .card-list-wrapper {
    padding-left: 1rem;
    flex-grow: 6;
  }
`
