import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faUpload } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  uploadingAttachment?: any
  attachments: any
  attachmentsLimit: any
  onFileSelect?: any
}

const ViewFile = ({
  uploadingAttachment,
  attachments,
  attachmentsLimit,
  onFileSelect
}: Props) => {
  return (
    <div className="attachment-container">
      {onFileSelect && onFileSelect ? (
        <>
          <i className="attachment-helper-text">
            Apenas {attachmentsLimit} anexo{attachmentsLimit > 1 && 's'} pode
            ser enviado por recurso.
          </i>
          <Button
            onClick={() => document.getElementById('attach-input').click()}
            style={{
              width: '150px',
              margin: '30px 0px 0px 30px'
            }}
            variant="contained"
            color="primary"
            disabled
            startIcon={<FontAwesomeIcon icon={faUpload} />}
          >
            Anexar
          </Button>

          {attachments &&
            attachments.map((att, index) => (
              <div key={index} className="view-attachment">
                <a href={att.url} target="_blank" rel="noreferrer">
                  {att.filename}
                </a>
              </div>
            ))}
          {uploadingAttachment && (
            <FontAwesomeIcon
              icon={faSpinner}
              pulse
              size="2x"
              style={{ marginLeft: '5%' }}
            />
          )}

          <input
            type="file"
            style={{ visibility: 'hidden', height: 0 }}
            name="attachment"
            id="attach-input"
            onChange={onFileSelect}
            disabled={attachments.length >= attachmentsLimit}
          />
        </>
      ) : (
        <>
          {attachments &&
            attachments.map((att, index) => (
              <div key={index} className="view-attachment">
                <a href={att.url} target="_blank" rel="noreferrer">
                  {att.filename}
                </a>
              </div>
            ))}
        </>
      )}
    </div>
  )
}

export default styled(ViewFile)`
  .attachment-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  .attachment-helper-text {
    font-size: 12px;
    margin-top: 10px;
  }
  .view-attachment {
    padding-left: 30px;
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
  }
  .fa-icon {
    cursor: pointer;
    padding: 7px;
    border-radius: 50%;
    background-color: #ddd;
    color: #333;
  }

  @media (max-width: 800px) {
    .appeal-issuing-form {
      width: 100%;
    }
    .view-attachment {
      width: 80%;
    }
  }
`
