import moment from 'moment'
import { ICollection } from 'types'

export const collectionIsFinished = (collection: ICollection) => {
  if (!collection) {
    return false
  }

  const collectionFinishDate = collection.endTime
  const now = moment(new Date())
  const collectionEnd = moment(collectionFinishDate)

  return collectionEnd.isSameOrBefore(now)
}
