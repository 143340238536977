import { useSnackbar, VariantType } from 'notistack'
import { ReactNode, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { RollbarErrorTracking } from 'infra/rollbar'

/**
 * Use this hook to handle user feedback.
 *
 * This is currently being done using snackbars (another fun name for toasts).
 * By using this hook, we abstract this implementation and make
 * changes easier.
 *
 * It also automatically reports errors to a notification service (like Rollbar).
 */
const useFeedback = () => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const handleFeedback = useCallback(
    (variant: VariantType, message?: ReactNode, redirectTo?: string) => {
      enqueueSnackbar(message, { variant })
      redirectTo && history.push(redirectTo)
    },
    [enqueueSnackbar, history]
  )

  const handleErrorFeedback = useCallback(
    (error: Error, message: ReactNode, redirectTo?: string) => {
      handleFeedback('error', message, redirectTo)
      RollbarErrorTracking.logError(error)
    },
    [handleFeedback]
  )

  const handleSuccessFeedback = useCallback(
    (message: ReactNode, redirectTo?: string) => {
      handleFeedback('success', message, redirectTo)
    },
    [handleFeedback]
  )

  const handleInfoFeedback = useCallback(
    (message: ReactNode, redirectTo?: string) => {
      handleFeedback('info', message, redirectTo)
    },
    [handleFeedback]
  )

  return { handleErrorFeedback, handleSuccessFeedback, handleInfoFeedback }
}

export default useFeedback
