import AnswerSheetItemContent from 'pages/AnswerSheet/components/AnswerSheetItemContent'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Modal from '../Modal/Modal'
import ItemTitle from 'pages/AnswerSheet/components/ItemTitle'
import axios from 'axios'
import { API_HOST } from 'consts'
import AlternativesBarChart from 'components/StatisticsDashboardComponents/AlternativesBarChart'
import { AnswerSheet, AnswerSheetItem } from 'data/domain/exams'

type QuestionModalProps = {
  isOpen: boolean
  setIsOpen: Function
  className?: string
  answerSheet?: AnswerSheet
  item: number
  examId: number
}

const QuestionModal = ({
  isOpen,
  setIsOpen,
  className,
  answerSheet,
  item,
  examId
}: QuestionModalProps) => {
  const { t } = useTranslation()

  const [alternativesCount, setAlternativesCount] = useState([])
  const [itemContent, setItemContent] = useState(undefined)

  const fetchAlternatives = useCallback(
    async (examId: number, itemId: number) => {
      const response = await axios.get(
        `${API_HOST}/v1/exams/${examId}/alternative_frequency?item=${itemId}`
      )
      setAlternativesCount(response.data)
    },
    []
  )

  const fetchItem = async (itemId: number) => {
    const response = await axios.get(`${API_HOST}/v1/items/${itemId}`)
    setItemContent(response.data)
  }

  useEffect(() => {
    if (item && examId) {
      fetchAlternatives(examId, item)
      fetchItem(item)
    }
  }, [item, examId, fetchAlternatives])

  if (!answerSheet) return <></>

  return (
    <Modal
      className={className}
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      title={t('Item view')}
    >
      <div>
        <div className="title-wrapper">
          <div className="item-wrapper">
            <ItemTitle className="colorText">Código do item: {item}</ItemTitle>
          </div>
        </div>
        <AnswerSheetItemContent
          item={itemContent}
          correctAnswer={
            answerSheet.find((i: AnswerSheetItem) => i.itemId === item)?.answer
          }
          correctBulkAnswer={[]}
          isCancelled={
            answerSheet.find((i: AnswerSheetItem) => i.itemId === item)
              ?.isCancelled
          }
          postAlternativeExplanationVisualization={false}
        />
        <AlternativesBarChart dataset={alternativesCount} />
      </div>
    </Modal>
  )
}

export default styled(QuestionModal)`
  .colorText {
    color: #000;
  }

  .item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
  }
`
