import React from 'react'
import { FinishReason } from 'types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

type Props = {
  finishReason: FinishReason
}

const messages = {
  EXPIRED_GRACE_PERIOD:
    'You reached the grace period for this exam. You will not be able to start it.'
}

const FinishReasonIndicator = ({ finishReason }: Props) => {
  const message = messages[finishReason]
  const { t } = useTranslation()

  if (!message) {
    return null
  }

  return (
    <span>
      <FontAwesomeIcon icon="exclamation-circle" /> {t(message)}
    </span>
  )
}

export default FinishReasonIndicator
