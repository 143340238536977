export const normalize = (string: string) => {
  // Removes accents from string
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const cmpFormat = (string: string) => {
  // Formats string for making LIKE operations ignoring case and accents
  return normalize(string).toUpperCase()
}

export const filterBy = <T>(
  array: T[],
  getField: Function,
  value: string
): T[] => {
  const cmpValue = cmpFormat(value)
  return array.filter((d) => cmpFormat(getField(d)).includes(cmpValue))
}

export const sortBy = <T>(array: T[], getField: (obj: T) => any): T[] => {
  return array.sort((a, b) =>
    cmpFormat(getField(a)).localeCompare(cmpFormat(getField(b)))
  )
}

export const lowerCaseStringAndRemoveAccent = (value: string): string =>
  value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

export const stripHtml = (string: string) =>
  string?.replace(/(<([^>]+)>)/gi, '')
