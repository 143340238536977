import { BarChart } from 'd3plus-plot'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled, { ThemeContext } from 'styled-components'
import { IndividualReportCardContext } from '../../states/IndividualReportCardState'

type ComparativeChartProps = {
  className?: string
}

interface IChartData {
  id: string
  x: string
  y: number
  color: string
}

const ComparativeChart = ({ className }: ComparativeChartProps) => {
  const { individualReportCard, fetchingIndividualReportCard } = useContext(
    IndividualReportCardContext
  )
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  useEffect(() => {
    const data: IChartData[] = []

    if (!individualReportCard) {
      return
    }

    individualReportCard.exams.forEach((e) => {
      // Add user grade
      data.push({
        id: t('You'),
        x: e.exam.name,
        y: Math.trunc((e.generalGrade / e.totalItens) * 100),
        color: 'principal'
      })

      // Add class grade
      data.push({
        id: t('All students'),
        x: e.exam.name,
        y: Math.trunc((e.classGrade / e.totalItens) * 100),
        color: 'neutral'
      })
    })

    new BarChart()
      .select('.chart')
      .data(data)
      .groupBy('id')
      .groupPadding(40)
      .height(400)
      .shapeConfig({
        label: false,
        fill: function (d) {
          return theme.reportColors[d.color]
        },
        width: 30
      })
      .tooltipConfig({
        title: function (d) {
          return `${d.id}: ${d.y}%`
        }
      })
      .xConfig({
        title: t('Exam')
      })
      .yConfig({
        title: t('Grade')
      })
      .render()
  }, [individualReportCard, t, theme.reportColors])

  return (
    <>
      <h2>{t('Comparative Chart')}</h2>
      {fetchingIndividualReportCard && <Skeleton height={500} />}
      <div className={`${className} chart`} />
    </>
  )
}

export default styled(ComparativeChart)`
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
`
