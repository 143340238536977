import React, { useState } from 'react'
import styled from 'styled-components'
import { IExamDashboardApplication } from 'types'
import VideoProcessStatus from '../VideoProcessStatus'
import Button from 'components/Button'
import axios from 'axios'
import { API_HOST } from 'consts'
type OralVideoProps = {
  className?: string
  application: IExamDashboardApplication
}

const OralVideo = ({ className, application }: OralVideoProps) => {
  const [videoLink, setVideoLink] = useState('')

  const handleGetVideo = async () => {
    const startTime = application?.exam.timeWindows[0]?.startTime

    await axios
      .get(`${API_HOST}/video-oral`, {
        params: {
          user_id: application.user.id,
          provider_codename: application.user.provider.codename,
          selected_date: startTime
        }
      })
      .then((res) => setVideoLink(res.data.video))
      .catch((err) => console.error(err))
  }

  return (
    <div className={className}>
      {videoLink === '' ? (
        <div className="main">
          <VideoProcessStatus
            videoProcessStatus={application.videoProcessStatus}
          />
          <Button action="primary" onClick={handleGetVideo}>
            {' '}
            Ver vídeo{' '}
          </Button>
        </div>
      ) : (
        <div className="video">
          {/* <video autoPlay src={videoLink}></video> */}
          <video src={videoLink} controls>
            {' '}
          </video>
        </div>
      )}
    </div>
  )
}

export default styled(OralVideo)`
  padding: 0 1rem;

  p {
    font-weight: bold;
  }

  .main {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;

    ${Button} {
      margin-top: 10px;
      width: 220px;
    }
  }

  .video {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;

    ${Button} {
      margin-top: 10px;
      width: 200px;
    }
  }
`
