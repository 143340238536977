import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

type RoomSubtitlesInfosProps = {
  className?: string
  proctorName?: any
}

const RoomSubtitlesInfos = ({
  proctorName,
  className
}: RoomSubtitlesInfosProps) => {
  const { t } = useTranslation()

  return (
    <>
      <span className={className}>
        <FontAwesomeIcon className="icon-space" icon={faUser} />
        {t('Aplicador').toUpperCase()}: Aplicador {proctorName}
      </span>
    </>
  )
}

export default styled(RoomSubtitlesInfos)`
  font-size: 12px;

  .icon-space {
    margin-right: 10px;
  }
`
