import Axios from 'axios'
import { API_HOST } from 'consts'
import { IItem } from 'types'

export const fetchItem = async (itemId: number) => {
  const response = await Axios.get<IItem>(`${API_HOST}/v1/items/${itemId}`)
  return response.data
}

export const fetchAnswerSheetItem = async (itemId: number) => {
  const response = await Axios.get<IItem>(
    `${API_HOST}/v1/items/${itemId}/answer_sheet`
  )
  return response.data
}
