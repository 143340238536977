import firebase, { db } from 'infra/firebase'

export default class FirebaseSuspectResponse {
  application: string

  constructor(application: string) {
    this.application = application
  }

  async get() {
    const findOne = await db
      .collection('suspectResponse')
      .doc(this.application)
      .get()
    return findOne?.data()?.suspectAnswer
  }

  async set() {
    const docRef = await db.collection('suspectResponse').doc(this.application)
    const findOne = await db
      .collection('suspectResponse')
      .doc(this.application)
      .get()

    if (findOne.exists) {
      await docRef.update({
        suspectAnswer: firebase.firestore.FieldValue.increment(1)
      })
    } else {
      await docRef.set({
        suspectAnswer: 1
      })
    }
  }

  async clear() {
    const docRef = await db.collection('suspectResponse').doc(this.application)
    docRef.update({
      suspectAnswer: 0
    })
  }
}
