import Modal from 'components/Modal/Modal'
import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './Button'

type UserResulAppealDetailProps = {
  className?: string
  data?: any
  appeal?: any
}

const UserResulAppealDetail = ({
  className,
  appeal,
  data
}: UserResulAppealDetailProps) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Modal isOpen={isOpen} title="Detalhes do recurso" onClose={handleClose}>
      <div className={className}>
        <div className="modal-body">
          <div className="modal-title">
            <br />
            <span>Resultado: </span>
            {data?.selectionProcessName}
            <br />
            <br />
            <span>Etapa: </span>
            {data?.sectionName}
            <br />
            <br />
            <span>Criado em: </span>
            {appeal[0]?.createdAt}
            <br />
            <br />
          </div>
          <div className="modal-buttons">
            <Button
              className="button"
              action="primary"
              type="button"
              onClick={() => handleClose()}
            >
              Fechar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default styled(UserResulAppealDetail)`
  display: flex;

  .modal-body {
    .modal-title {
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 1.2rem;
    .button {
      margin-right: 10px;
    }
  }
`
