import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { API_HOST } from '../../consts'
import InputSelect from './InputSelect'

const ClassInput = ({
  formikValues,
  basic,
  params,
  label = 'Class',
  ...props
}: {
  params?: any
  formikValues?: any
  basic?: boolean
  label?: string
}) => {
  const { t } = useTranslation()
  // eslint-disable-next-line
  const [data, setData] = useState()
  const fetchClass = useCallback(
    debounce(async (formikValues) => {
      const response = await axios.get(
        `${API_HOST}/classes-users/user_classes`,
        {
          params: {
            grade: formikValues?.grade?.id,
            school: formikValues?.school?.codIntegrador,
            ...params
          }
        }
      )
      setData(response.data)
      // return response.data
    }, 500),
    [params]
  )
  useEffect(() => {
    fetchClass(formikValues)
  }, [formikValues])

  return (
    <div
      onClick={() => {
        console.log(formikValues)
      }}
    >
      <InputSelect
        label="Turmas"
        options={data}
        placeholder="Turmas"
        name="classes"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        {...props}
      />
    </div>
  )
}

export default ClassInput
