import { SnackbarProvider } from 'notistack'
import { COORDINATOR_ALLOWED_IN_DASHBOARD } from 'consts'
import moment from 'moment'
import DashboardPreFilter from 'pages/DashboardPreFilter'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import VideoStreamingState from 'states/VideoStreamingState'
import './App.css'
import 'react-datepicker/dist/react-datepicker.css'
import ErrorBoundaryRollbar from './components/ErrorBoundaryRollbar'
import ExamDashboardHeader from './components/ExamDashboardComponents/Header'
import PrivateRoute from './components/PrivateRoute'
import StyledToastContainer from './components/StyledToastContainer'
import UserRedirect from './components/UserRedirect'
import useAnswerSync from './hooks/useAnswerSync'
import useGoogleAnalytics from './hooks/useGoogleAnalytics'
import Application from './pages/Application'
import Appeal from './pages/Appeal'
import ApplicationList from './pages/ApplicationList'
import BrowserCompatibility from './pages/BrowserCompatibility'
import CameraPermission from './pages/CameraPermission'
import Error from './pages/Error'
import GradesDownload from './pages/Grades/Download'
import ImportExamPage from './pages/ImportExamPage'
import ImportVectoresPage from './pages/ImportVectoresPage'
import ImportStudentPage from './pages/ImportStudentPage'
import IndividualReportCard from './pages/IndividualReportCard'
import IndividualReportCardList from './pages/IndividualReportCardList'
import EmailRecipientList from './pages/EmailRecipientList'
import EmailRecipientFormPage from './pages/EmailRecipientFormPage'
import EmailList from './pages/EmailList'
import Login from './pages/Login'
import ProviderLogin from './pages/ProviderLogin'
import './register-fa-icons'
import AuthState from './states/AuthState'
import ConfigState from './states/ConfigState'
import ModalState from './states/ModalState'
import NetworkState from './states/NetworkState'
import DefaultTheme from './themes/DefaultTheme'
import CameraPermissionTutorial from 'pages/CameraPermissionTutorial'
import BulkCreateApplicationPage from 'pages/BulkCreateApplication/BulkCreateApplicationPage'
import PermissionsManagement from 'pages/PermissionsManagement'
import AnswerSheet from 'pages/AnswerSheet/AnswerSheet'
import PostExam from 'pages/PostExam/PostExam'
import ResetPasswordPage from 'pages/ResetPassword'
import RequestPasswordReset from 'pages/RequestPasswordReset'
import ResetPasswordExpired from 'pages/ResetPasswordExpired'
import UploadUserDocument from 'pages/UploadUserDocument'
import AnswersList from 'pages/AnswerSheet/AnswersList'
import ChatManagement from 'pages/ChatManagement'
import PerformanceByHierarchy from 'pages/PerformanceByHierarchy'
import AppealResponse from 'pages/AppealResponse'
import AppealsList from 'pages/AppealsList'
import ItemsList from 'pages/ItemsList'
import ProctorsList from 'pages/proctosList'
import ErrorFirefoxVersion from 'pages/ErrorFirefoxVersion'
import EventsCalendar from 'pages/EventsCalendar'
import BillBoardPage from 'pages/BillBoardPage'
import CreateNoticePage from 'pages/CreateNoticePage'
import EducatBridgePage from 'pages/EducatBridgePage'
import EditNoticePage from 'pages/EditNoticePage'
import ReportStudent from 'pages/ReportStudent'
import ReportStudentSkillLevel from 'pages/ReportStudentSkillLevel'
import ConfigSchedulingMenu from 'pages/ConfigSchedulingMenu'

function Routes() {
  useGoogleAnalytics()
  useAnswerSync()

  moment.locale('pt-BR')

  const dashboardPermissions = [
    'ADMINISTRADOR',
    'PROCTOR',
    'COMISSION',
    'Professor',
    'CORRECTOR',
    'Diretor'
  ]
  if (COORDINATOR_ALLOWED_IN_DASHBOARD) {
    dashboardPermissions.push('COORDINATOR')
  }

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/educat-bridge/:token" component={EducatBridgePage} />
      <PrivateRoute exact path="/" render={() => <UserRedirect />} />
      <PrivateRoute exact path="/applications" component={ApplicationList} />
      <Route path="/applications/:applicationId(\d+)" component={Application} />
      <PrivateRoute
        path="/exams/dashboard"
        component={ExamDashboardHeader}
        allowedGroups={dashboardPermissions}
      />
      <PrivateRoute
        path="/exams/dashboard-open"
        component={ExamDashboardHeader}
        allowedGroups={dashboardPermissions}
      />
      <PrivateRoute
        path="/exams/dashboard-pre-filter"
        component={DashboardPreFilter}
        allowedGroups={[
          'ADMINISTRADOR',
          'COORDINATOR',
          'PROCTOR',
          'COMISSION',
          'Professor',
          'PROFESSOR',
          'CORRECTOR',
          'Diretor'
        ]}
      />
      <PrivateRoute
        path="/report-cards/individual/:reportCardId(\d+)"
        component={IndividualReportCard}
        allowedGroups={[
          'ADMINISTRADOR',
          'ALUNO',
          'RESPONDENTE',
          'Professor',
          'COORDINATOR',
          'RESPONSIBLE'
        ]}
      />

      <PrivateRoute
        path="/aluno-relatorio/:id"
        component={ReportStudent}
        allowedGroups={['ALUNO', 'RESPONDENTE', 'RESPONSIBLE']}
      />

      <PrivateRoute
        path="/aluno-acertos/:id"
        component={ReportStudentSkillLevel}
        allowedGroups={['ALUNO', 'RESPONDENTE', 'RESPONSIBLE']}
      />

      <PrivateRoute
        path="/report-cards/individual"
        component={IndividualReportCardList}
        allowedGroups={['ADMINISTRADOR', 'Professor', 'COORDINATOR']}
      />
      <PrivateRoute
        path="/events"
        component={EventsCalendar}
        allowedGroups={['ALUNO', 'RESPONDENTE', 'Professor', 'RESPONSIBLE']}
      />
      <PrivateRoute
        path="/grades/download"
        component={GradesDownload}
        allowedGroups={['ADMINISTRADOR', 'Professor', 'COORDINATOR']}
      />
      <PrivateRoute
        path="/hierarchy/performance"
        component={PerformanceByHierarchy}
        allowedGroups={['ADMINISTRADOR', 'Professor', 'COORDINATOR']}
      />
      <Route path="/reset_password/:token" component={ResetPasswordPage} />
      <Route path="/reset-password-expired" component={ResetPasswordExpired} />
      <Route path="/forgot-password" component={RequestPasswordReset} />
      <PrivateRoute
        path="/plural-quadro-de-avisos"
        component={BillBoardPage}
        allowedGroups={[
          'ALUNO',
          'RESPONDENTE',
          'ADMINISTRADOR',
          'Professor',
          'RESPONSIBLE'
        ]}
      />
      <PrivateRoute
        path="/plural-criar-aviso"
        component={CreateNoticePage}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/plural-editar-aviso/:id"
        component={EditNoticePage}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      {/*  */}
      <PrivateRoute
        path="/imports/exam"
        component={ImportExamPage}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/imports/vectors"
        component={ImportVectoresPage}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/imports/students"
        component={ImportStudentPage}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/upload-user-document"
        component={UploadUserDocument}
        allowedGroups={[
          'ADMINISTRADOR',
          'Professor',
          'ALUNO',
          'RESPONDENTE',
          'COORDINATOR'
        ]}
      />
      <PrivateRoute
        path="/camera-permission"
        component={CameraPermission}
        allowedGroups={[
          'ADMINISTRADOR',
          'Professor',
          'ALUNO',
          'RESPONDENTE',
          'COORDINATOR'
        ]}
      />
      <PrivateRoute
        path="/camera-permission-tutorial"
        component={CameraPermissionTutorial}
      />
      <PrivateRoute
        exact
        path="/emails/recipients"
        component={EmailRecipientList}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/emails/recipients/form"
        component={EmailRecipientFormPage}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/emails"
        component={EmailList}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/batchapplications/exams"
        component={BulkCreateApplicationPage}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/users/permissions"
        component={PermissionsManagement}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/users/chat-management"
        component={ChatManagement}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/config-scheduling/menu"
        component={ConfigSchedulingMenu}
        // allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/post-exams"
        component={PostExam}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/appeals/dashboard"
        component={AppealsList}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/proctorsList/dashboard"
        component={ProctorsList}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/items-list/dashboard"
        component={ItemsList}
        allowedGroups={['ADMINISTRADOR', 'Professor']}
      />
      <PrivateRoute
        path="/answer-list/:applicationId"
        component={AnswersList}
        allowedGroups={[
          'ADMINISTRADOR',
          'Professor',
          'ALUNO',
          'RESPONDENTE',
          'RESPONSIBLE'
        ]}
      />
      <Route
        path="/external-auth/:provider/token/:token"
        exact
        component={ProviderLogin}
      />
      <PrivateRoute
        path="/answer-sheets/:applicationId"
        component={AnswerSheet}
      />
      <PrivateRoute
        path="/appeals-response/:appealId"
        component={AppealResponse}
      />
      <Route path="/appeals" component={Appeal} />
      <Route path="/erro-firefox" component={ErrorFirefoxVersion} />
      <Route path="*" component={Error} />
    </Switch>
  )
}

export type AppProps = {
  browserIsCompatible: boolean | number
}

const App = ({ browserIsCompatible }: AppProps) => {
  if (browserIsCompatible) {
    return (
      <ErrorBoundaryRollbar>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <BrowserRouter>
            <ConfigState>
              <AuthState>
                {() => (
                  <>
                    <DefaultTheme>
                      <VideoStreamingState>
                        <ModalState>
                          <NetworkState>
                            <>
                              <StyledToastContainer autoClose={8000} />
                              <Routes />
                            </>
                          </NetworkState>
                        </ModalState>
                      </VideoStreamingState>
                    </DefaultTheme>
                  </>
                )}
              </AuthState>
            </ConfigState>
          </BrowserRouter>
        </SnackbarProvider>
      </ErrorBoundaryRollbar>
    )
  } else {
    return (
      <ErrorBoundaryRollbar>
        <DefaultTheme>
          <BrowserCompatibility />
        </DefaultTheme>
      </ErrorBoundaryRollbar>
    )
  }
}

export default App
