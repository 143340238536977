import styled from 'styled-components'

const Card = styled.div`
  padding: 16px;
  background-color: white;
  border: 2px solid ${(props) => props.theme.colors.grayLight};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  gap: 16px;
  border: 1px solid #DFDFDF;
  
  
  :hover {
    ${(props) =>
      props.onClick &&
      `
      background-color: ${props.theme.colors.grayLight};
    `}
  }
`

export default Card
