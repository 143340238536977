import ConfigContext from 'contexts/ConfigContext'
import debounce from 'debounce-promise'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import Tag from 'components/Tag'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import FetchItemError from './FetchItemError'
import { syncAnswers } from '../../hooks/useAnswerSync'
import { AnswerContext } from '../../states/AnswerState'
import AlternativeGroup from '../AlternativeGroup/AlternativeGroup'
import FreeResponse from '../FreeResponse'
import Html from '../Html'
import ReloadItemHelper from './ReloadItemHelper'
import CanvasContainer from './CanvasContainer'
import TooltipButton from 'components/TooltipButton'
import { Box } from '@material-ui/core'
import ItemTableStyle from 'syles/ItemTableStyle'
import { walkOnHTMLElements } from 'utils/dom'
import { ApplicationContext } from 'states/ApplicationState'
import { SnackbarProvider } from 'notistack'
import RepertorizationItem from 'components/Repertorization/RepertorizationItem'
import FlashCard from 'pages/AnswerSheet/components/FlashCard'
import MultipleFreeResponse from 'components/MultipleFreeResponse'
import Lacuna from 'components/Lacuna'
import MultipleLinearScaleItem from './MultipleLinearScaleItem'

type ItemProps = {
  className?: string
  isTextMarkerEnabled: boolean
  answers?: any
  answer?: any
  items?: any
}

const Item = ({
  className,
  isTextMarkerEnabled,
  answers,
  items,
  answer
}: ItemProps) => {
  const { item, fetchingItem, fetchItemError, fetchItem } = useContext(
    AnswerContext
  )
  const { application } = useContext(ApplicationContext)
  const canvasContainerRef = useRef<HTMLDivElement>(null)
  const { disableItemTextHighlight } = useContext(ConfigContext)
  const [fontSize, setFontSize] = useState(0)
  const maxFontSize = 100
  const minFontSize = -20
  const { t } = useTranslation()

  const changeFontSize = useCallback(
    (operator: '+' | '-') => {
      const jumpValue = operator === '+' ? 10 : -10
      const newFontSize = fontSize + jumpValue

      if (newFontSize > minFontSize && newFontSize < maxFontSize) {
        setFontSize(newFontSize)
      }
    },
    [fontSize, maxFontSize, minFontSize]
  )

  const handleFontSizeChange = (newFontSize: number) => {
    const itemElement = document.getElementById('item-content')

    if (itemElement) {
      itemElement.style.fontSize = `${100 + newFontSize}%`
    }
    walkOnHTMLElements(itemElement, (node) => {
      const elementFontSize = +node?.style.fontSize.replace('px', '')
      if (elementFontSize > 0) {
        node.style.fontSize = ''
      }
    })
  }
  useEffect(() => {
    if (!item) return
    handleFontSizeChange(fontSize)
  }, [item, fontSize])

  // eslint-disable-next-line
  const callSyncAnswers = useCallback(
    debounce(async () => {
      return await syncAnswers()
    }, 1500),
    []
  )

  const onItemClick = () => {
    callSyncAnswers()
  }

  if (fetchItemError) {
    return <FetchItemError onRetry={() => fetchItem(true)} />
  }

  if (fetchingItem || !item) {
    return <Skeleton height={1000} />
  }

  return (
    <div id="item-content" className={className} onClick={onItemClick}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div className="card-header">
          <p style={{ marginBottom: '1rem', marginTop: '1rem' }}>
            {answers.length === 0 ? (
              <Skeleton height={18} width={125} />
            ) : (
              <span className="answer-label">
                {answer?.verboseName
                  ? `${answer?.verboseName}`
                  : t('{{position}} de {{total}}', {
                      position: answer?.position,
                      total: answers?.length
                    })}
              </span>
            )}
            {items?.category === 'FREE_RESPONSE' && (
              <Tag color="rebeccapurple">{t('Free response')}</Tag>
            )}
            {items?.category === 'MULTIPLE_ALTERNATIVES' && (
              <>
                <Tag color="rebeccapurple">{t('Multiple Alternatives')}</Tag>
                {items.maxAllowedToMark > 0 && (
                  <span className="subtitle">
                    Selecione {item.maxAllowedToMark} alternativas
                  </span>
                )}
              </>
            )}
          </p>
        </div>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Box marginLeft="1rem">
            <TooltipButton
              onClick={() => changeFontSize('-')}
              tooltipText="Diminuir fonte"
              action="icon"
              style={{
                backgroundColor: 'white',
                borderColor: 'grey',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)'
              }}
            >
              -A
            </TooltipButton>
          </Box>
          <Box marginLeft="1rem">
            <TooltipButton
              onClick={() => changeFontSize('+')}
              tooltipText="Aumentar fonte"
              action="icon"
              style={{
                backgroundColor: 'white',
                borderColor: 'grey',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)'
              }}
            >
              +A
            </TooltipButton>
          </Box>
        </Box>
      </div>
      <div ref={canvasContainerRef} className="question-card" draggable={false}>
        <CanvasContainer
          isEnabled={isTextMarkerEnabled}
          canvasHeight={canvasContainerRef.current?.offsetHeight}
          canvasWidth={canvasContainerRef.current?.offsetWidth}
        >
          {item.category === 'FLASH_CARD' ? (
            <ItemTableStyle>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <FlashCard item={item} />
              </div>
            </ItemTableStyle>
          ) : item.category === 'MULTIPLE_FREE_RESPONSE' ? (
            <ItemTableStyle>
              <MultipleFreeResponse />
            </ItemTableStyle>
          ) : item.category === 'GAP_MULTIPLE_RESPONSE' ? (
            <ItemTableStyle>
              <Lacuna />
            </ItemTableStyle>
          ) : (
            <ItemTableStyle>
              <div>
                <Html noHighlight={disableItemTextHighlight}>
                  {item.introduction}
                </Html>
                {item.category === 'CONCEITO' ? (
                  <></>
                ) : (
                  <Html noHighlight={disableItemTextHighlight}>
                    {item.question}
                  </Html>
                )}
              </div>
            </ItemTableStyle>
          )}
        </CanvasContainer>
      </div>
      <ReloadItemHelper
        onReload={() => {
          fetchItem(true)
        }}
      />

      {item.category === 'REPERTORIZATION_TABLE' ? (
        <SnackbarProvider transitionDuration={0} maxSnack={1}>
          <RepertorizationItem application={application} />
        </SnackbarProvider>
      ) : item.category === 'MULTIPLE_LINEAR_SCALE' ? (
        <MultipleLinearScaleItem item={item} />
      ) : item.category === 'FREE_RESPONSE' ? (
        <FreeResponse />
      ) : (
        <AlternativeGroup item={item} />
      )}
    </div>
  )
}

export default styled(Item)`
  .zoom {
    margin: 0;
  }

  .question-card {
    position: relative;
    background-color: white;
    padding: 20px;
    color: black;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .question-card {
    img {
      max-width: 100%;
    }
  }

  .retry-button {
    margin-top: 5px;
  }

  @media (max-width: 600px) {
    max-width: 400px;
    overflow-x: scroll !important;

    img {
    }
  }
`
