import AppealDetail from 'components/AppealComponents/AppealDetail'
import AppealResponseForm from 'components/AppealComponents/AppealResponseForm'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppealResponseContext } from 'states/AppealResponseState'
import Page from 'components/Page'
import AppealResponseDetail from 'components/AppealComponents/AppealResponseDetail'
import Separator from 'components/ExamDashboardComponents/Separator'
import Card from 'components/Card/Card'

type AppealResponsePage = {
  className?: string
}

const AppealResponsePage = ({ className }: AppealResponsePage) => {
  const { appeal } = useContext(AppealResponseContext)

  return (
    <Page customWidth={1024}>
      <div className={className}>
        <div className="main">
          <Card>
            <AppealDetail appeal={appeal} />
            <Separator style={{ width: '100%' }} />
            {appeal?.status.canChangeAppeal && (
              <AppealResponseForm appeal={appeal} />
            )}
            {!appeal?.status.canChangeAppeal && <AppealResponseDetail />}
          </Card>
        </div>
      </div>
    </Page>
  )
}

export default styled(AppealResponsePage)`
  display: flex;
  justify-content: center;
`
