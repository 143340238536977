import axios from 'axios'
import React, { useCallback, useState } from 'react'
import { API_HOST } from '../../consts'
import SelectMultipleCategories from './SelectMultipleCategories'

type HierarchyInputSelectProps = {
  provider: string
}

const HierarchyInputSelect = ({ provider }: HierarchyInputSelectProps) => {
  const [categories, setCategories] = useState([])
  const fetchHierarchies = useCallback(async () => {
    const response = await axios.get(
      `${API_HOST}/v1/provider/${provider}/hierarchy_category`
    )
    return response.data
  }, [provider])

  const removeCategory = (e) => {
    const newValues = categories.filter(
      (category) => +e.currentTarget.id !== +category.id
    )

    setCategories(newValues)
  }

  const handleChange = useCallback(
    (value) => {
      if (categories.find((category) => category === value)) return
      setCategories([...categories, value])
      console.log(categories)
    },
    [categories, setCategories]
  )

  return (
    <>
      <SelectMultipleCategories
        id="hierarchies"
        defaultOptions
        loadOptions={fetchHierarchies}
        name={'hierarchies'}
        label={'Adicionar categorias'}
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) => d.name}
        onValueChange={handleChange}
        hierarchies={categories}
      />
      <span>
        <strong>Categorias Selecionadas:</strong>
      </span>
      {categories &&
        categories.map((category_codename) => {
          return (
            <span
              key={category_codename.id}
              id={category_codename.id}
              onClick={removeCategory}
            >
              {category_codename.codename}
            </span>
          )
        })}
    </>
  )
}

export default HierarchyInputSelect
