import Button from 'components/Button'
import CollectionInput from 'components/Inputs/CollectionInput'
import Page from 'components/Page'
import { allowedToCreateApplication } from 'data/apis/collections'
import { Form, Formik } from 'formik'
import React, { ReactNode, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BulkCreateApplicationContext } from 'states/BulkCreateApplicationState'
import styled from 'styled-components'
import BulkCreateApplicationPageInfo from './BulkCreateApplicationPageInfo'

type SelectCollectionProps = {
  className?: string
}

const SelectCollection = ({ className }: SelectCollectionProps) => {
  const { t } = useTranslation()
  const { currentStep, setSelectedCollections } = useContext(
    BulkCreateApplicationContext
  )
  const [checkedCollections, setCheckedCollections] = useState({})
  const [checkingExams, setCheckingExams] = useState(false)

  const formInitialValues: any = {
    collection: []
  }

  const handleSubmit = (formData: any) => {
    setSelectedCollections(formData.collection)
  }

  const validateCollectionAllowedToCreateApplication = useCallback(
    async (collection: any) => {
      if (checkedCollections[collection.id] !== undefined) return
      setCheckingExams(true)
      const allowed = await allowedToCreateApplication(collection.id)
      setCheckedCollections((prevState) => ({
        ...prevState,
        [collection.id]: allowed
      }))
      setCheckingExams(false)
    },
    [checkedCollections]
  )

  const validateForm = useCallback(
    async (values: any) => {
      const errors: { [key: string]: string | ReactNode } = {}
      const requiredMsg = t('This field is required.')
      const collections = values.collection

      if (collections.length === 0) {
        return { collection: requiredMsg }
      }

      await Promise.all(
        collections.map((c: any) =>
          validateCollectionAllowedToCreateApplication(c)
        )
      )

      const collectionsWithError = collections.filter(
        (c: { id: number }) => checkedCollections[c.id] === false
      )

      if (collectionsWithError.length > 0) {
        errors.collection = collectionsWithError.map(
          (c: { id: number; name: string }) => (
            <p key={c.id}>
              {c.name} possui atividades sem janelas de aplicação disponíveis.
            </p>
          )
        )
      }

      return errors
    },
    [checkedCollections, t, validateCollectionAllowedToCreateApplication]
  )

  if (currentStep !== 1) {
    return <></>
  }

  return (
    <div className={className}>
      <Page customWidth={1366}>
        <BulkCreateApplicationPageInfo
          title={t('Batch activity allocation')}
          instructions={t('Select one or more challenges')}
        />
        <Formik
          validate={validateForm}
          onSubmit={handleSubmit}
          initialValues={formInitialValues}
        >
          <Form>
            <CollectionInput isMulti />
            <div className="btn-group">
              <Button action="primary" disabled={checkingExams}>
                Avançar
              </Button>
            </div>
          </Form>
        </Formik>
      </Page>
    </div>
  )
}

export default styled(SelectCollection)`
  .btn-group {
    margin-top: 10px;
    display: grid;
    justify-content: end;
  }
`
