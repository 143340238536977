import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type BooleanIconProps = {
  value: boolean
  className?: string
}

const BooleanIcon = ({ value, className }: BooleanIconProps) => (
  <div className={className}>
    <FontAwesomeIcon
      className={value ? 'success' : 'danger'}
      icon={value ? 'check-circle' : 'times-circle'}
    />
  </div>
)

export default styled(BooleanIcon)`
  .success {
    color: ${(props) => props.theme.colors.success};
  }
  .danger {
    color: ${(props) => props.theme.colors.danger};
  }
`
