import Axios from 'axios'
import Button from 'components/Button'
import ExamInformationCard from 'components/ExamInformationCard'
import { API_HOST } from 'consts'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IExam, IExamDashboardApplication } from '../../types'
import ExpandableDetails from './ExpandableDetails'
import { collectionIsFinished } from 'data/utils/collections'

type ExamDashbhoardApplicationCardProps = {
  application: IExamDashboardApplication
  exam?: IExam | undefined
}

const ExamDashbhoardApplicationCard = ({
  application
}: ExamDashbhoardApplicationCardProps) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const history = useHistory()

  const downloadStaticImages = async () => {
    try {
      const response = await Axios.get(
        `${API_HOST}/v1/applications/${application.id}/static_images`
      )
      const link = document.createElement('a')
      link.href = response.data.url
      link.setAttribute('download', 'static-images.zip')
      document.body.appendChild(link)
      link.click()
    } catch {
      toast.error('Nenhuma imagem encontrada.')
    }
  }

  return (
    <>
      <ExamInformationCard application={application}>
        <Button
          action="unstyled"
          disabled={!collectionIsFinished(application?.exam?.collection)}
          type="button"
          onClick={toggleExpanded}
        >
          {expanded ? t('Close') : t('See exam data')}
        </Button>
        <Button
          action="unstyled"
          disabled={!collectionIsFinished(application?.exam?.collection)}
          onClick={downloadStaticImages}
        >
          {'Download imagens estáticas'}
        </Button>

        <Button
          action="unstyled"
          type="button"
          disabled={application?.status !== 'FINISHED'}
          onClick={() =>
            history.push({
              pathname: `/answer-list/${application.id}`,
              state: [
                {
                  ShowHeader: true,
                  ShowInternetHistory: true,
                  ShowMessageExchangeHistory: true,
                  ShowLoginHistory: true,
                  ShowAnswers: true
                }
              ]
            })
          }
        >
          {expanded ? t('Close') : t('See report card')}
        </Button>
      </ExamInformationCard>
      <ExpandableDetails expanded={expanded} application={application} />
    </>
  )
}

export default ExamDashbhoardApplicationCard
