import Modal from 'components/Modal/Modal'
import AnswerSheetItemContent from 'pages/AnswerSheet/components/AnswerSheetItemContent'
import React, { useState } from 'react'
import styled from 'styled-components'

type ItemModalProps = {
  className?: string
  items?: any
  itemId?: any
}

const ItemModal = ({ items }: ItemModalProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <AnswerSheetItemContent
        item={items?.item}
        correctAnswer={items?.item?.correctAnswer}
        givenAnswer={items?.freeResponse}
        attachment={items?.attachment}
        isCancelled={false}
        postAlternativeExplanationVisualization={false}
        requestedBy="OTHER"
        className="answer-sheet"
      />
    </Modal>
  )
}

export default styled(ItemModal)``
