import randomColor from 'randomcolor'
import React, { useRef } from 'react'
import styled from 'styled-components'

type RoomPictureProps = {
  className?: string
}

const RoomPicture = ({ className }: RoomPictureProps) => {
  // Creates a random color for profile picture background
  // that does not change in every render
  const colorRef = useRef(randomColor({ luminosity: 'dark' }))

  return (
    <span
      className={className}
      style={{
        backgroundColor: colorRef.current
      }}
    >
      <h2>S</h2>
    </span>
  )
}

export default styled(RoomPicture)`
  width: 55px;
  height: 55px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 50%;
  font-size: 19px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`
