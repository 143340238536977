import Space from 'components/Space'
import ConfigContext from 'contexts/ConfigContext'
import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { IItem } from 'types'
import Html from '../../../components/Html'
import FetchItemError from '../../../components/Item/FetchItemError'
import ItemCard from '../../../components/Item/ItemCard'
import AnswerSheetAlternatives from './AnswerSheetAlternatives'
import AnswerSheetFreeResponse from './AnswerSheetFreeResponse'
import AnswerSheetBulkAlternatives from './AnswerSheetBulkAlternatives'
import { ICorrectAnswer } from 'data/domain/exams'
import SymptomTableReview from 'components/Repertorization/SymptomTableReview'
import RepertorizationTableReview from 'components/Repertorization/RepertorizationTableReview'
import LinearScaleModal from 'components/LinearScaleModal'
import ItemTitle from 'pages/AnswerSheet/components/ItemTitle'

type Props = {
  item: IItem
  fetchingItem?: boolean
  fetchItemError?: string
  correctAnswer?: string
  answerGradeLinear?: any
  correctBulkAnswer?: ICorrectAnswer[]
  bulkAnswer?: any[]
  givenAnswer?: string
  isCancelled: any
  postAlternativeExplanationVisualization: boolean
  requestedBy?: string
  className?: string
  application?: any
  attachment?: any
}

const makeAnswers = (itemCategory: string) =>
  ({
    FREE_RESPONSE: AnswerSheetFreeResponse,
    MULTIPLE_CHOICE: AnswerSheetAlternatives,
    MULTIPLE_ALTERNATIVES: AnswerSheetBulkAlternatives
  }[itemCategory] || (() => null))

const AnswerSheetItemContent = ({
  item,
  fetchingItem,
  fetchItemError,
  correctAnswer,
  correctBulkAnswer,
  givenAnswer,
  bulkAnswer,
  isCancelled,
  postAlternativeExplanationVisualization,
  className,
  application,
  attachment,
  answerGradeLinear,
  requestedBy = 'CANDIDATE'
}: Props) => {
  const { disableItemTextHighlight } = useContext(ConfigContext)

  if (fetchItemError) {
    return <FetchItemError />
  }

  if (fetchingItem || !item) {
    return <Skeleton height={1000} />
  }
  
  const Answers = makeAnswers(item.category)
  return (
    <>
      <ItemTitle 
        style={{
          color:' #262930',
          fontFamily: 'Public Sans',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '16px',
          letterSpacing: '0em',
          textAlign: 'left',  
          }}>Enunciado </ItemTitle>
      <ItemCard style={{
        backgroundColor:' #FBFBFB',
        borderRadius:'4px',
        boxShadow: 'none',
        border: 'none',
      }}>
        <Html noHighlight={disableItemTextHighlight}>{item.introduction}</Html>
        <Html noHighlight={disableItemTextHighlight}>{item.question}</Html>
      </ItemCard>
      <ItemTitle 
        style={{
          color:' #262930',
          fontFamily: 'Public Sans',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '16px',
          marginTop:'16px',
          letterSpacing: '0em',
          textAlign: 'left',  
        }}>Resposta </ItemTitle>
      <Space value="20px" />
      {item?.category === 'REPERTORIZATION_TABLE' ? (
        <>
          <SymptomTableReview application={application} />
          <RepertorizationTableReview application={application} />
        </>
      ) : item?.category === 'MULTIPLE_LINEAR_SCALE' ? (
        <LinearScaleModal answer={answerGradeLinear} item={item} />
      ) : (
        <Answers
          requestedBy={requestedBy}
          correctAnswer={correctAnswer}
          correctBulkAnswer={correctBulkAnswer}
          bulkAnswer={bulkAnswer}
          givenAnswer={givenAnswer}
          attachment={attachment}
          alternatives={item.alternatives}
          isCancelled={isCancelled}
          postAlternativeExplanationVisualization={
            postAlternativeExplanationVisualization
          }
        />
      )}
    </>
  )
}

export default AnswerSheetItemContent
