import axios from 'axios'
import { Formik, FormikValues } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { API_HOST } from '../../consts'
import UserInput from '../Inputs/UserInput'
import Modal from '../Modal/Modal'
import NewStudentForm from './NewStudentForm'
import useQuery from 'hooks/useQuery'
import useFeedback from 'hooks/useFeedback'
import { messages, errors } from 'resources'
import { toast } from 'react-toastify'
import { Radio, RadioProps, withStyles } from '@material-ui/core'
import ClassInput from 'components/Inputs/ClassInput'
import GradeInput from 'components/Inputs/GradeInput'
import SchoolInput from 'components/Inputs/SchoolInput'
import SharedStudentInput from 'components/Inputs/SharedStudentInput'
import usePreference from 'hooks/usePreference'
import { IBooleanPreference } from 'types'
import { ModalContext } from 'states/ModalState'
import SetExamDateModal from './SetExamDateModal'
import { ApplicationContext } from 'states/ApplicationState'

type AddStudentModalProps = {
  isOpen: boolean
  setIsOpen: Function
  providerCodename?: string
  className?: string
}

const AddStudentModal = ({
  isOpen,
  setIsOpen,
  providerCodename = '',
  className
}: AddStudentModalProps) => {
  const [isNewStudent, setIsNewStudent] = useState(false)
  const [selectedValueRadio, setSelectedValueRadio] = useState('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [showSharedStudents, setShowSharedStudents] = useState('SharedStudent')
  const [selectClick, setSelectClick] = useState([
    'auto',
    'auto',
    'auto',
    'auto'
  ])
  const { showModal } = useContext(ModalContext)
  const { application } = useContext(ApplicationContext)

  const { t } = useTranslation()
  const query = useQuery()
  const { handleSuccessFeedback, handleErrorFeedback } = useFeedback()

  const fetchSchool = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/v1/users_group_by_institucions`
      )
      const isAdmin = response.data.some(
        (schools) => schools.group['name'] === 'ADMINISTRADOR'
      )
      setIsAdmin(isAdmin)
      return response.data
    } catch (error) {}
  }, [])

  useEffect(() => {
    fetchSchool()
  }, [])

  const collectionId = +(query.get('collection') || '')

  const handleClose = () => {
    setIsOpen(false)
    setSelectedValueRadio('')
    setShowSharedStudents('')
    setIsNewStudent(false)
  }
  const sharedFlow = usePreference<IBooleanPreference>(
    'Shared_Data__ActivateSharedFlow'
  )

  const CustumizeRadio = withStyles({
    root: {
      color: 'rgba(0, 0, 0, 0.54)',
      '&$checked': {
        color: '#3F51B5'
      }
    },
    checked: {}
  })((props: RadioProps) => <Radio color="default" {...props} />)

  const handleOpenModal = (application?: any) => {
    const handleSetExamDateSubmit = (values: any): Promise<any> => {
      return axios
        .post(
          `${API_HOST}/v1/applications/${application.id}/set_application_dates`,
          values
        )
    }

    return showModal(() => (
      <SetExamDateModal
        onSubmit={handleSetExamDateSubmit}
        application={application}
      />
    ))
  }

  const addAllStudents = async (values: FormikValues) => {
    // add existing student to exam
    try {
      const payload = {
        user: { id: values.user && values.user != null && values.user.id },
        grade: values.grade && values.grade.id,
        classes: values.classes && values.classes.id,
        shared_student: values.shared_student && values.shared_student.id,
        collection: collectionId
      }
      const path = values.user
        ? `${API_HOST}/v1/applications`
        : `${API_HOST}/v1/applications/shared_create`
      await axios.post(path, payload).then((res) => {
        if (res.data.status === 400) {
          toast.success(t(messages.SAVED_SUCCESSFULLY))
          window.location.host.includes('avaliacao') &&
            toast.warning(
              t('This student does not yet have an appointment for this test!')
            )
        } else {
          axios.get(`${API_HOST}/v1/applications/dashboard?collection=${res.data?.collection}&user=${res.data?.user?.id}`)
          .then((res)=>{
            const application = res.data?.results[0]
            handleOpenModal(application)
           
          })
          .catch((err) => {
            console.error(err)
          })
        }
      })
      setIsOpen(false)
    } catch (error) {
      if(error.message.includes('404')){
        toast.error('Nenhum estudante encontrado nessa turma.')
      } else {
        handleErrorFeedback(error, t(errors.ERROR_SAVING_TRY_AGAIN))
      }
    }
  }

  const addNewStudent = async (values: FormikValues, { setFieldError }) => {
    // post new student form info using form values
    const {
      name,
      public_identifier,
      email,
      username,
      password,
      ...hierarchyCategories
    } = values
    const hierarchy = Object.entries(hierarchyCategories).reduce(
      (acc, entry) => {
        const [key, value] = entry
        return {
          ...acc,
          [key]: {
            value: value.value,
            name: value.name
          }
        }
      },
      {}
    )

    const payload = {
      user: {
        name,
        public_identifier,
        email,
        username,
        password,
        extra: JSON.stringify({
          hierarchy
        })
      },
      collection: collectionId
    }
    try {
      await axios
        .post(`${API_HOST}/v1/applications`, payload)
        .then(
          (res) =>
            res.data.status === 400 &&
            toast.error(t('This test is not scheduled for this class!'))
        )
        .catch((err) => console.log(err))
      setIsNewStudent(false)
      handleSuccessFeedback(t('User created and added to the collection!'))
    } catch (err) {
      setFieldError('email', get(err, 'response.data.user.email.0'))
      setFieldError(
        'public_identifier',
        get(err, 'response.data.user.public_identifier.0')
      )
    }
  }

  const changeHeight = (input) => {
    if (selectClick[0] === 'auto' && input === 1) {
      setSelectClick(['400px', 'auto', 'auto', 'auto'])
    } else if (selectClick[1] === 'auto' && input === 2) {
      setSelectClick(['auto', '400px', 'auto', 'auto'])
    } else if (selectClick[2] === 'auto' && input === 3) {
      setSelectClick(['auto', 'auto', '400px', 'auto'])
    } else if (selectClick[3] === 'auto' && input === 4) {
      setSelectClick(['auto', 'auto', 'auto', '400px'])
    } else {
      setSelectClick(['auto', 'auto', 'auto', 'auto'])
    }
  }

  const validateNewStudentForm = (values) => {
    const errors: { [key: string]: string } = {}
    const requiredMsg = t('This field is required.')
    const { name, public_identifier, ...hierarchy } = values

    if (!name) {
      errors.name = requiredMsg
    }
    if (!public_identifier) {
      errors.public_identifier = requiredMsg
    }
    Object.entries(hierarchy).forEach((entry) => {
      const [key, value] = entry
      if (!value) {
        errors[key] = requiredMsg
      }
    })
    return errors
  }
  const deleteStudentValue = (values) => {
    if (values.user) {
      values.user = null
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValueRadio(event.target.value)
  }
  const handleSharedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSharedStudents(event.target.value)
  }


  return (
    <div>
      {/* add existing student */}
      <Formik initialValues={{}} onSubmit={addAllStudents}>
        {(formik) => (
          <Modal
            className={className}
            isOpen={isOpen}
            onAction={formik.handleSubmit}
            onClose={handleClose}
            onCancel={handleClose}
            actionDisabled={formik.isSubmitting}
            actionText={t('Adicionar')}
            title={t('Add Student')}
            height="auto"
          >
            {sharedFlow && get(sharedFlow, 'value') ? (
              <>
                <div data-testid="add-student-modal">
                  <div className="form-grid">
                    <div className="form-checkbox-radio">
                      <CustumizeRadio
                        checked={selectedValueRadio === 'Student'}
                        onChange={handleChange}
                        value="Student"
                        name="radio-button-Student"
                      />
                      <label>{t('Add Student')}</label>
                    </div>
                    <div className="form-checkbox-radio">
                      <CustumizeRadio
                        checked={selectedValueRadio === 'Class'}
                        onChange={handleChange}
                        value="Class"
                        name="radio-button-Class"
                      />
                      <label>{t('Add All Class')}</label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {selectedValueRadio ? (
              selectedValueRadio === 'Student' ? (
                <>
                  <div
                    className="div-user-input-student"
                    style={{
                      marginBottom: '16px',
                      height: `${selectClick[0]}`
                    }}
                    onClick={() => {
                      changeHeight(1)
                    }}
                  >
                    {showSharedStudents === 'UsualStudent' ? (
                      <UserInput label={t('Student')} />
                    ) : (
                      <SharedStudentInput label={t('Shared Students')} />
                    )}
                  </div>

                  {isAdmin && (
                    <>
                      <div className="form-checkbox-radio">
                        <CustumizeRadio
                          checked={showSharedStudents === 'SharedStudent'}
                          onChange={handleSharedChange}
                          value="SharedStudent"
                          name="radio-button-Student"
                        />
                        <label>{t('Shared Students')}</label>
                      </div>

                      <div className="form-checkbox-radio">
                        <CustumizeRadio
                          checked={showSharedStudents === 'UsualStudent'}
                          onChange={handleSharedChange}
                          value="UsualStudent"
                          name="radio-button-Student"
                        />
                        <label>{t('Students')}</label>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className="div-class-inputs">
                  <div
                    className="div-class-input-student"
                    style={{
                      marginBottom: '16px',
                      height: `${selectClick[3]}`
                    }}
                    onClick={() => {
                      changeHeight(4)
                    }}
                  >
                    <SchoolInput label={t('Schools')} />
                  </div>
                  <div
                    className="div-class-input-student"
                    style={{
                      marginBottom: '16px',
                      height: `${selectClick[1]}`
                    }}
                    onClick={() => {
                      changeHeight(2)
                    }}
                  >
                    <GradeInput
                      label={t('School Grade')}
                      formikValues={formik.values}
                    />
                  </div>
                  <div
                    className="div-grade-input-student"
                    style={{
                      marginBottom: '16px',
                      height: `${selectClick[2]}`
                    }}
                    onClick={() => {
                      changeHeight(3)
                      deleteStudentValue(formik.values)
                    }}
                  >
                    <ClassInput
                      label={t('Class')}
                      formikValues={formik.values}
                    />
                  </div>
                </div>
              )
            ) : !get(sharedFlow, 'value') ? (
              <div
                className="div-user-input-student"
                style={{ marginBottom: '16px', height: `${selectClick[0]}` }}
                onClick={() => {
                  changeHeight(1)
                }}
              >
                <UserInput label={t('Student')} />
              </div>
            ) : (
              <></>
            )}
          </Modal>
        )}
      </Formik>

      {/* create new student */}
      <Formik
        initialValues={{}}
        onSubmit={addNewStudent}
        validate={validateNewStudentForm}
      >
        {(formik) => (
          <>
            <Modal
              isOpen={isNewStudent}
              onClose={handleClose}
              onAction={formik.submitForm}
              onCancel={handleClose}
              actionDisabled={formik.isSubmitting}
              title={t('Add New Student')}
            >
              <NewStudentForm providerCodename={providerCodename} />
            </Modal>
          </>
        )}
      </Formik>
    </div>
  )
}

export default styled(AddStudentModal)`
  .form-grid {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .form-checkbox-radio {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 50%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
`
