import React, {useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal/Modal'

type ReproveModalProps = {
  isOpen: boolean
  setIsOpen: Function
  className?: string
  fetchReproved?: any
  examId: number
}

const ReproveModal = ({
  isOpen,
  setIsOpen,
  className,
  examId,
  fetchReproved
}: ReproveModalProps) => {

  const [justify, setJustify] = useState('')

  return (
    <Modal
      className={className}
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      title='Deseja remover esta Trilha Adaptativa?'
    >
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'100%', flexDirection:'column'}}>
        {/* <div className="title">
          <span>Ao reprovar esta Trilha Adaptativa, ela será desvinculada de todos os alunos.</span>
        </div> */}
        <div className="subtitle">
          <span><strong>Atenção!</strong> Se você reprovar esta trilha, ela será desvinculada dos estudantes e o você não poderá mais visualizá-la. Certifique-se de tomar sua decisão com cuidado. Se deseja continuar, justifique sua resposta abaixo.</span>
        </div>
        <div className="text-area">
          <textarea onChange={(e)=>setJustify(e.target.value)}></textarea>
        </div>
        <button
          onClick={()=>{fetchReproved(examId,false,justify)}}
        >
          Confirmar
        </button>
      </div>
    </Modal>
  )
}

export default styled(ReproveModal)`
  .title{
    width: 80%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .subtitle{
    padding-top: 9px;
    width: 90%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  .text-area{
    width: 90%;
    height: 200px;
    margin-top: 10px;
  }
  textarea{
    width: 100%;
    border-radius: 20px;
    border: 1px solid black;
    height: 100%;
    padding: 15px;
    resize: none;
  }
  button{
    margin-top:1rem;
    color:#fff;
    background:#c63f3f;
    width:100%;
    border:none;
    height:30px;
    border-radius:8px;
    transition: 0.5s;
    text-align: center;
    width: 30%;
  }
  button:hover{
    opacity: 0.7;
  }
`
