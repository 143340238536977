import React from 'react'
import styled from 'styled-components'
import { IEmail } from '../../types'
import Card from '../Card/Card'
import CardTitle from '../ExamDashboardComponents/CardTitle'
import BooleanIcon from '../DataDisplay/BooleanIcon'
import CardDetails from '../CardDetails'
import { dateTimeToDate, dateTimeToHour } from '../../utils/date'

type EmailCardProps = {
  className?: string
  email: IEmail
}

const EmailCard = ({ className, email }: EmailCardProps) => {
  return (
    <Card className={className}>
      <div>
        <CardTitle>
          <div className="card-title">
            <BooleanIcon value={!email.failed} />
            {email.recipientEmail} - {email.type}
          </div>
        </CardTitle>
        <CardDetails>
          <div className="card-details">
            <label>
              Criado em: {dateTimeToDate(email.createdAt)}{' '}
              {dateTimeToHour(email.createdAt)}
            </label>
            <label>
              Enviado em: {dateTimeToDate(email.sentAt)}{' '}
              {dateTimeToHour(email.sentAt)}
            </label>
          </div>
        </CardDetails>
      </div>
    </Card>
  )
}

export default styled(EmailCard)`
  .card-details {
    display: flex;
  }
  .card-details label {
    flex: 1;
  }
  .card-title {
    display: flex;
  }
  .card-title svg {
    padding-right: 10px;
  }
`
