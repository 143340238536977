import React, { useEffect, useState, useContext, useCallback } from 'react'
import { IAppealFormData } from 'types'
import { useParams } from 'react-router-dom'
import { AppealContext } from 'states/AppealState'
import AppealView from 'components/AppealComponents/AppealView'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { fetchApplication } from 'data/apis/applications'
import useQuery from '../hooks/useQuery'

type Params = {
  appealId: string
  location?: any
}

const AppealViewForm = ({ className, location }) => {
  const { appealId } = useParams<Params>()
  const { t } = useTranslation()
  const { postItemNavigation, discloseAppeal, isCancelled } = location?.state
  const [application, setApplication] = useState(null)
  const query = useQuery()
  const applicationId = query.get('application')

  const {
    currentAppeal,
    fetchAppeal,
    updateAppeal,
    updateAppealError,
    updateAppealSuccess,
    appealUpdatingError,
    appealAttachments
  } = useContext(AppealContext)

  useEffect(() => {
    fetchAppeal(+appealId)
  }, [fetchAppeal, appealId])

  useEffect(() => {
    fetchApplication(+applicationId)
      .then((res) => setApplication(res))
      .catch(() =>
        toast.error(
          t('An error ocurred whilst fetching application informations.')
        )
      )
  }, [applicationId, t])

  useEffect(() => {
    if (updateAppealError) toast.error(appealUpdatingError)
    if (updateAppealSuccess) toast.success(t('Appeal successfully updated'))
  }, [updateAppealError, updateAppealSuccess, appealUpdatingError, t])

  const handleSubmit = useCallback(
    async (formik) => {
      if (!formik && !formik.items.id)
        return toast.error(t('Please select the item'))
      const appealData: IAppealFormData = {
        application: currentAppeal?.application,
        item: currentAppeal.item,
        content: formik.content,
        bibliography: formik.bibliography,
        attachments: appealAttachments
      }
      await updateAppeal(+appealId, appealData)
    },
    [currentAppeal, t, appealId, updateAppeal, appealAttachments]
  )

  return (
    <>
      {currentAppeal && (
        <AppealView
          application={application}
          className={className}
          postItemNavigation={postItemNavigation}
          discloseAppeal={discloseAppeal}
          isCancelled={isCancelled}
          initialValues={currentAppeal}
          handleSubmit={handleSubmit}
          isNewAppeal={false}
        />
      )}
    </>
  )
}

export default AppealViewForm
