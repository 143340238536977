import React, { useState, useContext } from 'react'
import Page from '../components/Page'
import { API_HOST, DEFAULT_LOGO } from '../consts'
import { useTranslation } from 'react-i18next'
import educatLogo from '../assets/img/educat.png'
import styled, { ThemeContext } from 'styled-components'
import Button from '../components/Button'
import { toast } from 'react-toastify'
import axios from 'axios'
import * as yup from 'yup'

interface ResetPasswordPage {
  className?: string
}

const RequestPasswordResetSchema = yup.object().shape({
  email: yup.string().email().required()
})

const RequestPasswordResetPage: React.FC<ResetPasswordPage> = ({
  className
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setIsLoading(true)
      e.preventDefault()
      await RequestPasswordResetSchema.validate({ email })
      await axios.post(`${API_HOST}/reset_password/`, { email })
      setEmailSent(true)
    } catch (err) {
      err.message.includes('400') &&
        toast.error(t("There's no user registered with the provided email"))
      err instanceof yup.ValidationError && toast.error(t(err.message))
      !err.message.includes('400') &&
        toast.error(t('An error occurred. Please try again.'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Page className={className}>
      <img src={theme.logoImg || DEFAULT_LOGO || educatLogo} alt={theme.name} />
      <p>
        Digite o email utilizado no momento da sua inscrição para
        alterar/definir sua senha.
      </p>
      <p>
        A senha será utilizada para acessar o ambiente de provas e agendar seu
        pré-teste.
      </p>
      <form onSubmit={onSubmit}>
        <input
          required
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder={t('Email')}
        />
        {!emailSent && (
          <Button action="primary" type="submit" isLoading={isLoading}>
            {t('Continue').toUpperCase()}
          </Button>
        )}
      </form>
      {emailSent && (
        <p className="success-message">
          {t('We sent you an email with instructions to reset your password.')}
        </p>
      )}
    </Page>
  )
}

export default styled(RequestPasswordResetPage)`
  margin-top: 5rem;
  text-align: center;

  img {
    margin-top: 100px;
    margin-bottom: 50px;
    max-height: 200px;
    max-width: 300px;
  }

  form {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 300px;
    justify-content: center;

    input {
      font-size: 16px;
      padding: 5px;
      line-height: 1.5;
      background-color: white;
      border: 2px solid ${(props) => props.theme.colors.graylight};
      border-radius: 5px;
    }
  }

  .success-message {
    font-size: 18px;
  }
`
