import React, { useContext } from 'react'
import styled from 'styled-components'
import Axios from 'axios'
import { toast } from 'react-toastify'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button'
import { API_HOST } from 'consts'
import BulkOrderStatus from 'components/BulkOrder/BulkOrderStatus'
import ToggleMenuButton from 'components/Buttons/ToggleMenuButton'
import ProfilePicture from 'components/ProfilePicture'
import Flex from 'components/Flex'
import Card from 'components/Card/Card'
import CardTitle from 'components/ExamDashboardComponents/CardTitle'
import CardSubtitle from 'components/ExamDashboardComponents/CardSubtitle'
import SubtitleDisplayIdentifier from 'components/ExamDashboardComponents/SubtitleDisplayIdentifier'
import SubtitleDisplayRoom from 'components/ExamDashboardComponents/SubtitleDisplayRoom'
import { AnswerSheetOrder } from 'types'
import { ModalContext } from 'states/ModalState'

type BulkOrderInfoCardProps = {
  className?: string
  answerSheetOrder: AnswerSheetOrder
}

const BulkOrderInfoCard: React.FC<BulkOrderInfoCardProps> = ({
  className,
  answerSheetOrder
}) => {
  const { showModal, hideModal } = useContext(ModalContext)

  const downloadPdfReportCard = async () => {
    try {
      const response = await Axios.get(
        `${API_HOST}/v1/answer-sheet-order/${answerSheetOrder.application.id}/generate_presigned_url`
      )
      const link = document.createElement('a')
      const applicationName = answerSheetOrder.application?.user?.name.replace(
        ' ',
        '_'
      )
      link.href = response.data.url
      link.setAttribute('download', `Boletim-${applicationName}.pdf`)
      document.body.appendChild(link)
      link.click()
    } catch {
      toast.error('Nenhum pdf encontrado.')
    }
  }
  return (
    <Card className={className}>
      <ProfilePicture user={answerSheetOrder.application?.user} />
      <div>
        <CardTitle>
          {answerSheetOrder.application?.user?.name}
          <span className="subtitle-small">
            {answerSheetOrder.application?.exam?.name}
          </span>

          <Flex>
            <BulkOrderStatus
              className="appeal-status"
              status={answerSheetOrder.status?.name}
            />
            <div>
              <ToggleMenuButton
                action="icon"
                data-testid="resume-toggle-menu-button"
                disabled={false}
              >
                <Button action="unstyled" onClick={downloadPdfReportCard}>
                  {'Download boletim individual'}
                </Button>
                {answerSheetOrder?.status?.name === 'finalizado com erros' && (
                  <Button
                    action="unstyled"
                    onClick={() =>
                      showModal(() => (
                        <Modal
                          isOpen
                          title="Visualização de erros"
                          onClose={hideModal}
                        >
                          {answerSheetOrder?.errors}
                        </Modal>
                      ))
                    }
                  >
                    {'Visualizar erros'}
                  </Button>
                )}
              </ToggleMenuButton>
            </div>
          </Flex>
        </CardTitle>
        <CardSubtitle>
          <SubtitleDisplayIdentifier
            publicIdentifier={
              answerSheetOrder.application?.user?.publicIdentifier
            }
          />
          <SubtitleDisplayRoom
            room={answerSheetOrder.application?.user?.roomId}
          />
        </CardSubtitle>
      </div>
    </Card>
  )
}

export default styled(BulkOrderInfoCard)`
  display: grid;
  grid-template-columns: 55px auto;
  grid-column-gap: 1rem;

  ${CardTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    div {
      margin-left: 1.2rem;
    }
  }
  .status-order {
    border: 1px solid #676767;
    padding: 0.3rem;
    font-size: 0.8rem;
    border-radius: 6px;
  }
  ${CardSubtitle} {
    display: grid;
    justify-content: space-between;
    margin-top: 5px;
    align-items: baseline;
    font-size: 12px;
  }

  .subtitle-small {
    font-size: 13px !important;
  }
`
