import useRepertorizationSync from 'hooks/repertorization/useRepertorizationSync'
import repertorizationManager from 'managers/repertorizationManager'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState, useCallback } from 'react'

import { IApplication } from 'types'
import RepertorizationTable from './RepertorizationTable'
import SymptomTable from './SymptomTable'

type RepertorizationItemProps = {
  application: IApplication
}

const RepertorizationItem = ({ application }: RepertorizationItemProps) => {
  useEffect(() => {
    repertorizationManager.startSaveRepertorizationRoutine()
  }, [])

  const [synchronizationStatus, setSynchronizationStatus] = useState<
    string | undefined
  >(undefined)

  const { enqueueSnackbar } = useSnackbar()

  const notifySynchronizing = useCallback(() => {
    const message = 'Sincronizando Repertorização.'
    enqueueSnackbar(message, { variant: 'info', persist: true })
  }, [enqueueSnackbar])

  const notifySynchronized = useCallback(() => {
    enqueueSnackbar('Repertorização sincronizada com sucesso.', {
      variant: 'success'
    })
  }, [enqueueSnackbar])

  useEffect(() => {
    if (synchronizationStatus === 'synchronizing') {
      notifySynchronizing()
    } else if (synchronizationStatus === 'synchronized') {
      notifySynchronized()
    }
  }, [synchronizationStatus, notifySynchronizing, notifySynchronized])

  const [synchronizingAnswers, allAnswers] = useRepertorizationSync()

  useEffect(() => {
    if (+allAnswers === 0) return

    if (+synchronizingAnswers > 0) {
      setSynchronizationStatus('synchronizing')
    } else {
      setSynchronizationStatus('synchronized')
    }
  }, [synchronizingAnswers, allAnswers])

  return (
    <>
      <SymptomTable application={application} />
      <RepertorizationTable application={application} />
    </>
  )
}

export default RepertorizationItem
