import Button from 'components/Button'
import React from 'react'
import styled from 'styled-components'

type ReloadItemHelperProps = {
  onReload: () => void
  className?: string
}

const ReloadItemHelper = ({ className, onReload }: ReloadItemHelperProps) => {
  return (
    <span className={className}>
      Caso tenha alguma dificuldade para visualizar a questão,{' '}
      <div className="button-link">
        <Button
          action="link"
          onClick={onReload}
          marginBottom="0rem"
          marginTop="10px"
        >
          clique aqui
        </Button>{' '}
      </div>
      para recarregá-la.
    </span>
  )
}

export default styled(ReloadItemHelper)`
  margin: 5px;
  font-style: italic;
  font-size: 0.7rem;

  .button-link {
    display: inline;
  }
`
