import debounce from 'debounce-promise'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AnswerContext } from '../states/AnswerState'
import { ApplicationContext } from '../states/ApplicationState'
import TextArea from './TextArea'
import useApplicationConfiguration from 'hooks/useApplicationConfiguration'
import AttachmentAnswerUpload from './AppealComponents/AttachmentAnswerUpload'
import { toast } from 'react-toastify'

type FreeResponseProps = {
  className?: string
}

const FreeResponse = ({ className }: FreeResponseProps) => {
  const {
    answer,
    updateFreeResponse,
    item,
    alreadyAnswered,
    finishingAnswer,
    answerAttachments,
    removeAttachment,
    uploadAttachedFile,
    uploadingAttachment
  } = useContext(AnswerContext)
  const fileSizeLimit = 1024 * 1024 * 50
  const { t } = useTranslation()

  const allowedMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/heic',
    'image/heif',
    'image/gif',
    'image/bmp',
    'image/*',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/excel',
    'text/plain',
    'text/csv',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'video/mp4',
    'video/mp3'
  ]

  const [responseValue, updateResponseValue] = useState(
    answer?.freeResponse || ''
  )
  const { secondsToTimeout, application } = useContext(ApplicationContext)
  const configuration = useApplicationConfiguration(
    application?.exam.collection.applicationConfiguration
  )

  const showAttachmentButton =
    application?.exam?.collection?.canUploadFileAsAnswer

  const updateResponse = debounce((responseText: string) => {
    updateFreeResponse(responseText)
  }, 300)

  // Rule is disabled here because in fact we want this function to run
  // only when the answer changes.
  useEffect(() => {
    updateResponseValue(answer?.freeResponse || '')
    // eslint-disable-next-line
  }, [answer.position])

  const handleResponseChange = (value: string) => {
    updateResponseValue(value)
    updateResponse(value)
  }

  const shouldUpdateAnswer =
    configuration?.canUpdateAnswer ||
    answer?.finished !== true ||
    !alreadyAnswered

  const isDisabled =
    secondsToTimeout === 0 ||
    !shouldUpdateAnswer ||
    answer?.timeoutDate !== null ||
    finishingAnswer

  const handleAttachmentChange = async (e) => {
    const file = e.target.files[0]
    e.target.value = null
    if (!file) return
    if (allowedMimeTypes.includes(file.type)) {
      if (file.size < fileSizeLimit) {
        const formData = new FormData()
        formData.append('answer', JSON.stringify(answer?.id))
        formData.append('file', file)
        return (
          uploadAttachedFile(
            formData,
            file,
            answerAttachments,
            answer?.id,
            answer?.application?.id
          ),
          toast.success('Realizando upload do arquivo.')
        )
      }
      return toast.error(t('File size must be less than 50Mb'))
    }

    return toast.error(t('File format is not allowed'))
  }
  return (
    <div className={className}>
      {showAttachmentButton ? (
        <AttachmentAnswerUpload
          uploadingAttachment={uploadingAttachment}
          attachments={answerAttachments}
          answer={answer}
          attachmentsLimit={1}
          onDeleteAttachment={removeAttachment}
          onFileSelect={handleAttachmentChange}
        />
      ) : (
        ''
      )}
      <TextArea
        autoFocus={false}
        disabled={isDisabled}
        maxLength={item?.freeResponseMaxLength}
        value={responseValue}
        onChange={(event) => handleResponseChange(event.target.value)}
        rows={5}
        placeholder={`${t('Write your answer')}...`}
      />
    </div>
  )
}

export default styled(FreeResponse)`
  ${TextArea} {
    margin-top: 20px;
  }
`
