import axios from 'axios'
import { API_HOST } from 'consts'
import {
  DirectorSymptom,
  Rubric,
  SymptomTableRow
} from 'data/domain/repertorization'
import db from 'db'
import Dexie from 'dexie'

class RepertorizationManager {
  private rubricRoutineId = -1
  private symptomTableRowRoutineId = -1
  private directorSymptomRoutineId = -1

  private saveRepertorizationAnswerRoutine = async (
    dbTable: Dexie.Table<any, any>,
    getTableKey: (answer: any) => any,
    onRemoteSave: (answer: any) => Promise<void>
  ) => {
    let answer: any

    do {
      answer = await dbTable.where({ syncStatus: 'not synchronized' }).first()
      if (answer) {
        const key = getTableKey(answer)

        try {
          await dbTable.update(getTableKey(answer), {
            syncStatus: 'synchronizing'
          })

          await onRemoteSave(answer)

          db.transaction('rw', dbTable, async () => {
            const currentAnswer = await dbTable.get(key)
            if (currentAnswer.syncStatus === 'synchronizing') {
              dbTable.update(key, {
                syncStatus: 'synchronized'
              })
            }
          })
        } catch {
          setTimeout(async () => {
            await dbTable.update(key, {
              syncStatus: 'not synchronized'
            })
          }, 1000)
        }
      }
    } while (answer)
  }

  private saveRubricRoutine = async () => {
    await this.saveRepertorizationAnswerRoutine(
      db.rubrics,
      (r: Rubric) => [r.application, r.item, r.position],
      async (r: Rubric) =>
        axios.post(`${API_HOST}/v1/rubrics`, {
          item: r.item,
          position: r.position,
          value: r.value,
          application: r.application
        })
    )

    this.rubricRoutineId = setTimeout(this.saveRubricRoutine, 5000)
  }

  private saveSymptomTableRowRoutine = async () => {
    await this.saveRepertorizationAnswerRoutine(
      db.symptomTableRows,
      (s: SymptomTableRow) => [s.application, s.position],
      async (s: SymptomTableRow) =>
        axios.post(`${API_HOST}/v1/symptom_table`, {
          application: s.application,
          position: s.position,
          symptom: s.symptom,
          rubric: s.rubric,
          pageColumn: s.pageColumn
        })
    )

    this.symptomTableRowRoutineId = setTimeout(
      this.saveSymptomTableRowRoutine,
      5000
    )
  }

  private saveDirectorSymptomRoutine = async () => {
    await this.saveRepertorizationAnswerRoutine(
      db.directorSymptoms,
      (d: DirectorSymptom) => d.application,
      async (d: DirectorSymptom) =>
        axios.post(`${API_HOST}/v1/director_symptom`, {
          application: d.application,
          name: d.name
        })
    )

    this.directorSymptomRoutineId = setTimeout(
      this.saveDirectorSymptomRoutine,
      5000
    )
  }

  public startSaveRepertorizationRoutine() {
    if (this.rubricRoutineId === -1) {
      this.saveRubricRoutine()
    }
    if (this.symptomTableRowRoutineId === -1) {
      this.saveSymptomTableRowRoutine()
    }
    if (this.directorSymptomRoutineId === -1) {
      this.saveDirectorSymptomRoutine()
    }
  }

  public stopSaveRepertorizationRoutine() {
    if (this.rubricRoutineId === -1) {
      clearTimeout(this.rubricRoutineId)
    }
    if (this.symptomTableRowRoutineId === -1) {
      clearTimeout(this.symptomTableRowRoutineId)
    }
    if (this.directorSymptomRoutineId === -1) {
      clearTimeout(this.directorSymptomRoutineId)
    }
  }

  public async saveRubric(rubric: Rubric) {
    await db.rubrics.put({ ...rubric, syncStatus: 'not synchronized' })
  }

  public saveSymptomTableRow(symptomTableRow: SymptomTableRow) {
    db.transaction('rw', db.symptomTableRows, async () => {
      let updatedData = {
        syncStatus: 'not synchronized'
      }

      ;['symptom', 'rubric', 'pageColumn'].forEach((rowKey) => {
        const rowValue = symptomTableRow[rowKey]
        if (rowValue !== undefined) {
          updatedData = {
            ...updatedData,
            [rowKey]: rowValue
          }
        }
      })

      db.symptomTableRows
        .add({
          ...updatedData,
          ...symptomTableRow
        } as SymptomTableRow)
        .catch(() => {
          db.symptomTableRows.update(
            [symptomTableRow.application, symptomTableRow.position],
            updatedData
          )
        })
    }).catch(console.log)
  }

  public async saveDirectorSymptom(directorSymptom: DirectorSymptom) {
    await db.directorSymptoms.put({
      ...directorSymptom,
      syncStatus: 'not synchronized'
    })
  }
}

const repertorizationManager = new RepertorizationManager()

export default repertorizationManager
