import {
  getCandidateDocumentsAsArray,
  updateCandidateDocument
} from 'data/apis/document-pictures'
import { DocumentPictures } from 'data/domain/document-pictures'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IExamDashboardApplication } from 'types'
import styled from 'styled-components'
import Button from 'components/Button'
import { AuthContext } from 'states/AuthState'
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface PicturesReviewProps {
  className?: string
  application: IExamDashboardApplication
}

const PicturesReview: React.FC<PicturesReviewProps> = ({
  className,
  application
}) => {
  const [noData, setNoData] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [firestorePictures, setFirestorePictures] = useState<
    DocumentPictures[]
  >([])
  const { hasGroup, user } = useContext(AuthContext)
  const { t } = useTranslation()

  const fetchCandidatePictures = useCallback(async () => {
    const applicationDate = new Date(application.startedAt)

    const documents = await getCandidateDocumentsAsArray(
      `${application.user.id}`,
      new Date(applicationDate)
    )
    if (!document) {
      setNoData(true)
      setIsFetching(false)
      return
    }
    setFirestorePictures(documents)
    setIsFetching(false)
  }, [application.user.id, application.startedAt])

  const approvePictures = async (picture: DocumentPictures) => {
    setIsUpdating(true)
    await updateCandidateDocument(picture, user.id, user.name)
    fetchCandidatePictures()
    setIsUpdating(false)
  }

  useEffect(() => {
    fetchCandidatePictures()
  }, [fetchCandidatePictures])

  return (
    <div className={className}>
      {isFetching && (
        <div>
          <Skeleton height="120px" />
          <Skeleton height="400px" />
        </div>
      )}
      {noData && <h2>{t('No records found')}</h2>}
      {!noData &&
        firestorePictures.map((firestorePicture) => (
          <div key={firestorePicture?.documentPictureUrl}>
            {!!firestorePicture?.confirmedBy && (
              <div className="confirmation-card">
                <div className="confirmation-card__compatibility">
                  {!!firestorePicture?.compatibility && <div></div>}
                  {!!firestorePicture?.confirmedBy && (
                    <div className="confirmation-card__compatibility__button">
                      <p>Fotos Aprovadas</p>
                    </div>
                  )}
                </div>

                <div className="confirmation-card__details">
                  <p>
                    Data da confirmação:{' '}
                    {moment(
                      new Date(firestorePicture?.confirmedWhen.seconds * 1000)
                    ).format('DD-MM-YYYY')}
                  </p>
                  <p>Confirmado por: {firestorePicture?.confirmedBy.name}</p>
                </div>
              </div>
            )}
            <div className="images-grid">
              {!firestorePictures && (
                <>
                  <Skeleton height="300px" />
                  <Skeleton height="300px" />
                </>
              )}
              {firestorePictures && (
                <>
                  <div className="image-card">
                    <img
                      src={firestorePicture?.documentPictureUrl}
                      alt="Foto do rosto"
                    />
                    <p className="image-card__description">
                      Foto do rosto (
                      {moment(
                        new Date(firestorePicture?.dateUploaded.toDate())
                      ).format('DD/MM/YYYY HH:mm')}
                      )
                    </p>
                  </div>
                  <div className="image-card">
                    <img
                      src={firestorePicture?.documentPictureWithFaceUrl}
                      alt="Foto do rosto com documento"
                    />
                    <p className="image-card__description">
                      Foto do rosto com documento (
                      {moment(
                        new Date(firestorePicture?.dateUploaded.toDate())
                      ).format('DD/MM/YYYY HH:mm')}
                      )
                    </p>
                  </div>
                </>
              )}
            </div>
            {hasGroup('ADMINISTRADOR') && !firestorePicture?.confirmedBy && (
              <div>
                <Button
                  isLoading={isUpdating}
                  onClick={() => approvePictures(firestorePicture)}
                  action="primary"
                >
                  <p>Aprovar fotos</p>
                </Button>
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default styled(PicturesReview)`
  padding: 20px;

  p {
    margin: 0;
  }

  .confirmation-card {
    padding: 10px;
    border: 2px solid #f5f5f5;
    border-radius: 5px;
    margin-bottom: 10px;

    &__compatibility {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      p {
        margin: 0;
        line-height: 1;
      }

      &__button {
        padding: 10px 20px;
        border-radius: 5px;
        background: #033849;
        color: white;
      }
    }

    &__details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .images-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;

    .image-card {
      img {
        width: 100%;
      }

      &__description {
        margin: 0;
        background: #033849;
        color: white;
        padding: 5px 10px;
      }
    }
  }
`
