import React from 'react'
import styled from 'styled-components'
import ChromeTutorial from './ChromeTutorial'
import FirefoxTutorial from './FirefoxTutorial'
import { useTranslation } from 'react-i18next'

type TutorialProps = {
  className?: string
  active: string
}

const Tutorial = ({ className, active }: TutorialProps) => {
  const { t } = useTranslation()
  return (
    <div className={className}>
      {active && <h3>{t('Instructions')}</h3>}
      {active === 'Chrome' && <ChromeTutorial />}
      {active === 'Firefox' && <FirefoxTutorial />}
    </div>
  )
}

export default styled(Tutorial)`
  text-align: left;
  /* Stop tutorial text from "pushing down" content below it */
  min-height: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
`
