import React, { ChangeEvent, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import SearchBar from '../SearchBar'

type SearchBarWrapperProps = {
  className?: string
  searchTerm?: string
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const SearchBarWrapper = ({
  className,
  searchTerm,
  onSearchChange,
  placeholder
}: SearchBarWrapperProps) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  return (
    <div className={className}>
      <SearchBar
        placeholder={
          placeholder ? t(placeholder) : t('Search for candidate...')
        }
        value={searchTerm}
        onChange={onSearchChange}
      />
      <div className="icon-wrapper">Buscar</div>
    </div>
  )
}

export default styled(SearchBarWrapper)`
  display: flex;
  font-family: 'Arial', sans-serif;
  align-items: center;
  justify-content: center;
  border-radius: 16px;

  ${SearchBar} {
    width: 70%;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0.7rem;
    font-size: 16px;
    font-family: 'Arial', sans-serif !important;
    color: ${(props) => props.theme.clientColors.buttonSearchPrimary};
    font-weight: bold;
    text-align: center;
    padding: 0.5rem 1rem;
    height: 40px;
    width: auto;
    transition: all 0.3s;
    background: ${(props) => props.theme.clientColors.buttonSearchSecondary};
    border-radius: 20px;
    cursor: pointer;
    &:hover {
      background-color: ${(props) => props.theme.clientColors.secondaryDark};
    }
  }
`
