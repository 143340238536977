import { db } from 'infra/firebase'

export const addInternetMeasurementRecord = (
  candidateId: string,
  measuredDelay: number,
  currentAverage: number,
  currentScore: number
) => {
  return db
    .collection('connection')
    .doc(candidateId)
    .collection('measures')
    .add({
      when: new Date().toISOString(),
      measuredDelay,
      currentAverage,
      currentScore
    })
}
