import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import Button from '../components/Button'
import Page from '../components/Page'

type ErrorFirefoxVersionProps = {
  className?: string
}

const ErrorFirefoxVersion = ({ className }: ErrorFirefoxVersionProps) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  return (
    <Page title={t('Ops!')} className={className}>
      <p>
        Ocorreu um erro ao acessar a atividade, entre em contato com nosso
        suporte <span>(31) 99991-7595</span> via WhatsApp ou Ligação.
      </p>
      <img alt="error" src={theme.errorImg} />
      <Button
        action="primary"
        onClick={() => window.location.replace('/applications')}
      >
        VOLTAR PARA PÁGINA ANTERIOR
      </Button>
    </Page>
  )
}

export default styled(ErrorFirefoxVersion)`
  text-align: center;
  h1 {
    font-weight: 600;
    color: ${(props) => props.theme.clientColors.text};
  }
  span {
    font-weight: 800;
  }

  img {
    width: 80%;
    max-width: 400px;
    margin: auto;
    margin-top: 2rem;
    display: block;
  }

  ${Button} {
    margin-top: 2rem;
  }
`
