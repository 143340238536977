import React from 'react'
import { getHistory } from 'data/apis/message-exchange'
import Modal from 'components/Modal/Modal'
import CandidateHistoryItem from './CandidateHistoryItem'
import qs from 'qs'
import { useLocation, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'

type CandidateHistoryModalProps = {
  roomId?: string
}

type LocationState = {
  candidateName?: string
}

const CandidateHistoryModal = ({ roomId }: CandidateHistoryModalProps) => {
  const { t } = useTranslation()
  const location = useLocation<LocationState>()
  const history = useHistory()
  const { candidateId } = qs.parse(location.search)
  const { data, isLoading } = useQuery(
    ['get-history', roomId, candidateId],
    (_, roomId: string, candidateId: string): Promise<any[]> => {
      if (!roomId) {
        return Promise.resolve([])
      }
      if (candidateId) {
        return getHistory(roomId, candidateId)
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve([])
          }, 1000)
        })
      }
    }
  )

  return (
    <Modal
      isOpen={!!candidateId}
      title="Histórico"
      subtitle={location?.state?.candidateName}
      onClose={() => {
        history.push(
          location.pathname +
            '?' +
            qs.stringify({
              ...qs.parse(location.search.slice(1)),
              candidateId: undefined
            })
        )
      }}
    >
      {isLoading && <Skeleton height={50} />}
      {data && data.length === 0 && t('No results found.')}
      {data?.map((d, index) => (
        <CandidateHistoryItem
          key={index}
          timestamp={d.timestamp}
          event={d.status}
          motive={d.motive}
          author={d.author}
          extra={d.message}
          reason={d.reason}
        />
      ))}
    </Modal>
  )
}

export default CandidateHistoryModal
