import { capture, OutputType } from 'html-screen-capture-js'
import { IUser } from 'types'
import { uploadScreenshotToS3 } from '../infra/screenshot'

class ScreenshotManager {
  private user: IUser | undefined

  private createFile = (snapshot) => {
    const timestamp = new Date()
      .toISOString()
      .replace(/\./g, '_')
      .replace(/:/g, '_')

    return new File([snapshot], `${timestamp}.html`)
  }

  private createKey = (file) => {
    const date = new Date()
    const key = `${this.user.provider.codename}/${this.user.id}/${
      date.getFullYear() + '_' + date.getMonth() + '_' + date.getDate()
    }/candidate_screen/${file.name}`

    return key
  }

  private screeshotProcess = () => {
    const screenshotFile = capture(OutputType.STRING, window.document, {
      rulesToAddToDocStyle: [
        'max-width: 1280px',
        'max-height: 720px',
        'min-width: 320px',
        'min-height: 240px',
        'display: flex'
      ]
    })

    const blob = new Blob([screenshotFile as string], {
      type: 'text/html'
    })

    const file = this.createFile(blob)
    const key = this.createKey(file)
    uploadScreenshotToS3(file, key)
  }

  private startTakingScreenshots = () => {
    setInterval(this.screeshotProcess, 5000)
  }

  public startUploadingScreenshots(user: IUser) {
    this.user = user
    this.startTakingScreenshots()
  }
}

const screenshotManager = new ScreenshotManager()
export default screenshotManager
