import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import CardList from '../components/CardList'
import Page from '../components/Page'
import PaginationFooter from '../components/PaginationFooter'
import EmailCard from '../components/Email/EmailCard'
import Stripe from '../components/Stripe'
import EmailListState from '../states/EmailListState'

type EmailListProps = {
  className?: string
}

const EmailList = ({ className }: EmailListProps) => {
  const { t } = useTranslation()

  return (
    <EmailListState>
      {({
        results,
        numPages,
        handlePageChange,
        handlePageSizeChange,
        count,
        isLoading,
        pageSize
      }) => (
        <div className={className}>
          <Stripe title={t('Emails')} />
          <Page>
            <div>
              <CardList>
                {isLoading ? (
                  <Skeleton height={110} count={pageSize} />
                ) : (
                  results.map((email) => (
                    <EmailCard key={email.id} email={email} />
                  ))
                )}
              </CardList>
              <PaginationFooter
                pageSize={pageSize}
                pageCount={numPages}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              >
                <p>
                  Mostrando {results.length} de {count} registros.
                </p>
              </PaginationFooter>
            </div>
          </Page>
        </div>
      )}
    </EmailListState>
  )
}

export default styled(EmailList)`
  .button-div {
    float: right;
  }
`
