import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Axios from 'axios'
import { API_HOST } from 'consts'
import { lowerCaseStringAndRemoveAccent } from 'utils/string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'
import Select from 'react-select'
interface Props {
  className?: string
}

interface IAdmin {
  id: number | string
  name: string
}

const ChatManagement = ({ className }: Props) => {
  const [options, setOptions] = useState([])
  const [rooms, setRooms] = useState([])
  const [selectedAdmin, setSelectedAdmin] = useState<IAdmin>()
  const [roomsChecked, setRoomsChecked] = useState([])
  const [roomsToCheck, setRoomsToCheck] = useState([])
  const [roomsToRemove, setRoomsToRemove] = useState([])
  const [roomsResultExist, setRoomsResultExist] = useState([])
  const [roomsResultBelong, setRoomsResultBelong] = useState([])

  const fetchRooms = async () => {
    const response = await Axios.get(`${API_HOST}/v1/rooms`, {
      params: {
        opened: true
      }
    })

    const roomObj = response?.data?.results?.map((room) => {
      return { id: room?.id, checked: false, background: false }
    })

    setRooms(roomObj)
  }

  const fetchAdmins = async () => {
    const response = await Axios.get(`${API_HOST}/v1/users_group`, {
      params: {
        is_active: true
      }
    })

    const sort = response?.data?.sort(function (a: any, b: any) {
      if (b?.name < a?.name) {
        return -1
      }
      if (b?.name > a?.name) {
        return 1
      }
      return 0
    })
    setOptions(sort)
  }

  useEffect(() => {
    fetchAdmins()
    fetchRooms()
  }, [])

  const joinAdminsRooms = (userRooms: any) => {
    const arr = []
    if (userRooms) {
      rooms.map((room) => {
        if (userRooms.find((x) => x?.room === room?.id)) {
          return arr.push({ id: room?.id, checked: true, background: false })
        } else {
          return arr.push({ id: room?.id, checked: false, background: false })
        }
      })
      setRoomsChecked(arr)
    } else {
      rooms.map((room) =>
        arr.push({ id: room?.id, checked: false, background: false })
      )
      setRoomsChecked(arr)
    }
  }

  const handleClick = async (user: IAdmin) => {
    setSelectedAdmin(user)
    const response = await Axios.get(`${API_HOST}/v1/room_responsibles`, {
      params: {
        responsible: user?.id
      }
    })
    joinAdminsRooms(response?.data)
  }

  const verifyDuplicateRoomsChecked = (room: number) => {
    if (roomsToCheck?.find((r) => r === room)) {
      setRoomsToCheck(roomsToCheck?.filter((r) => r !== room))
    } else {
      setRoomsToCheck([...roomsToCheck, room])
    }

    setRoomsChecked(
      roomsChecked?.map((r) => {
        if (r?.id === room) {
          return { id: r?.id, checked: r?.checked, background: !r?.background }
        } else {
          return { id: r?.id, checked: r?.checked, background: r?.background }
        }
      })
    )
  }

  const verifyDuplicateRoomsRemove = (room: number) => {
    if (roomsToRemove?.find((r) => r === room)) {
      setRoomsToRemove(roomsToRemove?.filter((r) => r !== room))
    } else {
      setRoomsToRemove([...roomsToRemove, room])
    }

    setRoomsChecked(
      roomsChecked?.map((r) => {
        if (r?.id === room) {
          return { id: r?.id, checked: r?.checked, background: !r?.background }
        } else {
          return { id: r?.id, checked: r?.checked, background: r?.background }
        }
      })
    )
  }

  const addRooms = async () => {
    await Axios.post(`${API_HOST}/v1/room_responsibles`, {
      responsible: selectedAdmin?.id,
      rooms: roomsToCheck
    })

    setRoomsChecked(
      roomsChecked.map((r) => {
        const find = roomsToCheck.sort((a, b) => a - b).map((x) => x === r.id)

        if (find.find((x) => x)) {
          return { id: r?.id, checked: true, background: false }
        } else {
          return { id: r?.id, checked: r?.checked, background: r?.background }
        }
      })
    )

    setRoomsToCheck([])
  }

  useEffect(() => {
    setRoomsResultExist(roomsChecked)
    setRoomsResultBelong(roomsChecked)
  }, [roomsChecked])

  const removeRooms = async () => {
    await Axios.post(`${API_HOST}/v1/room_responsibles/bulk_delete`, {
      responsible: selectedAdmin?.id,
      rooms: roomsToRemove
    })

    setRoomsChecked(
      roomsChecked.map((r) => {
        const find = roomsToRemove.sort((a, b) => a - b).map((x) => x === r.id)

        if (find.find((x) => x)) {
          return { id: r?.id, checked: false, background: false }
        } else {
          return { id: r?.id, checked: r?.checked, background: r?.background }
        }
      })
    )

    setRoomsToRemove([])
  }

  const handleSelectAllChecked = () => {
    const arr = []

    setRoomsChecked(
      roomsChecked?.map((r) => {
        if (!r?.checked && !r?.background) {
          arr.push(r?.id)
          return { id: r?.id, checked: r?.checked, background: !r?.background }
        } else if (!r?.checked) {
          return { id: r?.id, checked: r?.checked, background: !r?.background }
        } else {
          return { id: r?.id, checked: r?.checked, background: r?.background }
        }
      })
    )

    setRoomsToCheck(arr)
  }

  const handleSelectAllRemove = () => {
    const arr = []

    setRoomsChecked(
      roomsChecked?.map((r) => {
        if (r?.checked && !r?.background) {
          arr.push(r?.id)
          return { id: r?.id, checked: r?.checked, background: !r?.background }
        } else if (r?.checked) {
          return { id: r?.id, checked: r?.checked, background: !r?.background }
        } else {
          return { id: r?.id, checked: r?.checked, background: r?.background }
        }
      })
    )

    setRoomsToRemove(arr)
  }

  const buildOptions = () =>
    options.map((op) => {
      return { value: op?.id, label: op.name }
    })

  const roomFilterExist = (input: string) => {
    setRoomsResultExist(
      roomsChecked?.filter((room) =>
        lowerCaseStringAndRemoveAccent(`sala ${room?.id}`)?.includes(
          lowerCaseStringAndRemoveAccent(input)
        )
      )
    )
  }

  const roomFilterBelong = (input: string) => {
    setRoomsResultBelong(
      roomsChecked?.filter((room) =>
        lowerCaseStringAndRemoveAccent(`sala ${room?.id}`)?.includes(
          lowerCaseStringAndRemoveAccent(input)
        )
      )
    )
  }

  return (
    <section className={className}>
      <p className="title-header">
        Relacione administradores à salas para ter acesso ao chat.
      </p>
      <Select
        placeholder="Selecione um administrador"
        className="select"
        options={buildOptions()}
        onChange={(e: any) => handleClick({ id: e.value, name: e.label })}
      />
      <section className="box-wrapper">
        <div className="user-box">
          <div className="title-card">
            <p>Salas existentes</p>
            <input
              className="checkbox"
              type="checkbox"
              onChange={handleSelectAllChecked}
            />
          </div>
          <div className="input-card">
            <input
              className="input-text"
              onChange={(e) => roomFilterExist(e.target.value)}
            />
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <div className="user-box-body">
            {roomsResultExist?.map((room) => (
              <>
                {!room?.checked && (
                  <div
                    key={room?.id}
                    className="card"
                    style={{
                      background: room?.background && 'rgba(0,0,0,0.3)'
                    }}
                    onClick={() => verifyDuplicateRoomsChecked(room?.id)}
                  >
                    Sala {room?.id}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="button-wrapper">
          <button onClick={addRooms}>
            <FontAwesomeIcon icon={faChevronRight} size="2x" color="green" />
            <h5>adicionar</h5>
          </button>
          <button onClick={removeRooms}>
            <FontAwesomeIcon icon={faChevronLeft} size="2x" color="red" />
            <h5>remover</h5>
          </button>
        </div>
        <div className="user-box">
          <div className="title-card">
            <p>Salas pertecentes</p>
            <input
              className="checkbox"
              type="checkbox"
              onChange={handleSelectAllRemove}
            />
          </div>
          <div className="input-card">
            <input
              className="input-text"
              onChange={(e) => roomFilterBelong(e.target.value)}
            />
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <div className="user-box-body">
            {roomsResultBelong?.map((room) => (
              <>
                {room?.checked && (
                  <div
                    key={room?.id}
                    className="card"
                    style={{
                      background: room?.background && 'rgba(0,0,0,0.3)'
                    }}
                    onClick={() => verifyDuplicateRoomsRemove(room?.id)}
                  >
                    Sala {room?.id}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </section>
    </section>
  )
}

export default styled(ChatManagement)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #777777;

  .title-header {
    color: ${(props) => props.theme.clientColors.primary};
    font-size: 32px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  .select {
    width: 400px;
    margin-bottom: 20px;
    z-index: 2;
  }

  .box-wrapper {
    width: 1000px;
    height: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-wrapper {
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      button {
        svg {
          margin-top: 12px;
        }
        color: #777777;

        border: none;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

        background: #f5f4f3;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border-radius: 8px;
        height: 90px;
        width: 90px;
        transition: filter 300ms;

        &:hover {
          filter: brightness(85%);
        }
      }
    }
  }

  section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-box {
      box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.25);
      height: 640px;
      width: 400px;
      border-radius: 4px;
      border: 1px solid #c4c4c4;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title-card {
        width: 85%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${(props) => props.theme.clientColors.primary};
        font-weight: 600;

        .checkbox {
          height: 20px;
          width: 20px;
        }
      }

      .input-card {
        height: 52px;
        width: 90%;
        border-radius: 4px;
        margin-top: 20px;

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        background: #f5f4f3;

        .input-text {
          width: 300px;
          height: 31px;
          border-radius: 4px;
          border: 1px solid #c4c4c4;
          padding: 0 33px 0 10px;

          &:focus {
            outline: none;
            border: 2px solid #c4c4c4;
          }
        }

        svg {
          color: #777777;
          position: absolute;
          right: 25px;
          z-index: 1;
        }
      }

      .user-box-body {
        height: 550px;
        width: 400px;
        border-radius: 4px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;

        ::-webkit-scrollbar {
          display: unset;
          width: 8px;
        }
        ::-webkit-scrollbar-thumb {
          background: ${(props) => props.theme.colors.grayMedium};
          border-radius: 4px;
        }
        ::-webkit-scrollbar-track {
          background: white;
        }

        .card {
          background: #f5f4f3;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          width: 361px;
          border-radius: 4px;
          margin-top: 10px;
          font-size: 18px;
          padding: 10px 0;

          p {
            width: 90%;
            text-align: start;
          }

          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
          transition: background 500ms;

          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
`
