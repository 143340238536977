import AgoraRTC from 'agora-rtc-sdk'
import { AGORA_API_KEY } from 'consts'

class RoomStreamingManager {
  public client: object = {}

  public leaveChannel = async (channelName: string) => {
    try {
      await this.client[channelName].leave()
    } catch {}
    this.client[channelName] = undefined
  }

  public createClientStreaming = (
    channelName: string,
    onStreamAdded: Function,
    onPeerLeave: Function
  ) => {
    this.client[channelName] = AgoraRTC.createClient({
      mode: 'live',
      codec: 'h264'
    })
    this.client[channelName].init(
      AGORA_API_KEY,
      () => {
        this.client[channelName].join(
          null,
          channelName,
          null,
          () => {
            this.client[channelName].on('stream-added', (obj) =>
              onStreamAdded(this.client[channelName], obj)
            )
            this.client[channelName].on('peer-leave', (obj) =>
              onPeerLeave(this.client[channelName], obj)
            )
            this.client[channelName].on('stream-removed', (obj) =>
              onPeerLeave(this.client[channelName], obj)
            )
          },
          (e) => {
            console.error(e)
          }
        )
      },
      (e) => {
        console.error(e)
      }
    )
  }
}

const RoomStreaming = new RoomStreamingManager()
export default RoomStreaming
