import Axios from 'axios'
import { API_HOST } from 'consts'
import { RollbarErrorTracking } from 'infra/rollbar'
import { useCallback, useEffect, useState } from 'react'
import { ICollection } from 'types'

const useCollection = (collectionId: string | number | undefined) => {
  const [collection, setCollection] = useState<ICollection>(undefined)

  const getCollection = useCallback(async () => {
    if (!collectionId) {
      setCollection(null)
      return
    }
    try {
      const response = await Axios.get(
        `${API_HOST}/v1/collections/${collectionId}`
      )
      const collection = response.data
      setCollection(collection)
    } catch (e) {
      RollbarErrorTracking.logError(e)
    }
  }, [collectionId])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  return collection
}

export default useCollection
