import styled from 'styled-components'
import React from 'react'

type Props = {
  className?: string
  label: string
  value?: string
  color?: string
}

const colorTransform = {
  danger: '#DD3C31',
  info: '#1E88DD',
  success: '#459F48'
}

const SelectionProcessResultCardStat = ({ className, label, value }: Props) => {
  return (
    <div className={className}>
      <span className="label">{label}: </span>
      <span className="value">{value}</span>
    </div>
  )
}

export default styled(SelectionProcessResultCardStat)`
  letter-spacing: 1px;

  .label {
    text-transform: uppercase;
  }

  .value {
    font-weight: 600;
    color: ${(props) => colorTransform[props.color]};
  }
`
