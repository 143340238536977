import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'

const SharedStudentInput = ({
  basic,
  params,
  label = 'User',
  ...props
}: {
  params?: any
  basic?: boolean
  label?: string
}) => {
  const { t } = useTranslation()

  // eslint-disable-next-line
  const fetchUsers = useCallback(
    debounce(async (search) => {
      const response = await axios.get(
        `${API_HOST}/students-user/user_students`,
        {
          params: {
            search,
            ...params
          }
        }
      )
      return response.data
    }, 500),
    [params]
  )

  return (
    <InputAsync
      key={JSON.stringify(params)}
      defaultOptions
      loadOptions={fetchUsers}
      label={t(label)}
      name="shared_student"
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.name}
      {...props}
    />
  )
}

export default SharedStudentInput
