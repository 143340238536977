import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

type Props = {
  className?: string
}

const LimiteTimeExceededIndicator = ({ className }: Props) => {
  const { t } = useTranslation()
  return <span className={className}>{t('Item Maximum Time Exceeded')}</span>
}

export default styled(LimiteTimeExceededIndicator)`
  font-weight: 500;
  color: ${(props) => props.theme.colors.danger};
`
