import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Radio } from '@material-ui/core'
import styled from 'styled-components'
import axios from 'axios'
import { API_HOST } from 'consts'

interface LinearScaleModalProps {
  className?: string
  answer: any
  item: any
}

const LinearScaleModal = ({
  className,
  answer,
  item
}: LinearScaleModalProps) => {
  const { t } = useTranslation()
  const [itemLinear, setItemLinear] = useState<any>()

  const fetchItem = async () => {
    const response = await axios.get(`${API_HOST}/v1/items/${item?.id}`)
    setItemLinear(
      response.data?.gradeLinear &&
        JSON.parse(response.data?.gradeLinear?.replace(/'/g, '"'))
    )
  }

  useEffect(() => {
    if (item && answer?.gradeLinear) {
      fetchItem()
    }
  }, [item])
  const linearScaleParsed =
    answer?.gradeLinear && JSON.parse(answer?.gradeLinear?.replace(/'/g, '"'))

  return (
    <div className={className}>
      <table>
        <thead>
          <tr>
            <th></th>
            {itemLinear?.column &&
              itemLinear?.column.map((column) => (
                <th key={column?.position} align="center">
                  {column?.value}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {itemLinear?.row &&
            itemLinear?.row?.map((r) => (
              <tr key={r?.position}>
                <td align="left">{r?.value}</td>
                {itemLinear?.column &&
                  itemLinear?.column.map((column) => (
                    <td key={column?.position} align="center">
                      <Radio
                        className="radio__button__fake"
                        color="primary"
                        name={`${r?.value} ${column.value}`}
                        value={`${r?.position} ${column.position}}`}
                        checked={
                          !!linearScaleParsed?.find(
                            (value) =>
                              value.column === column.position &&
                              value.row === r.position
                          )
                        }
                        disabled
                      />
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
export default styled(LinearScaleModal)`
  table {
    width: 100%;
  }
  th {
    &:nth-child(n + 2) {
      border: 1px solid #2e2e2e;
      font-size: 14px;
      padding: 10px;
    }
  }
  tbody {
    color: #2e2e2e;
  }
  td {
    padding: 10px;
    text-align: center;
    border: 1px solid #2e2e2e;
  }

  .radio__button__fake {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    bottom: calc(50% - 7px);
  }
`
