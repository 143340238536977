import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  pt_BR: {
    translation: {
      Exams: 'Atividades',
      exams: 'atividades',
      previous: 'Anterior',
      next: 'Próximo',
      next_fs: 'Próxima',
      Available: 'Pendente',
      'remaining character_p': 'caracteres restantes',
      'remaining character': 'caractere restante',
      'Duration unavailable': 'Duração indisponível',
      to: 'a',
      'back to applications': 'Retornar para a lista de atividades',
      'This exam has already been submitted.': 'Esta atividade já foi enviada.',
      'Your exam has already been started.': 'Sua atividade já foi iniciada.',
      'After starting your exam, you will have':
        'Após o início da atividade, você terá',
      'to submit it_fs': 'para enviá-la',
      'submit answers': 'enviar respostas',
      'Review your answers before submitting.':
        'Revise suas respostas antes de enviar.',
      'Time unavailable': 'Horário indisponível',
      'Question {{position}} from {{total}}':
        'Questão {{position}} de {{total}}',
      'An error ocurred while loading this question.':
        'Ocorreu um erro ao carregar esta questão.',
      'Please verify your Internet connection and try again.':
        'Por favor verifique sua conexão à Internet e tente novamente.',
      'An error ocurred while authenticating.':
        'Ocorreu um erro ao autenticar.',
      Authenticating: 'Autenticando',
      Instructions: 'Instruções',
      Schools: 'Escolas',
      'No instructions available': 'Instruções indisponíveis',
      'School Grade': 'Série/Ano Escolar',
      'Shared Students': 'Estudantes do banco compartilhado',
      resume: 'continuar',
      start: 'iniciar',
      'Write your answer': 'Escreva sua resposta',
      'Free response': 'Discursiva',
      'Turned in': 'Entregue',
      Continue: 'Continuar',
      Start: 'Iniciar',
      'Available soon': 'Disponível em breve',
      Unavailable: 'Indisponível',
      Unknown: 'Status desconhecido',
      'Searching for screenshot': 'Buscar screenshot',
      'Screenshot links from s3': 'Link para visualizar tela do candidato(a)',
      'Screenshot list':
        'Lista de Screenshots Disponíveis no horário selecionado:',
      'See last video chunk': 'Ver último vídeo do candidato',
      'Select the datetime': 'Selecione a data que deseja pesquisar:',
      Stopped: 'Interrompida',
      'Exam status is unknown. Please wait.':
        'O status da atividade é desconhecido. Por favor aguarde.',
      'This exam has been stopped. Please wait.':
        'A atividade foi interrompida. Por favor aguarde.',
      'An error occurred. Please try again.':
        'Um erro ocorreu. Por favor tente novamente.',
      'go back': 'voltar',
      'Search for candidate...': 'Buscar por candidato...',
      Dashboard: 'Dashboard',
      'Start time': 'Início',
      'End time': 'Término',
      Duration: 'Duração',
      login: 'entrar',
      'Total remaining time': 'Tempo restante total',
      'Remaining time': 'Tempo restante',
      'Question remaining time': 'Tempo restante para esta questão',
      'You have': 'Você possui',
      'left to submit it.': 'restantes para entregá-la.',
      'You started your exam at {{time}}.':
        'Você iniciou sua atividade em {{time}}.',
      Alternative: 'Alternativa',
      Time: 'Tempo',
      Finished: 'Finalizada',
      Started: 'Iniciada',
      Cancel: 'Cancelar',
      Save: 'Salvar',
      'Finish exam': 'Concluir atividade',
      Finish: 'Enviar respostas',
      'Are you sure you want to send answers?':
        'Você tem certeza que deseja enviar as respostas?',
      'You have {{unansweredItems}} questions without an answer.':
        'Você tem {{unansweredItems}} objetos de aprendizagem sem resposta.',
      'review answers': 'revisar respostas',
      'Congratulations! You have successfully completed one more step.':
        'Parabéns! Você concluiu com sucesso mais uma etapa',
      'Are you sure you want to end the activity?':
        'Você tem certeza que deseja finalizar a atividade?',
      exit: 'sair',
      'Do you wish to start a new session?': 'Deseja iniciar uma nova sessão?',
      'You will be disconnected from your other session.':
        'Você será desconectado da sua outra sessão.',
      continue: 'continuar',
      cancel: 'cancelar',
      'Leave the testing site?': 'Sair da plataforma de atividades?',
      YES: 'SIM',
      NO: 'NÃO',
      Yes: 'Sim',
      No: 'Não',
      'The time for this step has been reached':
        'O tempo para esta etapa foi atingido',
      "Don't worry, your answers entered so far are saved.":
        'Não se preocupe, suas respostas inseridas até agora estão salvas.',
      'An error occurred': 'Ocorreu um erro',
      'Please check your internet connection and try again.':
        'Favor verificar sua conexão com a internet e tentar novamente.',
      'Try again': 'Tentar novamente',
      'Oops!': 'Ops!',
      'An unexpected error occured.': 'Ocorreu um erro inesperado.',
      'RETURN TO ORIGINAL PAGE': 'VOLTAR À PÁGINA INICIAL',
      'Your browser is incompatible with our system':
        'Seu navegador é incompatível com o nosso sistema',
      'Download the latest version of Google Chrome, Safari, Firefox or Opera.':
        'Faça o download da última versão do Google Chrome, Safari, Firefox ou Opera.',
      Download: 'Download do',
      en_us: 'pt_br',
      'https://www.apple.com/safari/': 'https://www.apple.com/br/safari/',
      'en-US': 'pt-BR',
      'https://www.opera.com/': 'https://www.opera.com/pt-br',
      'Could not connect to the server, try again later.':
        'Não foi possível conectar ao servidor, tente novamente mais tarde.',
      'There are no exams available at the moment.':
        'Não existem atividades disponíveis no momento.',
      'Individual Report Card': 'Boletim Individual',
      'Individual Report Cards': 'Boletins Individuais',
      'Overall Comparative': 'Comparativo Geral',
      'Your overall result': 'Seu conhecimento geral',
      'Your class result': 'Conhecimento geral da turma',
      'Regarding your class you are': 'Em relação a sua turma você está',
      'You are above average': 'Você está acima da média',
      'You are below average': 'Você está abaixo da média',
      'Comparative Chart': 'Gráfico Comparativo',
      You: 'Você',
      'Your class': 'Sua turma',
      'Lost internet connection. Please re-establish your connection to proceed.':
        'Não há conexão com a internet. Restabeleça sua conexão para prosseguir.',
      'Internet connection successfully reestablished':
        'Conexão com a internet reestabelecida com sucesso',
      Grade: 'Nota',
      Grades: 'Notas',
      Exam: 'Atividade',
      'Your score': 'Seus acertos',
      'Your class overall score': 'Média de acertos da sua turma',
      'See results': 'Ver resultados',
      Collection: 'Desafio',
      'Filter by a collection to download all report cards from it.':
        'Filtre por um desafio para fazer o download dos boletins.',
      Apply: 'Aplicar',
      'Total Items': 'Total de Objetos de aprendizagem',
      User: 'Usuário',
      'You are average': 'Você está na média',
      'All students result': 'Conhecimento geral de todos os respondentes',
      'Regarding all students': 'Em relação a todos os respondentes',
      'All students overall score': 'Média de acerto de todos os respondentes',
      'All students': 'Total de respondentes',
      Correct: 'Acertos',
      Wrong: 'Erros',
      Identifier: 'Identificador',
      Filters: 'Filtros',
      Date: 'Data',
      'Exam value': 'Valor da atividade',
      'Exam dashboard': 'Painel da atividade',
      'Exam time': 'Horário de atividade',
      'Are you sure you want to delete this exam?':
        'Tem certeza de que deseja reiniciar esta atividade?',
      'Are you sure you want to reset the time for this exam?':
        'Tem certeza de que deseja reiniciar o cronômetro desta atividade?',
      'Exam was reset successfully': 'A atividade foi redefinida com sucesso!',
      'Exam time was reset successfully':
        'O cronômetro da atividade foi reiniciado com sucesso!',
      'No changes have been made.': 'Nenhuma alteração foi feita.',
      'Check to clear answers':
        'Ao marcar esta caixa, todas as respostas do respondente também serão apagadas.',
      'Reset exam': 'Reiniciar atividade',
      'Reset time': 'Reiniciar cronômetro',
      Name: 'Nome',
      'Add Student': 'Adicionar Estudante',
      Student: 'Estudante',
      'Add All Class': 'Adicionar toda turma',
      'Add New Student': 'Adicionar Novo Estudante',
      Add: 'Adicionar',
      'No results found.': 'Nenhum resultado encontrado.',
      'results found': 'resultados',
      'result found': 'resultado',
      'Didn’t find the student? Click here to register a new student.':
        'Não encontrou o Estudante? Clique aqui para cadastrá-lo.',
      'Real time stats': 'Estatísticas em tempo real',
      'Access logs': 'Log de acessos',
      'Reset logs': 'Log de resets',
      Results: 'Resultados',
      'See reports': 'Consulta de boletins',
      'Grades reports': 'Relatório de notas',
      'TCT Analysis': 'Análise TCT',
      'TRI Analysis': 'Análise TRI',
      Resource: 'Recurso',
      'New resource': 'Novo recurso',
      'Check resource': 'Acompanhar recurso',
      Configurations: 'Configurações',
      'Exam scheduling': 'Agendamento de atividades',
      'Exam grouping': 'Agrupamento de atividades',
      Users: 'Usuários',
      'Filter by "{{name}}" first': 'Filtre por "{{name}}" primeiro',
      'No results found': 'Nenhum resultado encontrado',
      'Action finished successfully': 'Ação finalizada com sucesso',
      'There was an error while executing the action':
        'Houve um erro ao realizar a ação',
      'No time window available': 'Nenhum horário de atividade disponível',
      'This field is required.': 'Este campo é obrigatório.',
      'Please select the item.': 'Por favor, selecione a questão.',
      'Invalid format.': 'Formato inválido.',
      'User created and added to the collection!':
        'Usuário criado e adicionado ao desafio!',
      'This test is not scheduled for this class!':
        'Esta prova não está agendado para esta turma!',
      'This student does not yet have an appointment for this test!':
        'Esse estudante ainda não possui agendamento para esta prova!',
      'User added to the collection!': 'Usuário adicionado ao desafio!',
      'Set exam date': 'Definir data da atividade',
      'Set deadline for the activity': 'Definir prazo para a atividade',
      'Definition of deadline for activity':
        'Definição de prazo para atividade',
      Hour: 'Hora',
      minutes: 'minutos',
      'Exam date set successfully': 'Data definida com sucesso',
      'Could not set date. Check the data and try again':
        'Näo foi possível definir a data da atividade. Verifique os dados digitados e tente novamente',
      'Question with the highest success rate':
        'Questão mais acertada da atividade',
      'Question with the highest failure rate':
        'Questão mais errada da atividade',
      'Questions with the highest failure rate':
        'Questões mais erradas da atividade',
      Statistics: 'Estatísticas',
      'See videos': 'Ver vídeos',
      'Students List': 'Lista de Estudantes',
      'Average time per question': 'Tempo médio por questão',
      'Success rate per question': '% de acertos por objeto de aprendizagem',
      'of students got it right': 'dos Estudantes acertaram',
      'of students got it wrong': 'dos Estudantes erraram',
      Students: 'Estudantes',
      Class: 'Turma',
      'Alert candidate': 'Alertar candidato',
      'Pause exam': 'Pausar atividade',
      'Resume exam': 'Retomar atividade',
      'Your exam was paused': 'Sua atividade foi pausada',
      Reason: 'Motivo',
      Disconnected: 'Desconectado',
      'Paused by applicator':
        'Pausado pelo aplicador. Por favor aguarde novas orientações.',
      Paused: 'Pausado',
      'Send alert': 'Enviar alerta',
      'Type your message here': 'Digite sua mensagem aqui',
      'Cannot send an empty alert':
        'Não é possível enviar um alerta sem mensagem',
      'Message sent successfully': 'Mensagem enviada com sucesso',
      Attention: 'Atenção',
      'Contact applicator': 'Comunicar com aplicador',
      'Send message': 'Enviar mensagem',
      'Time in exam': 'Tempo de atividade',
      'Total answered': 'Total respondido',
      'Current item': 'Objeto de aprendizagem atual',
      'Request a bathroom break': 'Solicitar pausa para banheiro',
      'Nursing break': 'Pausa para amamentação',
      'What do you want to do?': 'O que deseja fazer?',
      'Waiting for applicator confirmation...':
        'Aguardando confirmação do aplicador',
      'Your request was sent. If the request gets rejected, you will be notified.':
        'Seu pedido foi enviado. Se a requisição for rejeitada, você será notificado.',
      'The applicator rejected your break request.':
        'O aplicador rejeitou seu pedido de pausa.',
      'The applicator approved your break request.':
        'O aplicador aceitou seu pedido de pausa.',
      'Your exam will be paused when you finish the current question.':
        'Sua atividade será pausada quando você terminar sua questão atual.',
      'You have 2 minutes to come back': 'Você tem 2 minutos para voltar',
      'I am back!': 'Voltei!',
      'Additional info': 'Informações adicionais',
      seconds: 'segundos',
      'Loading...': 'Carregando...',
      'Select a room': 'Selecione uma sala',
      'Please answer the question to go to the next item':
        'Por favor responda a questão para ir para a próxima',
      Imports: 'Importações',
      Import: 'Importar',
      'Import exam': 'Importar atividade',
      'Import vectors': 'Importar vetores de resposta',
      'Import students': 'Importar Estudantes',
      'Receive a .json file with the content of the exam. This file can be generated by exporting a exam in the item creation system.':
        'Recebe um arquivo .json com o conteúdo da atividade. Esse arquivo pode ser gerado exportando uma atividade no sistema de criação de objetos de aprendizagem.',
      'Receive a .csv file with the content of the exam.':
        'Recebe um arquivo .csv com o vetor de resposta dos Estudantes. ',
      'JSON format is invalid.': 'JSON está em um formato inválido.',
      'File should be .json.': 'Arquivo deve ser no formato .json.',
      'Exam successfully imported!': 'Atividade importada com sucesso!',
      Username: 'Usuário',
      Password: 'Senha',
      Actions: 'Ações',
      'This action will affect {{ num }} applications.':
        'Esta ação afetará {{ num }} aplicações.',
      'All records that meet the following filters will be considered':
        'Todos os registros que satisfazem os filtros abaixo serão considerados.',
      'Set exam dates': 'Definir data da atividade',
      'No filters selected': 'Nenhum filtro selecionado',
      'Use the list filters to choose the applications you want to set a new date.':
        'Utilize os filtros da lista para escolher as aplicações que deseja definir uma nova data.',
      'must be selected.': 'devem ser selecionados.',
      of: 'de',
      'Select new time window for ':
        'Selecione uma nova data de atividade para ',
      Confirm: 'Confirmar',
      Minutes: 'Minutos',
      'Invalid format': 'Formato inválido',
      'Keep existing dates': 'Manter datas existentes',
      'Remove existing dates': 'Remover datas existentes',
      'Candidate in the bathroom': 'Candidato no banheiro',
      'Do you want to approve the bathroom request?':
        'Aceitar a solicitação do Estudante de ir ao banheiro?',
      'Do you want to approve the breastfeed request?':
        'Aceitar a solicitação da aluna de ir amamentar?',
      'Permission to use camera and microphone':
        'Permissões para uso de câmera e microfone',
      'You have been assigned one or more exams which require camera and microphone access as a mandatory permission. If you refuse to grant, you will not be able to proceed.':
        'Foi designado a você um ou mais atividades que são monitorados remotamente por câmera e microfone de forma mandatória. Se você recusar em conceder acesso, não poderá realizá-los.',
      'You refused to grant the necessary permissions, therefore you cannot proceed on the exams.':
        'Você recusou as permissões necessárias, então não poderá prosseguir nas atividades.',
      'To proceed on this exam(s), you will need to grant us permission to access your camera and microphone. If you refuse, you will not me able to proceed.':
        'Para prosseguir nesta(s) atividade(s), você precisará nos dar permissão para acessar sua câmera e microfone. Se você recusar o acesso, não será possível continuar.',
      Allow: 'Permitir',
      'All set!': 'Tudo certo!',
      'Before going on, please align your camera in order to show your entire face frontally.':
        'Antes de avançar, por favor alinhe sua câmera de maneira a mostrar seu rosto completamente de maneira frontal.',
      'If you find any issues, contact the technical support.':
        'Se você encontrar algum problema, contate o suporte técnico.',
      'Then, click on the button below to go to the exam selection area':
        'Depois, clique no botão abaixo para ir para a área de seleção de atividades.',
      'Exam started': 'Atividade iniciada',
      'Exam finished': 'Atividade finalizada',
      'Last item acessed': 'Último objeto de aprendizagem acessado',
      'This candidate is not currently on the exam':
        'Este candidato não está realizando a atividade',
      'Looking up': 'Olhando para cima',
      'Looking down': 'Olhando para baixo',
      'Looking right': 'Olhando para a direita',
      'Looking left': 'Olhando para a esquerda',
      'Candidate not found': 'Candidato não encontrado',
      'Multiple people found': 'Múltiplas pessoas encontradas',
      'Already exist exam to this date.':
        'Já existe atividade marcada para esta data.',
      'See feedback': 'Visualizar gabarito',
      'Here you can see what you answered, but you will not be able to change any answer.':
        'Aqui você pode ver o que você respondeu, mas não será possível alterar nenhuma resposta.',
      'Camera permission pending': 'Permissão de acesso à câmera pendente',
      'Send answer': 'Responder',
      'You are constantly looking away from the exam. This may lead to interruption of your application.':
        'Você está desviando o olhar da atividade constantemente, isto pode levar a interrupção de sua aplicação.',
      'You got up without authorization, this may lead to interruption of your application.':
        'Você levantou sem autorização, isto pode levar a interrupção de sua aplicação.',
      'It is prohibited to use any electronics during the exam aside from the one used for the exam.':
        'É proibido utilizar equipamentos eletrônicos durante a atividade, exceto pelo equipamento usado para realizar a mesma.',
      'Your camera is not properly positioned in order to give full sight of your face, please reposition it.':
        'Sua câmera não está corretamente posicionada de modo a dar visão total do seu rosto, favor reposicioná-la.',
      'CANCELLED ITEM': 'OBJETO DE APRENDIZAGEM ANULADO',
      'Correct answer': 'Resposta correta',
      'Wrong answer': 'Resposta incorreta',
      'Not answered': 'Não respondido',
      'The applicator has requested for your window to be reloaded. This is a mandatory action and will not affect your answers. After reloading, you will need to accept the camera permission request again. Click ok to continue.':
        'O aplicador requisitou que sua janela seja recarregada. Esta é uma ação mandatória e não vai afetar suas respostas. Após o recarregamento, você precisará conceder permissões de câmera novamente. Clique ok para continuar.',
      'Send reload request': 'Enviar pedido de recarregamento',
      'Reload request': 'Pedido de recarregamento',
      Absent: 'Ausentes',
      'In progress': 'Em andamento',
      'Total students': 'Total',
      'Not enough data to calculate': 'Não há dados suficientes para calcular',
      Question: 'Questão',
      'Records history': 'Registro de ocorrências',
      Room: 'Sala',
      'History of saved records': 'Histórico de registros efetuados',
      'New record': 'Novo registro',
      'Record description': 'Descrição da ocorrência',
      From: 'De',
      'Total exams': 'Total de atividades',
      participante: 'participants',
      participants: 'participantes',
      'Search...': 'Pesquisar...',
      'Students successfully imported!': 'Estudantes importados com sucesso!',
      Importing: 'Importando',
      'Exemple file': 'Arquivo de exemplo',
      'time reseted': 'tempo reiniciado',
      Keep: 'Manter',
      Remove: 'Remover',
      'Existing dates': 'Datas existentes',
      'Apply to all collection applications':
        'Aplicar a todas as atividades do desafio',
      'Choose if previous dates should be kept or removed':
        'Escolha se as datas anteriores devem ser mantidas ou removidas',
      'Provided dates will be applied to all candidate applications from collection':
        'As datas fornecidas serão replicadas a todas as atividades do desafio deste candidato',
      'View history': 'Ver histórico',
      'Aprove exam': 'Aprovar pré-teste',
      'Position your identifying document with picture at your side.':
        'Posicione seu documento de identificação com foto ao lado.',
      'Align your document with the square on the side with the picture part visible, then click on "Take picture"':
        'Alinhe seu documento no quadrado ao lado com a parte da foto visível, depois clique em "Tirar foto"',
      'Now take a picture of your face': 'Agora tire uma foto do seu rosto',
      'For this, do not wear a mask, glasses, hat or any sort of head adornment. Position your face on the center of the square and then click on "Take picture"':
        'Para isto, não use máscara, óculos, chapeu ou qualquer tipo de adereço na cabeça. Posicione seu rosto no centro do quadrado e depois clique em "Tirar foto"',
      'Now take a picture of the same document besides your face':
        'Agora tire uma foto do mesmo documento ao lado do seu rosto',
      'Take picture': 'Tirar foto',
      Next: 'Próximo',
      'Sending photos...': 'Enviando fotos...',
      Send: 'Enviar',
      'Since the page has been reloaded, you will need to grant the camera permissions once again.':
        'Como a página foi recarregada, você precisará nos dar permissão de acesso à câmera novamente.',
      'Permission not granted': 'Permissão negada',
      "If the system cannot detect that the permission has been granted, it means you refused the permission at some point, and you must manually update your browser permissions, otherwise you won't be able to continue.":
        'Se o sistema não for capaz de detectar a permissão, significa que em algum momento você provavelmente recusou-as, e você deve manualmente atualizar as permissões do seu navegador, ou não será possível continuar.',
      'This exam had its time restarted':
        'Essa atividade teve o seu tempo reiniciado',
      'Send emails': 'Enviar emails',
      'Your answer has not been save':
        'Ocorreu um erro ao salvar essa questão.',
      'Select the recipients': 'Selecione os destinatários',
      'Select a template to continue filling out the form.':
        'Selecione um template para continuar preenchendo o formulário',
      Leave: 'Sair',
      'Created by {{author}}': 'Criado pelo(a) {{author}}',
      'Are you sure?': 'Você tem certeza?',
      "You won't be able to return to this question.":
        'Não será possível retornar a esta questão.',
      "Don't show again": 'Não exibir novamente',
      'An error ocurred while trying to send your message':
        'Um erro ocorreu ao tentar enviar a sua mensagem',
      Applications: 'Aplicações',
      Order: 'Ordem',
      'Time Window': 'Janela de aplicação',
      Proceed: 'Prosseguir',
      'answer and next': 'responder e próxima',
      'An error ocurred while trying to select the users':
        'Ocorreu um erro ao tentar selecionar os usuários',
      'Got it': 'Entendi',
      'There was an error while trying to request access to your camera and microphone':
        'Um erro ocorreu ao tentar acessar sua câmera e microfone',
      'You can follow the steps below to try to find the problem':
        'Você pode seguir os passos abaixo para tentar encontrar o problema',
      'Close our safe exam platform': 'Feche a plataforma de atividades',
      'Open the web browser of your preference':
        'Abra o navegador de sua preferência',
      'Type in meet.google.com in the URL bar':
        'Digite meet.google.com na barra de endereços',
      'Start an instant meeting': 'Inicie uma reunião instantânea',
      'If you can see yourself on the screen, contact the technical support':
        'Se você consegue se ver na tela, contate o suporte técnico',
      'Otherwise, this means your camera is not working properly, and you will need specialized support':
        'Caso contrário, significa que sua câmera não está funcionando corretamente, e você vai precisar de suporte especializado',
      'Click to go back to the exams list':
        'Clique para voltar para a lista de atividades',
      'Check answers': 'Conferir respostas',
      'No question found.': 'Nenhuma questão encontrada.',
      'No question available.': 'Nenhuma questão disponível.',
      'Change room': 'Trocar sala',
      'An error ocurred while trying to load the group list':
        'Um erro ocorreu ao tentar carregar a lista de grupos',
      'An error ocurred while trying to load the permission list':
        'Um erro ocorreu ao tentar carregar a lista de permissões',
      Permissions: 'Permissões',
      Groups: 'Grupos',
      'No group found': 'Nenhum grupo encontrado',
      'See item': 'Visualizar objeto de aprendizagem',
      'Item view': 'Visualização das questões',
      'Visualization of alternatives': 'Frequência das Alternativas',
      'The changes are saved automatically when you click on the checkboxes.':
        'As alterações são salvas automaticamente quando você clica nas checkboxes.',
      'Saved successfully.': 'Salvo com sucesso.',
      'Add an explanation': 'Justifique a ação...',
      'Show explanation to the candidate': 'Mostrar justificativa ao candidato',
      'Your application has been paused': 'Sua atividade foi pausada',
      'Your bathroom break request has been accepted':
        'Seu pedido de pausa para o banheiro foi aceito',
      'Get rooms list': 'Download da lista de salas',
      'Stop receiving video': 'Pausar recebimento de vídeo',
      'Resume receiving video': 'Retomar recebimento de vídeo',
      'Video receiving disabled': 'Recebimento de vídeo desabilitado',
      'Response time timed out for this item':
        'Tempo de resposta esgotado para este objeto de aprendizagem',
      'The time to answer this item has been reached. You will be directed to the next question.':
        'O tempo para responder este objeto de aprendizagem foi atingido. Você será direcionado para a próxima questão.',
      'applications already created.': 'aplicações já criadas.',
      'Applications created.': 'aplicações criadas.',
      'You reached the grace period for this exam. You will not be able to start it.':
        'O tempo limite para iniciar esta atividade foi ultrapassado. Não será possível iniciá-la.',
      '{{minutes}} minutes grace period for starting the first test':
        'Tolerância de {{minutes}} minutos para iniciar a primeira atividade',
      'Add student': 'Adicionar Estudante',
      SymbolTable: 'Tabela de Siglas',
      Calculator: 'Calculadora',
      Notepad: 'Bloco de notas',
      Code: 'Código',
      'Congratulations!': 'Parabéns!',
      'Your answers were successfully sent!':
        'Suas respostas foram enviadas com sucesso!',
      'You will be redirected to the next step':
        'Você será redirecionado para a próxima etapa',
      'Congratulations! the exam was finished and your answers are saved!':
        'Parabéns! A atividade foi concluída e suas respostas estão salvas!',
      'Leave anyway': 'Sair mesmo assim',
      'If you leave now, you will be disclassified, since the minimum stay time was not reached yet.':
        'Se você sair agora, você será desclassificado, pois o tempo mínimo de permanência não foi atingido.',
      'Please check if you are not within the minimum stay time.':
        'Por favor verifique se você não está dentro do tempo mínimo de permanência.',
      'Attention to minimum stay time! You must not leave the platform until {{date}}':
        'Atenção ao tempo de sigilo! Você não deve deixar a plataforma antes de {{date}}.',
      'You will return to the exam list':
        'Você retornará à lista de atividades',
      'Be aware that if you proceed to see the feedback, your answer on the current question will ne automatically saved, and when you get back, you will be presented to the new question.':
        'Esteja ciente que ao prosseguir para ver o gabarito, sua resposta na questão atual será automaticamente salva e quando você retornar, será apresentada uma nova questão.',
      'No camera found, please connect one and restart the system':
        'Nenhuma câmera encontrada, por favor conecte uma e reinicie o sistema',
      'Next(a)': 'Próxima',
      'Set room': 'Definir sala',
      'Back to Exams List': 'Voltar para a lista de atividades',
      'Continue to the next Exam': 'Continue para próxima atividade',
      'Face detection enabled': 'Detecção de face habilitada',
      'User configuration': 'Configuração do usuário',
      Close: 'Fechar',
      'Alternative explanation': 'Justificativa da alternativa',
      'See alternative explanation': 'Ver justificativa da alterantiva',
      'Alternative explanation not informed':
        'Justificativa da alterantiva não informada',
      'See exam data': 'Ver dados da atividade',
      Videos: 'Vídeos',
      'View item correction': 'Visualizar vista de atividade',
      'Chat history': 'Histórico de chat',
      Content: 'Conteúdo',
      'Batch activity allocation': 'Alocação de atividades em lote',
      'Review the application windows, applications will be generated respecting the following schedules':
        'Revise as janelas de aplicação, as aplicações serão geradas respeitando os seguintes agendamentos',
      'Select the candidates': 'Selecione os candidatos',
      'Select one or more challenges': 'Selecione um ou mais desafios',
      'Sent by the proctor': 'Enviado pelo aplicador',
      'Sent by': 'Enviado por',
      'Internet connection': 'Conexão de internet',
      'Results of the exam: {{exam}}': 'Resultados da atividade {{exam}}',
      'SEE INSTRUCTIONS': 'VER INSTRUÇÕES',
      Chat: 'Chat',
      Schedule: 'Horário',
      'Quality (%)': 'Qualidade (%)',
      'No records found': 'Nenhum registro encontrado',
      'Quality chart': 'Grafíco de qualidade',
      'Average internet quality': 'Qualidade média da internet',
      Excellent: 'Excelente',
      Good: 'Boa',
      Average: 'Média',
      Bad: 'Ruim',
      'Very Bad': 'Muito Ruim',
      'Section {{section}} of {{total}}': 'Trecho {{section}} de {{total}}',
      '{{total}} video clips were found':
        'Foram encontrados {{total}} trechos de vídeo',
      Pictures: 'Fotos',
      'Reload item': 'Recarregar item',
      Soon: 'Em breve',
      'change password': 'Alterar senha',
      'Forgot my password': 'Definir ou alterar senha',
      'We sent you an email with instructions to reset your password.':
        'Enviamos um email com instruções para definir/alterar sua senha.',
      'Password changed successfully, please access the platform with the new password.':
        'Senha definida com sucesso, por favor, acesse a plataforma utilizando a senha definida.',
      'password must be at least 8 characters':
        'a senha deve ter pelo menos 8 caracteres',
      'Confirmation must be equal to password':
        'A confirmação deve ser igual à senha',
      'email must be a valid email': 'o email deve ser válido',
      "There's no user registered with the provided email":
        'Nenhum usuário encontrado com o email fornecido.',
      'Oops! Looks like your token is invalid or has expired.':
        'Ops! Parece que seu token é inválido ou expirou.',
      'Back to login': 'Voltar para o login',
      'File format is not allowed ':
        'Formato do arquivo enviado não é permitido',
      'File size must be less than 10Mb':
        'O tamanho do arquivo deve ser menor que 10Mb',
      'No file was selected': 'Nenhum arquivo foi selecionado',
      'Upload an identification document (identity card, passport, drivers license) up to 10mb in size in one of the following formats png, jpg, pdf':
        'Faça o upload de um documento de identificação (RG, Passaporte, Carteira de Motorista) de até 10mb nos formatos jpeg, png ou pdf',
      'Document was sent!': 'Documento enviado!',
      'Identification Document Upload': 'Upload de Documento de Identificação',
      'ATTENTION! You will only be allowed to attach 1 document, so the front and back of the document must be contained in a single file.':
        'ATENÇÃO! Só será permitido anexar 1 documento, portanto a frente e o verso do documento precisam estar contidas em um único arquivo.',
      'try to connection stream': 'Iniciando a conexão por streaming.',
      'stream connected': 'Stream conectada.',
      'stream disabled': 'Streaming não habilitada',
      'lost connection from stream': 'Conexão por streaming perdida.',
      'The network quality is unknown.': 'Qualidade de internet desconhecida.',
      'The network quality is excellent.': 'Qualidade de internet excelente.',
      'The network quality is quite good, but the bitrate may be slightly lower than excellent.':
        'A qualidade da rede é muito boa, mas a taxa de bits pode ser ligeiramente inferior à excelente.',
      'Users can feel the communication slightly impaired.':
        'Os usuários podem sentir a comunicação um pouco prejudicada',
      'Users cannot communicate smoothly.':
        'Os usuários não conseguem se comunicar facilmente.',
      'The network is so bad that users can barely communicate.':
        'A rede é tão ruim que os usuários mal conseguem se comunicar.',
      'The network is down and users cannot communicate at all.':
        'A rede está fora do ar e os usuários não conseguem se comunicar.',
      Cellphone: 'Celular',
      Provider: 'Instituição',
      'See report card': 'Ver boletim',
      'No login events found.': 'Nenhum evento de login encontrado.',
      Event: 'Evento',
      'Candidate Informations': 'Informações do candidato',
      'Start Time Application': 'Início da atividade',
      'Finish Time Application': 'Fim da atividade',
      'Questions Answered': 'Questões respondidas',
      'Item Maximum Time Exceeded':
        'Tempo máximo no objeto de aprendizagem excedido',
      "User's internet quality during exam":
        'Qualidade da internet do usuário durante a atividade',
      'shut down': 'encerrar',
      'Please answer the question to end the test':
        'Por favor responda a questão para finalizar a atividade',
      'Unanswered item': 'Objeto de aprendizagem não respondido',
      'Questions Cancelled': 'Questões canceladas',
      'Correct Answers Quantity': 'Quantidade de acertos',
      'Error when importing file': 'Erro ao importar arquivo',
      Attach: 'Anexar',
      'Please wait loading image':
        'Por favor, aguarde o carregamento da imagem',
      'wait, soon you will see the state of exam this user':
        'Logo, aqui mostrará o status de atividade do usuário',
      'Select new timeout for': 'Selecione um novo tempo de atividade para ',
      Timeout: 'Tempo Limite',
      'Extra Time Reset for Activity':
        'Redefinição de tempo extra para Atividade',
      'Add extra time to exams': 'Atribuir tempo extra de atividade',
      'Timeout was changed with successfully':
        'Tempo limite foi alterado com sucesso',
      'It was not possible to change timeout':
        'Não foi possível alterar o tempo limite',
      'Finished at': 'Finalizado em',
      'Change exam timeout': 'Alterar Tempo de Atividade',
      Alternatives: 'Alternativas',
      'Multiple Alternatives': 'Multiplas Alternativas',
      'Chat Management': 'Gestão Chat',
      'Soon ({{hour}})': 'Em breve ({{hour}})',
      'You should access through the Safe Browser.':
        'Você deve acessar através do Browser Seguro',
      'Performance by hierarchy': 'Desempenho por Hierarquia',
      'Your document is being prepared, the download will start soon':
        'Preparando seu documento... O download começará em breve',
      'Your document is ready, download started':
        'Documento pronto. Download iniciado',
      'An error ocurred while preparing your document':
        'Ocorreu um erro ao preparar seu documento',
      'See report-card': 'Ver boletim',
      'Application report': 'Meus relatórios de atividade',
      'Appeal successfully updated': 'Recurso atualizado com sucesso',
      'Appeal successfully issued.': 'Recurso aberto com sucesso.',
      'An error ocurred whilst fetching application informations.':
        'Ocorreu um erro ao obter as informações da atividade.',
      'Are you sure you want to delete this attachment?':
        'Tem certeza de que deseja excluir este anexo?',
      'Are you sure do you want to leave? Unsaved changes will be lost.':
        'Você tem certeza que deseja enviar o recurso. Após o envio não será possível editar nenhuma informação.',
      'There is already a pending break request':
        'Já existe um pedido de pausa em andamento',
      'Appeals list': 'Visualizar Recursos',
      'Appeals not found': 'Nenhum recurso encontrado',
      'Status Appeal': 'Status de Recurso',
      'See appeals': 'Ver Recursos',
      'Defer appeal': 'Deferimento',
      'Showing {{appealsLenght}} of {{totalAppeals}} found':
        'Mostrando {{appealsLenght}} de {{totalAppeals}} encontrados',
      'Appeal Informations': 'Dados do Recurso',
      'Appeal Status': 'Status do Recurso',
      Appeal_id: 'Código do recurso',
      Item_id: 'Questão',
      Object_of_Learning: 'Objeto de aprendizagem',
      Exam_name: 'Atividade',
      AppealId: 'Código do recurso:',
      'Grade/Notes': 'Nota obtida/Nota total',
      CreatedAt: 'Data de criação:',
      Bibliography: 'Bibliografia:',
      'Answer to candidate': 'Resposta para Candidato',
      'Items List': 'Ver Questões Discursivas',
      'Public Identifier': 'Identificador Público',
      'See Item': 'Ver Questão',
      correction_value: 'Nota após Correção',
      free_response: 'Buscar por palavra chave',
      'Proctors List': 'Ver salas',
      Medicament: 'Medicamento',
      Cover: 'Cobertura',
      Value: 'Valor',
      'See repertorization table': 'Ver tabela de repertorização',
      'Repertorization Table': 'Tabela de repertorização',
      'See symptom table': 'Ver tabela de sintomas',
      Symptom: 'Sintoma',
      Rubrics: 'Rubricas',
      'PAGE/COLUMN': 'Pag/Col',
      'Rubric value must be between 1 and 3':
        'O valor da rubrica deve estar entre 1 e 3',
      'Director symptom': 'Sintoma Diretor',
      'Finished applications': 'Status das Atividades',
      'Special Need': 'Necessidade especial',
      'Nursing Mother': 'Lactante',
      'Generate report card': 'Gerar boletins',
      'Attendance of activities': 'Acompanhamento de atividades',
      'Learning Objects': 'Objetos de Aprendizagem',
      'New Object': 'Novo objeto',
      'All objects': 'Todos os Objetos',
      Resources: 'Recursos',
      'New Resource': 'Novo Recurso',
      'Resource List': 'Lista de Recursos',
      'Reference matrix': 'Matriz de Referência',
      Arrays: 'Matrizes',
      'New Matrix': 'Nova Matriz',
      'All Matrices': 'Todas as Matrizes',
      Registration: 'Cadastro',
      Axes: 'Eixos',
      Compentences: 'Competências',
      Skills: 'Habilidades',
      'pre-tests': 'Pré-testes',
      'New pre-test': 'Novo Pré-teste',
      'All pre-tests': 'Todos os Pré-testes',
      'Analysis and reports': 'Análises e Relátorios',
      Reports: 'Relatórios',
      'TCT/TRI Analysis': 'Análise TCT/TRI',
      'Individual Report': 'Relatório Individual',
      'Skill level report': 'Relatório Nível Habilidade',
      'Dre report': 'Relatório dre',
      'Proficiency level report': 'Relatório Nível Proficiencia',
      'Class report': 'Relatório Turmas',
      'Order Objects': 'Encomenda de Objetos',
      'New order': 'Nova Encomenda',
      'All Orders': 'Todas as Encomendas',
      'Import Students': 'Importar Estudantes',
      'Submit activity for application': 'Enviar Atividade para a Aplicação',
      'New Activity': 'Nova Atividade',
      'All activities': 'Todas as Atividades',
      'activity management': 'Gestão de Atividades',
      'Student Permissions': 'Permissões do Estudante'
    }
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'pt_BR',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n
