import Axios from 'axios'
import CreateNoticeForm from 'components/CreateNoticeForm'
import Page from 'components/Page'
import { API_HOST } from 'consts'
import { get } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

type EditNoticePageProps = {
  className?: string
}

const EditNoticePage = ({ className }: EditNoticePageProps) => {
  const { id } = useParams<{ id: string }>()
  const [initialValues, setInitialValues] = React.useState()

  const getNoticeInfo = useCallback(() => {
    Axios.get(`${API_HOST}/billboard/get_notices?id=${id}`)
      .then((res) => {
        return setInitialValues(get(res.data, 'notice'))
      })
      .catch((err) => {
        return console.error(err)
      })
  }, [id])

  useEffect(() => {
    if (!initialValues) {
      getNoticeInfo()
    }
  }, [initialValues, getNoticeInfo])
  return (
    <div className={className}>
      <Page>
        <CreateNoticeForm initialValues={initialValues} edit={true} />
      </Page>
    </div>
  )
}
export default styled(EditNoticePage)``
