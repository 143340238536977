import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  className?: string
}

const ItemVoidIndicator = ({ className }: Props) => {
  const { t } = useTranslation()
  return <span className={className}>{t('Unanswered item')}</span>
}

export default styled(ItemVoidIndicator)`
  font-weight: 500;
  color: ${(props) => props.theme.colors.danger};
`
