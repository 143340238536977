import React from 'react'
import { Redirect } from 'react-router-dom'
import { getUserGroups } from '../utils/auth'

// TODO: Remove this on create login
const UserRedirect = () => {
  const userGroups = getUserGroups()
  if (userGroups.includes('RESPONDENTE')) {
    return <Redirect to="/applications" />
  }

  if (
    userGroups.includes('ADMINISTRADOR') ||
    userGroups.includes('PROCTOR') ||
    userGroups.includes('COORDINATOR') ||
    userGroups.includes('COMISSION') ||
    userGroups.includes('Professor') ||
    userGroups.includes('Diretor') ||
    userGroups.includes('CORRECTOR')
  ) {
    return <Redirect to="/exams/dashboard" />
  }

  return <Redirect to="/applications" />
}

export default UserRedirect
