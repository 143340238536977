import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import Button from 'components/Button'
import ShopCart from 'assets/img/shopping-cart.png'
import { Badge } from '@material-ui/core'
import styled from 'styled-components'
import { AppealProcess } from 'data/domain/appeals'

type PayableAppealHelperProps = {
  onClickShopCart: () => void
  appealCreatedCount: number
  className?: string
  appealProcess?: AppealProcess
}

const PayableAppealHelper = ({
  onClickShopCart,
  appealCreatedCount,
  className,
  appealProcess
}: PayableAppealHelperProps) => {
  const waitingPaymentPayment = appealProcess?.status?.tag === 'waiting-payment'
  const appealPayed = appealProcess?.status?.tag === 'payed'
  const creatingAppeal = appealProcess?.status?.tag === 'creating'
  const shouldShowCart = creatingAppeal

  const getAlertMessage = () => {
    if (appealPayed) {
      return <p>Pagamento confirmado, em breve seu recurso será avaliado.</p>
    }

    if (waitingPaymentPayment) {
      return (
        <>
          <p>
            Seu processo de pagamento já foi iniciado, caso já tenha efetuado o
            pagamento aguarde.
          </p>
          <p>
            Se não, <a href={appealProcess?.paymentLinkUrl}>clique aqui</a> para
            efetuar o pagamento
          </p>
        </>
      )
    }

    return (
      <>
        <p>
          Para que seu(s) recurso(s) seja(m) analisado(s), é necessário realizar
          o pagamento baseado no número de recursos abertos.
        </p>
        <p>
          Para isso, é necessário clicar no botão do carrinho e concluir o
          pagamento.
        </p>
      </>
    )
  }

  const getAlertTitleMessage = () => {
    if (waitingPaymentPayment) {
      return 'Aguardando pagamento dos recursos'
    }

    if (appealPayed) {
      return 'Pagamento do recurso confirmado'
    }

    return 'Criação de recurso'
  }

  const currentStatusMessage = () => {
    if (appealCreatedCount === 0) {
      return 'Nenhum recurso criado.'
    }
    if (appealCreatedCount === 1) {
      return '1 recurso criado.'
    }
    if (appealCreatedCount > 1) {
      return `${appealCreatedCount} recursos criados.`
    }
  }

  return (
    <div className={className}>
      <Alert
        severity="info"
        icon={false}
        action={
          shouldShowCart && (
            <div className=".card-action">
              <Badge badgeContent={appealCreatedCount} color="primary">
                <Button
                  className="btnCart"
                  action="secondary"
                  onClick={() => onClickShopCart()}
                >
                  <img className="shopCart" src={ShopCart} alt="Cart icon" />
                </Button>
              </Badge>
            </div>
          )
        }
      >
        <AlertTitle>{getAlertTitleMessage()}</AlertTitle>
        <div className="content">
          <div>
            <p className="current-status">
              {getAlertMessage()}
              <span>Status atual:</span> {currentStatusMessage()}
            </p>
          </div>
        </div>
      </Alert>
    </div>
  )
}

export default styled(PayableAppealHelper)`
  margin-top: 10px;
  text-align: left;

  .MuiAlert-action {
    align-items: flex-start;
  }
  .content {
    display: flex;
    justify-content: space-between;
  }

  .current-status {
    span {
      font-weight: bold;
    }
  }
`
