import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import styled from 'styled-components'
import BrowserButton from './BrowserButton'

type Browser = {
  name: string
  icon: IconName
}

type BrowserMenuProps = {
  className?: string
  active: string
  onChange: (browser: string) => void
}

const Browsers = ({ className, active, onChange }: BrowserMenuProps) => {
  const browsers: Browser[] = [
    {
      name: 'Chrome',
      icon: 'chrome'
    },
    {
      name: 'Firefox',
      icon: 'firefox-browser'
    }
  ]

  return (
    <div className={className}>
      {browsers.map((browser) => (
        <div key={browser.name}>
          <BrowserButton
            className={active === browser.name ? 'active' : ''}
            key={browser.name}
            onClick={() => onChange(browser.name)}
          >
            <FontAwesomeIcon icon={['fab', browser.icon]} />
          </BrowserButton>
          {browser.name}
        </div>
      ))}
    </div>
  )
}

export default styled(Browsers)`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 20px;
    }

    > * {
      margin-bottom: 5px;
    }
  }
`
