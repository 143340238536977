import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import CandidateVideo from '../components/ExamDashboardComponents/CandidateVideo'
import DashboardVideosState from '../states/DashboardVideosState'
import { IDashboardVideosState, IDashboardVideosSocketState } from '../types'
import RoomMessageExchangeState from 'states/RoomMessageExchangeState'
import CandidateContext from 'contexts/CandidateContext'
import CandidateHistoryModal from 'components/ExamDashboardComponents/CandidateHistoryModal'
import Chat from 'components/Chat'

type DashboardVideosProps = {
  className?: string
  roomId: string
}

const DashboardVideos = ({ className, roomId }: DashboardVideosProps) => {
  return (
    <div>
      {roomId && (
        <RoomMessageExchangeState roomId={roomId}>
          {({
            candidatesConnectionStatus,
            candidatesMessageStatus,
            candidatesBreakInfo,
            candidatesActivityStatus,
            candidatesFinishedStatus,
            candidatesPausedStatus,
            candidatesPermissionStatus,
            messages,
            breakRequests,
            candidates,
            onlineAmount,
            collection
          }: IDashboardVideosSocketState) => (
            <DashboardVideosState roomId={+roomId}>
              {({ fetching, users }: IDashboardVideosState) => {
                if (fetching) {
                  return (
                    <div className={className}>
                      <Skeleton width={250} height={212} />
                      <Skeleton width={250} height={212} />
                      <Skeleton width={250} height={212} />
                    </div>
                  )
                }

                if (users.length === 0) {
                  return <p>Esta sala está vazia.</p>
                }

                const videos = users.map((user) => {
                  return (
                    <CandidateContext.Provider
                      key={user.id}
                      value={{
                        candidate: candidates?.find(
                          (d) => d.id === user.id.toString()
                        ),
                        messages: messages?.filter(
                          (d) => d.candidateId === user.id.toString()
                        ),
                        breakRequest: breakRequests?.find(
                          (d) => d.candidateId === user.id.toString()
                        )
                      }}
                    >
                      <CandidateVideo
                        application={{ user }}
                        collection={collection}
                        isOnline={candidatesConnectionStatus[user.id]}
                        question={candidatesMessageStatus[user.id]}
                        breakStatus={candidatesBreakInfo[user.id]}
                        activity={
                          candidatesActivityStatus[user.id] &&
                          candidatesActivityStatus[user.id].reason
                        }
                        finished={candidatesFinishedStatus[user.id]}
                        pauseStatus={candidatesPausedStatus[user.id]}
                        permissionStatus={candidatesPermissionStatus[user.id]}
                        roomId={roomId}
                      />
                    </CandidateContext.Provider>
                  )
                })
                return (
                  <>
                    <span>
                      <h5 style={{ padding: '0.5em', margin: '0' }}>
                        Total online: {onlineAmount}
                      </h5>
                    </span>
                    <div className={className}>{videos}</div>
                    <CandidateHistoryModal roomId={roomId} />
                    <Chat room={roomId} />
                  </>
                )
              }}
            </DashboardVideosState>
          )}
        </RoomMessageExchangeState>
      )}
    </div>
  )
}

export default styled(DashboardVideos)`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`
