export interface UserConfiguration {
  faceDetectionEnabled: boolean
}

export const UserConfigurationOptions = [
  {
    name: 'faceDetectionEnabled',
    verboseName: 'Face detection enabled',
    default: true
  }
]
