import { useCallback } from 'react'
import { getOnlineUsers } from 'data/apis/online-status'
import useFirebaseCollection from './useFirebaseCollection'

const useOnlineCandidates = (roomId: string) => {
  const _getOnlineUsers = useCallback(() => {
    if (!roomId) {
      return null
    }
    return getOnlineUsers(roomId)
  }, [roomId])

  const { collection: users } = useFirebaseCollection<{
    id: string
    isOnline: boolean
  }>(_getOnlineUsers)

  const onlineCandidateIds = {}
  users &&
    users.forEach((user) => {
      onlineCandidateIds[user.id] = user.isOnline
    })

  return { onlineCandidateIds }
}

export default useOnlineCandidates
