import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer } from '@material-ui/core'
import React, { useCallback, ReactNode } from 'react'
import styled from 'styled-components'

type CandidateActionDrawerProps = {
  className?: string
  onClose: () => void
  title: string
  candidateName: string
  isOpen: boolean
  children: ReactNode
}

const CandidateActionDrawer = ({
  className,
  candidateName,
  onClose,
  isOpen,
  children,
  title
}: CandidateActionDrawerProps) => {
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Drawer open={isOpen} anchor="right" onBackdropClick={handleClose}>
      <div className={className}>
        <div className="header">
          <h3>{title}</h3>
          <p onClick={handleClose} className="close-button">
            <FontAwesomeIcon icon="times" />
          </p>
        </div>
        <p className="subheader">{candidateName}</p>
        {children}
      </div>
    </Drawer>
  )
}

export default styled(CandidateActionDrawer)`
  padding: 0px 10px 10px 10px;
  max-width: 400px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header > p {
    margin: 0;
  }

  .subheader {
    margin-top: 0;
  }

  .close-button {
    color: #5a5a5a;
    font-weight: bold;
    cursor: pointer;
  }
`
