import AppealsStatus from 'components/Appeal/AppealsStatus'
import ToggleMenuButton from 'components/Buttons/ToggleMenuButton'
import Card from 'components/Card/Card'
import CardSubtitle from 'components/ExamDashboardComponents/CardSubtitle'
import CardTitle from 'components/ExamDashboardComponents/CardTitle'
import Flex from 'components/Flex'
import ProfilePicture from 'components/ProfilePicture'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TextEditor from 'components/AppealComponents/TextEditor'
import { IItemWithFreeResponse, IExam } from 'types'
import Html from 'components/Html'
import Button from 'components/Button'
import ItemSubtitlesInfos from './ItemSubtitlesInfos'
import ItemsStatusTag from './ItemStatusTag'
import { Formik } from 'formik'
import InputNumber from 'components/Inputs/InputNumber'
import Axios from 'axios'
import { API_HOST } from 'consts'
import AutosaveFeedback, { IFeedBackStatus } from 'components/AutoSaveFeedBack'
import { toast } from 'react-toastify'
import { get } from 'lodash'
import ViewFile from 'components/AppealComponents/ViewFile'

type ItemInfoCardProps = {
  className?: string
  items: IItemWithFreeResponse
  toggleMenuDisabled?: boolean
  exam?: IExam | undefined
  initialComment?: string
}

const ItemInfoCard: React.FC<ItemInfoCardProps> = ({
  className,
  items,
  toggleMenuDisabled = false,
  children
}) => {
  const { t } = useTranslation()
  const [convertedText, setConvertedText] = useState<any>(items?.comment)
  const [savingStatus, setSavingStatus] = useState<IFeedBackStatus>('pending')
  const saveFreeResponseCorrection = useCallback(
    (value: number, evt?: any) => {
      if (value) {
        setSavingStatus('saving')
        Axios.post(`${API_HOST}/v1/free_response_corrections`, {
          answer: items.id,
          value
        })
          .then(() => {
            setSavingStatus('ok')
          })
          .catch((e) => {
            setSavingStatus('error')

            if (e?.response?.status === 400) {
              const errorMessage =
                get(e.response.data, 'value.0') ||
                get(e.response.data, 'nonFieldErrors.0') ||
                'Ocorreu um erro inesparado.'

              toast.error(errorMessage)
            }
          })
      } else if (evt?.target?.value === undefined) {
        setSavingStatus('saving')
        Axios.post(
          `${API_HOST}/v1/free_response_corrections/${items?.correctionId}/delete_grade`
        )
          .then(() => {
            setSavingStatus('ok')
          })
          .catch((e) => {
            setSavingStatus('error')

            if (e?.response?.status === 400) {
              const errorMessage = 'Ocorreu um erro inesperado.'
              toast.error(errorMessage)
            }
          })
      }
    },
    [items]
  )

  const saveComment = useCallback(
    (evt?: any) => {
      setConvertedText(evt)

      if (evt) {
        setSavingStatus('saving')
        Axios.post(`${API_HOST}/v1/free_response_corrections/save_comment`, {
          id: items.id,
          comment: evt
        })
          .then(() => {
            setSavingStatus('ok')
          })
          .catch((e) => {
            setSavingStatus('error')
            console.error(e?.response)
          })
      }
    },
    [items]
  )

  return (
    <Card className={className}>
      <ProfilePicture user={items.application} />
      <div>
        <CardTitle>
          <span>
            <b>Candidato #{items.application.name}</b>
          </span>
          <span className="subtitle-small">{items?.application?.exam}</span>
          <Flex>
            <ItemsStatusTag
              className="item-tag-status"
              status={items?.correctionStatus}
            />
            <ToggleMenuButton disabled={toggleMenuDisabled}>
              {children}
            </ToggleMenuButton>
          </Flex>
        </CardTitle>
        <CardSubtitle>
          <ItemSubtitlesInfos
            itemPosition={items?.itemVerboseName || items?.itemPosition}
            itemValue={items?.itemValue}
          />
        </CardSubtitle>
        <div className="answers-fields">
          <h4>{t('Resposta do Candidato')}</h4>
          <span>{items?.freeResponse}</span>
          <ViewFile attachments={items?.attachment} attachmentsLimit={1} />
        </div>
        <div className="input-button-field">
          <Formik
            initialValues={{
              grade: items?.correctionValue
            }}
            onSubmit={() => undefined}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-between',
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              {items?.correctionStatus !== 'disclosed' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '80%',
                    height: 'auto',
                    marginTop: '15px'
                  }}
                >
                  <b>Comentário:</b>
                  {convertedText && (
                    <TextEditor
                      value={convertedText}
                      onChange={(event) => saveComment(event)}
                    />
                  )}
                </div>
              ) : (
                ''
              )}
              <div>
                {items?.correctionStatus === 'disclosed' ? (
                  <>
                    <div className="valorObtido">
                      <p>Nota final</p>
                      <span>{items?.correctionValue}</span>
                    </div>

                    <div className="comentario">
                      <p>Comentário</p>
                      {items?.comment ? <Html>{items?.comment}</Html> : ''}
                    </div>
                  </>
                ) : (
                  <div style={{ marginLeft: '1rem' }}>
                    <InputNumber
                      label="Nota"
                      name="grade"
                      onChange={(value: any, evt: any) =>
                        saveFreeResponseCorrection(value, evt)
                      }
                    />
                    <AutosaveFeedback status={savingStatus} />
                  </div>
                )}
              </div>
            </div>
          </Formik>
        </div>
      </div>
    </Card>
  )
}

export default styled(ItemInfoCard)`
  display: grid;
  grid-template-columns: 55px auto;
  grid-column-gap: 1rem;

  .input-button-field {
    display: flex;
    justify-content: flex-end;
    align-items: right;

    .input {
      height: 1.8rem;
      width: 5rem;
      font-size: 16px;
      border: 1px solid;
      border-color: hsl(0, 0%, 80%);
      border-radius: 5px;
      margin-right: 0.2rem;
    }

    ${Button} {
      width: 6rem;
    }
  }

  ${CardTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    ${AppealsStatus} {
      margin-right: 5px;
      width: 4.9rem;
      text-align: center;
    }
    div {
      margin-left: 1rem;
    }
    span {
    }
  }
  ${CardSubtitle} {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
    padding-right: 1.5rem;
  }

  .subtitle-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-left: 6rem;
  }
  .answers-fields {
    display: grid;

    h4 {
      font-weight: 700;
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    span {
      text-align: justify;
      font-size: 0.9rem;
      margin-top: -16px;
      width: 95%;
    }
    .input-search {
      width: 22%;
      margin-top: -13px;
      margin-bottom: 10px;
    }
    ${Html} {
      text-align: justify;
      font-size: 0.9rem;
      margin-top: -2rem;
    }
  }
  .valorObtido {
    display: columns;
    padding: 20px 95px 0 0;
    p {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .comentario {
    p {
      font-weight: 700;
      font-size: 16px;
    }
    span {
      font-size: 12px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      width: 70%;
      height: 50px;
      resize: none;
    }
  }
  .textarea {
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    width: 70%;
    height: 50px;
    resize: none;
  }
  .comment-button {
    width: 100px;
    height: 20px;
    margin-top: 5px;
    background-color: ${(props) => props.theme.clientColors.secondary};
    color: ${(props) => props.theme.clientColors.primary};
    border-radius: 3px;
    border: none;
  }
`
