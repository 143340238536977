import styled from 'styled-components'
import React, { Children, ReactNode } from 'react'
import BreadcrumbSeparator from './BreadcrumbSeparator'

type Props = {
  children: ReactNode[]
  className?: string
}

const Breadcrumb = ({ children, className }: Props) => (
  <div className={className}>
    {Children.map(children, (child, index) => (
      <>
        {child}
        {index !== children.length - 1 && <BreadcrumbSeparator />}
      </>
    ))}
  </div>
)

export default styled(Breadcrumb)`
  margin-left: 20px;
`
