import React from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type AnswerCountdownProps = {
  className?: string
  secondsToTimeout: number | undefined
  initial: number | undefined
  onComplete: () => void
}

const AnswerCountdown = ({
  className,
  initial,
  secondsToTimeout,
  onComplete
}: AnswerCountdownProps) => {
  const { t } = useTranslation()

  if (secondsToTimeout === undefined || !initial) {
    return (
      <span className={className}>
        <span>{t('Unavailable')}</span>
      </span>
    )
  }

  const handleStart = ({ completed }) => {
    if (completed) {
      onComplete()
    }
  }

  return (
    <Countdown
      date={initial + secondsToTimeout * 1000}
      onComplete={onComplete}
      onStart={handleStart}
      renderer={({ ...time }) => {
        const secondsRemain = time.total / 1000

        const alertClass = secondsRemain <= 10
        const warningClass = secondsRemain <= 30

        return (
          <span className={className}>
            <span
              className={`${alertClass && 'alert'}${
                warningClass ? '-blink' : ''
              }`}
            >
              <p>
                {t('Question remaining time')}: {secondsRemain} {t('seconds')}
              </p>
            </span>
          </span>
        )
      }}
    />
  )
}

export default styled(AnswerCountdown)`
  color: ${(props) => props.theme.colors.grayDark};
  margin: 0px;

  .alert {
    color: red;

    &-blink {
      color: red;
      animation: blink 1s infinite;
    }
  }

  p {
    text-align: right;
    margin: 0px;
    font-weight: bold;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media (max-width: 600px) {
    .alert {
      background: yellow;
    }
  }
`
