import React, { ReactNode, useContext } from 'react'
import AlternativeOutline from './AlternativeOutline'
import { ThemeContext } from 'styled-components'

type Props = {
  children: ReactNode
  requestedBy?: string
}

const CandidateHitTheQuestion = ({
  children,
  requestedBy = 'CANDIDATE'
}: Props) => {
  const theme = useContext(ThemeContext)
  return (
    <AlternativeOutline
      color={theme.colors.success}
      text={requestedBy === 'CANDIDATE' ? 'Você acertou' : 'Candidato acertou'}
    >
      {children}
    </AlternativeOutline>
  )
}

export default CandidateHitTheQuestion
