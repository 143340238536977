import React from 'react'
import { IAlternative } from 'types'
import AnswerSheetAlternative from './AnswerSheetAlternative'

type Props = {
  alternatives: IAlternative[]
  givenAnswer: string
  correctAnswer: string
  isCancelled: boolean
  postAlternativeExplanationVisualization: boolean
  requestedBy
}
const AnswerSheetAlternatives = (
{
  alternatives,
  givenAnswer,
  correctAnswer,
  isCancelled,
  postAlternativeExplanationVisualization,
  requestedBy = 'CANDIDATE'
}: Props) =>
alternatives?.map((alternative) => (
  <AnswerSheetAlternative
  key={alternative.id}
  requestedBy={requestedBy}
  letter={alternative.letter}
  content={alternative.content}
  explanation={alternative.explanation}
  isPicked={alternative.letter === givenAnswer}
  correctAnswerByCandidate={
    alternative.letter === correctAnswer &&
    alternative.letter === givenAnswer
  }
  isCorrect={alternative.letter === correctAnswer}
  isCancelled={isCancelled}
  postAlternativeExplanationVisualization={
    postAlternativeExplanationVisualization
  }
  />
  ))
   || null
export default AnswerSheetAlternatives
