import React, { useCallback, useEffect } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import LightBorderCard from 'components/Card/LightBorderCard'

type AlternativesProps = {
  size?: number
  dataset: any
}

const AlternativesBarChart = ({ size = 250, dataset }: AlternativesProps) => {
  const { t } = useTranslation()

  const margin = { top: 15, right: 30, bottom: 30, left: 50 }
  const width = 600 - margin.left - margin.right
  const height = 320 - margin.top - margin.bottom
  const total = dataset.reduce((acc: number, d) => acc + d.count, 0)
  const formatedDataset = dataset.map((d) => ({
    ...d,
    count: ((d.count / total) * 100).toFixed(1)
  }))

  const format = d3.format('')

  const drawChart = useCallback(() => {
    const svg = d3
      .select('.altenatives-chart')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('viewBox', -size / 2 + ' ' + -size / 2 + ' ' + size + ' ' + size)
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    const x = d3.scaleBand().range([0, width]).padding(0.4)
    const y = d3.scaleLinear().range([height, 0])

    const xAxis = d3.axisBottom(x).tickSize([]).tickPadding(10)
    const yAxis = d3.axisLeft(y).tickFormat(format)

    x.domain(formatedDataset.map((d) => d.alternative))

    y.domain([0, 100])

    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
    svg.append('g').attr('class', 'y axis').call(yAxis)

    svg
      .selectAll('.bar')
      .remove()
      .data(formatedDataset)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .style('fill', '#4cbff2')
      .style('opacity', 0.8)
      .style('fill', (d) => {
        return (
          d.count ===
          d3.max(formatedDataset, (d) => {
            return d.count
          })
        )
      })
      .attr('x', (d) => {
        return x(d.alternative)
      })
      .attr('width', x.bandwidth())
      .attr('y', (d) => {
        return height + y(d.count)
      })
      .attr('y', (d) => {
        return y(d.count)
      })
      .attr('height', (d) => {
        return height - y(d.count)
      })

    svg
      .selectAll('.label')
      .data(formatedDataset)
      .enter()
      .append('text', 'height')
      .attr('class', 'label')
      .attr('x', (d) => {
        return x(d.alternative) + x.bandwidth() / 2 - 20
      })
      .style('fill', '#000000', (d) => {
        return (
          d.count ===
          d3.max(formatedDataset, (d) => {
            return d.count
          })
        )
      })
      .text((d) => {
        return `${format(d.count)} %`
      })
      .attr('y', (d) => {
        return y(d.count) - 2
      })
  }, [
    formatedDataset,
    size,
    format,
    height,
    width,
    margin.left,
    margin.right,
    margin.top,
    margin.bottom
  ])
  useEffect(() => {
    d3.select('.statistics-bar-chart').selectAll('svg').remove().exit()
    drawChart()
  }, [formatedDataset, drawChart])

  return (
    <LightBorderCard title={t('Visualization of alternatives')}>
      <div className="statistics-bar-chart">
        <div className="altenatives-chart" />
      </div>
    </LightBorderCard>
  )
}

export default AlternativesBarChart
