import { useField, useFormikContext } from 'formik'
import React from 'react'

type CheckBoxInputProps = {
  label?: string
  className?: string
  disabled?: boolean
  onChange?: any
  value?: string | number | unknown
  name: string
}

const CheckBoxInput = ({
  label,
  className,
  disabled,
  onChange,
  value,
  name
}: CheckBoxInputProps) => {
  const [field, meta] = useField(name)
  const { setFieldValue } = useFormikContext()

  return (
    <label
      className={`container-check-custom ${className} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <input
        type="checkbox"
        {...field}
        name={name}
        checked={value ? field.value === value : field.value}
        onChange={(event) => {
          if (value) {
            setFieldValue(field.name, event.target.checked ? value : null)
          } else {
            setFieldValue(field.name, event.target.checked)
          }
          if (onChange) {
            onChange(event.target.checked)
          }
        }}
      />
      {label}
      <span className="checkmark" />
      {meta.error && <span className="input-helper red">{meta.error}</span>}
    </label>
  )
}

export default CheckBoxInput
