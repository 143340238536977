import { IUser } from '../../types'
import { RollbarErrorTracking } from '../../infra/rollbar'
import { uploadRecordToS3 } from '../../infra/s3'

declare let MediaRecorder: any
class RecordingProctorScreenManager {
  private mediaRecorder
  private user: IUser | undefined
  private currentStream: MediaStream | null = null
  private roomId: number | undefined
  private isRecording = false
  // Media Recorder resolution/quality
  private readonly AUDIO_BITS_PER_SECOND = 800000
  private readonly VIDEO_BITS_PER_SECOND = 800000
  // records consistent chunks of 5 seconds
  private readonly DEFAULT_RECORDING_CHUNK_SIZE = 5000
  private readonly DEFAULT_RECORDING_OFFSET = 50

  public async startRecordingForUser(
    stream: MediaStream,
    user: IUser,
    roomId: number
  ) {
    if (this.isRecording || !user) {
      return
    }
    this.isRecording = true
    this.currentStream = stream
    this.user = user
    this.roomId = roomId
    try {
      this.mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'video/webm; codecs="vp8, opus"',
        audioBitsPerSecond: this.AUDIO_BITS_PER_SECOND,
        videoBitsPerSecond: this.VIDEO_BITS_PER_SECOND
      })
      this.setUpMediaRecorderEvents()
      this.mediaRecorder.start()
    } catch (e) {
      console.log(e)
    }
  }

  public destroy() {
    try {
      if (this.mediaRecorder) {
        this.mediaRecorder.addEventListener('stop', () => {})
        this.mediaRecorder.stop()
      }
      if (this.currentStream) {
        this.currentStream.getTracks().forEach((track) => track.stop())
      }
    } catch (e) {
      RollbarErrorTracking.logError(e)
    }
    this.isRecording = false
  }

  private onStopRec = () => {
    setTimeout(() => this.mediaRecorder.start(), this.DEFAULT_RECORDING_OFFSET)
  }

  private onStartRec = () => {
    setTimeout(
      () => this.mediaRecorder.stop(),
      this.DEFAULT_RECORDING_CHUNK_SIZE
    )
  }

  private setUpMediaRecorderEvents() {
    this.mediaRecorder.addEventListener('dataavailable', this.uploadChunk)
    this.mediaRecorder.addEventListener('stop', this.onStopRec)
    this.mediaRecorder.addEventListener('start', this.onStartRec)
  }

  private uploadChunk = async (e: any) => {
    const chunkData = e.data
    const date = new Date()
    const timestamp = new Date()
      .toISOString()
      .replace(/\./g, '_')
      .replace(/:/g, '_')
    const key = `${this.user.provider.codename}/tela_aplicadores/room-${
      this.roomId
    }/${
      date.getFullYear() + '_' + date.getMonth() + '_' + date.getDate()
    }/${timestamp}.webm`

    uploadRecordToS3(chunkData, key)
  }
}

const proctorScreenManager = new RecordingProctorScreenManager()
export default proctorScreenManager
